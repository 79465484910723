import React, { useState, useEffect } from 'react';
import { ListGroup } from 'react-bootstrap';
import { GetSerializedItemAndStatusList } from '../API/AssetInventory';
import { Link } from 'react-router-dom';



export default props => {
    const [items, setItems] = useState([])

    useEffect(() => {
        LoadInventory();
    }, [])

    const LoadInventory = () => {
        GetSerializedItemAndStatusList()
            .then(res => {
                setItems(res.data)
            })
    }

    return (
        <div>
            <div className="row">
                <div className="col m-2">
                    <ListGroup>
                        <ListGroup.Item className="enc-data-list">
                            Asset List
                        </ListGroup.Item>
                        <ListGroup.Item className="enc-data-list">
                            <div className="row">
                                <div className="col">
                                    Name
                                </div>
                                <div className="col">
                                    Serial Number
                                </div>
                                <div className="col">
                                    Status
                                </div>
                                <div className="col">
                                    Location
                                </div>
                                <div className="col">
                                    Last Activity
                                </div>
                            </div>
                        
                        </ListGroup.Item>
                        {items.map(i =>
                            <ListGroup.Item key={`Asset-${i.serializedItemId}`} as={Link} to={`${props.match.url}/${i.serializedItemId}`} className="click-list">
                                <div className="row">
                                    <div className="col">
                                        {i.name}
                                    </div>
                                    <div className="col">
                                        {i.serialNumber}
                                    </div>
                                    <div className="col">
                                        {i.status}
                                    </div>
                                    <div className="col">
                                        {i.location}
                                    </div>
                                    <div className="col">
                                        {i.lastActivity}
                                    </div>
                                </div>
                            </ListGroup.Item>
                        )}
                    </ListGroup>
                </div>
            </div>
          
        </div>
       
    )
}
