import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { GetJobScopeList, SetJobScopeItemSummary, GetJobScopeBudgetSummary } from '../../../API/JobScope';
import { Button, ListGroup } from 'react-bootstrap';
import DownloadBudgetSummary from './DownloadBudgetSummary';

export default ({jobId}) => {
    const [scope, setScope] = useState([]);

    useEffect(() => {
        loadScope(jobId)
    }, [jobId])

    const loadScope = (jobId) => {
        GetJobScopeList(jobId)
            .then(res => {
                setScope(res.data)
            })
    }

    const setScopeItemSummary = (scopeItem) => {
        SetJobScopeItemSummary({ "ScopeItemId": scopeItem.scopeItemId, "Summary": !scopeItem.summary })
            .then(res => {
                if (res.success) {
                    let s = [ ...scope ];
                    let i = s.findIndex((si => si.scopeItemId == scopeItem.scopeItemId));
                    s[i].summary = !scopeItem.summary;
                    setScope(s)
                }
            })
    }

    return (
        <>
            <ListGroup className="mt-3"><ListGroup.Item className="enc-data-list">
                Budget Summary
                </ListGroup.Item>
                <ListGroup.Item className="enc-data-list">
                    <div className="row">
                        <div className="col-lg-9">Scope Item</div>
                        <div className="col-lg-3 text-right">Include in Budget Summary</div>
                    </div>
                </ListGroup.Item>
                {scope.map((si, i) => (
                        <ListGroup.Item key={`budget-row-${i}`} className="enc-budget-list">
                            <div className="row">
                                <div className="col-lg-9">
                                    {(si.name == "" ? "N/A" : si.name) + " - " + si.description}
                                </div>
                                <div className="col-lg-3 text-right">
                                    <input type="checkbox"
                                        checked={si.summary}
                                        onChange={e => setScopeItemSummary(si, !si.summary)} />
                                </div>
                            </div>
                        </ListGroup.Item>
                    ))}
            </ListGroup>
            <ListGroup className="mt-3">
            </ListGroup>
        </>
    )
}
