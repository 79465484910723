import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faDownload, faCamera } from '@fortawesome/free-solid-svg-icons';
import { ListGroup, Button } from 'react-bootstrap';
import { FilePond } from 'react-filepond';
import { fpPayload } from '../API/MainAPICall/filepondApi';
import { GetJobAttachment, DeleteJobAttachment, GetJobAttachmentList } from '../API/JobAttachments';
import  Webcam  from "react-webcam";

export default ({ editable = false, jobId, jobStep, title = "Attachments", ...props }) => {
    const [attachments, setAttachments] = useState([]);

    const FACING_MODE_USER = "user";
    const FACING_MODE_ENVIRONMENT = "environment";

    const [isWebcam, setWebcam] = useState(false)
    const [isWebcamFrozen, setWebcamFrozen] = useState(false)
    const [webcamImage, setWebCamImage] = useState('');
    const [webcamImageName, setWebCamImageName] = useState('');

    const webcamRef = React.useRef(null);
    const filepondRef = React.useRef(null);

    const videoConstraints = {
        width: 1920,
        height: 1080,
        renderwidth: '',
        renderheight: '',
        facingMode: FACING_MODE_ENVIRONMENT
    };
    const webcamSnapshot = React.useCallback(
        () => {
            if (!isWebcamFrozen) {
                const imageSrc = webcamRef.current.getScreenshot();
                setWebCamImage(imageSrc)
            }
            else {
                setWebCamImage('')
                setWebCamImageName('')
            }
            setWebcamFrozen(!isWebcamFrozen)
        },
        [webcamRef, isWebcamFrozen]
    );

    useEffect(() => {
        LoadAttachments(jobId, jobStep)
    }, [jobId, jobStep])

    const LoadAttachments = (argJobId, argJobStep) => {
        GetJobAttachmentList(argJobId, argJobStep)
            .then(res => { setAttachments(res.data) })
    }

    const RemoveAttachment = (id) => {
        DeleteJobAttachment(id)
            .then(res => {
                LoadAttachments(jobId, jobStep)
            })
    }

    const preventFileLoadOnMissedDragAndDrop = () => {
        window.addEventListener("dragover", function (e) {

            e.preventDefault();
        }, false);

        window.addEventListener("drop", function (e) {

            e.preventDefault();
        }, false);
    }


    const renderUpload = () => {
        if (editable) {
            return <div className="attachment-container">
                <div className="attachment-camera clickable" onClick={() => setWebcam(!isWebcam)}>
                    <FontAwesomeIcon icon={faCamera} className=""  />
                </div>
                {isWebcam ?
                    <div className="attachment-camera-preview">
                        {isWebcamFrozen ?
                            <img src={webcamImage} />
                        :
                        <Webcam
                            audio={false}
                            height={videoConstraints.renderheight}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            width={videoConstraints.renderwidth}
                            videoConstraints={videoConstraints}
                        />
                        }
                        { renderCameraButtons() }
                    </div>
                    : null}
                <FilePond
                    className="mt-2"
                    allowMultiple={false}
                    server={fpPayload(jobId, jobStep, () => { LoadAttachments(jobId, jobStep) })}
                    credits={false}
                    ref={filepondRef}
                />
            </div>
        }
        else {
            return null;
        }
    }
    const renderCameraButtons = () => {
        return <div>
            {webcamImage && isWebcamFrozen ?
            <div className="row justify-content-center">
                <input type="text"
                    className="enc-input-100"
                    name="webcamImageName"
                    value={webcamImageName}
                    placeholder="Image Name"
                    onChange={e => setWebCamImageName(e.target.value)}
                />
            </div>
                : null}
            <div className="row justify-content-center">
            <Button className="enc-button" onClick={() => resetCamera()}>Cancel</Button>&nbsp;
            <Button className="enc-button" onClick={() => webcamSnapshot()}>{isWebcamFrozen ? "Reset" : "Snapshot"}</Button>&nbsp;
            {webcamImage && isWebcamFrozen ?
                <Button className="enc-button" onClick={() => uploadCamera()}>Upload</Button>
                    : null}
            </div>
        </div>;
    }
    const uploadCamera = () => {
        var fp = filepondRef.current;
        if (fp) {
            fp.addFile(webcamImage).then(item => {
                var n = webcamImageName;
                if (n)
                    item.file.name = n;
                var t = item.file.type; // attempt to append the correct filetype
                t = t.replace("image/", "");
                item.file.name = item.file.name + "." + t;
            });
        }
        resetCamera();
    }
    const resetCamera = () => {
        setWebcam(false);
        setWebcamFrozen(false);
        setWebCamImage('')
        setWebCamImageName('')
    }

    useEffect(() => {
        preventFileLoadOnMissedDragAndDrop();
    })

    return (
        <>
            <ListGroup>
                <ListGroup.Item className="enc-list">
                    <div className="row">
                        <div className="col">
                            <strong>{title}</strong>
                        </div>
                    </div>
                </ListGroup.Item>
                {attachments.length === 0 && <ListGroup.Item className="enc-list">No Attachments Found</ListGroup.Item>}
                {attachments.map(m => {
                    return (
                        <ListGroup.Item key={`Attachment-${m.jobAttachmentId}`} className="enc-list">
                            <div className="row">
                                <div className="col">
                                    <strong>{m.name}</strong>
                                </div>
                                <div className="col-1">
                                    <FontAwesomeIcon icon={faDownload} className="clickable" onClick={() => GetJobAttachment(m.jobAttachmentId, m.name)} />
                                </div>
                                {editable && <div className="col-1">
                                    <FontAwesomeIcon icon={faTrash} className="clickable" onClick={() => RemoveAttachment(m.jobAttachmentId)} />
                                </div>}
                            </div>
                        </ListGroup.Item>
                    )
                }
                )}
            </ListGroup>
            {renderUpload()}
        </>
    )
}


