import { faDownload, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Alert, Button, ListGroup } from 'react-bootstrap';
import { DropdownList } from 'react-widgets';
import { GetAttachmentList, GetAttachmentUpload, GetNoteList, GetPrefill, PostPrefill } from '../../API/Proposal';

export default props => {
    const [prefill, setPrefill] = useState({
        prefillBaseId: 0,
        leafId: 0,
        proposalTitle: "",
        scopeName: "",
        proposalDescription: "",
        notes: [],
        attachments: [],
        detailed: false,
        hideItem: false
    })

    const [confirmSave, setConfirmSave] = useState(false);

    const [attachmentList, setAttachmentList] = useState([])
    const [selectedAttachment, setSelectedAttachment] = useState(null)
    const [noteList, setNoteList] = useState([])
    const [selectedNote, setSelectedNote] = useState(null)

    useEffect(() => {
        LoadAttachments()
        LoadNotes()
    }, [])

    useEffect(() => {
        LoadPrefill(props.match.params.id)
    }, [props.match.params.id])

    const LoadPrefill = (id) => {
        GetPrefill(id)
            .then(res => {
                setPrefill(res.data)
            })
    }

    const SavePrefill = () => {
        let payload = { ...prefill }
        if (prefill.leafId === 0) {
            payload.leafId = parseInt(props.match.params.id)
        }
        PostPrefill(payload)
            .then(res => {
                LoadPrefill(props.match.params.id)
                if(res.success){
                    setConfirmSave(true);
                }
            })
    }

    const ChangePrefill = (key, value) => {
        let s = { ...prefill }
        s[key] = value
        setPrefill(s)
    }

    const LoadAttachments = () => {
        GetAttachmentList()
            .then(res => {
                setAttachmentList(res.data)
            })
    }

    const LoadNotes = () => {
        GetNoteList()
            .then(res => {
                setNoteList(res.data)
            })
    }

    const GetUpload = (id) => {
        GetAttachmentUpload(id)
            .then(blob => {
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                }
                else {
                    var objectUrl = URL.createObjectURL(blob);
                    window.open(objectUrl);
                }
            })
    }

    const RemoveAttachment = (index) => {
        let s = { ...prefill }
        s.attachments.splice(index, 1)
        setPrefill(s)
    }

    const RemoveNote = (index) => {
        let s = { ...prefill }
        s.notes.splice(index, 1)
        setPrefill(s)
    }

    const AddAttachment = () => {
        if (selectedAttachment) {
            let s = { ...prefill }
            s.attachments.push(selectedAttachment)
            setSelectedAttachment(null)
            setPrefill(s)
        }
    }

    const AddNote = () => {
        if (selectedNote) {
            let s = { ...prefill }
            s.notes.push(selectedNote)
            setSelectedNote(null)
            setPrefill(s)
        }
    }


    return (
        <div className="container">
            <form action="#" onSubmit={(e) => { e.preventDefault(); SavePrefill() }}>
                <div className="row">
                    <div className="col">
                        <h3>Scope Editor</h3>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <strong>{prefill.scopeName}</strong>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-3">
                        Proposal Title
                </div>
                    <div className="col">
                        <input
                            className="enc-input-100"
                            value={prefill.proposalTitle}
                            onChange={e => ChangePrefill('proposalTitle', e.target.value)} />
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-3">
                        Description
                </div>
                    <div className="col mt-2">
                        <textarea
                            value={prefill.proposalDescription}
                            className="enc-textarea"
                            onChange={e => ChangePrefill('proposalDescription', e.target.value)}
                        />
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <div className="toggle-switch small-switch">
                            <input
                                type="checkbox"
                                className="toggle-switch-checkbox"
                                id="disabled"
                                checked={prefill.detailed}
                                onChange={e => ChangePrefill('detailed', !prefill.detailed)}
                            />
                            <label className="toggle-switch-label" htmlFor="disabled">
                                <span className={"toggle-switch-inner"} />
                                <span
                                    className={"toggle-switch-switch"}
                                />
                            </label>
                        </div>
                    &nbsp; &nbsp;Detailed Budget
                    </div>
                    
                    <div className="col">
                        <div className="toggle-switch small-switch">
                            <input
                                type="checkbox"
                                className="toggle-switch-checkbox"
                                id="hidden"
                                checked={prefill.hideItem}
                                onChange={e => ChangePrefill('hideItem', !prefill.hideItem)}
                            />
                            <label className="toggle-switch-label" htmlFor="hidden">
                                <span className={"toggle-switch-inner"} />
                                <span
                                    className={"toggle-switch-switch"}
                                />
                            </label>
                        </div>
                    &nbsp; &nbsp;Hide Item
                    </div>
                </div>

                <div className="divider my-3" />

                <div className="row">
                    <div className="col">
                        <h4>Add Note</h4>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col">
                        <DropdownList
                            placeholder="Select a Note"
                            data={noteList.filter(item => !prefill.notes.find(({ prefillNoteId }) => item.prefillNoteId === prefillNoteId))}
                            textField="note"
                            filter='contains'
                            onChange={e => setSelectedNote(e)}
                            value={selectedNote}
                        />
                    </div>
                    <div className="col-3 text-right">
                        <Button className="enc-button" onClick={AddNote}>Add Note</Button>
                    </div>
                </div>
                <ListGroup className="mt-2">
                    <ListGroup.Item className="enc-list">
                        Linked Notes
                </ListGroup.Item>
                    {prefill.notes.length === 0 &&
                        <ListGroup.Item>
                            No Notes Linked
                </ListGroup.Item>}
                    {prefill.notes.map((m, i) =>
                        <ListGroup.Item key={`note-${i}`} className="enc-list">
                            <div className="row">
                                <div className="col">
                                    {m.note}
                                </div>
                                <div className="col-1">
                                    <FontAwesomeIcon icon={faTrash} className="clickable" onClick={() => RemoveNote(i)} />
                                </div>
                            </div>

                        </ListGroup.Item>)}
                </ListGroup>


                <div className="divider my-3" />

                <div className="row">
                    <div className="col">
                        <h4>Add Attachment</h4>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <DropdownList
                            placeholder="Select an Attachment"
                            data={attachmentList.filter(item => !prefill.attachments.find(({ prefillAttachmentId }) => item.prefillAttachmentId === prefillAttachmentId))}
                            textField="attachmentName"
                            filter='contains'
                            onChange={e => setSelectedAttachment(e)}
                            value={selectedAttachment}
                        />
                    </div>
                    <div className="col-3 text-right">
                        <Button className="enc-button" onClick={AddAttachment}>Add Attachment</Button>
                    </div>
                </div>
                <ListGroup className="mt-2">
                    <ListGroup.Item className="enc-list">
                        Linked Attachments
                </ListGroup.Item>
                    {prefill.attachments.length === 0 &&
                        <ListGroup.Item>
                            No Attachments Linked
                </ListGroup.Item>}
                    {prefill.attachments.map((m, i) =>
                        <ListGroup.Item key={`attachment-${i}`} className="enc-list">
                            <div className="row">
                                <div className="col">
                                    {m.attachmentName}
                                </div>
                                <div className="col">
                                    {m.termAndCondition && "Terms & Conditions"}
                                </div>
                                <div className="col-1 text-right">
                                    <FontAwesomeIcon icon={faDownload} className="clickable" onClick={e => GetUpload(m.prefillAttachmentId)} />
                                </div>
                                <div className="col-1 text-right">
                                    <FontAwesomeIcon icon={faTrash} className="clickable" onClick={() => RemoveAttachment(i)} />
                                </div>
                            </div>
                        </ListGroup.Item>)}
                </ListGroup>
                <div className="row mt-2 mb-4">
                    <div className="col-8">
                        <Alert variant="success" show={confirmSave} onClose={() => setConfirmSave(false)} dismissible>
                            <Alert.Heading>Configuration Saved</Alert.Heading>
                            <p>
                                Successfully saved configuration data.
                            </p>
                        </Alert>
                    </div>
                    <div className="col text-right">
                        <Button type='submit' className="enc-button">Save</Button>
                    </div>
                </div>
            </form>
        </div>
    )
}
