import React, { useState, useEffect } from 'react';
import { ListGroup } from 'react-bootstrap';
import { SearchWiki } from '../API/Wiki'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import { useHistory } from 'react-router-dom'

export default props => {
    const [searchStr, setSearchStr] = useState("");
    const [lastSearch, setLastSearch] = useState("");
    const [searchResults, setSearchResults] = useState(null);

    let history = useHistory();
    useEffect(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let str = params.get('str');

        if (str) {
            setSearchStr(str);
            searchWiki(str);
        }
        let s = props;
    }, [props]);

    const checkSubmit = (e) => {
        if (e.key === "Enter") {
            searchWiki(searchStr);
            history.push({
                pathname: `/Wiki/Search`,
                search: `?str=${encodeURIComponent(searchStr)}`
            });
        }
    }
    const searchWiki = (str) => {
        setLastSearch(str);
        SearchWiki(str).then(res => {
            if (res.success) {
                setSearchResults(res.data.result);
            } else {
                setSearchResults([]);
            }
        })
    }

    const RenderWikiPath = (path) => {
        return path.split(":").join(" > ");
    }

    return (
        <>
            <div className="container">
                <div className="row my-2">
                    <div className="col-11">
                        <input
                            value={searchStr ? searchStr : ""}
                            placeholder={ "Search" }
                            onChange={e => setSearchStr(e.target.value)}
                            onKeyDown={(e) => checkSubmit(e)}
                            className="enc-input-100"
                        />
                    </div>
                    <div className="col-1">
                        <button className="enc-button btn btn-primary enc-input-100"
                            onClick={e => searchWiki(searchStr)}
                        >
                            <FontAwesomeIcon icon={faSearch} />
                            
                        </button>
                    </div>
                </div>
                {searchResults === null ?
                    <>
                    </>
                    : searchResults.length > 0 ?
                        <ListGroup className="my-3">
                            {searchResults.map((result, resultIndex) => {
                                return (
                                    <ListGroup.Item className="py-1" key={`result-${resultIndex}`}>
                                            <div class="row card-title h5">
                                                <a className="navlink-spacing" href={result.url} target="_blank" rel="noreferrer noopener">
                                                {RenderWikiPath(result.id)}
                                                </a>
                                            </div>
                                        <div class="row navlink-spacing search-result" dangerouslySetInnerHTML={{ __html: result.description }}>
                                               
                                        </div>
                                    </ListGroup.Item>
                                )
                            })}
                        </ListGroup>
                        : `No results for ${lastSearch}`
                }
            </div>
        </>
    )
}
