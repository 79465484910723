import APIRequest, { APIPDFRequest, APIPostFileReturn } from './MainAPICall/api';
import config from '../APIConfig';

export const GetAttachmentList = () => {
    return APIRequest({
        url: config.baseURL + "/Proposal/AttachmentList",
        method: "get"
    });
}

export const GetAttachmentUpload = (id) => {
    return APIPDFRequest({
        url: config.baseURL + `/Proposal/UploadAttachment?id=${id}`,
        method: "get"
    });
}

export const GetProposalAttachment = (id) => {
    return APIRequest({
        url: config.baseURL + `/Proposal/ProposalAttachment?attachmentId=${id}`,
        method: "get"
    });
}

export const PostProposalAttachment = (payload) => {
    return APIRequest({
        url: config.baseURL + `/Proposal/ProposalAttachment`,
        method: "post",
        body: payload
    });
}

export const GetNoteList = () => {
    return APIRequest({
        url: config.baseURL + "/Proposal/NoteList",
        method: "get"
    });
}

export const CreateNote = (payload) => {
    return APIRequest({
        url: config.baseURL + "/Proposal/CreateNote",
        method: "post",
        body: payload
    });
}

export const GetNote = (id) => {
    return APIRequest({
        url: config.baseURL + `/Proposal/Note?noteId=${id}`,
        method: "get"
    });
}

export const PostNote = (payload) => {
    return APIRequest({
        url: config.baseURL + "/Proposal/Note",
        method: "post",
        body: payload
    });
}


export const GetProposal = (id) => {
    return APIRequest({
        url: config.baseURL + `/Proposal/Proposal?id=${id}`,
        method: "get"
    });
}

export const GenerateProposal = (id) => {
    return APIPDFRequest({
        url: config.baseURL + `/Proposal/GenerateProposal?jobid=${id}`,
        method: "get"
    });
}

export const ProposalListNames = () => {
    return APIRequest({
        url: config.baseURL + `/Proposal/ProposalListNames`,
        method: "get"
    });
}

export const PostProposal = (payload) => {
    return APIRequest({
        url: config.baseURL + "/Proposal/Proposal",
        method: "post",
        body: payload
    });
}

export const GetPrefill = (id) => {
    return APIRequest({
        url: config.baseURL + `/Proposal/Prefill?id=${id}`,
        method: "get"
    });
}

export const PostPrefill = (payload) => {
    return APIRequest({
        url: config.baseURL + `/Proposal/Prefill`,
        method: "post",
        body: payload
    });
}

export const GetBuildProposal = (id) => {
    return APIRequest({
        url: config.baseURL + `/Proposal/BuildProposal?jobId=${id}`,
        method: "get"
    });
}

export const PostBuildProposal = (payload) => {
    return APIRequest({
        url: config.baseURL + `/Proposal/BuildProposal`,
        method: "post",
        body: payload
    });
}

export const GeneratePDF = (payload) => {
    return APIPostFileReturn({
        url: config.baseURL + `/Proposal/GeneratePDF`,
        method: "post",
        body: payload
    });
}


export const RegenerateBody = (id) => {
    return APIRequest({
        url: config.baseURL + `/Proposal/RegenerateBody?jobId=${id}`,
        method: "get"
    });
}

export const ProposalListView = (id) => {
    return APIRequest({
        url: config.baseURL + `/Proposal/ProposalListView?jobId=${id}`,
        method: "get"
    });
}

export const GetProposalPdf = (id) => {
    return APIPDFRequest({
        url: config.baseURL + `/Proposal/ProposalPDF?PdfId=${id}`,
        method: "get"
    });
}

export const BulkNoteLink = (scopeId, noteId) => {
    return APIRequest({
        url: config.baseURL + `/proposal/LinkNote?noteId=${noteId}&scopeId=${scopeId}`,
        method: "get"
    });
}