import React, { useState, useEffect } from 'react';
import { ListGroup } from 'react-bootstrap';
import { GetJobInventoryProfile } from '../../API/Inventory';

export default props => {
    // Items That Are In Inventory
    const [jobInventory, setJobInventory] = useState({
        assets: [],
        bulkInventory: []
    });

    useEffect(() => {
        let id = props.jobId;
        LoadProfile(id)
    }, [props.jobId])

    const LoadProfile = (id) => {
        GetJobInventoryProfile(id)
            .then(res => {
                setJobInventory(res.data)
            })
    }
    return (
        <>
            <ListGroup>
                <ListGroup.Item className="enc-list">
                    Job Ready Inventory
                    </ListGroup.Item>
                {jobInventory.bulkInventory.length === 0 ?
                    <ListGroup.Item className="enc-list">
                        No Inventory to Display
                    </ListGroup.Item>
                    :
                    <ListGroup.Item className="enc-list">
                        <div className="row">
                            <div className="col">
                                Item Name
                        </div>
                            <div className="col">
                                Total Sent
                        </div>
                            <div className="col">
                                Inventory Total
                        </div>
                        </div>
                    </ListGroup.Item>
                }
                {jobInventory.bulkInventory.map((m, i) =>
                    <ListGroup.Item key={`BulkInventory-${i}`}>
                        <div className="row">
                            <div className="col">
                                {m.name}
                            </div>
                            <div className="col">
                                {m.totalAmount}
                            </div>
                            <div className="col">
                                {m.currentAmount}
                            </div>
                        </div>
                    </ListGroup.Item>
                )}
            </ListGroup>

            <ListGroup className="my-4">
                <ListGroup.Item className="enc-list">
                    Asset Inventory
                </ListGroup.Item>
                {jobInventory.assets.length === 0 ?
                    <ListGroup.Item className="enc-list">
                        No Inventory to Display
                    </ListGroup.Item>
                    :
                    <ListGroup.Item className="enc-list">
                        <div className="row">
                            <div className="col">
                                Asset
                        </div>
                            <div className="col">
                                Serial Number
                        </div>
                            <div className="col">
                                Status
                        </div>
                        </div>
                    </ListGroup.Item>
                }
                {jobInventory.assets.map((m, i) =>
                    <ListGroup.Item key={`inv-${i}`} className="enc-list">
                        <div className="row">
                            <div className="col">
                                {m.itemName}
                            </div>
                            <div className="col">
                                {m.serialNumber}
                            </div>
                            <div className="col">
                                {m.status}
                            </div>
                        </div>
                    </ListGroup.Item>
                )}
                </ListGroup>
        </>
    )
}
