import APIRequest from './MainAPICall/api';
import config from '../APIConfig';

export const GetSerializedItemAndStatusList = () => {
    return APIRequest({
        url: config.baseURL + "/AssetInventory/GetSerializedItemAndStatusList",
        method: "get"
    })
}

export const SerializedItemProfile = (id) => {
    return APIRequest({
        url: config.baseURL + `/AssetInventory/SerializedItemProfile?id=${id}`,
        method: "get"
    })
}


export const CreateSerializedItem = (payload) => {
    return APIRequest({
        url: config.baseURL + "/AssetInventory/CreateSerializedItem",
        method: "post",
        body: payload
    })
}

export const DecommissionItem = (id) => {
    return APIRequest({
        url: config.baseURL + `/AssetInventory/DecommissionItem?id=${id}`,
        method: "get"
    })
}

export const AssetAtJob = (id) => {
    return APIRequest({
        url: config.baseURL + `/AssetInventory/AssetAtJob?id=${id}`,
        method: "get"
    })
}

export const AssetAtYard = (id) => {
    return APIRequest({
        url: config.baseURL + `/AssetInventory/AssetAtYard?id=${id}`,
        method: "get"
    })
}

export const AssetTypeList = () => {
    return APIRequest({
        url: config.baseURL + `/AssetInventory/AssetTypeList`,
        method: "get"
    })
}

export const MeterTypeList = () => {
    return APIRequest({
        url: config.baseURL + `/AssetInventory/MeterTypeList`,
        method: "get"
    })
}

export const RepairAsset = (payload) => {
    return APIRequest({
        url: config.baseURL + `/AssetInventory/RepairAsset`,
        method: "post",
        body: payload
    })
}

export const CheckInRepairedAsset = (payload) => {
    return APIRequest({
        url: config.baseURL + `/AssetInventory/CheckInRepairedAsset`,
        method: "post",
        body: payload
    })
}

export const OutForRepairList = () => {
    return APIRequest({
        url: config.baseURL + `/AssetInventory/OutForRepairList`,
        method: "get"
    })
}

export const GetFailureJobHistory = (id) => {
    return APIRequest({
        url: config.baseURL + `/AssetInventory/FailureJobHistory?assetId=${id}`,
        method: "get"
    })
}

export const PostFailureForm = (payload) => {
    return APIRequest({
        url: config.baseURL + `/AssetInventory/FailureForm`,
        method: "post",
        body: payload
    })
}

export const GetFailurePhoto = (id, fileName) => {
    let download = require('downloadjs')

    let token;
            
    const LS = localStorage.getItem("BAENCToken");
    const SS = sessionStorage.getItem("BAENCToken");

    if(LS){
        token = LS;
    } else if (SS) {
        token = SS;
    }

    fetch(`${config.baseURL}/AssetInventory/FailurePhoto?PhotoId=${id}`, {
        method: "get",
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then(res=>res.blob())
    .then(blob => {
        download(blob, fileName)})
}

export const DeleteFailurePhoto = (id) => {
    return APIRequest({
        url: config.baseURL + `/AssetInventory/DeleteFailurePhoto?PhotoId=${id}`,
        method: "get"
    })
}

export const GetFailureFormList = (startDate, endDate) => {
    return APIRequest({
        url: config.baseURL + `/AssetInventory/FailureFormList?startDate=${startDate}&endDate=${endDate}`,
        method: "get"
    })
}

export const GetFailureForm = (id) => {
    return APIRequest({
        url: config.baseURL + `/AssetInventory/FailureForm?id=${id}`,
        method: "get"
    })
}

export const GetAsset = (id) => {
    return APIRequest({
        url: config.baseURL + `/AssetInventory/Asset?id=${id}`,
        method: "get"
    })
}

export const PostAsset = (payload) => {
    return APIRequest({
        url: config.baseURL + `/AssetInventory/Asset`,
        method: "post",
        body: payload
    })
}