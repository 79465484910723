import React, { useState, useEffect } from 'react';
import { GetEmployee, SaveEmployee, DeleteEmployee } from '../API/Employee';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';

export default (props) => {
    const [employee, setEmployee] = useState({
        employeeId: 0,
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        pin: ""
    });
    const [message, setMessage] = useState("");

    useEffect(() => {
        LoadEmployee(props.employeeId);
    }, [props.employeeId])

    const LoadEmployee = (id) => {
        if (id !== "new") {
            GetEmployee(parseInt(id))
                .then(res => {
                    if (res.success) {
                        setEmployee(res.data);
                    }
                })
        }
    }

    const changeEmployee = (e) => {
        let newState = { ...employee };
        newState[e.target.name] = e.target.value;
        setEmployee(newState)
    }

    const history = useHistory();
    const SubmitEmployee = () => {
        SaveEmployee(employee)
            .then(res => {
                if (res.success) {
                    history.push('/Employees');
                }
                else {
                    let newState = { ...employee };
                    newState.pin = "";
                    setEmployee(newState)
                    setMessage(res.message);
                }
            })

    }

    const DeleteCurrentEmployee = () => {
        DeleteEmployee(employee.employeeId)
            .then(res => {
                if (res.success) {
                    history.push(`/Employees`);
                }
            })
    }

    return (
        <div className="container border py-3 mt-2">

            <h4 className="text-center">Create New Employee</h4>
            <div className="divider py-2" />

            <form action="#" onSubmit={(e) => { e.preventDefault(); SubmitEmployee() }}>

                <div className="row pt-2">
                    <div className="col">
                        First Name
                    </div>
                    <div className="col">
                        <input type="text" className="enc-input-100" name="firstName" value={employee.firstName} placeholder="First Name" onChange={changeEmployee} required />
                    </div>
                </div>
                <div className="row pt-2">
                    <div className="col">
                        Last Name
                    </div>
                    <div className="col">
                        <input type="text" className="enc-input-100" name="lastName" value={employee.lastName} placeholder="Last Name" onChange={changeEmployee} required />
                    </div>
                </div>
                <div className="row pt-2">
                    <div className="col">
                        Phone Number
                    </div>
                    <div className="col">
                        <input type="text" className="enc-input-100" name="phoneNumber" value={employee.phoneNumber} placeholder="Phone Number" onChange={changeEmployee} required />
                    </div>
                </div>
                <div className="row pt-2">
                    <div className="col">
                        Email Address
                    </div>
                    <div className="col">
                        <input type="text" className="enc-input-100" name="email" value={employee.email} placeholder="Email" onChange={changeEmployee} required />
                    </div>
                </div>
                <div className="row pt-2">
                    <div className="col">
                        PIN
                    </div>
                    <div className="col">
                        <input type="text" className="enc-input-100" name="pin" value={employee.pin} placeholder="PIN" onChange={changeEmployee} required />
                    </div>
                </div>
                <div className="row pt-2">
                    <div className="col">

                    </div>
                    <div className="col">
                        {message}
                    </div>
                </div>

                <div className="row pt-2">
                    <div className="col text-right">
                        {employee.employeeId !== 0 &&
                            <Button variant="danger" onClick={DeleteCurrentEmployee}>Delete</Button>
                        }
                        <Button variant="secondary" className="ml-2" onClick={() => history.push(`/Employees`)}>Cancel</Button>
                        <Button className="enc-button ml-2" type="submit">Save</Button>
                    </div>
                </div>
            </form>

        </div>
    )
}


