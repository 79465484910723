import React, { useState, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom'

import { GetNav } from '../API/Navigation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { Navbar, Nav } from 'react-bootstrap';

export default props => {
    const [Navigation, setNavigation] = useState([]);
    const [searchStr, setSearchStr] = useState("");
    const [currentPath, setCurrentPath] = useState("");
    
    let history = useHistory();

    useEffect(() => {
        LoadNavigation();
    }, []);
    useEffect(() => {
        let s = props;
        setCurrentPath("");
    }, [props]);
    const LoadNavigation = () => {
        GetNav()
            .then(res => {
                let nav = res.navigationItems
                // STATIC for front end append... circumvents backend fing up
                setNavigation(nav)
            })
            .catch(

            )
    }
    const SearchWiki = (e) => {
        if (e.key === "Enter") {
            history.push({
                pathname: `/Wiki/Search`,
                search: `?str=${encodeURIComponent(searchStr)}`
            });
        }
    }
    const GetRoute = () => {
        let loc = useLocation();
        return loc.pathname;
    }
    return (
        <div className="no-print">
            <Navbar className="navbar-dark" expand="xl">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse>
                    <Nav.Link className="navlink-spacing" as={Link} to="/"><FontAwesomeIcon icon={faHome} /></Nav.Link>
                    {Navigation.map(m => (<Nav.Link className="navlink-spacing" key={`nav-${m.label}`} as={Link} to={m.url}>{m.label}</Nav.Link>))}
                    {
                        GetRoute() !== "/Wiki/Search" &&
                        <div className="navlink-spacing ml-auto" >
                            <input
                                value={searchStr}
                                placeholder={"Wiki"}
                                className="enc-input"
                                onChange={(e) => setSearchStr(e.target.value)}
                                onKeyDown={(e) => SearchWiki(e)}
                            />
                        </div>
                    }
                    {
                        props.HelpLink &&
                        <a className="navlink-spacing" href={props.HelpLink} target="_blank" rel="noreferrer noopener">
                            <FontAwesomeIcon icon={faQuestionCircle} />
                        </a>
                    }
                </Navbar.Collapse>
            </Navbar>
        </div>

    )
}