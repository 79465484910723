import React from 'react';
import { Nav } from 'react-bootstrap';
import { Switch, NavLink, Route } from 'react-router-dom';
import JobReadyItemList from './JobReadyInventory/JobReadyItemList';
import BulkItemList from './BulkInventory/BulkItemsList';
import BulkItem from './BulkInventory/BulkItem';
import JobReadyItem from './JobReadyInventory/JobReadyItem';
import InventoryCategory from './InventoryCategory';
import InventoryCategoryList from './InventoryCategoryList';


export default props => {
    return (
        <>
            <Nav variant="tabs" className="my-2">
                <Nav.Item>
                    <Nav.Link eventKey={1} as={NavLink} to={`${props.match.url}/JobReadyInventoryItems`}>Job Ready Items</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey={2} as={NavLink} to={`${props.match.url}/BulkItems`}>Bulk Items</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey={3} as={NavLink} to={`${props.match.url}/InventoryCategory`}>Inventory Categories</Nav.Link>
                </Nav.Item>
            </Nav>
            <div className="container">
                <Switch>
                    <Route path={`${props.match.url}/JobReadyInventoryItems/:id`} component={JobReadyItem} />
                    <Route path={`${props.match.url}/JobReadyInventoryItems`} component={JobReadyItemList} />
                    <Route path={`${props.match.url}/BulkItems/:id`} component={BulkItem} />
                    <Route path={`${props.match.url}/BulkItems`} component={BulkItemList} />
                    <Route path={`${props.match.url}/InventoryCategory/:id`} component={InventoryCategory} />
                    <Route path={`${props.match.url}/InventoryCategory`} component={InventoryCategoryList} />
                </Switch>
            </div>
        </>
    )
}

