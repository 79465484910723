import { faArrowDown, faArrowUp, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Accordion, Alert, Button, Card, ListGroup, Spinner } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { Link, useHistory } from 'react-router-dom';
import { DropdownList } from 'react-widgets';
import { GetBuildProposal, PostBuildProposal, RegenerateBody, GeneratePDF } from '../../../API/Proposal';

export default props => {
    const options = ['NONE', '-AND-', '-OR-'];
    const [error, setError] = useState("")
    const [show, setShow] = useState(false);

    const [generating, setGenerating] = useState(false);

    const [Honorific, setHonorific] = useState([])

    const [userList, setUserList] = useState([])
    const [selectedUser, setSelectedUser] = useState(null)

    const [attachments, setAttachments] = useState([])
    const [selectedAttachment, setSelectedAttachment] = useState(null)

    const [contactList, setContactList] = useState([])
    const [selectedContact, setSelectedContact] = useState(null)

    const [locationList, setLocationList] = useState([])
    const [selectedLocation, setSelectedLocation] = useState(null)

    const [OLD_additionalLines, OLD_setAddtionalLines] = useState([])

    const [newNote, setNewNote] = useState("")

    const [proposal, setProposal] = useState({
        jobId: 0,
        proposalName: "",
        title: "",
        proposalId: 0,
        date: new Date(),
        companyContactPronoun: "",
        companyContact: 0,
        companyLocation: 0,
        encContact: 0,
        reference: "",
        subject: "",
        introParagraph: "",
        proposalScope: [],
        notes: [],
        attachments: []
    })

    const changeFollow = (index, key, value) => {
        if (value === "NONE") {
            value = "";
        }
        changeScope(index, key, value)
    }

    const saveProposal = () => {
        let payload = {
            ...proposal,
            companyContact: selectedContact.contactId,
            companyLocation: selectedLocation.accountLocationId,
            encContact: selectedUser.userId
        }
        PostBuildProposal(payload)
            .then(res => {
                LoadProposalBuild(props.jobId)
                setShow(true);
            })
    }

    const updateProposalAdditionalLine = (index, key, value) => {
        let s = { ...proposal };
        let item = s.proposalScope[index].additionalLine;
        item[key] = value;
        setProposal(s);
    }
    const updateProposalAdditionalLines = (index, lineIndex, key, value) => {
        let s = { ...proposal };
        let item = s.proposalScope[index].additionalLines[lineIndex];
        item[key] = value;
        setProposal(s);
    }
    const addProposalAdditionalLine = (index) => {
        let s = { ...proposal };
        let item = s.proposalScope[index].additionalLine;
        s.proposalScope[index].additionalLines.push(item);
        s.proposalScope[index].additionalLine = { additionalText: "", optionalCost: "" };
        setProposal(s);
    }
    const deleteProposalAdditionalLine = (scopeIndex, lineIndex) => {
        let s = { ...proposal }
        s.proposalScope[scopeIndex].additionalLines.splice(lineIndex, 1)
        setProposal(s)
    }


    const Validate = () => {
        if (!selectedUser) {
            setError("Select a BAENC Contact")
            return false;
        }
        if (!selectedLocation) {
            setError("Select a Customer Office Location")
            return false;
        }
        if (!selectedContact) {
            setError("Select a Company Contact")
            return false;
        }
        return true;
    }

    const addNote = () => {
        let s = { ...proposal }
        let x = {
            note: newNote,
            custom: true
        }
        s.notes.push(x)
        setProposal(s)
        setNewNote("")
    }

    const addAttachment = () => {
        let s = { ...proposal }
        let x = {
            ...selectedAttachment,
            default: false
        }
        s.attachments.push(x)
        setProposal(s)
        setSelectedAttachment(null)
    }

    const DeleteNote = (index) => {
        let s = { ...proposal }
        s.notes.splice(index, 1)
        setProposal(s)
    }

    const DeleteAttachment = (index) => {
        let s = { ...proposal }
        s.attachments.splice(index, 1)
        setProposal(s)
    }

    const changeProposal = (key, value) => {
        let s = { ...proposal }
        s[key] = value;
        setProposal(s)
    }

    const changeScope = (index, key, value) => {
        let s = { ...proposal }
        s.proposalScope[index][key] = value
        setProposal(s)
    }

    const BuildNewBody = () => {
        if (window.confirm("Warning: This will delete all body data and regenerate from database. Are you sure you want to do this?")) {
            RegenerateBody(props.jobId)
                .then(res => {
                    let s = { ...proposal }
                    delete s.notes
                    delete s.proposalScope
                    delete s.attachments
                    delete s.subject
                    setProposal({
                        ...s,
                        notes: res.notes,
                        proposalScope: res.proposalScope,
                        attachments: res.attachments,
                        subject: res.subject
                    })
                })
        }
    }

    const LoadProposalBuild = (id) => {
        GetBuildProposal(id)
            .then(res => {
                setHonorific(res.honorific)
                res.proposal.date = new Date(res.proposal.date)
                setAttachments(res.attachments)
                setProposal(res.proposal)
                setUserList(res.users)
                setContactList(res.contacts)
                setLocationList(res.locations)
            })
    }

    useEffect(() => {
        GetBuildProposal(props.jobId)
            .then(res => {
                setHonorific(res.honorific)
                res.proposal.date = new Date(res.proposal.date)
                setProposal(res.proposal)
                setAttachments(res.attachments)
                setUserList(res.users)
                setContactList(res.contacts)
                setLocationList(res.locations)
            })
    }, [props.jobId])

    useEffect(() => {
        let s = contactList.find(x => x.contactId === proposal.companyContact)
        setSelectedContact(s)
    }, [proposal.companyContact, contactList])

    useEffect(() => {
        let s = locationList.find(x => x.accountLocationId === proposal.companyLocation)
        setSelectedLocation(s)
    }, [proposal.companyLocation, locationList])

    useEffect(() => {
        let s = userList.find(x => x.userId === proposal.encContact)
        setSelectedUser(s)
    }, [proposal.encContact, userList])


    let history = useHistory();
    const BuildPdf = () => {
        if (!Validate()) {
            return;
        }
        let payload = {
            ...proposal,
            companyContact: selectedContact.contactId,
            companyLocation: selectedLocation.accountLocationId,
            encContact: selectedUser.userId,
            proposalScope: proposal.proposalScope.map((m, i) => { return { ...m, order: i } })
        }
        setGenerating(true)
        GeneratePDF(payload)
            .then(res => {
                setShow(true);
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(res, "proposal.pdf");
                }
                else {
                    var objectUrl = URL.createObjectURL(res);
                    window.open(objectUrl);
                }
                setGenerating(false);
                history.push(`${props.redirectUrl}`)
            })
            .catch(err => {
                setError("Failed to generate a proposal. Please try again.")
                setGenerating(false);
            })
    }

    const scopeJudgement = (item, index) => {
        if (item.editable) {
            return editableScope(item, index)
        }
        return lockedScope(item, index)
    }

    const moveUp = (i) => {
        let s = { ...proposal }
        if (i === 0) { return; }
        let first = s.proposalScope[i]
        let second = s.proposalScope[i - 1]
        s.proposalScope.splice(i - 1, 2, first, second)
        setProposal(s)
        resetScopeOrder();
    }

    const moveDown = (i) => {
        let s = { ...proposal }
        if (i === proposal.proposalScope.length - 1) { return; }
        let first = s.proposalScope[i + 1]
        let second = s.proposalScope[i]
        s.proposalScope.splice(i, 2, first, second);
        setProposal(s)
        resetScopeOrder();
    }

    const resetScopeOrder = () => {
        let s = { ...proposal };
        for (let i = 0; i < proposal.proposalScope.length; i++) {
            proposal.proposalScope[i].order = i;
        }
        setProposal(s);
    }

    const editSubLine = (scopeIndex, lineIndex, key, value) => {
        let s = { ...proposal }
        s.proposalScope[scopeIndex].additionalLines[lineIndex][key] = value;
        setProposal(s)
    }

    const editableScope = (item, index) => {
        return (
            <>
                <div className="divider my-3" />
                <div className="row">
                    <div className="col">
                        <small>Internal Scope Title</small>
                        <br />
                        {item.optional && "Optional"} {item.scopeName}
                    </div>
                    <div className="col-3 text-right">
                        <div className="toggle-switch small-switch">
                            <input
                                type="checkbox"
                                className="toggle-switch-checkbox"
                                id={`scopeitem-${index}`}
                                checked={item.descriptive}
                                onChange={() => changeScope(index, 'descriptive', !item.descriptive)}
                            />
                            <label className="toggle-switch-label" htmlFor={`scopeitem-${index}`}>
                                <span className={"toggle-switch-inner"} />
                                <span
                                    className={"toggle-switch-switch"}
                                />
                            </label>
                        </div>
                            &nbsp; &nbsp; Detailed Scope
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <small>Internal Description</small>
                        <br />
                        {item.internalDescription}
                    </div>
                    <div className="col-3 text-right">
                        <div className="toggle-switch small-switch">
                            <input
                                type="checkbox"
                                className="toggle-switch-checkbox"
                                id={`hideService-${index}`}
                                checked={item.hideService}
                                onChange={() => changeScope(index, 'hideService', !item.hideService)}
                            />
                            <label className="toggle-switch-label" htmlFor={`hideService-${index}`}>
                                <span className={"toggle-switch-inner"} />
                                <span
                                    className={"toggle-switch-switch"}
                                />
                            </label>
                        </div>
                            &nbsp; &nbsp; Hide Service
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <label>Title:</label>
                        <input required className="enc-input-100" value={item.title} onChange={e => changeScope(index, 'title', e.target.value)} />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <label>Description:</label>
                        <textarea required className="enc-textarea" value={item.description} onChange={e => changeScope(index, 'description', e.target.value)} />
                    </div>
                </div>

                <ListGroup>
                    <ListGroup.Item className="enc-data-list">
                        <div className="row">
                            <div className="col">
                                Additional Sublines
                            </div>
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item className="enc-data-list">
                        <div className="row">
                            <div className="col">
                                Text Line
                            </div>
                            <div className="col-2 text-right">
                                Optional Cost
                            </div>
                        </div>
                    </ListGroup.Item>

                    {item.additionalLines.map((m, i) => {
                        return (
                            <ListGroup.Item key={`AdditionalLine-${i}`}>
                                <div className="row">
                                    <div className="col-9">
                                        <input
                                            className="enc-input-100"
                                            value={m.additionalText}
                                            onChange={e => updateProposalAdditionalLines(index, i, 'additionalText', e.target.value)}
                                        />
                                    </div>
                                    <div className="col-2">
                                        <input
                                            className="enc-input-100"
                                            value={m.optionalCost}
                                            onChange={e => updateProposalAdditionalLines(index, i, 'optionalCost', e.target.value)}
                                        />
                                    </div>
                                    <div className="col-1">
                                        <FontAwesomeIcon icon={faTrash} className="clickable" onClick={() => deleteProposalAdditionalLine(index, i)} />
                                    </div>
                                </div>
                            </ListGroup.Item>
                        )
                    })}

                    <ListGroup.Item className="enc-data-list">
                        <>
                            <div className="row">
                                <div className="col">
                                    <input
                                        className="enc-input-100"
                                        value={item.additionalLine.additionalText}
                                        onChange={e => updateProposalAdditionalLine(index, "additionalText", e.target.value)}
                                    />
                                </div>
                                <div className="col-2">
                                    <input
                                        className="enc-input-100"
                                        value={item.additionalLine.optionalCost}
                                        onChange={e => updateProposalAdditionalLine(index, "optionalCost", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col text-right">
                                    <Button className="enc-button" onClick={() => addProposalAdditionalLine(index)}>Add Line</Button>
                                </div>
                            </div>
                        </>
                    </ListGroup.Item>
                </ListGroup>
            </>
        )
    }


    const lockedScope = (item, index) => {
        return (
            <>
                <div className="divider my-3" />

                <div className="row">
                    <div className="col">
                        <small>Internal Scope Title</small>
                        <br />
                        {item.optional && "Optional"} {item.scopeName}
                    </div>
                    <div className="col-3 text-right">
                        <div className="toggle-switch small-switch">
                            <input
                                type="checkbox"
                                className="toggle-switch-checkbox"
                                id={`scopeitem-${index}`}
                                checked={item.descriptive}
                                onChange={() => changeScope(index, 'descriptive', !item.descriptive)}
                            />
                            <label className="toggle-switch-label" htmlFor={`scopeitem-${index}`}>
                                <span className={"toggle-switch-inner"} />
                                <span
                                    className={"toggle-switch-switch"}
                                />
                            </label>
                        </div>
                            &nbsp; &nbsp; Detailed Scope
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <small>Internal Description</small>
                        <br />
                        {item.internalDescription}
                    </div>
                    <div className="col-3 text-right">
                        <div className="toggle-switch small-switch">
                            <input
                                type="checkbox"
                                className="toggle-switch-checkbox"
                                id={`hideService-${index}`}
                                checked={item.hideService}
                                onChange={() => changeScope(index, 'hideService', !item.hideService)}
                            />
                            <label className="toggle-switch-label" htmlFor={`hideService-${index}`}>
                                <span className={"toggle-switch-inner"} />
                                <span
                                    className={"toggle-switch-switch"}
                                />
                            </label>
                        </div>
                            &nbsp; &nbsp; Hide Service
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col">
                        <label>Title:</label>
                        {item.title}
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <label>Description:</label>
                        {item.description}
                    </div>
                </div>

                <ListGroup>
                    <ListGroup.Item className="enc-data-list">
                        <div className="row">
                            <div className="col">
                                Additional Sublines
                            </div>
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item className="enc-data-list">
                        <div className="row">
                            <div className="col">
                                Text Line
                            </div>
                            <div className="col-2 text-right">
                                Optional Cost
                            </div>
                        </div>
                    </ListGroup.Item>

                    {item.additionalLines.map((m, i) => {
                        return (
                            <ListGroup.Item key={`Additional-Line-2-${i}`}>
                                <div className="row">
                                    <div className="col-9">
                                        <input
                                            className="enc-input-100"
                                            value={m.additionalText}
                                            onChange={e => updateProposalAdditionalLines(index, i, 'additionalText', e.target.value)}
                                        />
                                    </div>
                                    <div className="col-2">
                                        <input
                                            className="enc-input-100"
                                            value={m.optionalCost}
                                            onChange={e => updateProposalAdditionalLines(index, i, 'optionalCost', e.target.value)}
                                        />
                                    </div>
                                    <div className="col-1">
                                        <FontAwesomeIcon icon={faTrash} className="clickable" onClick={() => deleteProposalAdditionalLine(index, i)} />
                                    </div>
                                </div>
                            </ListGroup.Item>
                        )
                    })}
                    <ListGroup.Item className="enc-data-list">
                        <>
                            <div className="row">
                                <div className="col">
                                    <input
                                        className="enc-input-100"
                                        value={item.additionalLine.additionalText}
                                        onChange={e => updateProposalAdditionalLine(index, "additionalText", e.target.value)}
                                    />
                                </div>
                                <div className="col-2">
                                    <input
                                        className="enc-input-100"
                                        value={item.additionalLine.optionalCost}
                                        onChange={e => updateProposalAdditionalLine(index, "optionalCost", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col text-right">
                                    <Button className="enc-button" onClick={() => addProposalAdditionalLine(index)}>Add Line</Button>
                                </div>
                            </div>
                        </>
                    </ListGroup.Item>
                </ListGroup>
            </>
        )
    }




    return (
        <div className="container">
            <form action="#" onSubmit={(e) => { e.preventDefault(); BuildPdf() }}>
                <div className="row">
                    <div className="col">
                        <small>Proposal Identifier Name</small>
                        <input
                            className="enc-input-100"
                            placeholder="Proposal-Name"
                            value={proposal.proposalName}
                            onChange={e => changeProposal('proposalName', e.target.value)} />
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <h3>Cover Letter</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-3">
                        Date:
                </div>
                    <div className="col">
                        <ReactDatePicker
                            className="enc-input-100"
                            selected={proposal.date}
                            onChange={(date) => changeProposal('date', date)}
                        />
                    </div>
                </div>
                <div className="row mb-1">
                    <div className="col-3">
                        <label>Subject: </label>
                    </div>
                    <div className="col">
                        {proposal.subject}
                    </div>
                </div>
                <div className="row mb-1">
                    <div className="col-3">
                        <label>BAENC Contact: </label>
                    </div>
                    <div className="col">
                        <DropdownList
                            data={userList}
                            textField='name'
                            value={selectedUser}
                            onChange={e => setSelectedUser(e)}
                        />
                    </div>
                </div>
                <div className="row mb-1">
                    <div className="col-3">
                        <label>Company Contact</label>
                    </div>
                    <div className="col-2">
                        <DropdownList
                            data={Honorific}
                            value={proposal.companyContactPronoun}
                            onChange={e => changeProposal('companyContactPronoun', e)}
                        />

                    </div>
                    <div className="col">
                        <DropdownList
                            data={contactList}
                            textField='contactName'
                            value={selectedContact}
                            onChange={e => setSelectedContact(e)}
                        />
                    </div>
                </div>
                <div className="row mb-1">
                    <div className="col-3">
                        Customer Office Location
                </div>
                    <div className="col">
                        <DropdownList
                            data={locationList}
                            textField="locationName"
                            value={selectedLocation}
                            onChange={e => setSelectedLocation(e)}
                        />
                    </div>
                </div>
                <div className="row mb-1">
                    <div className="col-3">
                        <label>Reference: </label>
                    </div>
                    <div className="col">
                        <textarea required className="enc-textarea" value={proposal.reference} onChange={e => changeProposal('reference', e.target.value)} />
                    </div>
                </div>
                <div className="row mb-1">
                    <div className="col-3">
                        <label>Intro: </label>
                    </div>
                    <div className="col">
                        <textarea required className="enc-textarea" value={proposal.introParagraph} onChange={e => changeProposal('introParagraph', e.target.value)} />
                    </div>
                </div>
                <div className="row mb-1">
                    <div className="col">
                        <Accordion>
                            <Card style={{ overflow: "visible" }}>
                                <Accordion.Toggle as={Card.Header} eventKey="0">
                                    Summary And/Or Module
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <ListGroup>
                                            <ListGroup.Item className="enc-list">
                                                Scope Items
                                            </ListGroup.Item>
                                            <ListGroup.Item className="enc-data-list">
                                                <div className="row">
                                                    <div className="col-1">
                                                        #
                                                        </div>
                                                    <div className="col-4">
                                                        Scope Name
                                                        </div>
                                                    <div className="col-4">
                                                        Proposal Title
                                                        </div>
                                                    <div className="col-3">
                                                        Operator
                                                        </div>
                                                </div>
                                            </ListGroup.Item>
                                            {proposal.proposalScope.map((m, i) => {
                                                return (
                                                    <ListGroup.Item key={`summ-${i}`}>
                                                        <div className="row">
                                                            <div className="col-1">
                                                                <div className="row">
                                                                    <div className="col text-center">
                                                                        {i !== 0 && <FontAwesomeIcon onClick={() => moveUp(i)} className="clickable" icon={faArrowUp} />}
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col text-center">
                                                                        <strong>{i + 1}</strong>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col text-center">
                                                                        {i !== proposal.proposalScope.length - 1 && <FontAwesomeIcon onClick={() => moveDown(i)} className="clickable" icon={faArrowDown} />}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-4">
                                                                {m.optional && "Optional"} {m.scopeName}
                                                            </div>
                                                            <div className="col-4">
                                                                {m.title}
                                                            </div>
                                                            <div className="col-3">
                                                                <DropdownList
                                                                    data={options}
                                                                    value={m.followOperator}
                                                                    placeholder="Follow Operator"
                                                                    onChange={e => changeFollow(i, 'followOperator', e)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </ListGroup.Item>
                                                )
                                            })}
                                        </ListGroup>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <h4>Body</h4>
                    </div>
                    <div className="col-3 text-right">
                        <Button className="enc-button" onClick={BuildNewBody}>Re-Build Body from Scope</Button>
                    </div>
                </div>
                {proposal.proposalScope.map((m, index) =>
                    <div key={`scope-${index}`}>
                        {scopeJudgement(m, index)}
                    </div>
                )}
                <div className="row mt-1">
                    <div className="col-3">
                        <label>Notes: </label>
                    </div>
                </div>
                <ListGroup>
                    {proposal.notes.map((m, i) => {
                        return (
                            <ListGroup.Item key={`Note-${i}`}>
                                <div className="row">
                                    <div className="col-11">
                                        {m.note}
                                    </div>
                                    <div className='col-1'>
                                        {m.custom && <FontAwesomeIcon icon={faTrash} className="clickable" onClick={() => DeleteNote(i)} />}
                                    </div>
                                </div>
                            </ListGroup.Item>
                        )
                    })}
                </ListGroup>
                <div className="row">
                    <div className="col">
                        <h4>Additional Notes</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <input className="enc-input-100" value={newNote} onChange={e => setNewNote(e.target.value)} />
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col text-right">
                        <Button className="enc-button" onClick={addNote}>Add Additional Note</Button>
                    </div>
                </div>

                <div className="row mt-1">
                    <div className="col-3">
                        <label>Attachments: </label>
                    </div>
                </div>
                <ListGroup>
                    {proposal.attachments.map((m, i) => {
                        return (
                            <ListGroup.Item key={`Note-${i}`}>
                                <div className="row">
                                    <div className="col-11">
                                        {m.attachmentName}
                                    </div>
                                    <div className='col-1'>
                                        {!m.default && <FontAwesomeIcon icon={faTrash} className="clickable" onClick={() => DeleteAttachment(i)} />}
                                    </div>
                                </div>
                            </ListGroup.Item>
                        )
                    })}
                </ListGroup>
                <div className="row">
                    <div className="col">
                        <h4>Add Attachments</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <DropdownList
                            data={attachments.filter(attachment => !proposal.attachments.find(({ prefillAttachmentId }) => prefillAttachmentId === attachment.prefillAttachmentId))}
                            textField="attachmentName"
                            value={selectedAttachment}
                            onChange={e => setSelectedAttachment(e)}
                        />
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col text-right">
                        <Button className="enc-button" onClick={addAttachment}>Add Attachment</Button>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className='col'>
                        <Alert variant="success" show={show} onClose={() => setShow(false)} dismissible>
                            <Alert.Heading>Successfully Saved Proposal</Alert.Heading>
                        </Alert>
                        <Alert variant="danger" show={error !== ""} onClose={() => setError("")} dismissible>
                            <Alert.Heading>{error}</Alert.Heading>
                        </Alert>
                    </div>
                    <div className="col text-right">
                        <Button variant="secondary" as={Link} to={`${props.redirectUrl}`}>Back</Button>
                        <Button className="ml-2" variant="success" type='submit' disabled={generating}>
                            {generating ? <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            /> : "Generate Proposal"}</Button>
                        <Button className="enc-button ml-2" onClick={saveProposal}>Save Proposal</Button>
                    </div>
                </div>
            </form>
        </div>
    )
}
