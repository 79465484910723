import React, { useEffect, useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { YardList } from '../API/Inventory';

export default props => {
    const [yards, setYards] = useState([])

    useEffect(() => {
        LoadYardList();
    }, [])

    const LoadYardList = () => {
        YardList()
            .then(res => {
                setYards(res.data)
            })
    }
    return (
        <>
            <div className="row">
                <div className="col">
                    <ListGroup>
                        <ListGroup.Item className="enc-data-list">
                            Yard List
                        </ListGroup.Item>
                        <ListGroup.Item className="enc-data-list">
                            <div className="row">
                                <div className="col">
                                    Yard Name
                            </div>
                                <div className="col">
                                    Office Location
                            </div>
                            </div>
                        </ListGroup.Item>
                        {yards.map(y => {
                            return (
                                <ListGroup.Item as={Link} key={`${y.yardId}-yard`} to={`${props.match.url}/${y.yardId}`} className="click-list">
                                    <div className="row">
                                        <div className="col">
                                            {y.yardName}
                                        </div>
                                        <div className="col">
                                            {y.officeName}
                                        </div>
                                    </div>
                                </ListGroup.Item>
                            )
                        })}
                    </ListGroup>
                </div>
            </div>
        </>
    )
}
