import React, { useEffect, useState } from 'react';
import { Nav } from 'react-bootstrap';
import { Switch, Route, NavLink } from 'react-router-dom';

import { GetJobFormsAdminNav, GetAdminJobList } from '../../API/JobForm';
import FieldTicket from '../../Jobs/InProgress/FieldTicket';
import EquipmentChecklist from '../../Jobs/InProgress/EquipmentChecklist';
import JSA from '../../Jobs/InProgress/JSA';
import AdminFieldTicket from './AdminFieldTicket';
import AdminJSA from './AdminJSA';
import { DropdownList } from 'react-widgets';
import AdminEquipment from './AdminEquipment';


export default props => {
    const [nav, setNav] = useState([])
    const [jobId, setJobId] = useState(0)
    const [jobs, setJobs] = useState([])
    // const [jobStepId, setJobStepId] = useState(0)
    useEffect(()=>{
        GetJobFormsAdminNav()
        .then(res => setNav(res.navigationItems))
        GetAdminJobList()
        .then(res =>{
            
            res.data.unshift({jobId: 0, jobNumber: "Select", jobName:" a job" })
            setJobs(res.data)
        })
    },[])

    return (
        <>
            <Nav variant="tabs" className="my-2">
                {nav.map((m,i)=> {return(
                <Nav.Item key={`nav-${i}`}>
                    <Nav.Link eventKey={i} as={NavLink} to={`${props.match.url}${m.url}`}>{m.label}</Nav.Link>
                </Nav.Item>
                )})}
            </Nav>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <label>Select a Job: </label> 
                    </div>
                    <div className="col">
                        <DropdownList
                        data={jobs}
                        value={jobId}
                        valueField='jobId'
                        textField={(e) => `${e.jobNumber} ${e.jobName}`}
                        onChange={e => {setJobId(e.jobId);}}
                        //the above line is the issue. e.jobStepId is always set to 0 in this manner witch will not return any results.
                        filter="contains"
                        />
                    </div>
                </div>
            </div>
            <>
            {
                <Switch>
                    <Route path={`${props.match.url}/FieldTickets/:id`} render={(RouteProps) => <FieldTicket preurl={props.url} jobId={jobId} {...RouteProps} />} />
                    <Route path={`${props.match.url}/FieldTickets/`} render={(RouteProps) => <AdminFieldTicket jobId={jobId} {...RouteProps} />} />
                    <Route path={`${props.match.url}/EquipmentForms/:id`} render={(RouteProps) => 
                        <EquipmentChecklist jobId={jobId} {...RouteProps} EquipmentFormId={RouteProps.match.params.id} />} />
                    <Route path={`${props.match.url}/EquipmentForms/`} render={(RouteProps) => <AdminEquipment jobId={jobId} {...RouteProps} />} />
                    <Route path={`${props.match.url}/JSA/:id`} render={(RouteProps) => <JSA jobId={jobId} {...RouteProps} />} />
                    <Route path={`${props.match.url}/JSA/`} render={(RouteProps) => <AdminJSA jobId={jobId}  {...RouteProps} />} />
                </Switch>
            }
            </>
        </>
    )
}