import APIRequest, { APIFileUpload, APIGenericFile } from './MainAPICall/api';
import config from '../APIConfig';

export const GetSelf = () => {
    return APIRequest({
        url: config.baseURL + "/User/GetSelfInfo",
        method: "get",
    });
}

export const GetUser = (id) => {
    return APIRequest({
        url: config.baseURL + "/User/GetUser",
        method: "post",
        body: {
            userId: id
        }
    });
}

export const ChangePassword = (payload) => {
    return APIRequest({
        url: config.baseURL + "/User/ChangePassword",
        method: "post",
        body: payload
    })
}

export const CheckPhonePermission = () => {
    return APIRequest({
        url: config.baseURL + "/User/CheckPhonePermission",
        method: "get"
    })
}

export const SavePhoneNumber = (payload) => {
    return APIRequest({
        url: config.baseURL + "/User/SavePhoneNumber",
        method: "post",
        body: payload
    })
}

export const CheckEmailPermission = () => {
    return APIRequest({
        url: config.baseURL + "/User/CheckEmailPermission",
        method: "get"
    })
}

export const SaveEmail = (payload) => {
    return APIRequest({
        url: config.baseURL + "/User/SaveEmail",
        method: "post",
        body: payload
    })
}

export const CreateUser = (payload) => {
    return APIRequest({
        url: config.baseURL + "/User/CreateUser",
        method: "post",
        body: payload
    })
}

export const EditUser = (payload) => {
    return APIRequest({
        url: config.baseURL + "/User/EditUser",
        method: "Post",
        body: payload
    })
}

export const GetPhoneTypes = () => {
    return APIRequest({
        url: config.baseURL + "/User/GetPhoneTypes",
        method: "get"
    })
}

export const ResetPasswordByUsername = (Username) => {
    return APIRequest({
        url: config.baseURL + "/User/ResetPasswordByUsername",
        method: "post",
        body: {
            Username: Username
        }
    })
}

export const ResetPasswordById = (id) => {
    return APIRequest({
        url: config.baseURL + "/User/ResetPasswordById",
        method: "post",
        body: {
            userId: id
        }
    })
}

export const ResetPasswordLoggedIn = () => {
    return APIRequest({
        url: config.baseURL + "/User/ResetPasswordLoggedIn",
        method: "get"
    })
}

export const GetUserList = () => {
    return APIRequest({
        url: config.baseURL + "/User/UserList",
        method: "get"
    })
}

export const PostUserSignature = (payload) => {
    return APIFileUpload({
        url: config.baseURL + "/User/UserSignature",
        method: "Post",
        body: payload
    })
}

export const GetUserSignature = (id) => {
    return APIFileUpload({
        url: config.baseURL + `/User/UserSignature?userId=${id}`,
        method: "get"
    })
}

export const SelfCheck = () => {
    return APIRequest({
        url: config.baseURL + `/User/SelfCheck`,
        method: "get"
    })
}

export const SendReportBox = (payload) => {
    return APIRequest({
        url: config.baseURL + "/User/SendReportBox",
        method: "post",
        body: payload
    })
}

// user document
export const GetUserDocument = (id) => {
    return APIGenericFile({
        url: config.baseURL + `/User/UserDocument?UserDocumentId=${id}`,
        method: "get"
    });
}

export const SaveUserDocument = (payload) => {
    return APIRequest({
        url: config.baseURL + "/User/SaveUserDocument",
        method: "post",
        body: payload
    });
}

export const DeleteUserDocument = (id) => {
    return APIRequest({
        url: config.baseURL + "/User/DeleteUserDocument",
        method: "post",
        body: {
            id: id
        }
    });
}

export const GetUserDocumentList = () => {
    return APIRequest({
        url: config.baseURL + "/User/GetUserDocumentList",
        method: "get"
    });
}
