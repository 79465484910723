import React from 'react';
import { ListGroup } from 'react-bootstrap';

export default ({ticketItems, ChangeSelectedAmount, SelectMax, ...props}) => {
    return (
        <div>
            {ticketItems.length > 0 &&
                <ListGroup className="mt-2">
                    <ListGroup.Item className="enc-data-list">
                        <div className="row">
                            <div className="col">
                                Shop Ticket Items
                                </div>
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item className="enc-data-list">
                        <div className="row">
                            <div className="col">
                                Item Name
                        </div>
                            <div className="col">
                                Ticket Total
                        </div>
                            <div className="col">
                                Amount Remaining
                            </div>
                            <div className="col">
                                Amount Completed
                            </div>
                        </div>
                    </ListGroup.Item>
                    {ticketItems.map((m, i) =>
                        <ListGroup.Item key={`st-item-${i}`}>
                            <div className="row">
                                <div className="col">
                                    {m.baseItem}
                                </div>
                                <div className="col">
                                    {m.amount}
                                </div>
                                <div className="col">
                                    {m.amount - m.amountCompleted}
                                </div>
                                <div className="col">

                                </div>
                            </div>
                        </ListGroup.Item>
                    )}
                </ListGroup>
            }
        </div>
    )
}
