import React, { useEffect, useState } from 'react';
import { useHistory, Link, NavLink } from 'react-router-dom';
import { ListGroup, Button, Nav } from 'react-bootstrap';
import { CheckEditOpportunity, GetOpportunityList, GetOpportunityNavigation } from '../API/Opportunity';

export default props => {
    const [opportunities, setOpportunities] = useState([]);
    const [filter, setFilter] = useState("");
    const [closedOpportunities, setClosedOpportunities] = useState(false);
    const [editOpportunity, setEditOpportunity] = useState(false);


    useEffect(() => {
        LoadOpportunities(closedOpportunities)
    }, [closedOpportunities])

    const [nav, setNav] = useState([]);
    useEffect(() => {
        GetOpportunityNavigation()
            .then(res => {
                setNav(res.data)
            })

        CheckEditOpportunity()
        .then(res => {
            setEditOpportunity(res.success)
        })
    }, [])

    const LoadOpportunities = (closed) => {
        GetOpportunityList(closed)
            .then(res => {
                setOpportunities(res.data)
            })
    }
    const history = useHistory();
    const navigateToOpportunity = (id) => {
        history.push(`/Opportunities/${id}`)
    }
    return (
        <>
            <Nav variant="tabs" className="my-2">
                {nav.map((m, i) => {
                    return (
                        <Nav.Item key={i}>
                            <Nav.Link exact={true} eventKey={i} as={NavLink} to={`${props.match.url}${m.url}`}>{m.label}</Nav.Link>
                        </Nav.Item>
                    )
                })}
            </Nav>
            <div className="container m-auto">
                <div className="row my-2">
                    <div className="col-4">
                        {editOpportunity && <Button className="enc-button" as={Link} to={`/Opportunities/NewOpportunity`}>Create Opportunity</Button>}
                    </div>
                    <div className="col-4 text-right">
                        <div className="toggle-switch small-switch">
                            <input
                                type="checkbox"
                                className="toggle-switch-checkbox"
                                id="disabled"
                                checked={closedOpportunities}
                                onChange={() => setClosedOpportunities(!closedOpportunities)}
                            />
                            <label className="toggle-switch-label" htmlFor="disabled">
                                <span className={"toggle-switch-inner"} />
                                <span
                                    className={"toggle-switch-switch"}
                                />
                            </label>
                        </div>
                    &nbsp; &nbsp;Show Closed Opportunities
                </div>
                    <div className="col-4 text-right">
                        <input placeholder="Account & Opportunity Name Filter" className="enc-input-100" value={filter} onChange={(e) => { setFilter(e.target.value) }} />
                    </div>

                </div>
                <div>
                    <ListGroup>
                        <ListGroup.Item className="enc-data-list">
                            Opportunity List
                        </ListGroup.Item>
                        <ListGroup.Item className="enc-data-list">
                            <div className="row">
                                <div className="col-lg-4">
                                    Opportunity Name
                                </div>
                                <div className="col-lg-4">
                                    Description
                                </div>
                                <div className="col-lg-4">
                                    Account Name
                                </div>
                            </div>
                        </ListGroup.Item>
                        {opportunities.length === 0 && <ListGroup.Item> No Opportunities to Display </ListGroup.Item>}
                        {opportunities.filter(({ account, opportunity }) => opportunity.opportunityName.toLowerCase().search(filter.toLowerCase()) !== -1 || account.accountName.toLowerCase().search(filter.toLowerCase()) !== -1)
                        .map(({ opportunity, account }, m, i) =>

                            <ListGroup.Item key={`LGI-${opportunity.opportunityId}`} onClick={() => navigateToOpportunity(opportunity.opportunityId)} className="click-list">
                                <div className="row">
                                    <div className="col-lg-4">
                                        {opportunity.opportunityName}
                                    </div>
                                    <div className="col-lg-4">
                                        {opportunity.opportunityDescription}
                                    </div>
                                    <div className="col-lg-4">
                                        {account.accountName}
                                    </div>
                                </div>
                            </ListGroup.Item>)}
                    </ListGroup>
                </div>
            </div>
        </>
    )
}
