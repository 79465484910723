import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { CheckEditOpportunity, GetOpportunityProfile } from '../API/Opportunity';
import JobRouter from '../Jobs/JobRouter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { ListGroup } from 'react-bootstrap';

export default props => {
    const [opportunity, setOpportunity] = useState({
        "opportunityId": 0,
        "opportunityName": "",
        "opportunityDescription": "",
        "gpsLatitude": "",
        "gpsLongitude": "",
        "lsd": "",
        "typeOfSite": "",
        "officeId": 0,
        "accountId": 0,
        "locationId": 0,
        "pointOfContactId": 0,
        "closed": true
    });

    const [account, setAccount] = useState({
        "accountId": 0,
        "accountName": "",
        "accountNumber": "",
        "enabled": true,
    });

    const [billingLocation, setBillingLocation] = useState({
        "accountLocationId": 0,
        "accountId": 0,
        "locationName": "",
        "billingAddress1": "",
        "billingAddress2": "",
        "billingCity": "",
        "billingState": "",
        "billingZip": "",
        "shippingAddress1": "",
        "shippingAddress2": "",
        "shippingCity": "",
        "shippingState": "",
        "shippingZip": "",
        "enabled": true
    })

    const [contact, setContact] = useState({
        "accountContactId": 0,
        "accountId": 0,
        "fullName": "",
        "firstName": "",
        "lastName": "",
        "phoneNumber": "",
        "email": "",
        "enabled": true
    });

    const [editOpportunity, setEditOpportunity] = useState(false);

    useEffect(() => {
        LoadProfile(parseInt(props.match.params.oppId))
    }, [props.match.params.oppId])

    useEffect(() => {
        CheckEditOpportunity()
        .then(res => {
            setEditOpportunity(res.success)
        })
    })

    const LoadProfile = (id) => {
        GetOpportunityProfile(id)
            .then(res => {
                setOpportunity(res.opportunity);
                setAccount(res.account);
                setContact(res.contact);
                setBillingLocation(res.billingLocation);

            })
    }

    const history = useHistory();

    return (
        <>
            <div className="container-fluid">

                <div className="row mt-2">
                    <div className="col">
                        <a href={`/Accounts/${account.accountId}`} rel="noreferrer noopener" style={{ color: 'black', textDecoration: 'underline' }}>
                            <h2>{account.accountName}</h2>
                        </a>
                    </div>
                    <div className="col">
                        {account.accountNumber && `Account Number: ${account.accountNumber}`}
                    </div>
                    <div className="col-1 text-right">
                        {editOpportunity && <FontAwesomeIcon
                            icon={faPencilAlt}
                            className="clickable"
                            onClick={() => history.push(`/Opportunities/${opportunity.opportunityId}/Edit`)}
                        />}
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <ListGroup>
                            <ListGroup.Item className="enc-list">
                                Billing Information
                        </ListGroup.Item>
                            <ListGroup.Item className="enc-list">
                                <div className="row">
                                    <div className="col">
                                        {billingLocation.locationName}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        {billingLocation.billingAddress1}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        {billingLocation.billingAddress2}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        {`${billingLocation.billingCity ? `${billingLocation.billingCity},` : ""} ${billingLocation.billingState} ${billingLocation.billingZip}`}
                                    </div>
                                </div>
                            </ListGroup.Item>
                        </ListGroup>
                    </div>



                    <div className="col-4">
                        <ListGroup>
                            <ListGroup.Item className="enc-list">
                                Opportunity Information
                        </ListGroup.Item>
                            <ListGroup.Item className="enc-list">
                                <div className="row">
                                    <div className="col-6">
                                        {opportunity.typeOfSite &&
                                            <>
                                                <small>Site Type</small>
                                                <br />
                                                <span>{opportunity.typeOfSite}</span>
                                            </>
                                        }
                                    </div>
                                    <div className="col-6">
                                        {opportunity.estimatedStart &&
                                            <>
                                                <small>Estimated Start</small>
                                                <br />
                                                {opportunity.estimatedStart}
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        {opportunity.lsd &&
                                            <>
                                                <small>LSD</small>
                                                <br />
                                                {opportunity.lsd}
                                            </>
                                        }
                                    </div>
                                    <div className="col-6">
                                        {opportunity.duration &&
                                            <>
                                                <small>Duration</small>
                                                {opportunity.duration}
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        {opportunity.gpsLatitude &&
                                            <>
                                                <small>Latitude</small>
                                                <br />
                                                {opportunity.gpsLatitude}
                                            </>
                                        }
                                    </div>
                                    <div className="col-6">
                                        {opportunity.gpsLongitude &&
                                            <>
                                                <small>Longitude</small>
                                                <br/>
                                                {opportunity.gpsLongitude}
                                            </>
                                        }
                                    </div>
                                </div>
                            </ListGroup.Item>
                        </ListGroup>
                    </div>

                    <div className="col-4">
                        <ListGroup>
                            <ListGroup.Item className="enc-list">
                                Contact Information
                        </ListGroup.Item>
                            <ListGroup.Item className="enc-list">
                                <div className="row">
                                    <div className="col">
                                        {contact.fullName}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        {contact.phoneNumber}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        {contact.email}
                                    </div>
                                </div>
                            </ListGroup.Item>
                        </ListGroup>
                    </div>


                </div>
            </div>
            <JobRouter {...props} />
        </>
    )
}
