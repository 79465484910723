import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, ListGroup } from 'react-bootstrap';
import { GetSurveyFormList, CreateSurveyForm } from '../../../API/SurveyForm';

export default (props) => {
    const [SurveyFormList, setSurveyFormList] = useState([]);
    
    useEffect(() => {
        loadSurveyForms(props.jobId);
    }, [props.jobId])
    
    const loadSurveyForms = (jobId) => {
        GetSurveyFormList(parseInt(jobId))
            .then(res => {
                if (res.success) {
                    setSurveyFormList(res.data);
                } else {
                    console.log(res.message);
                }
            })
    }

    const history = useHistory();
    const navigateToSurveyForm = (surveyFormId) => {
        history.push(`${props.match.url}/${surveyFormId}`);
    }

    const addSurveyForm = () => {
        CreateSurveyForm(parseInt(props.jobId))
            .then(res => {
                if (res.success) {
                    history.push(`${props.match.url}/${res.data.surveyFormId}`);
                } else {
                    console.log(res.message);
                }
            })
    }

    return (
        <div className="container">
            <div className="row my-3">
                <div className="col text-center">
                    <Button className="enc-button" onClick={() => addSurveyForm()}>Create Survey Form</Button>
                </div>
            </div> 
            <ListGroup variant="flush">
                <ListGroup.Item className="click-list">
                    <div className="row">
                        <div className="col">
                            Company
                        </div>
                        <div className="col">
                            Site
                        </div>
                        <div className="col">
                            Submission Date
                        </div>
                    </div>
                </ListGroup.Item>
                {SurveyFormList.map(sf =>
                    <ListGroup.Item onClick={() => navigateToSurveyForm(sf.surveyFormId)} className="click-list" key={sf.surveyFormId}>
                        <div className="row">
                            <div className="col">
                                {sf.company}
                            </div>
                            <div className="col">
                                {sf.site}
                            </div>
                            <div className="col">
                                {new Date(sf.submissionDate).toLocaleDateString()}
                            </div>
                        </div>
                    </ListGroup.Item>
                )}
            </ListGroup>
        </div>
    )
}


