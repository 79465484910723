import React, { useState, useEffect, useRef } from 'react';
import { SendReportBox } from '../API/User'
import { useHistory, useLocation } from 'react-router-dom';
import { browserName } from 'react-device-detect';
import { Modal, Button } from 'react-bootstrap';

export default (props) => {
    const location = useLocation(); //location.pathname

    //useEffect(() => {
    //    console.log("report box")
    //    console.log(window);
    //    console.log(browserName);
    //}, [])

    const [openBoxModal, setOpenBoxModal] = useState(false);

    const [description, setDescription] = useState("");
    const [error, setError] = useState("");

    const submitReport = () => {
        setError("");

        let payload = {
            description: description,
            locationPath: location.pathname,
            browser: browserName
        };

        SendReportBox(payload).then((res) => {
            //console.log("report box");
            //console.log(res);

            setError(res.message);
            if (res.success) {
                setDescription("");
                setOpenBoxModal(false);
            }
        }).catch((e) => {
            console.log(e);
            setError("An error has occurred");
        });
    };

    const openModal = () => {
        setDescription("");
        setError("");
        setOpenBoxModal(true);
    }

    const boxModal = () => {
        return (
            <Modal show={openBoxModal} onHide={() => setOpenBoxModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Suggestion & Bug Report</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>
                        <input type="text" className="enc-input-100" name="description"
                            placeholder="Description" value={description}
                            onChange={(e) => setDescription(e.target.value)} required />
                    </p>

                    <p>
                        {error !== "" && error}
                    </p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setOpenBoxModal(false)}>Close</Button>
                    <Button variant="primary" onClick={submitReport}>Submit</Button>
                </Modal.Footer>
            </Modal>
        )
    };

    //return (
    //    <table className="table container-fluid mb-2">
    //        <div className="row">
    //            <div className="col-2 my-auto text-center">
    //                <h6>Suggestion & Bug Report:</h6>
    //            </div>
    //            <div className="col-9 my-auto text-center">
    //                <input type="text" className="enc-input-100" name="description" placeholder="Description"
    //                    value={description} onChange={(e) => setDescription(e.target.value)}
    //                    required />
    //            </div>
    //            <div className="col-1 my-auto text-center">
    //                <input type="submit" value="Submit" className="enc-button" onClick={submitReport} />
    //            </div>
    //        </div>
    //        {error !== "" && (
    //            <div className="row">
    //                <div className="col my-auto text-center">
    //                    <div>{error}</div>
    //                </div>
    //            </div>
    //        )}
    //    </table>
    //)

    return (
        <table className="table container-fluid mb-2">
            <div className="row">
                <div className="col-2 my-auto text-center">
                </div>
                <div className="col-8 my-auto text-center">
                    {boxModal()}
                </div>
                <div className="col-2 my-auto text-center">
                    <button type='button' className="enc-button" onClick={() => openModal()}>Bug Report</button>
                </div>
            </div>
        </table>
    )
}