import APIRequest from './MainAPICall/api';
import config from '../APIConfig';

export const GetSurveyMeasurementList = (id) => {
    return APIRequest({
        url: config.baseURL + "/SurveyMeasurement/GetSurveyMeasurementList",
        method: "post",
        body: { id: id }
    });
}

export const GetSurveyMeasurement = (id) => {
    return APIRequest({
        url: config.baseURL + "/SurveyMeasurement/GetSurveyMeasurement",
        method: "post",
        body: { id: id }
    });
}

export const CreateSurveyMeasurement = (id) => {
    return APIRequest({
        url: config.baseURL + "/SurveyMeasurement/CreateSurveyMeasurement",
        method: "post",
        body: { id: id }
    });
}

export const SaveSurveyMeasurement = (payload) => {
    return APIRequest({
        url: config.baseURL + "/SurveyMeasurement/SaveSurveyMeasurement",
        method: "post",
        body: payload
    });
}

export const DeleteSurveyMeasurement = (id) => {
    return APIRequest({
        url: config.baseURL + "/SurveyMeasurement/DeleteSurveyMeasurement",
        method: "post",
        body: { id: id }
    });
}