import React, { useState, useEffect } from 'react';
import { CheckEditFieldTicket } from '../../API/JobForm';
import ViewFieldTicket from './ViewFieldTicket';
import FieldTicket from './FieldTicket';

export default props => {
    const [edit, setEdit] = useState(false);
    useEffect(()=>{
        LoadPage(props.match.params.id)
    },[props.match.params.id])
    const LoadPage = (arg) => {
        let id = arg;
        if(id === 'new'){
            id = 0;
        }
        CheckEditFieldTicket(id)
        .then(res=>{
            setEdit(res.success)
        })
    }
    return (
        <>
            {edit?<FieldTicket stepId={props.stepId} lp={(id) => id?LoadPage(id):LoadPage(props.match.params.id)} {...props} />:<ViewFieldTicket {...props} />}
        </>
    )
}
