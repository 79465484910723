import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function EditGrid({ data = [], headers = [], headerName = "", clickRow = false, url="/", urlId="id", sortBy = {sortKey: "", sortDesc: true}, sortCallBack = ()=>{}}) {
    const HeaderClick = (key) => {
        let sort = { ...sortBy }
        if (sort.sortKey === key) {
            sort.sortDesc = !sort.sortDesc
        } else {
            sort.sortKey = key
            sort.sortDesc = true
        }
        let s = [...data]
        function compare(a, b) {
            if (a[sort.sortKey] < b[sort.sortKey]) {
                return -1;
            }
            if (a[sort.sortKey] > b[sort.sortKey]) {
                return 1;
            }
            return 0;
        }
        s = s.sort(compare);
        if (sort.sortDesc) {
            s.reverse()
        }
        sortCallBack(s, sort)
    }




    return (
        <div className="container-fluid my-2">
            <ListGroup>
                <ListGroup.Item className="enc-data-list">
                    {headerName ? headerName : "Data Grid"}
                </ListGroup.Item>
                <ListGroup.Item className="enc-data-list">
                    <div className="row">
                        {headers.map((m, i) => {
                            return (
                                <div className={`col clickable`} key={`header-${i}`} onClick={() => HeaderClick(m.key)}>
                                    {m.name} {m.key === sortBy.sortKey && (sortBy.sortDesc ? <FontAwesomeIcon icon={faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} />)}
                                </div>
                            )
                        })}
                    </div>
                </ListGroup.Item>
                {data.map((m, i) => {
                    let AdditionalParams = {}
                    if(clickRow){
                        AdditionalParams["as"] = Link
                        AdditionalParams["to"] = `${url}/${m[urlId]}`
                        AdditionalParams["className"] = "click-list"
                    }
                    
                    return (
                        <ListGroup.Item key={`SDG-${i}`} {...AdditionalParams} > 
                            <div className="row">
                                {headers.map((h, hi) => {
                                    return (
                                        <div className="col" key={`SDG-DC-${hi}`}>
                                            {h.input ? <input className="enc-input-100" value={m[h.key]} onChange={e => h.changeFunction(i, e.target.value)} /> : m[h.key]}
                                        </div>
                                    )
                                })}
                            </div>
                        </ListGroup.Item>
                    )
                })}
            </ListGroup>
        </div>
    )
}

export default EditGrid