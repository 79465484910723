import React, { useState, useEffect } from 'react';
import { ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { GetJobReadyItemList } from '../../API/JobReadyInventory';

export default props => {
    const [JobReadyInventory, setJobReadyInventory] = useState([]);

    useEffect(()=>{
        LoadJobReadyInventoryList()
    },[])

    const LoadJobReadyInventoryList = () => {
        GetJobReadyItemList()
        .then(res => {
            setJobReadyInventory(res.data)
        })
    }

    return (
        <div className="container">
            <div className="row mt-2">
                <div className="col">
                    <ListGroup>
                        <ListGroup.Item className="click-list">
                            <div className="row">
                                <div className="col">
                                    Shop Ticket Configuration - Job Ready Item List
                                </div>
                            </div>
                        </ListGroup.Item>
                        {JobReadyInventory.map((j, i) => {
                            return (
                                <ListGroup.Item key={`JobReady-${i}`} as={Link} to={`${props.match.url}/${j.jobReadyItemId}`} className="click-list">
                                    <div className="row">
                                        <div className="col">
                                            {j.name}
                                        </div>
                                    </div>
                                </ListGroup.Item>
                            )
                        })}
                    </ListGroup>
                </div>
            </div>
        </div>
    )
}
