import APIRequest from './MainAPICall/api';
import config from '../APIConfig';

export const SaveAnnouncement = (payload) => {
    return APIRequest({
        url: config.baseURL + "/Announcement/SaveAnnouncement",
        method: "post",
        body: payload
    });
}

export const DeleteAnnouncement = (payload) => {
    return APIRequest({
        url: config.baseURL + "/Announcement/DeleteAnnouncement",
        method: "post",
        body: payload
    });
}


export const GetAnnouncementsList = (payload) => {
    return APIRequest({
        url: config.baseURL + "/Announcement/GetAnnouncmentsList",
        method: "post",
        body: payload
    });
}


export const GetAnnouncement = (payload) => {
    return APIRequest({
        url: config.baseURL + "/Announcement/GetAnnouncement",
        method: "post",
        body: payload
    });
}

