import React from 'react';
import { Button } from 'react-bootstrap';
import { OperationsRejection, OperationsApproval } from '../../API/JobApproval';

export default ({jobId, rl, approval}) => {
    const Approve = () => {
        OperationsApproval(parseInt(jobId))
            .then(res => {
                if (res.success) {
                    rl()
                }
            })
    }

    const Reject = () => {
        OperationsRejection(parseInt(jobId))
            .then(res => {
                if (res.success) {
                    rl()
                }
            })
    }
    return (
        <div>
            <div className="row">
                <div className="col">
                    <h4>Operations Approval</h4>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    {approval.jobApprovalId !== 0 ? `Approved by: ${approval.approver} on ${approval.approvalDate}` : "No Approval"}
                </div>
            </div>
            {approval.edit &&
                <div className="row">
                    <div className="col text-right">
                        <Button className="enc-button mr-1" onClick={Reject}>Reject</Button>
                        <Button className="enc-button" onClick={Approve}>Approve</Button>
                    </div>
                </div>
            }
        </div>
    )
}