import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Header from './Main/Header'
import Footer from './Main/Footer'
import NavigationRouting from './NavigationRouting';

import VerifyTimeSheet from './TimeSheets/VerifyTimeSheet';

export default (props) => {
    return (
        <div className="app-container">
            <Header logout={props.logout} />

            <Switch>
                <Route path={`/VerifyTimesheet/:tsId`} render={(routeprops) =>
                    <VerifyTimeSheet 
                        timesheetId={routeprops.match.params.tsId} 
                    /> 
                } />
                <Route path="/" render={() =>
                    <NavigationRouting />
                } />
            </Switch>

            <Footer />
        </div>
    )
}