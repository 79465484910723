import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faDownload } from '@fortawesome/free-solid-svg-icons';
import { DropdownList } from 'react-widgets';
import { GetSurveyAttachment, SaveSurveyAttachmentCategory, DeleteSurveyAttachment } from '../../../API/SurveyAttachment';

export default (props) => {
    const [photoOption, setPhotoOption] = useState({value: "", label: ""})

    useEffect(() => {
        props.photoOptions.forEach(po => {
            if (po.value === props.surveyAttachment.categoryId) {
                setPhotoOption(po);
            }
        })
    },[props.surveyAttachment, props.photoOptions])

    const removeSurveyAttachment = (id) => {
        DeleteSurveyAttachment(id)
        .then(res=>{
            props.reloadAttachments();
        })
    }

    const changeCategory = (e) => {
        let payload = {
            surveyAttachmentId: props.surveyAttachment.surveyAttachmentId,
            category: e.value
        }
        SaveSurveyAttachmentCategory(payload)
        .then(res => {
            if (res.success) {
                setPhotoOption(e);
            } 
        })
    }

    return (
        <div className="row">
            <div className="col">
                <strong>{props.surveyAttachment.name}</strong>
            </div>
            <div className="col">
                { props.editing ?
                    <DropdownList 
                        data={props.photoOptions} 
                        textField='label' 
                        value={photoOption.label} 
                        valueField='value' 
                        onChange={(e) => changeCategory(e)} 
                    /> : 
                    <div>
                        {photoOption.label}
                    </div>
                }
            </div>
            <div className="col-1">
                <FontAwesomeIcon 
                    icon={faDownload} 
                    className="clickable" 
                    onClick={() => GetSurveyAttachment(props.surveyAttachment.surveyAttachmentId, props.surveyAttachment.name)}
                />
            </div>
            { props.editing && <div className="col-1">
                <FontAwesomeIcon 
                    icon={faTrash} 
                    className="clickable" 
                    onClick={() => removeSurveyAttachment(props.surveyAttachment.surveyAttachmentId)} 
                />
            </div>}
        </div>
    )
}


