import React from 'react';

import User from './User'
import ReportBox from './ReportBox'

export default props => {
    return (
        <div className="container-fluid no-print">
            <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-12 my-auto">
                    <img className="img-fluid header-image" src="../images/logo.png" alt="Environmental Noise Control" />
                </div>
                <div className="col-6 d-none d-xl-block d-lg-block"></div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                    <User logout={props.logout} />
                </div>
            </div>

            <div className="row">
                <div className="col my-auto">
                    <ReportBox />
                </div>
            </div>
        </div >
    )
}
