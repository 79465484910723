import React, { useState } from 'react';
import { Accordion, Card, Button, Table } from 'react-bootstrap';
import { DropdownList } from 'react-widgets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

export default ({ updateHours, removeHour, addHours, hoursList, hours = [] }, props) => {
    const [selection, setSelection] = useState(
        {
            "budgetCostHourPriceId": 0,
            "price": 0,
            "name": "Select Hour Type",
            "archived": true
        }
    )

    const expanded = () => {
        if (hours.length > 0) {
            return { defaultActiveKey: "0" }
        }
    }

    const PushHours = () => {
        if (selection.budgetCostHourPriceId !== 0) {
            addHours(selection)
        }
    }

    return (
        <>
            <Accordion className="my-2" {...expanded()} >
                <Card style={{ overflow: "visible" }}>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                        <div className="row">
                        <div className="col">
                            <strong>Hours Calculator</strong>
                        </div>
                        <div className="col text-right">
                            <small>Contains {hours.length} line(s).</small>
                        </div>
                        </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body >

                            <Table className="table-bordered hours-table">
                                <thead>
                                    <tr>
                                        <th scope='col' colSpan="4">
                                            Calculating Hours
                                        </th>
                                    </tr>
                                    <tr>
                                        <th scope='col'>Name</th>
                                        <th scope='col'>Price</th>
                                        <th scope='col'>Hours</th>
                                        <th scope='col'></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {hours.map((m, index) =>
                                        <tr key={`cardRow-${index}`}>
                                            <td> {m.name}</td>
                                            <td>${m.price} / Hour</td>
                                            <td>
                                                <input type='number' value={m.hours} className="enc-input-100" onChange={e => updateHours(e.target.value, index)} />
                                            </td>
                                            <td>
                                                <FontAwesomeIcon className="clickable" icon={faTrash} onClick={() => removeHour(index)} />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>

                            <div className="row mt-2">
                                <div className="col">
                                    <DropdownList data={hoursList} value={selection.name} textField='name' onChange={(e) => setSelection(e)} />
                                </div>
                                <div className="col">
                                    Cost Per Hour: ${selection.price}
                                </div>

                                <div className="col mb-4">
                                    {/* mb-4 was assigned to this as a hacky fix, the issue is overflow:hidden is automatically assigned and overflow:visible needs to be assigned to all parent elements,
                                    or this needs to be converted to a ReactJS Portar -per recommendations on several forums and stack overflow */}
                                    <Button className="enc-button" onClick={PushHours}>Add Hour Line</Button>
                                </div>
                            </div>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </>
    )
}
