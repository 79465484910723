import { faClone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CheckClonePermission } from '../API/JobClone';

export default props => {
    const [allowed, setAllowed] = useState(false);
    const [click, setClick] = useState(false);

    let history = useHistory();

    useEffect(()=>{
        CheckClonePermission(props.jobId)
        .then(res => {
            setAllowed(res.viewPermission)
            setClick(res.clickPermission)
        })
    },[props.jobId])

    const ClickHandler = () => {
        if(click){
            console.log(props.url)
            history.push(`${props.url}/Clone`)
        }
    }

    return (
        <>
            {allowed &&
                <FontAwesomeIcon icon={faClone} className={click ? "clickable" : "denied"} onClick={ClickHandler} />
            }
        </>
    )
}
