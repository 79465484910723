import React, { useEffect, useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { GetEquipmentFormListAllByJobId } from '../../API/JobForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';

export default props => {
    const [equipmentFormList, setEquipmentFormList] = useState([]);
    useEffect(() => {
        GetEquipmentFormListAllByJobId(props.jobId)
            .then(res => {
                setEquipmentFormList(res.data)
            })
    }, [props.jobId])
    return (
        <div className="container my-3">
            <ListGroup>
            <ListGroup.Item className="enc-list">Equipment Forms</ListGroup.Item>
                {equipmentFormList.length === 0 && <ListGroup.Item>No Forms Available</ListGroup.Item>}
                {equipmentFormList.map(ft => {
                    return (
                        <ListGroup.Item className="click-list"  key={`equipForm-${ft.equipmentFormId}`} as={Link} to={`${props.match.url}/${ft.equipmentFormId}`}>
                            <div className="row">
                                <div className="col-1">
                                    {ft.completed ?
                                        <FontAwesomeIcon icon={faCheck} style={{ color: "green" }} />
                                        :
                                        <FontAwesomeIcon icon={faTimes} style={{ color: "red" }} />
                                    }
                                </div>
                                <div className="col">
                                    {ft.date}
                                </div>
                                <div className="col">
                                    {ft.name}
                                </div>
                            </div>
                        </ListGroup.Item>)
                })}
            </ListGroup>
        </div>
    )
}