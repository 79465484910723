import React from 'react';
import { ListGroup } from 'react-bootstrap';

export default ({ ticketItems, ChangeRetired, ChangeSalvaged, ...props }) => {
    return (
        <div>
            {ticketItems.length > 0 && ticketItems.map((m, i) =>
                <ListGroup className="mt-2" key={`ti-${i}`}>
                    <ListGroup.Item className="enc-data-list">
                        <div className="row">
                            <div className="col">
                                {m.baseItem}
                            </div>
                            <div className="col text-right">
                                {m.damagedAmount > 0 ? 
                                <>
                                Damaged Items: {m.damagedAmount} - Undamaged Items: {m.amount}
                                </> 
                                :
                                <>
                                 Undamaged Items : {m.amount}
                                </>
                                }
                            </div>
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item className="enc-data-list">
                        <div className="row">
                            <div className="col">
                                Item Name
                            </div>
                            <div className="col">
                                Ticket Total
                            </div>
                            <div className="col">
                                Amount Remaining
                            </div>
                            <div className="col">
                                Amount Retired
                                </div>
                            <div className="col">
                                Amount Salvaged
                                </div>
                        </div>
                    </ListGroup.Item>

                    {m.components.map((c, ci) => {
                        return (
                            <ListGroup.Item key={`st-item-${ci}`}>
                                <div className="row">
                                    <div className="col">
                                        {c.bulkItem}
                                    </div>
                                    <div className="col">
                                        {c.amount}
                                    </div>
                                    <div className="col">
                                        {c.amount - c.amountRetired - c.amountTransfered}
                                    </div>
                                    <div className="col">
                                        <span>Recorded Already: {c.amountRetired}</span>
                                        <br />
                                        <input
                                            type='number'
                                            className="enc-input-100"
                                            value={c.amountRetiredAdjustment}
                                            onChange={(e) => ChangeRetired(i, ci, e.target.value)} />
                                    </div>
                                    <div className="col">
                                        <span>Recorded Already: {c.amountTransfered}</span>
                                        <br />
                                        <input
                                            type='number'
                                            className="enc-input-100"
                                            value={c.amountTransferedAdjustment}
                                            onChange={(e) => ChangeSalvaged(i, ci, e.target.value)} />
                                    </div>
                                </div>
                            </ListGroup.Item>
                        )
                    })}
                </ListGroup>
            )}
        </div>
    )
}
