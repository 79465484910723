import React, { useEffect, useState } from 'react';
import { SerializedItemProfile, DecommissionItem } from '../../../API/AssetInventory';
import { ListGroup, Button, Modal } from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';

export default props => {
    const [profile, setProfile] = useState({
        serializedItemId: 0,
        serialNumber: "",
        name: "",
        decommissioned: false,
        statuses: [],
        manufacturer: "",
        mode: "",
        licenses: "",
        purchaseDate: "",
        purchasePrice: 0,
        assetType: 0,
        meterType: ""
    })

    const [confirm, setConfirm] = useState(false)

    useEffect(() => {
        SerializedItemProfile(props.match.params.Sid)
            .then(res => {
                setProfile(res.data)
            })
    }, [props.match.params.Sid])

    const history = useHistory();

    const Decommision = () => {
        DecommissionItem(props.match.params.Sid)
            .then(res => {
                history.goBack();
            })
    }

    return (
        <div className="container mt-3">
            <div className="row my-2">
                <div className="col">
                    <h3>Asset Details</h3>
                </div>
            </div>
            <div className="row my-2">
                <div className="col text-right">
                    <Button as={Link} to={`${props.match.url}/EditAsset`} className="enc-button ml-2">Edit Asset</Button>
                    {profile.onRepair ?
                        <Button as={Link} to={`${props.match.url}/CheckInRepair`} className="enc-button ml-2">Check In Repair</Button>
                        :
                        <Button as={Link} to={`${props.match.url}/AssetRepair`} className="enc-button ml-2">Asset Repair</Button>
                    }

                    <Button as={Link} to={`${props.match.url}/AssetFailure`} className="enc-button ml-2">Report Failure</Button>

                    {!profile.decommissioned && <Button className="enc-button ml-2" onClick={() => setConfirm(true)}>Decommission</Button>}
                </div>
            </div>
            <div className="divider my-2" />
            <div className="row">
                <div className="col">
                    <small>Manufacturer</small>
                    <h4>{profile.manufacturer}</h4>
                </div>
                <div className="col">
                    <small>Model</small>
                    <h4>{profile.model}</h4>
                </div>
                <div className="col text-right">
                    <small>Serial Number</small>
                    <h4>{profile.serialNumber}</h4>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col">
                    <small>Asset Type</small>
                    <br />
                    {profile.assetType}
                </div>
                <div className="col">
                    <small>Meter Type</small>
                    <br />
                    {profile.meterType}
                </div>
            </div>
            <div className="row mt-2">
                <div className="col">
                    <small>Purchase Date</small>
                    <br />
                    {profile.purchaseDate}
                </div>
                <div className="col">
                    <small>Last Calibrated Date</small>
                    <br />
                    {profile.lastCalibratedDate}
                </div>

            </div>
            <div className="row mt-2">
                <div className="col">
                    <small>Purchase Price</small>
                    <br />
                    ${profile.purchasePrice}
                </div>
            </div>
            <div className="row my-2">
                <div className="col">
                    <small>Licenses</small>
                    <br />
                    {profile.licenses}
                </div>
            </div>
            <ListGroup className="mb-3">
                {profile.statuses.map((m, i) => {
                    return (
                        <ListGroup.Item key={`Asset-${i}`} className="mt-1">
                            <div className="row">
                                <div className="col">
                                    Status: {m.status}
                                </div>
                                <div className="col">
                                    Location: {m.location}
                                </div>
                                <div className="col text-right">
                                    {m.date}
                                </div>
                            </div>
                            <div className="divider my-2" />
                            <div className="row">
                                <div className="col">
                                    {m.statusNote}
                                </div>
                            </div>
                        </ListGroup.Item>
                    )
                })}
            </ListGroup>


            <Modal show={confirm} onHide={() => setConfirm(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Decommission Item</Modal.Title>
                </Modal.Header>
                <Modal.Body>This will permenantly decommission item. This action cannot be reversed.</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setConfirm(false)}>
                        Close
          </Button>
                    <Button className="enc-button" onClick={Decommision}>
                        Confirm Decommision
          </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
