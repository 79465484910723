import React, { useState, useEffect } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { GetTimesheet, VerifyTimesheet } from '../API/TimeSheet';
import { useHistory } from 'react-router-dom';
import TimesheetEntryList from './TimeSheetEntryList';

export default (props) => {
    const [timesheet, setTimesheet] = useState({
        timesheetId: 0,
        date: "",
        employeeName: ""
    });
    const [pin, setPin] = useState("");
    const [mask, setMask] = useState("");
    const [message, setMessage] = useState("");

    useEffect(() => {
        LoadTimesheet(props.timesheetId);
    }, [props.timesheetId, props.date])

    const history = useHistory();

    const LoadTimesheet = (timesheetId) => {
        GetTimesheet(parseInt(timesheetId))
            .then(res => {
                if (res.success) {
                    setTimesheet(res.data);
                } 
            })
    }

    const SubmitTimesheet = () => {
        let payload = { 
            timesheetId: parseInt(props.timesheetId),
            employeePin: pin
        };

        VerifyTimesheet(payload)
        .then(res => {
            if (res.success) {
                history.push(`/Timesheets`);
            }
            else {
                SetPin("");
                setMessage(res.message);
            }
        })
    }

    const pinChanged = (d) => {
        if (pin.length > 3) {
            return;
        }
        let newPin = pin + d;
        SetPin(newPin);
    }

    const pinDeleted = () => {
        if (pin.length === 0) {
            return;
        }
        let newPin = pin.substring(0, pin.length-1);
        SetPin(newPin);
    }

    const SetPin = (newPin) => {
        setPin(newPin);
        setMask(newPin.replace(/./g, "*"));
    }

    return (
        <div className="container pt-4">
            <div className="row pt-2">
                <div className="col-lg-2">
                    {new Date(Date.parse(timesheet.date)).toLocaleDateString()}
                </div>
                <div className="col-lg-2">
                    {timesheet.employeeName}
                </div>
                <div className="col-lg-2">
                    {new Date(Date.parse(timesheet.earliestStartTime)).toLocaleTimeString()}
                </div>
                <div className="col-lg-2">
                    {new Date(Date.parse(timesheet.latestEndTime)).toLocaleTimeString()}
                </div>
                <div className="col-lg-2">
                    {timesheet.totalHours}
                </div>
                <div className="col text-right">
                    <input type="button" value="Cancel" className="enc-button"  onClick={() => history.push(`/Timesheets`)}/>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <TimesheetEntryList timesheetId={props.timesheetId} nav={false}/>
                </div>
            </div>
                <div className="row pt-2">
                    <div className="col">
                        <input type="text" value={mask} className="enc-input" name="mask" autoComplete="any" disabled={true}/>
                    </div>

                </div>
                <div className="row pt-2">
                    <div className="col">
                        {message}
                    </div>
                </div>

                <div className="row">
                    <div className="wrapper">
                        <div className="one button" onClick={() => pinChanged(1)}>1</div>
                        <div className="two button" onClick={() => pinChanged(2)}>2</div>
                        <div className="three button" onClick={() => pinChanged(3)}>3</div>
                        <div className="four button" onClick={() => pinChanged(4)}>4</div>
                        <div className="five button" onClick={() => pinChanged(5)}>5</div>
                        <div className="six button" onClick={() => pinChanged(6)}>6</div>
                        <div className="seven button" onClick={() => pinChanged(7)}>7</div>
                        <div className="eight button" onClick={() => pinChanged(8)}>8</div>
                        <div className="nine button" onClick={() => pinChanged(9)}>9</div>
                        <div className="zero button" onClick={() => pinChanged(0)}>0</div>
                        <div className="backspace button" onClick={() => pinDeleted()}>Delete</div>
                        <div className="enter button" onClick={() => SubmitTimesheet()}>Verify</div>
                    </div>
                </div>
        </div>
    )
}


