import React, { useState, useEffect } from 'react';
import { GetAnnouncementsList } from '../API/Announcement';
import { ListGroup } from 'react-bootstrap';

export default props => {
    const [AnnouncementList, setAnnouncementList] = useState([]);

    useEffect(() => {
        loadAnnouncements({ today: true })
    }, [])

    const loadAnnouncements = (payload) => {
        GetAnnouncementsList(payload)
            .then(res => {
                setAnnouncementList(res.data)
            })
    }

    return (
        <ListGroup>
            <ListGroup.Item className="enc-list">
                Announcements for {new Date().toLocaleDateString()}
            </ListGroup.Item>
            {AnnouncementList.map((m,i) => {
                return (
                    <ListGroup.Item key={`item-${i}`} className="enc-list">
                        <div className="row" key={`Announcement-${m.announcementId}`}>
                            <div className="col">
                                <h5>{m.title}</h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                {m.details}
                            </div>
                        </div>
                    </ListGroup.Item>
                )
            })}
        </ListGroup>
    )
}
