import React, { useEffect, useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { GetAllFieldTicketsByJobId } from '../../API/JobForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';

export default props => {
    const [fieldTickets, setFieldTickets] = useState([]);
    useEffect(()=>{
        GetAllFieldTicketsByJobId(props.jobId)
        .then(res => {
            setFieldTickets(res.data)
        })
    },[props.jobId])
    return (
        <div className="container my-3">
        <ListGroup>
            <ListGroup.Item className="enc-list">Field Tickets</ListGroup.Item>
            {fieldTickets.length === 0 && <ListGroup.Item>No Forms Available</ListGroup.Item>}
            {fieldTickets.map(ft => {
                return(
            <ListGroup.Item className="click-list" key={`field-ticket-${ft.fieldTicketId}`} as={Link} to={`${props.match.url}/${ft.fieldTicketId}`}>
                <div className="row">
                    <div className="col-1">
                    {ft.completed ?
                    <FontAwesomeIcon icon={faCheck} style={{ color: "green" }} />
                    :
                    <FontAwesomeIcon icon={faTimes} style={{ color: "red" }} />
                        }
                    </div>
                    <div className="col">
                        {ft.date}
                    </div>
                    <div className="col">
                        Field Ticket
                    </div>
                </div>
            </ListGroup.Item>
            )})}
        </ListGroup>
        </div>
    )
}
