import React, { useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import AdvancedSearch from './AdvancedSearch';

export default props => {
    const [jobs, setJobs] = useState([]);

    let history = useHistory();

    const navigateToJob = (oppId, id, stageName) => {
        let stage = stageName.replace(/\s/g, '')
        history.push(`/Opportunities/${oppId}/${id}/${stage}`)
    }

    return (
        <>
            <div className="container">
                <div>
                    <AdvancedSearch
                        setJobs = {setJobs}
                        type={props.type}
                    />

                    <ListGroup className="my-3">
                        <ListGroup.Item className="enc-data-list">Job List</ListGroup.Item>
                        <ListGroup.Item className="enc-data-list">
                            <div className="row">
                                <div className="col-lg-2">
                                    Name
                                </div>
                                <div className="col-lg-2">
                                    Classification
                                </div>
                                <div className="col-lg-2">
                                    Account Name
                                </div>
                                <div className="col-lg-2">
                                    Job Number
                                </div>
                                <div className="col-lg-2">
                                    Stage
                                </div>
                                <div className="col-lg-2">
                                    Last Updated
                                </div>
                            </div>
                        </ListGroup.Item>
                        {jobs.length === 0 && <ListGroup.Item>No Jobs To Display</ListGroup.Item>}
                        {jobs.map(m => {
                            const getTime = () => {
                                if (m.lastUpdated !== null) {
                                    var utcDate = m.lastUpdated;
                                    var localDate = new Date(utcDate + 'Z');
                                    return (
                                        <>
                                            {localDate.toLocaleDateString()}
                                            &nbsp;
                                            {localDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                        </>
                                    )
                                }
                                else {
                                    return (
                                        <>
                                            N/A
                                        </>
                                    )
                                }
                            };
                            return (
                                <ListGroup.Item key={`LGI-${m.jobId}`} onClick={() => navigateToJob(m.opportunityId, m.jobId, m.jobStage)} className="click-list">
                                    <div className="row">
                                        <div className="col-lg-2">
                                            {m.canceled && "Cancelled -"} {m.jobName}
                                        </div>
                                        <div className="col-lg-2">
                                            {m.jobClassification}
                                        </div>
                                        <div className="col-lg-2">
                                            {m.accountName}
                                        </div>
                                        <div className="col-lg-2">
                                            {m.jobNumber}
                                        </div>
                                        <div className="col-lg-2">
                                            {m.jobStage}
                                        </div>
                                        <div className="col-lg-2">
                                            {getTime()}
                                        </div>
                                    </div>
                                </ListGroup.Item>
                            )
                        })}
                    </ListGroup>
                </div>
            </div>
        </>
    )
}
