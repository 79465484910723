import React, { useState, useEffect } from 'react';
import { Button, Table } from 'react-bootstrap';
import { GetJSAEdits } from "../../API/JSA.js"
export default props => {

    const [ShowLog, setShowLog] = useState(false)
    const [edits, setEdits] = useState([])

    useEffect(() => {
        GetJSAEdits(props.JSAId)
            .then(resp => {
                setEdits(resp.jsaEditsList)
            }
            )

    }, [props.JSAId]
    )



    const setShowLogCallback = () => {
        setShowLog(!ShowLog)
    }

    const parseDate = (timeStampString) => {
        let output = ""
        output += timeStampString.slice(0, 10) + " at " + timeStampString.slice(11, 16)

        return output
    }

    const displayEditLog = () => {
        if (ShowLog) {

            if (edits.length === 0) {
                return (
                    <h5 className="mx-auto mt-3">
                        No Edits have been made to this document
                    </h5>
                )
            }
            else {
                return (
                    <div>
                        <h4 className="mt-3">Edit Log</h4>
                        <Table className="container mx-auto">

                            <tbody>
                                <tr>
                                    <th>Name</th>
                                    <th>Time</th>
                                </tr>
                                {edits.map((edit, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{edit.editorName}</td>
                                            <td>{parseDate(edit.editedTime)}</td>
                                        </tr>)
                                })}
                            </tbody>
                        </Table>
                    </div>
                )
            }
        }
    }

    return (
        <>
                <div className="col">
                    <Button className="mt-2 mx-2 enc-button" onClick={setShowLogCallback}>View Edit Log</Button>
                </div>
            {displayEditLog()}
        </>
    )


}