import React, { useState, useEffect } from 'react';
import { Button, Breadcrumb, Alert } from 'react-bootstrap';
import { CreateAccount, GetAccountListWithChildren, GetIndustryTypes, GetTerritories } from '../API/Account';
import { useHistory, Link } from 'react-router-dom';
import { DropdownList } from 'react-widgets';
import PossibleMatches from './PossibleMatches';

import stateList from '../Components/stateList';

export default (props) => {
    const [account, setAccount] = useState({
        "accountId": 0,
        "accountName": "",
        "accountNumber": "",
        "parentAccount": 0,
        "enabled": true,
        "industryTypeId": null,
        "territoryId": null,
        "taxExempt": false
    })

    const [location, setLocation] = useState({
        "accountLocationId": 0,
        "accountId": 0,
        "locationName": "",
        "billingAddress1": "",
        "billingAddress2": "",
        "billingCity": "",
        "billingState": "",
        "billingZip": "",
        "shippingAddress1": "",
        "shippingAddress2": "",
        "shippingCity": "",
        "shippingState": "",
        "shippingZip": "",
        "enabled": true,
    })

    const [contact, setContact] = useState({
        "accountContactId": 0,
        "firstName": "",
        "lastName": "",
        "phoneNumber": "",
        "email": "",
        "enabled": true,
        "primary": false
    })

    const [sameBilling, setSameBilling] = useState(false);

    const [show, setShow] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [redirectUrl, setRedirectUrl] = useState("");

    const [possibleMatches, setPossibleMatches] = useState([]);
    const [showPossible, setShowPossible] = useState(false);

    const handleToggle = () => {
        let s = { ...location }
        if (sameBilling) {
            s.shippingAddress1 = ""
            s.shippingAddress2 = ""
            s.shippingCity = ""
            s.shippingState = ""
            s.shippingZip = ""
        } else {
            s = changeShipping(s)
        }
        setLocation(s)
        setSameBilling(!sameBilling);
    }

    const changeShipping = (state) => {
        state.shippingAddress1 = state.billingAddress1
        state.shippingAddress2 = state.billingAddress2
        state.shippingCity = state.billingCity
        state.shippingState = state.billingState
        state.shippingZip = state.billingZip
        return state
    }

    const [accountList, setAccountList] = useState([]);

    const [industryTypeList, setIndustryTypeList] = useState([]);
    const [territoriesList, setTerritoryList] = useState([]);

    useEffect(() => {
        const loadAccounts = () => {
            GetAccountListWithChildren(true)
                .then(res => {
                    if (res.success) {
                        setAccountList(flattenAccountsList(res.data))
                    }
                })
        }

        const flattenAccountsList = (accounts) => {
            let flattenedList = []
            accounts.forEach(e => {
                flattenedList.push(e)
                if (e.nodes && e.nodes.length > 0) {
                    flattenedList = flattenAccountsList(e.nodes).concat(flattenedList)
                }
            });
            return flattenedList
        }

        const loadIndustryTypes = () => {
            GetIndustryTypes()
                .then(res => {
                    if (res.success) {
                        setIndustryTypeList(res.data)
                    }
                })
        }

        const loadTerritories = () => {
            GetTerritories()
                .then(res => {
                    if (res.success) {
                        setTerritoryList(res.data)
                    }
                })
        }

        loadAccounts();
        loadIndustryTypes();
        loadTerritories();
    }, [])

    const history = useHistory();


    const createPayload = () => {
        let payload = {
            accountInfo: account,
            locationInfo: location,
            contactInfo: contact
        }
        return payload;
    }

    const resetMessages = () => {
        setShow(false);
        setAlertMessage("");
        setRedirectUrl("");
        setPossibleMatches([]);
        setShowPossible(false);
    }

    const overrideCreateAccount = () => {
        let payload = createPayload();
        payload.override = true;
        resetMessages();
        sendRequestToServer(payload);
    }

    const TryCreateAccount = () => {
        let payload = createPayload();
        resetMessages();
        sendRequestToServer(payload);
    }

    const sendRequestToServer = (payload) => {
        CreateAccount(payload)
            .then(res => {
                if (res.success && res.data.length === 1) {
                    history.push(`${res.data[0].accountId}`)
                } else if (res.data.length > 0) {
                    setPossibleMatches(res.data)
                    setShowPossible(true);
                }
            })
            .catch(err => {
                setShow(true);
                if (err.details) {
                    setAlertMessage(err.details.message)
                    if (err.details.existingID) {
                        let newLoc = props.match.url.split('/').slice(0, -1).join('/');
                        setRedirectUrl(`${newLoc}/${err.details.existingID}`)
                    }
                } else {
                    setAlertMessage("An error has occured");
                }
            })
    }

    const changeAccount = (key, value) => {
        let s = { ...account };
        s[key] = value
        setAccount(s)
    }

    const changeContact = (key, value) => {
        let s = { ...contact };
        s[key] = value
        setContact(s)
    }


    const changeLocation = (key, value) => {
        let s = { ...location };
        s[key] = value
        if (sameBilling) {
            let shipping = changeShipping(s)
            setLocation(shipping)
        } else {
            setLocation(s)
        }
    }

    return (
        <div className="container-fluid">
            <Breadcrumb className="breadcrumb">
                <li className="breadcrumb-item"><Link to={`/Accounts`}>Accounts List</Link></li>
                <Breadcrumb.Item active>New Account</Breadcrumb.Item>
            </Breadcrumb>
            <div className="container">
                <form action="#" onSubmit={(e) => { e.preventDefault(); TryCreateAccount() }}>
                    <div className="row">
                        <div className="col">
                            <h3>New Account</h3>
                        </div>
                    </div>

                    <div className="divider my-2" />
                    <div className="row">
                        <div className="col">
                            <h4>Account Information</h4>

                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col">
                            <small>Account Name</small>
                            <input
                                required
                                type="text"
                                className="enc-input-100"
                                name="accountName"
                                value={account.accountName}
                                placeholder="Account Name"
                                onChange={e => changeAccount('accountName', e.target.value)}
                            />
                        </div>
                        <div className="col">
                            <small>Account Number</small>
                            <input
                                type="text"
                                className="enc-input-100"
                                name="accountNumber"
                                value={account.accountNumber}
                                placeholder="Account Number"
                                onChange={e => changeAccount('accountNumber', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col">
                            <small>Parent Account</small>
                            <DropdownList
                                filter='contains'
                                placeholder="Parent Account"
                                data={accountList}
                                textField="accountName"
                                value={account.parentAccount}
                                valueField='accountId'
                                onChange={(e) => changeAccount('parentAccount', e.accountId)}

                            />
                        </div>
                    </div>

                    {/*<div className="row mb-2">*/}
                    {/*    <div className="col">*/}
                    {/*        <small>Industry</small>*/}
                    {/*        <DropdownList*/}
                    {/*            filter='contains'*/}
                    {/*            placeholder="Select Industry"*/}
                    {/*            data={industryTypeList}*/}
                    {/*            textField="industryName"*/}
                    {/*            value={account.industryTypeId}*/}
                    {/*            valueField="industryTypeId"*/}
                    {/*            onChange={e => changeAccount("industryTypeId", e.industryTypeId)}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*    <div className="col">*/}
                    {/*        <small>Territory</small>*/}
                    {/*        <DropdownList*/}
                    {/*            filter='contains'*/}
                    {/*            placeholder="Territory"*/}
                    {/*            data={territoriesList}*/}
                    {/*            textField="territoryName"*/}
                    {/*            value={account.territoryId}*/}
                    {/*            valueField="territoryId"*/}
                    {/*            onChange={e => changeAccount("territoryId", e.territoryId)}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="row mt-3">*/}
                    {/*    <div className="col">*/}
                    {/*        <div className="toggle-switch small-switch">*/}
                    {/*            <input*/}
                    {/*                type="checkbox"*/}
                    {/*                className="toggle-switch-checkbox"*/}
                    {/*                id="taxExempt"*/}
                    {/*                checked={account.taxExempt}*/}
                    {/*                onChange={e => changeAccount("taxExempt", e.target.checked)}*/}
                    {/*            />*/}
                    {/*            <label className="toggle-switch-label" htmlFor="taxExempt">*/}
                    {/*                <span className={"toggle-switch-inner"} />*/}
                    {/*                <span*/}
                    {/*                    className={"toggle-switch-switch"}*/}
                    {/*                />*/}
                    {/*            </label>*/}
                    {/*        </div>*/}
                    {/*        <small className="ml-2">Tax Exempt</small>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="divider my-2" />

                    <div className="row">
                        <div className="col">
                            <h3>Location Information</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="row">
                                <div className="col">
                                    <small>Location Name</small>
                                    <input type="text"
                                        required
                                        className="enc-input-100"
                                        value={location.locationName}
                                        placeholder="Location Name"
                                        onChange={e => changeLocation('locationName', e.target.value)} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="toggle-switch small-switch">
                                        <input
                                            type="checkbox"
                                            className="toggle-switch-checkbox"
                                            id="disabled"
                                            checked={sameBilling}
                                            onChange={handleToggle}
                                        />
                                        <label className="toggle-switch-label" htmlFor="disabled">
                                            <span className={"toggle-switch-inner"} />
                                            <span
                                                className={"toggle-switch-switch"}
                                            />
                                        </label>
                                    </div>
                                    &nbsp; &nbsp;Use Same Shipping/Billing Address
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="row mb-1">
                                        <div className="col">
                                            <small>Billing Address</small>
                                            <input type="text"
                                                className="enc-input-100"
                                                name="billingAddress1"
                                                value={location.billingAddress1}
                                                placeholder="Billing Address"
                                                onChange={e => changeLocation('billingAddress1', e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col">
                                            <input type="text"
                                                className="enc-input-100"
                                                name="billingAddress2"
                                                value={location.billingAddress2}
                                                onChange={e => changeLocation('billingAddress2', e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <small>City</small>
                                            <input type="text"
                                                className="enc-input-100"
                                                name="billingCity"
                                                value={location.billingCity}
                                                placeholder="City"
                                                onChange={e => changeLocation('billingCity', e.target.value)} />
                                        </div>
                                        <div className="col">
                                            <small>State</small>
                                            <DropdownList
                                                data={stateList}
                                                name="billingState"
                                                value={location.billingState}
                                                placeholder="State"
                                                onChange={e => changeLocation('billingState', e)}
                                            />
                                        </div>
                                        <div className="col">
                                            <small>Zip Code</small>

                                            <input
                                                type="text"
                                                className="enc-input-100"
                                                name="billingZip"
                                                value={location.billingZip}
                                                placeholder="Zip Code"
                                                onChange={e => changeLocation('billingZip', e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="row mb-1">
                                        <div className="col">
                                            <small>Shipping Address</small>
                                            <input
                                                type="text"
                                                className="enc-input-100"
                                                disabled={sameBilling}
                                                name="shippingAddress1"
                                                value={location.shippingAddress1}
                                                placeholder="Shipping Address"
                                                onChange={e => changeLocation('shippingAddress1', e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col">
                                            <input type="text"
                                                className="enc-input-100"
                                                disabled={sameBilling}
                                                name="shippingAddress2"
                                                value={location.shippingAddress2}
                                                onChange={e => changeLocation('shippingAddress2', e.target.value)} />

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <small>City</small>
                                            <input
                                                type="text"
                                                className="enc-input-100"
                                                disabled={sameBilling}
                                                name="shippingCity"
                                                value={location.shippingCity}
                                                placeholder="City"
                                                onChange={e => changeLocation('shippingCity', e.target.value)}
                                            />
                                        </div>
                                        <div className="col">
                                            <small>State</small>
                                            <DropdownList
                                                data={stateList}
                                                disabled={sameBilling}
                                                name="shippingState"
                                                value={location.shippingState}
                                                placeholder="State"
                                                onChange={e => changeLocation('shippingState', e)}
                                            />
                                        </div>
                                        <div className="col">
                                            <small>Zip Code</small>
                                            <input type="text"
                                                className="enc-input-100"
                                                disabled={sameBilling}
                                                name="shippingZip"
                                                value={location.shippingZip}
                                                placeholder="Zip Code"
                                                onChange={e => changeLocation('shippingZip', e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="divider my-2" />
                    {/* Contact Information */}
                    <div className="row">
                        <div className="col">
                            <h4>Contact Information</h4>
                        </div>
                    </div>
                    <div className="row mb-1">
                        <div className="col">
                            <small>First Name</small>
                            <input type="text"
                                className="enc-input-100"
                                name="firstName"
                                value={contact.firstName}
                                placeholder="First Name"
                                onChange={e => changeContact('firstName', e.target.value)} />
                        </div>
                        <div className="col">
                            <small>Last Name</small>
                            <input
                                type="text"
                                className="enc-input-100"
                                name="lastName"
                                value={contact.lastName}
                                placeholder="Last Name"
                                onChange={e => changeContact('lastName', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="row mb-1">
                        <div className="col">
                            <small>Phone</small>
                            <input
                                type="text"
                                className="enc-input-100"
                                name="phoneNumber"
                                value={contact.phoneNumber}
                                placeholder="Phone Number"
                                onChange={e => changeContact('phoneNumber', e.target.value)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <small>Email</small>
                            <input
                                type="text"
                                className="enc-input-100"
                                name="email"
                                value={contact.email}
                                placeholder="Email"
                                onChange={e => changeContact('email', e.target.value)} />
                        </div>
                    </div>
                    <div className="row my-2">
                        <div className="col text-right">
                            <div className="toggle-switch small-switch">
                                <input
                                    type="checkbox"
                                    className="toggle-switch-checkbox"
                                    id="primary"
                                    checked={contact.primary}
                                    onChange={() => changeContact('primary', !contact.primary)}
                                />
                                <label className="toggle-switch-label" htmlFor="primary">
                                    <span className={"toggle-switch-inner"} />
                                    <span
                                        className={"toggle-switch-switch"}
                                    />
                                </label>
                            </div>
                    &nbsp; &nbsp;Set As Primary Contact For Location
                </div>
                    </div>
                    <div className="row p-2">
                        <div className="col-8">
                            <Alert variant={"warning"} show={showPossible}>
                                Possible matches found, please review below.
                            </Alert>
                            <Alert show={show} variant="danger" onClose={() => setShow(false)} dismissible>
                                {alertMessage} {redirectUrl !== "" && <Link to={redirectUrl}>Click here to go to account.</Link>}
                            </Alert>
                        </div>
                        <div className="col text-right">
                            <Button type="submit" className="enc-button">Save Account</Button>
                        </div>
                    </div>
                </form>
                {showPossible &&
                    <>
                        <PossibleMatches matches={possibleMatches} />
                        <div className="row p-2">
                            <div className="col-8">
                                <span style={{ color: "red" }}>
                                    Clicking this button will create a new account. You should be sure none of the matched accounts above are the account you are making.
                                </span>
                            </div>
                            <div className="col text-right">
                                <Button onClick={overrideCreateAccount} className="enc-button">Confirm Creation</Button>
                            </div>
                        </div>
                    </>
                }
            </div >
        </div >
    )
}
