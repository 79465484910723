import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { CheckBilling, CompleteBilling } from '../API/Jobs';
import Attachments from '../Components/Attachments';

export default ({jobId, ...props}) => {
    useEffect(()=>{
        LoadCheck(jobId)
    },[jobId])

    const [perms,setPerms] = useState({
        allowClose: false,
        billingStage: false
    })

    const LoadCheck = (id) => {
        CheckBilling(id)
        .then(res => {
            setPerms(res.data)
        })
    }

    const CloseBilling = () => {
        CompleteBilling(jobId)
        .then(res => {
            LoadCheck(jobId)
            props.reload();
        })
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h3>Job Complete</h3>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col">
                    <h4>{perms.billingStage? "Billing In Progress" : "Billing Complete"} </h4>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    {perms.allowClose && !perms.billingStage && props.completedBy && props.completedDate?
                        
                        `Completed Billing by: ${props.completedBy} on ${props.completedDate}`
                        :
                         ''}
                </div>
            </div>
            <div className="row mt-2">
                <div className="col text-right">
                    {perms.allowClose && perms.billingStage &&<Button className="enc-button" onClick={CloseBilling}>Complete Billing</Button>}
                </div>
            </div>
            <div className="row mt-3">
                <div className="col">
                        <Attachments
                    jobId={jobId}
                    jobStep={-1}
                    title="All Job Attachments"
                    editable={true}
                    />
                </div>
            </div>
        </div>
    )
}
