import APIRequest from './MainAPICall/api';
import config from '../APIConfig';

export const GetReviewInformation = (id) => {
    return APIRequest({
        url: config.baseURL + `/JobReview/ReviewInformation?JobId=${id}`,
        method: "get"
    });
}

export const PostReviewNote = (payload) => {
    return APIRequest({
        url: config.baseURL + "/JobReview/ReviewNote",
        method: "post",
        body: payload
    });
}
