import React from 'react';
import { Nav } from 'react-bootstrap';
import { Switch, NavLink, Route } from 'react-router-dom';
import JobInventoryInternal from './JobInventoryInternal';
import JobInventoryTransfer from './JobInventoryTransfer';
import TransferList from '../../Inventory/TransferList';
import CheckInTicket from '../../Inventory/CheckInTicket';
import PickTicket from '../../Inventory/PickTicket';


export default props => {
    return (
        <>
            <Nav variant="tabs" className="my-2" defaultActiveKey={1}>
                <Nav.Item>
                    <Nav.Link eventKey={1} as={NavLink} to={`${props.match.url}/Stock`}>Stock</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey={2} as={NavLink} to={`${props.match.url}/Return`}>Transfer</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey={3} as={NavLink} to={`${props.match.url}/CheckIn`}>Check In</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey={4} as={NavLink} to={`${props.match.url}/PickTicket`}>Pick Ticket</Nav.Link>
                </Nav.Item>
            </Nav>
            <div className="container">
                <Switch>
                    <Route path={`${props.match.url}/PickTicket/:id`} component={PickTicket} />
                    <Route path={`${props.match.url}/PickTicket`} render={(routeProps) => <TransferList {...routeProps} type="JobPick" id={props.jobId} /> } />
                    <Route path={`${props.match.url}/CheckIn/:id`} component={CheckInTicket} />
                    <Route path={`${props.match.url}/CheckIn`} render={(routeProps) => <TransferList {...routeProps} type="JobCheckIn" id={props.jobId} /> } />
                    <Route path={`${props.match.url}/Return`} render={() => <JobInventoryTransfer {...props}/> } jobId={props.jobId} />
                    <Route path={`${props.match.url}/`} render={() => <JobInventoryInternal {...props}/> } />
                </Switch>
            </div>
        </>
    )
}



