import React, { useState, useEffect } from 'react';
import { ListGroup } from 'react-bootstrap';
import { FilePond } from 'react-filepond';
import { fpPayloadSurveyMeasurement } from '../../../API/MainAPICall/filepondApi';
import { GetSurveyAttachmentList } from '../../../API/SurveyAttachment';
import SurveyMeasurementAttachment from './SurveyMeasurementAttachment';

export default (props) => {
    const [attachments, setAttachments] = useState([]);

    useEffect(() => {
        loadAttachments(props.surveyMeasurementId);
    }, [props.surveyMeasurementId])

    const loadAttachments = (surveyMeasurementId) => {
        if (surveyMeasurementId !== 0) {
            GetSurveyAttachmentList(parseInt(surveyMeasurementId))
                .then(res => {
                    if (res.success) {
                        setAttachments(res.data);
                    }
                });
        }
    }

    const reloadAttachments = () => {
        loadAttachments(parseInt(props.surveyMeasurementId))
    }

    return (
        <>
            <ListGroup variant="flush">
                <ListGroup.Item>
                    <div className="row survey-head">
                        <div className="col">
                            <strong>Pictures</strong>
                        </div>
                    </div>
                </ListGroup.Item>

                {attachments.map(m => {
                    return (
                        <ListGroup.Item key={`Attachment-${m.surveyAttachmentId}`} className="data-display">
                            <SurveyMeasurementAttachment
                                surveyAttachment={m}
                                reloadAttachments={reloadAttachments}
                                editing={props.editing}
                                photoOptions={props.photoOptions}
                            />
                        </ListGroup.Item>
                    )
                }
                )}
            </ListGroup>
            {props.editing &&
                <FilePond
                    className="mt-5"
                    allowMultiple={false}
                    server={fpPayloadSurveyMeasurement(props.surveyMeasurementId, reloadAttachments)}
                    credits={false}

                />
            }
        </>
    )
}


