import React from 'react';
import { useHistory } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';

export default props => {
    const imageLink = () => {
        if (props.userInfo.employeePhoto) {
            return props.userInfo.employeePhoto
        } else {
            return "../images/user.png"
        }
    }

    const primaryEmail = () => {
        let prim = ""
        if(props.userInfo.emails && props.userInfo.emails[0]){
            prim = props.userInfo.emails[0].email
        }
        props.userInfo.emails.forEach(e => {
            if (e.primary) {
                prim = e.email;
            }
        });
        return prim
    }

    const primaryPhone = () => {
        let prim = ""
        if(props.userInfo.phoneNumbers && props.userInfo.phoneNumbers[0]){
            prim = props.userInfo.phoneNumbers[0].phoneNumber
        }
        props.userInfo.phoneNumbers.forEach(e => {
            if (e.primary) {
                prim = e.phoneNumber;
            }
        });
        return prim
    }

    const history = useHistory();
    const navigateToUser = () => {
        history.push(`/Admin/User/${props.userInfo.userId}`)
    }

    return (
        <div className="col-lg-2 col-sm-12 p-3">
        <Card>
            <Card.Body className="text-center">
                <Card.Img style={{ width: '25%' }} variant="top" src={imageLink()} />
            </Card.Body>
            <Card.Body className="text-center">
                <Card.Title>
                    {props.userInfo.fullName}
                </Card.Title>
                <Card.Subtitle>
                    {props.userInfo.positionTitle}
                </Card.Subtitle>
            </Card.Body>
            <ListGroup variant="flush">
                <ListGroup.Item>{primaryEmail()}</ListGroup.Item>
                <ListGroup.Item>{primaryPhone()}</ListGroup.Item>
            </ListGroup>
            <Card.Footer>
                <div className="row justify-content-between">
                    <small className="text-muted col-8">
                        {props.userInfo.roleName}
                    </small>
                    <small className="col-1 p-1">
                        <FontAwesomeIcon className="clickable" icon={faPencilAlt} onClick={navigateToUser} />
                    </small>
                </div>
            </Card.Footer>
        </Card>
        </div>
    )
}
