import React, { useState, useEffect } from 'react';
import { GetLocationProfile, GetAccountContacts, SaveLocation } from '../API/Account';
import { Card, Button, Modal, Breadcrumb } from 'react-bootstrap';
import { Combobox, DropdownList } from 'react-widgets';
import { useHistory, Link } from 'react-router-dom';
import stateList from '../Components/stateList';

export default props => {
    const [locationInfo, setLocationInfo] = useState({
        "accountLocationId": 0,
        "accountId": 0,
        "locationName": "",
        "billingAddress1": "",
        "billingAddress2": "",
        "billingCity": "",
        "billingState": "",
        "billingZip": "",
        "shippingAddress1": "",
        "shippingAddress2": "",
        "shippingCity": "",
        "shippingState": "",
        "shippingZip": "",
        "enabled": true,
        "jobLocation": false
    })
    const [primaryContact, setPrimaryContact] = useState([])
    const [show, setShow] = useState(false)
    const [allContacts, setAllContacts] = useState([])
    const [removeContacts, setRemoveContacts] = useState([])
    const [contacts, setContacts] = useState([]);
    const [selectContact, setSelectContact] = useState(null);
    const [remConf, setRemConf] = useState(false);

    const [sameBilling, setSameBilling] = useState(false);


    const handleToggle = () => {
        let s = { ...locationInfo }
        if (sameBilling) {
            s.shippingAddress1 = ""
            s.shippingAddress2 = ""
            s.shippingCity = ""
            s.shippingState = ""
            s.shippingZip = ""
        } else {
            s = changeShipping(s)
        }
        setLocationInfo(s)
        setSameBilling(!sameBilling);
    }



    const changeShipping = (state) => {
        state.shippingAddress1 = state.billingAddress1
        state.shippingAddress2 = state.billingAddress2
        state.shippingCity = state.billingCity
        state.shippingState = state.billingState
        state.shippingZip = state.billingZip
        return state
    }


    useEffect(() => {
        if (props.locid) {
            loadData(parseInt(props.locid))
        }
    }, [props.locid])

    const changeLocation = (key, value) => {
        let s = { ...locationInfo };
        s[key] = value
        if(sameBilling){
            let shipping = changeShipping(s)
            setLocationInfo(shipping)
        } else {
            setLocationInfo(s)
        }
    }

    const loadData = (id) => {
        if (id === 0) {
            return;
        }
        GetLocationProfile(id)
            .then(res => {
                setLocationInfo(res.locationInfo)
                setContacts(res.locationContacts)
                if (res.locationInfo.pocId !== 0) {
                    setPrimaryContact([{
                        "accountContactId": res.locationInfo.pocId,
                        "firstName": res.locationInfo.pocFirstName,
                        "lastName": res.locationInfo.pocLastName,
                        "phoneNumber": res.locationInfo.pocPhoneNumber,
                        "email": res.locationInfo.pocEmail,
                        "enabled": true
                    }])
                }
            })
    }

    const loadContactList = () => {
        setSelectContact(null)
        GetAccountContacts(parseInt(props.accountId))
            .then(res => {
                let contactIds = []
                contacts.map(e => contactIds.push(e.accountContactId));
                primaryContact.map(m => contactIds.push(m.accountContactId))
                let filteredList = res.data.filter(e => contactIds.indexOf(e.accountContactId) === -1)
                setAllContacts(filteredList)
            })
        setShow(true)
    }

    const setPrimary = (contact, index) => {
        let newContacts = [...contacts];
        primaryContact.map(m => newContacts.push(m))
        newContacts.splice(index, 1)
        setContacts(newContacts)
        setPrimaryContact([contact])
    }

    const removeContact = (i) => {
        let newContacts = [...contacts];
        let removed = newContacts.splice(i, 1)
        let removedList = [...removeContacts]
        removedList.push(removed[0].accountContactId)
        setRemoveContacts(removedList)
        setContacts(newContacts)
    }

    const history = useHistory();
    const saveLocation = () => {
        let payload = { ...locationInfo };
        let contactIdList = [];
        contacts.map(m => contactIdList.push(m.accountContactId))
        if (payload.accountLocationId === 0) {
            payload.accountId = parseInt(props.accountId);
        }
        payload.contactIdList = contactIdList;
        if (primaryContact.length > 0) {
            payload.primaryPOC = primaryContact[0].accountContactId;
        }
        payload.removedContacts = removeContacts;
        SaveLocation(payload)
            .then(res => {
                if (res.success) {
                    history.push(`${props.url}/${props.accountId}/Location/${res.locationId}`)
                }
            })
    }

    const addContact = () => {
        let newContacts = [...contacts]
        allContacts.forEach(e => {
            if (e.accountContactId === parseInt(selectContact)) {
                newContacts.push(e)
            }
        })
        setContacts(newContacts)
        setShow(false)
    }

    const deactivateLocation = () => {
        let payload = { ...locationInfo }
        payload.enabled = false;
        SaveLocation(payload)
            .then(res => {
                if (res.success) {
                    history.push(`${props.url}/${props.accountId}/Location`)
                }
            })
    }


    return (
        <div className="container-fluid">
            <Breadcrumb className="breadcrumb">
                <li className="breadcrumb-item"><Link to={`${props.url}`}>Accounts List</Link></li>
                <li className="breadcrumb-item"><Link to={`${props.url}/${props.accountId}`}>Account</Link></li>
                {locationInfo.accountLocationId !== 0 && <li className="breadcrumb-item"><Link to={`${props.url}/${props.accountId}/Location/${locationInfo.accountLocationId}`}>Location Profile</Link></li>}
                <Breadcrumb.Item active>Edit Location</Breadcrumb.Item>
            </Breadcrumb>
            <div className="container">
                <form action="#" id="Location_Form" onSubmit={(e) => { e.preventDefault(); saveLocation() }}>
                    <div className="row mt-3 p-2">
                        <div className="col">
                            <div className="row">
                                <div className="col">
                                    <h5>Account Location</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row my-3">

                        <div className="col text-right">
                            <div className="toggle-switch small-switch">
                                <input
                                    type="checkbox"
                                    className="toggle-switch-checkbox"
                                    id="location"
                                    checked={locationInfo.jobLocation}
                                    onChange={() => changeLocation('jobLocation', !locationInfo.jobLocation)}
                                />
                                <label className="toggle-switch-label" htmlFor="location">
                                    <span className={"toggle-switch-inner"} />
                                    <span
                                        className={"toggle-switch-switch"}
                                    />
                                </label>
                            </div>
                            &nbsp; &nbsp;Job Location
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="row">
                                <div className="col">
                                    <small>Location Name</small>
                                    <input type="text"
                                        required className="enc-input-100"
                                        value={locationInfo.locationName}
                                        placeholder="Location Name"
                                        onChange={e => changeLocation('locationName', e.target.value)} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="toggle-switch small-switch">
                                        <input
                                            type="checkbox"
                                            className="toggle-switch-checkbox"
                                            id="disabled"
                                            checked={sameBilling}
                                            onChange={handleToggle}
                                        />
                                        <label className="toggle-switch-label" htmlFor="disabled">
                                            <span className={"toggle-switch-inner"} />
                                            <span
                                                className={"toggle-switch-switch"}
                                            />
                                        </label>
                                    </div>
                    &nbsp; &nbsp;Use Same Shipping/Billing Address
                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="row mb-1">
                                        <div className="col">
                                            <small>Billing Address</small>
                                            <input type="text"
                                                className="enc-input-100"
                                                name="billingAddress1"
                                                value={locationInfo.billingAddress1}
                                                placeholder="Billing Address"
                                                onChange={e => changeLocation('billingAddress1', e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col">
                                            <input type="text"
                                                className="enc-input-100"
                                                name="billingAddress2"
                                                value={locationInfo.billingAddress2}
                                                onChange={e => changeLocation('billingAddress2', e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <small>City</small>
                                            <input type="text"
                                                className="enc-input-100"
                                                name="billingCity"
                                                value={locationInfo.billingCity}
                                                placeholder="City"
                                                onChange={e => changeLocation('billingCity', e.target.value)} />
                                        </div>
                                        <div className="col">
                                            <small>State</small>
                                            <DropdownList
                                                data={stateList}
                                                name="billingState"
                                                value={locationInfo.billingState}
                                                placeholder="State"
                                                onChange={e => changeLocation('billingState', e)}
                                            />
                                        </div>
                                        <div className="col">
                                            <small>Zip Code</small>

                                            <input
                                                type="text"
                                                className="enc-input-100"
                                                name="billingZip"
                                                value={locationInfo.billingZip}
                                                placeholder="Zip Code"
                                                onChange={e => changeLocation('billingZip', e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="row mb-1">
                                        <div className="col">
                                            <small>Shipping Address</small>
                                            <input
                                                type="text"
                                                className="enc-input-100"
                                                disabled={sameBilling}
                                                name="shippingAddress1"
                                                value={locationInfo.shippingAddress1}
                                                placeholder="Shipping Address"
                                                onChange={e => changeLocation('shippingAddress1', e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col">
                                            <input type="text"
                                                className="enc-input-100"
                                                disabled={sameBilling}
                                                name="shippingAddress2"
                                                value={locationInfo.shippingAddress2}
                                                onChange={e => changeLocation('shippingAddress2', e.target.value)} />

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <small>City</small>
                                            <input
                                                type="text"
                                                className="enc-input-100"
                                                disabled={sameBilling}
                                                name="shippingCity"
                                                value={locationInfo.shippingCity}
                                                placeholder="City"
                                                onChange={e => changeLocation('shippingCity', e.target.value)}
                                            />
                                        </div>
                                        <div className="col">
                                            <small>State</small>
                                            <DropdownList
                                                data={stateList}
                                                disabled={sameBilling}
                                                name="shippingState"
                                                value={locationInfo.shippingState}
                                                placeholder="State"
                                                onChange={e => changeLocation('shippingState', e)}
                                            />
                                        </div>
                                        <div className="col">
                                            <small>Zip Code</small>
                                            <input type="text"
                                                className="enc-input-100"
                                                disabled={sameBilling}
                                                name="shippingZip"
                                                value={locationInfo.shippingZip}
                                                placeholder="Zip Code"
                                                onChange={e => changeLocation('shippingZip', e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div className="container-fluid mt-2">
                <div className="row mt-2">
                    <div className="col text-center">
                        <h3>Primary Contact</h3>
                    </div>
                </div>
                <div className="row mt-2 justify-content-center">
                    {primaryContact.length > 0 ? primaryContact.map(c =>
                        <Card key={`contact-${c.accountContactId}`} style={{ width: '18rem' }}>
                            <Card.Header><strong>{`${c.firstName} ${c.lastName}`}</strong></Card.Header>
                            <Card.Body>
                        <Card.Text className="mt-2">
                            {c.phoneNumber &&
                                <>
                                    <Card.Subtitle>
                                        <small>Phone Number</small>
                                    </Card.Subtitle>
                                    <Card.Link href={`tel:${c.phoneNumber}`}>{c.phoneNumber}</Card.Link>
                                </>
                            }

                            {c.mobileNumber &&
                                <>
                                    <Card.Subtitle>
                                        <small>Mobile Number</small>
                                    </Card.Subtitle>
                                    <Card.Link href={`tel:${c.mobileNumber}`}>{c.mobileNumber}</Card.Link>
                                </>
                            }

                            {c.officeNumber &&
                                <>
                                    <Card.Subtitle>
                                        <small>Office Number</small>
                                    </Card.Subtitle>
                                    <Card.Link href={`tel:${c.officeNumber}`}>{c.officeNumber}</Card.Link>
                                </>
                            }

                            {c.otherNumber &&
                                <>
                                    <Card.Subtitle>
                                        <small>Other Number</small>
                                    </Card.Subtitle>
                                    <Card.Link href={`tel:${c.otherNumber}`}>{c.otherNumber}</Card.Link>
                                </>
                            }


                            <Card.Subtitle>
                                <small>Email</small>
                            </Card.Subtitle>
                            <Card.Link href={`mailto:${c.email}`}>{c.email}</Card.Link>
                        </Card.Text>
                    </Card.Body>
                        </Card>
                    ) :
                        <div className="col text-center">
                            No Primary Contact Set
                        </div>
                    }
                </div >
            </div >
            <div className="container-fluid mt-2">
                <div className="row mt-2">
                    <div className="col text-center">
                        <h3>Location Contacts</h3>
                        <Button onClick={loadContactList} className="enc-button">Add Contact</Button>
                    </div>
                </div>
                <div className="row mt-2">
                    {contacts.map((c, index) =>
                        <div className="col-2 p-2" key={`contact-${c.accountContactId}`}>
                            <Card style={{ width: '18rem' }}>
                                <Card.Header><strong>{`${c.firstName} ${c.lastName}`}</strong></Card.Header>
                                <Card.Body>
                                    <Card.Subtitle><strong>Phone Number</strong></Card.Subtitle>
                                    <Card.Link href={`tel:${c.phoneNumber}`}>{c.phoneNumber}</Card.Link>
                                </Card.Body>
                                <Card.Body>
                                    <Card.Subtitle><strong>Email</strong></Card.Subtitle>
                                    <Card.Link href={`mailto:${c.email}`}>{c.email}</Card.Link>
                                </Card.Body>
                                <Card.Footer>
                                    <div className="row">
                                        <div className="col">
                                            <Button variant="danger"  onClick={() => removeContact(index)}><small>Remove</small></Button>
                                        </div>
                                        <div className="col text-right">
                                            <Button className="enc-button" onClick={() => setPrimary(c, index)}><small>Set Primary</small></Button>
                                        </div>
                                    </div>
                                </Card.Footer>
                            </Card>
                        </div>
                    )}
                </div >
                <div className="row mt-2 mb-5">
                    <div className="col text-right">
                        {locationInfo.accountLocationId !== 0 ? <Button variant="danger" onClick={() => setRemConf(true)}>Delete Location</Button> : null}

                        <Button className="enc-button ml-2" form="Location_Form" type='submit'>Save Changes</Button>
                    </div>
                </div>
            </div >
            <Modal show={show} onHide={() => setShow(false)}>
                <form action="#" onSubmit={(e) => { e.preventDefault(); addContact() }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Contact to Location</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Combobox
                            data={allContacts}
                            textField='fullName'
                            valueField='accountContactId'
                            value={selectContact}
                            onChange={con => setSelectContact(con.accountContactId)}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="enc-button" onClick={() => setShow(false)}>
                            Cancel
                    </Button>
                        <Button type='submit' className="enc-button" >
                            Add Contact
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            <Modal show={remConf} onHide={() => setRemConf(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Removal</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    This will delete this contact. Please confirm you would like to take this action.
                    </Modal.Body>
                <Modal.Footer>
                    <Button className="enc-button" onClick={() => setRemConf(false)}>
                        Cancel
                    </Button>
                    <Button className="enc-button" onClick={deactivateLocation} >
                        Confirm
                        </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
