import APIRequest from './MainAPICall/api';
import config from '../APIConfig';

export const GetInventory = (params) => {
    return APIRequest({
        url: config.baseURL + `/InventoryTransfer/Inventory?id=${params.id}&type=${params.type}&source=${params.source}`,
        method: "get"
    })
}

export const GetInventoryEditTransfer = (params) => {
    return APIRequest({
        url: config.baseURL + `/InventoryTransfer/InventoryEditTransfer?id=${params.id}&type=${params.type}`,
        method: "get"
    })
}


export const GetLocations = (type) => {
    return APIRequest({
        url: config.baseURL + `/InventoryTransfer/Location?type=${type}`,
        method: "get"
    })
}
export const GetTransferRequest = (id) => {
    return APIRequest({
        url: config.baseURL + `/InventoryTransfer/TransferRequest?id=${id}`,
        method: "get"
    })
}
export const PostTransferRequest = (payload) => {
    return APIRequest({
        url: config.baseURL + `/InventoryTransfer/TransferRequest`,
        method: "post",
        body: payload
    })
}
export const PostReturnAll = (payload) => {
    return APIRequest({
        url: config.baseURL + `/InventoryTransfer/ReturnAll`,
        method: "post",
        body: payload
    })
}
export const GetJobTransferList = (type, id) => {
    return APIRequest({
        url: config.baseURL + `/InventoryTransfer/TransferList?id=${id}&type=${type}`,
        method: "get"
    })
}
export const GetTransferList = (Type) => {
    return APIRequest({
        url: config.baseURL + `/InventoryTransfer/TransferList?type=${Type}`,
        method: "get"
    })
}
export const GetBillOfLadingList = (historical) => {
    return APIRequest({
        url: config.baseURL + `/InventoryTransfer/BillOfLadingList?historical=${historical}`,
        method: "get"
    })
}
export const PostShipItems = (payload) => {
    return APIRequest({
        url: config.baseURL + `/InventoryTransfer/ShipItems`,
        method: "post",
        body: payload
    })
}

export const GetBillOfLading = (id) => {
    return APIRequest({
        url: config.baseURL + `/InventoryTransfer/BillOfLading?id=${id}`,
        method: "get"
    })
}
export const PostBillOfLading = (payload) => {
    return APIRequest({
        url: config.baseURL + `/InventoryTransfer/BillOfLading`,
        method: "post",
        body: payload
    })
}

export const CloseRequest = (id) => {
    return APIRequest({
        url: config.baseURL + `/InventoryTransfer/CloseRequest?id=${id}`,
        method: "get"
    })
}
export const PostCheckIn = (payload) => {
    return APIRequest({
        url: config.baseURL + `/InventoryTransfer/CheckIn`,
        method: "post",
        body: payload
    })
}

export const GetEditTransfer = (id) => {
    return APIRequest({
        url: config.baseURL + `/InventoryTransfer/EditTransfer?id=${id}`,
        method: "get"
    })
}
export const PostEditTransfer = (payload) => {
    return APIRequest({
        url: config.baseURL + `/InventoryTransfer/EditTransfer`,
        method: "post",
        body: payload
    })
}
export const CancelTransferRequest = (id) => {
    return APIRequest({
        url: config.baseURL + `/InventoryTransfer/CancelTransferRequest?id=${id}`,
        method: "get"
    })
}

export const TransferYards = () => {
    return APIRequest({
        url: config.baseURL + `/InventoryTransfer/Yards`,
        method: "get"
    })
}
export const TransferJobs = (id) => {
    return APIRequest({
        url: config.baseURL + `/InventoryTransfer/ActiveJobs`,
        method: "get"
    })
}
export const GetJobsWithInventory = (id) => {
    return APIRequest({
        url: config.baseURL + `/InventoryTransfer/ActiveJobsWithInventory`,
        method: "get"
    })
}

export const GetJobsWithInventoryAccountInformation = (id) => {
    return APIRequest({
        url: config.baseURL + `/InventoryTransfer/ActiveJobsWithInventoryWithAccount`,
        method: "get"
    })
}

export const AvailableYardInventory = (id) => {
    return APIRequest({
        url: config.baseURL + `/InventoryTransfer/AvailableYardInventory?id=${id}`,
        method: "get"
    })
}

export const AvailableJobInventory = (id) => {
    return APIRequest({
        url: config.baseURL + `/InventoryTransfer/AvailableJobInventory?id=${id}`,
        method: "get"
    })
}

export const GetInvetoryExcelTemplate = () => {
    let download = require('downloadjs')

    let token;
            
    const LS = localStorage.getItem("BAENCToken");
    const SS = sessionStorage.getItem("BAENCToken");

    if(LS){
        token = LS;
    } else if (SS) {
        token = SS;
    }

    fetch(`${config.baseURL}/InventoryTransfer/InventoryExcel`, {
        method: "get",
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then(res=>res.blob())
    .then(blob => {
        download(blob, "InventoryUploadTemplate.xlsx")})
}
