import React, { useState, useEffect } from 'react';
import { GetCall, ChangeCallStatus } from '../API/Call';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

export default props => {
    const [call, setCall] = useState({
        "callId": 0,
        "callTime": "",
        "callerName": "",
        "companyName": "",
        "contactEmail": "",
        "contactPhone": "",
        "description": "",
        "jobLocation": "",
        "active": true
    })
    const [account, setAccount] = useState({
        "accountId": 0,
        "accountName": "",
        "accountNumber": "",
        "enabled": true,
    })

    useEffect(() => {
        LoadCallData(parseInt(props.callId))
    }, [props.callId])

    const LoadCallData = (id) => {
        GetCall(id)
            .then(res => {
                let payload = res.call;
                payload.callTime = new Date(res.call.callTime)
                setCall(payload)
                setAccount(res.account)
            })
    }

    let history = useHistory();
    const CloseCall = () => {
        if(window.confirm("Close call without creating an opportunity?")){
            let payload = {
                callId: parseInt(props.callId),
                active: false
            }
            ChangeCallStatus(payload)
            .then(res=>{
                history.push(props.url);
            })
        }
    }

    const findAccount = (acc, fa) => {
        let obj = {}
        if(acc && acc.accountId !== 0){
            obj.accountId = acc.accountId;
            obj.accountName = acc.accountName;
            fa(obj)
        }
        

    }
    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    Call Time: {call.callTime.toLocaleString()}
                </div>
            </div>
            <div className="row">
                <div className="col">
                    Name: {call.callerName}
                </div>
                <div className="col">
                    Company: {call.companyName}
                </div>
            </div>
            <div className="row">
                <div className="col">
                    Email: {call.contactEmail}
                </div>
                <div className="col">
                    Phone: {call.contactPhone}
                </div>
            </div>
            <div className="row">
                <div className="col">
                    Location: {call.jobLocation}
                </div>
            </div>
            <div className="row">
                <div className="col">
                    Description: <br /> {call.description}
                </div>
            </div>
            <div className="row">
                <div className="col">
                    {call.active && <Button onClick={CloseCall} className="enc-button">Close Call</Button>}
                </div>
                <div className="col">
                    {account && props.findAccount && <Button className="enc-button" onClick={() => findAccount(account, props.findAccount)}>Find Account</Button>}
                </div>
            </div>
        </div>

    )
}
