import React, { useEffect, useState } from 'react';
import { GetScopeBuilder } from '../../API/JobScope';
import { DropdownList } from 'react-widgets';
import { Alert, Button } from 'react-bootstrap';

export default props => {
    const [scopeBuilder, setScopeBuilder] = useState([])
    const [selected, setSelected] = useState([null])
    //const [description, setDescription] = useState("")
    //const [quantity, setQuantity] = useState(0)
    //const [optional, setOptional] = useState(false)
    const [nullFlag, setNullFlag] = useState(false);

    useEffect(() => {
        GetScopeBuilder()
            .then(res => {
                let data = res.data;
                let sb = data.filter(s => s.itemName === "Mitigation"); // only from mitigation scope
                setScopeBuilder(sb)
            })
    }, [])

    const changeScope = (tier, value) => {
        let s = [...selected]
        if (selected.length >= tier + 1) {
            s.splice(tier, selected.length - tier, value)
            if(value.children && value.children.length > 0){
                s.push(null)
            }
        } else {
            s[tier] = (value)
            if(value.children && value.children.length > 0){
                s.push(null)
            }
        }
        setSelected(s)
    }

    const loadScope = (sc, tier = 0, arr = []) => {
        arr.push({ data: sc, tier: tier})
        let select;
        if (selected[tier]) {
            let scSelect = sc.filter(scope => scope.scopeBuilderId === selected[tier].scopeBuilderId)[0];

            let removeItems = []; // removes any children that include STC 25 or STC 32

            for (let i = 0; i < scSelect.children.length; i++) {
                if (scSelect.children[i].itemName === 'STC 25' || scSelect.children[i].itemName === 'STC 32') {
                    //removeItems.push(scSelect.children[i].scopeBuilderId);
                    removeItems.push(scSelect.children[i]);
                }
            }

            for (let i = 0; i < removeItems.length; i++) {
                //let index = scSelect.children.indexOf(c => c.scopeBuilderId === removeItems[i]);
                let index = scSelect.children.indexOf(removeItems[i]);
                scSelect.children.splice(index, 1);
            }

            select = scSelect;
        }
        if (select && select.children && select.children.length > 0) {
            loadScope(select.children, tier + 1, arr)
        }
        return arr;
    }

    const buildPayload = () => {
        let payload = {}
        payload.selections = [...selected]
        //payload.quantity = quantity
        //payload.description = description
        //payload.optional = optional
        if (props.jsaID !== 'new') {
            payload.jsaID = parseInt(props.jsaID)
        } else {
            payload.jsaID = parseInt(-1)
        }
        return payload
    }

    const AddProduct = () => {
        //if(selected.includes(null)){
        //    console.log(selected)
        //    setNullFlag(true);
        //    return;
        //}
        let payload = buildPayload();
        props.addProduct(payload)
        //.then(res=>{
        //    //setOptional(false)
        //    setSelected([])
        //    //setDescription("")
        //    //setQuantity(0)
        //})
        setSelected([])
    }

    //const validateQty = (e) => {
    //    const reg = RegExp('[^\\d]')
    //    let value = e.target.value.replace(reg,'')
    //    if(value === ''){
    //        setQuantity("")
    //    } else {
    //        setQuantity(parseInt(value))
    //    }
    //}

    return (
        <div>
            {/*<form action="#" onSubmit={(e) => { e.preventDefault(); AddProduct() }}>*/}
                <div className='row my-2'>
                {loadScope(scopeBuilder).map((m, i) => {
                        return <div key={`dd-${i}`} className="col-6 mt-1">
                            <DropdownList
                                data={m.data}
                                onChange={e => changeScope(m.tier, e)}
                                value={selected[m.tier]}
                                textField='itemName'
                                placeholder="Select a value"
                                required
                            />
                        </div>
                    })}
                </div>
                {/*<div className="row">*/}
                {/*    <div className="col-2">*/}
                {/*        <label>Internal Description</label>*/}
                {/*    </div>*/}
                {/*    <div className="col">*/}
                {/*        <textarea*/}
                {/*            value={description}*/}
                {/*            onChange={e => setDescription(e.target.value)}*/}
                {/*            className="enc-textarea"*/}
                {/*            placeholder="Insert Description Here..."*/}
                {/*            required*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="row">*/}
                {/*    <div className="col-2">*/}
                {/*        <label>Quantity</label>*/}
                {/*    </div>*/}
                {/*    <div className="col-5">*/}
                {/*        <input*/}
                {/*            type='text'*/}
                {/*            value={quantity}*/}
                {/*            onChange={validateQty}*/}
                {/*            className="enc-input-100"*/}
                {/*            placeholder="0"*/}
                {/*            required*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*    <div className="col-4">*/}
                {/*    <div className="toggle-switch small-switch">*/}
                {/*        <input*/}
                {/*            type="checkbox"*/}
                {/*            className="toggle-switch-checkbox"*/}
                {/*            id="terms"*/}
                {/*            checked={optional}*/}
                {/*            onChange={() => setOptional(!optional)}*/}
                {/*        />*/}
                {/*        <label className="toggle-switch-label" htmlFor="terms">*/}
                {/*            <span className={"toggle-switch-inner"} />*/}
                {/*            <span*/}
                {/*                className={"toggle-switch-switch"}*/}
                {/*            />*/}
                {/*        </label>*/}
                {/*    </div>*/}
                {/*    &nbsp; &nbsp; Optional*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="row">
                    <div className="col-8">
                        {/*<Alert variant="danger" show={nullFlag} onClose={() => setNullFlag(false)} dismissible>*/}
                        {/*    Please select all values until you are no longer prompted.*/}
                        {/*</Alert>*/}
                    </div>
                    <div className="col text-right">
                        <Button type='submit' className="enc-button" onClick={AddProduct}>Add Product</Button>
                    </div>
                </div>
            {/*</form>*/}
        </div>
    )
}
