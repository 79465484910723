import React, { useEffect, useState } from 'react';
import { Alert, Button, ListGroup, Spinner, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { DropdownList } from 'react-widgets';
import { GetBudgetTemplateItemsList, PostBulkTemplate } from '../API/BudgetTemplate';
import { GetScopeBuilderNoOther } from '../API/JobScope';
import { ProposalListNames } from '../API/Proposal';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ConfirmDelete from '../Components/ConfirmDelete';
import { GetSequenceActions, GetSequenceAction, AddSequenceAction, EditSequenceAction, RemoveSequenceAction } from '../API/JSA';
import { GetPotentialRisks, PostBulkAction } from '../API/JSA';

export default props => {
    const [filter, setFilter] = useState("")

    const [confirmDeleteItem, setConfirmDeleteItem] = useState(false);
    const [actionId, setActionId] = useState(0);

    const [actions, setActions] = useState([]);

    const [action, setAction] = useState("");

    const [openEditModal, setOpenEditModal] = useState(false);
    const [actionItem, setActionItem] = useState("");
    const [actionItemId, setActionItemId] = useState(0);

    const [working, setWorking] = useState(false);
    const [confirm, setConfirm] = useState(false);

    const [allRisks, setAllRisks] = useState([])
    const [selectedRisk, setSelectedRisk] = useState(null)

    const [selectedActions, setSelectedActions] = useState([]);

    let productsLink = "/Admin/ProductRisks";

    useEffect(() => {
        LoadActions();
        LoadRisks();
    }, [])

    const LoadActions = () => {
        GetSequenceActions().then(res => {
            setActions(res.data)
        })
    }

    const LoadRisks = () => {
        GetPotentialRisks().then(res => {
            setAllRisks(res.data)
        })
    };

    const AddAction = () => {
        if (action !== "" && action !== null && action !== " ") {
            let payload = {
                sequenceActionId: 0,
                action: action
            };
            AddSequenceAction(payload).then(res => {
                if (res.success === true) {
                    setAction("");
                    LoadActions();
                }
            }).catch(err => {
                //
            });
        }
    }

    const openEditActionModal = (id) => {
        GetSequenceAction(id).then((res) => {
            if (res.success === true) {
                setActionItem(res.action.action);
                setActionItemId(res.action.sequenceActionId);
                setOpenEditModal(true);
            }
        }).catch((err) => {
            //
        });
    }

    const editActionModal = () => {
        return (
            <Modal show={openEditModal} onHide={() => setOpenEditModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Action</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <input
                        className="enc-input-100"
                        value={actionItem}
                        onChange={(e) => setActionItem(e.target.value)}
                        required
                    />
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setOpenEditModal(false)}>Close</Button>
                    <Button variant="danger" onClick={EditAction}>Confirm</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const EditAction = () => {
        EditSequenceAction({ sequenceActionId: Number(actionItemId), action: actionItem }).then((res) => {
            if (res.success === true) {
                setOpenEditModal(false);
                LoadActions();
            }
        }).catch((err) => {
            //
        });
    }

    const confirmRemoveItem = (id) => {
        setActionId(id);
        setConfirmDeleteItem(true);
    }

    const RemoveAction = (index) => {
        RemoveSequenceAction(actionId).then(res => {
            setConfirmDeleteItem(false);
            setActionId(0);
            LoadActions();
        }).catch(err => {
            setConfirmDeleteItem(false);
        });
    }

    const actionCbList = (e) => {
        let cb = [...selectedActions];
        if (e.target.checked === true) {
            cb.push(Number(e.target.value));
        } else {
            let index = cb.indexOf(Number(e.target.value));
            cb.splice(index, 1);
        }
        setSelectedActions(cb);
    }

    const BulkAddAction = () => {
        setWorking(true);
        //console.log(selectedRisk)
        //console.log(selectedActions.length)
        if (selectedRisk && selectedActions.length > 0) {
            let payload = {
                selectedActions: selectedActions,
                selectedRisk: selectedRisk,
            }
            PostBulkAction(payload)
                .then(res => {
                    if (res.success === true) {
                        setSelectedActions([])
                        setSelectedRisk(null)
                        setConfirm(true);
                    }
                })
        }
        setWorking(false);
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h3>Actions</h3>
                </div>
            </div>

            <div className="divider my-2" />

            <div className="row">
                <div className="col">
                    <h4>Bulk Action Builder</h4>
                </div>
            </div>

            <div>
                <form action="#" onSubmit={(e) => { e.preventDefault(); BulkAddAction() }}>
                    <div className="row">
                        <div className="col">
                            <small>Select a Risk for Bulk Operation</small>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <DropdownList
                                filter="contains"
                                data={allRisks}
                                placeholder="Select a Risk"
                                textField="risk" // change
                                value={selectedRisk}
                                onChange={e => setSelectedRisk(e)}
                            />
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col">
                            <small>Check a box for an action to bulk add risk.</small>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-8">
                            <Alert variant="success" show={confirm} onClose={() => setConfirm(false)} dismissible>
                                Successfully Bulk Added Risks
                            </Alert>
                        </div>

                        <div className="col text-right">
                            <Button type='submit' className="enc-button" disabled={working}>
                                {working ?
                                    <span>Processing
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </span> : "Bulk Add Risk"}
                            </Button>
                        </div>
                    </div>
                </form>
            </div>

            <div className="divider my-2" />

            <div className="row my-2">
                <div className="col-4">
                    <Button className="enc-button" as={Link} to={`${productsLink}`}>View Products</Button>
                </div>
                <div className="col-4"></div>
                <div className="col-4 text-right">
                    <input placeholder="Filter" className="enc-input-100" value={filter} onChange={e => setFilter(e.target.value)} />
                </div>
            </div>

            <div className="divider my-2" />

            <div className="row my-2">
                <div className="col-10">
                    <input
                        className="enc-input-100"
                        value={action}
                        onChange={(e) => setAction(e.target.value)}
                        required
                    />
                </div>
                <div className="col-2 text-right">
                    <Button className="enc-button" onClick={AddAction}>Add Action</Button>
                </div>
            </div>

            <div className="divider my-2" />

            <ListGroup className="mb-3">
                <ListGroup.Item className="click-list">
                    Sequence of Actions
                </ListGroup.Item>

                {actions.length === 0 && <ListGroup.Item>No Actions Added</ListGroup.Item>}

                {/* link to sequenceActionSingle.js */}
                {actions.filter(x => x.action.toLowerCase().search(filter.toLowerCase()) !== -1).map((m, i) => {
                    return (
                        <ListGroup.Item key={`action-${i}`}>
                            <div className="row">
                                <div className="col-1">
                                    <input type="checkbox" id={`action-${i}`} className='sq' name={m.action} value={m.sequenceActionId} onChange={(e) => { actionCbList(e) } } />
                                    {/* on change - like if checked */}
                                </div>

                                <div className="col-9">
                                    <Link to={`${props.match.url}/${m.sequenceActionId}`}>
                                        {m.action}
                                    </Link>
                                </div>

                                <div className="col-1 text-right">
                                    {editActionModal()}

                                    <Button className="enc-button" onClick={() => openEditActionModal(m.sequenceActionId)}>Edit</Button>
                                </div>

                                <div className="col-1 text-right">
                                    <ConfirmDelete
                                        show={confirmDeleteItem}
                                        setShow={setConfirmDeleteItem}
                                        action={RemoveAction}
                                        message="Confirm you would like to delete action."
                                    />

                                    <FontAwesomeIcon icon={faTrash} className='clickable' onClick={() => confirmRemoveItem(m.sequenceActionId)} />
                                </div>
                            </div>
                        </ListGroup.Item>
                    )
                })}
            </ListGroup>
        </div>
    )
}
