import React, { useEffect, useState } from 'react';
import { GetHandOffProfile } from '../../API/HandOff';

import ViewInfo from './ViewInfo';

import ViewMobilization from './ViewMobilization';
import Attachments from '../../Components/Attachments';
import ViewJobScope from './JobScope/ViewJobScope';
import JobReview from '../../Components/JobReview';

export default props => {
    const [opp, setOpp] = useState({
        "handOffId": 0,
        "price": "",
        "additionalInfo": "",
        "completed": true
    });
    const [mobilization, setMobilization] = useState({
        "mobilizationId": 0,
        "installType": "",
        "removalType": "",
        "totalLoads": 0,
        "installCost": 0,
        "removalCost": 0,
        "leadTime": "",
        "installDate": "",
        "removalDate": ""
    });
    const [displayReview, setDisplayReview] = useState(false)


    useEffect(() => {
        LoadHandOff(parseInt(props.jobId))
    }, [props.jobId])

    const LoadHandOff = (id) => {
        GetHandOffProfile(id)
            .then(res => {
                setOpp(res.handOff);
                setMobilization(res.mobilization);
                setDisplayReview(res.displayReview)
            })
    }

    const reload = () => {
        props.reload();
        LoadHandOff(parseInt(props.jobId))
    }


    return (
        <>
            <ViewJobScope handOffId={opp.handOffId} />
            <div className="divider my-3" />
            <div className="row">
                <div className="col">
                    <h4>Mobilization Information</h4>
                </div>
            </div>
            <ViewMobilization data={mobilization} />
            <div className="divider my-3" />
            <div className="row">
                <div className="col">
                    <h4>Additional Information</h4>
                </div>
            </div>
            <ViewInfo additionalInfo={opp.additionalInfo} prevailingWage={opp.prevailingWage} />
            <div className="divider my-3" />
            <Attachments 
                jobId={props.jobId}
                jobStep={1}
            />

            {displayReview && <JobReview reloadParent={reload}  jobId={props.jobId} />}
        </>
    )
}

