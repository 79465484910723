import React, { useState, useEffect } from 'react';
import { CheckPhonePermission, GetPhoneTypes } from '../API/User';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import { faStar as FullStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as EmptyStar } from '@fortawesome/free-regular-svg-icons';
import { Modal, ListGroup } from 'react-bootstrap';

export default props => {
    const [editPerm, setEditPerm] = useState(false);
    const [editPhone, setEditPhone] = useState({
        userId: 0,
        phoneNumberId: 0,
        phoneNumber: "",
        primary: false,
        typeId: 0
    });
    const [editing, setEditing] = useState(false)
    const [phoneTypes, setPhoneTypes] = useState([]);

    useEffect(() => {
        loadPhoneTypes();
    }, [])

    useEffect(() => {
        VerifyPhoneEdit()
    }, [])

    const VerifyPhoneEdit = () => {
        CheckPhonePermission()
            .then(res => {
                setEditPerm(res.success)
            })
    }

    const savePhone = () => {
        let payload = { ...editPhone }
        payload.typeId = parseInt(editPhone.typeId)
        props.savePhone(payload)
        setEditing(false)
    }

    const handleEditClick = (obj) => {
        setEditing(true)
        setEditPhone(obj)
    }
    const makeNewPhone = () => {
        setEditing(true)
        setEditPhone({
            userId: 0,
            phoneNumberId: 0,
            phoneNumber: "",
            primary: false,
            typeId: 0
        })
    }
    const loadPhoneTypes = () => {
        GetPhoneTypes()
            .then(res => {
                if (res.success) {
                    setPhoneTypes(res.phoneTypeList);
                }
            })
    }

    const changeEditPhone = (e) => {
        let newPhone = { ...editPhone }
        newPhone[e.target.name] = e.target.value
        setEditPhone(newPhone)
    }

    const changePrimary = () => {
        let newPhone = { ...editPhone }
        newPhone.primary = !editPhone.primary
        setEditPhone(newPhone)
    }

    return (
        <div className="container-fluid">
            <ListGroup>
                <ListGroup.Item className="enc-list">
                    <div className="row">
                        <div className="col">
                            Phone List:
                        </div>
                        <div className="col text-right">
                            <FontAwesomeIcon icon={faPlus} onClick={makeNewPhone} className="clickable" />
                        </div>
                    </div>
                </ListGroup.Item>
                {Object.values(props.phones).map((phone, index) => {
                    return (
                        <ListGroup.Item className="enc-list" key={`phone-list-${index}`}>
                            <div  className="row" name={index}>
                                <div className="col-1">
                                    {phone.primary ? <FontAwesomeIcon className="clickable" icon={FullStar} /> : null}
                                </div>
                                <div className="col-5">{phone.phoneNumber}</div>
                                <div className="col-4">{phone.typeName}</div>
                                <div className="col-1">
                                    {editPerm ? <FontAwesomeIcon className="clickable" icon={faPencilAlt} id={phone.phoneNumberId} onClick={() => handleEditClick(phone)} /> : null}
                                </div>
                            </div>
                        </ListGroup.Item>
                    )
                })}
            </ListGroup>

            <Modal show={editing} onHide={() => setEditing(false)}>
                <form action="#" onSubmit={(e) => { e.preventDefault(); savePhone() }}>
                    <Modal.Header closeButton>
                        <Modal.Title>{editPhone.phoneNumberId === 0 ? "New Phone" : "Edit Phone"}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="row mb-1">
                            <div className="col-2">{editPhone.primary ?
                                <FontAwesomeIcon icon={FullStar} className="clickable" onClick={changePrimary} /> :
                                <FontAwesomeIcon icon={EmptyStar} className="clickable" onClick={changePrimary} />}</div>
                            <div className="col-10">
                                <input className="enc-input-pe" type="tel" name="phoneNumber" onChange={changeEditPhone} value={editPhone.phoneNumber} required />
                            </div>
                        </div>
                        <div className="row mb-1">
                            <div className="col-2" />
                            <div className="col-10 text-right">
                                <select className="enc-dropdown-pe" name="typeId" value={editPhone.typeId} onChange={changeEditPhone}>
                                    {phoneTypes.map(a =>
                                        <option key={a.typeId} value={a.typeId}>{a.typeName}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <input type="button" className="enc-button" onClick={() => setEditing(false)} value="Cancel" />
                        <input type="submit" className="enc-button" value="Save" />
                    </Modal.Footer>
                </form>
            </Modal>
        </div >
    )
}