import APIRequest from './MainAPICall/api';
import config from '../APIConfig';

export const GetAdminNavigation = () => {
    return APIRequest({
        url: config.baseURL + "/Administrator/GetAdminNavigation",
        method: "get"
    });
} 

export const GetAllUsers = (enabled) => {
    return APIRequest({
        url: config.baseURL + "/Administrator/GetAllUsers",
        method: "post",
        body: {
            enabled: enabled
        }
    });
}

export const GetRoleList = () => {
    return APIRequest({
        url: config.baseURL + "/Administrator/GetRoleList",
        method: "get"
    })
}

export const GetRolePermission = (id) => {
    return APIRequest({
        url: config.baseURL + "/Administrator/GetRolePermission",
        method: "post",
        body: {
            roleId: id
        }
    })
}

export const SaveRole = (payload) => {
    return APIRequest({
        url: config.baseURL + "/Administrator/SaveRole",
        method: "post",
        body: payload
    })
}
