import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { CreateSerializedItem, AssetTypeList, MeterTypeList } from '../../../API/AssetInventory';
import { DropdownList } from 'react-widgets';
import { YardList } from '../../../API/Inventory';
import ReactDatePicker from 'react-datepicker';
import { useHistory } from 'react-router-dom';


export default props => {

    const [meterTypes, setMeterTypes] = useState([]);
    const [assetTypes, setAssetTypes] = useState([]);
    const [yards, setYards] = useState([]);

    const [errorMessage, setErrorMessage] = useState("");

    const [newItem, setNewItem] = useState({
        serialNumber: "",
        yardId: -1,
        manufacturer: "",
        model: "",
        licenses: "",
        purchaseDate: "",
        lastCalibratedDate: "",
        purchasePrice: "",
        assetType: -1,
        meterType: -1
    })

    useEffect(() => {
        LoadMeterTypes();
        LoadAssetTypes();
        LoadYards();
    }, [])

    const LoadYards = () => {
        YardList()
            .then(res => {
                res.data.unshift({
                    yardId: -1,
                    yardName: "Select Yard"
                })
                setYards(res.data)
            })
    }

    const LoadMeterTypes = () => {
        MeterTypeList()
            .then(res => {
                res.data.unshift({
                    meterTypeId: -1,
                    meterTypeName: "Select Meter Type"
                })
                setMeterTypes(res.data)
            })
    }
    const LoadAssetTypes = () => {
        AssetTypeList()
            .then(res => {
                res.data.unshift({
                    assetTypeId: -1,
                    assetTypeName: "Select Asset Type"
                })
                setAssetTypes(res.data)
            })
    }

    let history = useHistory();
    const submitNew = () => {
        let payload = { ...newItem };
        payload.purchasePrice = parseFloat(payload.purchasePrice)
        if (payload.assetType === -1) {
            setErrorMessage("Please select asset type.")
            return;
        }
        if (payload.assetType === 0 && payload.meterType === -1) {
            setErrorMessage("Please select meter type")
            return;
        }
        if (payload.yardId === -1) {
            setErrorMessage("Please select a yard")
            return;
        }
        if (payload.assetType !== 0) {
            payload.meterType = null;
        }
        CreateSerializedItem(payload)
            .then(res => {
                history.push('/Admin/Inventory/Asset')
            });
    }

    const changeNewItem = (key, value) => {
        let s = { ...newItem }
        s[key] = value
        setNewItem(s)
    }


    return (

        <div className="container">
            <div>
                <h4 className="text-center itemHeaderENCLight">Create Asset Item</h4>
            </div>

            <div className="container border border-dark py-3">

                <div className="divider my-2" />
                <form action="#" onSubmit={(e) => { e.preventDefault(); submitNew() }}>
                    <div className="row">
                        <div className="col">
                            <input
                                className="enc-input-100"
                                placeholder="Manufacturer"
                                value={newItem.manufacturer}
                                onChange={(e) => changeNewItem('manufacturer', e.target.value)}
                                required
                            />
                        </div>
                        <div className="col">
                            <input
                                className="enc-input-100"
                                placeholder="Model"
                                value={newItem.model}
                                onChange={(e) => changeNewItem('model', e.target.value)}
                                required
                            />
                        </div>
                        <div className="col">
                            <input
                                className="enc-input-100"
                                placeholder="Serial Number"
                                value={newItem.serialNumber}
                                onChange={(e) => changeNewItem('serialNumber', e.target.value)}
                                required
                            />
                        </div>
                    </div>


                    <div className="row mt-1">
                        <div className="col">
                            <DropdownList
                                data={assetTypes}
                                value={newItem.assetType}
                                placeholder="Asset Type"
                                textField="assetTypeName"
                                valueField="assetTypeId"
                                onChange={(e) => changeNewItem('assetType', e.assetTypeId)}
                            />
                        </div>
                        <div className="col">
                            {newItem.assetType === 0 &&
                                <DropdownList
                                    placeholder="Meter Type"
                                    data={meterTypes}
                                    value={newItem.meterType}
                                    textField="meterTypeName"
                                    valueField="meterTypeId"
                                    onChange={(e) => changeNewItem('meterType', e.meterTypeId)}
                                />
                            }
                        </div>
                        <div className="col">
                            <DropdownList
                                placeholder="Yard Select"
                                data={yards}
                                value={newItem.yardId}
                                textField="yardName"
                                valueField="yardId"
                                onChange={(e) => changeNewItem('yardId', e.yardId)}
                            />
                        </div>
                    </div>
                    <div className="row mt-1">
                        <div className="col-4 date-picker-fix">
                            <ReactDatePicker
                                className="enc-input-100"
                                placeholderText="Purchase Date"
                                selected={newItem.purchaseDate}
                                onChange={(date) => changeNewItem('purchaseDate', date)}
                            />
                        </div>
                        <div className="col-4 date-picker-fix">
                            <ReactDatePicker
                                className="enc-input-100"
                                placeholderText="Last Calibrated Date"
                                selected={newItem.lastCalibratedDate}
                                onChange={(date) => changeNewItem('lastCalibratedDate', date)}
                            />
                        </div>
                        <div className="col-4">
                            <input
                                type="number"
                                className="enc-input-100"
                                placeholder="Purchase Price"
                                value={newItem.purchasePrice}
                                onChange={(e) => changeNewItem('purchasePrice', e.target.value)} />
                        </div>
                    </div>
                    <div className="row mt-1">
                        <div className="col">
                            <textarea
                                className="enc-textarea"
                                placeholder="Licenses"
                                value={newItem.licenses}
                                onChange={(e) => changeNewItem('licenses', e.target.value)} />
                        </div>
                    </div>
                    <div className="row mt-1">
                        <div className='col'>
                            <strong style={{color: 'red'}}>{errorMessage}</strong>
                        </div>
                        <div className="col text-right">
                            <Button className="enc-button" type='submit'>Save Item</Button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
