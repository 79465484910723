import React, { useState } from 'react';
import BillOfLading from './BillOfLading';
import EditBillOfLading from './EditBillOfLading';

export default props => {
    const [edit, setEdit] = useState(false)
    return (
        <div>
            {edit ? <EditBillOfLading {...props} toggle={e => setEdit(false)} /> : <BillOfLading toggle={() => setEdit(true)} {...props} /> }
        </div>
    )
}
