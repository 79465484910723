import APIRequest from './MainAPICall/api';
import config from '../APIConfig';

export const GetReminderList = (id) => {
    return APIRequest({
        url: config.baseURL + `/Reminder/ReminderList?JobId=${id}`,
        method: "get"
    });
}

export const PostReminder = (payload) => {
    return APIRequest({
        url: config.baseURL + `/Reminder/Reminder`,
        method: "post",
        body: payload
    });
}

export const DeleteReminder = (id) => {
    return APIRequest({
        url: config.baseURL + `/Reminder/DeleteReminder?id=${id}`,
        method: "get"
    });
}

export const GetRemindersUsers = () => {
    return APIRequest({
        url: config.baseURL + `/Reminder/RemindersUsers`,
        method: "get"
    });
}