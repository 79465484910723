import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { Combobox, DropdownList } from 'react-widgets';
import { GetAccountList, GetAccountLocations, GetAccountContacts } from '../API/Account';
import { useHistory } from 'react-router-dom';


import stateList from '../Components/stateList';
import { CloneJob } from '../API/JobClone';


export default props => {
    const [cloneInformation, setCloneInformation] = useState({
        "JobId": 0,
        "accountId": 0,
        "accountName": "",
    });

    const [billingLocation, setBillingLocation] = useState({
        "accountLocationId": 0,
        "locationName": "",
        "billingAddress1": "",
        "billingAddress2": "",
        "billingCity": "",
        "billingState": "",
        "billingZip": "",
        "shippingAddress1": "",
        "shippingAddress2": "",
        "shippingCity": "",
        "shippingState": "",
        "shippingZip": "",
        "enabled": true
    });

    const [shippingLocation, setShippingLocation] = useState({
        "accountLocationId": 0,
        "locationName": "",
        "billingAddress1": "",
        "billingAddress2": "",
        "billingCity": "",
        "billingState": "",
        "billingZip": "",
        "shippingAddress1": "",
        "shippingAddress2": "",
        "shippingCity": "",
        "shippingState": "",
        "shippingZip": "",
        "enabled": true
    });
    const [contact, setContact] = useState({
        accountContactId: 0,
        fullName: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        enabled: true
    });

    // Lists for Dropdowns
    const [shippingLocationList, setShippingLocationList] = useState([]);
    const [billingLocationList, setBillingLocationList] = useState([]);
    const [accountList, setAccountList] = useState([]);
    const [contactsList, setContactsList] = useState([]);

    const [error, setError] = useState("")
    /// Objects that build an opportunity.



    // Loading of Data
    useEffect(() => {
        if (cloneInformation.accountId !== 0) {
            loadLocations(parseInt(cloneInformation.accountId))
            loadContacts(parseInt(cloneInformation.accountId))
            clearLocation();
            clearContact();
        } else {
            setBillingLocationList([])
            setShippingLocationList([])
            setContactsList([])
        }
    }, [cloneInformation.accountId])

    useEffect(() => {
        loadLists();
    }, [])

    // Location Methods

    const loadLocations = (id) => {
        GetAccountLocations(id, true)
            .then(res => {
                setShippingLocationList(res.data)
            })
        GetAccountLocations(id, false)
            .then(res => {
                setBillingLocationList(res.data)
            })
    }

    const changeLocation = (key, value) => {
        let s = { ...shippingLocation }
        s[key] = value;
        setShippingLocation(s)
    }

    // Clear Locations
    const clearLocation = () => {
        setShippingLocation({
            "accountLocationId": 0,
            "locationName": "",
            "billingAddress1": "",
            "billingAddress2": "",
            "billingCity": "",
            "billingState": "",
            "billingZip": "",
            "shippingAddress1": "",
            "shippingAddress2": "",
            "shippingCity": "",
            "shippingState": "",
            "shippingZip": "",
            "enabled": true
        })
    }

    const clearBillingLocation = () => {
        setBillingLocation({
            "accountLocationId": 0,
            "locationName": "",
            "billingAddress1": "",
            "billingAddress2": "",
            "billingCity": "",
            "billingState": "",
            "billingZip": "",
            "shippingAddress1": "",
            "shippingAddress2": "",
            "shippingCity": "",
            "shippingState": "",
            "shippingZip": "",
            "enabled": true
        })
    }

    // end

    // Contact Methods

    const changeContact = (key, value) => {
        let s = { ...contact }
        if (s.accountContactId !== 0) {
            if (window.confirm("Changing this field will create a new contact")) {
                s.accountContactId = 0;
                s[key] = value;
                setContact(s)
            }
        } else {
            s[key] = value
            setContact(s)
        }
    }

    const loadContacts = (id) => {
        GetAccountContacts(id)
            .then(res => {
                let payload = [...res.data]
                if (payload.length > 0) {
                    payload.unshift({
                        accountContactId: 0,
                        fullName: "Find Existing Contact"
                    })
                }
                setContactsList(payload)
            })
    }

    const autoFill = (e) => {
        if (e.accountContactId !== 0) {
            setContact(e)
        }
        else {
            clearContact();
        }
    }

    const clearContact = () => {
        setContact({
            accountContactId: 0,
            fullName: "",
            firstName: "",
            lastName: "",
            phoneNumber: "",
            email: "",
            enabled: true
        })
    }

    // end

    const loadLists = () => {
        GetAccountList(true)
            .then(res => {
                setAccountList(res.data)
            })
    }

    const changeAccount = (e) => {
        let state = { ...cloneInformation }
        if (typeof (e) === 'string') {
            if (state.accountId !== 0) {
                if (window.confirm("Changing this field will clear preloaded location and contact info.")) {
                    if (contact.accountContactId !== 0) {
                        clearContact();
                    }
                    if (shippingLocation.accountLocationId !== 0) {
                        clearLocation();
                    }
                    clearBillingLocation();
                } else {
                    return;
                }
            }
            state.accountName = e
            state.accountId = 0

        } else {
            state.accountName = e.accountName;
            state.accountId = e.accountId;
        }
        setCloneInformation(state)
    }

    // Save
    const history = useHistory();
    const SendCloneJobRequest = () => {
        let payload = { ...cloneInformation }
        payload.contact = contact
        payload.shippingLocation = shippingLocation
        payload.billingLocation = billingLocation
        payload.jobId = parseInt(props.jobId)
        CloneJob(payload)
            .then(res => {
                history.push(`/Opportunities/${res.opportunityId}/${res.jobId}`)
            })
            .catch(err => {
                if (err.details && err.details.message) {
                    setError(err.details.message)
                }
            })
    }

    const AccountBlur = () => {
        let accountName = cloneInformation.accountName
        let account = accountList.filter(account => account.accountName === accountName)
        if (account.length === 1) {
            let s = { ...cloneInformation }
            s.accountId = account[0].accountId
            s.accountName = account[0].accountName
            setCloneInformation(s)
        }
    }

    return (

        <div className="container-fluid">

            <form action="#" onSubmit={(e) => { e.preventDefault(); SendCloneJobRequest() }}>
                <div className="container mt-2">
                    <div className="row">
                        <div className="col">
                            <h3>Clone Job</h3>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-2">
                            <strong>Account Name:</strong>
                        </div>
                        <div className="col">
                            <Combobox
                                inputProps={{ required: true, onBlur: () => AccountBlur() }}
                                filter="contains" value={cloneInformation.accountName}
                                valueField='accountName' data={accountList}
                                textField='accountName' onChange={changeAccount}
                                placeholder="Example Company" />
                        </div>
                    </div>


                    <div className="row mt-3">
                        <div className="col-2">
                            <strong>Billing Location:</strong>
                        </div>
                        <div className="col">
                            <DropdownList
                                inputProps={{ required: true }}
                                placeholder="Location Name"
                                textField='locationName'
                                valueField='locationName'
                                value={billingLocation.locationName}
                                data={billingLocationList}
                                onChange={(e) => setBillingLocation(e)}
                            />
                        </div>
                    </div>





                    <div className="row mt-3">
                        <div className="col-2">
                            <strong>Shipping Location:</strong>
                        </div>
                        <div className="col">
                            <DropdownList
                                placeholder="Select Existing Location"
                                textField='locationName'
                                valueField='locationName'
                                value={shippingLocation.locationName}
                                data={shippingLocationList}
                                onChange={(e) => setShippingLocation(e)}
                            />
                        </div>
                        <div className="col-3 text-right">
                            <Button className="enc-button" onClick={clearLocation}>
                                New Address
                        </Button>
                        </div>
                    </div>
                    <div className="divider my-2" />
                    <div className="row mt-3 mb-1">
                        <div className="col">
                            <small>Job Shipping Address</small>
                            <input type="text"
                                className="enc-input-100"
                                name="locationName"
                                value={shippingLocation.locationName}
                                placeholder="Location Name"
                                disabled={shippingLocation.accountLocationId !== 0}
                                onChange={e => changeLocation('locationName', e.target.value)}
                                required
                            />
                        </div>
                    </div>
                    <div className="row mt-3 mb-1">
                        <div className="col">
                            <input type="text"
                                className="enc-input-100"
                                name="shippingAddress1"
                                value={shippingLocation.shippingAddress1}
                                placeholder="Shipping Address"
                                disabled={shippingLocation.accountLocationId !== 0}
                                onChange={e => changeLocation('shippingAddress1', e.target.value)}
                                required
                            />
                        </div>
                    </div>
                    <div className="row mb-1">
                        <div className="col">
                            <input type="text"
                                className="enc-input-100"
                                name="shippingAddress2"
                                value={shippingLocation.shippingAddress2}
                                disabled={shippingLocation.accountLocationId !== 0}
                                onChange={e => changeLocation('shippingAddress2', e.target.value)}
                            />

                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <small>City</small>
                            <input type="text"
                                className="enc-input-100"
                                name="shippingCity"
                                value={shippingLocation.shippingCity}
                                placeholder="City"
                                disabled={shippingLocation.accountLocationId !== 0}
                                onChange={e => changeLocation('shippingCity', e.target.value)}
                                required
                            />
                        </div>
                        <div className="col">
                            <small>State</small>
                            <DropdownList
                                filter="contains"
                                value={shippingLocation.shippingState}
                                disabled={shippingLocation.accountLocationId !== 0}
                                onChange={e => changeLocation('shippingState', e)}
                                data={stateList}
                                required
                            />
                        </div>
                        <div className="col">
                            <small>Zip Code</small>
                            <input type="text"
                                className="enc-input-100"
                                name="shippingZip"
                                value={shippingLocation.shippingZip}
                                placeholder="Zip Code"
                                disabled={shippingLocation.accountLocationId !== 0}
                                onChange={e => changeLocation('shippingZip', e.target.value)}
                                required
                            />
                        </div>
                    </div>




                    <div className="row my-3">
                        <div className="col-2">
                            <strong>Contact:</strong>
                        </div>
                    </div>
                    <div className="row my-1">
                        <div className="col">
                            <DropdownList
                                placeholder="Find Existing Contact"
                                defaultValue={"Find Existing Contact"}
                                onChange={(e) => autoFill(e)}
                                filter="contains"
                                data={contactsList}
                                value={contact}
                                textField="fullName" />
                        </div>
                    </div>
                    <div className="row mb-1">
                        <div className="col">
                            <small>First Name</small>
                            <input type="text"
                                required
                                className="enc-input-100"
                                name="firstName"
                                value={contact.firstName}
                                placeholder="First Name"
                                onChange={e => changeContact('firstName', e.target.value)} />
                        </div>
                        <div className="col">
                            <small>Last Name</small>
                            <input type="text"
                                required
                                className="enc-input-100"
                                name="lastName"
                                value={contact.lastName}
                                placeholder="Last Name"
                                onChange={e => changeContact('lastName', e.target.value)} />
                        </div>
                    </div>
                    <div className="row mb-1">
                        <div className="col">
                            <small>Phone</small>
                            <input type="text"
                                required
                                className="enc-input-100"
                                name="phoneNumber"
                                value={contact.phoneNumber}
                                placeholder="Phone Number"
                                onChange={e => changeContact('phoneNumber', e.target.value)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <small>Email</small>
                            <input type="text"
                                required
                                className="enc-input-100"
                                name="email"
                                value={contact.email}
                                placeholder="Email" onChange={e => changeContact('email', e.target.value)} />
                        </div>
                    </div>
                </div>
                <div className="divider my-2" />



                <div className="row mb-2">
                    <div className="col-8">
                        <span style={{ color: 'red', weight: 'bold' }}>{error}</span>
                    </div>
                    <div className="col text-right">
                        <Button onClick={() => history.goBack()} variant="secondary" className="mr-2">Cancel</Button>
                        <Button type='submit' className="enc-button">Save</Button>
                    </div>
                </div>
            </form>
        </div >

    )
}
