import { faTrash, faFont, faDollarSign, faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Alert, Button, ListGroup } from 'react-bootstrap';
import { DropdownList } from 'react-widgets';
import { GetBudgetTemplateItemsList, GetBudgetTemplate, PostBudgetTemplate } from '../API/BudgetTemplate';
import { SaveProductActions, GetProductActions, GetSequenceActions } from '../API/JSA';
import { Link } from 'react-router-dom';

export default props => {
    const [template, setTemplate] = useState({
        scopeLeafId: 0,
        fullName: "",
        actions: [],
    })
    const [confirm, setConfirm] = useState(false);
    const [action, setAction] = useState(null);
    const [allActions, setAllActions] = useState([]);
    const [productActions, setProductActions] = useState([]);

    let sequnceActionLink = "/Admin/SequenceActions";

    useEffect(() => {
        LoadActions()
        getProduct(props.match.params.id);
        getProductActions(props.match.params.id);
    }, [props.match.params.id])
    
    const getProduct = (id) => {
        GetBudgetTemplate(id).then(res => {
            let temp = { ...template };
            temp.scopeLeafId = res.data.scopeLeafId;
            temp.fullName = res.data.fullName;
            setTemplate(temp);
        })
    }

    const getProductActions = (id) => {
        GetProductActions(id).then(result => {
            if (result.success === true) {
                setProductActions(result.actions)
            }
        })
    }

    const LoadActions = () => {
        GetSequenceActions().then(res => {
            if (res.success === true) {
                setAllActions(res.data)
            }
        })
    }

    const AddAction = () => {
        if (action !== null) {
            let a = {
                productActionId: 0,
                sequenceActionId: action.sequenceActionId,
                action: action.action,
                scopeBuilderId: parseInt(props.match.params.id),
                order: parseInt(productActions.length)
            }
            let pas = [...productActions];
            pas.push(a);
            setProductActions(pas);
            setAction(null)
        }
    }

    const RemoveAction = (index) => {
        let pas = [ ...productActions ];
        pas.splice(index, 1);
        for (let i = index; i < pas.length; i++) {
            pas[index].order = pas[index].order - 1;
        }
        setProductActions(pas);
    }

    const SaveProduct = () => {
        let temp = { ...template };
        temp.actions = productActions;
        SaveProductActions(temp)
        .then(res => {
            getProduct(props.match.params.id)
            getProductActions(props.match.params.id)
            LoadActions()
            setConfirm(true);
        })
    }

    const moveActionUp = (index) => {
        if (index === 0) { return; }
        let pActions = [...productActions];
        let first = pActions[index]; // selected action to move up
        let second = pActions[index - 1];

        first.order = parseInt(index - 1);
        second.order = parseInt(index);
        pActions.splice(index - 1, 2, first, second);

        setProductActions(pActions);
    }

    const moveActionDown = (index) => {
        if (index === 0) { return; }
        let pActions = [...productActions];
        let first = pActions[index + 1];
        let second = pActions[index]; // selected action to move down

        first.order = parseInt(index);
        second.order = parseInt(index + 1);
        pActions.splice(index, 2, first, second);

        setProductActions(pActions);
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h3>{template.fullName}</h3>
                </div>
            </div>

            <div className="divider my-2" />

            <div className="row my-3">
                <div className="col-10">
                    <DropdownList
                        filter='contains'
                        data={allActions.filter(a => !productActions.find(({ sequenceActionId }) => a.sequenceActionId === sequenceActionId))}
                        textField="action"
                        placeholder="Select an Action"
                        value={action}
                        onChange={e => setAction(e)}
                    />
                </div>
                <div className="col-2 text-right">
                    <Button className="enc-button" onClick={AddAction}>Add Action</Button>
                </div>
            </div>

            <ListGroup>
                <ListGroup.Item className="enc-list">
                    Product Actions
                </ListGroup.Item>

                {productActions.length === 0 && <ListGroup.Item> No Product Actions Added</ListGroup.Item>}

                {productActions.map((m, i) => {
                    return (
                        <ListGroup.Item key={`action-${i}`}>
                            <div className="row">
                                <div className="col-2">
                                    {i !== 0 && <FontAwesomeIcon onClick={() => moveActionUp(i)} className="clickable" icon={faArrowUp} />}
                                    &nbsp;
                                    <strong>#{i + 1}</strong>
                                    &nbsp;
                                    {i !== productActions.length - 1 && <FontAwesomeIcon onClick={() => moveActionDown(i)} className="clickable" icon={faArrowDown} />}
                                </div>

                                <div className="col-6">
                                    <Link to={`${sequnceActionLink}/${m.sequenceActionId}`}>
                                        {m.action}
                                    </Link>
                                </div>

                                <div className="col-4 text-right">
                                    <FontAwesomeIcon icon={faTrash} className='clickable' onClick={() => RemoveAction(i)}/>
                                </div>
                            </div>
                        </ListGroup.Item>
                    )
                })}
            </ListGroup>
            
            <div className="row my-3">
                <div className="col-8">
                    <Alert variant="success" show={confirm} onClose={() => setConfirm(false)} dismissible>
                        Successfully Saved Product
                    </Alert>
                </div>

                <div className="col text-right">
                    <Button onClick={SaveProduct} className="enc-button">Save Product</Button>
                </div>
            </div>
        </div>
    )
}
