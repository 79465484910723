import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { ResetPasswordByUsername } from '../API/User';

export default props => {
    const [formData, setFormData] = useState({
        username: "",
        password: ""
    });
    const [forgotPassword, setForgotPassword] = useState(false)
    const LoginButton = () => {
        props.authenticate(formData.username, formData.password.trim());
    }

    const inputChanged = e => {
        let newFormData = { ...formData }
        newFormData[e.target.name] = e.target.value;
        setFormData(newFormData);
    }

    const handleForgotPassword = () => {
        setForgotPassword(!forgotPassword);
    }

    const resetPassword = () => {
        ResetPasswordByUsername(formData.username)
        handleForgotPassword()
    }


    return (
        <div className="app-container-image">
            <div className="container">
                <div className="row justify-content-center h-100">
                    <div className="col-xl-6 col-sm-12 m-auto white-bg p1-border px-5 py-3">
                        <div className="row justify-content-center">
                            <div className="col-10">
                                <img className="img-fluid" src="../images/logo.png" alt="Environmental Noise Control" />
                            </div>
                        </div>
                        <form action="#" onSubmit={(e) => { e.preventDefault(); LoginButton() }}>
                            <div className="row mt-3">
                                <div className="col text-center">
                                    <input type="text" className="enc-input-100" name="username" placeholder="Username"
                                        value={formData.username} onChange={(e) => inputChanged(e)}
                                        required />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col text-center">
                                    <input type="password" className="enc-input-100" name="password" placeholder="Password"
                                        onChange={(e) => inputChanged(e)} value={formData.password}
                                        required />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col">
                                    <input type="checkbox" checked={props.rememberValue} name="rememberMe"
                                        onChange={props.remember} /> Remember Me
                            </div>
                                <div className='col text-right'>
                                    <div onClick={handleForgotPassword}><h6 className="fake-link">Forgot your password?</h6></div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col">
                                    <span style={{color:'red'}}>{props.loginError}</span>
                                </div>
                                <div className="col text-right">
                                    <input type="submit" value="Login" className="enc-button" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>



                <Modal show={forgotPassword} onHide={handleForgotPassword}>
                    <Modal.Header closeButton>
                        <Modal.Title>Password Reset</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Confirm Password Reset for username: "{formData.username}". This will email you a new password.</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleForgotPassword}>
                            Close
                    </Button>
                        <input type="button" className="enc-button" onClick={resetPassword} value="Reset Password" />

                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    )
}