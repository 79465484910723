import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { GetSurveyForm, SaveSurveyForm, DeleteSurveyForm, GetSurveyDurationOptions, GetSurveyTypeOptions, 
    GetSurveyMeterOptions, GetSurveyFormPermissions } from '../../../API/SurveyForm';
import DatePicker from 'react-datepicker';
import { Button } from 'react-bootstrap';
import { DropdownList } from 'react-widgets';
import { GetSurveyMeasurementList, SaveSurveyMeasurement } from '../../../API/SurveyMeasurement';
import { GetSurveyAttachmentCategoryOptions } from '../../../API/SurveyAttachment';
import SurveyMeasurementView from './SurveyMeasurementView';
import SurveyFormMap from './SurveyFormMap';

export default (props) => {
    const [surveyForm, setSurveyForm] = useState({
        factoryCalMeters: false,
        parameter_CNEL: false,
        parameter_FullAudio: false,
        parameter_FullOctave: false,
        parameter_LMinMax: false,
        parameter_Ldn: false,
        parameter_StatsLn: false,
        parameter_ThirdOctave: false,
        parameter_TriggeredAudio: false,
        parameter_dBA: false,
        parameter_dBC: false,
        submissionDate: new Date(),
        weatherStation: false,
    });
    const [photoOptions, setPhotoOptions] = useState([]);
    const [durationOption, setDurationOption] = useState({value: "", label: ""})
    const [durationOptions, setDurationOptions] = useState([]);
    const [surveyTypeOption, setSurveyTypeOption] = useState({value: "", label: ""})
    const [surveyTypeOptions, setSurveyTypeOptions] = useState([]);
    const [surveyMeterOptions, setSurveyMeterOptions] = useState([]);
    const [permissions, setPermissions] = useState({ editSurveyFormTop: false, editSurveyFormBottom: false, });
    const [surveyMeasurements, setSurveyMeasurements] = useState([]);
    const [editing, setEditing] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        loadDurationOptions();
        loadSurveyTypeOptions();
        loadPermissions();
        loadPhotoOptions();
        loadSurveyForm(props.match.params.id);
        loadSurveyMeterOptions(props.match.params.id);
        loadSurveyMeasurements(props.match.params.id);
    },[props.match.params.id]);

    useEffect(() => {
        durationOptions.forEach(durationOption => {
            if (durationOption.value === surveyForm.duration) {
                setDurationOption(durationOption);
            }
        });
    },[durationOptions, surveyForm.duration]);

    useEffect(() => {
        surveyTypeOptions.forEach(surveyTypeOption => {
            if (surveyTypeOption.value === surveyForm.surveyType) {
                setSurveyTypeOption(surveyTypeOption);
            }
        });
    },[surveyTypeOptions, surveyForm.surveyType]);

    const loadPhotoOptions = () => {
        GetSurveyAttachmentCategoryOptions()
            .then(res => {
                let options = [];
                res.data.forEach(photoOption => {
                    options.push({value: photoOption.surveyAttachmentCategoryId, label: photoOption.surveyAttachmentCategoryName});
                });
                setPhotoOptions(options);
            });
    }

    const loadDurationOptions = () => {
        GetSurveyDurationOptions()
            .then(res => {
                let options = [];
                res.data.forEach(durationOption => {
                    options.push({value: durationOption.surveyDurationId, label: durationOption.surveyDurationName});
                });
                setDurationOptions(options);
            });
    }

    const loadSurveyTypeOptions = () => {
        GetSurveyTypeOptions()
            .then(res => {
                let options = [];
                res.data.forEach(surveyTypeOption => {
                    options.push({value: surveyTypeOption.surveyTypeId, label: surveyTypeOption.surveyTypeName});
                });
                setSurveyTypeOptions(options);
            });
    }

    const loadSurveyMeterOptions = (surveyFormId) => {
        GetSurveyMeterOptions(parseInt(surveyFormId))
        .then(res => {
            if (res.success) {
                let options = [];
                res.data.forEach(surveyMeterAssetType => {
                    options.push({
                        value: surveyMeterAssetType.inventoryAssetId, 
                        serialNumber: surveyMeterAssetType.serialNumber, 
                        type: surveyMeterAssetType.meterType
                    });
                });
                setSurveyMeterOptions(options);
            }
        });
    }

    const loadPermissions = () => {
        GetSurveyFormPermissions()
            .then(res => {
                if (res.success) {
                    setPermissions({editSurveyFormTop: res.editSurveyFormTop, editSurveyFormBottom: res.editSurveyFormBottom});
                } 
            });
    }

    const loadSurveyForm = (surveyFormId) => {
        GetSurveyForm(parseInt(surveyFormId))
            .then(res => {
                if (res.success) {
                    let newForm = res.data;
                    newForm.oldSoundMeterCount = newForm.soundMeterCount;
                    newForm.oldVibrationMeterCount = newForm.vibrationMeterCount;
                    setSurveyForm(newForm);
                } else {
                    setError(res.message);
                }
            });
    }

    const loadSurveyMeasurements = (surveyFormId) => {
        GetSurveyMeasurementList(parseInt(surveyFormId))
            .then(res => {
                if (res.success) {
                    for (let i = 0; i < res.data.length; i++){
                        if (res.data[i].height === 0 ) res.data[i].height = "";
                        let deploymentDate = new Date(Date.parse(res.data[i].deploymentDateString));
                        res.data[i].deploymentDate = deploymentDate > new Date("1/1/2") ? deploymentDate : null;
                        let retrievalDate = new Date(Date.parse(res.data[i].retrievalDateString));
                        res.data[i].retrievalDate = retrievalDate > new Date("1/1/2") ? retrievalDate : null;
                    }
                    setSurveyMeasurements(res.data);
                } else {
                    console.log(res.message);
                }
            });
    }

    const addNewSurveyMeasurement = () => {
        let newSurveyMeasurements = [...(surveyMeasurements)];
        newSurveyMeasurements.push({
            surveyMeasurementId: 0,
            surveyFormId: parseInt(props.match.params.id),
            deploymentDate: null,
            retrievalDate: null,
            preCalibrated: false,
            postCalibrated: false,
            location_X: 0,
            location_Y: 0,
        });
        setSurveyMeasurements(newSurveyMeasurements);
    }

    const deleteSurveyMeasurement = (index) => {
        let newSurveyMeasurements = [];
        for (let i = 0; i < surveyMeasurements.length; i++) {
            if (i !== index) {
                newSurveyMeasurements.push(surveyMeasurements[i]);
            }
        }
        setSurveyMeasurements(newSurveyMeasurements);
    }

    const toggleEditing = () => {
        if (editing) loadSurveyForm(props.match.params.id);
        setEditing(!editing);
    }

    const isEditingTop = () => {
        return editing && permissions.editSurveyFormTop;
    }
    
    const isEditingBottom = () => {
        return editing && permissions.editSurveyFormBottom;
    }

    const changeSurveyForm = (e) => {
        const newState = { ...(surveyForm) };
        newState[e.target.name] = e.target.value;
        setSurveyForm(newState);
    }

    const changeSubmissionDate = (date) => {
        const newState = { ...(surveyForm) };
        newState.submissionDate = date;
        setSurveyForm(newState);
    }

    const toggleCheckbox = (e) => {
        const newState = { ...(surveyForm) };
        newState[e.target.name] = !surveyForm[e.target.name];
        //Mutually exclusive checkboxes (can't both be true)
        if (e.target.value) {
            switch (e.target.name) {
                case "parameter_FullOctave":
                    newState.parameter_ThirdOctave = false;
                    break;
                case "parameter_ThirdOctave":
                    newState.parameter_FullOctave = false;
                    break;
                case "parameter_FullAudio":
                    newState.parameter_TriggeredAudio = false;
                    break;
                case "parameter_TriggeredAudio":
                    newState.parameter_FullAudio = false;
                    break;
                default:
                    break;
            }
        }
        setSurveyForm(newState);
    }

    const onSave = async () => {
        const payload = [...(surveyMeasurements)];
        for (let i = 0; i < payload.length; i++) {
            let p = {...(payload[i])};
            p.height = parseInt(p.height);
            if (isNaN(p.height)) p.height = 0;
            p.deploymentDateString = ((p.deploymentDate) ? new Date(Date.parse(p.deploymentDate)) : new Date("1/1/1")).toJSON();
            p.retrievalDateString = ((p.retrievalDate) ? new Date(Date.parse(p.retrievalDate)) : new Date("1/1/1")).toJSON();
            await SaveSurveyMeasurement(p);
        }
        saveForm();
    }

    const saveForm = () => {
        const payload = { ...(surveyForm)};
        payload.durationOther = parseInt(surveyForm.durationOther);
        payload.soundMeterCount = parseInt(surveyForm.soundMeterCount);
        if (isNaN(payload.soundMeterCount)) payload.soundMeterCount = 0;
        payload.vibrationMeterCount = parseInt(surveyForm.vibrationMeterCount);
        if (isNaN(payload.vibrationMeterCount)) payload.vibrationMeterCount = 0;
        SaveSurveyForm(payload)
            .then(res => {
                if (res.success) {
                    toggleEditing();
                    loadSurveyMeasurements(props.match.params.id);
                } else {
                    console.log(res.message);
                }
            });
    }

    const onCancel = () => {
        toggleEditing();
        loadSurveyForm(props.match.params.id);
        loadSurveyMeasurements(props.match.params.id);
    }

    const onDelete = () => {
        DeleteSurveyForm(parseInt(props.match.params.id))
            .then(res => {
                if (res.success) {
                    returnToSurveyFormList();
                } else {
                    console.log(res.message);
                }
            });
    }

    const history = useHistory();
    const returnToSurveyFormList = () => {
        let newLoc = props.match.url.split('/').slice(0, -1).join('/');
        history.push(`${newLoc}`);
    }

    const updateSurveyMeasurement = (surveyMeasurement, index) => {
        let newSurveyMeasurements = [...(surveyMeasurements)];
        newSurveyMeasurements[index] = surveyMeasurement;
        setSurveyMeasurements(newSurveyMeasurements);     
    }

    const reloadSurveyMeasurements = () => {
        loadSurveyMeasurements(props.match.params.id);
    }

    const changeDuration = (e) => {
        surveyForm.duration = e.value;
        setDurationOption(e);
    }

    const changeSurveyType = (e) => {
        surveyForm.surveyType = e.value;
        setSurveyTypeOption(e);
    }

    const getSurveyMeterOptions = (index) => {
        //Get array of meters currently in use, excluding yours
        let selectedMeters = [];
        for (let i = 0; i < surveyMeasurements.length; i++) {
            if (i !== index) {
                selectedMeters.push(surveyMeasurements[i].inventoryAssetId);
            }
        }
        //Add to set of options only meters not in use
        let newSurveyMeterOptions = [];
        for (let j = 0; j < surveyMeterOptions.length; j++) {
            if (!selectedMeters.includes(surveyMeterOptions[j].value)) {
                newSurveyMeterOptions.push(surveyMeterOptions[j]);
            }
        }
        return newSurveyMeterOptions;
    }
    
    return (
        <div className="container">
            { error ? 
                <div>
                    {error}
                </div> : <div>
                    {   /******************* TOP BUTTONS *******************/
                        (permissions.editSurveyFormTop || permissions.editSurveyFormBottom) && 
                        <div>
                            { editing ?
                                <div className="row my-3">
                                    <div className="col text-right">
                                        {permissions.editSurveyFormTop && <Button variant="danger" onClick={() => onDelete()}>Delete</Button>}
                                        <Button variant="secondary" className="ml-2" onClick={() => onCancel()}>Cancel</Button>
                                        <Button className="enc-button ml-2" onClick={() => onSave()}>Save</Button>
                                    </div>
                                </div> : 
                                <div className="row my-3">
                                    <div className="col text-right">
                                        <Button variant="secondary" className="ml-2" onClick={() => returnToSurveyFormList()}>Return</Button>
                                        <Button className="enc-button ml-2" onClick={() => toggleEditing()}>Edit</Button>
                                    </div>
                                </div> 
                            }
                        </div>
                    }
                    
                    {   /******************* TOP FORM *******************/   }
                    <div>
                        {/* 1) Client/Site Info */}
                        <div className="survey-section">
                            <div className="row survey-head">
                                <div className="col text-center">
                                    1) Client/Site Information
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    Company: {surveyForm.company}
                                </div>
                                <div className="col"/>
                                <div className="col">
                                    Site: {surveyForm.site}
                                </div>
                            </div>
                            <div className="row">
                                {/* Client Info */}
                                <div className="col">
                                    <div className="row survey-subhead">
                                        <div className="col">
                                            Client Information
                                        </div>
                                        <div className="col"/>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            Name:
                                        </div>
                                        <div className="col">
                                            {surveyForm.clientName}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            Phone:
                                        </div>
                                        <div className="col">
                                            {surveyForm.clientPhone}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            Email:
                                        </div>
                                        <div className="col">
                                            {surveyForm.clientEmail}
                                        </div>
                                    </div>
                                </div>
                                {/* ENC Info */}
                                <div className="col">
                                    <div className="row survey-subhead">
                                        <div className="col">
                                            ENC Personnel
                                        <div className="col"/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            Salesperson:
                                        </div>
                                        <div className="col">
                                            { isEditingTop() ?
                                                <input 
                                                    type="text" 
                                                    className="enc-input-100" 
                                                    name="salesperson" 
                                                    value={surveyForm.salesperson || ""} 
                                                    onChange={changeSurveyForm} 
                                                /> : 
                                                <div>
                                                    {surveyForm.salesperson}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            Date Submitted:
                                        </div>
                                        <div className="col">
                                            { isEditingTop() ?
                                                <DatePicker
                                                    className="enc-input-100"
                                                    selected={new Date(surveyForm.submissionDate)}
                                                    date={new Date(surveyForm.submissionDate)}
                                                    onChange={(date) => changeSubmissionDate(date)}
                                                /> : 
                                                <div>
                                                    {new Date(surveyForm.submissionDate).toLocaleDateString()}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            Meter Tech:
                                        </div>
                                        <div className="col">
                                            { isEditingTop() ?
                                                <input 
                                                    type="text" 
                                                    className="enc-input-100" 
                                                    name="meterTech" 
                                                    value={surveyForm.meterTech || ""} 
                                                    onChange={changeSurveyForm} 
                                                /> : 
                                                <div>
                                                    {surveyForm.meterTech}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            Engineer:
                                        </div>
                                        <div className="col">
                                            { isEditingTop() ?
                                                <input 
                                                    type="text" 
                                                    className="enc-input-100" 
                                                    name="engineer" 
                                                    value={surveyForm.engineer || ""} 
                                                    onChange={changeSurveyForm} 
                                                /> : 
                                                <div>
                                                    {surveyForm.engineer}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {/* Site Info */}
                                <div className="col">
                                    <div className="row survey-subhead">
                                        <div className="col">
                                            Site Information
                                        </div>
                                        <div className="col"/>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            GPS Lat:
                                        </div>
                                        <div className="col">
                                            {surveyForm.gpsLat}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            GPS Long:
                                        </div>
                                        <div className="col">
                                            {surveyForm.gpsLong}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            Address:
                                        </div>
                                        <div className="col">
                                            {surveyForm.address}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            City, State:
                                        </div>
                                        <div className="col">
                                            {surveyForm.city + ", " + surveyForm.state}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* 2) Survey Info */}
                        <div className="survey-section">
                            <div>
                                <div className="row survey-head">
                                    <div className="col text-center">
                                        2) Survey Information
                                    </div>
                                </div>
                                {/* First Row */}
                                <div className="row">
                                    <div className="col">
                                        <div className="row survey-subhead">
                                            <div className="col">
                                                Duration
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <div className="row">
                                                    <div className="col">
                                                        { isEditingTop() ?
                                                            <DropdownList 
                                                            data={durationOptions} 
                                                            textField='label' 
                                                            value={durationOption.label} 
                                                            valueField='value' 
                                                            onChange={(e) => changeDuration(e)} 
                                                            /> : 
                                                            <div>
                                                                {durationOption.label}
                                                            </div>
                                                        }
                                                    </div> 
                                                </div><div className="row">
                                                    <div className="col">
                                                        { surveyForm.duration === 0 && (isEditingTop() ?
                                                            <input 
                                                            type="text" 
                                                            className="enc-input-100" 
                                                            name="durationOther" 
                                                            value={surveyForm.durationOther} 
                                                            onChange={changeSurveyForm} 
                                                            /> : 
                                                            <div>
                                                                {surveyForm.durationOther}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="row survey-subhead">
                                            <div className="col">
                                                Survey Type
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <div className="row">
                                                    <div className="col">
                                                        { isEditingTop() ?
                                                            <DropdownList 
                                                            data={surveyTypeOptions} 
                                                            textField='label' 
                                                            value={surveyTypeOption.label} 
                                                            valueField='value' 
                                                            onChange={(e) => changeSurveyType(e)} 
                                                            /> : 
                                                            <div>
                                                                {surveyTypeOption.label}
                                                            </div>
                                                        }
                                                    </div>
                                                </div><div className="row">
                                                    <div className="col">
                                                        { surveyForm.surveyType === 0 && (isEditingTop() ?
                                                            <input 
                                                            type="text" 
                                                            className="enc-input-100" 
                                                            name="surveyTypeOther" 
                                                            value={surveyForm.surveyTypeOther || ""} 
                                                            onChange={changeSurveyForm} 
                                                            /> : 
                                                            <div>
                                                                {surveyForm.surveyTypeOther}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="row survey-subhead">
                                            <div className="col">
                                                # of Meters
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                Acousitic:
                                            </div>
                                            <div className="col">
                                                { isEditingTop() ?
                                                    <input 
                                                        type="text" 
                                                        className="enc-input-100" 
                                                        name="soundMeterCount" 
                                                        value={surveyForm.soundMeterCount} 
                                                        onChange={changeSurveyForm} 
                                                    /> : 
                                                    <div>
                                                        {surveyForm.soundMeterCount}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                Vibration:
                                            </div>
                                            <div className="col">
                                                { isEditingTop() ?
                                                    <input 
                                                        type="text" 
                                                        className="enc-input-100" 
                                                        name="vibrationMeterCount" 
                                                        value={surveyForm.vibrationMeterCount} 
                                                        onChange={changeSurveyForm} 
                                                    /> : 
                                                    <div>
                                                        {surveyForm.vibrationMeterCount}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="row survey-subhead">
                                            <div className="col">
                                                Site Type
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                { isEditingTop() ?
                                                    <input 
                                                        type="text" 
                                                        className="enc-input-100" 
                                                        name="siteType" 
                                                        value={surveyForm.siteType || ""} 
                                                        onChange={changeSurveyForm} 
                                                    /> : 
                                                    <div>
                                                        {surveyForm.siteType}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="row survey-subhead">
                                            <div className="col">
                                                Other Info
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <input
                                                    type="checkbox"
                                                    name="weatherStation"
                                                    checked={surveyForm.weatherStation}
                                                    onChange={toggleCheckbox}
                                                    disabled={!isEditingTop()}
                                                />
                                                {" Weather Station"}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <input
                                                    type="checkbox"
                                                    name="factoryCalMeters" 
                                                    checked={surveyForm.factoryCalMeters}
                                                    onChange={toggleCheckbox}
                                                    disabled={!isEditingTop()}
                                                /> 
                                                {" Factory Cal. Meters"}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Parameters */}
                                <div className="row survey-subhead">
                                    <div className="col text-center">
                                        Parameters
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <input
                                            type="checkbox"
                                            name="parameter_dBA" 
                                            checked={surveyForm.parameter_dBA}
                                            onChange={toggleCheckbox}
                                            disabled={!isEditingTop()}
                                        /> 
                                        {" dBA"}
                                    </div>
                                    <div className="col">
                                        <input
                                            type="checkbox"
                                            name="parameter_Ldn" 
                                            checked={surveyForm.parameter_Ldn}
                                            onChange={toggleCheckbox}
                                            disabled={!isEditingTop()}
                                        /> 
                                        {" Ldn"}
                                    </div>
                                    <div className="col">
                                        <input
                                            type="checkbox"
                                            name="parameter_FullOctave" 
                                            checked={surveyForm.parameter_FullOctave}
                                            onChange={toggleCheckbox}
                                            disabled={!isEditingTop()}
                                        /> 
                                        {" Full Octave"}
                                    </div>
                                    <div className="col">
                                        <input
                                            type="checkbox"
                                            name="parameter_StatsLn" 
                                            checked={surveyForm.parameter_StatsLn}
                                            onChange={toggleCheckbox}
                                            disabled={!isEditingTop()}
                                        /> 
                                        {" Stats (Ln)"}
                                    </div>
                                    <div className="col">
                                        <input
                                            type="checkbox"
                                            name="parameter_FullAudio" 
                                            checked={surveyForm.parameter_FullAudio}
                                            onChange={toggleCheckbox}
                                            disabled={!isEditingTop()}
                                        /> 
                                        {" Full Audio"}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <input
                                            type="checkbox"
                                            name="parameter_dBC" 
                                            checked={surveyForm.parameter_dBC}
                                            onChange={toggleCheckbox}
                                            disabled={!isEditingTop()}
                                        /> 
                                        {" dBC"}
                                    </div>
                                    <div className="col">
                                        <input
                                            type="checkbox"
                                            name="parameter_CNEL" 
                                            checked={surveyForm.parameter_CNEL}
                                            onChange={toggleCheckbox}
                                            disabled={!isEditingTop()}
                                        /> 
                                        {" CNEL"}
                                    </div>
                                    <div className="col">
                                        <input
                                            type="checkbox"
                                            name="parameter_ThirdOctave" 
                                            checked={surveyForm.parameter_ThirdOctave}
                                            onChange={toggleCheckbox}
                                            disabled={!isEditingTop()}
                                        /> 
                                        {" 1/3 Octave"}
                                    </div>
                                    <div className="col">
                                        <input
                                            type="checkbox"
                                            name="parameter_LMinMax" 
                                            checked={surveyForm.parameter_LMinMax}
                                            onChange={toggleCheckbox}
                                            disabled={!isEditingTop()}
                                        /> 
                                        {" Lmin, Lmax"}
                                    </div>
                                    <div className="col">
                                        <input
                                            type="checkbox"
                                            name="parameter_TriggeredAudio" 
                                            checked={surveyForm.parameter_TriggeredAudio}
                                            onChange={toggleCheckbox}
                                            disabled={!isEditingTop()}
                                        /> 
                                        {" Triggered Audio"}
                                    </div>
                                </div>
                            </div>
                            {   /* Applicable Ordinances / Notes */   }
                            <div className="row">
                                <div className="col">
                                    Applicable Ordinance(s) or Noise Limits:
                                </div>
                                <div className="col">
                                    { isEditingTop() ?
                                        <input 
                                            type="text" 
                                            className="enc-input-100" 
                                            name="noiseOrdinances" 
                                            value={surveyForm.noiseOrdinances || ""} 
                                            onChange={changeSurveyForm} 
                                        /> : 
                                        <div>
                                            {surveyForm.noiseOrdinances}
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="row">
                            <div className="col">
                                Further Survey Instructions, Details, or Notes:
                            </div>
                            <div className="col">
                                { isEditingTop() ?
                                    <input 
                                        type="text" 
                                        className="enc-input-100" 
                                        name="notes" 
                                        value={surveyForm.notes || ""} 
                                        onChange={changeSurveyForm} 
                                    /> : 
                                    <div>
                                        {surveyForm.notes}
                                    </div>
                                }
                            </div>
                        </div>
                        </div>
                    </div>
                    
                    {   /******************* BOTTOM FORM *******************/   }
                    <div>
                        {/* 3) Measurement Locations */}
                        <div className="survey-section">
                            <div className="row survey-head">
                                <div className="col text-center">
                                    3) Measurement Locations
                                </div>
                            </div>
                            { surveyMeasurements.map((sm, index) =>
                                <div key={index}>
                                    <SurveyMeasurementView 
                                        surveyMeasurement={sm}
                                        index={index} 
                                        updateSurveyMeasurement={updateSurveyMeasurement}
                                        deleteSurveyMeasurement={deleteSurveyMeasurement}
                                        editing={isEditingBottom()} 
                                        photoOptions={photoOptions}
                                        surveyMeterOptions={getSurveyMeterOptions(index)}
                                    />
                                </div>
                            )}
                            { isEditingBottom() &&
                                <div className="row pt-2">
                                    <div className="col text-center">
                                        <input type="button" value="Add Meter" className="enc-button"  onClick={() => addNewSurveyMeasurement()}/>
                                    </div>
                                </div>
                            }
                        </div>
                        {/* 4) Survey Notes */}
                        <div className="survey-section">
                            <div className="row survey-head">
                                <div className="col text-center">
                                    4) Survey Notes
                                </div>
                            </div>
                            <div className="row survey-subhead">
                                <div className="col">
                                    What was going on at the site at the time of the survey (no activity, operational, construction, etc.)?
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    { isEditingBottom() ?
                                        <textarea 
                                            type="text" 
                                            className="enc-textarea" 
                                            name="notes_SiteActivity" 
                                            value={surveyForm.notes_SiteActivity || ""} 
                                            onChange={changeSurveyForm} 
                                        /> : 
                                        <div>
                                            {surveyForm.notes_SiteActivity}
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="row survey-subhead">
                                <div className="col">
                                    If operational, what was operating at the site? Was it continuous, or were there periods of non-operation?
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    { isEditingBottom() ?
                                        <textarea 
                                            type="text" 
                                            className="enc-textarea" 
                                            name="notes_Operations" 
                                            value={surveyForm.notes_Operations || ""} 
                                            onChange={changeSurveyForm} 
                                        /> : 
                                        <div>
                                            {surveyForm.notes_Operations}
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="row survey-subhead">
                                <div className="col">
                                    Were there any non-site related noise sources (Air traffic, road traffic, construction, other sites, etc.?
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    { isEditingBottom() ?
                                        <textarea 
                                            type="text" 
                                            className="enc-textarea" 
                                            name="notes_Sources" 
                                            value={surveyForm.notes_Sources || ""} 
                                            onChange={changeSurveyForm} 
                                        /> : 
                                        <div>
                                            {surveyForm.notes_Sources}
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="row survey-subhead">
                                <div className="col">
                                    Were there any periods of precipitation or elevated winds?
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    { isEditingBottom() ?
                                        <textarea 
                                            type="text" 
                                            className="enc-textarea" 
                                            name="notes_Weather" 
                                            value={surveyForm.notes_Weather || ""} 
                                            onChange={changeSurveyForm} 
                                        /> : 
                                        <div>
                                            {surveyForm.notes_Weather}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        {/* 5) Measurement Locations */}
                        <div className="survey-section">
                            <div className="row survey-head">
                                <div className="col text-center">
                                    5) Measurement Locations 
                                </div>
                            </div>

                            <SurveyFormMap 
                                surveyFormId={surveyForm.surveyFormId}
                                surveyMeasurements={surveyMeasurements}
                                updateSurveyMeasurement={updateSurveyMeasurement}
                                reloadSurveyMeasurements={reloadSurveyMeasurements}
                                editing={isEditingBottom()} 
                            />
                        </div>
                    </div>

                    {   /******************* BOTTOM BUTTONS *******************/   }
                    { (permissions.editSurveyFormTop || permissions.editSurveyFormBottom) && 
                        <div>
                            { editing ?
                                <div className="row pt-2">
                                    <div className="col text-right">
                                        {permissions.editSurveyFormTop && <Button variant="danger" onClick={() => onDelete()}>Delete</Button>}
                                        <Button variant="secondary" className="ml-2" onClick={() => onCancel()}>Cancel</Button>
                                        <Button className="enc-button ml-2" onClick={() => onSave()}>Save</Button>
                                    </div>
                                </div> : 
                                <div className="row pt-2">
                                    <div className="col text-right">
                                        <Button variant="secondary" className="ml-2" onClick={() => returnToSurveyFormList()}>Return</Button>
                                        <Button className="enc-button ml-2" onClick={() => toggleEditing()}>Edit</Button>
                                    </div>
                                </div> 
                            }
                        </div>
                    }
                </div> 
            }
        </div>
    )
}


