import React from 'react';

export default props => {
    const today = new Date();
    return (
        <div className="footer no-print">
            <div className="footer-content">
                Behrens and Associates Environmental Noise Control - {today.getFullYear()}
            </div>
        </div>
    )
}
