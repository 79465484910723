import React, { useEffect, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { DropdownList } from 'react-widgets';
import { GetInventoryCategoryList } from '../../../API/Inventory';
import { CreateBulkItem, GetBulkItem } from '../../../API/InventoryBulk';


export default props => {
    const [bulkItem, setBulkItem] = useState({
        inventoryBulkItemId: 0,
        bulkItemName: "",
        inventoryCategoryId: 0
    });
    const [showAlert, setShowAlert] = useState(false);

    const [categories, setCategories] = useState([])
    const [category, setCategory] = useState(null)

    useEffect(() => {
        GetInventoryCategoryList()
            .then(res => {
                setCategories(res.data)
                if (props.match.params.id !== 'new') {
                    GetBulkItem(props.match.params.id)
                        .then(item => {
                            setBulkItem(item.data)
                            let cat = res.data.find(x => x.inventoryCategoryId === item.data.inventoryCategoryId)
                            setCategory(cat)
                        })
                }
            })
    }, [props.match.params.id])

    const changeItem = (key, value) => {
        let s = { ...bulkItem }
        s[key] = value;
        setBulkItem(s)
    }

    const submitNew = () => {
        let payload = { ...bulkItem }
        payload.inventoryCategoryId = category.inventoryCategoryId
        CreateBulkItem(payload)
            .then(res => {
                setShowAlert(true);
            })
    }

    return (
        <div>
            <form action="#" onSubmit={(e) => { e.preventDefault(); submitNew() }}>
                <div className="row mt-3">
                    <div className="col">
                        <h3>Bulk Item Editor</h3>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <small>Bulk Item Name</small>
                        <input className="enc-input-100" placeholder="Bulk Item Name" value={bulkItem.bulkItemName} onChange={e => changeItem('bulkItemName', e.target.value)} required />
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <small>Item Category</small>
                        <DropdownList
                            data={categories}
                            textField="name"
                            onChange={setCategory}
                            value={category}
                            placeholder="Select a Category"
                        />
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="col-6">
                        <Alert variant="success" show={showAlert} dismissible onClose={() => setShowAlert(false)}>
                            <strong>Successfully Saved Bulk Item</strong>
                        </Alert>
                    </div>

                    <div className="col text-right">
                        <Button className="enc-button" type='submit'>Save Item</Button>
                    </div>
                </div>
            </form>
        </div>
    )
}
