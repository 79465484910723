import React, { useEffect, useState } from 'react';
import { Alert, Button, ListGroup, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { DropdownList } from 'react-widgets';
import { GetBudgetTemplateItemsList, PostBulkTemplate } from '../API/BudgetTemplate';
import { GetScopeBuilderNoOther } from '../API/JobScope';
import { ProposalListNames } from '../API/Proposal';
import { GetSequenceActions, PostBulkProduct } from '../API/JSA';

export default props => {
    const [scopeItem, setScopeItem] = useState([])
    const [working, setWorking] = useState(false);
    const [selected, setSelected] = useState([]);
    const [prefills, setPrefills] = useState([])
    const [filter, setFilter] = useState("")
    const [confirm, setConfirm] = useState(false);

    const [allActions, setAllActions] = useState([])
    const [selectedAction, setSelectedAction] = useState(null)

    let sequnceActionLink = "/Admin/SequenceActions";
    let potentialRisksLink = "/Admin/PotentialRisks";

    useEffect(() => {
        GetSequenceActions()
            .then(res => {
                setAllActions(res.data)
            })
        LoadProposalPrefills()
        GetScopeBuilderNoOther()
            .then(res => {
                let data = res.data;
                let sb = data.filter(s => s.itemName === "Mitigation"); // only from mitigation scope
                setScopeItem(sb)
            })
    }, [])

    const LoadProposalPrefills = () => {
        ProposalListNames()
            .then(res => {
                let data = res.data;
                let sb = data.filter(s => s.scopeName.startsWith("Mitigation ")); // only from mitigation scope
                setPrefills(sb)
            })
    }
    
    const changeScope = (tier, value) => {
        let s = [...selected]
        if (selected.length >= tier + 1) {
            s.splice(tier, selected.length - tier, value)
        } else {
            s.push(value)
        }
        setSelected(s)
    }

    const loadScope = (sc, tier = 0, arr = []) => {
        arr.push({ data: sc, tier: tier })
        let select;
        if (selected[tier]) {
            let scSelect = sc.filter(scope => scope.scopeBuilderId === selected[tier].scopeBuilderId)[0];

            let removeItems = []; // removes any children that include STC 25 or STC 32

            for (let i = 0; i < scSelect.children.length; i++) {
                if (scSelect.children[i].itemName === 'STC 25' || scSelect.children[i].itemName === 'STC 32') {
                    //removeItems.push(scSelect.children[i].scopeBuilderId);
                    removeItems.push(scSelect.children[i]);
                }
            }
            
            for (let i = 0; i < removeItems.length; i++) {
                //let index = scSelect.children.indexOf(c => c.scopeBuilderId === removeItems[i]);
                let index = scSelect.children.indexOf(removeItems[i]);
                scSelect.children.splice(index, 1);
            }

            select = scSelect;
        }
        if (select && select.children && select.children.length > 0) {
            loadScope(select.children, tier + 1, arr)
        }
        return arr;
    }

    const BulkAddProduct = () => {
        let lastItem = selected.slice(-1).pop()
        setWorking(true);
        if (lastItem && selectedAction) {
            let sa = {
                productActionId: 0,
                sequenceActionId: selectedAction.sequenceActionId,
                action: selectedAction.action,
                scopeBuilderId: lastItem.scopeBuilderId,
            }
            let payload = {
                scopeBuilderId: lastItem.scopeBuilderId,
                selectedAction: sa
            }
            PostBulkProduct(payload)
            .then(res => {
                if (res.success === true) {
                    setSelected([])
                    setSelectedAction(null)
                    setConfirm(true);
                }
            })
        }
        setWorking(false);
    }
    
    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h3>Products</h3>
                </div>
            </div>

            <div className="divider my-2" />

            <div className="row">
                <div className="col">
                    <h4>Bulk Product Builder</h4>
                </div>
            </div>

            <div>
                <form action="#" onSubmit={(e) => { e.preventDefault();  BulkAddProduct() }}>
                    <div className="row">
                        <div className="col">
                            <small>Select an Action for Bulk Operation</small>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <DropdownList 
                                filter="contains"
                                data={allActions}
                                placeholder="Select an Action"
                                textField="action" // change
                                value={selectedAction}
                                onChange={e => setSelectedAction(e)}
                            />
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col">
                            <small>Select a product parent to bulk add action.</small>
                        </div>
                    </div>

                    <div className='row mb-2'>
                        {loadScope(scopeItem).map((m, i) => {
                            return <div key={`dd-${i}`} className="col-6 mt-1">
                                <DropdownList
                                    data={m.data}
                                    onChange={e => changeScope(m.tier, e)}
                                    value={selected[m.tier]}
                                    textField='itemName'
                                    placeholder="Select a value"
                                    required
                                />
                            </div>
                        })}
                    </div>

                    <div className="row">
                        <div className="col-8">
                            <Alert variant="success" show={confirm} onClose={() => setConfirm(false)} dismissible>
                                Successfully Bulk Added Actions
                            </Alert>
                        </div>

                        <div className="col text-right">
                            <Button type='submit' className="enc-button" disabled={working}>
                                {working ?
                                    <span>Processing
                                        <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                    </span> : "Bulk Add Action"}
                            </Button>
                        </div>
                    </div>
                </form>
            </div>

            <div className="divider my-2" />

            <div className="row my-2">
                <div className="col-4">
                    <Button className="enc-button" as={Link} to={`${sequnceActionLink}`}>View Actions</Button>
                </div>
                <div className="col-4">
                    <Button className="enc-button" as={Link} to={`${potentialRisksLink}`}>View Risks</Button>
                </div>
                <div className="col-4 text-right">
                    <input placeholder="Filter" className="enc-input-100" value={filter} onChange={e => setFilter(e.target.value)} />
                </div>
            </div>

            <ListGroup className="mb-3">
                <ListGroup.Item className="click-list">
                    Product Combinations
                </ListGroup.Item>

                {prefills.filter(x => x.scopeName.toLowerCase().search(filter.toLowerCase()) !== -1).map((m, i) => {
                    return (
                        <ListGroup.Item as={Link} to={`${props.match.url}/${m.leafId}`} className="click-list" key={`Prefill-${i}`}>
                            {m.scopeName}
                        </ListGroup.Item>
                    )
                })}
            </ListGroup>
        </div>
    )
}
