import APIRequest from './MainAPICall/api';
import config from '../APIConfig';

export const SaveCall = (payload) => {
    return APIRequest({
        url: config.baseURL + "/Call/SaveCall",
        method: "post",
        body: payload
    });
}

export const GetCall = (id) => {
    return APIRequest({
        url: config.baseURL + "/Call/GetCall",
        method: "post",
        body: {
            id: id
        }
    });
}

export const GetCallList = (active) => {
    return APIRequest({
        url: config.baseURL + `/Call/CallList?active=${active}`,
        method: "get"
    });
}

export const ChangeCallStatus = (payload) => {
    return APIRequest({
        url: config.baseURL + "/Call/ChangeCallStatus",
        method: "post",
        body: payload
    });
}