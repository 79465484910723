import APIRequest from './MainAPICall/api';
import config from '../APIConfig';


export const GetJSAForm = () => {
    return APIRequest({
        url: config.baseURL + "/JSA/JSAForm",
        method: "get",
    });
}

export const GetJSA = (id) => {
    return APIRequest({
        url: config.baseURL + `/JSA/JSA?id=${id}`,
        method: "get",
    });
}

export const GetJSAEdits = (id) =>{
    return APIRequest({
        url: config.baseURL + `/JSA/JSAListEdits?JSAId=${id}`,
        method: "get"
    })
}

export const PostJSA = (payload) => {
    return APIRequest({
        url: config.baseURL + "/JSA/JSA",
        method: "post",
        body: payload
    });
}

export const GetJSAList = (id, stepId) => {
    return APIRequest({
        url: config.baseURL + `/JSA/JSAList?id=${id}&stepId=${stepId}`,
        method: "get",
    });
}

export const GetJSAListAll = (jobId) =>{
    return APIRequest({
        url:config.baseURL + `/JSA/JSAListAll?id=${jobId}`
    })
}

export const CheckEditJSA = (id) => {
    return APIRequest({
        url: config.baseURL + `/JSA/CheckJSAEdit?id=${id}`,
        method: "get",
    });
}

export const SignJSA = (payload) => { 
    return APIRequest({

        url: config.baseURL + "/JSA/SignJSA",
        method: "post",
        body: payload
    });
}

export const GetJSASignatures = (id) =>{
    return APIRequest({
        url: config.baseURL + `/JSA/JSAListSignatures?id=${id}`,
        method: "get"
    })
}

export const PostBulkAction = (payload) => {
    return APIRequest({
        url: config.baseURL + "/JSA/PostBulkAction",
        method: "post",
        body: payload
    });
}

export const GetSequenceActions = () => {
    return APIRequest({
        url: config.baseURL + `/JSA/GetSequenceActions`,
        method: "get",
    });
}

export const GetSequenceAction = (id) => {
    return APIRequest({
        url: config.baseURL + `/JSA/GetSequenceAction?id=${id}`,
        method: "get",
    });
}

export const AddSequenceAction = (payload) => {
    return APIRequest({
        url: config.baseURL + `/JSA/AddSequenceAction`,
        method: "post",
        body: payload
    });
}

export const EditSequenceAction = (payload) => {
    return APIRequest({
        url: config.baseURL + `/JSA/EditSequenceAction`,
        method: "post",
        body: payload
    });
}

export const RemoveSequenceAction = (id) => {
    return APIRequest({
        url: config.baseURL + `/JSA/RemoveSequenceAction?id=${id}`,
        method: "post",
    });
}

export const GetPotentialRisks = () => {
    return APIRequest({
        url: config.baseURL + `/JSA/GetPotentialRisks`,
        method: "get",
    });
}

export const GetPotentialRisk = (id) => {
    return APIRequest({
        url: config.baseURL + `/JSA/GetPotentialRisk?id=${id}`,
        method: "get",
    });
}

export const AddPotentialRisk = (payload) => {
    return APIRequest({
        url: config.baseURL + `/JSA/AddPotentialRisk`,
        method: "post",
        body: payload
    });
}

export const EditPotentialRisk = (payload) => {
    return APIRequest({
        url: config.baseURL + `/JSA/EditPotentialRisk`,
        method: "post",
        body: payload
    });
}

export const RemovePotentialRisk = (id) => {
    return APIRequest({
        url: config.baseURL + `/JSA/RemovePotentialRisk?id=${id}`,
        method: "post",
    });
}

export const SaveRiskActions = (payload) => {
    return APIRequest({
        url: config.baseURL + `/JSA/SaveRiskActions`,
        method: "post",
        body: payload
    });
}

export const RemoveRiskAction = (id) => {
    return APIRequest({
        url: config.baseURL + `/JSA/RemoveRiskAction?id=${id}`,
        method: "post",
    });
}

export const PostBulkProduct = (payload) => {
    return APIRequest({
        url: config.baseURL + "/JSA/PostBulkProduct",
        method: "post",
        body: payload
    });
}

export const GetProductActions = (id) => {
    return APIRequest({
        url: config.baseURL + `/JSA/GetProductActions?id=${id}`,
        method: "get",
    });
}

export const SaveProductActions = (payload) => {
    return APIRequest({
        url: config.baseURL + "/JSA/SaveProductActions",
        method: "post",
        body: payload
    });
}
