import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { FilePond, registerPlugin } from 'react-filepond';
import * as FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import Resizer from 'react-image-file-resizer';
import { fpPayloadSurveyMap } from '../../../API/MainAPICall/filepondApi';
import { GetSurveyFormMap, DeleteSurveyFormMap } from '../../../API/SurveyFormMap';
import SurveyFormMapCanvas from './SurveyFormMapCanvas'

registerPlugin(FilePondPluginFileValidateType);

export default (props) => {
    const [image, setImage] = useState();
    const [markerImage, setMarkerImage] = useState();
    const [markerIndex, setMarkerIndex] = useState(false); //Will be false if not setting a marker, or the index of the marker we're setting.
    const [message, setMessage] = useState(false);
    const [error, setError] = useState(false);

    const canvasWidth = 800;
    const canvasHeight = 500;

    useEffect(() => {
        loadMarkerImage();
    }, []);

    useEffect(() => {
        loadSurveyFormMap(props.surveyFormId);
    }, [props.surveyFormId]);

    const loadMarkerImage = () => {
        const marker = new Image();
        marker.src = "../images/marker.png"
        setMarkerImage(marker);
    }

    const loadSurveyFormMap = (surveyFormId) => {
        const resizeFile = (file) => new Promise(resolve => {
            Resizer.imageFileResizer(file, canvasWidth, canvasHeight, 'PNG', 100, 0, uri => { resolve(uri); }, 'base64', canvasWidth);
        });

        if (surveyFormId) {
            GetSurveyFormMap(parseInt(surveyFormId))
                .then(async res => {
                    if (res.success && res.hasMap) {
                        const byteCharacters = atob(res.mapData);
                        const byteArrays = [];
                        const sliceSize = 512;
                        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                            const slice = byteCharacters.slice(offset, offset + sliceSize);
                            const byteNumbers = new Array(slice.length);
                            for (let i = 0; i < slice.length; i++) {
                                byteNumbers[i] = slice.charCodeAt(i);
                            }
                            const byteArray = new Uint8Array(byteNumbers);
                            byteArrays.push(byteArray);
                        }
                        const mapSprite = new Image();
                        mapSprite.src = await resizeFile(new Blob(byteArrays));
                        setImage(mapSprite);
                        setError("");
                    } else {
                        setError(res.message);
                    }
                })
        }
    }

    const reloadMap = () => {
        loadSurveyFormMap(parseInt(props.surveyFormId))
    }

    const onDeleteMap = () => {
        DeleteSurveyFormMap(parseInt(props.surveyFormId))
            .then(res => {
                if (res.success) {
                    setImage(null);
                    props.reloadSurveyMeasurements();
                    setError("");
                } else {
                    setError(res.message);
                }
            });
    }

    const updateSurveyMeasurementLocation = (index, x, y) => {
        let newSurveyMeasurement = props.surveyMeasurements[index];
        newSurveyMeasurement.location_X = x;
        newSurveyMeasurement.location_Y = y;
        props.updateSurveyMeasurement(newSurveyMeasurement, index);
    }

    const draw = (context) => {
        context.fillStyle = '#000000';
        context.fillRect(0, 0, canvasWidth, canvasHeight);
        if (image) {
            context.drawImage(image, 0, 0, canvasWidth, canvasHeight);
            props.surveyMeasurements.forEach((sm, index) => {
                if (sm.location_X !== 0 && sm.location_Y !== 0) {
                    let x = sm.location_X - 16; //Math.min(Math.max(sm.location_X-16, 0), canvasWidth-32);
                    let y = sm.location_Y - 32; //Math.min(Math.max(sm.location_Y-32, 0), canvasHeight-32);
                    context.drawImage(markerImage, x, y, 32, 32);
                    let markerText = `Meter ${index + 1}`;
                    let textWidth = context.measureText(markerText).width;
                    let xOffset = sm.location_X - (textWidth / 2);
                    let yOffset = sm.location_Y - 40;
                    context.fillStyle = "#333";
                    context.globalAlpha = 0.7;
                    context.fillRect(xOffset - 4, yOffset - 12, textWidth + 8, 16);
                    context.globalAlpha = 1;
                    context.fillStyle = "#fff";
                    context.fillText(markerText, xOffset, yOffset);
                }
            });
        }
    }

    const mouseClicked = (x, y) => {
        if (markerIndex !== false) { //explicit compare necessary - index will often be 0
            updateSurveyMeasurementLocation(markerIndex, parseInt(x), parseInt(y));
            setMarkerIndex(false);
            setMessage(`Meter ${markerIndex + 1} placed.`);
        }
    }

    const activatePinPlacement = (index) => {
        updateSurveyMeasurementLocation(index, 0, 0);
        setMarkerIndex(index);
        setMessage(`Click map to place Meter ${index + 1}.`);
    }

    const removePin = (index) => {
        updateSurveyMeasurementLocation(index, 0, 0);
        setMessage(`Meter ${index + 1} removed.`);
    }

    return (
        <div className="container pt-4">
            { image ?
                <div>
                    <div className="row pt-2">
                        <div className="col">
                            <SurveyFormMapCanvas width={canvasWidth} height={canvasHeight} draw={draw} mouseClicked={mouseClicked} />
                            {props.editing &&
                                <div className="text-center"> {message} </div>
                            }
                        </div>
                        {props.editing &&
                            <div className="col">
                                <div className="row section-head">
                                    <div className="col">
                                        Set Location
                                    </div>
                                    <div className="col">
                                        Remove
                                    </div>
                                </div>
                                {props.surveyMeasurements.map((sm, index) =>
                                    <div className="row pt-2" key={index}>
                                        <div className="col">
                                            <Button className="enc-button ml-2" value={`Meter ${index + 1}`} onClick={() => activatePinPlacement(index)}>Meter {index + 1}</Button>
                                        </div>
                                        <div className="col">
                                            {sm.location_X !== 0 && sm.location_Y !== 0 &&
                                                <Button variant="danger" value="Remove" onClick={() => removePin(index)}>Remove</Button>
                                            }
                                        </div>
                                    </div>
                                )}
                            </div>
                        }
                    </div>
                    {props.editing &&
                        <div className="row pt-2">
                            <Button variant="danger" value="Delete Map" onClick={() => onDeleteMap()}>Delete Map</Button>
                        </div>
                    }
                </div> :
                <div>
                    {props.editing &&
                        <FilePond
                            className="mt-5"
                            allowMultiple={false}
                            allowFileTypeValidation={true}
                            acceptedFileTypes={['image/*']}
                            server={fpPayloadSurveyMap(props.surveyFormId, reloadMap)}
                            credits={false}

                        />
                    }
                </div>
            }
            {error}
        </div>
    )
}


