import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { SaveJob, GetJob } from '../API/Jobs';
import { GetTypeList } from '../API/PB';
import { DropdownList } from 'react-widgets';
import ConfirmDelete from '../Components/ConfirmDelete';
import ReturnScopeButton from './ReturnScopeButton';
import { GetOpportunityLocationList } from '../API/Opportunity';

import stateList from '../Components/stateList';

export default props => {
    const [job, setJob] = useState({
        "opportunityId": 0,
        "jobName": "",
        "jobDescription": "",
        "jobClassification": 0,
        "canceled": false,
        "jobId": 0,
        "id": 0,
    });

    const [shippingLocation, setShippingLocation] = useState({
        "accountLocationId": 0,
        "accountId": 0,
        "locationName": "",
        "billingAddress1": "",
        "billingAddress2": "",
        "billingCity": "",
        "billingState": "",
        "billingZip": "",
        "shippingAddress1": "",
        "shippingAddress2": "",
        "shippingCity": "",
        "shippingState": "",
        "shippingZip": "",
        "enabled": true,
        "jobLocation": true,
    });

    const [shippingList, setShippingList] = useState([]);

    const [confirmClose, setConfirmClose] = useState(false);
    const [confirmCloseUncancel, setConfirmCloseUncancel] = useState(false);

    const [typeList, setTypeList] = useState([])

    useEffect(() => {
        GetTypeList()
            .then(res => {
                setTypeList(res.data)
            })
        if (props.match.params.jobId) {
            loadJob(parseInt(props.match.params.jobId))
        } else {
            GetOpportunityLocationList(props.oppId)
                .then(oppList => {
                    setShippingLocation(oppList.data.opportunityLocation)
                    setShippingList(oppList.data.locations);
                })
        }
    }, [props.match.params.jobId])

    useEffect(() => {

    }, [props.oppId, job.shippingLocation])

    const createNewShippingLocation = () => {
        setShippingLocation({
            "accountLocationId": 0,
            "accountId": 0,
            "locationName": "",
            "billingAddress1": "",
            "billingAddress2": "",
            "billingCity": "",
            "billingState": "",
            "billingZip": "",
            "shippingAddress1": "",
            "shippingAddress2": "",
            "shippingCity": "",
            "shippingState": "",
            "shippingZip": "",
            "enabled": true,
            "jobLocation": true
        })
    }

    const loadJob = (id) => {
        GetJob(id)
            .then(res => {
                setJob({
                    "opportunityId": res.data.opportunityId,
                    "jobName": res.data.jobName,
                    "jobDescription": res.data.jobDescription,
                    "jobClassification": res.data.jobClassificationId,
                    "canceled": res.data.canceled,
                    "jobId": res.data.jobId,
                    "id": 0
                })
                GetOpportunityLocationList(props.oppId)
                    .then(oppList => {
                        if (res.data.shippingLocation.accountLocationId !== 0) {
                            setShippingLocation(res.data.shippingLocation)
                        } else {
                            setShippingLocation(oppList.data.opportunityLocation)
                        }
                        setShippingList(oppList.data.locations);
                    })
            })
    }

    const changeJob = (key, val) => {
        let s = { ...job }
        s[key] = val;
        setJob(s)
    }

    const history = useHistory();
    const saveJob = () => {
        let payload = { ...job }
        payload.opportunityId = parseInt(props.oppId)
        payload.shippingLocation = shippingLocation
        SaveJob(payload)
            .then(res => {
                if (res.success) {
                    history.push(`/Opportunities/${props.oppId}/${res.id}`)
                }
            })
    }

    const changeLocation = (key, value) => {
        let s = { ...shippingLocation }
        s.accountLocationId = 0;
        s[key] = value;
        setShippingLocation(s)
    }

    const cancelJob = () => {
        let payload = { ...job }
        payload.canceled = true;
        SaveJob(payload)
            .then(res => {
                if (res.success) {
                    history.push(`/Opportunities/${props.oppId}/${res.id}`)
                }
            })
    }

    const uncancelJob = () => {
        let payload = { ...job }
        payload.canceled = false;
        SaveJob(payload)
            .then(res => {
                if (res.success) {
                    history.push(`/Opportunities/${props.oppId}/${res.id}`)
                }
            })
    }

    return (
        <div className="container-fluid">
            <form action="#" onSubmit={(e) => { e.preventDefault(); saveJob() }}>
                <div className="row mt-1">
                    <div className="col">
                        <input type='text' required value={job.jobName} onChange={(e) => { changeJob('jobName', e.target.value) }} className="enc-input-100" placeholder="Job Name" />
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="col">
                        <textarea value={job.jobDescription} onChange={(e) => { changeJob('jobDescription', e.target.value) }} className="enc-textarea" placeholder="Job Description" />
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="col">
                        <DropdownList placeholder="ClassifyJob" data={typeList} onChange={(e) => changeJob('jobClassification', e.classificationId)} value={job.jobClassification} textField='classificationName' valueField='classificationId' />
                    </div>
                </div>
                <div className="divider my-2" />
                <div className="row m-3">
                    <div className="col-4">
                        <strong>Shipping Information:</strong>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col">
                        <DropdownList
                            placeholder="Select Existing Location"
                            textField='locationName'
                            valueField='locationName'
                            value={shippingLocation.locationName}
                            data={shippingList}
                            onChange={(e) => setShippingLocation(e)}
                        />
                    </div>
                    <div className="col-3 text-right">
                        <Button className="enc-button" onClick={createNewShippingLocation}>
                            New Address
                        </Button>
                    </div>
                </div>
                <div className="divider my-2" />
                <div className="row mt-3 mb-1">
                    <div className="col">
                        <small>Job Shipping Address</small>
                        <input type="text"
                            className="enc-input-100"
                            name="locationName"
                            value={shippingLocation.locationName}
                            placeholder="Location Name"
                            disabled={shippingLocation.accountLocationId !== 0}
                            onChange={e => changeLocation('locationName', e.target.value)}
                            required
                        />
                    </div>
                </div>
                <div className="row mt-3 mb-1">
                    <div className="col">
                        <input type="text"
                            className="enc-input-100"
                            name="shippingAddress1"
                            value={shippingLocation.shippingAddress1}
                            placeholder="Shipping Address"
                            disabled={shippingLocation.accountLocationId !== 0}
                            onChange={e => changeLocation('shippingAddress1', e.target.value)}
                            required
                        />
                    </div>
                </div>
                <div className="row mb-1">
                    <div className="col">
                        <input type="text"
                            className="enc-input-100"
                            name="shippingAddress2"
                            value={shippingLocation.shippingAddress2}
                            disabled={shippingLocation.accountLocationId !== 0}
                            onChange={e => changeLocation('shippingAddress2', e.target.value)}
                        />

                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <small>City</small>
                        <input type="text"
                            className="enc-input-100"
                            name="shippingCity"
                            value={shippingLocation.shippingCity}
                            placeholder="City"
                            disabled={shippingLocation.accountLocationId !== 0}
                            onChange={e => changeLocation('shippingCity', e.target.value)}
                            required
                        />
                    </div>
                    <div className="col">
                        <small>State</small>
                        <DropdownList
                            filter="contains"
                            value={shippingLocation.shippingState}
                            disabled={shippingLocation.accountLocationId !== 0}
                            onChange={e => changeLocation('shippingState', e)}
                            data={stateList}
                            required
                        />
                    </div>
                    <div className="col">
                        <small>Zip Code</small>
                        <input type="text"
                            className="enc-input-100"
                            name="shippingZip"
                            value={shippingLocation.shippingZip}
                            placeholder="Zip Code"
                            disabled={shippingLocation.accountLocationId !== 0}
                            onChange={e => changeLocation('shippingZip', e.target.value)}
                            required
                        />
                    </div>
                </div>

                <div className="row my-4">
                    <div className="col text-right">
                        <ConfirmDelete
                            show={confirmClose}
                            setShow={setConfirmClose}
                            action={cancelJob}
                            message="Confirm you would like to cancel job."
                        />
                        <ConfirmDelete
                            show={confirmCloseUncancel}
                            setShow={setConfirmCloseUncancel}
                            action={uncancelJob}
                            message="Confirm you would like to un-cancel job."
                        />
                        {job.jobId !== 0 && <ReturnScopeButton jobId={job.jobId} />}
                        {job.jobId !== 0 && !job.canceled && <Button onClick={() => setConfirmClose(true)} className="mx-1" variant='danger'>Cancel Job</Button>}
                        {job.jobId !== 0 && job.canceled && <Button onClick={() => setConfirmCloseUncancel(true)} className="mx-1" variant='danger'>Un-Cancel Job</Button>}
                        {props.jobId && <Button onClick={() => history.goBack()} className="enc-button mx-1">Back</Button>}
                        <Button type='submit' className="enc-button">Save</Button>
                    </div>
                </div>
            </form>
        </div >

    )
}
