import React, { useState, useEffect } from 'react';
import { GetAllUsers } from '../API/Administrator';
import UserPane from './UserPane';
import LoadingOverlay from 'react-loading-overlay';

export default props => {
    const [userList, setUserList] = useState([]);
    const [viewDisabled, setViewDisabled] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        LoadUsers(true)
    }, []);

    const LoadUsers = (dis) => {
        setLoading(true);
        GetAllUsers(dis)
            .then(res => {
                if (res.success) {
                    setUserList(res.data.userList)
                    setLoading(false);
                }
            })
    }

    const handleChange = () => {
        setViewDisabled(!viewDisabled)
        LoadUsers(viewDisabled)
    }

    return (
        <LoadingOverlay
            active={loading}
            spinner
            text='Loading Users...'
        >
            <div className="container-fluid">
                <div className="row mb-1">
                    <div className="col text-right">
                        <div className="toggle-switch small-switch">
                            <input
                                type="checkbox"
                                className="toggle-switch-checkbox"
                                id="disabled"
                                checked={viewDisabled}
                                onChange={handleChange}
                            />
                            <label className="toggle-switch-label" htmlFor="disabled">
                                <span className={"toggle-switch-inner"} />
                                <span
                                    className={"toggle-switch-switch"}
                                />
                            </label>
                        </div>
                    &nbsp; &nbsp;View Disabled Accounts
                </div>
                </div>
                <div className="row">
                    {userList.map(m => { return <UserPane key={`UserPane-${m.userId}`} userInfo={m} /> })}
                </div>
            </div>
        </LoadingOverlay>
    )
}
