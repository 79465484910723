import APIRequest from './MainAPICall/api';
import config from '../APIConfig';

export const GetUsersWidgets = () => {
    return APIRequest({
        url: config.baseURL + "/Dashboard/UsersWidgets",
        method: "get"
    });
}

export const GetWidgetTypesList = () => {
    return APIRequest({
        url: config.baseURL + "/Dashboard/WidgetTypeList",
        method: "get"
    });
}

export const PostWidgetList = (payload) => {
    return APIRequest({
        url: config.baseURL + "/Dashboard/WidgetList",
        method: "post",
        body: payload
    });
}

export const PostWidget = (payload) => {
    return APIRequest({
        url: config.baseURL + "/Dashboard/Widget",
        method: "post",
        body: payload
    });
}

export const GetDashboardJobList = (id) => {
    return APIRequest({
        url: config.baseURL + `/Dashboard/JobList?WidgetId=${id}`,
        method: "get"
    });
}

