import React from 'react';
import { Button } from 'react-bootstrap';
import Attachments from '../../Components/Attachments';
import { ClientApproval, ClientRejection } from '../../API/JobApproval';

export default ({ rl, jobId, approval }) => {
    const Approve = () => {
        ClientApproval(parseInt(jobId))
            .then(res => {
                if (res.success) {
                    rl()
                }
            })
    }

    const Reject = () => {
        ClientRejection(parseInt(jobId))
            .then(res => {
                if (res.success) {
                    rl()
                }
            })
    }


    return (
        <div className="mb-3">
            <div className="row">
                <div className="col">
                    <h4>Client Approval</h4>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    {approval.jobApprovalId === 0 ? 
                    "No Approval" : approval.approval ? 
                    `Approval Logged by: ${approval.approver} on ${approval.approvalDate}` : 
                    `Client Denied Approval - Logged by: ${approval.approver} on ${approval.approvalDate}`}
                </div>
            </div>
            <div className="mt-3">
                <Attachments
                    jobId={jobId}
                    jobStep={7}
                    title="Client Approval Attachments"
                    editable={approval.edit}
                    />
            </div>
            {approval.edit &&
                <div className="row">
                    <div className="col text-right">
                        <Button className="enc-button mr-1" onClick={Reject}>Reject</Button>
                        <Button className="enc-button" onClick={Approve}>Approve</Button>
                    </div>
                </div>
            }
        </div>
    )
}
