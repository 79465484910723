import React, { useEffect, useState } from 'react';
import { ListGroup, Button } from 'react-bootstrap';
import { DropdownList } from 'react-widgets';
import { GetBulkItemList } from '../../API/InventoryBulk';
import { PostShopConversion, GetShopConversion } from '../../API/ShopConversion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

export default props => {
    const [shopConversion, setShopConversion] = useState({
        shopConversionId: 0,
        bulkComponents: []
    })

    const [bulkItems, setBulkItems] = useState([])
    const [selectedBulk, setSelectedBulk] = useState(null)

    useEffect(() => {
        let id = props.match.params.id;
        LoadShopConversion(id)
        LoadBulkItems();
    }, [props.match.params.id])

    const LoadBulkItems = () => {
        GetBulkItemList()
            .then(res => {
                setBulkItems(res.data)
            })
    }

    const AddComponent = () => {
        let s = { ...shopConversion }
        let payload = { ...selectedBulk, amount: 0 }
        s.bulkComponents.push(payload)
        setShopConversion(s)
        setSelectedBulk(null)
    }

    const LoadShopConversion = (id) => {
        GetShopConversion(id)
            .then(res => {
                setShopConversion(res.data)
            })
    }

    const SaveShopConversion = () => {
        PostShopConversion(shopConversion)
            .then(res => {
                LoadShopConversion(props.match.params.id)
            })
    }

    const ChangeAmount = (index, value) => {
        let reg = new RegExp('^[\\d]+$')
        if (reg.test(value) || value === "") {
            let s = { ...shopConversion }
            if (value !== "") {
                s.bulkComponents[index].amount = parseInt(value)
            } else {
                s.bulkComponents[index].amount = ""
            }

            setShopConversion(s)
        }
    }

    const RemoveBulkComponent = (index) => {
        let s = { ...shopConversion }
        s.bulkComponents.splice(index, 1)
        setShopConversion(s)
    }

    return (

        <div className="container">
            <h4>Shop Form Conversion</h4>
            <div className="divider my-3" />
            <form action="#" onSubmit={(e) => { e.preventDefault(); SaveShopConversion() }}>
                <div className="row">
                    <div className="col">
                        <DropdownList
                            data={bulkItems.filter(item => !shopConversion.bulkComponents.find(({ inventoryBulkItemId }) => item.inventoryBulkItemId === inventoryBulkItemId))}
                            placeholder="Select a Bulk Item"
                            textField='bulkItemName'
                            value={selectedBulk}
                            onChange={(e) => setSelectedBulk(e)}
                        />
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="col text-right">
                        <Button className="enc-button" onClick={AddComponent}>Add Component</Button>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <h4>Job Ready Item: <strong>{shopConversion.jobReadyInventoryItemName}</strong></h4>
                    </div>
                </div>
                <ListGroup>
                    <ListGroup.Item>
                        <div className="row">
                            <div className="col">
                                Material Name
                                </div>
                            <div className='col'>
                                Amount
                                </div>
                        </div>
                    </ListGroup.Item>
                    {shopConversion.bulkComponents.map((m, i) => {
                        return (
                            <ListGroup.Item key={`bulk-component-${i}`}>
                                <div className="row">
                                    <div className="col">
                                        {m.bulkItemName}
                                    </div>
                                    <div className='col'>
                                        <input type='number' value={m.amount} onChange={e => ChangeAmount(i, e.target.value)} className="enc-input-100" required />
                                    </div>
                                    <div className="col text-right">
                                        <FontAwesomeIcon icon={faTrash} className="clickable" onClick={e => RemoveBulkComponent(i)} />
                                    </div>
                                </div>
                            </ListGroup.Item>
                        )
                    })}
                </ListGroup>
                <div className="row mt-1">
                    <div className="col text-right">
                        <Button type='submit' className="enc-button mt-2">Save Conversion</Button>
                    </div>
                </div>
            </form>
        </div>
    )
}
