import React, { useState, useEffect } from 'react';
import { GetTransferRequest, PostShipItems, CloseRequest } from '../API/InventoryTransfer';
import { Button, ListGroup, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

export default props => {
    const [pickOrder, setPickOrder] = useState({
        "transferRequestId": 0,
        "requestingUser": "",
        "date": "",
        "transferItems": [],
        "transferItemsBulk": [],
        "assetTransfers": [],
        "transferType": "",
        "completed": true,
        "origin": "",
        "destination": "",
        "allowClose": true
    })

    const [working, setWorking] = useState(false);

    const [damagedItems, setDamagedItems] = useState(false);

    useEffect(() => {
        LoadPickTicket(props.match.params.id)
    }, [props.match.params.id])

    const LoadPickTicket = (id) => {
        GetTransferRequest(id)
            .then(res => {
                let s = res.data;
                s.transferItems.forEach(e => {
                    if (e.damagedAmount > 0) {
                        setDamagedItems(true);
                    }
                    e.selected = 0;
                });
                s.assetTransfers.forEach(e => {
                    e.selected = false;
                })
                setPickOrder(res.data);
            })
    }

    const ShipItems = () => {
        let s = { ...pickOrder };
        setWorking(true);
        s.transferItems = s.transferItems.map(m => m.selected = parseInt(m.selected));
        s.transferItemsBulk = s.transferItemsBulk.map(m => m.selected = parseInt(m.selected));
        PostShipItems(pickOrder)
            .then(res => {
                LoadPickTicket(props.match.params.id);
                setWorking(false);
            })
    }

    const ChangeSelectedAmount = (index, value) => {
        let s = { ...pickOrder };
        if (new RegExp('^([0-9]{0,10})$').test(value)) {
            s.transferItems[index].selected = value;
            if (s.transferItems[index].amountRemaining - parseInt(value) < 0) {
                return;
            }
            setPickOrder(s);
        }
    }

    const ChangeSelectedAmountBulk = (index, value) => {
        let s = { ...pickOrder };
        if (new RegExp('^([0-9]{0,10})$').test(value)) {
            s.transferItemsBulk[index].selected = value;
            if (s.transferItemsBulk[index].amountRemaining - parseInt(value) < 0) {
                return;
            }
            setPickOrder(s);
        }
    }

    const ChangeSelectedDamagedAmount = (index, value) => {
        let s = { ...pickOrder };
        if (new RegExp('^([0-9]{0,10})$').test(value)) {
            s.transferItems[index].selectedDamaged = value;
            if (s.transferItems[index].damagedAmount - parseInt(value) < 0) {
                return;
            }
            setPickOrder(s);
        }
    }

    const ChangeSelectedDamagedAmountBulk = (index, value) => {
        let s = { ...pickOrder };
        if (new RegExp('^([0-9]{0,10})$').test(value)) {
            s.transferItemsBulk[index].selectedDamaged = value;
            if (s.transferItemsBulk[index].damagedAmount - parseInt(value) < 0) {
                return;
            }
            setPickOrder(s);
        }
    }

    const SelectAsset = (index) => {
        let s = { ...pickOrder };
        s.assetTransfers[index].selected = !s.assetTransfers[index].selected;
        setPickOrder(s);
    }

    const history = useHistory();
    const closeRequest = () => {
        CloseRequest(props.match.params.id)
            .then(res => {
                history.goBack();
            })
    }

    const SelectAll = () => {
        let s = { ...pickOrder };

        s.transferItems.forEach(m => {
            m.selected = m.amountRemaining;
            m.selectedDamaged = m.damagedAmount;
        });

        s.transferItemsBulk.forEach(m => {
            m.selected = m.amountRemaining;
            m.selectedDamaged = m.damagedAmount;
        });

        s.assetTransfers.forEach(m => m.selected = true);
        setPickOrder(s);
    }

    const SelectMax = (index) => {
        let s = { ...pickOrder };
        s.transferItems[index].selected = s.transferItems[index].amountRemaining;
        s.transferItems[index].selectedDamaged = s.transferItems[index].damagedAmount;
        setPickOrder(s);
    }

    const SelectMaxBulk = (index) => {
        let s = { ...pickOrder };
        s.transferItemsBulk[index].selected = s.transferItemsBulk[index].amountRemaining;
        s.transferItemsBulk[index].selectedDamaged = s.transferItemsBulk[index].damagedAmount;
        setPickOrder(s);
    }

    return (
        <div>
            <div className="row">
                <div className="col">
                    <h4>Origin: {pickOrder.origin}</h4>
                </div>
                <div className="col">
                    <h4>Destination: {pickOrder.destination}</h4>
                </div>
            </div>
            <div className="row my-3">
                <div className="col">
                    Date Placed: {pickOrder.date}
                </div>
                <div className="col">
                    Ticket Type: {pickOrder.transferType}
                </div>
            </div>
            <div className="row my-1">
                <div className="col">
                    <Button onClick={SelectAll} className="enc-button">Select All</Button>
                </div>
            </div>

            {/* ASSETS */}

            {pickOrder.assetTransfers.length > 0 &&
                <ListGroup className="mt-2">
                    <ListGroup.Item className="enc-data-list">
                        Assets
                    </ListGroup.Item>
                    <ListGroup.Item className="enc-data-list">
                        <div className="row">
                            <div className="col">
                                Asset
                        </div>
                            <div className="col">
                                Serial Number
                        </div>
                            <div className="col">
                                Status
                        </div>
                            <div>
                                Select
                        </div>
                        </div>
                    </ListGroup.Item>
                    {pickOrder.assetTransfers.map((m, i) =>
                        <ListGroup.Item key={`asset-${i}`}>
                            <div className="row">
                                <div className="col">
                                    {m.itemName}
                                </div>
                                <div className="col">
                                    {m.serialNumber}
                                </div>
                                <div className="col">
                                    {m.status}
                                </div>
                                <div>
                                    <div className="toggle-switch small-switch">
                                        <input
                                            type="checkbox"
                                            className="toggle-switch-checkbox"
                                            id={i}
                                            checked={m.selected}
                                            onChange={() => SelectAsset(i)}
                                        />
                                        <label className="toggle-switch-label" htmlFor={i}>
                                            <span className={"toggle-switch-inner"} />
                                            <span
                                                className={"toggle-switch-switch"}
                                            />
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </ListGroup.Item>
                    )}
                </ListGroup>
            }

            {/* JOB READY ITEMS */}

            {pickOrder.transferItems.length > 0 &&
                <ListGroup className="mt-2">
                    <ListGroup.Item className="enc-data-list">
                        Job Ready Items
                    </ListGroup.Item>
                    <ListGroup.Item className="enc-data-list">
                        <div className="row">
                            <div className="col">
                                Item Name
                        </div>
                            <div className="col">
                                Order Total
                        </div>
                            <div className="col">
                                Remaining Unshipped
                        </div>
                            <div className="col">
                                Select Amount
                        </div>
                            <div className="col-1">

                            </div>
                        </div>
                        {damagedItems &&
                            <div className="row mt-2">
                                <div className="col">

                                </div>
                                <div className="col">

                                </div>
                                <div className="col">
                                    Damaged Items
                        </div>
                                <div className="col">
                                    Select Damaged Amount
                        </div>
                                <div className="col-1">

                                </div>
                            </div>
                        }
                    </ListGroup.Item>
                    {pickOrder.transferItems.map((m, i) =>
                        <ListGroup.Item key={`transferItem-${i}`}>
                            <div className="row">
                                <div className="col">
                                    {m.itemName}
                                </div>
                                <div className="col">
                                    {m.totalAmount}
                                </div>
                                <div className="col">
                                    {m.amountRemaining}
                                </div>
                                <div className="col">
                                    <input
                                        type='number'
                                        className="enc-input-100"
                                        value={m.selected}
                                        onChange={(e) => ChangeSelectedAmount(i, e.target.value)} />
                                </div>
                                <div className="col-1">
                                    <Button className="enc-button" size="sm" onClick={() => SelectMax(i)}>Max</Button>
                                </div>
                            </div>
                            {damagedItems &&
                                <div className="row mt-2">
                                    <div className="col">

                                    </div>
                                    <div className="col">

                                    </div>
                                    <div className="col">
                                        {m.damagedAmount}
                                    </div>
                                    <div className="col">
                                        <input
                                            type='number'
                                            className="enc-input-100"
                                            value={m.selectedDamaged}
                                            onChange={(e) => ChangeSelectedDamagedAmount(i, e.target.value)} />
                                    </div>
                                    <div className="col-1">

                                    </div>
                                </div>
                            }
                        </ListGroup.Item>
                    )}
                </ListGroup>
            }

            {/* BULK ITEMS */}

            {pickOrder.transferItemsBulk.length > 0 &&
                <ListGroup className="mt-2">
                    <ListGroup.Item className="enc-data-list">
                        Bulk Items
                    </ListGroup.Item>
                    <ListGroup.Item className="enc-data-list">
                        <div className="row">
                            <div className="col">
                                Item Name
                        </div>
                            <div className="col">
                                Order Total
                        </div>
                            <div className="col">
                                Remaining Unshipped
                        </div>
                            <div className="col">
                                Select Amount
                        </div>
                            <div className="col-1">

                            </div>
                        </div>
                        {damagedItems &&
                            <div className="row mt-2">
                                <div className="col">

                                </div>
                                <div className="col">

                                </div>
                                <div className="col">
                                    Damaged Items
                        </div>
                                <div className="col">
                                    Select Damaged Amount
                        </div>
                                <div className="col-1">

                                </div>
                            </div>
                        }
                    </ListGroup.Item>
                    {pickOrder.transferItemsBulk.map((m, i) =>
                        <ListGroup.Item key={`transferItem-${i}`}>
                            <div className="row">
                                <div className="col">
                                    {m.itemName}
                                </div>
                                <div className="col">
                                    {m.totalAmount}
                                </div>
                                <div className="col">
                                    {m.amountRemaining}
                                </div>
                                <div className="col">
                                    <input
                                        type='number'
                                        className="enc-input-100"
                                        value={m.selected}
                                        onChange={(e) => ChangeSelectedAmountBulk(i, e.target.value)} />
                                </div>
                                <div className="col-1">
                                    <Button className="enc-button" size="sm" onClick={() => SelectMaxBulk(i)}>Max</Button>
                                </div>
                            </div>
                            {damagedItems &&
                                <div className="row mt-2">
                                    <div className="col">

                                    </div>
                                    <div className="col">

                                    </div>
                                    <div className="col">
                                        {m.damagedAmount}
                                    </div>
                                    <div className="col">
                                        <input
                                            type='number'
                                            className="enc-input-100"
                                            value={m.selectedDamaged}
                                            onChange={(e) => ChangeSelectedDamagedAmountBulk(i, e.target.value)} />
                                    </div>
                                    <div className="col-1">

                                    </div>
                                </div>
                            }
                        </ListGroup.Item>
                    )}
                </ListGroup>
            }

            
            <div className="row mt-2">
                <div className="col text-right">
                    {pickOrder.allowClose && <Button className="enc-button mr-3" onClick={closeRequest}>Close Ticket</Button>}
                    <Button className="enc-button" disabled={pickOrder.allowClose || working} onClick={ShipItems}>
                        {working ?
                            <span>Processing
                                    <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                /></span> : "Ship Items"}
                    </Button>
                </div>
            </div>
        </div>
    )
}
