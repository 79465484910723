import React from 'react';
import { Switch, Route} from 'react-router-dom';
import Stage2View from './Stage2View';
import ProposalEdit from './Proposal/ProposalEdit';

export default props => {
    return (
        <>
            <Switch>
                <Route path={`${props.match.url}/Proposal`} render={(routeProps) => 
                    <ProposalEdit jobId={props.jobId} {...routeProps} redirectUrl={props.match.url}/>
                }/>
                <Route path={`${props.match.url}/`} render={(routeProps) =>
                    <Stage2View reload={props.reload} jobId={props.jobId} {...routeProps} />
                } />
            </Switch>
        </>
    )
}
