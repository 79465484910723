import React, { useState, useEffect } from 'react';
import { GetAnnouncementsList } from '../API/Announcement'
import { useHistory, Link } from 'react-router-dom';
import { Button, ListGroup } from 'react-bootstrap';

export default props => {
    const [AnnouncementList, setAnnouncementList] = useState([]);

    useEffect(() => {
        loadAnnouncements({ upcoming: true, showDisabled: true })
    }, [])

    const history = useHistory();

    const loadAnnouncements = (payload) => {
        GetAnnouncementsList(payload)
            .then(res => {
                setAnnouncementList(res.data)
            })
    }

    const RouteToAnnouncement = (e) => {
        history.push(`/Admin/Announcement/${e.currentTarget.id}`)
    }

    return (
        <div className="container my-5">
            <div className="row">
                <div className="col">
                    <Button className="enc-button" as={Link} to="/Admin/Announcement/new">Create Announcement</Button>
                </div>
            </div>



            <ListGroup className="mt-1">
                <ListGroup.Item className="enc-list">
                    Announcement List
                </ListGroup.Item>
                {AnnouncementList.length === 0 &&
                    <ListGroup.Item>
                        No Announcments Found
                </ListGroup.Item>
                }
                {AnnouncementList.map((m, index) => {
                    let start = new Date(m.startDate);
                    let end = new Date(m.endDate);
                    return (
                        <ListGroup.Item className="click-list" id={m.announcementId} onClick={RouteToAnnouncement} key={`Announcement-${m.announcementId}`}>
                            <div className="row">
                                <div className="col-6">
                                    <h5>{m.title}</h5>
                                </div>
                                <div className="col-2">
                                    {m.enabled ? null : <small style={{ color: "red" }}>Disabled</small>}
                                </div>
                                <div className="col-2">
                                    Start: {start.toLocaleDateString()}
                                </div>
                                <div className="col-2">
                                    End: {end.toLocaleDateString()}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    {m.details}
                                </div>
                            </div>
                        </ListGroup.Item>
                    )
                })}
            </ListGroup>
        </div>
    )
}
