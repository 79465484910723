import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { GetJobScopeBudgetSummary } from '../../../API/JobScope';
import { Button } from 'react-bootstrap';

export default ({ jobId }) => {

    const downloadBudgetSummary = () => {
        let download = require('downloadjs')
        GetJobScopeBudgetSummary(jobId)
            .then(res => {
                download(res, `BudgetSummary-${jobId}.xlsx`)
            })
    }

    return (
        <Button variant="success" onClick={() => downloadBudgetSummary()} className="ml-2">
            <FontAwesomeIcon icon={faDownload} />
        </Button>
    )
}
