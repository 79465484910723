import APIRequest from './MainAPICall/api';
import config from '../APIConfig';

export const PostShopConversion = (payload) => {
    return APIRequest({
        url: config.baseURL + "/ShopConversion/ShopConversion",
        method: "post",
        body: payload
    });
}

export const GetShopConversion = (id) => {
    return APIRequest({
        url: config.baseURL + `/ShopConversion/ShopConversion?jobReadyId=${id}`,
        method: "get",
    });
}

export const GetShopActions = () => {
    return APIRequest({
        url: config.baseURL + `/ShopConversion/ShopActions`,
        method: "get",
    });
}

export const GetShopTicketOptions = (type, yard) => {
    return APIRequest({
        url: config.baseURL + `/ShopConversion/ShopTicketOptions?type=${type}&yard=${yard}`,
        method: "get",
    });
}

export const GetShopTicketList = (yard, closed = false) => {
    return APIRequest({
        url: config.baseURL + `/ShopConversion/ShopTicketList?&yardId=${yard}&closed=${closed}`,
        method: "get",
    });
}

export const PostShopTicket = (payload) => {
    return APIRequest({
        url: config.baseURL + `/ShopConversion/ShopTicket`,
        method: "post",
        body: payload
    });
}

export const GetShopTicket = (id) => {
    return APIRequest({
        url: config.baseURL + `/ShopConversion/ShopTicket?ticketId=${id}`,
        method: "get",
    });
}

export const AdjustShopTicket = (payload) => {
    return APIRequest({
        url: config.baseURL + `/ShopConversion/AdjustShopTicket`,
        method: "post",
        body: payload
    });
}