import React, { useState, useEffect } from 'react';
import { GetUser, ResetPasswordById, EditUser } from '../API/User';
import '../css/User.css';
import ProfileDisplay from '../Components/ProfileDisplay';
import { SavePhoneNumber, SaveEmail } from '../API/User';
import { Link } from 'react-router-dom';
import { Alert, ListGroup } from 'react-bootstrap';

export default props => {
    const [userInfo, setUserInfo] = useState({
        userId: 0,
        username: "",
        firstName: "",
        lastName: "",
        fullName: "",
        positionTitle: "",
        phoneNumbers: [],
        emails: [],
        officeLocations: [],
        employeePhoto: "",
        enabled: true
    })
    const [show, setShow] = useState(false)

    useEffect(() => {
        getUserInfo(parseInt(props.userId));
    }, [props.userId]);

    const getUserInfo = (id) => {
        GetUser(id)
            .then(res => {
                if (res.success)
                    setUserInfo(res.data)
            })
            .catch(err => {
                console.error("Unable to load user data.")
            })
    }

    const saveEmail = (payload) => {
        payload["userId"] = userInfo.userId
        SaveEmail(payload)
            .then(res => {
                let update = { ...userInfo };
                update['emails'] = res.emailList;
                setUserInfo(update);
            })
            .catch(err => {
                console.error(err)
            })
    }


    const savePhone = (payload) => {
        payload["userId"] = userInfo.userId
        SavePhoneNumber(payload)
            .then(res => {
                let update = { ...userInfo };
                update['phoneNumbers'] = res.phoneList;
                setUserInfo(update);
            })
            .catch(err => {
                console.error(err)
            })
    }

    const resetPassword = () => {
        ResetPasswordById(userInfo.userId)
            .then(res => {
                if (res.success) {
                    setShow(true)
                }
            })
    }

    const deactivateUser = () => {
        let payload = { ...userInfo }
        payload.enabled = false;
        EditUser(payload)
            .then(res => {
                getUserInfo(userInfo.userId)
            })
    }

    const activateUser = () => {
        let payload = { ...userInfo };
        payload.enabled = true;
        EditUser(payload)
            .then(res => {
                getUserInfo(userInfo.userId)
            })
    }





    return (
        <div className="container-fluid">
            <ProfileDisplay saveEmail={saveEmail} savePhone={savePhone} userInfo={userInfo} />
            <div className="container">
                <div className="divider my-2" />
                <div className="row">
                    <div className="col text-center">
                        <h4>User Office Assignments</h4>
                    </div>
                </div>
                <ListGroup>
                    <ListGroup.Item className="enc-list">
                        Currently Assigned
                        </ListGroup.Item>
                    {userInfo.officeLocations.map((m, i) => {
                        return (
                            <ListGroup.Item key={`officeLocation-${i}`}>
                                <div className="row">
                                    <div className="col">
                                        {m.name}
                                    </div>
                                </div>
                            </ListGroup.Item>
                        )
                    })}

                </ListGroup>

            </div>
            <div className="divider my-2" />
            <div className="row justify-content-center mt-3">
                <div className="col-md text-center">
                    <h3>Manage Account</h3>
                </div>
            </div>

            <Alert variant="danger" show={show} onClose={() => setShow(false)} dismissible>
                Successfully Reset Users Password.
            </Alert>
            {/* Annoying Ternary Below to decide if user is active or not and menu to display.*/}
            <div className="my-4">
                <div className="container">
                    {userInfo.enabled ? (
                        <div className="row justify-content-center">
                            <div className="col-3 text-center">
                                <h5 onClick={resetPassword} className="fake-link">Reset Password</h5>
                            </div>
                            <div className="col-3 text-center">
                                <h5 onClick={deactivateUser} className="fake-link">Deactivate User</h5>
                            </div>
                            <div className="col-3 text-center">
                                <Link to={`/Admin/User/${userInfo.userId}/Edit`}><h5>Edit User</h5></Link>
                            </div>
                            <div className="col-3 text-center">
                                <Link to={`/Admin/User/${userInfo.userId}/EditSignature`}><h5>Edit User Signature</h5></Link>

                            </div>
                        </div>
                    ) : (
                            <div className="row justify-content-center">
                                <div className="col text-center">
                                    <h5 className="fake-link" onClick={activateUser}>
                                        Activate User
                            </h5>
                                </div>
                            </div>
                        )}
                </div>
            </div>
            {/* End of Annoying Ternary.*/}

            {/* <Modal show={confirm} onHide={() => setConfirm(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Password Reset</Modal.Title>
                </Modal.Header>
                <Modal.Body>Confirm Password Reset for "{userInfo.username}". This will email them a new password</Modal.Body>
                <Modal.Footer>
                    <Button className="enc-button" onClick={() => setConfirm(false)}>
                        Close
                    </Button>
                    <Button className="enc-button" onClick={resetPassword}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal> */}
        </div>
    )
}
