import { Button, Modal, Form } from 'react-bootstrap';
import {EmailJSAPDF, EmailFieldTicketPDF, EmailEquipmentFormPDF, EmailBolPDF} from "../../../API/JobFormPdf";
import React, { useState } from 'react';

export default props =>{

    const [emailForm, setEmailForm] = useState({
        message: "",
        toAddress: "",
        toName: "",
        fromName:""
    });
    
    const [modalController, setModalController] = useState({
        show: false   
    })

    const EmailForm = () =>{
        switch (props.formType){
            case "JSA":
               EmailJSAPDF(props.formId, emailForm.toAddress, emailForm.toName, emailForm.message, emailForm.fromName, (new Date()).getTimezoneOffset())
                    break
            case "Field Ticket":
                EmailFieldTicketPDF(props.formId, emailForm.toAddress, emailForm.toName, emailForm.message, emailForm.fromName)
                    break
            case "Equipment Form":
                EmailEquipmentFormPDF(props.formId, emailForm.toAddress, emailForm.toName, emailForm.message, emailForm.fromName)
                break
            case "BOL":
                EmailBolPDF(props.formId, emailForm.toAddress, emailForm.toName, emailForm.message, emailForm.fromName)
                break
            default:
                break
        }
    }

    const handleModalShowHide=() =>{
        let controller = {...modalController};
        controller.show = !modalController.show;
        setModalController(controller);
    };

    const handleChange = (e) =>{
        let form = {...emailForm}
        form[e.target.name] = e.target.value
        setEmailForm(form)
    }

    const handleSubmit =(e) => {
        e.preventDefault()
        EmailForm()
        let controller = {...modalController}
        controller.show = !modalController.show
        setModalController(controller)
    }

    return (
        <>
            <Button className="enc-button ml-2" onClick = {handleModalShowHide}>
                Share
            </Button>
            <Modal show = {modalController.show} onHide = {()=>{return null}}>

                <Modal.Header closeButton onClick={handleModalShowHide}>
                    <Modal.Title>Send form as email attachment</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form onSubmit={handleSubmit}>
                        <Form.Group>
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" placeholder="Enter email address" name="toAddress" onChange={handleChange}/>
                        </Form.Group>
                        <Form.Group >
                            <Form.Label>Recipients Name</Form.Label>
                            <Form.Control type="text" placeholder="Name" name="toName" onChange={handleChange}/>
                        </Form.Group>
                        <Form.Group >
                            <Form.Label>Your Name</Form.Label>
                            <Form.Control type="text" placeholder="Your Name" name="fromName" onChange={handleChange}/>
                        </Form.Group>
                        <Form.Group >
                            <Form.Label>Message</Form.Label>
                            <Form.Control type="text" placeholder="Message" name="message" onChange={handleChange}/>
                        </Form.Group>
                        <Button type="submit">
                            Send
                        </Button>
                    </Form>

                </Modal.Body>

            </Modal>
        </>

    )

}