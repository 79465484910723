import React, { useState, useEffect } from 'react';
import EquipmentChecklist from './EquipmentChecklist';
import ViewEquipmentForm from './ViewEquipmentForm';
import { CheckEditEquipmentForm } from '../../API/JobForm';

export default props => {
    const [edit, setEdit] = useState(false);
    useEffect(()=>{
        LoadPage(props.match.params.id)
    },[props.match.params.id])
    const LoadPage =(arg) => {
        CheckEditEquipmentForm(arg)
        .then(res=>{
            setEdit(res.success)
        })
    }
    return (
        <>
            {edit?<EquipmentChecklist stepId={props.stepId} lp={() => LoadPage(props.match.params.id)} EquipmentFormId={props.match.params.id} {...props} />:<ViewEquipmentForm {...props} />}
        </>
    )
}
