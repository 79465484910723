import React, { useState, useEffect } from 'react';
import { DropdownList } from 'react-widgets';
import { Button } from 'react-bootstrap';

export default ({ revenue = [], labor = [], nonlabor = [], tax = [], add }, props) => {
    const [selected, setSelected] = useState("")
    const selections = ["Revenue", "Non-Labor", "Labor", "Tax"];
    const [list, setList] = useState([])
    const [lineSelect, setLineSelect] = useState({})

    const changeSelection = (e) => {
        setSelected(e)
    }

    useEffect(()=>{
        const loadDrops = (e) => {
            switch (e) {
                case "Revenue":
                    setList(revenue)
                    if (revenue.length !== 0) {
                        setLineSelect(revenue[0])
                    }
                    break;
                case "Non-Labor":
                    setList(nonlabor)
                    if (nonlabor.length !== 0) {
                        setLineSelect(nonlabor[0])
    
                    }
                    break;
                case "Labor":
                    setList(labor)
                    if (labor.length !== 0) {
                        setLineSelect(labor[0])
                    }
                    break;
                case "Tax":
                    setList(tax);
                    break;
                default:
                    break;
            }
        }
        loadDrops(selected)
    },[selected, revenue, labor, nonlabor])


    const addItem = () => {
        add(selected, lineSelect)
        changeSelection(selected)
    }

    return (
        <>
        <div className="row">
            <div className="col">
                <strong>Add Lines to Budget</strong>
            </div>
        </div>
        <div className="row my-4">
            <div className="col-3">
                <DropdownList placeholder="Select Type" value={selected} data={selections} onChange={e => changeSelection(e)} />
            </div>
            <div className="col-7">
                {list.length > 0 && <DropdownList filter="contains" value={lineSelect} textField={(item) => { return `${item.number} - ${item.name}` }} data={list} onChange={e => setLineSelect(e)} />}
            </div>
            <div className="col-2 text-right">
                <Button className="enc-button" onClick={addItem} disabled={Object.keys(lineSelect).length === 0 && selected !== "Tax"}>Add Line</Button>
            </div>
        </div>
        </>
    )
}
