import APIRequest from './MainAPICall/api';
import config from '../APIConfig';

export const YardList = () => {
    return APIRequest({
        url: config.baseURL + "/Inventory/YardList",
        method: "get"
    })
}

export const AllowedYardList = () => {
    return APIRequest({
        url: config.baseURL + "/Inventory/AllowedYards",
        method: "get"
    })
}

export const GetJobInventoryProfile = (id) => {
    return APIRequest({
        url: config.baseURL + `/Inventory/JobInventoryProfile?id=${id}`,
        method: "get"
    })
}

export const PostYard = (payload) => {
    return APIRequest({
        url: config.baseURL + `/Inventory/Yard`,
        method: "post",
        body: payload
    })
}

export const GetInventoryAudit = (amount) => {
    return APIRequest({
        url: config.baseURL + `/Inventory/InventoryAudit?amount=${amount}`,
        method: "get"
    })
}

export const GetJobInventory = (id) => {
    return APIRequest({
        url: config.baseURL + `/Inventory/JobInventory?JobId=${id}`,
        method: "get"
    })
}

export const GetYardInventory = (id) => {
    return APIRequest({
        url: config.baseURL + `/Inventory/YardInventory?YardId=${id}`,
        method: "get"
    })
}

export const PostYardInventory = (payload) => {
    return APIRequest({
        url: config.baseURL + `/Inventory/YardInventory`,
        method: "post",
        body: payload
    })
}

export const GetInventoryCategoryList = (payload) => {
    return APIRequest({
        url: config.baseURL + `/Inventory/InventoryCategoryList`,
        method: "get"
    })
}
export const PostInventoryCategory = (payload) => {
    return APIRequest({
        url: config.baseURL + `/Inventory/InventoryCategory`,
        method: "post",
        body: payload
    })
}
export const GetInventoryCategory = (id) => {
    return APIRequest({
        url: config.baseURL + `/Inventory/InventoryCategory?id=${id}`,
        method: "get"
    })
}

export const DeleteInventoryCategory = (id) => {
    return APIRequest({
        url: config.baseURL + `/Inventory/DeleteInventoryCategory?id=${id}`,
        method: "delete"
    })
}