
import React, { useEffect, useState } from 'react';
import { Button, ListGroup } from 'react-bootstrap';
import { GetShopTicketOptions, PostShopTicket } from '../../API/ShopConversion';
import { DropdownList } from 'react-widgets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

export default props => {
    const [ticketOptions, setTicketOptions] = useState([]);

    const [ticketName, setTicketName] = useState("");

    const [selectedOption, setSelectedOption] = useState(null);

    const [shopTicketItems, setShopTicketItems] = useState([]);

    const [amount, setAmount] = useState(0)

    const [damagedAmount, setDamagedAmount] = useState(0)

    const [error, setError] = useState("")

    useEffect(() => {
        GetShopTicketOptions(props.action.shopActionId, props.yard.yardId)
            .then(res => {
                setTicketOptions(res.data)
            })
    }, [props.yard.yardId, props.action.shopActionId])

    const AddItem = () => {
        if (selectedOption) {
            let s = [...shopTicketItems]
            s.push({ ...selectedOption, amount: amount, damagedAmount: damagedAmount })
            setShopTicketItems(s)
            setSelectedOption(null)
            setAmount(0)
            setDamagedAmount(0)
        }
    }

    const setDam = (value) => {
        if (new RegExp('^([0-9]{0,10})$').test(value)) {
            if (selectedOption.damagedAvailable - parseInt(value) < 0) {
                return;
            }
            setDamagedAmount(value)
        }
    }

    const setAmm = (value) => {
        if (new RegExp('^([0-9]{0,10})$').test(value)) {
            if (selectedOption.amountAvailable - parseInt(value) < 0) {
                return;
            }
            setAmount(value)
        }
    }

    const changeShopTicket = (index, value) => {
        let s = [ ...shopTicketItems ]
        if (new RegExp('^([0-9]{0,10})$').test(value)) {
            s[index].amount = value
            if (s[index].amountAvailable - parseInt(value) < 0) {
                return;
            }
            setShopTicketItems(s)
        }
    }

    const changeDamagedAmount = (index, value) => {
        let s = [ ...shopTicketItems ]
        if (new RegExp('^([0-9]{0,10})$').test(value)) {
            s[index].damagedAmount = value
            if (s[index].damagedAvailable - parseInt(value) < 0) {
                return;
            }
            setShopTicketItems(s)
        }
    }

    const removeItem = (i) => {
        let s = [...shopTicketItems]
        s.splice(i, 1)
        setShopTicketItems(s)
    }

    const SaveShopTicket = () => {
        let payload = {
            shopAction: props.action.shopActionId,
            yardId: props.yard.yardId,
            ticketName: ticketName
        }
        let convertInt = []
        if (shopTicketItems.length !== 0) {
            shopTicketItems.forEach(element => {
                if(element.amount === "") { element.amount = 0}
                if(element.damagedAmount === "") { element.amount = 0}
                convertInt.push({ 
                    ...element, 
                    amount: parseInt(element.amount), 
                    damagedAmount: parseInt(element.damagedAmount) 
                })
            });
        } else {
            setError("Please add items to shop ticket")
            return;
        }
        payload.shopTicketItems = convertInt
        PostShopTicket(payload)
            .then(res => {
                props.save()
            })
    }

    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <h4>{props.action.name} items at {props.yard.yardName}</h4>
                </div>
            </div>
            <div className="row mt-2">

                <div className="col">
                    <small>Ticket Name Identifier</small>
                    <input className="enc-input-100" value={ticketName} onChange={e => setTicketName(e.target.value)} />
                </div>
            </div>
            <div className="row mt-2" style={{ minHeight: '5rem' }}>
                <div className="col">
                    <DropdownList
                        placeholder="Select an Item"
                        data={ticketOptions}
                        textField='jobReadyItemName'
                        value={selectedOption}
                        onChange={e => setSelectedOption(e)}
                    />
                </div>
                <div className="col">
                    {selectedOption &&
                        <>
                            <div className="row">
                                <div className="col" style={{ height: '38px' }}>
                                    Amount Available:
                            </div>
                                <div className="col">
                                    {selectedOption.amountAvailable}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <input
                                        type='number'
                                        className="enc-input-100"
                                        value={amount}
                                        onChange={e => setAmm(e.target.value)}
                                    />
                                </div>
                            </div>
                            {props.action.shopActionId === 1 &&
                                <>
                                    <div className="divider my-2" />
                                    <div className="row">
                                        <div className="col" style={{ height: '38px' }}>
                                            Damaged Amount Available:
                            </div>
                                        <div className="col">
                                            {selectedOption.damagedAvailable}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <input
                                                type='number'
                                                className="enc-input-100"
                                                value={damagedAmount}
                                                onChange={e => setDam(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </>
                            }
                        </>
                    }
                </div>
            </div>
            <div className="row">
                <div className="col text-right">
                    <Button className="enc-button" onClick={AddItem}>Add Item</Button>
                </div>
            </div>
            <div className="divider my-2" />
            <form action="#" onSubmit={(e) => { e.preventDefault(); SaveShopTicket() }}>
                <ListGroup className="my-4">
                    <ListGroup.Item className="enc-list">Shop Ticket Contents</ListGroup.Item>
                    <ListGroup.Item>
                        <div className="row">
                            <div className="col">
                                Item Name
                                </div>
                            <div className="col">
                                Available at Yard
                                </div>
                            <div className="col">
                                Amount in Ticket
                                </div>
                        </div>
                    </ListGroup.Item>
                    {shopTicketItems.map((m, i) => {
                        return (
                            <ListGroup.Item key={`item-${i}`}>
                                <div className="row">
                                    <div className="col">
                                        {m.jobReadyItemName}
                                    </div>
                                    <div className="col">
                                        {m.amountAvailable}
                                    </div>
                                    <div className="col">
                                        <input value={m.amount} onChange={e => changeShopTicket(i, e.target.value)} className="enc-input-100" />
                                    </div>
                                    <div className="col-1">
                                        <FontAwesomeIcon icon={faTrash} onClick={() => removeItem(i)} className="clickable" />
                                    </div>
                                </div>
                                {props.action.shopActionId === 1 &&
                                    <>
                                        <div className="divider my-2" />

                                        <div className="row">
                                            <div className="col">
                                                Damaged {m.jobReadyItemName}
                                            </div>
                                            <div className="col">
                                                {m.damagedAvailable}
                                            </div>
                                            <div className="col">
                                                <input value={m.damagedAmount} onChange={e => changeDamagedAmount(i, e.target.value)} className="enc-input-100" />
                                            </div>
                                            <div className="col-1">

                                            </div>
                                        </div>
                                    </>
                                }
                            </ListGroup.Item>
                        )
                    })}
                </ListGroup>
                <div className="row">
                    <div className="col">
                        <span style={{ color: 'red' }}>{error}</span>
                    </div>
                    <div className="col text-right">
                        <Button type='submit' className="enc-button">Submit</Button>
                    </div>
                </div>
            </form>
        </>
    )
}


