
import React from 'react';
import { Button } from 'react-bootstrap';


export default ({ ShowButton, JobId, StepId, StageName, CloseStage, ...props }) => {
    return (
        <div>
            <div>

                <div className="container">
                    <div className="row my-2">
                        <div className="col">
                            <h1>Select a section to continue.</h1>
                        </div>
                    </div>
                </div>
                {ShowButton &&
                    <div className="container my-5">
                        <div className="row my-2">
                            <div className="col text-right">
                                <Button className="enc-button" onClick={CloseStage}>Close {StageName}</Button>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

