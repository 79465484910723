import React, { useState, useEffect, createRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import { DropdownList } from 'react-widgets';
import { Button, Accordion, Card, ListGroup } from 'react-bootstrap';
import EquipmentChecklist from './EquipmentChecklist';
import ViewEquipmentForm from './ViewEquipmentForm';
import { GetEquipmentFormListById, GetFieldTicketCommentType, GetOrphanedForms, PostFieldTicket, GetEditFieldTicket, GetHourFuelPhoto } from '../../API/JobForm';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck, faTrash, faDownload, faCamera } from '@fortawesome/free-solid-svg-icons';
import SignatureCanvas from 'react-signature-canvas';

import { FilePond, registerPlugin } from 'react-filepond';
import { useLocation, useHistory } from 'react-router-dom';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

import Webcam from "react-webcam";

export default props => {
    const [fieldTicket, setFieldTicket] = useState({
        date: new Date(),
        equipmentForms: [],
        fieldTicketComments: [{
            type: -1,
            comment: "",
            signature: "",
            signee: "",
            signatureRequired: false
        }],
        fieldTicketRoustabouts: [],
        clientSignaturePrint: "",
        clientSignatureData: {},
        supervisorSignatureData: {},
        supervisorSignaturePrint: ""
    });
    const [allowAdd, setAllowAdd] = useState(true);
    const [equipmentList, setEquipmentList] = useState([]);
    const [open, setOpen] = useState(0);
    const [hourFuel, setHourFuel] = useState(-1);
    const [commentTypes, setCommentTypes] = useState([]);
    const [recoverEquipment, setRecoverEquipment] = useState([]);
    const [selectedRecoverEquipment, setSelectedRecoverEquipment] = useState(-1);
    const [errorMessage, setErrorMessage] = useState("");
    const [commentRefs, setCommentRefs] = useState([]);

    const FACING_MODE_USER = "user";
    const FACING_MODE_ENVIRONMENT = "environment";

    const [isWebcam, setWebcam] = useState(false)
    const [isWebcamFrozen, setWebcamFrozen] = useState(false)
    const [webcamImage, setWebCamImage] = useState('');
    const [webcamImageName, setWebCamImageName] = useState('');

    const webcamRef = React.useRef(null);
    const filepondRef = React.useRef(null);

    const videoConstraints = {
        width: 1920,
        height: 1080,
        renderwidth: '',
        renderheight: '',
        facingMode: FACING_MODE_ENVIRONMENT
    };

    const webcamSnapshot = React.useCallback(
        () => {
            if (!isWebcamFrozen) {
                const imageSrc = webcamRef.current.getScreenshot();
                setWebCamImage(imageSrc)
            }
            else {
                setWebCamImage('')
                setWebCamImageName('')
            }
            setWebcamFrozen(!isWebcamFrozen)
        },
        [webcamRef, isWebcamFrozen]
    );

    registerPlugin(FilePondPluginFileValidateType);

    useEffect(() => {
        setCommentRefs(elRefs => (
            Array(fieldTicket.fieldTicketComments.length).fill().map((_, i) => elRefs[i] || createRef())
        ));
    }, [fieldTicket.fieldTicketComments.length]);

    useEffect(() => {
        LoadOrphanedForms(fieldTicket.date.toJSON(), props.jobId, equipmentList)
    }, [fieldTicket.date, props.jobId, equipmentList])

    useEffect(() => {
        LoadEquipmentList(equipmentList)
    }, [equipmentList])

    useEffect(() => {
        if (props.match.params.id !== 'new') {
            LoadEditFieldTicket(props.match.params.id)
        }
    }, [props.match.params.id])

    useEffect(() => {
        LoadCommentTypes()
    }, [])

    const LoadEditFieldTicket = (id) => {
        GetEditFieldTicket(id)
            .then(res => {
                setEquipmentList(res.data.equipmentFormInts)
                delete res.data.equipmentFormInts
                res.data.date = new Date(res.data.date)
                setFieldTicket(ft => ({ ...ft, ...res.data }))
            })
    }

    // FilePondConfiguration //
    const FilePondSettings = (equipmentFormId, i) => {
        let token;

        const LS = localStorage.getItem("BAENCToken");
        const SS = sessionStorage.getItem("BAENCToken");

        if (LS) {
            token = LS;
        } else if (SS) {
            token = SS;
        }

        return (
            {
                url: "/api/JobForm",
                process: {
                    url: "/HourFuelPicture",
                    method: "post",
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    ondata: (formData) => {
                        formData.append("EquipmentFormId", equipmentFormId);
                        return formData;
                    },
                    onload: (res) => {
                        res = JSON.parse(res);
                        if (res.success) {
                            let s = { ...fieldTicket }
                            s.equipmentForms[i].hourFuelPhoto = res.data;
                            setFieldTicket(s)
                        }
                    }
                },
                load: null,
                revert: null,
                restore: null,
                fetch: null,
                patch: null
            })
    }

    const downloadHourFuel = (id) => {
        let download = require('downloadjs')
        GetHourFuelPhoto(id)
            .then(res => {
                res.blob.then(b => {
                    download(b, res.fileName)

                })
            })
    }

    const LoadOrphanedForms = (date, jobId, equipList) => {
        GetOrphanedForms(date, jobId)
            .then(res => {
                let s = res.data.filter(form => !equipList.find(id => id === form.equipmentFormId))
                if (s.length === 0) {
                    s.unshift({
                        "equipmentFormId": -1,
                        "equipmentFormName": "- No Forms Found ",
                        "unitNumber": "",
                        "complete": true,
                        "hourFuelPhoto": true
                    })
                } else {
                    s.unshift({
                        "equipmentFormId": -1,
                        "equipmentFormName": "- Select a Form",
                        "unitNumber": "",
                        "complete": true,
                        "hourFuelPhoto": true
                    })
                }
                setRecoverEquipment(s)
            })

    }

    const recoverForm = () => {
        if (selectedRecoverEquipment !== -1) {
            let s = [...equipmentList]
            if (!s.includes(selectedRecoverEquipment)) {
                s.push(selectedRecoverEquipment)
            }
            setEquipmentList(s)
            setSelectedRecoverEquipment(-1)
        }
    }

    const LoadCommentTypes = () => {
        GetFieldTicketCommentType()
            .then(res => {
                res.data.unshift({
                    "fieldTicketCommentId": -1,
                    "fieldTicketCommentName": "Select Comment Type",
                    "signatureRequired": false
                })
                setCommentTypes(res.data)
            })
    }

    const saveFieldTicket = () => {
        let payload = buildPayload()
        PostFieldTicket(payload)
            .then(res => {
                LoadEditFieldTicket(res.id)
            })
    }

    let location = useLocation();
    let history = useHistory();

    const submitFieldTicket = () => {
        setErrorMessage("");
        let newLoc = location.pathname.split('/').slice(0, -1).join('/')
        fieldTicket.equipmentForms.forEach(ef => {
            if (!ef.complete) {
                setErrorMessage("Please complete all vehicle checklists.")
                return;
            }
        });
        if (fieldTicket.clientSignaturePrint === "") {
            setErrorMessage("Please fill out the Client Printed Name under Client Signature.");
            return;
        }
        else if (fieldTicket.supervisorSignaturePrint === "") {
            setErrorMessage("Please fill out the Supervisor Name under Supervisor Signature.");
            return;
        }
        let payload = buildPayload()
        payload.complete = true;
        PostFieldTicket(payload)
            .then(res => {
                if (res.success)
                    history.push(newLoc + `/${res.id}`)
            })
    }

    const buildPayload = () => {
        let payload = { ...fieldTicket }
        delete payload.equipmentForms
        payload.equipmentForms = equipmentList;
        if (props.stepId) {
            payload.stepId = parseInt(props.stepId);
        }
        payload.date = fieldTicket.date.toJSON();
        payload.jobId = parseInt(props.jobId);
        payload.fieldTicketComments = []
        for (let i = 0; i < fieldTicket.fieldTicketComments.length; i++) {
            let comment = { ...fieldTicket.fieldTicketComments[i] }
            if (commentRefs[i].current && !commentRefs[i].current.isEmpty()) {
                comment.signatureData = commentRefs[i].current.toDataURL();
            }
            payload.fieldTicketComments.push(comment)
        }
        payload.clientSignatureData = fieldTicket.clientSignatureData.toDataURL()
        payload.supervisorSignatureData = fieldTicket.supervisorSignatureData.toDataURL()
        payload.clientSignaturePrint = fieldTicket.clientSignaturePrint;
        payload.supervisorSignaturePrint = fieldTicket.supervisorSignaturePrint;
        return payload
    }

    const addComment = () => {
        let s = { ...fieldTicket }
        s.fieldTicketComments.push({
            type: -1,
            comment: "",
            signature: "",
            signee: "",
            signatureRequired: false
        })
        setFieldTicket(s)
    }

    const addRoustabout = () => {
        let s = { ...fieldTicket }
        s.fieldTicketRoustabouts.push({
            name: "",
            hours: 0
        })
        setFieldTicket(s)
    }

    const addEquipment = () => {
        let s = { ...fieldTicket }
        s.equipmentForms.push({
            equipmentFormId: 0,
            equipmentFormName: "Equipment Form",
            unitNumber: "Unsaved",
            complete: false
        })
        setOpen(s.equipmentForms.length - 1)
        setAllowAdd(false);
        setFieldTicket(s)
    }

    const editFieldTicket = (key, value) => {
        let s = { ...fieldTicket }
        s[key] = value
        setFieldTicket(s)
    }

    const editComment = (index, key, value) => {
        let s = { ...fieldTicket }
        s.fieldTicketComments[index][key] = value
        setFieldTicket(s)
    }

    const editCommentType = (index, e) => {
        let s = { ...fieldTicket }
        s.fieldTicketComments[index].commentType = e.fieldTicketCommentId
        s.fieldTicketComments[index].signatureRequired = e.signatureRequired
        if (e.signatureRequired && commentRefs[index].current) {
            commentRefs[index].current.clear()
        }
        setFieldTicket(s)
    }

    const validateHours = (index, key, value) => {
        const reg = RegExp('[^\\d]')
        value = value.replace(reg, '')
        editRoustabout(index, key, value)
    }

    const editRoustabout = (index, key, value) => {
        let s = { ...fieldTicket }
        s.fieldTicketRoustabouts[index][key] = value
        setFieldTicket(s)
    }

    const deleteRoustabout = (index) => {
        let s = { ...fieldTicket }
        s.fieldTicketRoustabouts.splice(index, 1)
        setFieldTicket(s)
    }

    const LoadEquipmentList = (el) => {
        GetEquipmentFormListById(el)
            .then(res => {
                setFieldTicket(fieldTicket => ({ ...fieldTicket, equipmentForms: res.data }))
            })
    }

    const addEquipmentId = (id) => {
        let s = [...equipmentList]
        if (!s.includes(id)) {
            s.push(id)
        }
        setEquipmentList(s)
        setAllowAdd(true)
    }

    const clickEquipment = (AccordionIndex) => {
        if (open === AccordionIndex) {
            setOpen(-1)
            return;
        }
        setOpen(AccordionIndex)
    }

    const clickHourFuel = (i) => {
        if (hourFuel === i) {
            setHourFuel(-1)
            return;
        }
        setHourFuel(i)
    }

    const handleSignatureChange = (e) => {
        const form = { ...fieldTicket }
        form[e.target.name] = e.target.value
        setFieldTicket(form)
    }

    const uploadCamera = () => {
        var fp = filepondRef.current;
        if (fp) {
            fp.addFile(webcamImage).then(item => {
                var n = webcamImageName;
                if (n)
                    item.file.name = n;
                var t = item.file.type; // attempt to append the correct filetype
                t = t.replace("image/", "");
                item.file.name = item.file.name + "." + t;
            });
        }
        resetCamera();
    }

    const renderCameraButtons = () => {
        return <div>
            {webcamImage && isWebcamFrozen ?
                <div className="row justify-content-center">
                    <input type="text"
                        className="enc-input-100"
                        name="webcamImageName"
                        value={webcamImageName}
                        placeholder="Image Name"
                        onChange={e => setWebCamImageName(e.target.value)}
                    />
                </div>
                : null}
            <div className="row justify-content-center">
                <Button className="enc-button" onClick={() => resetCamera()}>Cancel</Button>&nbsp;
                <Button className="enc-button" onClick={() => webcamSnapshot()}>{isWebcamFrozen ? "Reset" : "Snapshot"}</Button>&nbsp;
                {webcamImage && isWebcamFrozen ?
                    <Button className="enc-button" onClick={() => uploadCamera()}>Upload</Button>
                    : null}
            </div>
        </div>;
    }

    const renderUpload = (m, i) => {
        return (
            <div className="attachment-container">
                <div className="attachment-camera clickable" onClick={() => setWebcam(!isWebcam)}>
                    <FontAwesomeIcon icon={faCamera} className="" />
                </div>
                {isWebcam ?
                    <div className="attachment-camera-preview">
                        {isWebcamFrozen ?
                            <img src={webcamImage} />
                            :
                            <Webcam
                                audio={false}
                                height={videoConstraints.renderheight}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                width={videoConstraints.renderwidth}
                                videoConstraints={videoConstraints}
                            />
                        }
                        {renderCameraButtons()}
                    </div>
                    : null}
                <FilePond
                    //files={[]}
                    acceptedFileTypes={['image/*']}
                    className="mt-3"
                    allowMultiple={false}
                    server={FilePondSettings(m.equipmentFormId, i)}
                    credits={false}
                    ref={filepondRef}
                />
            </div>
        )
    }

    const resetCamera = () => {
        setWebcam(false);
        setWebcamFrozen(false);
        setWebCamImage('')
        setWebCamImageName('')
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h3>New Field Ticket</h3>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <small>Date:</small>
                    <br />
                    <ReactDatePicker
                        className="enc-input"
                        selected={fieldTicket.date}
                        onChange={(date) => editFieldTicket('date', date)}
                    />
                </div>
            </div>
            <div className="divider my-2" />
            <ListGroup>
                <ListGroup.Item className="enc-data-list">
                    <div className="row">
                        <div className="col">
                            Roustabouts
                        </div>
                        <div className="col text-right">
                            <Button className="enc-button" onClick={addRoustabout}>Create Roustabout</Button>
                        </div>
                    </div>
                </ListGroup.Item>
                <ListGroup.Item className="enc-data-list">
                    <div className="row">
                        <div className="col-7">
                            Name
                        </div>
                        <div className="col-4">
                            Hours
                        </div>
                        <div className="col-1"></div>
                    </div>
                </ListGroup.Item>
                {fieldTicket.fieldTicketRoustabouts.length === 0 &&
                    <ListGroup.Item>No Roustabout Hours Recorded</ListGroup.Item>
                }
                {fieldTicket.fieldTicketRoustabouts.map((_, i) => {
                    return (
                        <ListGroup.Item key={`roustabout-${i}`}>
                            <div className="row" >
                                <div className="col-7">
                                    <input onChange={(e) => editRoustabout(i, 'name', e.target.value)} className="enc-input-100" value={fieldTicket.fieldTicketRoustabouts[i].name} />
                                </div>
                                <div className="col-4">
                                    <input
                                        type='text'
                                        onChange={(e) => validateHours(i, 'hours', e.target.value)}
                                        className="enc-input-100"
                                        value={fieldTicket.fieldTicketRoustabouts[i].hours}
                                    />
                                </div>
                                <div className="col-1">
                                    <FontAwesomeIcon className="clickable" icon={faTrash} onClick={() => deleteRoustabout(i)} />
                                </div>
                            </div>
                        </ListGroup.Item>

                    )
                })}
            </ListGroup>

            <div className="divider my-2" />

            <ListGroup>
                <ListGroup.Item className="enc-list">
                    <div className="row">
                        <div className="col">
                            Equipment Forms
                        </div>
                        <div className="col text-right">
                            <Button className="enc-button" onClick={addEquipment} disabled={!allowAdd}>New Equipment Form</Button>
                        </div>
                    </div>
                </ListGroup.Item>
                <ListGroup.Item>
                    <div className="row">
                        <div className="col">
                            <h4>Form Recovery</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <DropdownList
                                value={selectedRecoverEquipment}
                                data={recoverEquipment}
                                textField={(item) => `${item.equipmentFormName} - ${item.unitNumber}`}
                                valueField="equipmentFormId"
                                onChange={e => setSelectedRecoverEquipment(e.equipmentFormId)}
                            />
                        </div>
                        <div className="col text-right">
                            <Button className="enc-button" disabled={recoverEquipment.length === 1 || !allowAdd} onClick={recoverForm}>Recover Form</Button>
                        </div>
                    </div>
                </ListGroup.Item>
                {fieldTicket.equipmentForms.length === 0 &&
                    <ListGroup.Item>No Equipment Forms Created</ListGroup.Item>
                }
                <Accordion activeKey={open}>
                    {fieldTicket.equipmentForms.map((m, a_index) => {
                        return (
                            <ListGroup.Item key={`EquipmentForm-${a_index}`}>
                                <Card>
                                    <Accordion.Toggle
                                        as={Card.Header}
                                        eventKey={`${a_index}`}
                                        onClick={() => clickEquipment(`${a_index}`)}>
                                        <div className="row">
                                            <div className="col-1">
                                                {m.complete ?
                                                    <FontAwesomeIcon icon={faCheck} style={{ color: "green" }} />
                                                    :
                                                    <FontAwesomeIcon icon={faTimes} style={{ color: "red" }} />
                                                }
                                            </div>
                                            <div className="col">
                                                {`${m.equipmentFormName} - ${m.unitNumber}`}
                                            </div>
                                        </div>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={`${a_index}`}>
                                        <Card.Body>
                                            {m.complete ?
                                                <ViewEquipmentForm
                                                    iterator={a_index}
                                                    stepId={props.stepId}
                                                    formId={m.equipmentFormId}
                                                    {...props}
                                                /> :
                                                <EquipmentChecklist
                                                    iterator={a_index}
                                                    stepId={props.stepId}
                                                    addEquipment={addEquipmentId}
                                                    jobId={props.jobId}
                                                    EquipmentFormId={m.equipmentFormId} />
                                            }
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </ListGroup.Item>
                        );
                    })}
                </Accordion>
            </ListGroup>


            <div className="divider my-2" />

            <ListGroup>
                <ListGroup.Item className="enc-list">
                    <div className="row">
                        <div className="col">
                            Equipment Hour/Fuel Photos
                        </div>
                    </div>
                </ListGroup.Item>
                {fieldTicket.equipmentForms.filter(form => form.equipmentFormId !== 0).length === 0 &&
                    <ListGroup.Item>No Equipment Forms Eligible for Photos</ListGroup.Item>
                }
                <Accordion activeKey={hourFuel}>
                    {fieldTicket.equipmentForms.filter(form => form.equipmentFormId !== 0).map((m, i) => {
                        return (
                            <ListGroup.Item key={`efhf-${i}`}>
                                <Card>
                                    <Accordion.Toggle
                                        as={Card.Header}
                                        eventKey={`${i}`}
                                        onClick={() => clickHourFuel(`${i}`)}>
                                        <div className="row">
                                            <div className="col-1">
                                                {m.hourFuelPhoto ?
                                                    <FontAwesomeIcon icon={faCheck} style={{ color: "green" }} />
                                                    :
                                                    <FontAwesomeIcon icon={faTimes} style={{ color: "red" }} />
                                                }
                                            </div>
                                            <div className="col">
                                                {`${m.equipmentFormName} - ${m.unitNumber}`}
                                            </div>
                                        </div>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={`${i}`}>
                                        <Card.Body>
                                            {m.hourFuelPhoto ?
                                                <div className="row">
                                                    <div className="col">
                                                        {m.hourFuelPhoto.fileName}
                                                    </div>
                                                    <div className="col">
                                                        <div className="col text-right">
                                                            <FontAwesomeIcon icon={faDownload} className="clickable ml-2" onClick={() => downloadHourFuel(m.hourFuelPhoto.equipmentFormPictureId)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                renderUpload(m, i)
                                            }
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </ListGroup.Item>
                        );
                    })}
                </Accordion>
            </ListGroup>

            <div className="divider my-2" />

            <ListGroup>
                <ListGroup.Item className="enc-comment-list">
                    <div className="row">
                        <div className="col">
                            Daily Ticket Comments
                        </div>
                        <div className="col text-right">
                            <Button className="enc-button" onClick={addComment}>New Comment</Button>
                        </div>
                    </div>
                </ListGroup.Item>
                {fieldTicket.fieldTicketComments.filter(comment => !comment.isDeleted).map((m, i) => {
                    return (
                        <ListGroup.Item key={`ftc-${i}`}>
                            <div className="row">
                                <div className="col text-right">
                                    <FontAwesomeIcon className="clickable" icon={faTrash} onClick={() => editComment(i, 'isDeleted', true)} />

                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-sm-12 col-lg-5">
                                    <small>Comment Type</small>
                                    <DropdownList
                                        data={commentTypes}
                                        textField="fieldTicketCommentName"
                                        valueField="fieldTicketCommentId"
                                        placeholder="Select Comment Type"
                                        value={m.commentType}
                                        onChange={(e) => editCommentType(i, e)}
                                        required
                                    />
                                    <small>Comment</small>
                                    <textarea
                                        className="ft-textarea mt-2"
                                        placeholder="Comment Text..."
                                        value={fieldTicket.fieldTicketComments[i].comment}
                                        onChange={(e) => editComment(i, 'comment', e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="col-sm-12 col-lg-5">
                                    {m.signatureRequired &&
                                        <>
                                            <small>Signee Name</small>
                                            <input
                                                className="enc-input-100"
                                                placeholder="John Doe"
                                                value={m.signee}
                                                onChange={(e) => editComment(i, 'signee', e.target.value)}
                                                required />
                                            <small>Signature</small>
                                            <div className='signature-wrapper  mt-2'>
                                                {(m.signature === "" || m.signature === null) ?
                                                    <SignatureCanvas
                                                        ref={commentRefs[i]}
                                                        onFocus={() => commentRefs[i].on()}
                                                        onBlur={() => commentRefs[i].off()}
                                                        penColor='black'
                                                        clearOnResize={false}
                                                        canvasProps={{ className: 'sigCanvas signature-style' }}
                                                    />
                                                    :
                                                    <img src={m.signature} alt='test' />
                                                }
                                            </div>

                                        </>
                                    }
                                </div>
                                <div className="col-lg-2">
                                    <div className="row">
                                        <div className="col text-right">
                                            {m.signatureRequired && m.signature === "" &&
                                                <Button className="enc-button mt-1" onClick={() => commentRefs[i].current.clear()}>Clear Signature</Button>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ListGroup.Item>)
                })}
            </ListGroup>

            <div className="divider my-2" />

            <div className="row">
                <div className="col-md-12 col-lg-6">
                    <h4>Client Signature</h4>
                    <br />
                    <small>Client Printed Name</small>
                    <br />
                    <input type="text" placeholder="Print Client Name" name="clientSignaturePrint" onChange={(e) => { handleSignatureChange(e) }} value={fieldTicket.clientSignaturePrint} className="enc-input-100" />
                    <br />
                    <small>Signature</small>
                    <div className='signature-wrapper  mt-2'>
                        <SignatureCanvas
                            ref={(ref) => { fieldTicket.clientSignatureData = ref }}
                            onFocus={() => fieldTicket.clientSignatureData.on()}
                            onBlur={() => fieldTicket.clientSignatureData.off()}
                            penColor='black'
                            clearOnResize={false}
                            canvasProps={{ className: 'sigCanvas signature-style' }}
                        />
                    </div>

                </div>
                <div className="col-md-12 col-lg-6">
                    <h4>Supervisor Signature</h4>
                    <br />
                    <small>
                        Supervisor Name:
                    </small>
                    <input type="text" placeholder="Print Supervisor Name" name="supervisorSignaturePrint" onChange={(e) => { handleSignatureChange(e) }} value={fieldTicket.supervisorSignaturePrint} className="enc-input-100" />
                    <small>Signature</small>
                    <div className='signature-wrapper  mt-2'>
                        <SignatureCanvas
                            ref={(ref) => { fieldTicket.supervisorSignatureData = ref }}
                            onFocus={() => fieldTicket.supervisorSignatureData.on()}
                            onBlur={() => fieldTicket.supervisorSignatureData.off()}
                            penColor='black'
                            clearOnResize={false}
                            canvasProps={{ className: 'sigCanvas signature-style' }}
                        />
                    </div>
                </div>
            </div>
            <div className="row mt-2 mb-5">
                <div className="col">
                    {errorMessage}
                </div>
                <div className="col text-right">
                    {props.stepId ?
                        <>
                            <Button onClick={saveFieldTicket} className="enc-button">Save Field Ticket</Button>
                            <Button onClick={submitFieldTicket} className="enc-button ml-2">Submit Field Ticket</Button>
                        </> :
                        <Button onClick={submitFieldTicket} className="enc-button">Submit Field Ticket</Button>
                    }
                </div>
            </div>
        </div>
    )
}
