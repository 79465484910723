const APIDownload = (params) => {
    let download = require('downloadjs')

    let token;
            
    const LS = localStorage.getItem("BAENCToken");
    const SS = sessionStorage.getItem("BAENCToken");

    if(LS){
        token = LS;
    } else if (SS) {
        token = SS;
    }

    fetch(params.url, {
        method: params.method,
        headers: {...params.headers,
            Authorization: `Bearer ${token}`
        }
    })
    .then(res=> res.blob())
    .then(blob => download(blob, params.filename))
}

export default APIDownload
