import React, { useState } from 'react';
import { DropdownList } from 'react-widgets';
import TransferList from './TransferList';

export default props => {
    const [selected, setSelected] = useState({ Name: "Yard Pick Tickets", Value: "Pick" })

    const PickOptions = [{ Name: "Yard Pick Tickets", Value: "Pick" }, { Name: "Job Pick Tickets", Value: "JobPick" }]
    return (
        <div className="container">
            <div className="row mb-2">
                <div className="col">
                    Location Selection:
                </div>
                <div className="col">
                    <DropdownList
                        data={PickOptions}
                        textField="Name"
                        onChange={setSelected}
                        value={selected}
                    />
                </div>
            </div>

            <div>
                {selected && <TransferList type={selected.Value} {...props} />}
            </div>
        </div>

    )
}
