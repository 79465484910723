
export const fpPayload = (JobId, JobStep, reload) => {
    let token;

    const LS = localStorage.getItem("BAENCToken");
    const SS = sessionStorage.getItem("BAENCToken");

    if (LS) {
        token = LS;
    } else if (SS) {
        token = SS;
    }

    return (
        {
            url: "/api/JobAttachment",
            process: {
                url: "/SaveJobAttachment",
                method: "post",
                headers: {
                    Authorization: `Bearer ${token}`
                },
                ondata: (formData) => {
                    formData.append("JobId", JobId);
                    formData.append("JobStep", JobStep);
                    return formData;
                },
                onload: () => {reload()}
            },
            load: null,
            revert: null,
            restore: null,
            fetch: null,
            patch: null
        })
}

export const fpPayloadSurveyMeasurement = (SurveyMeasurementId, reload) => {
    let token;

    const LS = localStorage.getItem("BAENCToken");
    const SS = sessionStorage.getItem("BAENCToken");

    if (LS) {
        token = LS;
    } else if (SS) {
        token = SS;
    }

    return (
        {
            url: "/api/SurveyAttachment",
            process: {
                url: "/SaveSurveyAttachment",
                method: "post",
                headers: {
                    Authorization: `Bearer ${token}`
                },
                ondata: (formData) => {
                    formData.append("SurveyMeasurementId", SurveyMeasurementId);
                    return formData;
                },
                onload: () => {reload()}
            },
            load: null,
            revert: null,
            restore: null,
            fetch: null,
            patch: null
        })
}

export const fpPayloadSurveyMap = (SurveyFormId, reload) => {
    let token;

    const LS = localStorage.getItem("BAENCToken");
    const SS = sessionStorage.getItem("BAENCToken");

    if (LS) {
        token = LS;
    } else if (SS) {
        token = SS;
    }

    return (
        {
            url: "/api/SurveyFormMap",
            process: {
                url: "/SaveSurveyFormMap",
                method: "post",
                headers: {
                    Authorization: `Bearer ${token}`
                },
                ondata: (formData) => {
                    formData.append("SurveyFormId", SurveyFormId);
                    return formData;
                },
                onload: () => {reload()}
            },
            load: null,
            revert: null,
            restore: null,
            fetch: null,
            patch: null
        })
}