import React, { useEffect, useState } from 'react';
import { Button, ListGroup } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { GetAccountJobs } from '../API/Account';
import { CheckEditOpportunity } from '../API/Opportunity';

export default props => {
    const [jobs, setJobs] = useState([]);
    const [filter, setFilter] = useState("");
    const [editOpportunity, setEditOpportunity] = useState(false);

    useEffect(() => {
        if (props.accountId) {
            GetAccountJobs(props.accountId)
                .then(res => {
                    setJobs(res.data)
                })
        }
        CheckEditOpportunity()
        .then(res => {
            setEditOpportunity(res.success)
        })
    }, [props.accountId])

    let history = useHistory();

    const navigateToJob = (oppId, id, stageName) => {
        let stage = stageName.replace(/\s/g, '')
        history.push(`/Opportunities/${oppId}/${id}/${stage}`)
    }

    return (
        <>
            <div className="container">
                <div>
                    <div className="row mt-2">
                        <div className="col">
                            {editOpportunity && <Button as={Link} to='/Opportunities/NewOpportunity' className="enc-button ml-2">New Opportunity</Button>}
                        </div>
                        <div className="col-6 text-right">
                            <input className="enc-input-100" placeholder="Job & Opportunity Name Filter" value={filter} onChange={e => setFilter(e.target.value)} />
                        </div>
                    </div>
                    <ListGroup className="my-3">
                        <ListGroup.Item className="enc-data-list">Job List</ListGroup.Item>
                        <ListGroup.Item className="enc-data-list">
                            <div className="row">
                                <div className="col-lg-2">
                                    Opportunity Name
                                </div>
                                <div className="col-lg-2">
                                    Job Name
                                </div>
                                <div className="col-lg-2">
                                    Classification
                                </div>
                                <div className="col-lg-2">
                                    Job Number
                                </div>
                                <div className="col-lg-2">
                                    Stage
                                </div>
                                <div className="col-lg-2">
                                    Last Updated
                                </div>
                            </div>
                        </ListGroup.Item>
                        {jobs.length === 0 && <ListGroup.Item>No Jobs To Display</ListGroup.Item>}
                        {jobs.filter(x => x.jobName.toLowerCase().search(filter.toLowerCase()) !== -1 || x.opportunityName?.toLowerCase().search(filter.toLowerCase()) !== -1).map(m => {
                            const getTime = () => {
                                if (m.lastUpdated !== null) {
                                    var utcDate = m.lastUpdated;
                                    var localDate = new Date(utcDate + 'Z');
                                    return (
                                        <>
                                            {localDate.toLocaleDateString()}
                                            &nbsp;
                                            {localDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                        </>
                                    )
                                }
                                else {
                                    return (
                                        <>
                                            N/A
                                        </>
                                    )
                                }
                            };
                            return (
                                <ListGroup.Item key={`LGI-${m.jobId}`} onClick={() => navigateToJob(m.opportunityId, m.jobId, m.jobStage)} className="click-list">
                                    <div className="row">
                                        <div className="col-lg-2">
                                            {m.opportunityName}
                                        </div>
                                        <div className="col-lg-2">
                                            {m.canceled && "Cancelled -"} {m.jobName}
                                        </div>
                                        <div className="col-lg-2">
                                            {m.jobClassification}
                                        </div>
                                        <div className="col-lg-2">
                                            {m.jobNumber}
                                        </div>
                                        <div className="col-lg-2">
                                            {m.jobStage}
                                        </div>
                                        <div className="col-lg-2">
                                            {getTime()}
                                        </div>
                                    </div>
                                </ListGroup.Item>
                            )
                        })}
                    </ListGroup>
                </div>
            </div>
        </>
    )
}
