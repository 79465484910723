import React, { useState, useEffect } from 'react';
import { GetBillOfLadingList } from '../../API/InventoryTransfer';
import { ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default props => {
    const [historical, setHistorical] = useState(false)
    const [billOfLadings, setBillOfLadings] = useState([]);



    useEffect(() => {
        GetBillOfLadingList(historical)
        .then(res=>{
            setBillOfLadings(res.data)
        })
    }, [historical])

    return (
        <div className="container">
            <div className="row mb-1">
                <div className="col text-right">
                    <div className="toggle-switch small-switch">
                        <input
                            type="checkbox"
                            className="toggle-switch-checkbox"
                            id="disabled"
                            checked={historical}
                            onChange={e => setHistorical(!historical)}
                        />
                        <label className="toggle-switch-label" htmlFor="disabled">
                            <span className={"toggle-switch-inner"} />
                            <span
                                className={"toggle-switch-switch"}
                            />
                        </label>
                    </div>
                    &nbsp; &nbsp;View Completed Shipments
                </div>
            </div>
            <ListGroup>
                <ListGroup.Item className="enc-data-list">
                    Bills of Lading List
                </ListGroup.Item>
                <ListGroup.Item className="enc-data-list">
                    <div className="row">
                        <div className="col-2">
                            Date
                        </div>
                        <div className="col">
                            Origin
                        </div>
                        <div className="col">
                            Destination
                        </div>
                        <div className="col">
                            Identifier
                        </div>
                    </div>
                </ListGroup.Item>
                {billOfLadings.map((m, i) => {
                    return (
                        <ListGroup.Item key={`BOL-${i}`} as={Link} to={`${props.match.url}/${m.transferRequestId}`} className="click-list">
                            <div className="row">
                                <div className="col-2">
                                    {m.date}
                                </div>
                                <div className="col">
                                    {m.origin}
                                </div>
                                <div className="col">
                                    {m.destination}
                                </div>
                                <div className="col">
                                    {m.identifier}
                                </div>
                            </div>
                        </ListGroup.Item>
                    )
                })}
            </ListGroup>
        </div>

    )
}
