import React, { useState, useEffect } from 'react';
import { ListGroup } from 'react-bootstrap';
import { GetJobScopeList } from '../../../API/JobScope';

export default props => {
    const [scope, setScope] = useState([])

    useEffect(()=>{
        LoadScope(props.handOffId)
    },[props.handOffId])

    const LoadScope = (handOffId) => {
        GetJobScopeList(handOffId)
        .then(res=>{
            setScope(res.data)
        })
    }

    return (
        <div>
            <ListGroup>
                <ListGroup.Item className="enc-data-list">Job Scope</ListGroup.Item>
                <ListGroup.Item className="enc-data-list">
                    <div className="row">
                        <div className="col">
                            Name
                                </div>
                        <div className="col">
                            Description
                                </div>
                        <div className="col-2">
                            Quantity
                                </div>
                    </div>
                </ListGroup.Item>
                {scope.map((m,i) => {
                    return (
                        <ListGroup.Item className="enc-data-list" key={`Scope-${i}`}>
                            <div className="row">
                                <div className="col">
                                    {m.optional && "Optional"} {m.name}
                                </div>
                                <div className="col">
                                    {m.description}
                                </div>
                                <div className="col-2">
                                    {m.quantity}
                                </div>
                            </div>
                        </ListGroup.Item>
                    )
                })}
            </ListGroup>
        </div>
    )
}


