import React, { useState, useEffect } from 'react';
import CalculatorForm from './CalculatorForm';
import { ListGroup } from 'react-bootstrap';
import { GetCalculatorList, DeleteCalculator } from '../API/Calculator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';

export default props => {
    const [calculators, setCalculators] = useState([])

    useEffect(()=>{
        LoadCalculatorList();
    },[])

    const LoadCalculatorList = () => {
        GetCalculatorList()
        .then(res=>{
            setCalculators(res.data)
        })
    }

    const removeCalculator = (id) => {
        if(window.confirm("Are you sure you would like to delete the calculator?"))
        {
            DeleteCalculator(id)
            .then(res=>{
                LoadCalculatorList()
            })
        }
    }
    let history = useHistory();
    const navigate = (id) =>{
        history.push(`${props.match.url}/${id}`)
    }

    return (
        <>
            <div className="container">
                <ListGroup>
                    <ListGroup.Item className="click-list">
                        Calculators
                    </ListGroup.Item>
                    {calculators.map((m, i) => {
                        return (
                            <ListGroup.Item key={`calc-${i}`} className="click-list">
                                <div className='row'>
                                    <div className="col" onClick={()=>navigate(m.calculatorId)}>
                                    {m.calculatorName}
                                    </div>
                                    <div className='col-1 text-right'>
                                        <FontAwesomeIcon icon={faTrash} onClick={() => removeCalculator(m.calculatorId)} />
                                    </div>
                                </div>
                            </ListGroup.Item>
                        )
                    })}
                </ListGroup>
            </div>
            <CalculatorForm rl={LoadCalculatorList} />
        </>
    )
}
