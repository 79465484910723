import React, { useEffect, useRef } from 'react';


//<img className="img-fluid header-image" src="../images/logo.png" alt="Environmental Noise Control" />

export default (props) => {
    const { draw, mouseClicked, ...rest } = props;
    const canvasRef = useRef(null);
    
    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        if (draw) draw(context);
    }, [draw]);
    
    useEffect(() => {
        const onMouseClicked = (mouse) => {
            const canvas = canvasRef.current;
            const rect = canvas.getBoundingClientRect();
            const mouseX = mouse.x - rect.left;
            const mouseY = mouse.y - rect.top;
            //console.log(`Mouse clicked at ${mouseX}, ${mouseY}`);
            mouseClicked(mouseX, mouseY);
        }

        const canvas = canvasRef.current;
        canvas.addEventListener("mousedown", onMouseClicked, false);
        return () => {
            canvas.removeEventListener("mousedown", onMouseClicked, false);
        }
    }, [mouseClicked]);

    return (
        <canvas ref={canvasRef} {...rest}/>
    );
}