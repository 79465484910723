import React from 'react';
import { Route, NavLink, Switch } from 'react-router-dom';

import { Nav } from 'react-bootstrap';

import ProposalNamesList from './ProposalNamesList';
import NoteList from './NoteList';
import ProposalAttachments from './ProposalAttachments';
import PrefillConfiguration from './PrefillConfiguration';
import Note from './Note';
import Attachment from './Attachment';



export default props => {
    return (
        <>
            <Nav variant="tabs" className="my-2">
                <Nav.Item>
                    <Nav.Link eventKey={1} as={NavLink} to={`${props.match.url}/Configuration`}>Proposal Configuration</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey={2} as={NavLink} to={`${props.match.url}/Note`}>Notes</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey={3} as={NavLink} to={`${props.match.url}/Attachment`}>Attachments</Nav.Link>
                </Nav.Item>
            </Nav>
            <Switch>
                <Route path={`${props.match.url}/Configuration/:id`} component={PrefillConfiguration} />
                <Route path={`${props.match.url}/Configuration`} component={ProposalNamesList} />
                <Route path={`${props.match.url}/Note/:id`} component={Note} />
                <Route path={`${props.match.url}/Note`} component={NoteList} />
                <Route path={`${props.match.url}/Attachment/:id`} component={Attachment} />
                <Route path={`${props.match.url}/Attachment`} component={ProposalAttachments} />
            </Switch>
        </>
    )
}
