import React, { useEffect, useState } from 'react';
import { GetAccountProfile, CheckAccountEditPermission } from '../API/Account';
import AccountLocations from './AccountLocations';
import ContactDisplay from './ContactDisplay';
import AccountDetails from './AccountDetails';
import { Tabs, Tab, Button, Breadcrumb } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import AccountJobs from './AccountJobs';


export default props => {
    const [accountInfo, setAccountInfo] = useState({
        accountInfo: {
            accountId: 0,
            accountName: "",
            accountNumber: "",
            enabled: true,
            taxExempt: false,
            territoryName: "",
            industryName: ""
        }
    })
    const [contacts, setContacts] = useState([])
    const [locations, setLocations] = useState([])
    const [edit, setEdit] = useState(false)

    useEffect(() => {
        checkEdit()
        loadProfile(parseInt(props.accountId))
    }, [props.accountId])

    const history = useHistory()

    const checkEdit = () => {
        CheckAccountEditPermission()
            .then(res => {
                setEdit(res.success)
            })
    }

    const loadProfile = (id) => {
        GetAccountProfile(id)
            .then(res => {
                setAccountInfo(res.accountInfo)
                setContacts(res.accountContacts)
                setLocations(res.accountLocations)
            })
    }
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-11">
                        <Breadcrumb className="breadcrumb">
                            <li className="breadcrumb-item"><Link to={`${props.url}`}> Accounts List</Link></li>
                            <Breadcrumb.Item active> Account </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className="col-1 text-right">
                        {edit && <FontAwesomeIcon icon={faPencilAlt} className="clickable" onClick={() => history.push(`${props.url}/${accountInfo.accountId}/Edit`)} />}
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-sm-6">
                        <div className="row">
                            <div className="col text-center">
                                <h3>
                                    {accountInfo.accountName}
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 text-center">
                        <div className="row">
                            <div className="col">
                                <h3>
                                    Account #{accountInfo.accountNumber}
                                </h3>
                            </div>
                        </div>

                    </div>
                </div>
                <Tabs fill defaultActiveKey={props.start}>
                    <Tab eventKey="locations" title="Account Locations">
                        <div className="container-fluid">
                            <div className="row mt-2">
                                <div className="col">
                                    {edit &&
                                        <Button as={Link} to={`${props.url}/${accountInfo.accountId}/Location/New`} className="enc-button">New Location</Button>
                                    }
                                </div>
                            </div>
                        </div>
                        <AccountLocations click={true} account={accountInfo.accountId} url={props.url} location={locations} />
                    </Tab>
                    <Tab eventKey="contacts" title="Account Contacts">
                        <div className="container-fluid">
                            <div className="row mt-2">
                                <div className="col">
                                    {edit &&
                                        <Button as={Link} to={`${props.url}/${accountInfo.accountId}/Contact/New`} className="enc-button">New Contact</Button>
                                    }
                                </div>
                            </div>
                        </div>
                        <ContactDisplay click={true} accountId={accountInfo.accountId} url={props.url} contacts={contacts} />
                    </Tab>
                    <Tab eventKey="jobs" title="Account Jobs">
                        <AccountJobs accountId={accountInfo.accountId} />
                    </Tab>
                    {/*<Tab eventKey="details" title="Account Details">*/}
                    {/*    <AccountDetails accountInfo={accountInfo} />*/}
                    {/*</Tab>*/}
                </Tabs>
            </div>
        </>
    )
}
