import { faDownload, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { Button, ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { GetProposalPdf, ProposalListView } from '../../../API/Proposal';


export default ({showButton, reloadParent, ...props}) => {
    const [proposals, setProposals] = useState([]);
    const [allowDownload, setAllowDownload] = useState(false);

    useEffect(() => {
        LoadProposals(props.jobId)
    }, [props.jobId])

    const LoadProposals = (id) => {
        ProposalListView(id)
            .then(res => {
                setProposals(res.data.proposals)
                setAllowDownload(res.data.allowDownload)
            })
    }


    const showWindow = (id, name) => {
        GetProposalPdf(id)
        .then(res => {
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                let displayName = name ? `${name}.pdf` : `Proposal-${id}.pdf`
                window.navigator.msSaveOrOpenBlob(res, displayName);
            }
            else {
                var objectUrl = URL.createObjectURL(res);
                window.open(objectUrl);
            }
        })
    }

    const download = (id, name) => {
        let download = require('downloadjs')
        let displayName = name ? `${name}.pdf` : `Proposal-${id}.pdf`
        GetProposalPdf(id)
        .then(res => {
            download(res, displayName)
        })
    }

    return (
        <>
            <ListGroup id="proposal_list">
                <ListGroup.Item className="enc-list">
                    Proposals
                </ListGroup.Item>
                {proposals.length === 0 && <ListGroup.Item>No Generated Proposals Found</ListGroup.Item>}
                {proposals.map((m, i) => {
                    return (
                        <ListGroup.Item key={`proposalPdf-${i}`}>
                            <div className="row">
                                <div className="col">
                                    {m.proposalName?m.proposalName:`Proposal ${m.proposalId}`}
                                </div>
                                {allowDownload && <div className="col text-right">
                                    <FontAwesomeIcon icon={faEye} className="clickable" onClick={() => showWindow(m.proposalId)} />
                                    <FontAwesomeIcon icon={faDownload} className="clickable ml-2" onClick={() => download(m.proposalId)} />
                                </div>}
                            </div>

                        </ListGroup.Item>
                    )
                })}

            </ListGroup>
            <div className="row mt-1">
                <div className="col text-right">
                    {showButton && 
                    <Button as={Link} to={`${props.url}/Proposal`} className="enc-button">
                        {proposals.length === 0 ? "Build" : "Edit"} Proposal</Button>
                        }
                </div>
            </div>
        </>
    )
}
