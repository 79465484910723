import React, { useState, useEffect } from 'react';
import { GetJSA } from '../../API/JSA';
import { ListGroup, Button } from 'react-bootstrap';
import JSASignIn from "./JSASignIn"

import JSAPdfButton from "./formPdfUxComponents/PdfButton";
import EmailPDFModal from "./formPdfUxComponents/emailPDFModal";
import JSAEditLog from "./JSAEditLog";

export default ({setEdit, reloadParent, ...props}) => {
    const [form, setForm] = useState({
        jsaId: 0,
        date: "",
        supervisor: "",
        supervisorContact: "",
        cprCertifiedEmployee: "",
        nameOfNearestHospital: "",
        nearestHospitalAddress: "",
        emergencyAddress: "",
        createdBy: "",
        checkBoxCategories: [],
        emergency: [],
        risk: [], // remove?
        riskManagements: [],
        actions: [],
        risks: [],
    })

    useEffect(() => {
        LoadJSA(props.match.params.id)
    }, [props.match.params.id])

    const LoadJSA = (id) => {
        GetJSA(id)
            .then(res => {
                if (res.success) {
                    let s = { ...res.data }
                    s.date = new Date(Date.parse(s.date)).toLocaleString();
                    s.jsaId = id;
                    setForm(s)
                } else {
                    console.log("jsa res")
                    console.log(res)
                }
            })
            .catch(err => {
                console.log("get jsa error:")
                console.log(err);
            })
    }

    const loadProductRisks = (m, i) => {
        return (
            <ul>
                {m.productRisks.length === 0 && (<li>No Risks Added</li>)}
                {m.productRisks.map((r, index) => {
                    let newRisk;
                    if (m.riskOther === true && r.risk.toLowerCase() === "other") {
                        newRisk = r.risk + " - " + m.riskTextOther;
                    } else {
                        newRisk = r.risk;
                    }

                    return (
                        <li key={`risk-${index}`}>
                            {newRisk}
                        </li>
                    )
                })}
            </ul>
        )
    }

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col text-right">
                        <JSAPdfButton formId={props.match.params.id} formType="JSA"/>
                        <EmailPDFModal formId={props.match.params.id} formType="JSA"/>
                        {<Button className="enc-button ml-2" onClick={() => setEdit(true)}>Edit JSA</Button>}
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <h3>Job Safety Analysis Worksheet</h3>
                    </div>
                </div>
                <div className="divider my-2" />
                <div className="row">
                    <div className="col-md-6">
                        <strong>Date</strong>
                        <br />
                        {form.date}
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <strong>Supervisor Name</strong>
                        <br />
                        {form.supervisor}
                    </div>
                    <div className="col-md-6">
                        <strong>Supervisor Contact</strong>
                        <br />
                        {form.supervisorContact}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <strong>CPR Certified Employee On Site</strong>
                        <br />
                        {form.cprCertifiedEmployee}
                    </div>
                    <div className="col-md-6">
                        <strong>Name Of Nearest Hospital</strong>
                        <br />
                        {form.nameOfNearestHospital}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <strong>Emergency Address</strong>
                        <br />
                        {form.emergencyAddress}
                    </div>
                    <div className="col-md-6">
                        <strong>Nearest Hospital Address</strong>
                        <br />
                        {form.nearestHospitalAddress}
                    </div>
                </div>


                <div className="divider my-2" />

                <div className="row">
                    <div className="col">
                        <h3>Considerations</h3>
                    </div>
                </div>
                {form.checkBoxCategories.map((cbc, ci) => {
                    return (
                        <ListGroup className="mt-3" key={`category-${ci}`}>
                            <ListGroup.Item className="enc-list">
                                <h4>{cbc.category}</h4>
                            </ListGroup.Item>
                            {cbc.boxes.map((permitItem, Index) =>
                                <ListGroup.Item className="enc-list" key={`cb-${ci}-${Index}`}>
                                    <div className="row">
                                        <div className="col-2">
                                            <input type="checkbox"
                                                checked={permitItem.checked}
                                                id={`checkbox-${ci}-${Index}`}
                                                className="regular-checkbox big-checkbox"
                                                readOnly />
                                            <label htmlFor={`checkbox-${ci}-${Index}`}></label>
                                        </div>
                                        <div className="col-lg-5">
                                            <span>{permitItem.jsaItemName}</span>
                                        </div>
                                        <div className="col-lg-5">
                                            {permitItem.otherText}
                                        </div>

                                    </div>
                                </ListGroup.Item>
                            )}
                        </ListGroup>
                    )
                })}

                {/* SECTION 2.5.... Emergency Locations*/}

                <div className="divider my-2" />

                <ListGroup>
                    <ListGroup.Item className="enc-data-list">
                        Emergency Locations
              </ListGroup.Item>
                    <ListGroup.Item className="enc-data-list">
                        <div className="row">
                            <div className="col-1 text-center" > #</div>
                            <div className="col-3" >Emergency Type</div>
                            <div className="col">Location</div>
                        </div>
                    </ListGroup.Item>
                    {form.emergency.length === 0 &&
                        <ListGroup.Item className="enc-data-list">
                            No Emergency Locations Set
                        </ListGroup.Item>
                    }
                    {form.emergency.map((emerg, i) => {
                        return (
                            <ListGroup.Item key={`table-${i}`} className="enc-list">
                                <div className="row">
                                    <div className="col-1 text-center">{i + 1}</div>
                                    <div className="col-3" >
                                        <img className="card-img-top" style={{ width: "80px" }} src={emerg.iconUrl} alt="icon" />
                                    </div>
                                    <div className="col">
                                        {emerg.emergencyTypeLocation}
                                    </div>
                                </div>
                            </ListGroup.Item>
                        )
                    })}
                </ListGroup>

                <div className="divider my-2" />
                {/* SECTION 3 */}
                {/* RISK MITIGATION */}
                <div className="row">
                    <div className="col">
                        <h3>Risk Management</h3>
                    </div>
                </div>

                <ListGroup>
                    <ListGroup.Item className="enc-data-list">
                        <div className="row">
                            <div className="col">
                                Risks
                            </div>
                        </div>
                    </ListGroup.Item>

                    <ListGroup.Item className="enc-data-list">
                        <div className="row">
                            <div className="col-2">Product to be Installed</div>
                            <div className="col">Sequence of Actions</div>
                            <div className="col">Potential Risks</div>
                            <div className="col">Site Specifc Risk</div>
                            <div className="col">Site Specifc Risk Mitigation</div>
                        </div>
                    </ListGroup.Item>

                    {form.riskManagements.length === 0 &&
                        <ListGroup.Item>
                            No Products Added to JSA
                        </ListGroup.Item>
                    }
                    {form.riskManagements.map((m, i) => {
                        let newAction;
                        if (m.actionOther === true && m.action.toLowerCase() === "other") {
                            newAction = m.action + " - " + m.actionTextOther;
                        } else {
                            newAction = m.action;
                        }

                        return (
                            <ListGroup.Item key={`product-${i}`}>
                                <div className="row" >
                                    <div className="col-2">
                                        {m.productName}
                                    </div>
                                    <div className="col">
                                        {newAction}
                                    </div>
                                    <div className="col">
                                        {loadProductRisks(m, i)}
                                    </div>
                                    <div className="col display-linebreak">
                                        {m.siteRisk}
                                    </div>
                                    <div className="col display-linebreak">
                                        {m.riskMitigation}
                                    </div>
                                </div>
                            </ListGroup.Item>
                        )
                    })}
                </ListGroup>

                <JSASignIn JSAId={props.match.params.id}></JSASignIn>
                <JSAEditLog JSAId={props.match.params.id}></JSAEditLog>

            </div >
        </>
    )
}