import React, { useState, useEffect } from 'react';
import { GetBillOfLading } from '../../API/InventoryTransfer';
import { ListGroup, Button } from 'react-bootstrap';
import EmailPDFModal from "../../Jobs/InProgress/formPdfUxComponents/emailPDFModal"
export default props => {
    const [billOfLading, setBillOfLading] = useState({
        "transferRequestId": 0,
        "transferItems": [],
        "assetTransfers": [],
        "date": "",
        "carrierName": "",
        "carrierAddress": "",
        "carrierCity": "",
        "carrierState": "",
        "carrierZip": "",
        "driverPhone": "",
        "driverName": "",
        "origin": "",
        "originAddress": "",
        "originAddress2": "",
        "originAddress3": "",
        "originContact": "",
        "destination": "",
        "destinationAddress": "",
        "destinationAddress2": "",
        "destinationAddress3": "",
        "destinationContact": "",
        "codName": "",
        "codAddress": "",
        "codCity": "",
        "codState": "",
        "codZip": "",
        "codAmount": 0,
        "codFeePrepaid": 0,
        "codFeeCollect": 0,
        "codTotalCharges": 0,
        "totalShipmentValue": 0,
        "shipmentId": ""
    })

    useEffect(() => {
        LoadBillOfLading(props.match.params.id)
    }, [props.match.params.id])

    const LoadBillOfLading = (id) => {
        GetBillOfLading(id)
            .then(res => {
                console.log(res.data)
                setBillOfLading(res.data)
            })
    }

    const PrintBillOfLading = () => {
        window.print();
    }

    return (
        <div className="mt-2">
            <div className="row no-print">
                <div className="col text-right">
                    <EmailPDFModal className="enc-button" formType="BOL" formId={props.match.params.id} />
                    <Button className="enc-button mx-2" onClick={PrintBillOfLading}>Print</Button>
                    <Button className="enc-button no-print" onClick={props.toggle}>Edit</Button>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col text-center">
                    <img className="img-fluid" src="../images/lading.png" alt="Environmental Noise Control" />
                </div>
            </div>
            <div className="row mb-2">
                <div className="col">
                    Shipment Id:
                </div>
                <div className="col">
                    {billOfLading.shipmentId}
                </div>
                <div className="col">
                    Identifier:
                </div>
                <div className="col">
                    {billOfLading.billOfLadingIdentifier}
                </div>
            </div>

            <div className="row">
                <div className="col-8 p-2 ">
                    <div className="row">
                        <div className="col">
                            <h4>Carrier Information</h4>
                        </div>
                    </div>
                    <div className="divider my-2" />
                    <div className="row">
                        <div className="col-3">
                            Name:
                </div>
                        <div className="col">
                            {billOfLading.carrierName}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            Address:
                </div>
                        <div className="col">
                            {billOfLading.carrierAddress}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            City
                </div>
                        <div className="col">
                            {billOfLading.carrierCity}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            State
                </div>
                        <div className="col">
                            {billOfLading.carrierState}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            Zip
                </div>
                        <div className="col">
                            {billOfLading.carrierZip}
                        </div>
                    </div>
                </div>
                <div className="col-4 p-2 ">
                    <div className="row">
                        <div className="col">
                            <h4>Driver Information</h4>
                        </div>
                    </div>
                    <div className="divider my-2" />
                    <div className="row">
                        <div className="col-4">
                            Driver Name
                </div>
                        <div className="col">
                            {billOfLading.driverName}

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            Driver Phone
                        </div>
                        <div className="col">
                            {billOfLading.driverPhone}
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-4">
                            Date
                        </div>
                        <div className="col">
                            {billOfLading.date}
                        </div>
                    </div>
                </div>
            </div>



            <div className="row my-2">
                <div className="col-6 p-2 ">
                    <div className="row">
                        <div className="col">
                            <h4>Shipper Information</h4>
                        </div>
                    </div>
                    <div className="divider my-2" />
                    <div className="row">
                        <div className="col">
                            (From) Shipper:
                </div>
                        <div className="col">
                            {billOfLading.origin}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            Address:
                </div>
                        <div className="col">
                            {billOfLading.originAddress}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                        </div>
                        <div className="col">
                            {billOfLading.originAddress2}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                        </div>
                        <div className="col">
                            {billOfLading.originAddress3}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            Contact:
                </div>
                        <div className="col">
                            {billOfLading.originContact}
                        </div>
                    </div>
                </div>
                <div className="col-6 p-2 ">
                    <div className="row">
                        <div className="col">
                            <h4>Consignee Information</h4>
                        </div>
                    </div>
                    <div className="divider my-2" />
                    <div className="row">
                        <div className="col">
                            CONSIGNEE:
                </div>
                        <div className="col">
                            {billOfLading.destination}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            Address:
                </div>
                        <div className="col">
                            {billOfLading.destinationAddress}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                        </div>
                        <div className="col">
                            {billOfLading.destinationAddress2}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                        </div>
                        <div className="col">
                            {billOfLading.destinationAddress3}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            Contact:
                </div>
                        <div className="col">
                            {billOfLading.destinationContact}
                        </div>
                    </div>
                </div>
            </div>



            <div className="row my-2">
                <div className="col p-2 ">
                    <div className="row">
                        <div className="col">
                            <h4>Contents</h4>
                        </div>
                    </div>
                    {billOfLading.transferItems.length > 0 &&
                        <ListGroup>
                            <ListGroup.Item>
                                <div className="row">
                                    <div className="col-6">
                                        Item Name
                            </div>
                                    <div className="col-2">
                                        Amount
                            </div>
                                    <div className="col-4">
                                        Weight
                            </div>
                                </div>
                                {billOfLading.transferItems.filter(x => x.damagedAmount > 0).length > 0 &&
                                        <>
                                            <div className="divider my-2" />
                                            <div className="row">
                                                <div className="col-6">
                                                    Damaged Item Name
                                                </div>
                                                <div className="col-2">
                                                    Damaged Amount
                                                </div>
                                                <div className="col">
                                                    Combined Total Items
                                                </div>
                                            </div>
                                        </>
                                    }
                                <div className="divider my-2" />
                                <div className="row">
                                    <div className="col-3">
                                        Hazarous Code*
                            </div>
                                    <div className="col-3">
                                        Code
                            </div>
                                    <div className="col-3">
                                        Rate
                            </div>
                                    <div className="col-3">
                                        Charges
                            </div>
                                </div>
                            </ListGroup.Item>
                            {billOfLading.transferItems.map((m, i) =>
                                <ListGroup.Item key={`transferItem-${i}`}>
                                    <div className="row">
                                        <div className="col-6">
                                            {m.itemName}
                                        </div>
                                        <div className="col-2">
                                            {m.totalAmount}
                                        </div>
                                        <div className="col-4">
                                            {m.weight} lbs
                                        </div>
                                    </div>
                                    {m.damagedAmount > 0 &&
                                        <>
                                            <div className="divider my-2" />
                                            <div className="row">
                                                <div className="col-6">
                                                    Damaged - {m.itemName}
                                                </div>
                                                <div className="col-2">
                                                    {m.damagedAmount}
                                                </div>
                                                <div className="col">
                                                    Total: {m.damagedAmount + m.totalAmount}
                                                </div>
                                            </div>
                                        </>
                                    }
                                    <div className="divider my-2" />

                                    <div className="row">
                                        <div className="col-3">
                                            {m.hazardous}
                                        </div>
                                        <div className="col-3">
                                            {m.code}

                                        </div>
                                        <div className="col-3">
                                            {m.rate}
                                        </div>
                                        <div className="col-3">
                                            {m.charges}
                                        </div>
                                    </div>
                                </ListGroup.Item>


                            )}
                        </ListGroup>
                    }

                    {billOfLading.assetTransfers.length > 0 &&
                        <ListGroup className="mt-2">
                            <ListGroup.Item>
                                <div className="row">
                                    <div className="col-6">
                                        Item Name
                            </div>
                                    <div className="col-2">
                                        Serial Number
                            </div>
                                    <div className="col-4">
                                        Weight
                            </div>
                                </div>
                                <div className="divider my-2" />

                                <div className="row">
                                    <div className="col-3">
                                        Hazarous Code*
                            </div>
                                    <div className="col-3">
                                        Code
                            </div>
                                    <div className="col-3">
                                        Rate
                            </div>
                                    <div className="col-3">
                                        Charges
                            </div>
                                </div>
                            </ListGroup.Item>
                            {billOfLading.assetTransfers.map((m, i) =>
                                <ListGroup.Item key={`Asset-${i}`}>
                                    <div className="row">
                                        <div className="col-6">
                                            {m.itemName}
                                        </div>
                                        <div className="col-2">
                                            {m.serialNumber}
                                        </div>
                                        <div className="col-4">
                                            {m.weight} lbs.
                                        </div>
                                    </div>
                                    <div className="divider my-2" />
                                    <div className="row">
                                        <div className="col-3">
                                            {m.hazardous}
                                        </div>
                                        <div className="col-3">
                                            {m.code}
                                        </div>
                                        <div className="col-3">
                                            {m.rate}
                                        </div>
                                        <div className="col-3">
                                            {m.charges}
                                        </div>
                                    </div>
                                </ListGroup.Item>
                            )}
                        </ListGroup>
                    }
                </div>
            </div>

            <div className="row">
                <div className="col p-2 ">
                    <div className="row">
                        <div className="col">
                            <h4>C.O.D. Information</h4>
                        </div>
                    </div>
                    <div className="divider my-2" />
                    <div className="row">
                        <div className="col-6">
                            <div className="row">
                                <div className="col-4">
                                    Remit C.O.D. To:
                    </div>
                                <div className="col">
                                    {billOfLading.codName}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4">
                                    Address:
                    </div>
                                <div className="col">
                                    {billOfLading.codAddress}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4">
                                    City:
                    </div>
                                <div className="col">
                                    {billOfLading.codCity}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4">
                                    State:
                    </div>
                                <div className="col">
                                    {billOfLading.codState}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4">
                                    Zip Code:
                    </div>
                                <div className="col">
                                    {billOfLading.codZip}
                                </div>
                            </div>
                        </div>
                        <div className="col-3 border-left">
                            <div className="row">
                                <div className="col">
                                    COD Amount
                                </div>
                                <div className="col">
                                    ${billOfLading.codAmount}
                                </div>
                            </div>
                            <div className="divider my-2" />
                            <div className="row">
                                <div className="col">
                                    <p>
                                        <small>
                                            Subject to Section 7 of conditions, if this shipment is o be delivered to the consignee without
                                            recourse on the consignor, the consignor shall sign the following statement:
                                            The carrier shall not make delivery of this shipment without payment of freight and all other lawful charges
                                        </small>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-3 border-left">
                            <div className="row">
                                <div className="col text-center">
                                    <strong>C.O.D. Fee</strong>
                                </div>
                            </div>
                            <div className="divider my-2" />
                            <div className="row">
                                <div className="col-3">
                                    Prepaid:
                                </div>
                                <div className="col">
                                    ${billOfLading.codFeePrepaid}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3">
                                    Collect:
                                </div>
                                <div className="col">
                                    ${billOfLading.codFeeCollect}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3">
                                    Total Charges:
                                </div>
                                <div className="col">
                                    ${billOfLading.codTotalCharges}
                                </div>
                            </div>
                            <div className="divider my-2" />
                            <div className="row">
                                <div className="col text-center">
                                    <small>
                                        FREIGHT CHARGES ARE PREPAID UNLESS MARKED COLLECT
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt-2">
                <div className="col p-2 ">
                    <div className="row">
                        <div className="col-3">
                            Total Shipment Value:
                        </div>
                        <div className="col-3">
                            ${billOfLading.totalShipmentValue}
                        </div>
                        <div className="col-3">
                            Signature of CoSignor
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <p>
                        <small>
                            RECEIVED, subject to the classifications and lawfully filed tariffs in effect on the date
                            of the issue of this Bill of Lading, the property described above in apparent good order,
                            except as noted (contents and conditions of contents of packages unknown), marked consigned
                            and destined as indicated above which said carrier (the word carrier being understood throughout
                            this contract as meaning any person or corporation in possession of the property under the contract)
                            agrees to carry to its usual place of delivery at said destination, if on its route, otherwise to
                            deliver to another carrier on the route to its destination.  It is mutually agreed as to each carrier
                            of all or any of the said property, over all or any portion of said route to destination and as to
                            each party at any time interested in all or any of said property, that every service to be performed
                            hereunder shall be subject to the bill of lading terms and conditions in the governing classification
                            on the date of shipment.  Shipper hereby certifies that he is familiar with all the bill of lading
                            terms and conditions in the governing classification and the said terms and conditions are hereby
                            agreed to by the shipper and accepted for himself and his assigns.
                        </small>
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col-6 p-2 ">
                    <div className="row">
                        <div className="col">
                            Shipper:
                        </div>
                    </div>
                    <div className="divider my-2" />
                    <div className="row">
                        <div className="col">
                            Per:
                        </div>
                    </div>
                </div>
                <div className="col-6 p-2 ">
                    <div className="row">
                        <div className="col">
                            Carrier:
                        </div>
                    </div>
                    <div className="divider my-2" />
                    <div className="row">
                        <div className="col">
                            Per:
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <p>
                        <small>
                            *  Mark "X" or "RQ" if appropriate to designate Hazardous Materials
                            as defined in the Department of Transport Regulations governing the
                            transportation of hazardous materials.  The use of this column is an
                            optional method for identifying hazardous materials on bills of lading
                            per Section172.201(a)(1)(iii) of Title 49, Code of Federal Regulations.
                            Also, when shipping hazardous materials the shipper's certification
                            statement prescribed in Section 172.204(a) of the Federal Regulations
                            must be indicated on the bill of lading, unless a specific exemption
                            from this requirement is provided in the Regulations for a particular
                            material.
                        </small>
                    </p>
                </div>
            </div>
        </div>
    )
}
