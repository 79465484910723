import APIRequest from './MainAPICall/api';
import APIDownload from './MainAPICall/APIDownload';
import config from '../APIConfig';

export const GetSurveyAttachmentList = (id) => {
    return APIRequest({
        url: config.baseURL + "/SurveyAttachment/GetSurveyAttachmentList",
        method: "post",
        body: { id: id }
    });
}

export const GetSurveyAttachment = (id, filename) => {
    return APIDownload({
        url: config.baseURL + `/SurveyAttachment/GetSurveyAttachment?FileId=${id}`,
        method: "get",
        body: {id: id},
        filename: filename
    });
}

export const DeleteSurveyAttachment = (id) => {
    return APIRequest({
        url: config.baseURL + "/SurveyAttachment/DeleteSurveyAttachment",
        method: "post",
        body: { id: id }
    });
}

export const SaveSurveyAttachment = (payload) => {
    return APIRequest({
        url: config.baseURL + "/SurveyAttachment/SaveSurveyAttachment",
        method: "post",
        body: payload
    });
}

export const SaveSurveyAttachmentCategory = (payload) => {
    return APIRequest({
        url: config.baseURL + "/SurveyAttachment/SaveSurveyAttachmentCategory",
        method: "post",
        body: payload
    });
}

export const GetSurveyAttachmentCategoryOptions = () => {
    return APIRequest({
        url: config.baseURL + "/SurveyAttachment/GetSurveyAttachmentCategoryOptions",
        method: "get"
    });
}