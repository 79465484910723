import React from 'react';
import { Nav } from 'react-bootstrap';
import { Switch, NavLink, Route } from 'react-router-dom';
import JobInventory from './JobInventory';
import YardInventory from './YardInventory';
import YardList from './YardList';
import JobInventoryList from './JobInventoryList';
import InventoryAudit from './InventoryAudit';
import AssetInventoryList from './Asset/AssetInventoryList';
import AssetItemDetails from './Asset/AssetItemDetails';
import ShopConversionList from './ShopConversionList';
import ShopConversion from './ShopConversion';
import CreateAsset from './Asset/CreateAsset';
import AssetFailure from './Asset/AssetFailure';
import AssetRepair from './Asset/AssetRepair';
import AssetCheckin from './Asset/AssetCheckin';
import AssetFailureLog from './Asset/AssetFailureLog';
import LogEntry from './Asset/LogEntry';
import EditAsset from './Asset/EditAsset';
import InventoryItems from './InventoryItems';


export default props => {
    return (
        <>
            <Nav variant="tabs" className="my-2">
                <Nav.Item>
                    <Nav.Link eventKey={1} as={NavLink} to={`${props.match.url}/Job`}>Job Inventory</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey={2} as={NavLink} to={`${props.match.url}/Yard`}>Yard Inventory</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey={3} as={NavLink} to={`${props.match.url}/Asset`}>Asset List</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey={4} as={NavLink} to={`${props.match.url}/ShopFormConfig`}>Shop Form Configuration</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey={5} as={NavLink} to={`${props.match.url}/Audit`}>Inventory Audit</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey={6} as={NavLink} to={`${props.match.url}/FailureLog`}>Asset Failure Log</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey={7} as={NavLink} to={`${props.match.url}/InventoryItems`}>Inventory Items</Nav.Link>
                </Nav.Item>
            </Nav>
            <Switch>
                <Route path={`${props.match.url}/Audit`} component={InventoryAudit} />
                <Route path={`${props.match.url}/InventoryItems`} component={InventoryItems} />
                <Route path={`${props.match.url}/FailureLog/:id`} component={LogEntry} />
                <Route path={`${props.match.url}/FailureLog`} component={AssetFailureLog} />
                <Route path={`${props.match.url}/Asset/CreateAsset`} component={CreateAsset} />
                <Route path={`${props.match.url}/Asset/:id/AssetRepair`} component={AssetRepair} />
                <Route path={`${props.match.url}/Asset/:id/AssetFailure`} component={AssetFailure} />
                <Route path={`${props.match.url}/Asset/:id/CheckInRepair`} component={AssetCheckin} />
                <Route path={`${props.match.url}/Asset/:id/EditAsset`} component={EditAsset} />
                <Route path={`${props.match.url}/Asset/:Sid`} component={AssetItemDetails} />
                <Route path={`${props.match.url}/Asset`} component={AssetInventoryList} />
                <Route path={`${props.match.url}/Job/:jobId`} component={JobInventory} />
                <Route path={`${props.match.url}/Job`} component={JobInventoryList} />
                <Route path={`${props.match.url}/Yard/:yardId`} component={YardInventory} />
                <Route path={`${props.match.url}/Yard`} component={YardList} />
                <Route path={`${props.match.url}/ShopFormConfig/:id`} component={ShopConversion} />
                <Route path={`${props.match.url}/ShopFormConfig`} component={ShopConversionList} />
            </Switch>
        </>
    )
}



