import React, { useState, useEffect } from 'react';
import { GetFieldTicket, GetHourFuelPhoto } from '../../API/JobForm';
import { Accordion, Card, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faDownload, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import PdfButton from "./formPdfUxComponents/PdfButton"
import EmailPDFModal from "./formPdfUxComponents/emailPDFModal"
import LoadingOverlay from 'react-loading-overlay';

export default props => {
    const [ticket, setTicket] = useState({
        fieldTicketId: 0,
        date: "",
        jobId: 0,
        equipmentForms: [],
        fieldTicketRoustabouts: [],
        fieldTicketComments: [],
        history: [],
        clientSignaturePrint: "",
        clientSignatureData: "",
        supervisorSignatureData: "",
        supervisorSignaturePrint: ""
    })

    const [active, setActive] = useState(true);

    useEffect(() => {
        if (props.match.params.id !== 'new') {
            GetFieldTicket(props.match.params.id)
                .then(res => {
                    setTicket(res.data)
                    setActive(false);
                })
        }
    }, [props.match.params.id])

    const downloadHourFuel = (id) => {
        let download = require('downloadjs')
        GetHourFuelPhoto(id)
        .then(res => {
            res.blob.then(b => {
                download(b, res.fileName)

            })
        })
    }


    return (
        <LoadingOverlay
            active={active}
            spinner
            text='Loading Fieldticket...'
        >
            <div className="container">
                <div className="row">
                    <div className="col">
                        <h3>Field Ticket for {ticket.date}</h3>
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col text-right">
                        <PdfButton formId={props.match.params.id} formType="Field Ticket" />
                        <EmailPDFModal formId={props.match.params.id} formType="Field Ticket" />
                    </div>
                </div>
                <ListGroup>
                    <ListGroup.Item className="enc-data-list">
                        <div className="row">
                            <div className="col">
                            Roustabouts
                        </div>
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item className="enc-data-list">
                        <div className="row">
                            <div className="col-8">
                            Name
                        </div>
                            <div className="col-4">
                            Hours
                        </div>
                        </div>
                    </ListGroup.Item>
                    {ticket.fieldTicketRoustabouts.length === 0 &&
                        <ListGroup.Item>No Roustabout Hours Recorded</ListGroup.Item>
                    }
                    {ticket.fieldTicketRoustabouts.map((m, i) => {
                        return (
                            <ListGroup.Item key={`routa-${i}`}>
                                <div className="row">
                                    <div className="col-8">
                                        {m.name}
                                    </div>
                                    <div className="col-4">
                                        {m.hours}
                                    </div>
                                </div>
                            </ListGroup.Item>
                        )
                    })}
                </ListGroup>

                <div className="divider my-2" />

                <ListGroup>
                    <ListGroup.Item className="enc-list">
                        <div className="row">
                            <div className="col">
                            Equipment Forms
                        </div>
                        </div>
                    </ListGroup.Item>
                    {ticket.equipmentForms.length === 0 &&
                        <ListGroup.Item>No Equipment Forms Created</ListGroup.Item>
                    }
                    {ticket.equipmentForms.map(ft => {
                        return (
                            <ListGroup.Item className="enc-click-list" key={`EF-${ft.equipmentFormId}`} as={Link} to={`${props.preurl}/EquipmentForms/${ft.equipmentFormId}`}>
                                <div className="row">
                                    <div className="col-1">
                                        {ft.completed ?
                                            <FontAwesomeIcon icon={faCheck} style={{ color: "green" }} />
                                            :
                                            <FontAwesomeIcon icon={faTimes} style={{ color: "red" }} />
                                        }
                                    </div>
                                    <div className="col">
                                        {ft.date}
                                    </div>
                                    <div className="col">
                                        {ft.name}
                                    </div>
                                </div>
                            </ListGroup.Item>
                        )
                    })}
                </ListGroup>


                <div className="divider my-2" />

                <ListGroup>
                    <ListGroup.Item className="enc-list">
                        <div className="row">
                            <div className="col">
                            Equipment Hour/Fuel Photos
                        </div>
                        </div>
                    </ListGroup.Item>
                    {ticket.equipmentForms.length === 0 &&
                        <ListGroup.Item>No Equipment Forms Eligible for Photos</ListGroup.Item>
                    }
                    {ticket.equipmentForms.map(ft => 
                        ft.hourFuelPhotos.map(photo => {
                            return (
                                <ListGroup.Item key={`EF-${photo.hourFuelPhotoId}`} >
                                    <div className="row">
                                        <div className="col">
                                            {photo.fileName}
                                        </div>
                                        <div className="col">
                                        <div className="col text-right">
                                            <FontAwesomeIcon icon={faDownload} className="clickable ml-2" onClick={() => downloadHourFuel(photo.hourFuelPhotoId)} />
                                        </div>
                                        </div>
                                    </div>
                                </ListGroup.Item>
                            )
                        })

                    )}
                </ListGroup>

                <div className="divider my-2" />

                <ListGroup>
                    <ListGroup.Item className="enc-comment-list">
                        <div className="row">
                            <div className="col">
                            Daily Ticket Comments
                        </div>
                        </div>
                    </ListGroup.Item>
                    {ticket.fieldTicketComments.map((m, i) => {
                        return (
                            <ListGroup.Item key={`comment-${i}`}>
                                <div className="row">
                                    <div className="col">
                                        {m.commentTypeStr}
                                    </div>
                                    <div className='col'>
                                        {m.signee}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        {m.comment}
                                    </div>
                                    {!(m.signature === null || m.signature === "") &&
                                        <div className="col">
                                            <img src={m.signature} alt="signature" />
                                        </div>
                                    }
                                </div>
                            </ListGroup.Item>
                        )
                    })}
                </ListGroup>

                <div className="divider my-2" />

                <div className="row">
                    <div className="col-md-12 col-lg-6">
                        <h4>Client Signature</h4>
                        <br />
                        <small>Client Printed Name</small>
                        <br />
                        {ticket.clientSignaturePrint}
                        <br />
                        <small>Signature</small>
                        <br />
                        {ticket.clientSignatureData ? 
                        <img className="img-fluid" src={ticket.clientSignatureData} alt="Client Signature" /> 
                        : 
                        "No Signature Available"}
                    </div>
                    <div className="col-md-12 col-lg-6">
                        <h4>Supervisor Signature</h4>
                        <br />
                        <small>
                        Supervisor Name:
                        </small>
                        <br />
                        {ticket.supervisorSignaturePrint}
                        <br />
                        <small>Signature</small>
                        <br />
                        {ticket.supervisorSignatureData ? 
                        <img className="img-fluid" src={ticket.supervisorSignatureData} alt="Supervisor Signature" /> 
                        : 
                        "No Signature Available"}
                    </div>
                </div>
            <Accordion>
                <Card>
                    <Accordion.Toggle as={Card.Header} className="clickable" eventKey="0">
                    Field Ticket History
                            </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <ListGroup variant="flush">
                                {ticket.history.map((m, i) =>
                                    <ListGroup.Item key={`history-${i}`}>
                                        {`${m.type} by ${m.user} on ${m.date}`}
                                    </ListGroup.Item>)}
                            </ListGroup>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>
        </LoadingOverlay>
    )
}
