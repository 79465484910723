import APIRequest from './MainAPICall/api';
import config from '../APIConfig';

export const CreateAccount = (payload) => {
    return APIRequest({
        url: config.baseURL + "/Account/CreateAccount",
        method: "post",
        body: payload
    });
}

export const GetAccountList = (enabled) => {
    return APIRequest({
        url: config.baseURL + "/Account/GetAccountList",
        method: "post",
        body: {enabled: enabled}
    });
}

export const GetAccountListWithChildren = (enabled) => {
    return APIRequest({
        url: config.baseURL + "/Account/GetAccountListWithChildren",
        method: "post",
        body: {enabled: enabled}
    });
}

export const GetAccountProfile = (id) => {
    return APIRequest({
        url: config.baseURL + "/Account/GetAccountProfile",
        method: "post",
        body: {id: id}
    });
}

export const GetAccount = (id) => {
    return APIRequest({
        url: config.baseURL + "/Account/GetAccount",
        method: "post",
        body: {id: id}
    });
}

export const GetLocationProfile = (id) => {
    return APIRequest({
        url: config.baseURL + "/Account/GetLocationProfile",
        method: "post",
        body: {id: id}
    });
}

export const GetAccountContacts = (id) => {
    return APIRequest({
        url: config.baseURL + "/Account/GetAccountContacts",
        method: "post",
        body: {id: id}
    });
}

export const SaveLocation = (payload) => {
    return APIRequest({
        url: config.baseURL + "/Account/SaveLocation",
        method: "post",
        body: payload
    });
}

export const GetContactProfile = (id) => {
    return APIRequest({
        url: config.baseURL + "/Account/GetContactProfile",
        method: "post",
        body: {id: id}
    });
}

export const GetAccountLocations = (id, jobLocation=false) => {
    return APIRequest({
        url: config.baseURL + `/Account/GetAccountLocations?id=${id}&jobLocation=${jobLocation}`,
        method: "get"
    });
}

export const GetAccountOptions = () => {
    return APIRequest({
        url: config.baseURL + "/Account/GetAccountOptions",
        method: "post",
        body: {}
    });
}

export const GetAccountLocationOptions = (id) => {
    return APIRequest({
        url: config.baseURL + "/Account/GetAccountLocationOptions",
        method: "post",
        body: {id: id}
    });
}

export const SaveContact = (payload) => {
    return APIRequest({
        url: config.baseURL + "/Account/SaveContact",
        method: "post",
        body: payload
    });
}

export const CheckAccountEditPermission = () => {
    return APIRequest({
        url: config.baseURL + "/Account/CheckEditPermission",
        method: "get"
    });
}

export const SaveAccountInfo = (payload) => {
    return APIRequest({
        url: config.baseURL + "/Account/SaveAccountInfo",
        method: "post",
        body: payload
    });
}

export const GetAccountJobs = (id) => {
    return APIRequest({
        url: config.baseURL + `/Account/Jobs?accountId=${id}`,
        method: "get"
    });
}

export const GetIndustryTypes = () => {
    return APIRequest({
        url: config.baseURL + `/Account/GetIndustryTypes`,
        method: "get"
    });
}

export const GetTerritories = () => {
    return APIRequest({
        url: config.baseURL + `/Account/GetTerritories`,
        method: "get"
    });
}