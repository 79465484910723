import React, { useState, useEffect, useRef } from 'react';
import { GetSelf } from '../API/User'
import { useHistory } from 'react-router-dom';

export default (props) => {
    const [displayDrop, setDisplayDrop] = useState(false)
    const [userInfo, setUserInfo] = useState({
        UserId: 0,
        Username: "",
        FirstName: "",
        LastName: "",
        FullName: "",
        positionTitle: "",
        PhoneNumbers: [],
        Emails: [],
        EmployeePhoto: ""
    })
    const wrapper = useRef(null);
    let history = useHistory();

    useEffect(() => {
        getUserInfo();
    }, [])

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapper.current && !wrapper.current.contains(event.target)) {
                if (displayDrop) {
                    handleMenuClick();
                }
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });

    const getUserInfo = () => {
        GetSelf()
            .then(res => {
                setUserInfo(res.data)
            })
    }

    const linkTo = (path) => {
        handleMenuClick();
        history.push(path)
    }

    const handleMenuClick = () => {
        setDisplayDrop(!displayDrop)
    };

    const logoutClick = () => {
        handleMenuClick();
        props.logout();
        history.push("/");
    }

    const displayUserImage = () => {
        if (userInfo.employeePhoto) {
            return <img className="user-image" src={userInfo.employeePhoto} alt="Employee" />
        } else {
            return <img className="user-image" src="../images/user.png" alt="Environmental Noise Control" />
        }
    }


    return (
        <div className="container-fluid">
            {/* The following handles the ability for the menu to be a drop down menu */}
            <div className="row">
                <div className="col p-2">
                    <div className="container-fluid p1-border" ref={wrapper}>
                        <div className="row">
                            <div className="col dd-header" onClick={handleMenuClick}>
                                <div className="row my-2">
                                    <div className="col-4">
                                        {displayUserImage()}
                                    </div>
                                    <div className="col-8 my-auto">
                                        <div className="row">
                                            <h4>{userInfo.fullName}</h4>
                                        </div>
                                        <div className="row">
                                            <h5>{userInfo.positionTitle}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {displayDrop &&
                        <>                        
                        <ul className="dd-list">
                            <li className="dd-list-item" onClick={() => linkTo("/User/Profile")}>Profile</li>
                            <li className="dd-list-item" onClick={logoutClick}>Logout</li>
                        </ul>
                        </>
                        }
                    </div>
                </div>

            </div>
        </div>
    )
}