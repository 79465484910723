//import 'bootstrap/scss/bootstrap.scss';
import './css/custom.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import Authentication from './Login/Authentication';
import registerServiceWorker from './registerServiceWorker';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');


ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <Authentication />
  </BrowserRouter>,
  rootElement);

registerServiceWorker();

export { baseUrl }


