
import React from 'react';
import { Button } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';

export default props => {
    return (
        <>
            <form action="#" onSubmit={(e) => { e.preventDefault(); props.save() }}>
                <div className="row mb-1">
                    <div className="col">
                        <div className="row">
                            <div className="col-3">
                                Install Type:
                            </div>
                            <div className="col-9">
                                <input type='text' className="enc-input-100" onChange={(e) => props.change('installType', e.target.value)} value={props.data.installType} />
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="row">
                            <div className="col-3">
                                Removal Type:
                            </div>
                            <div className="col-9">
                                <input type='text' className="enc-input-100" onChange={(e) => props.change('removalType', e.target.value)} value={props.data.removalType} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mb-1">
                    <div className="col-6">
                        <div className="row">
                            <div className="col-3">
                                Lead Time:
                            </div>
                            <div className="col-9">
                                <input type='text' className="enc-input-100" onChange={(e) => props.change('leadTime', e.target.value)} value={props.data.leadTime} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mb-1">
                    <div className="col">
                        <div className="row">
                            <div className="col-3">
                                Install Date:
                            </div>
                            <div className="col-9">
                                <ReactDatePicker
                                    className="enc-input"
                                    selected={props.data.installDate}
                                    onChange={(date) => props.change('installDate', date)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="row">
                            <div className="col-3">
                                Removal Date:
                            </div>
                            <ReactDatePicker
                                minDate={props.data.installDate}
                                className="enc-input"
                                selected={props.data.removalDate}
                                onChange={(date) => props.change('removalDate', date)}
                            />
                        </div>
                    </div>
                </div>
                <Button className="enc-button" type='submit'>Save</Button>
            </form>
        </>
    )
}
