import React, { useState, useEffect } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { DropdownList } from 'react-widgets';
import { GetReviewInformation, PostReviewNote } from '../API/JobReview';

export default ({ jobId, reloadParent = () => { }, ...props }) => {
    const [note, setNote] = useState({
        note: "",
        noteTitle: ""
    });

    const [error, setError] = useState("")

    const [steps, setSteps] = useState([])
    const [selectedStep, setSelectedStep] = useState(null);


    useEffect(() => {
        GetReviewInformation(jobId)
            .then(res => {
                setSteps(res.data.steps)
            })
    }, [jobId])

    const changeNote = (key, value) => {
        let s = { ...note };
        s[key] = value;
        setNote(s)
    };

    const AddFeedback = () => {
        if (!selectedStep) {
            setError("Please select a return step.")
            return;
        }
        PostReviewNote({
            jobId: parseInt(jobId),
            targetStep: selectedStep.stepId,
            ...note
        }).then(res => {
            if (res.success) {
                reloadParent()
            }
        })
    }

    const NextStage = () => {
        PostReviewNote({
            JobId: parseInt(jobId),
            targetStep: -1,
            ...note
        }).then(res => {
            if (res.success) {
                reloadParent()
            }
        })
    }

    return (
        <>

            <div className="divider my-3" />
            <form action="#" onSubmit={(e) => { e.preventDefault(); AddFeedback() }}>
                <div className="row">
                    <div className="col">
                        <small>Note Title</small>
                        <input
                            className="enc-input-100"
                            required
                            id="noteTitle"
                            placeholder="Note Title"
                            value={note.noteTitle}
                            onChange={(e) => changeNote('noteTitle', e.target.value)} />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <small>Review Note</small>
                        <textarea
                            className="enc-textarea"
                            required
                            value={note.note}
                            onChange={(e) => changeNote('note', e.target.value)}
                            placeholder="Review Note" />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <DropdownList
                            placeholder="Select Stage to Return to"
                            data={steps}
                            value={selectedStep}
                            textField="stepName"
                            onChange={e => setSelectedStep(e)} />
                    </div>
                    <div className="col text-right">
                        <Button className="enc-button mr-1" type='submit'>Add Feedback</Button><Button className="enc-button" onClick={NextStage}>Next Stage</Button>
                    </div>
                </div>
                <Alert variant="danger" show={error !== ""} onClose={() => setError("")} dismissible>
                    {error}
                </Alert>
            </form>
        </>
    )
}
