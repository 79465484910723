import React, { useEffect, useState } from 'react';
import { GetEmployeeList } from '../API/Employee';
import { useHistory, Link } from 'react-router-dom';
import { ListGroup, Button } from 'react-bootstrap';

export default props => {
    const [EmployeeList, setEmployeeList] = useState([]);
    
    useEffect(() => {
        loadEmployeess();
    }, [])

    const loadEmployeess = () => {
        GetEmployeeList({})
            .then(res => {
                setEmployeeList(res.data)
            })
    }

    const history = useHistory();
    const navigateToEmployee = (eId) => {
        history.push(`/Employees/${eId}`)
    }

    return (
        <div className="container pt-4">
            <div className="row">
                <div className="col pb-1">
                    <Button className="enc-button" as={Link} to={`/Employees/new`}>New Employee</Button>
                </div>
            </div>
            <div>
                <ListGroup>
                    <ListGroup.Item className="enc-data-list">Employee List</ListGroup.Item>
                    <ListGroup.Item className="enc-data-list">
                        <div className="row">
                            <div className="col">
                                First Name
                            </div>
                            <div className="col">
                                Last Name
                            </div>
                            <div className="col">
                                Phone
                            </div>
                            <div className="col">
                                Email
                            </div>
                        </div>
                    </ListGroup.Item>

                    {EmployeeList.map((e, i) =>
                        <ListGroup.Item key={`Employee-${i}`} onClick={() => navigateToEmployee(e.employeeId)} className="click-list">
                            <div className="row">
                                <div className="col">
                                    {e.firstName}
                                </div>
                                <div className="col">
                                    {e.lastName}
                                </div>
                                <div className="col">
                                    {e.phoneNumber}
                                </div>
                                <div className="col">
                                    {e.email}
                                </div>
                            </div>
                            <div className="row">
                                
                            </div>
                        </ListGroup.Item>)}
                </ListGroup>
            </div>
        </div>
    )
}
