import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import JobListWidget from './Widgets/JobListWidget';
import { GetUsersWidgets } from '../API/Dashboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs } from '@fortawesome/free-solid-svg-icons';

export default props => {
    const [widgets, setWidgets] = useState([])

    useEffect(() => {
        LoadWidgets()
    }, [])

    const LoadWidgets = () => {
        GetUsersWidgets()
        .then(res=>{
            setWidgets(res.data)
        })
    }

    const PopulateDashboard = (widget, i) => {
        if (widget.widgetType === 0)
            return <JobListWidget key={`dashwidg-${i}`} widgetId={widget.dashboardWidgetId} />
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col text-right">
                    <Button className='enc-button
                    ' onClick={props.toggle}><FontAwesomeIcon icon={faCogs} /></Button>
                </div>
            </div>
            <div className="container">
                    <div className="row">
                        {widgets.map((m, i) => PopulateDashboard(m, i))}
                    </div>
            </div>
        </div>
    )
}
