import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faCheck } from '@fortawesome/free-solid-svg-icons';
import { GetFailurePhoto, GetFailureForm } from '../../../API/AssetInventory';
import { ListGroup } from 'react-bootstrap';

export default props => {
    const [failureReport, setFailureReport] = useState({
        "assetFailureId": 0,
        "assetId": 0,
        "serialNumber": "",
        "assetName": "",
        "lastOperation": "",
        "failureDate": "",
        "jobFailure": "",
        "jobFailureId": 0,
        "technicianName": "",
        "technicianId": 0,
        "meterInCirculation": true,
        "natureOfFailure": "",
        "correctiveActions": "",
        "officeName": "",
        "photos": []
    });

    useEffect(() => {
        GetFailureForm(props.match.params.id)
            .then(res => {
                setFailureReport(res.data)
            })
    }, [props.match.params.id])
    return (
        <div className="container">
            <row className="row">
                <div className="col">
                    <h3>Meter Failure Report</h3>
                </div>
            </row>
            <div className="row mt-2">
                <div className="col">
                    <h4>Asset</h4>
                </div>
                <div className="col">
                    <h4>{failureReport.assetName}</h4>
                </div>
                <div className="col">
                    <h4>{failureReport.serialNumber}</h4>
                </div>
            </div>

            <div className="divider my-2" />

            <div className="row mt-2">
                <div className="col">
                    Last Time of Operation
                </div>
                <div className="col">
                    {failureReport.lastOperation}
                </div>
            </div>

            <div className="divider my-2" />

            <div className="row mt-2">
                <div className="col">
                    Date of Failure
                </div>
                <div className="col">
                    {failureReport.failureDate}
                </div>
            </div>

            <div className="divider my-2" />


            <div className="row mt-2">
                <div className="col">
                    Office Name
                </div>
                <div className="col">
                    {failureReport.officeName}
                </div>
            </div>

            <div className="divider my-2" />

            <div className="row mt-2">
                <div className="col">
                    Technician
                </div>
                <div className="col">
                    {failureReport.technicianName}
                </div>
            </div>

            <div className="divider my-2" />

            <div className="row mt-2">
                <div className="col">
                    Meter in circulation after failure?
                </div>
                <div className="col">
                    {failureReport.meterInCirculation && <FontAwesomeIcon icon={faCheck} />}
                </div>
            </div>

            <div className="divider my-2" />

            <div className="row mt-2">
                <div className="col">
                    Nature of Failure
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <p>
                        {failureReport.natureOfFailure}
                    </p>
                </div>
            </div>

            <div className="divider my-2" />

            <div className="row mt-2">
                <div className="col">
                    Corrective Actions
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <p>
                        {failureReport.correctiveActions}
                    </p>
                </div>
            </div>

            <div className="divider my-2" />

            <ListGroup className="mt-1">
                {failureReport.photos.map((m, i) =>
                    <ListGroup.Item key={`log-${i}`} >
                        <div className="row">
                            <strong className="col-8">{m.fileName}</strong>

                            <div className="col-2 ">
                                <FontAwesomeIcon className="clickable" icon={faDownload} onClick={() => GetFailurePhoto(m.assetFailurePhotoId, m.fileName)} />
                            </div>
                        </div>
                    </ListGroup.Item>
                )}
            </ListGroup>
        </div >
    )
}
