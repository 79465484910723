import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { PostCalculator, GetCalculator } from '../API/Calculator';
import { useHistory } from 'react-router-dom';

export default props => {
    const [calculator, setCalculator] = useState({
        calculatorName: "",
        panelsPerSection: 0,
        beamRatio: 0.0,
        beamName: "",
        truckBeams: 0,
        truckPanels: 72
    })

    useEffect(()=>{
        if(props.id){
            GetCalculator(props.id)
            .then(res=>{
                setCalculator(res.data)
            })
        }
    },[props.id])

    const changeCalculator = (key, value) => {
        let s = { ...calculator }
        s[key] = value
        setCalculator(s)
    }

    let history = useHistory();

    const saveCalculator = () => {
        let payload = {...calculator}
        payload.panelsPerSection = parseInt(calculator.panelsPerSection)
        payload.beamRatio = parseFloat(calculator.beamRatio)
        payload.truckBeams = parseInt(calculator.truckBeams)
        payload.truckPanels = parseInt(calculator.truckPanels)
        PostCalculator(payload)
            .then(res => {
                if (props.rl) {
                    props.rl();
                    setCalculator({
                        calculatorName: "",
                        panelsPerSection: 0,
                        beamRatio: 0.0,
                        beamName: "",
                        truckBeams: 0,
                        truckPanels: 72
                    })
                } else {
                    history.goBack();
                }
            })
    }

    return (
        <div className="container">
            <form action="#" onSubmit={(e) => { e.preventDefault(); saveCalculator() }} >
                <div className="row mt-5">
                    <div className="col">
                        <h3>Calculator Creation</h3>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <label>Calculator Name</label>
                        <input
                            className="enc-input-100"
                            value={calculator.calculatorName}
                            onChange={(e) => changeCalculator('calculatorName', e.target.value)}
                            required
                        />
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <h4>Wall Calculations</h4>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <label>Beam Name</label>
                        <input
                            className="enc-input-100"
                            value={calculator.beamName}
                            onChange={(e) => changeCalculator('beamName', e.target.value)}
                            required
                        />
                    </div>
                    <div className="col">
                        <label>Beam Ratio</label>
                        <input
                            className="enc-input-100"
                            value={calculator.beamRatio}
                            type='number'
                            onChange={(e) => changeCalculator('beamRatio', e.target.value)}
                            required
                        />
                    </div>
                    <div className="col">
                        <label>Panels Per Section(20&apos;)</label>
                        <input
                            className="enc-input-100"
                            value={calculator.panelsPerSection}
                            type='number'
                            onChange={(e) => changeCalculator('panelsPerSection', e.target.value)}
                            required
                        />
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <h4>Truck Calculations</h4>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <label>Beams Per Truck</label>
                        <input
                            className="enc-input-100"
                            value={calculator.truckBeams}
                            type='number'
                            onChange={(e) => changeCalculator('truckBeams', e.target.value)}
                            required
                        />
                    </div>
                    <div className="col">
                        <label>Panels Per Truck</label>
                        <input
                            className="enc-input-100"
                            value={calculator.truckPanels}
                            type='number'
                            onChange={(e) => changeCalculator('truckPanels', e.target.value)}
                            required
                        />
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col text-right">
                        <Button type='submit' className="enc-button">Save</Button>
                    </div>
                </div>
            </form>
        </div>
    )
}
