import APIRequest from './MainAPICall/api';
import config from '../APIConfig';

export const GetCalculator = (id) => {
    return APIRequest({
        url: config.baseURL + `/Calculator/Calculator?id=${id}`,
        method: "get",
    });
}

export const PostCalculator = (payload) => {
    return APIRequest({
        url: config.baseURL + "/Calculator/Calculator",
        method: "post",
        body: payload
    });
}

export const GetCalculatorList = () => {
    return APIRequest({
        url: config.baseURL + "/Calculator/CalculatorList",
        method: "get",
    });
}

export const DeleteCalculator = (id) => {
    return APIRequest({
        url: config.baseURL + `/Calculator/DeleteCalculator?id=${id}`,
        method: "get",
    });
}

export const GetCalculatorResult = (id) => {
    return APIRequest({
        url: config.baseURL + `/Calculator/CalculatorResult?id=${id}`,
        method: "get",
    });
}

export const PostCalculatorResult = (payload) => {
    return APIRequest({
        url: config.baseURL + `/Calculator/CalculatorResult`,
        method: "post",
        body: payload
    });
}

export const GetCalculatorResultList = (JobId) => {
    return APIRequest({
        url: config.baseURL + `/Calculator/CalculatorResultList?JobId=${JobId}`,
        method: "get",
    });
}