import React from 'react';
import { Switch, Route } from 'react-router-dom';
import EditJob from '../Jobs/EditJob';
import JobView from '../Jobs/JobView';
import OpportunityJobList from './OpportunityJobList';



export default (props) => {
    return (
        <>
            <Switch>
                <Route path={`${props.match.url}/NewJob`} render={() => <EditJob oppId={props.match.params.oppId} {...props} />} />
                <Route path={`${props.match.url}/:jobId/Edit`} render={(routeprops) => <EditJob oppId={props.match.params.oppId} {...routeprops} />} />
                <Route path={`${props.match.url}/:jobId`} render={(routeprops) => <JobView oppId={props.match.params.oppId} {...routeprops} />} />
                <Route path={`${props.match.url}`} render={() => <OpportunityJobList {...props} />} />
            </Switch>
        </>
    )
} 