import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import TimesheetList from './TimeSheetList';
import EditTimesheet from './EditTimeSheet';
import EditTimesheetEntry from './EditTimeSheetEntry';

export default (props) => {
    const url = props.match.url;
    const [date, setDate] = useState(new Date());

    return (
        <>
            <Switch>
                <Route path={`${url}/EditTimesheet/:tsId`} render={(routeprops) =>
                    <EditTimesheet 
                        url={routeprops.match.url} 
                        timesheetId={routeprops.match.params.tsId} 
                        date={date}
                    /> 
                } />
                <Route path={`${url}/EditTimesheetEntry/:tseId`} render={(routeprops) =>
                    <EditTimesheetEntry 
                        url={routeprops.match.url} 
                        timesheetEntryId={routeprops.match.params.tseId}
                        date={date}
                    /> 
                } />
                <Route path={`${url}`} render={(routeprops) =>
                    <TimesheetList 
                        url={routeprops.match.url} 
                        date={date}
                        setDate={setDate}
                    /> 
                } />
            </Switch>
        </>
    )
}