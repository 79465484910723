import React, { useEffect, useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { GetJobsWithInventoryAccountInformation } from '../API/InventoryTransfer';

export default props => {
    const [jobList, setJobList] = useState([]);
    useEffect(() => {
        GetJobsWithInventoryAccountInformation()
            .then(res => {
                setJobList(res.data)
            })
    }, [])
    return (
        <>
            <div className="row">
                <div className="col">
                    <ListGroup>
                        <ListGroup.Item className="enc-data-list">
                            Job List
                        </ListGroup.Item>
                        <ListGroup.Item className="enc-data-list">
                            <div className="row">
                                <div className="col">
                                    Job Name
                                </div>
                                <div className="col">
                                    Job Number
                                    </div>
                                <div className="col">
                                    Account Name
                                    </div>
                            </div>
                        </ListGroup.Item>
                        {jobList.map((j, i) => {
                            return (
                                <ListGroup.Item key={`job-${i}`} as={Link} to={`${props.match.url}/${j.jobId}`} className="click-list">
                                    <div className="row">
                                        <div className="col">
                                            {j.jobName}
                                        </div>
                                        <div className="col">
                                            {j.jobNumber}
                                        </div>
                                        <div className="col">
                                            {j.accountName}
                                        </div>
                                    </div>
                                </ListGroup.Item>
                            )
                        })}
                    </ListGroup>
                </div>
            </div>
        </>
    )
}
