
import React from 'react';
import { Button } from 'react-bootstrap';

export default props => {
    return (
        <>
            <div className="row">
                <div className="col">
                    Prevailing wage: {props.prevailingWage ? "Yes" : "No"}
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <p style={{ whiteSpace: "pre-line" }}>
                        {props.additionalInfo}
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col text-right">
                    {props.setEdit && <Button className="enc-button" onClick={() => props.setEdit(true)}>Edit Info</Button>}
                </div>
            </div>
        </>
    )
}
