import React, { useState, useEffect } from 'react';
import ReactDatePicker from 'react-datepicker';
import { GetFailureJobHistory, PostFailureForm } from '../../../API/AssetInventory';
import { DropdownList } from 'react-widgets';
import { Button } from 'react-bootstrap';
import { GetUserList } from '../../../API/User';

export default props => {
    const [failureReport, setFailureReport] = useState({
        "assetFailureId": 0,
        "lastOperation": new Date(),
        "failureDate": new Date(),
        "jobFailure": "",
        "jobFailureId": 0,
        "technicianName": "",
        "technicianId": 0,
        "meterInCirculation": false,
        "natureOfFailure": "",
        "correctiveActions": "",
    })

    const [circulation, setCirculation] = useState('No')

    const [userList, setUserList] = useState([]);
    const [user, setUser] = useState(null)

    const [jobHistory, setJobHistory] = useState([]);
    const [job, setJob] = useState(null)

    useEffect(() => {
        GetFailureJobHistory(props.match.params.id)
            .then(res => {
                setJobHistory(res.data)
            })
    }, [props.match.params.id])

    useEffect(() => {
        GetUserList()
        .then(res => {
            setUserList(res.data)
        })
    },[])

    const changeFailureReport = (key, value) => {
        let s = { ...failureReport }
        s[key] = value
        setFailureReport(s)
    }

    const SaveFailureReport = () => {
        let payload = {
            ...failureReport
        }
        payload.assetId = parseInt(props.match.params.id)
        payload.lastOperation = payload.lastOperation.toJSON();
        payload.failureDate = payload.failureDate.toJSON();
        payload.meterInCirculation = circulation === 'No' ? false : circulation.value;
        if(user){
            payload.technicianId = user.userId;
        }
        if(job){
            payload.jobFailureId = job.jobId
        }
        PostFailureForm(payload)
        .then(res => {
            props.save(res.id)
        })
    }

    return (
        <div className="container">
            <form action="#" onSubmit={(e) => { e.preventDefault(); SaveFailureReport() }}>
            <row className="row">
                <div className="col">
                    <h3>Meter Failure Report</h3>
                </div>
            </row>

            <div className="row mt-2">
                <div className="col">
                    Last Time of Operation
                </div>
                <div className="col">
                    <ReactDatePicker
                        required
                        showTimeSelect
                        dateFormat="MMMM d, yyyy h:mm aa"
                        className="enc-input-100"
                        selected={failureReport.lastOperation}
                        onChange={(date) => changeFailureReport('lastOperation', date)}
                    />
                </div>
            </div>

            <div className="row mt-2">
                <div className="col">
                    Date of Failure
                </div>
                <div className="col">
                    <ReactDatePicker
                        required
                        showTimeSelect
                        dateFormat="MMMM d, yyyy h:mm aa"
                        className="enc-input-100"
                        selected={failureReport.failureDate}
                        onChange={(date) => changeFailureReport('failureDate', date)}
                    />
                </div>
            </div>

            <div className="row mt-2">
                <div className="col">
                    Job of Failure
                </div>
                <div className="col">
                    <DropdownList
                        required
                        placeholder="Select Job"
                        data={jobHistory}
                        textField='jobName'
                        value={job}
                        onChange={e => setJob(e)}
                    />
                </div>
            </div>

            <div className="row mt-2">
                <div className="col">
                    Technician
                </div>
                <div className="col">
                    <DropdownList
                        required
                        filter='contains'
                        placeholder="User List"
                        textField="name"
                        data={userList}
                        value={user}
                        onChange={e => setUser(e)}
                    />
                </div>
            </div>

            <div className="row mt-2">
                <div className="col">
                    Meter in circulation after failure?
                </div>
                <div className="col">
                    <DropdownList
                        required
                        filter='contains'
                        textField="name"
                        data={ [{name: "Yes", value: true}, {name: "No", value: false}]}
                        value={ circulation }
                        onChange={e => setCirculation(e)}
                    />
                </div>
            </div>

            <div className="row mt-2">
                <div className="col">
                    Describe Nature of Failure
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <textarea 
                    required
                    className="enc-textarea" 
                    value={failureReport.natureOfFailure}
                    onChange={e => changeFailureReport('natureOfFailure', e.target.value)}
                    />
                </div>
            </div>

            <div className="row mt-2">
                <div className="col">
                    Describe Corrective Actions Take to Fix Error/Failure
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <textarea 
                    required
                    className="enc-textarea"
                    value={failureReport.correctiveActions}
                    onChange={e => changeFailureReport('correctiveActions', e.target.value)}
                    />
                </div>
            </div>
            <div className="row mt-2">
                <div className="col text-right">
                    <Button type='submit' className="enc-button">Save</Button>
                </div>
            </div>
            </form>
        </div>
    )
}
