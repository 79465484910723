import React, { useState } from 'react';
import { FilePond } from 'react-filepond';
import { ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faDownload } from '@fortawesome/free-solid-svg-icons';
import { GetFailurePhoto, DeleteFailurePhoto } from '../../../API/AssetInventory';

export default props => {
    let [pictures, setPictures] = useState([])
    // FilePondConfiguration //
    const addPicture = (res) => {
        let x = JSON.parse(res);
        let s = [...pictures]
        s.push(x.data)
        setPictures(s);
    }

    const deletePicture = (id, index) => {
        DeleteFailurePhoto(id)
            .then(res => {
                if (res.success) {
                    let s = [...pictures]
                    s.splice(index, 1)
                    setPictures(s)
                }
            })
    }

    const FilePondSettings = () => {
        let token;

        const LS = localStorage.getItem("BAENCToken");
        const SS = sessionStorage.getItem("BAENCToken");

        if (LS) {
            token = LS;
        } else if (SS) {
            token = SS;
        }

        return (
            {
                url: "/api/AssetInventory",
                process: {
                    url: "/FailurePhoto",
                    method: "post",
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    ondata: (formData) => {
                        formData.append("FailureId", props.failureId);
                        return formData;
                    },
                    onload: (res) => { addPicture(res) }
                },
                load: null,
                revert: null,
                restore: null,
                fetch: null,
                patch: null
            })
    }

    return (
        <div>
            {pictures.map((m, i) =>
                <ListGroup key={`Photo-${i}`} className="mt-1">
                    <ListGroup.Item  >
                        <div className="row">
                            <strong className="col-8">{m.fileName}</strong>

                            <div className="col-2 ">
                                <FontAwesomeIcon className="clickable" icon={faDownload} onClick={() => GetFailurePhoto(m.assetFailurePhotoId, m.fileName)} />
                            </div>
                            <div className="col-2">
                                <FontAwesomeIcon icon={faTrash} className="clickable" onClick={() => deletePicture(m.assetFailurePhotoId, i)} />
                            </div>
                        </div>
                    </ListGroup.Item>
                </ListGroup>
            )}

            <FilePond
                files={[]}
                className="mt-3"
                allowMultiple={false}
                server={FilePondSettings()}
                credits={false}
            />
        </div>
    )
}
