import React, { useState, useEffect } from 'react';
import { Table, Button, ListGroup } from 'react-bootstrap';
import { AssetAtJob } from '../../API/AssetInventory';
import { DropdownList } from 'react-widgets';
import { GetJobReadyItemList, GetJobInventory, PostJobInventory } from '../../API/JobReadyInventory';
import { GetBulkItemList, GetJobInventoryBulk, PostJobInventoryBulk } from '../../API/InventoryBulk';
import { Link } from 'react-router-dom';

export default props => {
    const [assetInventory, setAssetInventory] = useState([]);
    const [jobReadyItemInventory, setJobReadyItemInventory] = useState([]);
    const [bulkItemInventory, setBulkItemInventory] = useState([]);
    const [jobReadyItems, setJobReadyItems] = useState([]);
    const [selectedJobReady, setSelectedJobReady] = useState(-1);
    const [bulkItems, setBulkItems] = useState([]);
    const [selectedBulk, setSelectedBulk] = useState(-1);

    useEffect(() => {
        LoadJobReadyItems();
        LoadBulkItems();
    }, []);

    useEffect(() => {
        let id = props.match.params.jobId;
        LoadAssetInventory(id);
        LoadJobReadyItemInventory(id);
        LoadBulkItemInventory(id);
    }, [props.match.params.jobId]);

    const LoadAssetInventory = (id) => {
        AssetAtJob(id)
            .then(res => {
                setAssetInventory(res.data);
            });
    }

    const LoadJobReadyItems = () => {
        GetJobReadyItemList()
            .then(res => {
                res.data.unshift({
                    "jobReadyItemId": -1,
                    "name": "Select an Item"
                })
                setJobReadyItems(res.data);
            })
    }

    const LoadJobReadyItemInventory = (id) => {
        GetJobInventory(id)
            .then(res => {
                let data = [...res.data]
                data.forEach(e => {
                    e.adjustment = 0;
                })
                setJobReadyItemInventory(data);
            })
    }

    const LoadBulkItems = () => {
        GetBulkItemList()
            .then(res => {
                res.data.unshift({
                    "bulkItemId": -1,
                    "bulkItemName": "Select an Item"
                })
                setBulkItems(res.data);
            })
    }

    const LoadBulkItemInventory = (id) => {
        GetJobInventoryBulk(id)
            .then(res => {
                let data = [...res.data]
                data.forEach(e => {
                    e.adjustment = 0;
                })
                setBulkItemInventory(data);
            })
    }
    
    const saveInventory = () => {
        saveInventoryJRI();
        saveInventoryBulk();
    }
    
    const saveInventoryJRI = () => {
        let payload = {
            jobId: parseInt(props.match.params.jobId),
            data: []
        };

        jobReadyItemInventory.forEach(jri => {
            let x = {...jri};
            if (x.adjustment !== null) {
                jri.adjustment = parseInt(x.adjustment);
                payload.data.push(jri);
            }
        });   

        PostJobInventory(payload)
            .then(res => {
                LoadJobReadyItemInventory(payload.jobId)
            });
    }

    const saveInventoryBulk = () => {
        let payload = {
            jobId: parseInt(props.match.params.jobId),
            data: []
        };
        
        bulkItemInventory.forEach(bi => {
            let x = {...bi};
            if (x.adjustment !== null) {
                bi.adjustment = parseInt(x.adjustment);
                payload.data.push(bi);
            }
        });      

        PostJobInventoryBulk(payload)
            .then(res => {
                LoadBulkItemInventory(payload.jobId);
            });
    }

    const AddItem = () => {
        if (selectedJobReady === -1) {
            return;
        }
        let item = jobReadyItems.find(m => m.jobReadyItemId === selectedJobReady)
        let s = [...jobReadyItemInventory]
        s.unshift({
            yardInventoryId: 0,
            name: item.name,
            jobReadyItemId: selectedJobReady,
            totalAmount: 0,
            currentAmount: 0,
            adjustment: 0
        })
        setJobReadyItemInventory(s);
        setSelectedJobReady(-1);
    }

    const AddItemBulk = () => {
        if (selectedBulk === -1) {
            return;
        }
        let item = bulkItems.find(m => m.bulkItemId === selectedBulk)
        let s = [...bulkItemInventory]
        s.unshift({
            yardInventoryId: 0,
            bulkItemName: item.bulkItemName,
            bulkItemId: selectedBulk,
            totalAmount: 0,
            currentAmount: 0,
            adjustment: 0
        })
        setBulkItemInventory(s);
        setSelectedBulk(-1);
    }

    const adjustAmount = (index, value) => {
        let val = value === "" ? 0 : parseInt(value);
        let s = [...jobReadyItemInventory];
        if (new RegExp('^-?([0-9]{0,10})$').test(value)) {
            s[index].adjustment = value;
            if (!Number.isNaN(val)) {
                s[index].NA = s[index].currentAmount + val
            }
            setJobReadyItemInventory(s);
        }
    }

    const adjustAmountBulk = (index, value) => {
        let val = value === "" ? 0 : parseInt(value);
        let s = [...bulkItemInventory];
        if (new RegExp('^-?([0-9]{0,10})$').test(value)) {
            s[index].adjustment = value;
            if (!Number.isNaN(val)) {
                s[index].NA = s[index].currentAmount + val
            }
            setBulkItemInventory(s);
        }
    }

    return (
        <div className="container">
            <form action="#" onSubmit={(e) => { e.preventDefault(); saveInventory() }}>
                <div className="row mb-2">
                    <div className="col">
                        <DropdownList
                            data={jobReadyItems.filter(item => !jobReadyItemInventory.find(({ jobReadyItemId }) => item.jobReadyItemId === jobReadyItemId))}
                            textField='name'
                            value={selectedJobReady}
                            valueField='jobReadyItemId'
                            onChange={(e) => setSelectedJobReady(e.jobReadyItemId)}
                        />
                    </div>
                    <div className="col text-right">
                        <Button className="enc-button" onClick={AddItem}>Add Job Ready Item</Button>
                    </div>
                </div>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Item Name</th>
                            <th>Historical</th>
                            <th>Inventory</th>
                            <th>Change Inventory</th>
                            <th>New Inventory(Estimated)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {jobReadyItemInventory.map((m, index) =>
                            <tr key={`Row-${m.jobInventoryId}`}>
                                <td>{m.name}</td>
                                <td>{m.totalAmount}</td>
                                <td>{m.currentAmount}</td>
                                <td><input value={m.adjustment} onChange={(e) => adjustAmount(index, e.target.value)} type='number'></input></td>
                                <td>{m.NA}</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
                <div className="row mb-2">
                    <div className="col">
                        <DropdownList
                            data={bulkItems.filter(item => !bulkItemInventory.find(({ bulkItemId }) => item.bulkItemId === bulkItemId))}
                            textField='bulkItemName'
                            value={selectedBulk}
                            valueField='bulkItemId'
                            onChange={(e) => setSelectedBulk(e.bulkItemId)}
                        />
                    </div>
                    <div className="col text-right">
                        <Button className="enc-button" onClick={AddItemBulk}>Add Bulk Item</Button>
                    </div>
                </div>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Item Name</th>
                            <th>Historical</th>
                            <th>Inventory</th>
                            <th>Change Inventory</th>
                            <th>New Inventory(Estimated)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {bulkItemInventory.map((m, index) =>
                            <tr key={`Row-${m.jobInventoryBulkId}`}>
                                <td>{m.bulkItemName}</td>
                                <td>{m.totalAmount}</td>
                                <td>{m.currentAmount}</td>
                                <td><input value={m.adjustment} onChange={(e) => adjustAmountBulk(index, e.target.value)} type='number'></input></td>
                                <td>{m.NA}</td>
                            </tr>
                        )}
                    </tbody>
                </Table>


                <div className="row mb-2">
                    <div className="col text-right">
                        <Button type='submit' className="enc-button">Save Adjustments</Button>
                    </div>
                </div>
            </form>

            <ListGroup>
                <ListGroup.Item className="click-list">
                    <div className="row">
                        <div className="col">
                            Name
                            </div>
                        <div className="col">
                            Serial Number
                            </div>
                        <div className="col">
                            Status
                            </div>
                    </div>
                </ListGroup.Item>
                {assetInventory.map(m =>
                    <ListGroup.Item key={`${m.serializedItemId}-Asset`} className="click-list" as={Link} to={`/Inventory/Asset/${m.serializedItemId}`}>
                        <div className="row">
                            <div className="col">
                                {m.name}
                            </div>
                            <div className="col">
                                {m.serialNumber}
                            </div>
                            <div className="col">
                                {m.status}
                            </div>
                        </div>
                    </ListGroup.Item>
                )}
            </ListGroup>
        </div>
    )
}
