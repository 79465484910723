import React, { useEffect, useState } from 'react';
import { GetHandOffProfile, SaveHandOffInfo, SaveMobilization, CompleteStepOne } from '../../API/HandOff';
import EditInfo from './EditInfo';
import { Button } from 'react-bootstrap';
import ViewInfo from './ViewInfo';
import EditMobilization from './EditMobilization';
import ViewMobilization from './ViewMobilization';
import 'filepond/dist/filepond.min.css';
import Attachments from '../../Components/Attachments';
import CreateJobScope from './JobScope/CreateJobScope';

export default props => {
    const [handOff, setHandOff] = useState({
        "handOffId": 0,
        "price": "",
        "additionalInfo": "",
        "completed": true
    });
    const [mobilization, setMobilization] = useState({
        "mobilizationId": 0,
        "installType": "",
        "removalType": "",
        "leadTime": "",
        "installDate": new Date(),
        "removalDate": new Date()
    });
    const [editInfo, setEditInfo] = useState(false);
    const [editMobi, setEditMobi] = useState(false);

    useEffect(() => {
        loadHandoff(parseInt(props.jobId))
    }, [props.jobId])

    const CompleteStep = () => {
        CompleteStepOne(parseInt(props.jobId))
            .then(res => {
                if (res.success) {
                    props.checkView()
                }
            })
    }

    const loadHandoff = (id) => {
        GetHandOffProfile(id)
            .then(res => {
                setHandOff(res.handOff);
                let mobi = { ...res.mobilization }
                mobi.removalDate = new Date(res.mobilization.removalDate)
                mobi.installDate = new Date(res.mobilization.installDate)
                setMobilization(mobi)
            })
    }

    const changeHandOff = (key, value) => {
        let s = { ...handOff }
        s[key] = value;
        setHandOff(s)
    }

    const changeMobilization = (key, value) => {
        let s = { ...mobilization }
        s[key] = value;
        setMobilization(s)
    }

    const saveHandOffInfo = () => {
        let payload = {
            handOffId: handOff.handOffId,
            description: handOff.additionalInfo,
            prevailingWage: handOff.prevailingWage
        }
        SaveHandOffInfo(payload)
            .then(res => {
                if (res.success) {
                    setEditInfo(false)
                    props.reload();
                }
            })
    }

    const saveMobi = () => {
        SaveMobilization(mobilization)
            .then(res => {
                if (res.success) {
                    setEditMobi(false)
                }
            })
    }

    return (
        <>
            <CreateJobScope handOffId={handOff.handOffId}/>
            <div className="divider my-3" />
            <div className="row">
                <div className="col">
                    <h4>Mobilization Information</h4>
                </div>
            </div>
            {editMobi ?
                <EditMobilization save={saveMobi} data={mobilization} change={changeMobilization} setEdit={setEditMobi} /> :
                <ViewMobilization data={mobilization} setEdit={setEditMobi} />
            }
            <div className="divider my-3" />
            <div className="row">
                <div className="col">
                    <h4>Additional Information</h4>
                </div>
            </div>
            {editInfo ?
                <EditInfo save={saveHandOffInfo} change={changeHandOff} additionalInfo={handOff.additionalInfo} prevailingWage={handOff.prevailingWage} /> :
                <ViewInfo setEdit={setEditInfo} additionalInfo={handOff.additionalInfo} prevailingWage={handOff.prevailingWage} />}
            <div className="divider my-3" />
            <Attachments 
                jobId={props.jobId}
                jobStep={1}
                editable={true} 
                />
            <div className="divider my-3" />
            <div className="row">
                <div className="col text-right">
                    <Button onClick={CompleteStep} className="enc-button">Completed - Flag for Review</Button>
                </div>
            </div>
        </>
    )
}

