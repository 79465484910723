import React, { useEffect, useState } from 'react';
import { GetAdminBudgetEdit, DeleteBudgetCostLine, DeleteBudgetRevenueLine, DeleteBudgetHour, CreateBudgetCostLine, CreateBudgetRevenueLine, CreateBudgetHourLine } from '../API/Budget';
import { Button, ListGroup, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { DropdownList } from 'react-widgets';

export default props => {
    // Current Item State
    const [hours, setHours] = useState([])
    const [revenue, setRevenue] = useState([])
    const [cost, setCost] = useState([])
    const [categories, setCategories] = useState([])

    // Deletion State
    const [deleteRouter, setDeleteRouter] = useState(-1)
    const [deleteId, setDeleteId] = useState(0)
    const [confirmBox, setConfirmBox] = useState(false)

    // New State
    const [newCost, setNewCost] = useState({
        "category": 0,
        "number": "",
        "name": "",
        "labor": false
    })
    const [newRevenue, setNewRevenue] = useState({
        "number": "",
        "name": "",
    })
    const [newHour, setNewHour] = useState({
        "price": 0,
        "name": ""
    })

    useEffect(() => {
        LoadData()
    }, [])

    const addCost = () => {
        CreateBudgetCostLine(newCost)
            .then(res => {
                if (res.success) {
                    LoadData()
                    setNewCost({
                        "category": 0,
                        "number": "",
                        "name": "",
                        "labor": false
                    })
                }
            })
    }

    const addRevenue = () => {
        CreateBudgetRevenueLine(newRevenue)
            .then(res => {
                if (res.success) {
                    LoadData()
                    setNewRevenue({
                        "number": "",
                        "name": "",
                    })
                }
            })
    }

    const addHour = () => {
        CreateBudgetHourLine(newHour)
            .then(res => {
                if (res.success) {
                    LoadData()
                    setNewHour({
                        "price": 0,
                        "name": ""
                    })
                }
            })
    }

    const editCost = (key, value) => {
        let s = { ...newCost }
        s[key] = value
        setNewCost(s)
    }

    const editRevenue = (key, value) => {
        let s = { ...newRevenue }
        s[key] = value
        setNewRevenue(s)
    }

    const editHour = (key, value) => {
        let s = { ...newHour }
        s[key] = value
        setNewHour(s)
    }

    const BoxData = (itemId, routeId) => {
        setDeleteId(itemId)
        setDeleteRouter(routeId)
        setConfirmBox(true)
    }

    const LoadData = () => {
        GetAdminBudgetEdit()
            .then(res => {
                setHours(res.budgetHours)
                setRevenue(res.budgetRevenue)
                setCost(res.budgetCosts)
                setCategories(res.categories)
            })
    }

    const verifyNumberRevenue = (e) => {
        if (new RegExp('^([0-9]{0,5})$').test(e)) {
            editRevenue('number', e)
        }
    }

    const verifyNumberCost = (e) => {

        if (new RegExp('^([0-9]{0,5})$').test(e)) {
            editCost('number', e)
        }
    }

    const confirmDelete = () => {
        switch (deleteRouter) {
            case 1:
                DeleteBudgetCostLine(deleteId)
                    .then(res => {
                        if (res.success) {
                            LoadData()
                        }
                    })
                break;
            case 2:
                DeleteBudgetRevenueLine(deleteId)
                    .then(res => {
                        if (res.success) {
                            LoadData()
                        }
                    })
                break;
            case 3:
                DeleteBudgetHour(deleteId)
                    .then(res => {
                        if (res.success) {
                            LoadData()
                        }
                    })
                break;
            default:
                break;
        }
        setConfirmBox(false)
        setDeleteRouter(0)
    }


    return (
        <div className="container">
            <ListGroup className="mt-3">
                <ListGroup.Item className="enc-data-list">
                    Budgeting Costs
                </ListGroup.Item>
                <ListGroup.Item className="enc-data-list">
                    <div className="row">
                        <div className="col-lg-2">
                            Number
                        </div>
                        <div className="col-lg-6">
                            Name
                        </div>
                        <div className="col-lg-2">
                            Category
                        </div>
                        <div className="col-lg-1">
                            Labor
                        </div>
                        <div className="col-lg-1">
                        </div>
                    </div>
                </ListGroup.Item>
                {cost.map((m, i) => (
                    <ListGroup.Item key={`costrow-${i}`} className="enc-budget-list">
                        <div className="row">
                            <div className="col-lg-2">
                                {m.number}
                            </div>
                            <div className="col-lg-6">
                                {m.name}
                            </div>
                            <div className="col-lg-2">
                                {m.category}
                            </div>
                            <div className="col-lg-1">
                                {m.labor && "Labor"}
                            </div>
                            <div className="col-lg-1 text-right">
                                <FontAwesomeIcon className="clickable" icon={faTrash} onClick={() => BoxData(m.budgetCostLineId, 1)} />
                            </div>
                        </div>
                    </ListGroup.Item>
                ))}
                <ListGroup.Item>
                    <form action="#" onSubmit={(e) => { e.preventDefault(); addCost() }}>
                        <div className="row mt-3">
                            <div className="col-lg-2">
                                <input
                                    required
                                    placeholder="Number"
                                    value={newCost.number}
                                    type='text'
                                    onChange={e => verifyNumberCost(e.target.value)}
                                    className="enc-input-100" />
                            </div>

                            <div className="col-lg-6">
                                <input
                                    placeholder="Name"
                                    value={newCost.name}
                                    className="enc-input-100"
                                    onChange={e => editCost('name', e.target.value)} />
                            </div>

                            <div className="col-lg-2">
                                <DropdownList
                                    data={categories}
                                    placeholder="Category"
                                    textField="name"
                                    value={newCost.category}
                                    valueField="budgetCategoryId"
                                    onChange={e => editCost('category', e.budgetCategoryId)} />
                            </div>

                            <div className="col-lg-2">
                                <input type="checkbox"
                                    checked={newCost.labor}
                                    onChange={e => editCost('labor', !newCost.labor)} /> Labor
                            </div>
                        </div>
                        <div className="col text-right pt-2  pb-2">
                            <Button type='submit' className="enc-button">Create Budget Cost Line</Button>
                        </div>
                    </form>
                </ListGroup.Item>
            </ListGroup>


            <ListGroup className="mt-5">
                <ListGroup.Item className="enc-data-list">
                    Budget Revenue Lines
                </ListGroup.Item>
                <ListGroup.Item className="enc-data-list">
                    <div className="row">
                        <div className="col-lg-2">
                            Number
                    </div>
                        <div className="col-lg-9">
                            Name
                    </div>
                        <div className="col-lg-1">
                        </div>
                    </div>
                </ListGroup.Item>
                {revenue.map((m, i) => (
                    <ListGroup.Item key={`rev-row-${i}`} className="enc-budget-list">
                        <div className="row">
                            <div className="col-lg-2">
                                {m.number}
                            </div>
                            <div className="col-lg-9">
                                {m.name}
                            </div>
                            <div className="col-lg-1 text-right">
                                <FontAwesomeIcon icon={faTrash} className="clickable" onClick={() => BoxData(m.budgetRevenueLineId, 2)} />
                            </div>
                        </div>
                    </ListGroup.Item>
                ))}
                <ListGroup.Item>
                    <form action="#" onSubmit={(e) => { e.preventDefault(); addRevenue() }}>
                        <div className="row mt-1">
                            <div className="col-lg-2">
                                <input
                                    required
                                    value={newRevenue.number}
                                    type='text'
                                    placeholder="Number"
                                    onChange={e => verifyNumberRevenue(e.target.value)}
                                    className="enc-input-100" />
                            </div>
                            <div className="col-lg-9">
                                <input
                                    required
                                    value={newRevenue.name}
                                    className="enc-input-100"
                                    placeholder="Name"
                                    type='text'
                                    onChange={e => editRevenue('name', e.target.value)} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col text-right pt-2 pb-2">
                                <Button type='submit' className="enc-button">Create Budget Revenue Line</Button>
                            </div>
                        </div>
                    </form>
                </ListGroup.Item>
            </ListGroup>


            <ListGroup className="my-5">
                <ListGroup.Item className="enc-data-list">
                    Budgeting Hour - Calculator
                </ListGroup.Item>
                <ListGroup.Item className="enc-data-list">
                    <div className="row">
                        <div className="col-lg-6">
                            Name
                        </div>
                        <div className="col-lg-3">
                            Price
                        </div>
                        <div className="col-lg-3 text-right">
                        </div>
                    </div>
                </ListGroup.Item>

                {hours.map((m, i) => (
                    <ListGroup.Item key={`budget-row-${i}`} className="enc-budget-list">

                        <div className="row">
                            <div className="col-lg-6">
                                {m.name}
                            </div>
                            <div className="col-lg-3">
                                ${m.price} / Hour
                        </div>
                            <div className="col-lg-3 text-right">
                                <FontAwesomeIcon icon={faTrash} onClick={() => BoxData(m.budgetCostHourPriceId, 3)} className="clickable" />
                            </div>
                        </div>
                    </ListGroup.Item>
                ))}
                <ListGroup.Item>
                    <form action="#" onSubmit={(e) => { e.preventDefault(); addHour() }}>
                        <div className="row">
                            <div className="col-lg-6">
                                <input
                                    required
                                    type='text'
                                    value={newHour.name}
                                    onChange={(e) => editHour('name', e.target.value)}
                                    placeholder="Name"
                                    className="enc-input-100" />
                            </div>
                            <div className="col-lg-3">
                                <input
                                    required
                                    type='number'
                                    value={newHour.price}
                                    step=".01"
                                    placeholder="Price"
                                    onChange={e => editHour('price', parseFloat(e.target.value))}
                                    className="enc-input-100" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col text-right py-2">
                                <Button type='submit' className="enc-button">Create Budget Hour Line</Button>
                            </div>
                        </div>
                    </form>
                </ListGroup.Item>
            </ListGroup>

            <Modal show={confirmBox} onHide={() => setConfirmBox(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setConfirmBox(false)}>
                        Cancel
                </Button>
                    <Button variant="primary" onClick={confirmDelete}>
                        Delete
                </Button>
                </Modal.Footer>
            </Modal>
        </div >
    )
}
