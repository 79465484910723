import APIRequest from './MainAPICall/api';
import config from '../APIConfig';

export const CreateJobReadyItem = (payload) => {
    return APIRequest({
        url: config.baseURL + "/JobReadyInventory/CreateJobReadyItem",
        method: "post",
        body: payload
    });
}

export const GetJobReadyItemList = () => {
    return APIRequest({
        url: config.baseURL + "/JobReadyInventory/GetJobReadyItemList",
        method: "get"
    });
}

export const GetYardJobReadyInventory = (id) => {
    return APIRequest({
        url: config.baseURL + `/JobReadyInventory/YardInventory?id=${id}`,
        method: "get"
    });
}

export const GetJobInventory = (id) => {
    return APIRequest({
        url: config.baseURL + `/JobReadyInventory/JobInventory?id=${id}`,
        method: "get"
    });
}

export const PostJobInventory = (payload) => {
    return APIRequest({
        url: config.baseURL + `/JobReadyInventory/JobInventory`,
        method: "post",
        body: payload
    });
}

export const GetJobReadyItem = (id) => {
    return APIRequest({
        url: config.baseURL + `/JobReadyInventory/JobReadyItem?id=${id}`,
        method: "post"
    });
}

export const DeleteJobReadyItem = (id) => {
    return APIRequest({
        url: config.baseURL + `/JobReadyInventory/DeleteJobReadyItem?id=${id}`,
        method: "Delete"
    });
}
