import React, { useState } from 'react';
import SelectOptions from './SelectOptions';
import BuildTicket from './BuildTicket';
import FinishShopTicket from './FinishShopTicket';

export default props => {
    const [stage, setStage] = useState(0)

    const [yard, setYard] = useState(null)
    const [action, setAction] = useState(null)

    const stageLoad = () => {
        switch (stage) {
            case 0: 
                return <SelectOptions save={Stage1} />
            case 1:
                return <BuildTicket save={() => setStage(2)} yard={yard} action={action} />
            case 2:
                return <FinishShopTicket />
            default:
                return;
        }
    }


    const Stage1 = (yard, action) => {
        setYard(yard)
        setAction(action)
        setStage(1)
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h3>Create Shop Ticket</h3>
                </div>
            </div>
                {stageLoad()}
        </div>
    )
}
