import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { YardList } from '../../API/Inventory';
import { GetShopActions } from '../../API/ShopConversion';
import { DropdownList } from 'react-widgets';

export default props => {
    const [yards, setYards] = useState([])
    const [selectedYard, setSelectedYard] = useState(null)

    const [shopActions, setShopActions] = useState([])
    const [selectedAction, setSelectedAction] = useState(null)


    useEffect(() => {
        YardList()
            .then(res => {
                setYards(res.data)
            })
        GetShopActions()
            .then(res => {
                setShopActions(res.data)
            })
    }, [])

    const Save = () => {
        if(selectedAction !== null && selectedYard !== null){
            props.save(selectedYard, selectedAction)
        }
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    Select Yard for Shop Ticket:
                    <DropdownList
                        placeholder="Select a Yard"
                        data={yards}
                        textField="yardName"
                        value={selectedYard}
                        onChange={e => setSelectedYard(e)}
                    />
                </div>
                <div className="col">
                    Select Type of Shop Ticket:
                    <DropdownList
                        placeholder="Select Type of Ticket"
                        data={shopActions}
                        textField="name"
                        value={selectedAction}
                        onChange={e => setSelectedAction(e)}
                    />
                </div>
            </div>
            <div className="row mt-2">
                <div className="col text-right">
                    <Button className="enc-button" onClick={Save}>Confirm Details</Button>
                </div>
            </div>
        </div>
    )
}
