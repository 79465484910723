import React, { useEffect, useState } from 'react';
import { GetCallList } from '../API/Call';
import { ListGroup, Nav } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';

export default props => {
    const [calls, setCalls] = useState([]);
    const [viewActive, setViewActive] = useState(true);
    useEffect(() => {
        loadCalls(true)
    }, [])

    const loadCalls = (active) => {
        GetCallList(active)
            .then(res => { setCalls(res.data) })
    }

    const GenerateLink = (callInfo) => {
        if (callInfo.active) {
            return `${props.match.url}/${callInfo.callId}`
        } else {
            return `${props.url}/ClosedCall/${callInfo.callId}`
        }
    }

    useEffect(() => {
        loadCalls(viewActive)
    }, [viewActive])

    const handleChange = () => {
        setViewActive(!viewActive)
    }

    return (
        <>
            <Nav variant="tabs" className="my-2" defaultActiveKey={2}>
                <Nav.Item>
                    <Nav.Link as={Link} eventKey={1} to={`/Opportunities`}>Job Opportunities</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey={2} as={NavLink} to={`${props.match.url}/Call`} disabled>Call Opportunities</Nav.Link>
                </Nav.Item>
            </Nav>
            <div className="container">
                <div className="row mb-1">
                    <div className="col text-right">
                        <div className="toggle-switch small-switch">
                            <input
                                type="checkbox"
                                className="toggle-switch-checkbox"
                                id="disabled"
                                checked={viewActive}
                                onChange={handleChange}
                            />
                            <label className="toggle-switch-label" htmlFor="disabled">
                                <span className={"toggle-switch-inner"} />
                                <span
                                    className={"toggle-switch-switch"}
                                />
                            </label>
                        </div>
                    &nbsp; &nbsp;Show Open Calls
                </div>
                </div>
                <ListGroup>
                    <ListGroup.Item className="enc-data-list">
                        Call List
                    </ListGroup.Item>
                    <ListGroup.Item className="enc-data-list">
                        <div className="row">
                            <div className="col">
                                Date
                            </div>
                            <div className="col">
                                Company Name
                            </div>
                            <div className="col">
                                Caller Name
                            </div>
                        </div>
                    </ListGroup.Item>
                    {calls.length === 0 && <ListGroup.Item>No Calls To Display</ListGroup.Item>}
                    {calls.map((m, i) => {
                        let d = new Date(`${m.callTime}`),
                            minutes = d.getMinutes().toString().length === 1 ? '0' + d.getMinutes() : d.getMinutes(),
                            hours = d.getHours().toString().length === 1 ? '0' + d.getHours() : d.getHours(),
                            months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
                        let date = `${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()} - ${hours}:${minutes}`
                        return (
                            <ListGroup.Item key={`Call-${i}`} as={Link} to={GenerateLink(m)} className="click-list">
                                <div className="row">
                                    <div className="col">
                                        {date}
                                    </div>
                                    <div className="col">
                                        {m.companyName}
                                    </div>
                                    <div className="col">
                                        {m.callerName}
                                    </div>
                                </div>
                            </ListGroup.Item>
                        )
                    })}
                </ListGroup>

            </div>
        </>
    )
}
