import React, { useState, useEffect } from 'react';
import { ProposalListNames } from '../../API/Proposal';
import { ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default props => {
    const [prefills, setPrefills] = useState([])
    const [filter, setFilter] = useState("")

    useEffect(() => {
        LoadProposalPrefills()
    }, [])

    const LoadProposalPrefills = () => {
        ProposalListNames()
            .then(res => {
                setPrefills(res.data)
            })
    }

    return (
        <div>
            <div className="container my-5">
                <div className="row mb-2">
                    <div className="col-8" />
                    <div className="col-4 text-right">
                        <input placeholder="Filter" className="enc-input-100" value={filter} onChange={e => setFilter(e.target.value)} />
                    </div>
                </div>
                <ListGroup>
                    <ListGroup.Item className="click-list">
                        Job Scope Combinations
                    </ListGroup.Item>
                    {prefills.filter(x => x.scopeName.toLowerCase().search(filter.toLowerCase()) !== -1).map((m, i) => {
                        return (
                            <ListGroup.Item as={Link} to={`${props.match.url}/${m.leafId}`} className="click-list" key={`Prefill-${i}`}>
                                {m.scopeName}
                            </ListGroup.Item>
                        )
                    })}
                </ListGroup>
            </div>
        </div>
    )
}
