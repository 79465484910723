import React, { useState, useEffect } from 'react';
import { GetContactProfile, CheckAccountEditPermission } from '../API/Account';
import AccountLocations from './AccountLocations';
import { useHistory, Link } from 'react-router-dom';
import { Breadcrumb } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';

export default props => {
    const [contact, setContact] = useState({
        accountContactId: 0,
        accountId: 0,
        fullName: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        enabled: true
    });
    const [locations, setLocations] = useState([]);
    const [edit, setEdit] = useState(false);
    useEffect(() => {
        checkEdit()
        if (props.contactId) {
            loadData(parseInt(props.contactId))
        }
    }, [props.contactId])

    const checkEdit = () => {
        CheckAccountEditPermission()
            .then(res => {
                if (res.success) {
                    setEdit(true)
                }
            })
    }

    const loadData = (id) => {
        GetContactProfile(id)
            .then(res => {
                setContact(res.contactInfo)
                setLocations(res.locations)
            })
    }

    const history = useHistory();

    const handleClick = () => {
        history.push(`${props.url}/${props.accountId}/Contact/${contact.accountContactId}/Edit`)
    }

    return (
        <div className="container-fluid">
            <Breadcrumb className="breadcrumb">
                <li className="breadcrumb-item"><Link to={`${props.url}`}>Accounts List</Link></li>
                <li className="breadcrumb-item"><Link to={`${props.url}/${props.accountId}`}>Account</Link></li>
                <Breadcrumb.Item active>Contact Profile</Breadcrumb.Item>
            </Breadcrumb>
            <div className="row">
                <div className="col-2"></div>
                <div className="col text-center">
                    <h2>{`${contact.firstName} ${contact.lastName}`}</h2>
                </div>
                <div className="col-2 text-right">
                        {edit ? <FontAwesomeIcon icon={faPencilAlt} className="clickable" onClick={handleClick} /> : null}
                </div>
            </div>
            <div className="container">
                <div className="row mt-2">
                    <div className="col text-center">
                        <h5>Phone Number</h5>
                        <a href={`tel:${contact.phoneNumber}`}>{contact.phoneNumber}</a>
                    </div>
                    <div className="col text-center">
                        <h5>Office Number</h5>
                        <a href={`tel:${contact.officeNumber}`}>{contact.officeNumber}</a>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col text-center">
                        <h5>Mobile Number</h5>
                        <a href={`tel:${contact.mobileNumber}`}>{contact.mobileNumber}</a>
                    </div>
                    <div className="col text-center">
                        <h5>Other Number</h5>
                        <a href={`mailto:${contact.otherNumber}`}>{contact.otherNumber}</a>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col text-center">
                        <h5>Email</h5>
                        <a href={`mailto:${contact.email}`}>{contact.email}</a>
                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col text-center">
                    <h4>Contacts Locations</h4>
                </div>
            </div>
            <AccountLocations hidepc={true} account={contact.accountId} url={props.url} location={locations} />
        </div>
    )
}
