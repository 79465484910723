
import config from '../APIConfig';

import APIRequest from './MainAPICall/api';

export const CheckClonePermission = (JobId) => {
    return APIRequest({
        url: `${config.baseURL}/JobClone/CheckClonePermission?JobId=${JobId}`,
        method: "get"
    });
}

export const CloneJob = (payload) => {
    return APIRequest({
        url: `${config.baseURL}/JobClone/CloneJob`,
        method: "Post",
        body: payload
    })
}
