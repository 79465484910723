import TreeMenu from 'react-simple-tree-menu';
import React from 'react';
import { ListGroup, Button, Breadcrumb } from 'react-bootstrap';
import closedIconImg from '../Assets/closedIcon.png';
import openedIconImg from '../Assets/openedIcon.png';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CheckAccountEditPermission, GetAccountListWithChildren } from '../API/Account';
import { Link } from 'react-router-dom';

const DEFAULT_PADDING = 16;
const ICON_SIZE = 8;
const LEVEL_SPACE = 8;

const iconStyle = {
    verticalAlign: 'text-bottom',
};
const openedIcon = <img src={openedIconImg} alt="-" style={iconStyle}></img>;
const closedIcon = <img src={closedIconImg} alt="+" style={iconStyle}></img>;

const ToggleIcon = ({ on }) => <span style={{ marginRight: 8 }}>{on ? openedIcon : closedIcon}</span>;
const ListItem = ({
    level = 0,
    accountNumber,
    accountName,
    hasNodes,
    isOpen,
    label,
    searchTerm,
    openNodes,
    toggleNode,
    matchSearch,
    focused,
    ...props
}) => (
        <ListGroup.Item
            {...props}
            className={"click-list " + (level === 0 ? 'account-bg-1' : level % 2 === 0 ? "account-bg-2" : "account-bg-3")}
            style={{
                paddingLeft: DEFAULT_PADDING + ICON_SIZE + level * LEVEL_SPACE,
                cursor: 'pointer',
                boxShadow: focused ? '0px 0px 5px 0px #222' : 'none',
                zIndex: focused ? 999 : 'unset',
                position: 'relative',
            }}
        >
            <div className="row">
                {hasNodes ? (
                    <div
                        className="col-1"
                        onClick={e => {
                            hasNodes && toggleNode && toggleNode();
                            e.stopPropagation();
                        }}
                    >
                        <ToggleIcon on={isOpen} />
                    </div>
                ) : <div className="col-1" />}
                <div className="col-4">{accountNumber}</div> <div className="col-7">{accountName}</div>
            </div>
        </ListGroup.Item>
    );

export default props => {
    const [data, setData] = useState([])
    const [viewDisabled, setViewDisabled] = useState(false)

    const [editPermission, setEditPermission] = useState(false);


    useEffect(() => {
        const accountData = (layer) => {
            let res = []
            layer.forEach(e => {
                let add = {
                    label: e.accountName,
                    key: `key-${e.accountId}`
                }
                if (e.nodes && e.nodes.length > 0) {
                    add.nodes = accountData(e.nodes)
                }
                let node = { ...e, ...add }
                node['accountid'] = node.accountId
                delete node.accountId
                delete node.enabled
                delete node.parentAccount
                res.push(node)
            })
            return res;
        }

        const LoadAccounts = () => {
            GetAccountListWithChildren(true)
                .then(res => {
                    setData(accountData(res.data))
                })
        }
        LoadAccounts()


        CheckAccountEditPermission()
        .then(res => {
            setEditPermission(res.success)
        })

    }, [])

    const accountData = (layer) => {
        let res = []
        layer.forEach(e => {
            let add = {
                label: e.accountName,
                key: `key-${e.accountId}`
            }
            if (e.nodes && e.nodes.length > 0) {
                add.nodes = accountData(e.nodes)
            }
            let node = { ...e, ...add }
            node['accountid'] = node.accountId
            delete node.accountId
            delete node.enabled
            delete node.parentAccount
            res.push(node)
        })
        return res;
    }

    const ReloadAccounts = () => {
        GetAccountListWithChildren(viewDisabled)
            .then(res => {
                setData(accountData(res.data))
            })
    }

    const handleChange = () => {
        setViewDisabled(!viewDisabled)
        ReloadAccounts()
    }

    const history = useHistory();
    const handleClick = (id) => {
        history.push(`/Accounts/${id}`)
    }
    return (
        <>
            <div className="container-fluid">
                <Breadcrumb className="breadcrumb">
                    <Breadcrumb.Item active> Accounts List </Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className="container remove-child-outline">
                <TreeMenu data={data} debounceTime={500} onClickItem={(props) => handleClick(props.accountid)}>
                    {({ search, items }) => {
                        return (
                            <>
                                <div className="row py-2">
                                    <div className="col">
                                        {editPermission && <Button as={Link} to={`${props.match.url}/New`} className="enc-button">New Account</Button>}
                                    </div>
                                    <div className="col text-right">
                                        <div className="toggle-switch small-switch">
                                            <input
                                                type="checkbox"
                                                className="toggle-switch-checkbox"
                                                id="disabled"
                                                checked={viewDisabled}
                                                onChange={handleChange}
                                            />
                                            <label className="toggle-switch-label" htmlFor="disabled">
                                                <span className={"toggle-switch-inner"} />
                                                <span
                                                    className={"toggle-switch-switch"}
                                                />
                                            </label>
                                        </div>
                                        &nbsp; &nbsp;View Disabled Accounts
                                    </div>
                                    <div className="col">
                                        <input onChange={e => search(e.target.value)} className='enc-input-100' placeholder="Search by Account Name" />
                                    </div>
                                </div>
                                <ListGroup>
                                    <ListGroup.Item className="enc-data-list">
                                        Account List
                                </ListGroup.Item>
                                    <ListGroup.Item className="enc-data-list">
                                        <div className="row">
                                            <div className="col-1"></div>
                                            <div className="col-4">
                                                Account Number
                        </div>
                                            <div className="col-7">
                                                Account Name
                                        </div>
                                        </div>
                                    </ListGroup.Item>

                                    {items.map(({ ...props }, i) => {
                                        return (
                                            <ListItem key={`account-${i}`} {...props} />
                                        )
                                    }
                                    )}
                                </ListGroup>
                            </>
                        )
                    }}
                </TreeMenu>
            </div>
        </>
    )
}