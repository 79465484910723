import React, { useState, useEffect } from 'react';
import SimplifiedCalculator from '../../Calculator/SimplifiedCalculator';
import { ListGroup } from 'react-bootstrap';
import { GetCalculatorResultList } from '../../API/Calculator';

export default props => {
    const [calculations, setCalculations] = useState([])
    useEffect(() => {
        LoadCalculationList(props.jobId)
    }, [props.jobId])

    const LoadCalculationList = (id) => {
        GetCalculatorResultList(id)
            .then(res => {
                setCalculations(res.data)
            })
    }

    return (
        <div>
            <ListGroup>
                <ListGroup.Item className="enc-list">
                    Saved Calculations
                </ListGroup.Item>
                {calculations.length === 0 && <ListGroup.Item>No Calculations Saved</ListGroup.Item>}
                {calculations.map((c,i) => {
                    return (
                        <ListGroup.Item key={`Calc-${i}`}>
                            <>
                                <div className="row">
                                    <div className="col-4">
                                        <label className="mr-1">Calculator: </label>
                                        {c.calculator}
                                    </div>
                                    <div className="col-4">
                                        <label className="mr-1">Length: </label>
                                        {c.length}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <label className="mr-1">Beams #: </label>
                                        {c.beams}
                                    </div>
                                    <div className="col">
                                        <label className="mr-1">Panels #: </label>
                                        {c.panels}
                                    </div>
                                    <div className="col">
                                        <label className="mr-1">Trucks #: </label>
                                        {c.trucks}
                                    </div>
                                </div>
                            </>
                        </ListGroup.Item>
                    )
                })}

            </ListGroup>
            <SimplifiedCalculator jobId={props.jobId} rl={() => LoadCalculationList(props.jobId)} />
        </div>
    )
}
