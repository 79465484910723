import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { GetBudget, SaveUserItemOrder, SaveRevenueOrder, SaveCostOrder } from '../../../API/Budget';
import { ListGroup } from 'react-bootstrap';
export default props => {
    const [scope, setScope] = useState([])

    useEffect(() => {
        LoadBudget(props.jobId);
    }, [props.jobId]);

    const LoadBudget = (id) => {
        GetBudget(parseInt(id))
            .then(res => {
                setScope(res.budgetItems)
            });
    };

    const GetTotals = (m) => {
        let totalRevenue = 0;
        m.budgetRevenue.forEach(e => {
            if (e.revenuePer && e.budgetCalculation && e.budgetCalculationStr && e.budgetCalculationStr.toLowerCase() !== 'none') {
                totalRevenue += (e.revenuePer * e.quantity * e.minCycles)
            } else if (e.revenuePer) {
                totalRevenue += e.revenuePer * e.minCycles
            }
            totalRevenue += e.revenue
        });
        let totalCost = 0;
        m.budgetCost.budgetCostLabor.forEach(e => {
            totalCost += e.cost
        });
        m.budgetCost.budgetCostNonLabor.forEach(e => {
            totalCost += e.cost
        })

        let res = {
            totalCost: totalCost,
            totalRevenue: totalRevenue,
        }

        if (totalRevenue !== 0) {
            res.totalProfit = totalRevenue - totalCost;
            res.totalProfitPercentage = Math.round((totalRevenue - totalCost) / totalRevenue * 100);
        } else {
            res.totalProfit = 0;
            res.totalProfitPercentage = 0;
        }
        return res;
    }

    const [Summary, setSummary] = useState({
        totalCost: 0,
        totalRevenue: 0,
        totalProfit: 0,
        totalProfitPercentage: 0
    });

    const isRevenueNumericInput = (revenueItem) => {
        return (revenueItem.revenueText.length == 0) &&
            (revenueItem["revAltInput"] === undefined || (revenueItem["revAltInput"] !== undefined && revenueItem["revAltInput"] === false));
    }

    const RevDisplay = (revenue) => {
        if (revenue.revenueText.length > 0) {
            return revenue.revenueText;
        }
        if ( 
            revenue.revenuePer !== 0 && 
            (
                revenue.budgetCalculationStr !== null && 
                revenue.budgetCalculationStr !== "" && 
                revenue.budgetCalculationStr.toLowerCase() !== 'none'
            ) && 
            (
                revenue.budgetTimeStr !== null && 
                revenue.budgetTimeStr !== "" && 
                revenue.budgetTimeStr.toLowerCase() !== 'none'
            )
        ) {
            return `$${revenue.revenuePer * revenue.quantity}/${revenue.budgetCalculationStr}/${revenue.budgetTimeStr}`
        }
        if (
            revenue.revenuePer !== 0 && 
            revenue.budgetCalculationStr !== null 
            && revenue.budgetCalculationStr !== "" 
            && revenue.budgetCalculationStr.toLowerCase() !== 'none'
        ) {
            return `$${revenue.revenuePer}/${revenue.budgetCalculationStr}`
        }
        if (
            revenue.revenuePer !== 0 && 
            revenue.budgetTimeStr !== null && 
            revenue.budgetTimeStr !== "" && 
            revenue.budgetTimeStr.toLowerCase() !== 'none'
        ) {
            return `$${revenue.revenuePer}/${revenue.budgetTimeStr}`
        }
        return `$${revenue.revenue}`
    }


    // Sum All Totals and do maths for budget summary.
    useEffect(() => {
        let totalRevenue = 0;
        let totalCost = 0;
        for (let i = 0; i < scope.length; i++) {
            let si = scope[i];
            if (si.budgetRevenue) {
                for (let br = 0; br < si.budgetRevenue.length; br++) {
                    let e = si.budgetRevenue[br];
                    if (e.revenue !== "" && e.revenue !== ".") {
                        totalRevenue += parseFloat(e.revenue)
                    }
                    if (e.revenuePer) {
                        if (e.budgetCalculation && e.budgetCalculationStr && e.budgetCalculationStr !== '' && e.budgetCalculationStr.toLowerCase() !== 'none') {
                            totalRevenue += parseFloat(e.revenuePer * e.quantity * e.minCycles)
                        } else {
                            totalRevenue += parseFloat(e.revenuePer * e.minCycles)
                        }
                    }
                }
            }
            if (si.budgetCost.budgetCostLabor) {
                for (let bcl = 0; bcl < si.budgetCost.budgetCostLabor.length; bcl++) {
                    totalCost += si.budgetCost.budgetCostLabor[bcl].cost
                }
            }
            if (si.budgetCost.budgetCostNonLabor) {
                for (let bcn = 0; bcn < si.budgetCost.budgetCostNonLabor.length; bcn++) {
                    totalCost += si.budgetCost.budgetCostNonLabor[bcn].cost
                }
            }
        }
        let res = {
            totalCost: totalCost,
            totalRevenue: totalRevenue,
        }
        if (totalRevenue !== 0) {
            res.totalProfit = totalRevenue - totalCost;
            res.totalProfitPercentage = Math.round((totalRevenue - totalCost) / totalRevenue * 100);
        } else {
            res.totalProfit = 0;
            res.totalProfitPercentage = 0;
        }
        setSummary(res);
    }, [scope])

    const moveScopeUp = (index) => {
        let s = [...scope];
        if (index === 0) { return; }
        let first = s[index];
        let second = s[index - 1];
        first.order = parseInt(index - 1);
        second.order = parseInt(index);
        s.splice(index - 1, 2, first, second);
        resetScopeOrder(s);
    }
    const moveScopeDown = (index) => {
        let s = [...scope];
        if (index === scope.length - 1) { return; }
        let first = s[index + 1];
        let second = s[index];
        first.order = parseInt(index);
        second.order = parseInt(index + 1);
        s.splice(index, 2, first, second);
        resetScopeOrder(s);
    }
    const resetScopeOrder = (s) => {
        let payload = {
            jobId: parseInt(props.jobId),
            submit: true,
            budgetItems: s
        };
        SaveUserItemOrder(payload).then((res) => {
            if (res.success) {
                LoadBudget(props.jobId);
            }
        });
    }
    
    const moveRevUp = (scopeIndex, revIndex) => {
        let s = [...scope];
        //if (scopeIndex === 0) { return; }
        let item = s[scopeIndex];
        let revItem = item.budgetRevenue;
        if (revIndex === 0) { return; }
        let first = revItem[revIndex];
        let second = revItem[revIndex - 1];
        first.order = parseInt(revIndex - 1);
        second.order = parseInt(revIndex);
        revItem.splice(revIndex - 1, 2, first, second);
        resetRevOrder(item);
    };
    const moveRevDown = (scopeIndex, revIndex) => {
        let s = [...scope];
        //if (scopeIndex === scope.length - 1) { return; }
        let item = s[scopeIndex];
        let revItem = item.budgetRevenue;
        if (revIndex === revItem.length - 1) { return; }
        let first = revItem[revIndex + 1];
        let second = revItem[revIndex];
        first.order = parseInt(revIndex);
        second.order = parseInt(revIndex + 1);
        revItem.splice(revIndex, 2, first, second);
        resetRevOrder(item);
    };
    const resetRevOrder = (r) => {
        let payload = {
            jobId: parseInt(props.jobId),
            submit: true,
            budgetItem: r
        };
        SaveRevenueOrder(payload).then((res) => {
            if (res.success) {
                LoadBudget(props.jobId);
            }
        });
    };

    const moveNonLaborUp = (scopeIndex, costIndex) => {
        let s = [...scope];
        //if (scopeIndex === 0) { return; }
        let item = s[scopeIndex];
        let costItem = item.budgetCost.budgetCostNonLabor;
        if (costIndex === 0) { return; }
        let first = costItem[costIndex];
        let second = costItem[costIndex - 1];
        first.order = parseInt(costIndex - 1);
        second.order = parseInt(costIndex);
        costItem.splice(costIndex - 1, 2, first, second);
        resetCostLaborOrder(costItem);
    };
    const moveNonLaborDown = (scopeIndex, costIndex) => {
        let s = [...scope];
        //if (scopeIndex === scope.length - 1) { return; }
        let item = s[scopeIndex];
        let costItem = item.budgetCost.budgetCostNonLabor;
        if (costIndex === costItem.length - 1) { return; }
        let first = costItem[costIndex + 1];
        let second = costItem[costIndex];
        first.order = parseInt(costIndex);
        second.order = parseInt(costIndex + 1);
        costItem.splice(costIndex, 2, first, second);
        resetCostLaborOrder(costItem);
    };
    
    const moveLaborUp = (scopeIndex, costIndex) => {
        let s = [...scope];
        //if (scopeIndex === 0) { return; }
        let item = s[scopeIndex];
        let costItem = item.budgetCost.budgetCostLabor;
        if (costIndex === 0) { return; }
        let first = costItem[costIndex];
        let second = costItem[costIndex - 1];
        first.order = parseInt(costIndex - 1);
        second.order = parseInt(costIndex);
        costItem.splice(costIndex - 1, 2, first, second);
        resetCostLaborOrder(costItem);
    };
    const moveLaborDown = (scopeIndex, costIndex) => {
        let s = [...scope];
        //if (scopeIndex === scope.length - 1) { return; }
        let item = s[scopeIndex];
        let costItem = item.budgetCost.budgetCostLabor;
        if (costIndex === costItem.length - 1) { return; }
        let first = costItem[costIndex + 1];
        let second = costItem[costIndex];
        first.order = parseInt(costIndex);
        second.order = parseInt(costIndex + 1);
        costItem.splice(costIndex, 2, first, second);
        resetCostLaborOrder(costItem);
    };

    const resetCostLaborOrder = (c) => {
        let payload = {
            jobId: parseInt(props.jobId),
            submit: true,
            budgetItems: c
        };
        SaveCostOrder(payload).then((res) => {
            if (res.success) {
                LoadBudget(props.jobId);
            }
        });
    };

    return (
        <div>
            {scope.map((m, scopeIndex) => {
                let totals = GetTotals(m)
                return (
                    <div className="p1-border p-2 mt-2" key={`Scope-${scopeIndex}`}>
                        <div className="row">
                            <div className="col"></div>

                            <div className="col text-center">
                                Scope Information
                            </div>

                            <div className="col"></div>
                        </div>
                        <div className="row">
                            <div className="col"><h4>{m.optional && "Optional - "}{m.name}</h4></div>
                            <div className="col-3 text-right">Quantity: {m.quantity}</div>
                        </div>
                        <div className="row mt-1">
                            <div className="col">{m.description}</div>
                        </div>
                        <div className="row mt-1">
                            <div className="col">Total Rev: ${totals.totalRevenue}</div>
                            <div className="col text-center">Total Cost: ${totals.totalCost}</div>
                            <div className="col text-center">Total Profit: ${totals.totalProfit}</div>
                            <div className="col text-right">Profit: {totals.totalProfitPercentage}%</div>
                        </div>


                        <ListGroup className="mt-3">
                            <ListGroup.Item className="enc-budget-list">
                                Revenue
                            </ListGroup.Item>
                            <ListGroup.Item className="enc-budget-list">
                                <div className="row">
                                    <div className="col"></div>
                                    <div className="col enc-budget-col">
                                        #
                                    </div>
                                    <div className="col enc-budget-col">
                                        Description
                                    </div>
                                    <div className="col enc-budget-col">
                                        Revenue
                                    </div>
                                    <div className="col enc-budget-col">
                                        Minimum
                                    </div>
                                </div>
                            </ListGroup.Item>
                            
                            {m.budgetRevenue.length === 0 &&
                                <ListGroup.Item>
                                    No Lines Found
                            </ListGroup.Item>
                            }
                            {m.budgetRevenue.filter(rev => !isRevenueNumericInput(rev) || (rev.revenue !== 0 || rev.revenuePer !== 0)).map((r, revIndex) =>
                                <ListGroup.Item key={`BudgetRev-${revIndex}`} className="enc-budget-list">
                                    <div className="row">
                                        <div className="col">
                                            {revIndex !== 0 && <FontAwesomeIcon onClick={() => moveRevUp(scopeIndex, revIndex)} className="clickable" icon={faArrowUp} />}
                                            &nbsp;
                                            <strong>#{revIndex + 1}</strong>
                                            &nbsp;
                                            {revIndex !== m.budgetRevenue.length - 1 && <FontAwesomeIcon onClick={() => moveRevDown(scopeIndex, revIndex)} className="clickable" icon={faArrowDown} />}
                                        </div>

                                        <div className="col enc-budget-col">
                                            {r.number}
                                        </div>
                                        <div className="col enc-budget-col">
                                            {r.name}
                                        </div>
                                        <div className="col enc-budget-col">
                                            {RevDisplay(r)}
                                        </div>
                                        <div className="col enc-budget-col">
                                            {r.minCycles}
                                        </div>
                                    </div>
                                    {r.notes && 
                                        <>
                                            <div className="divider my-2" />
                                            <div className="row">
                                                <div className="col">
                                                    <small>Notes:</small>
                                                    <div>
                                                        {r.notes}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </ListGroup.Item>
                            )}
                        </ListGroup>


                        <ListGroup className="mt-3">
                            <ListGroup.Item className="enc-budget-list">
                                <div>
                                    <div className="col">
                                        Non-Labor
                                    </div>
                                </div>
                            </ListGroup.Item>
                            <ListGroup.Item className="enc-budget-list">
                                <div className="row">
                                    <div className="col"></div>
                                    <div className="col enc-budget-col">Category</div>
                                    <div className="col enc-budget-col">#</div>
                                    <div className="col enc-budget-col">Description</div>
                                    <div className="col enc-budget-col">Summary Cost</div>
                                </div>
                            </ListGroup.Item>
                            {m.budgetCost.budgetCostNonLabor.length === 0 &&
                                <ListGroup.Item>
                                    No Lines Found
                            </ListGroup.Item>
                            }
                            {m.budgetCost.budgetCostNonLabor.filter(c => c.cost !== 0).map((b, budgetIndex) =>
                                <ListGroup.Item key={`cost-${budgetIndex}`} className="enc-budget-list">
                                    <div className="row" key={`BudgetRev-${budgetIndex}`}>
                                        <div className="col">
                                            {budgetIndex !== 0 && <FontAwesomeIcon onClick={() => moveNonLaborUp(scopeIndex, budgetIndex)} className="clickable" icon={faArrowUp} />}
                                            &nbsp;
                                            <strong>#{budgetIndex + 1}</strong>
                                            &nbsp;
                                            {budgetIndex !== m.budgetCost.budgetCostNonLabor.length - 1 && <FontAwesomeIcon onClick={() => moveNonLaborDown(scopeIndex, budgetIndex)} className="clickable" icon={faArrowDown} />}
                                        </div>

                                        <div className="col enc-budget-col">
                                            {b.category}
                                        </div>
                                        <div className="col enc-budget-col">
                                            {b.number}
                                        </div>
                                        <div className="col enc-budget-col">
                                            {b.name}
                                        </div>
                                        <div className="col enc-budget-col">
                                            ${b.cost}
                                        </div>
                                    </div>
                                    {b.notes &&
                                        <>
                                            <div className="divider my-2" />
                                            <div className="row">
                                                <div className="col">
                                                    <small>Notes:</small>
                                                    <div>
                                                        {b.notes}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </ListGroup.Item>
                            )}
                        </ListGroup>



                        <ListGroup className="mt-3">
                            <ListGroup.Item className="enc-budget-list">
                                <div>
                                    <div className="col">
                                        Labor
                                    </div>
                                </div>
                            </ListGroup.Item>
                            <ListGroup.Item className="enc-budget-list">
                                <div className="row">
                                    <div className="col"></div>
                                    <div className="col enc-budget-col">Category</div>
                                    <div className="col enc-budget-col">#</div>
                                    <div className="col enc-budget-col">Description</div>
                                    <div className="col enc-budget-col">Summary Cost</div>
                                </div>
                            </ListGroup.Item>
                            {m.budgetCost.budgetCostLabor.length === 0 &&
                                <ListGroup.Item>
                                    No Lines Found
                            </ListGroup.Item>
                            }
                            {m.budgetCost.budgetCostLabor.filter(bcl => bcl.cost !== 0).map((bb, bbudgetIndex) =>
                                <ListGroup.Item key={`labor-${bbudgetIndex}`} className="enc-budget-list">
                                    <>
                                        <div className="row" key={`BudgetRev-${bbudgetIndex}`}>
                                            <div className="col">
                                                {bbudgetIndex !== 0 && <FontAwesomeIcon onClick={() => moveLaborUp(scopeIndex, bbudgetIndex)} className="clickable" icon={faArrowUp} />}
                                                &nbsp;
                                                <strong>#{bbudgetIndex + 1}</strong>
                                                &nbsp;
                                                {bbudgetIndex !== m.budgetCost.budgetCostLabor.length - 1 && <FontAwesomeIcon onClick={() => moveLaborDown(scopeIndex, bbudgetIndex)} className="clickable" icon={faArrowDown} />}
                                            </div>

                                            <div className="col enc-budget-col">
                                                {bb.category}
                                            </div>
                                            <div className="col enc-budget-col">
                                                {bb.number}
                                            </div>
                                            <div className="col enc-budget-col">
                                                {bb.name}
                                            </div>
                                            <div className="col enc-budget-col">
                                                ${bb.cost}
                                            </div>
                                        </div>
                                        {bb.notes &&
                                            <>
                                                <div className="divider my-2" />
                                                <div className="row">
                                                    <div className="col">
                                                        <small>Notes:</small>
                                                        <div>
                                                            {bb.notes}
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        <div className="divider my-2" />
                                        <ListGroup>
                                            <ListGroup.Item className="enc-budget-list">
                                                Hours Calculation
                                            </ListGroup.Item>
                                            <ListGroup.Item className="enc-budget-list">
                                                <div className='row'>
                                                    <div className='col'>Name</div>
                                                    <div className='col enc-budget-col'>Price</div>
                                                    <div className='col enc-budget-col'>Hours</div>

                                                </div>
                                            </ListGroup.Item>
                                            {bb.hours.map((hr, hourIndex) =>
                                                <ListGroup.Item key={`hr-${bbudgetIndex}-${hourIndex}`} className="enc-budget-list">
                                                    <div className='row'>
                                                        <div className='col'>{hr.name}</div>
                                                        <div className='col enc-budget-col'>${hr.price} / Hour</div>
                                                        <div className='col enc-budget-col'>{hr.hours}</div>

                                                    </div>
                                                </ListGroup.Item>
                                            )}
                                        </ListGroup>
                                    </>
                                </ListGroup.Item>
                            )}
                        </ListGroup>

                        <ListGroup className="mt-3">
                            <ListGroup.Item className="enc-budget-list">
                                <div>
                                    <div className="col">
                                        Tax
                                    </div>
                                </div>
                            </ListGroup.Item>
                            <ListGroup.Item className="enc-budget-list">
                                <div className="row">
                                    <div className="col">Cost</div>
                                </div>
                            </ListGroup.Item>
                            {m.budgetTax.length === 0 &&
                                <ListGroup.Item>
                                    No Lines Found
                            </ListGroup.Item>
                            }
                            {m.budgetTax.filter(c => c.cost !== 0).map((b, budgetIndex) =>
                                <ListGroup.Item key={`tax-${budgetIndex}`} className="enc-budget-list">
                                    <div className="row" key={`BudgetTax-${budgetIndex}`}>
                                        <div className="col">
                                            {b.description ?
                                                <>Tax for {b.description} - ${b.cost}</>
                                                :
                                                <>Tax - ${b.cost}</>
                                            }
                                        </div>
                                    </div>
                                    {b.notes &&
                                        <>
                                            <div className="divider my-2" />
                                            <div className="row">
                                                <div className="col">
                                                    <small>Notes:</small>
                                                    <div>
                                                        {b.notes}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </ListGroup.Item>
                            )}
                        </ListGroup>

                        <ListGroup className="mt-3">
                            <ListGroup.Item className="enc-budget-list">
                                <div>
                                    <div className="col">
                                        Additional Sublines
                                    </div>
                                </div>
                            </ListGroup.Item>
                            <ListGroup.Item className="enc-budget-list">
                                <div className="row">
                                    <div className="col">Cost</div>
                                </div>
                            </ListGroup.Item>
                            {m.budgetAdditionalLines.length === 0 &&
                                <ListGroup.Item>
                                    No Lines Found
                            </ListGroup.Item>
                            }
                            {m.budgetAdditionalLines.map((b, budgetIndex) =>
                                <ListGroup.Item key={`addline-${budgetIndex}`} className="enc-budget-list">
                                    <div className="row" key={`BudgetTax-${budgetIndex}`}>
                                        <div className="col">
                                            {b.optionalCost ?
                                                <>Tax for {b.description} - ${b.cost}</>
                                                :
                                                <>Tax - ${b.optionalCost}</>
                                            }
                                        </div>
                                    </div>
                                    {b.additionalText &&
                                        <>
                                            <div className="divider my-2" />
                                            <div className="row">
                                                <div className="col">
                                                    <small>Text:</small>
                                                    <div>
                                                        {b.additionalText}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </ListGroup.Item>
                            )}
                        </ListGroup>



                    </div>
                )
            })}
            <div className="container border p-2 mt-2">
                <div className="row">
                    <div className="col text-center">
                        <h4>Summary</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col">Total Rev: ${Summary.totalRevenue}</div>
                    <div className="col">Total Cost: ${Summary.totalCost}</div>
                    <div className="col">Total Profit: ${Summary.totalProfit}</div>
                    <div className="col">Profit: {Summary.totalProfitPercentage}%</div>
                </div>
            </div>
        </div >
    )
}