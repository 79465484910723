import React, { useEffect, useState } from 'react';
import PhoneList from './PhoneList'
import EmailList from './EmailList';
import '../css/User.css';
import { GetUserSignature } from '../API/User';
import { Modal, Button, ListGroup } from "react-bootstrap";
import { FilePond } from 'react-filepond';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faDownload } from "@fortawesome/free-solid-svg-icons";
import {
    GetUserDocumentList,
    DeleteUserDocument,
    GetUserDocument,
} from "../API/User";
import config from '../APIConfig';

export default props => {
    const [SignatureData, SetSignatureData] = useState("")
    const [fileName, setFileName] = useState("");
    const [documents, setDocuments] = useState([]);
    const [confirm, setConfirm] = useState({
        name: "",
        id: 0,
    });
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (props.userInfo && props.userInfo.userId) {
            LoadUserSignature(props.userInfo.userId)
        }
    }, [props.userInfo, props.userInfo.userId])

    useEffect(() => {
        loadDocuments();
    }, []);

    const loadDocuments = () => {
        GetUserDocumentList().then((res) => {
            setDocuments(res.data);
        });
    };

    const LoadUserSignature = (id) => {
        GetUserSignature(id)
            .then(res => {
                const convertUrl = () => {
                    return new Promise((resolve, reject) => {
                        var reader = new FileReader();
                        reader.onload = () => { resolve(reader.result) }
                        reader.readAsDataURL(res)
                    })
                }
                if (res.size !== 0) {
                    convertUrl()
                        .then(res => {
                            SetSignatureData(res)
                        })
                }
            })
    }

    const displayUserImage = () => {
        if (props.userInfo.employeePhoto) {
            return <img className="profile-image" src={props.userInfo.employeePhoto} alt="Employee" />
        } else {
            return <img className="profile-image" src="../images/user.png" alt="Environmental Noise Control" />
        }
    }

    // FilePondConfiguration //
    //const FilePondSettings = (filename) => {
    const FilePondSettings = () => {
        let token;

        const LS = localStorage.getItem("BAENCToken");
        const SS = sessionStorage.getItem("BAENCToken");

        if (LS) {
            token = LS;
        } else if (SS) {
            token = SS;
        }

        return {
            url: "/api/User",
            process: {
                url: "/SaveUserDocument",
                method: "post",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                ondata: (formData) => {
                    //formData.append("userDocumentName", filename);
                    return formData;
                },
                onload: (res) => {
                    loadDocuments();
                    //setFileName("");
                },
            },
            load: null,
            revert: null,
            restore: null,
            fetch: null,
            patch: null,
        };
    };

    const confirmDelete = (obj) => {
        setShow(true);
        setConfirm({
            id: obj.userDocumentId,
            name: obj.name,
        });
    };

    const deleteDoc = () => {
        setShow(false);
        DeleteUserDocument(confirm.id).then((res) => {
            if (res.success) {
                loadDocuments();
            }
        });
    };

    const downloadDoc = (id, name) => {
        let download = require("downloadjs");

        let token;

        const LS = localStorage.getItem("BAENCToken");
        const SS = sessionStorage.getItem("BAENCToken");

        if (LS) {
            token = LS;
        } else if (SS) {
            token = SS;
        }

        fetch(`${config.baseURL}/User/GetUserDocument?documentId=${id}`, {
            method: "get",
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(res => res.blob())
        .then(blob => {
            download(blob, name)
        })
    };

    const uploadDocuments = () => {
        return (
            <div className="container p-2 p1-border">
                <div className="row mt-1">
                    {/*<div className="col-lg-5 my-auto">*/}
                    {/*    <input*/}
                    {/*        type="text"*/}
                    {/*        className="enc-input-100 my-auto"*/}
                    {/*        value={fileName}*/}
                    {/*        onChange={(e) => setFileName(e.target.value)}*/}
                    {/*        placeholder="Display Name"*/}
                    {/*        required*/}
                    {/*    />*/}
                    {/*</div>*/}
                    {/*<div className="col-lg-7">*/}
                    {/*    {fileName !== "" ? (*/}
                    {/*        <FilePond*/}
                    {/*            files={[]}*/}
                    {/*            className="mt-3"*/}
                    {/*            allowMultiple={false}*/}
                    {/*            server={FilePondSettings(fileName)}*/}
                    {/*            credits={false}*/}
                    {/*        />*/}
                    {/*    ) : (*/}
                    {/*        <strong>*/}
                    {/*            Please add a document name before uploading file.*/}
                    {/*        </strong>*/}
                    {/*    )}*/}
                    {/*</div>*/}
                    <div className="col-lg">
                        <FilePond
                            files={[]}
                            className="mt-3"
                            allowMultiple={false}
                            server={FilePondSettings()}
                            credits={false}
                        />
                    </div>
                </div>
            </div>
        )
    }

    const listDocuments = () => {
        return (
            <ListGroup>
                <ListGroup.Item className="enc-list mt-1">
                    Current Documents
                </ListGroup.Item>
                {documents.length === 0 && (
                    <ListGroup.Item className="enc-list">
                        No Current Documents
                    </ListGroup.Item>
                )}
                {documents.map((m) => {
                    return (
                        <ListGroup.Item key={`UserDocument-${m.userDocumentId}`}>
                            <div className="row" id={m.userDocumentId}>
                                <div className="col-sm-1 my-auto">
                                    <FontAwesomeIcon
                                        className="clickable"
                                        icon={faDownload}
                                        onClick={() => downloadDoc(m.userDocumentId, m.filename)}
                                    />
                                </div>
                                <div className="col-sm-10 my-auto">
                                    <h5> {m.name}</h5>
                                </div>
                                <div className="col-sm-1 my-auto">
                                    <FontAwesomeIcon
                                        className="clickable"
                                        icon={faTrash}
                                        onClick={() => confirmDelete(m)}
                                    />{" "}
                                </div>
                            </div>
                        </ListGroup.Item>
                    );
                })}
            </ListGroup>
        )
    }

    const documentModal = () => {
        return (
            <Modal show={show}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Confirm you would like to permanently delete {confirm.name}?
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className="enc-button"
                        onClick={() => {
                            setShow(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button className="enc-button delete" onClick={deleteDoc}>
                        DELETE
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    return (
        <div className="container">
            <div className="row mt-4">
                <div className="col text-center">
                    <div className="profile-top-nti">
                        <div>{displayUserImage()}</div>
                        <h4>{props.userInfo.fullName}</h4>
                        <h5>{props.userInfo.positionTitle}</h5>
                    </div>
                    <div className="my-2">
                        {`@${props.userInfo.username}`}
                    </div>
                </div>
            </div>
            <div className="row sep-2px-bt">
                <div className="col-lg">
                    <PhoneList savePhone={props.savePhone} userId={props.userInfo.userId} phones={props.userInfo.phoneNumbers} />
                </div>
                <div className="col-lg">
                    <EmailList saveEmail={props.saveEmail} emails={props.userInfo.emails} />
                </div>
            </div>
            <div className="divider my-3" />
            <div className="row mt-2">
                <div className="col text-center">
                    <h4>User Documents</h4>
                </div>
            </div>
            <div className="row mb-4">
                <div className="col text-center">
                    {uploadDocuments()}
                    {listDocuments()}
                </div>
            </div>
            <div className="divider my-3" />
            <div className="row mt-2">
                <div className="col text-center">
                    <h4>Stored Signature</h4>
                </div>
            </div>
            <div className="row mb-4">
                <div className="col text-center">
                    {SignatureData ?
                        <img className="img-fluid" src={SignatureData} alt="Signature" /> :
                        "No Signature Saved"}
                </div>
            </div>

            {documentModal()}
        </div>
    )
}