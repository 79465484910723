import APIRequest from './MainAPICall/api';
import config from '../APIConfig';

export const SaveEmployee = (payload) => {
    return APIRequest({
        url: config.baseURL + "/Employee/SaveEmployee",
        method: "post",
        body: payload
    });
}

export const DeleteEmployee = (id) => {
    return APIRequest({
        url: config.baseURL + "/Employee/DeleteEmployee",
        method: "post",
        body: { id: id }
    });
}

export const GetEmployeeList = (payload) => {
    return APIRequest({
        url: config.baseURL + "/Employee/GetEmployeeList",
        method: "post",
        body: payload
    });
}

export const GetEmployee = (id) => {
    return APIRequest({
        url: config.baseURL + "/Employee/GetEmployee",
        method: "post",
        body: { id: id }
    });
}

