import React, { useEffect, useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import { GetAttachmentList } from '../../API/Proposal';
import { Link } from 'react-router-dom';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

export default props => {
    const [attachmentList, setAttachmentList] = useState([]);

    const [attachmentName, setAttachmentName] = useState("");

    registerPlugin(FilePondPluginFileValidateType)

    useEffect(() => {
        LoadAttachments();
    }, [])

    // FilePondConfiguration //
    const FilePondSettings = (reqPicId) => {
        let token;

        const LS = localStorage.getItem("BAENCToken");
        const SS = sessionStorage.getItem("BAENCToken");

        if (LS) {
            token = LS;
        } else if (SS) {
            token = SS;
        }

        return (
            {
                url: "/api/Proposal",
                process: {
                    url: "/UploadAttachment",
                    method: "post",
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    ondata: (formData) => {
                        formData.append("attachmentName", reqPicId);
                        return formData;
                    },
                    onload: (res) => {
                        LoadAttachments();
                        setAttachmentName("");
                    }
                },
                load: null,
                revert: null,
                restore: null,
                fetch: null,
                patch: null
            })
    }

    const LoadAttachments = () => {
        GetAttachmentList()
            .then(res => {
                setAttachmentList(res.data)
            })
    }

    return (
        <div className="container">
            <ListGroup>
                <ListGroup.Item className="enc-list">
                    Attachments List
                </ListGroup.Item>
                {attachmentList.length === 0 &&
                    <ListGroup.Item >No Attachments Found</ListGroup.Item>
                }
                {attachmentList.map((m, i) => {
                    return (
                        <ListGroup.Item className="click-list" as={Link} to={`${props.match.url}/${m.prefillAttachmentId}`} key={`note-${i}`}>
                            {m.attachmentName}
                        </ListGroup.Item>
                    )
                })}
            </ListGroup>
            <div className="divider my-3" />
            <div className="row mt-3">
                <div className="col">
                    <h4>Upload New Attachment (PDF ONLY)</h4>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-3">
                    Attachment Name:
                    </div>
                <div className="col">
                    <input type='text' value={attachmentName} onChange={e => setAttachmentName(e.target.value)} className="enc-input-100" />
                </div>
            </div>
            <div className="row mt-2">
                <div className="col text-center">
                    {attachmentName !== "" ? <FilePond
                        files={[]}
                        acceptedFileTypes={['application/pdf']}
                        className="mt-3"
                        allowMultiple={false}
                        server={FilePondSettings(attachmentName)}
                        credits={false}

                    /> : <strong>Please add an attachment name before uploading file.</strong>}
                </div>
            </div>
        </div>
    )
}
