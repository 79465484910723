import React from 'react';

export default props => {
    return (
        <div className="row">
            <div className="col text-center">
                <h3>Shop Ticket Created</h3>
            </div>
        </div>
    )
}
