import APIRequest from './MainAPICall/api';
import config from '../APIConfig';

export const GetSurveyFormMap = (id) => {
    return APIRequest({
        url: config.baseURL + `/SurveyFormMap/GetSurveyFormMap?FileId=${id}`,
        method: "post",
        body: { id: id }
    });
}

export const SaveSurveyFormMap = (payload) => {
    return APIRequest({
        url: config.baseURL + "/SurveyFormMap/SaveSurveyFormMap",
        method: "post",
        body: payload
    });
}

export const DeleteSurveyFormMap = (id) => {
    return APIRequest({
        url: config.baseURL + "/SurveyFormMap/DeleteSurveyFormMap",
        method: "post",
        body: { id: id }
    });
}
