import React, { useState, useEffect } from 'react';
import { CheckEmailPermission } from '../API/User';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import { faStar as FullStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as EmptyStar } from '@fortawesome/free-regular-svg-icons';
import { Modal, ListGroup, Button } from 'react-bootstrap';

export default props => {
    const [editPerm, setEditPerm] = useState(false);
    const [editEmail, setEditEmail] = useState({
        userId: 0,
        emailId: 0,
        primary: false,
        email: "",
    });
    const [editing, setEditing] = useState(false);

    useEffect(() => {
        VerifyemailEdit()
    }, [])

    const VerifyemailEdit = () => {
        CheckEmailPermission()
            .then(res => {
                setEditPerm(res.success)
            })
    }

    const saveEmail = () => {
        props.saveEmail(editEmail)
        setEditing(false)
    }

    const handleEditClick = (obj) => {
        setEditing(true)
        setEditEmail(obj)
    }

    const makeNewEmail = () => {
        setEditing(true)
        setEditEmail({
            userId: 0,
            emailId: 0,
            primary: false,
            email: "",
        })
    }
    const changeEditEmail = (e) => {
        let newemail = { ...editEmail }
        newemail[e.target.name] = e.target.value
        setEditEmail(newemail)
    }



    const changePrimary = () => {
        let newemail = { ...editEmail }
        newemail.primary = !editEmail.primary
        setEditEmail(newemail)
    }

    return (
        <div className="container-fluid">
            <ListGroup>
                <ListGroup.Item className="enc-list">
                    <div className="row">
                        <div className="col">
                            <h5>Email List:</h5>
                        </div>
                        <div className="col text-right">
                            <FontAwesomeIcon icon={faPlus} onClick={makeNewEmail} className="clickable" />
                        </div>
                    </div>
                </ListGroup.Item>
                {Object.values(props.emails).map((email, index) => {
                    return (
                        <ListGroup.Item className="enc-list" key={`email-list-${index}`}>
                            <div className="row">
                                <div className="col-1">
                                    {email.primary ? <FontAwesomeIcon icon={FullStar} /> : null}
                                </div>
                                <div className="col-9">{email.email}</div>
                                <div className="col-1">
                                    {editPerm ? <FontAwesomeIcon icon={faPencilAlt} className="clickable" onClick={() => handleEditClick(email)} /> : null}
                                </div>
                            </div>
                        </ListGroup.Item>

                    )
                })}
            </ListGroup>



            <Modal show={editing} onHide={() => setEditing(false)}>
                <form action="#" onSubmit={(e) => { e.preventDefault(); saveEmail() }}>
                    <Modal.Header closeButton>
                        <Modal.Title>{editEmail.emailId === 0 ? "New Email" : "Edit Email"}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="row mb-1">
                            <div className="col-2">{editEmail.primary ? <FontAwesomeIcon icon={FullStar} onClick={changePrimary} className="clickable" /> : <FontAwesomeIcon icon={EmptyStar} onClick={changePrimary} className="clickable" />}</div>
                            <div className="col-10"><input className="enc-input-pe" type="email" name="email" onChange={changeEditEmail} value={editEmail.email} required /></div>
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setEditing(false)}>Cancel</Button>
                        <Button type="submit" className="enc-button ml-2" value="Save">Save</Button>
                    </Modal.Footer>
                </form>
            </Modal>

        </div>
    )
}