import React, { useState, useEffect } from 'react';
import { GetJobTransferList, GetTransferList } from '../API/InventoryTransfer';
import Grid from '../Components/Grid/Grid';

export default props => {
    const [transfers, setTransfers] = useState([])
    useEffect(() => {
        if (props.id) {
            GetJobTransferList(props.type, props.id)
                .then(res => {
                    setTransfers(res.data)
                })
        } else {
            GetTransferList(props.type)
                .then(res => {
                    setTransfers(res.data)
                })
        }
    }, [props.type, props.id])

    return (
        <div>
                <Grid
                    headerName="Ticket List"
                    headers={[{ key: 'date', name: 'Date', sortType:"date" }, { key: 'origin', name: 'Origin' }, { key: 'destination', name: 'Destination' }, { key: 'transferType', name: 'Type' }]}
                    data={transfers}
                    url={props.match.url}
                    urlId={"transferRequestId"}
                    clickRow={true}
                />
        </div>
    )
}
