import APIRequest from './MainAPICall/api';
import config from '../APIConfig';

export const GetBudget = (id) => {
    return APIRequest({
        url: config.baseURL + "/Budget/GetBudget",
        method: "post",
        body: {id: id}
    });
}

export const SaveBudget = (payload) => {
    return APIRequest({
        url: config.baseURL + "/Budget/SaveBudget",
        method: "post",
        body: payload
    });
}

export const GetBudgetingHoursList = () => {
    return APIRequest({
        url: config.baseURL + "/Budget/GetBudgetingHoursList",
        method: "get"
    });
}

export const GetAdminBudgetEdit = () => {
    return APIRequest({
        url: config.baseURL + "/Budget/GetAdminBudgetEdit",
        method: "get"
    });
}

export const DeleteBudgetCostLine = (id) => {
    return APIRequest({
        url: config.baseURL + "/Budget/DeleteBudgetCostLine",
        method: "post",
        body: {id:id}
    });
}

export const DeleteBudgetRevenueLine = (id) => {
    return APIRequest({
        url: config.baseURL + "/Budget/DeleteBudgetRevenueLine",
        method: "post",
        body: {id:id}
    });
}

export const DeleteBudgetHour = (id) => {
    return APIRequest({
        url: config.baseURL + "/Budget/DeleteBudgetHour",
        method: "post",
        body: {id:id}
    });
}

export const CreateBudgetCostLine = (payload) => {
    return APIRequest({
        url: config.baseURL + "/Budget/CreateBudgetCostLine",
        method: "post",
        body: payload
    });
}

export const CreateBudgetHourLine = (payload) => {
    return APIRequest({
        url: config.baseURL + "/Budget/CreateBudgetHourLine",
        method: "post",
        body: payload
    });
}
export const CreateBudgetRevenueLine = (payload) => {
    return APIRequest({
        url: config.baseURL + "/Budget/CreateBudgetRevenueLine",
        method: "post",
        body: payload
    });
}

export const GetBudgetCalculations = () => {
    return APIRequest({
        url: config.baseURL + "/Budget/BudgetCalculations",
        method: "get"
    });
}

export const GetBudgetTimes = () => {
    return APIRequest({
        url: config.baseURL + "/Budget/BudgetTimes",
        method: "get"
    });
}

export const SaveUserItemOrder = (payload) => {
    return APIRequest({
        url: config.baseURL + "/Budget/SaveUserItemOrder",
        method: "post",
        body: payload
    });
}

export const SaveRevenueOrder = (payload) => {
    return APIRequest({
        url: config.baseURL + "/Budget/SaveRevenueOrder",
        method: "post",
        body: payload
    });
}

export const SaveCostOrder = (payload) => {
    return APIRequest({
        url: config.baseURL + "/Budget/SaveCostOrder",
        method: "post",
        body: payload
    });
}
