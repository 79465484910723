import React, { useState, useEffect } from 'react';
import { Button, ListGroup } from 'react-bootstrap';
import { GetSerializedItemAndStatusList, OutForRepairList } from '../../../API/AssetInventory';
import { Link } from 'react-router-dom';


export default props => {
    const [items, setItems] = useState([])
    const [repair, setRepair] = useState(false)

    useEffect(() => {
        if(repair){
            LoadRepairList();
        } else {
            LoadInventory();
        }
    }, [repair])

    const LoadInventory = () => {
        GetSerializedItemAndStatusList()
            .then(res => {
                setItems(res.data)
            })
    }

    const LoadRepairList = () => {
        OutForRepairList()
        .then(res => {
            setItems(res.data)
        })
    }

    return (
        
        <div className="container my-5">
            <div className="row">
                <div className="col">
                    <div className="toggle-switch small-switch">
                        <input
                            type="checkbox"
                            className="toggle-switch-checkbox"
                            id="disabled"
                            checked={repair}
                            onChange={e => setRepair(!repair)}
                        />
                        <label className="toggle-switch-label" htmlFor="disabled">
                            <span className={"toggle-switch-inner"} />
                            <span
                                className={"toggle-switch-switch"}
                            />
                        </label>
                    </div>
                    &nbsp; &nbsp;View Assets Out for Repair
                </div>
                <div className="col text-right">
                    <Button as={Link} to={`${props.match.url}/CreateAsset`} className="enc-button">Create Asset</Button>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col">
                    <ListGroup>
                        <ListGroup.Item className="enc-data-list">
                            Asset List
                        </ListGroup.Item>
                        <ListGroup.Item className="enc-data-list">
                            <div className="row">
                                <div className="col">
                                    Name
                                </div>
                                <div className="col">
                                    Serial Number
                                </div>
                                <div className="col">
                                    Status
                                </div>
                                <div className="col">
                                    Location
                                </div>
                                <div className="col">
                                    Last Activity
                                </div>
                            </div>
                        </ListGroup.Item>
                        {items.map(i =>
                            <ListGroup.Item key={`Asset-${i.serializedItemId}`} as={Link} to={`${props.match.url}/${i.serializedItemId}`} className="click-list">
                                <div className="row">
                                    <div className="col">
                                        {i.name}
                                    </div>
                                    <div className="col">
                                        {i.serialNumber}
                                    </div>
                                    <div className="col">
                                        {i.status}
                                    </div>
                                    <div className="col">
                                        {i.location}
                                    </div>
                                    <div className="col">
                                        {i.lastActivity}
                                    </div>
                                </div>
                            </ListGroup.Item>
                        )}
                    </ListGroup>
                </div>
            </div>
        </div>
    )
}
