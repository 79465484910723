import APIRequest from './MainAPICall/api';
import config from '../APIConfig';

export const GetBudgetTemplateItemsList = () => {
    return APIRequest({
        url: config.baseURL + "/BudgetTemplate/BudgetTemplateItemsList",
        method: "get",
    });
}

export const GetBudgetTemplate = (id) => {
    return APIRequest({
        url: config.baseURL + `/BudgetTemplate/BudgetTemplate?id=${id}`,
        method: "get",
    });
}


export const PostBudgetTemplate = (payload) => {
    return APIRequest({
        url: config.baseURL + `/BudgetTemplate/BudgetTemplate`,
        method: "post",
        body: payload
    });
}

export const PostBulkTemplate = (payload) => {
    return APIRequest({
        url: config.baseURL + `/BudgetTemplate/BulkTemplate`,
        method: "post",
        body: payload
    });
}

