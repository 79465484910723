import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { SaveJobNote, GetJobNote } from '../API/Jobs';
import FloatingLabel from '../Components/FloatingLabel';

export default props => {
    const [note, setNote] = useState({
        "title": "",
        "description": "",
        "jobId": props.jobId
    });

    useEffect(()=>{
        if(props.noteId){
            loadNote(parseInt(props.noteId))
        }
    },[props.noteId])

    const loadNote = (id) => {
        GetJobNote(id)
        .then(res=>{
            setNote(res.data)
        })
    }

    let history = useHistory();

    const saveNote = () => {
        SaveJobNote(note)
        .then(res=>{
            if(res.success){
                history.goBack()
            }
        })
    }

    const editNote = (key, value) => {
        let noteCopy = {...note}
        noteCopy[key] = value
        setNote(noteCopy)
    }

    return (
        <>
            <div className="container">
                <form action="#" onSubmit={(e) => { e.preventDefault(); saveNote();}}>
                    <div className="row">
                        <div className="col">
                            <FloatingLabel id="NoteTitle" value={note.title} onChange={(e)=>editNote('title', e.target.value)} type='text' placeholder="Note Title" required/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <FloatingLabel id="NoteBody" component="textarea" value={note.description} onChange={(e) => editNote('description', e.target.value)} placeholder="Note Body" required/>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col text-right">
                            <Button className="mr-1 enc-button" onClick={() => history.goBack()}>Cancel</Button>
                            <Button className="enc-button" type='submit'>Save Note</Button>
                        </div>
                    </div>

                </form>
            </div>
        </>
    )
}
