import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { GetTimesheetList, GetTimeSheetReport } from '../API/TimeSheet';
import { useHistory, Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import TimeSheetEntryList from './TimeSheetEntryList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

export default props => {
    const [TimesheetList, setTimesheetList] = useState([]);
    
    useEffect(() => {
        LoadTimesheets(props.date);
    }, [props.date])
    
    const LoadTimesheets = (filterDate) => {
        GetTimesheetList({ Date: filterDate })
            .then(res => {
                setTimesheetList(res.data)
            })
    }

    const DownloadTimesheet = () => {
        let download = require('downloadjs')
        GetTimeSheetReport({ Date: props.date, UTCOffset: new Date().getTimezoneOffset() / 60 })
            .then(res => {
                download(res, `TimeSheet-${props.date}.xlsx`)
            })
    }

    const SetDate = (newDate) => {
        props.setDate(newDate);
    }

    const history = useHistory();
    const navigateToTimesheet = (tsId) => {
        history.push(`/Timesheets/EditTimesheet/${tsId}`)
    }

    const getParsedTimeString = (date) => {
        return date ? new Date(Date.parse(date)).toLocaleTimeString() : "n/a";
    }

    return (
        <div className="container pt-4">
            <div className="row">
                <div className="col">
                    Date:
                    <DatePicker
                        className="enc-input"
                        selected={props.date}
                        onChange={(date) => SetDate(date)}
                        selectsEnd
                        date={props.date}
                        dateFormat="MMMM d, yyyy"
                    />
                </div>
                <div className="col text-right">
                    <Button className="enc-button" as={Link} to={`/Timesheets/EditTimesheetEntry/new`}>Add Entries</Button>
                    &nbsp;
                    {TimesheetList.length > 0  && 
                        <Button className="enc-button" onClick={() => DownloadTimesheet()}><FontAwesomeIcon icon={faDownload} className="clickable" /></Button>
                    }
                </div>
            </div>
            {TimesheetList.map((ts,i) =>
                <div className="container pt-4" key={`timesheet-${i}`}>
                    <div className="row pt-2" >
                        <div className="col-lg-4" onClick={() => navigateToTimesheet(ts.timesheetId)}>
                            {ts.employeeName}
                        </div>
                        <div className="col-lg-2">
                            {getParsedTimeString(ts.earliestStartTime)}
                        </div>
                        <div className="col-lg-2">
                            {getParsedTimeString(ts.latestEndTime)}
                        </div>
                        <div className="col-lg-2">
                            {ts.totalHours}
                        </div>
                        <div className="col text-right">
                            {ts.verified ? 
                                <p>Verified</p> : 
                                <Button className="enc-button" as={Link} to={`/VerifyTimesheet/${ts.timesheetId}`}>
                                    Verify
                                </Button>
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <TimeSheetEntryList timesheetId={ts.timesheetId} nav={true}/>
                        </div>
                    </div>
                    <hr/>
                </div>
            )}
        </div>
    )
}
