import React, { useEffect, useState } from 'react';
import { ListGroup, Button } from 'react-bootstrap';
import { CreateNote, GetNoteList } from '../../API/Proposal';
import { Link } from 'react-router-dom';

export default props => {
    const [noteList, setNoteList] = useState([]);

    const [newNote, setNewNote] = useState("");

    useEffect(() => {
        LoadNotes();
    }, [])

    const LoadNotes = () => {
        GetNoteList()
            .then(res => {
                setNoteList(res.data)
            })
    }

    const SaveNote = () => {
        let payload = {
            note: newNote
        }

        CreateNote(payload)
            .then(res => {
                setNewNote("");
                LoadNotes();
            })
    }

    return (
        <div className="container">
            <ListGroup>
                <ListGroup.Item className="enc-list">
                    Notes List
                </ListGroup.Item>
                {noteList.length === 0 &&
                    <ListGroup.Item >No Notes Found</ListGroup.Item>
                }
                {noteList.map((m, i) => {
                    return (
                        <ListGroup.Item className="click-list" as={Link} to={`${props.match.url}/${m.prefillNoteId}`} key={`note-${i}`}>
                            {m.note}
                        </ListGroup.Item>
                    )
                })}
            </ListGroup>
            <div className="divider my-3" />
            <form action="#" onSubmit={e => { e.preventDefault();  SaveNote(); } } >
                <div className="row mt-3">
                    <div className="col">
                        <h4>Create New Note</h4>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <textarea className="enc-textarea" value={newNote} onChange={e => setNewNote(e.target.value)} required/>
                    </div>
                </div>
                <div className="row">
                    <div className="col text-right">
                        <Button type="submit" className="enc-button">Add Note</Button>
                    </div>
                </div>
            </form>
        </div>
    )
}
