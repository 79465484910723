import APIRequest from './MainAPICall/api';
import config from '../APIConfig';

export const GetApprovals = (id) => {
    return APIRequest({
        url: config.baseURL + "/JobApproval/GetApprovals",
        method: "post",
        body: {id: id}
    });
}

export const CorporateApproval = (id) => {
    return APIRequest({
        url: config.baseURL + "/JobApproval/CorporateApproval",
        method: "post",
        body: {id: id}
    });
}
export const OperationsApproval = (id) => {
    return APIRequest({
        url: config.baseURL + "/JobApproval/OperationsApproval",
        method: "post",
        body: {id: id}
    });
}
export const ClientApproval = (id) => {
    return APIRequest({
        url: config.baseURL + "/JobApproval/ClientApproval",
        method: "post",
        body: {id: id}
    });
}
export const CorporateRejection = (id) => {
    return APIRequest({
        url: config.baseURL + "/JobApproval/CorporateRejection",
        method: "post",
        body: {id: id}
    });
}

export const ClientRejection = (id) => {
    return APIRequest({
        url: config.baseURL + "/JobApproval/ClientRejection",
        method: "post",
        body: {id: id}
    });
}

export const OperationsRejection = (id) => {
    return APIRequest({
        url: config.baseURL + "/JobApproval/OperationsRejection",
        method: "post",
        body: {id: id}
    });
}

export const AccountingApproval = (id) => {
    return APIRequest({
        url: config.baseURL + `/JobApproval/Accounting?id=${id}`,
        method: "get"
    });
}

export const ReviewSales = (id) => {
    return APIRequest({
        url: config.baseURL + `/JobApproval/ReviewSales?id=${id}`,
        method: "get"
    });
}

export const AccountingSkip = (id) => {
    return APIRequest({
        url: config.baseURL + `/JobApproval/AccountingSkip?id=${id}`,
        method: "get"
    });
}

export const SalesRejection = (id) => {
    return APIRequest({
        url: config.baseURL + `/JobApproval/SalesRejection?id=${id}`,
        method: "get"
    });
}