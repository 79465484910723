import React, { useState, useEffect } from 'react';
import { GetTimesheet, DeleteTimesheet } from '../API/TimeSheet';
import { useHistory } from 'react-router-dom';
import TimesheetEntryList from './TimeSheetEntryList';
import { Button } from 'react-bootstrap';

export default (props) => {
    const [timesheet, setTimesheet] = useState({
        timesheetId: 0,
        employeeIds: [],
        date: "",
        employeeName: "",
        earliestStartTime: "",
        latestEndTime: "",
        totalHours: 0
    });

    useEffect(() => {
        LoadTimesheet(props.timesheetId);
    }, [props.timesheetId, props.date])

    const history = useHistory();

    const LoadTimesheet = (timesheetId) => {
        GetTimesheet(timesheetId !== "new" ? parseInt(timesheetId) : 0)
            .then(res => {
                if (res.success) {
                    setTimesheet(res.data);
                }
            })
    }

    const DeleteCurrentTimesheet = () => {
        DeleteTimesheet(timesheet.timesheetId)
            .then(res => {
                if (res.success) {
                    history.push(`/Timesheets`);
                }
            })
    }

    const getParsedTimeString = (date) => {
        return date ? new Date(Date.parse(date)).toLocaleTimeString() : "n/a";
    }

    return (
        <div className="container pt-4">
            <div className="row pt-2">
                <div className="col-lg-1">
                    Date:
                </div>
                <div className="col">
                    {new Date(Date.parse(props.date)).toLocaleDateString()}
                </div>
            </div>
            <div>
                <div className="row pt-2" >
                    <div className="col-lg-4">
                        {timesheet.employeeName}
                    </div>
                    <div className="col-lg-2">
                        {getParsedTimeString(timesheet.earliestStartTime)}
                    </div>
                    <div className="col-lg-2">
                        {getParsedTimeString(timesheet.latestEndTime)}
                    </div>
                    <div className="col-lg-2">
                        {timesheet.totalHours}
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <TimesheetEntryList timesheetId={timesheet.timesheetId} nav={true} />
                    </div>
                </div>
                <hr />
            </div>
            <div className="row pt-2">
                <div className="col text-right">
                    <Button variant="danger" onClick={DeleteCurrentTimesheet}>Delete</Button>
                    <Button variant="secondary" onClick={() => history.push(`/Timesheets`)}>Cancel</Button>
                </div>
            </div>
        </div>
    )
}


