import React, { useState, useEffect, useRef } from 'react';
import { CloseRequest, GetEditTransfer, CancelTransferRequest, AvailableYardInventory, AvailableJobInventory, PostEditTransfer } from '../../API/InventoryTransfer';
import { Button, ListGroup } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { DropdownList } from 'react-widgets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

export default props => {
    const [transfer, setTransfer] = useState({
        "transferTypeId": 0,
        "transferRequestId": 0,
        "requestingUser": "",
        "date": "",
        "transferType": "",
        "completed": false,
        "origin": "",
        "destination": "",
        "allowClose": false,
        yardId: 0,
        jobId: 0,
        "additionalAssets": [],
        "additionalItems": [],
        "additionalItemsBulk": [],
        "transferItems": [],
        "transferItemsBulk": [],
        "assetTransfers": []
    })

    const [availableInventory, setAvailableInventory] = useState({
        "jobReadyAvailable": [],
        "bulkAvailable": [],
        "assetsAvailable": []
    });

    const [selectedAsset, setSelectedAsset] = useState(null);
    const [selectedJobReady, setSelectedJobReady] = useState(null);
    const [selectedBulk, setSelectedBulk] = useState(null);
    const [amountJRI, setAmountJRI] = useState(0);
    const [amountBulk, setAmountBulk] = useState(0);

    useEffect(() => {
        if (transfer.yardId !== 0) {
            AvailableYardInventory(transfer.yardId)
                .then(res => {
                    setAvailableInventory(res.data);
                })
        } else if (transfer.jobId !== 0) {
            AvailableJobInventory(transfer.jobId)
                .then(res => {
                    setAvailableInventory(res.data);
                })
        }
    }, [transfer.jobId, transfer.yardId]);

    const LoadEditTransfer = (id) => {
        GetEditTransfer(id)
            .then(res => {
                setTransfer(res.data);
            });
    }

    useEffect(() => {
        LoadEditTransfer(props.match.params.id);
    }, [props.match.params.id]);

    const AddJobReadyItem = () => {
        let s = { ...transfer };
        let item = {
            "transferItemId": 0,
            "jobReadyItemId": selectedJobReady.jobReadyItemId,
            "itemName": selectedJobReady.name,
            "totalAmount": 0,
            "amountRemaining": 0,
            "availableAmount": selectedJobReady.available,
            "selected": amountJRI
        };
        s.additionalItems.push(item);
        setTransfer(s);
        setSelectedJobReady(null);
        setAmountJRI(0);
    }

    const AddBulkItem = () => {
        let s = { ...transfer };
        let item = {
            "transferItemId": 0,
            "bulkItemId": selectedBulk.bulkItemId,
            "itemName": selectedBulk.name,
            "totalAmount": 0,
            "amountRemaining": 0,
            "availableAmount": selectedBulk.available,
            "selected": amountBulk
        };
        s.additionalItemsBulk.push(item);
        setTransfer(s);
        setSelectedBulk(null);
        setAmountBulk(0);
    }

    const removeAdditionalAsset = (index) => {
        let s = { ...transfer };
        s.additionalAssets.splice(index, 1);
        setTransfer(s);
    }

    const AddAsset = () => {
        let s = { ...transfer };
        let item = {
            "serializedStatusId": 0,
            "serializedItemId": selectedAsset.serializedItemId,
            "serialNumber": selectedAsset.serialNumber,
            "itemName": selectedAsset.name,
            "status": selectedAsset.status,
            "inStock": true,
            "selected": true
        };
        s.additionalAssets.push(item);
        setTransfer(s);
        setSelectedAsset(null);
    }

    const ValidateAmount = (value) => {
        setAmountJRI(value);
        if (value === "" || (new RegExp('^([0-9]{0,5})$').test(value) && selectedJobReady.available - parseInt(value) >= 0)) {
            ref.current.setCustomValidity("");
        } else {
            ref.current.setCustomValidity("Cannot add more than is available");
        }
    }

    const ValidateAmountBulk = (value) => {
        setAmountBulk(value);
        if (value === "" || (new RegExp('^([0-9]{0,5})$').test(value) && selectedBulk.available - parseInt(value) >= 0)) {
            ref.current.setCustomValidity("");
        } else {
            ref.current.setCustomValidity("Cannot add more than is available");
        }
    }

    const SaveTransfer = () => {
        let s = { ...transfer };
        s.transferItems = s.transferItems.map(m => { return ({ ...m, selected: parseInt(m.selected) }) });
        s.additionalItems = s.additionalItems.map(m => { return ({ ...m, selected: parseInt(m.selected) }) });
        s.transferItemsBulk = s.transferItemsBulk.map(m => { return ({ ...m, selected: parseInt(m.selected) }) });
        s.additionalItemsBulk = s.additionalItemsBulk.map(m => { return ({ ...m, selected: parseInt(m.selected) }) });
        PostEditTransfer(s)
            .then(res => {
                LoadEditTransfer(props.match.params.id);
            })
    }

    const ValidateListAmount = (index, value, list) => {
        let s = { ...transfer };
        let available = transfer[list][index].availableAmount;
        let inTicket = transfer[list][index].amountRemaining;
        if (value === "" || (new RegExp('^([0-9]{0,5})$').test(value) && available - parseInt(value) >= 0)) {
            s[list][index].selected = value;
            s[list][index].valid = null;
        }
        else if (new RegExp('^([0-9]{0,5})$').test(value)) {
            s[list][index].selected = available;
            s[list][index].valid = "Cannot add more than available.";
        }
        else if (new RegExp('^-?([0-9]{0,5})$').test(value)) {
            if (value === "-") {
                s[list][index].selected = "-";
            } else if (inTicket + parseInt(value) >= 0) {
                s[list][index].selected = value;
            } else {
                s[list][index].selected = 0 - inTicket;
                s[list][index].valid = "Cannot Remove more than available.";
            }
        }
        else {
            s[list][index].valid = "Invalid Input.";
        }
        setTransfer(s);
    }


    const SelectAsset = (index) => {
        let s = { ...transfer };
        s.assetTransfers[index].selected = !s.assetTransfers[index].selected;
        setTransfer(s);
    }

    const history = useHistory();
    const closeRequest = () => {
        CloseRequest(props.match.params.id)
            .then(res => {
                history.goBack();
            })
    }

    const cancelRequest = () => {
        CancelTransferRequest(props.match.params.id)
            .then(res => {
                history.goBack();
            })
    }

    let ref = useRef();

    return (
        <div>
            {/*LOCATIONS*/}
            <div className="row">
                <div className="col">
                    <h4>Origin: {transfer.origin}</h4>
                </div>
                <div className="col">
                    <h4>Destination: {transfer.destination}</h4>
                </div>
            </div>
            <div className="row my-3">
                <div className="col">
                    Date Placed: {transfer.date}
                </div>
                <div className="col">
                    Ticket Type: {transfer.transferType}
                </div>
            </div>
            
            {/*ASSETS*/}
            <div className="row mt-1">
                <div className="col">
                    <div className="row">
                        <div className="col">
                            Available Assets
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <DropdownList
                                textField={e => e ? `${e.name} - ${e.serialNumber}` : ""}
                                data={availableInventory.assetsAvailable
                                    .filter(item =>
                                        !transfer.assetTransfers.find(({ serializedItemId }) => item.serializedItemId === serializedItemId))
                                    .filter(item =>
                                        !transfer.additionalAssets.find(({ serializedItemId }) => item.serializedItemId === serializedItemId))
                                }
                                value={selectedAsset}
                                onChange={e => setSelectedAsset(e)}
                            />
                        </div>
                        <div className="col">
                            {selectedAsset &&
                                <div className="row">
                                    <div className="col">
                                        {`Serial Number: ${selectedAsset.serialNumber}`}
                                    </div>
                                    <div className="col text-right">
                                        <Button className="enc-button mt-2" onClick={AddAsset}>Add Asset</Button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {transfer.assetTransfers.length > 0 &&
                <ListGroup className="mt-2">
                    <ListGroup.Item className="enc-data-list">
                        Asset List
                    </ListGroup.Item>
                    <ListGroup.Item className="enc-data-list">
                        <div className="row">
                            <div className="col"> 
                                Asset
                            </div>
                            <div className="col">
                                Serial Number
                        </div>
                            <div className="col">
                                Status
                        </div>
                            <div className="col">
                                Return Item
                        </div>
                        </div>
                    </ListGroup.Item>
                    {transfer.assetTransfers.map((m, i) =>
                        <ListGroup.Item key={`asset-${i}`}>
                            <div className="row">
                                <div className="col">
                                    {m.itemName}
                                </div>
                                <div className="col">
                                    {m.serialNumber}
                                </div>
                                <div className="col">
                                    {m.status}
                                </div>
                                <div className="col">
                                    {m.inStock &&
                                        <div className="toggle-switch small-switch">
                                            <input type="checkbox" className="toggle-switch-checkbox" id={i} checked={m.selected} onChange={() => SelectAsset(i)}
                                            />
                                            <label className="toggle-switch-label" htmlFor={i}>
                                                <span className={"toggle-switch-inner"} />
                                                <span className={"toggle-switch-switch"} />
                                            </label>
                                        </div>
                                    }
                                </div>
                            </div>
                        </ListGroup.Item>
                    )}
                </ListGroup>
            }
            {transfer.additionalAssets.length > 0 &&
                <ListGroup className="mt-2">
                    <ListGroup.Item>Adding Assets</ListGroup.Item>
                    {transfer.additionalAssets.map((m, i) =>
                        <ListGroup.Item key={`additionalAsset-${i}`}>
                            <div className="row">
                                <div className="col">
                                    {m.itemName}
                                </div>
                                <div className="col">
                                    {m.serialNumber}
                                </div>
                                <div className="col">
                                    {m.status}
                                </div>
                                <div className="col">
                                    <FontAwesomeIcon icon={faTrash} className="clickable" onClick={() => removeAdditionalAsset(i)} />
                                </div>
                            </div>
                        </ListGroup.Item>
                    )}
                </ListGroup>
            }
            
            {/*JOB READY ITEMS*/}
            <div className="row mt-1">
                <div className="col">
                    <form action="#" onSubmit={(e) => { e.preventDefault(); AddJobReadyItem() }}>
                        <div className="row">
                            <div className="col">
                                Available Job Ready Inventory
                            </div>
                            <div className="col">
                            {selectedJobReady &&
                                <div className="row">
                                    <div className="col">
                                        <div className="row">
                                            <div className="col text-right">
                                                {`Available Amount: ${selectedJobReady.available}`}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col" />
                                </div>
                            }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <DropdownList
                                    textField='name'
                                    data={availableInventory.jobReadyAvailable
                                        .filter(item => !transfer.transferItems.find(({ jobReadyItemId }) => item.jobReadyItemId === jobReadyItemId))
                                        .filter(item => !transfer.additionalItems.find(({ jobReadyItemId }) => item.jobReadyItemId === jobReadyItemId))}
                                    value={selectedJobReady}
                                    onChange={e => setSelectedJobReady(e)}
                                />
                            </div>
                            <div className="col">
                            {selectedJobReady &&
                                <div className="row">
                                    <div className="col">
                                        <input type='number' ref={ref} className="enc-input-100" onChange={e => ValidateAmount(e.target.value)} value={amountJRI} />
                                    </div>
                                    <div className="col text-right">
                                        <Button className="enc-button" type="submit">Add Job Ready Item</Button>
                                    </div>
                                </div>
                            }
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <ListGroup className="mt-3">
                <ListGroup.Item className="enc-data-list">
                    Job Ready Items
                </ListGroup.Item>
                <ListGroup.Item className="enc-data-list">
                    <div className="row">
                        <div className="col">
                            Item Name
                        </div>
                        <div className="col">
                            Order Total
                        </div>
                        <div className="col">
                            Remaining
                        </div>
                        <div className="col">
                            Available Amount
                        </div>
                        <div className="col">
                            Adjust Amount
                        </div>
                        <div className="col">
                            Adjusted Total
                        </div>
                    </div>
                </ListGroup.Item>
                {transfer.transferItems.map((m, i) =>
                    <ListGroup.Item className="enc-data-list" key={`item-${i}`}>
                        <div className="row">
                            <div className="col">
                                {m.itemName}
                            </div>
                            <div className="col">
                                {m.totalAmount}
                            </div>
                            <div className="col">
                                {m.amountRemaining}
                            </div>
                            <div className="col">
                                {m.availableAmount}
                            </div>
                            <div className="col">
                                <input
                                    type='number'
                                    className="enc-input-100"
                                    value={m.selected}
                                    onChange={(e) => ValidateListAmount(i, e.target.value, 'transferItems')} />
                            </div>
                            <div className="col">
                                {(Number.isNaN(parseInt(m.amountRemaining) + parseInt(m.selected)) ?
                                    "Error" :
                                    (parseInt(m.amountRemaining) + parseInt(m.selected)))}
                            </div>
                        </div>
                    </ListGroup.Item>
                )}
                {transfer.additionalItems.map((m, i) =>
                    <ListGroup.Item key={`additional-${i}`}>
                        <div className="row">
                            <div className="col">
                                {m.itemName}
                            </div>
                            <div className="col">
                                {m.totalAmount}
                            </div>
                            <div className="col">
                                {m.amountRemaining}
                            </div>
                            <div className="col">
                                {m.availableAmount}
                            </div>
                            <div className="col">
                                <input
                                    type='number'
                                    className="enc-input-100"
                                    value={m.selected}
                                    onChange={(e) => ValidateListAmount(i, e.target.value, 'additionalItems')} />
                            </div>
                            <div className="col">
                                {(Number.isNaN(parseInt(m.amountRemaining) + parseInt(m.selected)) ?
                                    "Error" :
                                    (parseInt(m.amountRemaining) + parseInt(m.selected)))}
                            </div>
                        </div>
                    </ListGroup.Item>
                )}
            </ListGroup>

            {/*BULK ITEMS*/}
            <div className="row mt-1">
                <div className="col">
                    <form action="#" onSubmit={(e) => { e.preventDefault(); AddBulkItem() }}>
                        <div className="row">
                            <div className="col">
                                Available Bulk Inventory
                            </div>
                            <div className="col">
                            {selectedBulk &&
                                <div className="row">
                                    <div className="col">
                                        <div className="row">
                                            <div className="col text-right">
                                                {`Available Amount: ${selectedBulk.available}`}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col" />
                                </div>
                            }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <DropdownList
                                    textField='name'
                                    data={availableInventory.bulkAvailable
                                        .filter(item => !transfer.transferItems.find(({ jobReadyItemId }) => item.jobReadyItemId === jobReadyItemId))
                                        .filter(item => !transfer.additionalItems.find(({ jobReadyItemId }) => item.jobReadyItemId === jobReadyItemId))}
                                    value={selectedBulk}
                                    onChange={e => setSelectedBulk(e)}
                                />
                            </div>
                            <div className="col">
                            {selectedBulk &&
                                <div className="row">
                                    <div className="col">
                                        <input type='number' ref={ref} className="enc-input-100" onChange={e => ValidateAmountBulk(e.target.value)} value={amountBulk} />
                                    </div>
                                    <div className="col text-right">
                                        <Button className="enc-button" type="submit">Add Bulk Item</Button>
                                    </div>
                                </div>
                            }
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <ListGroup className="mt-3">
                <ListGroup.Item className="enc-data-list">
                    Bulk Items
                </ListGroup.Item>
                <ListGroup.Item className="enc-data-list">
                    <div className="row">
                        <div className="col">
                            Item Name
                        </div>
                        <div className="col">
                            Order Total
                        </div>
                        <div className="col">
                            Remaining
                        </div>
                        <div className="col">
                            Available Amount
                        </div>
                        <div className="col">
                            Adjust Amount
                        </div>
                        <div className="col">
                            Adjusted Total
                        </div>
                    </div>
                </ListGroup.Item>
                {transfer.transferItemsBulk.map((m, i) =>
                    <ListGroup.Item className="enc-data-list" key={`item-${i}`}>
                        <div className="row">
                            <div className="col">
                                {m.itemName}
                            </div>
                            <div className="col">
                                {m.totalAmount}
                            </div>
                            <div className="col">
                                {m.amountRemaining}
                            </div>
                            <div className="col">
                                {m.availableAmount}
                            </div>
                            <div className="col">
                                <input
                                    type='number'
                                    className="enc-input-100"
                                    value={m.selected}
                                    onChange={(e) => ValidateListAmount(i, e.target.value, 'transferItemsBulk')} />
                            </div>
                            <div className="col">
                                {(Number.isNaN(parseInt(m.amountRemaining) + parseInt(m.selected)) ?
                                    "Error" :
                                    (parseInt(m.amountRemaining) + parseInt(m.selected)))}
                            </div>
                        </div>
                    </ListGroup.Item>
                )}
                {transfer.additionalItemsBulk.map((m, i) =>
                    <ListGroup.Item key={`additional-${i}`}>
                        <div className="row">
                            <div className="col">
                                {m.itemName}
                            </div>
                            <div className="col">
                                {m.totalAmount}
                            </div>
                            <div className="col">
                                {m.amountRemaining}
                            </div>
                            <div className="col">
                                {m.availableAmount}
                            </div>
                            <div className="col">
                                <input
                                    type='number'
                                    className="enc-input-100"
                                    value={m.selected}
                                    onChange={(e) => ValidateListAmount(i, e.target.value, 'additionalItemsBulk')} />
                            </div>
                            <div className="col">
                                {(Number.isNaN(parseInt(m.amountRemaining) + parseInt(m.selected)) ?
                                    "Error" :
                                    (parseInt(m.amountRemaining) + parseInt(m.selected)))}
                            </div>
                        </div>
                    </ListGroup.Item>
                )}
            </ListGroup>
            
            
            {/*BUTTONS*/}
            <div className="row mt-1">
                <div className="col text-right">
                    {transfer.allowClose && <Button className="enc-button" onClick={closeRequest}>Close Ticket</Button>}

                    <Button className="mx-2" variant="danger" onClick={cancelRequest}>Cancel Transfer</Button>


                    <Button className="enc-button" onClick={SaveTransfer}>Save Adjustment</Button>
                </div>
            </div>
        </div>
    )
}
