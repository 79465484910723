import APIRequest, { APIGenericFile } from './MainAPICall/api';
import config from '../APIConfig';

export const GetDocument = (id) => {
    return APIGenericFile({
        url: config.baseURL + `/DocumentAndPolicy/Document?DocumentId=${id}`,
        method: "get"
    });
}

export const SaveDocumentAndPolicy = (payload) => {
    return APIRequest({
        url: config.baseURL + "/DocumentAndPolicy/SaveDocumentAndPolicy",
        method: "post",
        body: payload
    });
}

export const DeleteDocumentAndPolicy = (id) => {
    return APIRequest({
        url: config.baseURL + "/DocumentAndPolicy/DeleteDocumentAndPolicy",
        method: "post",
        body: {
            id: id
        }
    });
}

export const GetDocumentAndPolicyList = () => {
    return APIRequest({
        url: config.baseURL + "/DocumentAndPolicy/GetDocumentAndPolicyList",
        method: "get"
    });
}