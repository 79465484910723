import React, { useEffect, useState } from 'react';
import { ListGroup, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { YardList, PostYard } from '../../API/Inventory';
import { GetOfficeList } from '../../API/Office';
import { DropdownList } from 'react-widgets';

export default props => {
    const [yard, setYard] = useState({
        name: "",
        officeLocationId: -1
    })
    const [offices, setOffices] = useState([])
    const [yards, setYards] = useState([])
    const [errorText, setErrorText] = useState("")

    useEffect(() => {
        LoadYardList();
        GetOfficeList()
            .then(res => {
                res.data.unshift(
                    {
                        name: "Select Office",
                        officeLocationId: -1
                    }
                )
                setOffices(res.data)
            })
    }, [])

    const LoadYardList = () => {
        YardList()
            .then(res => {
                setYards(res.data)
            })
    }

    const changeYard = (key, value) => {
        let s = { ...yard };
        s[key] = value;
        setYard(s)
    }

    const saveYard = () => {
        if (yard.officeLocationId === -1){
            setErrorText("Please select an office");
            return;
        }
        PostYard(yard)
            .then(res => {
                LoadYardList();
            })
    }
    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <ListGroup>
                        <ListGroup.Item className="enc-data-list">
                            Yard List
                        </ListGroup.Item>
                        <ListGroup.Item className="enc-data-list">
                            <div className="row">
                                <div className="col">
                                    Yard Name
                            </div>
                                <div className="col">
                                    Office Location
                            </div>
                            </div>
                        </ListGroup.Item>
                        {yards.map(y => {
                            return (
                                <ListGroup.Item as={Link} key={`${y.yardId}-yard`} to={`${props.match.url}/${y.yardId}`} className="click-list">
                                    <div className="row">
                                        <div className="col">
                                            {y.yardName}
                                        </div>
                                        <div className="col">
                                            {y.officeName}
                                        </div>
                                    </div>
                                </ListGroup.Item>
                            )
                        })}
                    </ListGroup>
                </div>
            </div>
            <div className="divider my-3" />
            <form action="#" onSubmit={(e) => { e.preventDefault(); saveYard() }}>
                <div className="row">
                    <div className="col">
                        <input
                            className='enc-input-100'
                            value={yard.name}
                            onChange={e => changeYard('name', e.target.value)}
                            placeholder="Yard Name"
                            required />
                    </div>
                    <div className="col">
                        <DropdownList
                            data={offices}
                            textField='name'
                            value={yard.officeLocationId}
                            valueField='officeLocationId'
                            onChange={(e) => changeYard('officeLocationId', e.officeLocationId)}
                        />
                    </div>
                    <div className="col">
                        <Button className='enc-button' type='submit'>Add New Yard</Button>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        {errorText}
                    </div>
                </div>
            </form>
        </div>
    )
}
