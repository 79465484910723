import React, { useState, useEffect } from 'react';
import { Button, ListGroup, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { DeleteBulkItem, GetBulkItemList } from '../../../API/InventoryBulk';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

export default props => {
    const [items, setItems] = useState([])

    useEffect(() => {
        LoadBulkItemList();
    }, [])

    const LoadBulkItemList = () => {
        GetBulkItemList()
            .then(res => [
                setItems(res.data)
            ])
    }

    const [confirmDelete, setConfirmDelete] = useState(false);
    const [stagedForDelete, setStagedForDelete] = useState(0);


    const openConfirmDelete = (id) => {
        setStagedForDelete(id);
        setConfirmDelete(true);
    }

    const deleteCategory = () => {
        let id = items[stagedForDelete].inventoryBulkItemId
        DeleteBulkItem(id)
            .then(() => {
                LoadBulkItemList()
                setConfirmDelete(false);
            })
    }

    return (

        <div className="container">
            <div className="row">
                <div className="col">
                    <ListGroup>
                        <ListGroup.Item className="enc-list">
                            <div className="row">
                                <div className="col">
                                    Bulk Items
                                </div>
                                <div className="col text-right">
                                    <Button as={Link} to={`${props.match.url}/new`} className="enc-button">
                                        New Item
                                    </Button>
                                </div>
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item className="enc-data-list">
                            <div className="row">
                                <div className="col-6">
                                    Name
                                    </div>
                                <div className="col-6">
                                    Category
                                    </div>
                            </div>
                        </ListGroup.Item>
                        {items.map((m, i) =>
                            <ListGroup.Item key={`BulkItem-${i}`} className="click-list">
                                <div className="row">

                                    <div className="col-11">
                                        <Link to={`${props.match.url}/${m.inventoryBulkItemId}`} style={{color: "black"}}>
                                            <div className="row">
                                                <div className="col-6">
                                                    {m.bulkItemName}
                                                </div>
                                                <div className="col-5">
                                                    {m.category}
                                                </div>
                                            </div>
                                        </Link>
                                    </div>

                                    <div className="col-1">
                                        <FontAwesomeIcon icon={faTrash} className="clickable" onClick={() => openConfirmDelete(i)} />
                                    </div>
                                </div>
                            </ListGroup.Item>
                        )}
                    </ListGroup>
                </div>
            </div>
            <Modal show={confirmDelete} onHide={() => setConfirmDelete(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Removal</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    This will delete this Bulk Item. Please confirm you would like to take this action.
                    </Modal.Body>
                <Modal.Footer>
                    <Button className="enc-button" onClick={() => setConfirmDelete(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={deleteCategory} >
                        Confirm
                        </Button>
                </Modal.Footer>
            </Modal>
        </div >
    )
}
