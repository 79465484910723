import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { GetShopTicket, AdjustShopTicket } from '../../API/ShopConversion';
import Create from './Create';
import Dismantle from './Dismantle';
import ViewCreate from './ViewCreate';
import ViewDismantle from './ViewDismantle';

export default props => {
    const [shopTicket, setShopTicket] = useState({
        "shopTicketId": 0,
        "ticketName": "",
        "yardId": 0,
        "yardName": "",
        "shopActionId": 0,
        "shopAction": "",
        "date": "",
        "completed": false,
        "allowClose": true,
        "ticketItems": []
    });

    const [manage, setManage] = useState(false);

    useEffect(() => {
        LoadShopTicket(props.match.params.id)
    }, [props.match.params.id])

    const LoadShopTicket = (id) => {
        GetShopTicket(id)
            .then(res => {
                setShopTicket(res.data)
            })
    }

    const changeManage = () => {
        if (shopTicket.shopAdmin) {
            setManage(!manage)
        } else {
            setManage(false);
        }
    }

    const CompleteItems = () => {
        let s = { ...shopTicket };


        for(let item of s.ticketItems){
            item.adjustment = parseInt(item.adjustment)
            for(let component of item.components){
                component.amountRetiredAdjustment = parseInt(component.amountRetiredAdjustment)
                component.amountTransferedAdjustment = parseInt(component.amountTransferedAdjustment)
            }
        }


        AdjustShopTicket(s)
            .then(res => {
                LoadShopTicket(props.match.params.id)
                setManage(false);
            })
    }

    const ChangeSelectedAmount = (index, value) => {
        let s = { ...shopTicket }
        if (new RegExp('^([0-9]{0,10})$').test(value)) {
            if (s.ticketItems[index].amount - s.ticketItems[index].amountCompleted - parseInt(value) < 0) {
                return;
            } else {
                s.ticketItems[index].adjustment = value
                setShopTicket(s)
            }
        }
    }

    const ChangeRetired = (i, ci, value) => {
        let s = { ...shopTicket }
        let component = s.ticketItems[i].components[ci];
        if (new RegExp('^([0-9]{0,10})$').test(value)) {
            console.log(component.amount - component.amountRetired - component.amountTransfered - parseInt(component.amountTransferedAdjustment) - parseInt(value))
            if (component.amount - component.amountRetired - component.amountTransfered - parseInt(component.amountTransferedAdjustment) - parseInt(value) < 0) {
                console.log('whyyy')
                return;
            } else {
                s.ticketItems[i].components[ci].amountRetiredAdjustment = value
                setShopTicket(s)
            }
        }
    }

    const ChangeSalvaged = (i, ci, value) => {
        let s = { ...shopTicket }
        let component = s.ticketItems[i].components[ci];
        if (new RegExp('^([0-9]{0,10})$').test(value)) {
            if (component.amount - component.amountRetired - component.amountTransfered - parseInt(component.amountRetiredAdjustment) - parseInt(value) < 0) {
                console.log('whyyy')
                return;
            } else {
                component.amountTransferedAdjustment = value
                setShopTicket(s)
            }
        }
    }

    const closeRequest = () => {
        let s = { ...shopTicket };
        s.completed = true;
        AdjustShopTicket(s)
            .then(res => {
                LoadShopTicket(props.match.params.id)
            })
    }

    const SelectAll = () => {
        let s = { ...shopTicket }
        if (shopTicket.dismantleTicket) {
            for (let item of s.ticketItems) {
                for (let component of item.components) {
                    component.amountTransferedAdjustment = component.amount - component.amountRetired - component.amountTransfered - parseInt(component.amountRetiredAdjustment)
                }
            }
        } else {
            s.ticketItems.forEach(m => m.adjustment = m.amount - m.amountCompleted)
        }
        setShopTicket(s)
    }

    const SelectMax = (index) => {
        let s = { ...shopTicket }
        s.ticketItems[index].adjustment = s.ticketItems[index].amount - s.ticketItems[index].amountCompleted
        setShopTicket(s)
    }


    return (
        <div>
            <div className="row my-1">
                <div className="col text-center">
                    {shopTicket.completed && <h3>Ticket Closed</h3>}
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <h3>{shopTicket.ticketName}</h3>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col">
                    Ticket Created: <strong>{shopTicket.date}</strong>
                </div>
                <div className="col">
                    Ticket Type: <strong>{shopTicket.shopAction}</strong>
                </div>
            </div>
            <div className="divider my-2" />
            <div className="row my-1">
                <div className="col text-right">
                    {!shopTicket.completed && shopTicket.allowClose && <Button className="enc-button no-print" onClick={closeRequest}>Close Ticket</Button>}
                    {shopTicket.shopAdmin &&
                        <Button className="enc-button ml-2 no-print" onClick={changeManage}>Manage Ticket</Button>}
                    <Button className="enc-button mx-2 no-print" onClick={() => window.print()}>Print</Button>
                    {manage && <Button onClick={SelectAll} className="enc-button no-print">Select All</Button>}
                </div>
            </div>

            {manage ?

                <>

                    {shopTicket.dismantleTicket ?
                        <Dismantle ticketItems={shopTicket.ticketItems} ChangeRetired={ChangeRetired} ChangeSalvaged={ChangeSalvaged} /> :
                        <Create ticketItems={shopTicket.ticketItems} SelectMax={SelectMax} ChangeSelectedAmount={ChangeSelectedAmount} />}
                    <div className="row my-2">
                        <div className="col text-right">
                            {!shopTicket.completed && <Button className="enc-button ml-2" onClick={CompleteItems}>Save</Button>}
                        </div>
                    </div>
                </>
                :
                shopTicket.dismantleTicket ?
                    <ViewDismantle ticketItems={shopTicket.ticketItems} /> :
                    <ViewCreate ticketItems={shopTicket.ticketItems} />

            }


        </div>
    )
}
