import React, { useState, useEffect } from 'react';
import { GetSelf } from '../API/User';
import '../css/User.css';
import ProfileDisplay from '../Components/ProfileDisplay';
import { SavePhoneNumber, SaveEmail } from '../API/User';
import { Link } from 'react-router-dom';

export default props => {
    const [userInfo, setUserInfo] = useState({
        userId: 0,
        username: "",
        firstName: "",
        lastName: "",
        fullName: "",
        positionTitle: "",
        phoneNumbers: [],
        emails: [],
        employeePhoto: ""
    })

    useEffect(() => {
        getUserInfo();
    }, []
    );
    const getUserInfo = () => {
        GetSelf()
            .then(res => {
                setUserInfo(res.data)
            })
            .catch(err => {
                console.error("Unable to load user data.")
            })
    }


    const saveEmail = (payload) => {
        payload["userId"] = userInfo.userId
        SaveEmail(payload)
            .then(res => {
                let update = { ...userInfo };
                update['emails'] = res.emailList;
                setUserInfo(update);
            })
            .catch(err => {
                console.error(err)
            })
    }


    const savePhone = (payload) => {
        payload["userId"] = userInfo.userId
        SavePhoneNumber(payload)
            .then(res => {
                let update = { ...userInfo };
                update['phoneNumbers'] = res.phoneList;
                setUserInfo(update);
            })
            .catch(err => {
                console.error(err)
            })
    }





    return (
        <div className="container">
            <ProfileDisplay saveEmail={saveEmail} savePhone={savePhone} userInfo={userInfo} />
            <div className="divider my-3" />
            <div className="row justify-content-center mt-3">
                <div className="col-md text-center">
                    <h3>Account Management</h3>
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-6 text-center">
                    <Link to="/User/EditSignature"><h5>Edit Signature</h5></Link>
                </div>
                <div className="col-6 text-center">
                    <Link to="/User/ChangePassword"><h5>Change Password</h5></Link>
                </div>
            </div>
        </div>
    )
}