import React, { useEffect, useState } from 'react';
import { SerializedItemProfile, CheckInRepairedAsset } from '../../../API/AssetInventory';
import { YardList } from '../../../API/Inventory';
import { Button } from 'react-bootstrap';
import { DropdownList } from 'react-widgets';
import { useHistory } from 'react-router-dom';

export default props => {
    const [profile, setProfile] = useState({
        serializedItemId: 0,
        serialNumber: "",
        name: "",
        decommissioned: false,
        statuses: [],
        manufacturer: "",
        mode: "",
        licenses: "",
        purchaseDate: "",
        purchasePrice: 0,
        assetType: 0,
        meterType: ""
    })

    const [note, setNote] = useState("")
    const [yards, setYards] = useState([])
    const [yard, setYard] = useState(null)

    const [errorMessage, setErrorMessage] = useState("")

    useEffect(() => {
        SerializedItemProfile(props.match.params.id)
            .then(res => {
                setProfile(res.data)
            })
    }, [props.match.params.id])

    useEffect(()=>{
        YardList()
        .then(res=>{
            setYards(res.data)
        })
    },[])

    let history = useHistory();
    const checkInRepair = () => {
        if(yard == null){
            setErrorMessage("Please select a yard to check into.")
            return;
        }
        let payload={
            assetId: profile.serializedItemId,
            repairNote: note,
            yardId: yard.yardId
        }
        CheckInRepairedAsset(payload)
        .then(res=>{
            history.push(`/Admin/Inventory/Asset/${profile.serializedItemId}`)
        })
    }


    return (
        <div className="container my-5">
            <form action="#" onSubmit={(e) => { e.preventDefault(); checkInRepair() }}>
                <div className="row">
                    <div className="col">
                        <h3>Check in Asset from Repair</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <h4>{profile.manufacturer} - {profile.model} - {profile.serialNumber}</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <DropdownList
                            placeholder="Select Yard for Check In"
                            data = {yards}
                            value = {yard}
                            textField="yardName"
                            onChange = {e => setYard(e)}
                            />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        Repair Note:
                </div>
                </div>
                <div className="row">
                    <div className="col">
                        <textarea className="enc-textarea" value={note} onChange={e => setNote(e.target.value)} />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <span style={{color: 'red'}}>{errorMessage}</span>
                    </div>
                    <div className="col text-right">
                        <Button type='submit' className="enc-button">Check In Repair</Button>
                    </div>
                </div>
            </form>
        </div>
    )
}
