import React, { useState, useEffect } from 'react';
import { GetYardInventory } from '../API/Inventory';
import Grid from '../Components/Grid/Grid';

export default props => {

    const [Inventory, setInventory] = useState({
        "yardId": 0,
        "yardName": "",
        "bulkItems": [],
        "jobReadyItems": [],
        "assets": []
    })


    useEffect(() => {
        let id = props.match.params.yardId;
        LoadInventory(id);
    }, [props.match.params.yardId])



    const LoadInventory = (id) => {
        GetYardInventory(id)
            .then(res => {
                setInventory(res.data)
            })
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h3>{Inventory.yardName} Inventory</h3>
                </div>
            </div>

            <div className="row mt-2">
                <div className="col">
                    <h4>Job Ready Items</h4>
                </div>
            </div>

            {Inventory.jobReadyItems.map((cat, index) =>
                <Grid
                    key={`jrgrid-${index}`}
                    headerName={cat.categoryName}
                    headers={[{ key: 'name', name: 'Item Name' }, { key: 'amount', name: 'Amount' }, { key: 'damagedAmount', name: "Damaged Amount"}]}
                    data={cat.items}
                />
            )}

            <div className="divider my-2" />

            <div className="row mt-2">
                <div className="col">
                    <h4>Bulk Items</h4>
                </div>
            </div>

            {Inventory.bulkItems.map((cat, index) =>
                <Grid
                    key={`bulkgrid-${index}`}
                    headerName={cat.categoryName}
                    headers={[{ key: 'name', name: 'Item Name' }, { key: 'amount', name: 'Amount' }]}
                    data={cat.items}
                />
            )}

            <div className="divider my-2" />

            <div className="row mt-2">
                <div className="col">
                    <h4>Serialized Item List</h4>
                </div>
            </div>

            <Grid
                headerName={"Serialized Items"}
                headers={[{ key: 'name', name: 'Name' }, { key: 'serialNumber', name: 'Serial Number' }, { key: 'status', name: "Status" }]}
                data={Inventory.assets}
                url={`/Inventory/Asset`}
                urlId={"serializedItemId"}
                clickRow={true}
            />
        </div>
    )
}
