import React, { useEffect, useState } from 'react';
import { SerializedItemProfile } from '../API/AssetInventory';
import { ListGroup } from 'react-bootstrap';

export default props => {
    const [profile, setProfile] = useState({
        serializedItemId: 0,
        serialNumber: "",
        name: "",
        decommissioned: false,
        statuses: [],
        manufacturer: "",
        mode: "",
        licenses: "",
        purchaseDate: "",
        purchasePrice: 0,
        assetType: 0,
        meterType: ""
    })

    useEffect(() => {
        SerializedItemProfile(props.match.params.Sid)
            .then(res => {
                setProfile(res.data)
            })
    }, [props.match.params.Sid])

    return (
        <div>
            <div className="row">
                <div className="col">
                    <small>Manufacturer</small>
                    <h4>{profile.manufacturer}</h4>
                </div>
                <div className="col">
                    <small>Model</small>
                    <h4>{profile.model}</h4>
                </div>
                <div className="col text-right">
                    <small>Serial Number</small>
                    <h4>{profile.serialNumber}</h4>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col">
                    <small>Asset Type</small>
                    <br />
                    {profile.assetType}
                </div>
                <div className="col">
                    <small>Meter Type</small>
                    <br />
                    {profile.meterType}
                </div>
            </div>
            <div className="row mt-2">
                <div className="col">
                    <small>Purchase Date</small>
                    <br />
                    {profile.purchaseDate}
                </div>
                <div className="col">
                    <small>Last Calibrated Date</small>
                    <br />
                    {profile.lastCalibratedDate}
                </div>

            </div>
            <div className="row mt-2">
                <div className="col">
                    <small>Purchase Price</small>
                    <br />
                    ${profile.purchasePrice}
                </div>
            </div>
            <div className="row my-2">
                <div className="col">
                    <small>Licenses</small>
                    <br />
                    {profile.licenses}
                </div>
            </div>
            <ListGroup>
                <ListGroup.Item className="enc-data-list">
                    Asset Status History
                </ListGroup.Item>
                <ListGroup.Item className="enc-data-list">
                    <div className="row">
                        <div className="col">
                            Status
                                </div>
                        <div className="col">
                            Location
                                </div>
                        <div className="col text-right">
                            Date
                                </div>
                    </div>
                    <div className="divider my-2" />
                    <div className="row">
                        <div className="col">
                            Status Description
                        </div>
                    </div>
                </ListGroup.Item>
                {profile.statuses.map((m, i) => {
                    return (
                        <ListGroup.Item key={`status-${i}`} className="mt-1">
                            <div className="row">
                                <div className="col">
                                    Status: {m.status}
                                </div>
                                <div className="col">
                                    Location: {m.location}
                                </div>
                                <div className="col text-right">
                                    {m.date}
                                </div>
                            </div>
                            <div className="divider my-2" />
                            <div className="row">
                                <div className="col">
                                    {m.statusNote}
                                </div>
                            </div>
                        </ListGroup.Item>
                    )
                })}
            </ListGroup>

        </div >
    )
}
