import React, { useEffect, useState } from 'react';
import { GetLocationProfile, CheckAccountEditPermission } from '../API/Account';
import ContactDisplay from './ContactDisplay';
import { Card, Breadcrumb } from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';

export default props => {
    const [locationInfo, setLocationInfo] = useState({
        "pocName": "",
        "pocPhoneNumber": "",
        "pocEmail": "",
        "accountLocationId": 0,
        "accountId": 0,
        "locationName": "",
        "billingAddress1": "",
        "billingAddress2": "",
        "billingCity": "",
        "billingState": "",
        "billingZip": "",
        "shippingAddress1": "",
        "shippingAddress2": "",
        "shippingCity": "",
        "shippingState": "",
        "shippingZip": "",
        "enabled": true
    });
    const [contacts, setContacts] = useState([]);
    const [edit, setEdit] = useState(false);

    useEffect(() => {
        checkEdit()
        loadLocationProfile(parseInt(props.locid))
    }, [props.locid])

    const checkEdit = () => {
        CheckAccountEditPermission()
            .then(res => {
                if (res.success) {
                    setEdit(true)
                }
            })
    }

    const loadLocationProfile = (id) => {
        GetLocationProfile(id)
            .then(res => {
                setLocationInfo(res.locationInfo)
                setContacts(res.locationContacts)
            })
    }

    const history = useHistory();

    return (
        <div className="container-fluid">
            <Breadcrumb className="breadcrumb">
                <li className="breadcrumb-item"><Link to={`${props.url}`}>Accounts List</Link></li>
                <li className="breadcrumb-item"><Link to={`${props.url}/${props.accountId}`}>Account</Link></li>
                <Breadcrumb.Item active>Location Profile</Breadcrumb.Item>
            </Breadcrumb>
            <div className="row mb-3">
                <div className="col-2" />
                <div className="col text-center">
                    <h3>{locationInfo.locationName}</h3>
                </div>
                <div className="col-2 text-right">
                    {edit ? <FontAwesomeIcon icon={faPencilAlt} className="clickable" onClick={() => history.push(`${props.url}/${props.accountId}/Location/${locationInfo.accountLocationId}/Edit`)} /> : null}

                </div>
            </div>
            <div className="row justify-content-around">
                {(locationInfo.billingAddress1 || locationInfo.billingAddress2 || locationInfo.billingCity || locationInfo.billingZip) &&
                    <Card className="mb-2" style={{ width: '18rem' }}>
                        <Card.Header><strong>Billing Address</strong></Card.Header>
                        <Card.Body>
                            <Card.Text>
                                {`${locationInfo.billingAddress1}`} <br />
                                {locationInfo.billingAddress2 && <> {`${locationInfo.billingAddress2}`} <br /> </>}
                                {`${locationInfo.billingZip && locationInfo.billingCity + ","} ${locationInfo.billingState} ${locationInfo.billingZip}`}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                }
                {(locationInfo.shippingAddress1 || locationInfo.shippingAddress2 || locationInfo.shippingCity|| locationInfo.shippingZip) &&
                    <Card className="mb-2" style={{ width: '18rem' }}>
                        <Card.Header><strong>Shipping Address</strong></Card.Header>
                        <Card.Body>
                            <Card.Text>
                                {locationInfo.shippingAddress1 && <> {`${locationInfo.shippingAddress1}`} <br /> </>}
                                {locationInfo.shippingAddress2 && <> {`${locationInfo.shippingAddress2}`} <br /> </>}
                                {`${locationInfo.shippingCity && locationInfo.shippingCity + ","} ${locationInfo.shippingState} ${locationInfo.shippingZip}`}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                }
                <Card className="mb-2" style={{ width: '18rem' }}>
                    <Card.Header><strong>Primary Contact</strong></Card.Header>
                    <Card.Body>
                        <Card.Subtitle>
                            <small>Name</small>
                        </Card.Subtitle>
                        <Card.Subtitle><strong>{locationInfo.pocName}</strong></Card.Subtitle>
                        <Card.Text className="mt-2">
                            {locationInfo.pocPhoneNumber &&
                                <>
                                    <Card.Subtitle>
                                        <small>Phone Number</small>
                                    </Card.Subtitle>
                                    <Card.Link href={`tel:${locationInfo.pocPhoneNumber}`}>{locationInfo.pocPhoneNumber}</Card.Link>
                                </>
                            }

                            {locationInfo.pocMobileNumber &&
                                <>
                                    <Card.Subtitle>
                                        <small>Mobile Number</small>
                                    </Card.Subtitle>
                                    <Card.Link href={`tel:${locationInfo.pocMobileNumber}`}>{locationInfo.pocMobileNumber}</Card.Link>
                                </>
                            }

                            {locationInfo.pocOfficeNumber &&
                                <>
                                    <Card.Subtitle>
                                        <small>Office Number</small>
                                    </Card.Subtitle>
                                    <Card.Link href={`tel:${locationInfo.pocOfficeNumber}`}>{locationInfo.pocOfficeNumber}</Card.Link>
                                </>
                            }

                            {locationInfo.pocOtherNumber &&
                                <>
                                    <Card.Subtitle>
                                        <small>Other Number</small>
                                    </Card.Subtitle>
                                    <Card.Link href={`tel:${locationInfo.pocOtherNumber}`}>{locationInfo.pocOtherNumber}</Card.Link>
                                </>
                            }


                            <Card.Subtitle>
                                <small>Email</small>
                            </Card.Subtitle>
                            <Card.Link href={`mailto:${locationInfo.pocEmail}`}>{locationInfo.pocEmail}</Card.Link>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </div>
            <div className="row my-2">
                <div className="col text-center">
                    <h4>Location Contacts</h4>
                </div>
            </div>
            <ContactDisplay locationView={true} location={locationInfo.LocationId} accountId={locationInfo.accountId} contacts={contacts} />
        </div>
    )
}
