import APIRequest from './MainAPICall/api';
import config from '../APIConfig';

export const GetSurveyFormList = (id) => {
    return APIRequest({
        url: config.baseURL + "/SurveyForm/GetSurveyFormList",
        method: "post",
        body: { id: id }
    });
}

export const GetSurveyForm = (id) => {
    return APIRequest({
        url: config.baseURL + "/SurveyForm/GetSurveyForm",
        method: "post",
        body: { id: id }
    });
}

export const CreateSurveyForm = (id) => {
    return APIRequest({
        url: config.baseURL + "/SurveyForm/CreateSurveyForm",
        method: "post",
        body: { id: id }
    });
}

export const SaveSurveyForm = (payload) => {
    return APIRequest({
        url: config.baseURL + "/SurveyForm/SaveSurveyForm",
        method: "post",
        body: payload
    });
}

export const DeleteSurveyForm = (id) => {
    return APIRequest({
        url: config.baseURL + "/SurveyForm/DeleteSurveyForm",
        method: "post",
        body: { id: id }
    });
}

export const GetSurveyDurationOptions = () => {
    return APIRequest({
        url: config.baseURL + "/SurveyForm/GetSurveyDurationOptions",
        method: "get"
    });
}

export const GetSurveyTypeOptions = () => {
    return APIRequest({
        url: config.baseURL + "/SurveyForm/GetSurveyTypeOptions",
        method: "get"
    });
}

export const GetSurveyMeterOptions = (id) => {
    return APIRequest({
        url: config.baseURL + "/SurveyForm/GetSurveyMeterOptions",
        method: "post",
        body: { id: id }
    });
}

export const GetSurveyFormPermissions = () => {
    return APIRequest({
        url: config.baseURL + "/SurveyForm/GetSurveyFormPermissions",
        method: "get"
    });
}