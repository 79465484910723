import React, { useState, useEffect } from 'react';
import { Route, NavLink, Switch } from 'react-router-dom';

import { GetAdminNavigation } from '../API/Administrator';

import UserList from './UserList';
import RoleList from './RoleList';
import AnnouncementList from './AnnouncementList';
import DocumentsandPolicies from './DAPList';
import CreateNewUser from './UserEditor';
import ViewUserProfile from './ViewUserProfile';
import UserEditor from './UserEditor';
import EditAnnouncement from './EditAnnouncement';
import OfficeLocations from './OfficeLocations';
import EditOfficeLocations from './EditOfficeLocations';
import { Nav } from 'react-bootstrap';
import AdminBudgetControls from './AdminBudgetControls';
import InventoryRouter from './Inventory/InventoryRouter';
import CalculatorForm from './CalculatorForm';
import CalculatorView from './CalculatorView';
import FormEdit from './Forms/FormEdit';
import CreateEquipmentForm from './Forms/CreateEquipmentForm';
import ProposalRouter from './Proposal/ProposalRouter';
import UserSignatureUpload from '../Components/UserSignatureUpload';
import BudgetTemplate from './BudgetTemplate';
import BudgetTemplateSingle from './BudgetTemplateSingle';

import ProductRisk from './ProductRisk';
import ProductRiskSingle from './ProductRiskSingle';

import SequenceAction from './SequenceAction';
import SequenceActionSingle from './SequenceActionSingle';

import PotentialRisk from './PotentialRisk';

export default props => {
    const [nav, setNav] = useState([]);

    useEffect(() => {
        LoadNav();
    }, []);

    const LoadNav = () => {
        GetAdminNavigation()
            .then(res => {
                if (res.success) {
                    setNav(res.navigationItems)
                }
            })
    }

    const RenderNav = () => {
        return nav.map((item, index) => {
            return (
                <Nav.Item key={`AdminNav-${item.label}`}>
                    <Nav.Link as={NavLink} to={item.url}>{item.label}</Nav.Link>
                </Nav.Item>
            )
        });
    }

    return (
        <>
            <Nav variant="tabs" className="my-2">
                {RenderNav()}
            </Nav>
            <Switch>
                <Route exact path="/Admin/CreateUser" render={() =>
                    <CreateNewUser />
                } />
                <Route path="/Admin/Inventory" render={(routeprops) =>
                    <InventoryRouter {...routeprops} />
                } />
                <Route exact path="/Admin/UserList" render={() =>
                    <UserList />
                } />
                <Route path="/Admin/RoleList" render={() =>
                    <RoleList />
                } />
                <Route path="/Admin/AnnouncementList" render={() =>
                    <AnnouncementList />
                } />
                <Route path="/Admin/DocumentsandPolicies" render={() =>
                    <DocumentsandPolicies />
                } />
                <Route exact path="/Admin/OfficeLocations" render={() =>
                    <OfficeLocations />
                } />
                <Route exact path="/Admin/BudgetEdit" render={() =>
                    <AdminBudgetControls />
                } />
                <Route exact path="/Admin/OfficeLocations/:id/Edit" render={(routeprops) =>
                    <EditOfficeLocations id={routeprops.match.params.id} />
                } />
                <Route path="/Admin/User/:id/EditSignature" render={(routeprops) =>
                    <UserSignatureUpload userId={routeprops.match.params.id} />
                } />
                <Route path="/Admin/User/:id/Edit" render={(routeprops) =>
                    <UserEditor userId={routeprops.match.params.id} />
                } />
                <Route exact path="/Admin/User/:id" render={(routeprops) =>
                    <ViewUserProfile userId={routeprops.match.params.id} />
                } />
                <Route exact path="/Admin/Announcement/:id" render={(routeprops) =>
                    <EditAnnouncement id={routeprops.match.params.id} />
                } />
                <Route path="/Admin/CreateEquipmentForm" component={CreateEquipmentForm} />
                <Route path="/Admin/Forms" component={FormEdit} />
                <Route path="/Admin/Calculator" component={CalculatorView} />
                <Route path="/Admin/Calculator/:id" render={(routeprops) =>
                    <CalculatorForm id={routeprops.match.params.id} />
                } />
                <Route path="/Admin/Proposal" component={ProposalRouter} />
                <Route path="/Admin/BudgetTemplates/:id" component={BudgetTemplateSingle} />
                <Route path="/Admin/BudgetTemplates" component={BudgetTemplate} />

                <Route path="/Admin/ProductRisks/:id" component={ProductRiskSingle} />
                <Route path="/Admin/ProductRisks" component={ProductRisk} />

                <Route path="/Admin/SequenceActions/:id" component={SequenceActionSingle} />
                <Route path="/Admin/SequenceActions" component={SequenceAction} />

                <Route path="/Admin/PotentialRisks" component={PotentialRisk} />
            </Switch>
        </>
    )
}
