import React, { useState, useEffect, useRef } from 'react';
import { DropdownList } from 'react-widgets';
import { GetCalculator, GetCalculatorList, PostCalculatorResult } from '../API/Calculator';
import { Button, Table } from 'react-bootstrap';

export default props => {
    const [calculatorList, setCalculatorList] = useState([])
    const [calculator, setCalculator] = useState({
        calculatorId: 0,
        calculatorName: "",
        panelsPerSection: 0,
        beamRatio: 0,
        beamName: "",
        truckBeams: 0,
        truckPanels: 0
    })
    const [length, setLength] = useState(0)
    const [calculatedValues, setCalculatedValues] = useState({
        calculatedBeams: 0,
        calculatedPanels: 0,
        trucks: 0
    })
    const [selectedCalculator, setSelectedCalculator] = useState(-1)

    const [disableSave, setDisableSave] = useState(true);

    useEffect(() => {
        LoadCalculatorList()
    }, [])

    useEffect(() => {
        LoadCalculator(selectedCalculator)
    }, [selectedCalculator])

    const LoadCalculatorList = () => {
        GetCalculatorList()
            .then(res => {
                res.data.unshift({ calculatorId: -1, calculatorName: "Select a calculator" })
                setCalculatorList(res.data)
            })
    }

    const LoadCalculator = (id) => {
        if (id === -1) {
            return;
        }
        GetCalculator(id)
            .then(res => {
                setCalculator(res.data)
            })
    }

    let ref = useRef();

    const calculate = () => {
        let wallLength = parseInt(length)
        let calculations = {}
        // Math out the panels and beams
        let panels = Math.ceil(wallLength / 20 * calculator.panelsPerSection)
        let beams = Math.ceil((wallLength / 20) * calculator.beamRatio)
        // Set Panels and beams
        calculations.beams = beams
        calculations.panels = panels
        // Math out trucks
        let beamTrucks = calculator.truckBeams === 0 ? 0 : Math.ceil(beams / calculator.truckBeams)
        let panelTrucks = calculator.truckPanels === 0 ? 0 :  Math.ceil(panels / calculator.truckPanels)
        // Set Trucks
        calculations.trucks = beamTrucks + panelTrucks
        // Set values into state.
        setCalculatedValues(calculations)
        setDisableSave(false);
    }

    const changeInput = (value) => {
        // May need some number validation if HTML5 form doesn't do it right
        if (parseInt(value) % 20 !== 0) {
            ref.current.setCustomValidity('Please use a number that is an increment of 20.')
        } else {
            ref.current.setCustomValidity("")
        }
        setLength(value)
    }

    const SaveCalculations = () => {
        let payload = {
            ...calculatedValues,
            calculator: parseInt(selectedCalculator),
            jobId: parseInt(props.jobId),
            length: parseInt(length)
        }
        PostCalculatorResult(payload)
        .then(res=>{
            setDisableSave(true)
            if(props.rl){
                props.rl()
            }
        })
    }


    return (
        <div className="container my-5">
            <form onSubmit={e => { e.preventDefault(); calculate(); }}>
                <div className="row">
                    <div className="col">
                        <h3>Wall Calculator - Simple</h3>
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col">
                        <label>Calculator Type:</label>
                    </div>
                    <div className="col">
                        <DropdownList
                            placeholder="Select Calculator"
                            data={calculatorList}
                            textField='calculatorName'
                            valueField='calculatorId'
                            value={selectedCalculator}
                            onChange={e => setSelectedCalculator(e.calculatorId)}
                        />
                    </div>
                </div>

                <div className="row my-2">
                    <div className="col">
                        <label>Wall Length (20ft increment)</label>
                    </div>
                    <div className="col">
                        <input type='number'
                            className="enc-input-100"
                            ref={ref}
                            placeholder="0"
                            value={calculatedValues.length}
                            onChange={(e) => changeInput(e.target.value)}
                            step={20}
                            disabled={selectedCalculator === -1}
                            required
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col text-right my-2">
                        <Button className="enc-button" type='submit'>Calculate</Button>
                    </div>
                </div>
                <Table striped bordered>
                    <thead>
                        <tr>
                            <th colSpan="2">
                                Calculated Values
                                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <label>Calculated Number of {calculator.beamName} Beams:</label>
                            </td>
                            <td>
                                {calculatedValues.beams}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label>Calculated Number of Panels:</label>
                            </td>
                            <td>
                                {calculatedValues.panels}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label>Calculated Number of Trucks:</label>
                            </td>
                            <td>
                                {calculatedValues.trucks}
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <div className="row">
                    <div className="col text-right mt-2">
                        {props.jobId && <Button className="enc-button" onClick={SaveCalculations} disabled={disableSave}>Save Calculations</Button>}
                    </div>
                </div>
            </form>
        </div>
    )
}
