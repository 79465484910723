import React, { useEffect, useState, useRef } from 'react';
import { Button, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DropdownList } from 'react-widgets';
import { AvailableYardInventory, AvailableJobInventory, PostTransferRequest } from '../../API/InventoryTransfer';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

export default props => {
    useEffect(() => {
        if (props.fromType === 0) {
            AvailableYardInventory(props.from.id)
                .then(res => {
                    setAvailableInventory(res.data);
                });
        } else if (props.fromType === 1) {
            AvailableJobInventory(props.from.id)
                .then(res => {
                    setAvailableInventory(res.data);
                });
        }
    }, [props.fromType, props.from.id]);

    const damaged = props.fromType === 1 && props.toType === 0;

    const [availableInventory, setAvailableInventory] = useState({
        "jobReadyAvailable": [],
        "bulkAvailable": [],
        "assetsAvailable": []
    });

    const [selectedAsset, setSelectedAsset] = useState(null);
    const [selectedJobReady, setSelectedJobReady] = useState(null);
    const [selectedBulk, setSelectedBulk] = useState(null);
    const [amountJRI, setAmountJRI] = useState(0);
    const [amountBulk, setAmountBulk] = useState(0);

    const [pickOrder, setPickOrder] = useState({
        assets: [],
        jobReadyItems: [],
        bulkItems: []
    });

    const ValidateAmountJRI = (value) => {
        setAmountJRI(value);
        if (value === "" || (new RegExp('^([0-9]{0,5})$').test(value) && selectedJobReady.available - parseInt(value) >= 0)) {
            ref.current.setCustomValidity("");
        } else {
            ref.current.setCustomValidity("Cannot add more than is available");
        }
    }

    const ValidateAmountBulk = (value) => {
        setAmountBulk(value);
        if (value === "" || (new RegExp('^([0-9]{0,5})$').test(value) && selectedBulk.available - parseInt(value) >= 0)) {
            ref.current.setCustomValidity("");
        } else {
            ref.current.setCustomValidity("Cannot add more than is available");
        }
    }

    const ValidateListAmountJRI = (index, value, damaged = false) => {
        let s = { ...pickOrder };
        let available = pickOrder.jobReadyItems[index].available;

        if (value === "" || (new RegExp('^([0-9]{0,5})$').test(value) && available - parseInt(s.jobReadyItems[index].damagedAmount) - parseInt(value) >= 0)) {
            s.jobReadyItems[index].amount = value;
            s.jobReadyItems[index].valid = null;
        }
        else if (new RegExp('^([0-9]{0,5})$').test(value)) {
            s.jobReadyItems[index].amount = parseInt(available - s.jobReadyItems[index].damagedAmount);
            s.jobReadyItems[index].valid = "Cannot add more than available.";
        }
        else {
            s.jobReadyItems[index].amount = 0;
            s.jobReadyItems[index].valid = "Cannot add negative values.";
        }
        setPickOrder(s);
    }

    const ValidateListAmountBulk = (index, value, damaged = false) => {
        let s = { ...pickOrder };
        let available = pickOrder.bulkItems[index].available;

        if (value === "" || (new RegExp('^([0-9]{0,5})$').test(value) && available - parseInt(s.bulkItems[index].damagedAmount) - parseInt(value) >= 0)) {
            s.bulkItems[index].amount = value;
            s.bulkItems[index].valid = null;
        }
        else if (new RegExp('^([0-9]{0,5})$').test(value)) {
            s.bulkItems[index].amount = parseInt(available - s.bulkItems[index].damagedAmount);
            s.bulkItems[index].valid = "Cannot add more than available.";
        }
        else {
            s.bulkItems[index].amount = 0;
            s.bulkItems[index].valid = "Cannot add negative values.";
        }
        setPickOrder(s);
    }

    const ValidateDamagedAmountJRI = (index, value) => {
        let s = { ...pickOrder };
        let available = pickOrder.jobReadyItems[index].available;
        if (value === "" || (new RegExp('^([0-9]{0,5})$').test(value) && available - parseInt(s.jobReadyItems[index].amount) - parseInt(value) >= 0)) {
            s.jobReadyItems[index].damagedAmount = value;
            s.jobReadyItems[index].valid = null;
        }
        else if (new RegExp('^([0-9]{0,5})$').test(value)) {
            s.jobReadyItems[index].damagedAmount = parseInt(available - s.jobReadyItems[index].amount);
            s.jobReadyItems[index].valid = "Cannot add more than available.";
        }
        else {
            s.jobReadyItems[index].damagedAmount = 0;
            s.jobReadyItems[index].valid = "Cannot add negative values.";
        }

        setPickOrder(s);
    }

    const ValidateDamagedAmountBulk = (index, value) => {
        let s = { ...pickOrder };
        let available = pickOrder.bulkItems[index].available;
        if (value === "" || (new RegExp('^([0-9]{0,5})$').test(value) && available - parseInt(s.bulkItems[index].amount) - parseInt(value) >= 0)) {
            s.bulkItems[index].damagedAmount = value;
            s.bulkItems[index].valid = null;
        }
        else if (new RegExp('^([0-9]{0,5})$').test(value)) {
            s.bulkItems[index].damagedAmount = parseInt(available - s.bulkItems[index].amount);
            s.bulkItems[index].valid = "Cannot add more than available.";
        }
        else {
            s.bulkItems[index].damagedAmount = 0;
            s.bulkItems[index].valid = "Cannot add negative values.";
        }

        setPickOrder(s);
    }

    let ref = useRef();

    const RemoveAsset = (index) => {
        let s = { ...pickOrder };
        s.assets.splice(index, 1);
        setPickOrder(s);
    }

    const RemoveJobReadyItem = (index) => {
        let s = { ...pickOrder };
        s.jobReadyItems.splice(index, 1);
        setPickOrder(s);
    }

    const RemoveBulkItem = (index) => {
        let s = { ...pickOrder };
        s.bulkItems.splice(index, 1);
        setPickOrder(s);
    }

    const AddJobReadyItem = () => {
        let s = { ...pickOrder };
        let item = {
            ...selectedJobReady,
            damagedAmount: 0,
            amount: amountJRI,
            valid: null
        };
        s.jobReadyItems.push(item);
        setPickOrder(s);
        setSelectedJobReady(null);
        setAmountJRI(0);
    }

    const AddBulkItem = () => {
        let s = { ...pickOrder };
        let item = {
            ...selectedBulk,
            damagedAmount: 0,
            amount: amountBulk,
            valid: null
        };
        s.bulkItems.push(item);
        setPickOrder(s);
        setSelectedBulk(null);
        setAmountBulk(0);
    }

    const AddAsset = () => {
        let s = { ...pickOrder };
        let item = {
            ...selectedAsset,
            amount: 1
        };
        s.assets.push(item);
        setPickOrder(s);
        setSelectedAsset(null);
    }

    const submitPickOrder = () => {
        if (pickOrder.assets.length === 0 && pickOrder.jobReadyItems.length === 0 && pickOrder.bulkItems.length === 0) {
            return;
        }
        let payload = {
            FromType: props.fromType,
            FromLocationId: props.from.id,
            ToType: props.toType,
            ToLocationId: props.to.id,
            ...pickOrder
        };
        payload.jobReadyItems.forEach(element => {
            if (element.amount === "") {
                element.amount = 0
            } else {
                element.amount = parseInt(element.amount)
            }
            if (element.damagedAmount === "") {
                element.damagedAmount = 0
            } else {
                element.damagedAmount = parseInt(element.damagedAmount)
            }
        });
        payload.bulkItems.forEach(element => {
            if (element.amount === "") {
                element.amount = 0
            } else {
                element.amount = parseInt(element.amount)
            }
            if (element.damagedAmount === "") {
                element.damagedAmount = 0
            } else {
                element.damagedAmount = parseInt(element.damagedAmount)
            }
        });
        PostTransferRequest(payload)
            .then(res => {
                props.save();
            })
    }

    return (
        <div className="container">

            {/*ASSETS*/}
            <div className="row mt-1">
                <div className="col">
                    <div className="row">
                        <div className="col">
                            Available Assets
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <DropdownList
                                textField={e => e ? `${e.name} - ${e.serialNumber}` : ""}
                                data={availableInventory.assetsAvailable.filter(item => !pickOrder.assets.find(({ serializedItemId }) => item.serializedItemId === serializedItemId))}
                                value={selectedAsset}
                                onChange={e => setSelectedAsset(e)}
                                filter="contains"
                            />
                        </div>
                        <div className="col text-right">
                            {selectedAsset &&
                                <Button className="enc-button mt-2" onClick={AddAsset}>Add Asset</Button>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col">
                    <ListGroup>
                        <ListGroup.Item>
                            <div className="row">
                                <div className="col">
                                    Assets
                                </div>
                            </div>
                        </ListGroup.Item>
                        {pickOrder.assets.map((m, i) => {
                            return (
                                <ListGroup.Item key={`po-asset-${i}`}>
                                    <div className="row">
                                        <div className="col">
                                            {m.serialNumber} - {m.name}
                                        </div>
                                        <div className="col-1">
                                            <FontAwesomeIcon className="clickable" onClick={() => RemoveAsset(i)} icon={faTrash} />
                                        </div>
                                    </div>
                                </ListGroup.Item>
                            )
                        })}
                    </ListGroup>
                </div>
            </div>

            <div className="divider my-2" />

            {/*JRI*/}
            <div className="row mt-1">
                <div className="col">
                    <form action="#" onSubmit={(e) => { e.preventDefault(); AddJobReadyItem() }}>
                        <div className="row">
                            <div className="col">
                                Available Job Ready Inventory
                            </div>
                            {selectedJobReady &&
                                <div className="col">
                                    <div className="row">
                                        <div className="col text-right">
                                            {`Available Amount: ${selectedJobReady.available}`}
                                        </div>
                                        <div className="col" />
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="row">
                            <div className="col">
                                <DropdownList
                                    textField='name'
                                    data={availableInventory.jobReadyAvailable.filter(item => !pickOrder.jobReadyItems.find(({ jobReadyItemId }) => item.jobReadyItemId === jobReadyItemId))}
                                    value={selectedJobReady}
                                    onChange={e => setSelectedJobReady(e)}
                                    filter="contains"
                                />
                            </div>
                            <div className="col">
                                {selectedJobReady &&
                                    <div className="row">
                                        <div className="col">
                                            <input type='number' ref={ref} className="enc-input-100" onChange={e => ValidateAmountJRI(e.target.value)} value={amountJRI} />
                                        </div>
                                        <div className="col text-right">
                                            <Button className="enc-button" type="submit">Add Job Ready Item</Button>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div className="row mt-2">
                <div className="col">
                    <ListGroup>
                        <ListGroup.Item>
                            <div className="row">
                                <div className="col">
                                    Job Ready Items
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    Item:
                                </div>
                                <div className="col">
                                    Available:
                                </div>
                                <div className="col">
                                    {damaged ? "Return " : "Order "}Amount:
                                </div>
                                {damaged &&
                                    <div className="col">
                                        Damaged Amount
                                    </div>
                                }
                                <div className="col-1">

                                </div>
                            </div>
                        </ListGroup.Item>
                        {pickOrder.jobReadyItems.map((m, i) => {
                            return (
                                <ListGroup.Item key={`JRI-${i}`}>

                                    <div className="row">
                                        <div className="col">
                                            {m.name}
                                        </div>
                                        <div className="col">
                                            {m.available}
                                        </div>
                                        <div className="col">
                                            <input type='number' className="enc-input-100" onChange={e => ValidateListAmountJRI(i, e.target.value)} value={m.amount} />
                                            {m.valid && <span color="red">{m.valid}</span>}
                                        </div>
                                        {damaged &&
                                        <div className="col">
                                            <input type='number' className="enc-input-100" onChange={e => ValidateDamagedAmountJRI(i, e.target.value)} value={m.damagedAmount} />
                                        </div>
                                        }
                                        <div className="col-1">
                                            <FontAwesomeIcon className="clickable" onClick={() => RemoveJobReadyItem(i)} icon={faTrash} />
                                        </div>
                                    </div>
                                </ListGroup.Item>
                            )
                        })}
                    </ListGroup>
                </div>
            </div>
            
            <div className="divider my-2" />

            {/*Bulk*/}
            <div className="row mt-1">
                <div className="col">
                    <form action="#" onSubmit={(e) => { e.preventDefault(); AddBulkItem() }}>
                        <div className="row">
                            <div className="col">
                                Available Bulk Inventory
                            </div>
                            {selectedBulk &&
                                <div className="col">
                                    <div className="row">
                                        <div className="col text-right">
                                            {`Available Amount: ${selectedBulk.available}`}
                                        </div>
                                        <div className="col" />
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="row">
                            <div className="col">
                                <DropdownList
                                    textField='name'
                                    data={availableInventory.bulkAvailable.filter(item => !pickOrder.bulkItems.find(({ bulkItemId }) => item.bulkItemId === bulkItemId))}
                                    value={selectedBulk}
                                    onChange={e => setSelectedBulk(e)}
                                    filter="contains"
                                />
                            </div>
                            <div className="col">
                                {selectedBulk &&
                                    <div className="row">
                                        <div className="col">
                                    <input type='number' ref={ref} className="enc-input-100" onChange={e => ValidateAmountBulk(e.target.value)} value={amountBulk} />
                                        </div>
                                        <div className="col text-right">
                                    <Button className="enc-button" type="submit">Add Bulk Item</Button>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div className="row mt-2">
                <div className="col">
                    <ListGroup>
                        <ListGroup.Item>
                            <div className="row">
                                <div className="col">
                                    Bulk Items
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    Item:
                                </div>
                                <div className="col">
                                    Available:
                                </div>
                                <div className="col">
                                    {damaged ? "Return " : "Order "}Amount:
                                </div>
                                {damaged &&
                                    <div className="col">
                                        Damaged Amount
                                    </div>
                                }
                                <div className="col-1">

                                </div>
                            </div>
                        </ListGroup.Item>
                        {pickOrder.bulkItems.map((m, i) => {
                            return (
                                <ListGroup.Item key={`Bulk-${i}`}>

                                    <div className="row">
                                        <div className="col">
                                            {m.name}
                                        </div>
                                        <div className="col">
                                            {m.available}
                                        </div>
                                        <div className="col">
                                            <input type='number' className="enc-input-100" onChange={e => ValidateListAmountBulk(i, e.target.value)} value={m.amount} />
                                            {m.valid && <span color="red">{m.valid}</span>}
                                        </div>
                                        {damaged &&
                                        <div className="col">
                                            <input type='number' className="enc-input-100" onChange={e => ValidateDamagedAmountBulk(i, e.target.value)} value={m.damagedAmount} />
                                        </div>
                                        }
                                        <div className="col-1">
                                            <FontAwesomeIcon className="clickable" onClick={() => RemoveBulkItem(i)} icon={faTrash} />
                                        </div>
                                    </div>
                                </ListGroup.Item>
                            )
                        })}
                    </ListGroup>
                </div>
            </div>

            <div className="row mt-2">
                <div className="col text-right">
                    <Button className="enc-button" onClick={submitPickOrder}>Submit Pick Order</Button>
                </div>
            </div>
        </div>
    )
}
