import React, { useState, useEffect } from 'react';
import { DropdownList } from 'react-widgets';
import { GetNewEquipmentForm, GetEquipmentFormNames, PostEquipmentForm, GetEquipmentForm, GetEquipmentPicture, RemoveEquipmentPicture } from '../../API/JobForm';
import { ListGroup, Button } from 'react-bootstrap';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { faTrash, faDownload, faCamera } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Webcam from "react-webcam";
import { useLocation } from 'react-router-dom';

export default ({ EquipmentFormId, ...props }) => {
    const location = useLocation();

    useEffect(() => {
        if (location.pathname.toLowerCase().includes("installation")) {
            setDeliveryPhoto(true);
        }
        else if (location.pathname.toLowerCase().includes("teardown")) {
            setPickupPhoto(true);
        }
    }, [location]);

    // State
    const [form, setForm] = useState({
        equipmentFormId: 0,
        equipmentFormNameId: -1,
        unitNumber: "",
        cbCategories: [],
        pictures: [],
        damagePhotos: [],
        deliveryPhotos: [],
        pickupPhotos: [],
        comments: ""
    });
    const [error, setError] = useState("")
    const [equipmentFormNames, setEquipmentFormNames] = useState([])

    const [deliveryPhoto, setDeliveryPhoto] = useState(false)
    const [pickupPhoto, setPickupPhoto] = useState(false)

    const FACING_MODE_USER = "user";
    const FACING_MODE_ENVIRONMENT = "environment";

    // camera upload for damage photos
    const [isWebcamDmg, setWebcamDmg] = useState(false)
    const [isWebcamFrozenDmg, setWebcamFrozenDmg] = useState(false)
    const [webcamImageDmg, setWebCamImageDmg] = useState('');
    const [webcamImageNameDmg, setWebCamImageNameDmg] = useState('');
    const webcamRefDmg = React.useRef(null);
    const filepondRefDmg = React.useRef(null);
    const videoConstraintsDmg = {
        width: 1920,
        height: 1080,
        renderwidth: '',
        renderheight: '',
        facingMode: FACING_MODE_ENVIRONMENT
    };
    const webcamSnapshotDmg = React.useCallback(
        () => {
            if (!isWebcamFrozenDmg) {
                const imageSrc = webcamRefDmg.current.getScreenshot();
                setWebCamImageDmg(imageSrc)
            }
            else {
                setWebCamImageDmg('')
                setWebCamImageNameDmg('')
            }
            setWebcamFrozenDmg(!isWebcamFrozenDmg)
        },
        [webcamRefDmg, isWebcamFrozenDmg]
    );

    // camera upload for delivery photos
    const [isWebcamDelivery, setWebcamDelivery] = useState(false)
    const [isWebcamFrozenDelivery, setWebcamFrozenDelivery] = useState(false)
    const [webcamImageDelivery, setWebCamImageDelivery] = useState('');
    const [webcamImageNameDelivery, setWebCamImageNameDelivery] = useState('');
    const webcamRefDelivery = React.useRef(null);
    const filepondRefDelivery = React.useRef(null);
    const videoConstraintsDelivery = {
        width: 1920,
        height: 1080,
        renderwidth: '',
        renderheight: '',
        facingMode: FACING_MODE_ENVIRONMENT
    };
    const webcamSnapshotDelivery = React.useCallback(
        () => {
            if (!isWebcamFrozenDelivery) {
                const imageSrc = webcamRefDelivery.current.getScreenshot();
                setWebCamImageDelivery(imageSrc)
            }
            else {
                setWebCamImageDelivery('')
                setWebCamImageNameDelivery('')
            }
            setWebcamFrozenDelivery(!isWebcamFrozenDelivery)
        },
        [webcamRefDelivery, isWebcamFrozenDelivery]
    );

    // camera upload for pickup photos
    const [isWebcamPickup, setWebcamPickup] = useState(false)
    const [isWebcamFrozenPickup, setWebcamFrozenPickup] = useState(false)
    const [webcamImagePickup, setWebCamImagePickup] = useState('');
    const [webcamImageNamePickup, setWebCamImageNamePickup] = useState('');
    const webcamRefPickup = React.useRef(null);
    const filepondRefPickup = React.useRef(null);
    const videoConstraintsPickup = {
        width: 1920,
        height: 1080,
        renderwidth: '',
        renderheight: '',
        facingMode: FACING_MODE_ENVIRONMENT
    };
    const webcamSnapshotPickup = React.useCallback(
        () => {
            if (!isWebcamFrozenPickup) {
                const imageSrc = webcamRefPickup.current.getScreenshot();
                setWebCamImagePickup(imageSrc)
            }
            else {
                setWebCamImagePickup('')
                setWebCamImageNamePickup('')
            }
            setWebcamFrozenPickup(!isWebcamFrozenPickup)
        },
        [webcamRefPickup, isWebcamFrozenPickup]
    );

    // camera upload for equipment photos
    const [isWebcam, setWebcam] = useState(false)
    const [isWebcamFrozen, setWebcamFrozen] = useState(false)
    const [webcamImage, setWebCamImage] = useState('');
    const [webcamImageName, setWebCamImageName] = useState('');
    const [picIndex, setPicIndex] = useState(0);
    const [picType, setPicType] = useState(0);
    const webcamRef = React.useRef(null);
    const filepondRef = React.createRef(); // React.useRef(null);
    const videoConstraints = {
        width: 1920,
        height: 1080,
        renderwidth: '',
        renderheight: '',
        facingMode: FACING_MODE_ENVIRONMENT
    };
    const webcamSnapshot = React.useCallback(
        () => {
            if (!isWebcamFrozen) {
                const imageSrc = webcamRef.current.getScreenshot();
                setWebCamImage(imageSrc)
            }
            else {
                setWebCamImage('')
                setWebCamImageName('')
            }
            setWebcamFrozen(!isWebcamFrozen)
        },
        [webcamRef, isWebcamFrozen]
    );

    // Use Effect
    useEffect(() => {
        LoadEquipmentFormNames()
        if (EquipmentFormId !== 0) {
            LoadSavedForm(EquipmentFormId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [EquipmentFormId])

    useEffect(() => {
        if (form.equipmentFormNameId !== undefined) {
            LoadNewForm(form.equipmentFormNameId)
        }
        else { console.log("unable to load new equipment form, form.equipmentFormNameId is undefined. This does not inherently mean there is an error.") }
    }, [form.equipmentFormNameId])

    
    registerPlugin(FilePondPluginFileValidateType)

    // FilePondConfiguration //
    const FilePondSettings = (reqPicId, index, reqPicType, reqPicIndex) => {
        let type = reqPicId;
        let picI = index;

        // dmg type = 0
        // delivery type = -1
        // pickup type = -2
        if (reqPicId <= -2) {
            type = reqPicType;
        }
        if (index !== 'dmg' || index !== 'delivery' || index !== 'pickup') {
            picI = reqPicIndex;
        }

        let token;

        const LS = localStorage.getItem("BAENCToken");
        const SS = sessionStorage.getItem("BAENCToken");

        if (LS) {
            token = LS;
        } else if (SS) {
            token = SS;
        }

        return ({
            url: "/api/JobForm",
            process: {
                url: "/EquipmentPicture",
                method: "post",
                headers: {
                    Authorization: `Bearer ${token}`
                },
                ondata: (formData) => {
                    //formData.append("PictureType", reqPicId);
                    formData.append("PictureType", type);
                    formData.append("EquipmentFormId", form.equipmentFormId);
                    return formData;
                },
                //onload: (res) => { addPicture(res, index) }
                onload: (res) => { addPicture(res, picI) }
            },
            load: null,
            revert: null,
            restore: null,
            fetch: null,
            patch: null
        })
    }

    const LoadSavedForm = (id) => {
        GetEquipmentForm(id)
            .then(res => {
                setForm(res.data)
            })
    }

    const SaveForm = () => {
        let payload = formPayload();
        PostEquipmentForm(payload)
            .then(res => {
                if (props.addEquipment) {
                    props.addEquipment(res.id)
                }
            })
    }

    const SubmitForm = () => {
        let fail = false;
        form.pictures.forEach(e => {
            if (e.files.length === 0) {
                setError("Please upload all required photos")
                fail = true
            }
        })
        if (fail) {
            return;
        }
        let payload = formPayload();
        payload.complete = true;
        PostEquipmentForm(payload)
            .then(res => {
                if (props.addEquipment) {
                    props.addEquipment(res.id)
                }
            })
    }

    const formPayload = () => {
        let payload = { ...form }
        delete payload.cbCategories
        payload.jobId = parseInt(props.jobId)
        if (props.stepId) {
            payload.stepId = parseInt(props.stepId)
        }
        if (props.equipCheckId) {
            payload.equipmentFormId = parseInt(props.equipCheckId)
        }
        payload.checkBoxes = []
        form.cbCategories.forEach(e => {
            e.checkBoxes.forEach(x => {
                payload.checkBoxes.push(x)
            })
        })
        return payload
    }

    const LoadEquipmentFormNames = () => {
        GetEquipmentFormNames()
            .then(res => {
                res.data.unshift({
                    equipmentFormNameId: -1,
                    name: "Select a Vehicle Type"
                })
                setEquipmentFormNames(res.data)
            })
    }

    const LoadNewForm = (id) => {
        if (id !== -1) {
            GetNewEquipmentForm(id)
                .then(res => {
                    setForm(form => ({ ...form, cbCategories: res.checkBox }))
                })
        }

    }

    const addPicture = (res, index) => {
        let x = JSON.parse(res);
        let s = { ...form };
        if (index === 'dmg') {
            s.damagePhotos.push(x.data)
        } else if (index === 'delivery') {
            s.deliveryPhotos.push(x.data)
        } else if (index === 'pickup') {
            s.pickupPhotos.push(x.data)
        } else {
            //console.log(s.pictures);
            s.pictures[index].files.push(x.data)
        }
        setForm(s);
    }

    const deletePicture = (id, index) => {
        //state.pictures[index].files is the array of files that that are displayed once one is uploaded. once a file is uploaded
        // the current scheme replaces the filepond drag and drop with a ui component where the user can then download the image. 
        //this method currently removes all files from a particular section when on is deleted. rework of how the filepond drag and drop area is being rendered
        //as well as a rework of this method to remove an image at a specific index within the .files array will be required if client request ability to ypload multiple photos to one area.
        RemoveEquipmentPicture(id)
        // let formPics = form.pictures
        // let target = formPics[index]
        let state = { ...form }
        state.pictures[index].files = []
        setForm(state)
    }

    const changeForm = (key, value) => {
        let s = { ...form }
        s[key] = value
        setForm(s)
    }

    const changeCheckBox = (categoryIndex, checkboxIndex, key, value) => {
        let s = { ...form }
        s.cbCategories[categoryIndex].checkBoxes[checkboxIndex][key] = value
        setForm(s)
    }

    // camera upload for damage photos
    const uploadCameraDmg = () => {
        var fp = filepondRefDmg.current;
        if (fp) {
            fp.addFile(webcamImageDmg).then(item => {
                var n = webcamImageNameDmg;
                if (n)
                    item.file.name = n;
                var t = item.file.type; // attempt to append the correct filetype
                t = t.replace("image/", "");
                item.file.name = item.file.name + "." + t;
            });
        }
        resetCameraDmg();
    }
    const renderCameraButtonsDmg = () => {
        return <div>
            {webcamImageDmg && isWebcamFrozenDmg ?
                <div className="row justify-content-center">
                    <input type="text"
                        className="enc-input-100"
                        name="webcamImageNameDmg"
                        value={webcamImageNameDmg}
                        placeholder="Damage Image Name"
                        onChange={e => setWebCamImageNameDmg(e.target.value)}
                    />
                </div>
                : null}
            <div className="row justify-content-center">
                <Button className="enc-button" onClick={() => resetCameraDmg()}>Cancel</Button>&nbsp;
                <Button className="enc-button" onClick={() => webcamSnapshotDmg()}>{isWebcamFrozenDmg ? "Reset" : "Snapshot"}</Button>&nbsp;
                {webcamImageDmg && isWebcamFrozenDmg ?
                    <Button className="enc-button" onClick={() => uploadCameraDmg()}>Upload</Button>
                    : null}
            </div>
        </div>;
    }
    const renderUploadDmg = (picId, i) => {
        return (
            <div className="attachment-container">
                <div className="attachment-camera clickable" onClick={() => setWebcamDmg(!isWebcamDmg)}>
                    <FontAwesomeIcon icon={faCamera} className="" />
                </div>
                {isWebcamDmg ?
                    <div className="attachment-camera-preview">
                        {isWebcamFrozenDmg ?
                            <img src={webcamImageDmg} />
                            :
                            <Webcam
                                audio={false}
                                height={videoConstraintsDmg.renderheight}
                                ref={webcamRefDmg}
                                screenshotFormat="image/jpeg"
                                width={videoConstraintsDmg.renderwidth}
                                videoConstraints={videoConstraintsDmg}
                            />
                        }
                        {renderCameraButtonsDmg()}
                    </div>
                : null}
                <FilePond
                    //files={[]}
                    className="mt-3"
                    allowMultiple={false}
                    acceptedFileTypes={['image/*']}
                    server={FilePondSettings(Number(picId), i, Number(picId), i)}
                    credits={false}
                    ref={filepondRefDmg}
                />
            </div>
        )
    }
    const resetCameraDmg = () => {
        setWebcamDmg(false);
        setWebcamFrozenDmg(false);
        setWebCamImageDmg('')
        setWebCamImageNameDmg('')
    }

    // camera upload for delivery photos
    const uploadCameraDelivery = () => {
        var fp = filepondRefDelivery.current;
        if (fp) {
            fp.addFile(webcamImageDelivery).then(item => {
                var n = webcamImageNameDelivery;
                if (n)
                    item.file.name = n;
                var t = item.file.type; // attempt to append the correct filetype
                t = t.replace("image/", "");
                item.file.name = item.file.name + "." + t;
            });
        }
        resetCameraDelivery();
    }
    const renderCameraButtonsDelivery = () => {
        return <div>
            {webcamImageDelivery && isWebcamFrozenDelivery ?
                <div className="row justify-content-center">
                    <input type="text"
                        className="enc-input-100"
                        name="webcamImageNameDelivery"
                        value={webcamImageNameDelivery}
                        placeholder="Delivery Image Name"
                        onChange={e => setWebCamImageNameDelivery(e.target.value)}
                    />
                </div>
                : null}
            <div className="row justify-content-center">
                <Button className="enc-button" onClick={() => resetCameraDelivery()}>Cancel</Button>&nbsp;
                <Button className="enc-button" onClick={() => webcamSnapshotDelivery()}>{isWebcamFrozenDmg ? "Reset" : "Snapshot"}</Button>&nbsp;
                {webcamImageDelivery && isWebcamFrozenDelivery ?
                    <Button className="enc-button" onClick={() => uploadCameraDelivery()}>Upload</Button>
                    : null}
            </div>
        </div>;
    }
    const renderUploadDelivery = (picId, i) => {
        return (
            <div className="attachment-container">
                <div className="attachment-camera clickable" onClick={() => setWebcamDelivery(!isWebcamDelivery)}>
                    <FontAwesomeIcon icon={faCamera} className="" />
                </div>
                {isWebcamDelivery ?
                    <div className="attachment-camera-preview">
                        {isWebcamFrozenDelivery ?
                            <img src={webcamImageDelivery} />
                            :
                            <Webcam
                                audio={false}
                                height={videoConstraintsDelivery.renderheight}
                                ref={webcamRefDelivery}
                                screenshotFormat="image/jpeg"
                                width={videoConstraintsDelivery.renderwidth}
                                videoConstraints={videoConstraintsDelivery}
                            />
                        }
                        {renderCameraButtonsDelivery()}
                    </div>
                : null}
                <FilePond
                    //files={[]}
                    className="mt-3"
                    allowMultiple={false}
                    acceptedFileTypes={['image/*']}
                    server={FilePondSettings(Number(picId), i, Number(picId), i)}
                    credits={false}
                    ref={filepondRefDelivery}
                />
            </div>
        )
    }
    const resetCameraDelivery = () => {
        setWebcamDelivery(false);
        setWebcamFrozenDelivery(false);
        setWebCamImageDelivery('')
        setWebCamImageNameDelivery('')
    }

    // camera upload for pickup photos
    const uploadCameraPickup = () => {
        var fp = filepondRefPickup.current;
        if (fp) {
            fp.addFile(webcamImagePickup).then(item => {
                var n = webcamImageNamePickup;
                if (n)
                    item.file.name = n;
                var t = item.file.type; // attempt to append the correct filetype
                t = t.replace("image/", "");
                item.file.name = item.file.name + "." + t;
            });
        }
        resetCameraPickup();
    }
    const renderCameraButtonsPickup = () => {
        return <div>
            {webcamImagePickup && isWebcamFrozenPickup ?
                <div className="row justify-content-center">
                    <input type="text"
                        className="enc-input-100"
                        name="webcamImageNamePickup"
                        value={webcamImageNamePickup}
                        placeholder="Pickup Image Name"
                        onChange={e => setWebCamImageNamePickup(e.target.value)}
                    />
                </div>
                : null}
            <div className="row justify-content-center">
                <Button className="enc-button" onClick={() => resetCameraPickup()}>Cancel</Button>&nbsp;
                <Button className="enc-button" onClick={() => webcamSnapshotPickup()}>{isWebcamFrozenPickup ? "Reset" : "Snapshot"}</Button>&nbsp;
                {webcamImagePickup && isWebcamFrozenPickup ?
                    <Button className="enc-button" onClick={() => uploadCameraPickup()}>Upload</Button>
                    : null}
            </div>
        </div>;
    }
    const renderUploadPickup = (picId, i) => {
        return (
            <div className="attachment-container">
                <div className="attachment-camera clickable" onClick={() => setWebcamPickup(!isWebcamPickup)}>
                    <FontAwesomeIcon icon={faCamera} className="" />
                </div>
                {isWebcamPickup ?
                    <div className="attachment-camera-preview">
                        {isWebcamFrozenPickup ?
                            <img src={webcamImagePickup} />
                            :
                            <Webcam
                                audio={false}
                                height={videoConstraintsPickup.renderheight}
                                ref={webcamRefPickup}
                                screenshotFormat="image/jpeg"
                                width={videoConstraintsPickup.renderwidth}
                                videoConstraints={videoConstraintsPickup}
                            />
                        }
                        {renderCameraButtonsPickup()}
                    </div>
                : null}
                <FilePond
                    //files={[]}
                    className="mt-3"
                    allowMultiple={false}
                    acceptedFileTypes={['image/*']}
                    server={FilePondSettings(Number(picId), i, Number(picId), i)}
                    credits={false}
                    ref={filepondRefPickup}
                />
            </div>
        )
    }
    const resetCameraPickup = () => {
        setWebcamPickup(false);
        setWebcamFrozenPickup(false);
        setWebCamImagePickup('')
        setWebCamImageNamePickup('')
    }

    // camera upload for equipment photos
    const uploadCamera = () => {
        //console.log("upload");
        var fp = filepondRef.current;
        //console.log(fp);
        if (fp) {
            //console.log("camera");
            fp.addFile(webcamImage).then(item => {
                var n = webcamImageName;
                if (n)
                    item.file.name = n;
                var t = item.file.type; // attempt to append the correct filetype
                t = t.replace("image/", "");
                item.file.name = item.file.name + "." + t;
            });
        }
        resetCamera();
    }
    const renderCameraButtons = () => {
        return <div>
            {webcamImage && isWebcamFrozen ?
                <div className="row justify-content-center">
                    <input type="text"
                        className="enc-input-100"
                        name="webcamImageName"
                        value={webcamImageName}
                        placeholder="Image Name"
                        onChange={e => setWebCamImageName(e.target.value)}
                    />
                </div>
                : null}
            <div className="row justify-content-center">
                <Button className="enc-button" onClick={() => resetCamera()}>Cancel</Button>&nbsp;
                <Button className="enc-button" onClick={() => webcamSnapshot()}>{isWebcamFrozen ? "Reset" : "Snapshot"}</Button>&nbsp;
                {webcamImage && isWebcamFrozen ?
                    <Button className="enc-button" onClick={() => uploadCamera()}>Upload</Button>
                    : null}
            </div>
        </div>;
    }
    const renderUpload = (picId, i) => {
        return (
            <div className="attachment-container">
                <div className="attachment-camera clickable" onClick={() => { setWebcam(!isWebcam); setPicIndex(i); setPicType(picId); }}>
                    <FontAwesomeIcon icon={faCamera} className="" />
                </div>
                {(isWebcam === true && Number(picIndex) === Number(i)) ?
                    <div className="attachment-camera-preview">
                        {isWebcamFrozen ?
                            <img src={webcamImage} />
                            :
                            <Webcam
                                audio={false}
                                height={videoConstraints.renderheight}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                width={videoConstraints.renderwidth}
                                videoConstraints={videoConstraints}
                            />
                        }
                        {renderCameraButtons()}
                    </div>
                    : null}
                <FilePond
                    //files={[]}
                    className="mt-3"
                    allowMultiple={false}
                    acceptedFileTypes={['image/*']}
                    server={FilePondSettings(Number(picType), Number(picIndex), picId, i)}
                    credits={false}
                    ref={filepondRef}
                />
            </div>
        )
    }
    const resetCamera = () => {
        setWebcam(false);
        setWebcamFrozen(false);
        setWebCamImage('');
        setWebCamImageName('');
    }

    return (
        <div className="container px-0">
            <div>
                <h4 className="text-center itemHeaderENCLight">Equipment Checklist</h4>
            </div>

            <div className="container border border-dark" style={{ minHeight: "25em" }}>
                <form action="#" onSubmit={(e) => { e.preventDefault(); SubmitForm() }}>
                    <hr />
                    <div className="row">
                        <div className="col">
                            <input
                                className="enc-input-100"
                                placeholder="Unit #"
                                value={form.unitNumber}
                                onChange={(e) => changeForm('unitNumber', e.target.value)}
                                required />
                        </div>
                        <div className="col">
                            <DropdownList
                                disabled={form.equipmentFormId > 0}
                                placeholder="Equipment Type"
                                data={equipmentFormNames}
                                textField="name"
                                valueField="equipmentFormNameId"
                                value={form.equipmentFormNameId}
                                onChange={(e) => changeForm('equipmentFormNameId', e.equipmentFormNameId)}
                            />
                        </div>
                    </div>
                    {/* Only Load Bottom of Form once Vehicle Type is Selected */}
                    {form.equipmentFormNameId !== -1 &&
                        <>
                            {form.cbCategories.map((category, cIndex) =>
                                <ListGroup key={`cat-${cIndex}`} className="mt-3 ">
                                    <ListGroup.Item className="shaded-list">
                                        <strong>{category.category}:</strong>
                                    </ListGroup.Item>
                                    {category.checkBoxes.map((checkbox, cbIndex) =>
                                        <ListGroup.Item key={`${cIndex}-${cbIndex}-Checkbox`} className="shaded-list">
                                            <div className="row">
                                                <div className="col-1">
                                                    <input type="checkbox"
                                                        checked={checkbox.passed}
                                                        onChange={() => changeCheckBox(cIndex, cbIndex, 'passed', !checkbox.passed)}
                                                        id={`${props.iterator}-checkbox-${checkbox.equipmentCheckBoxId}`}
                                                        className="regular-checkbox big-checkbox" />
                                                    <label htmlFor={`${props.iterator}-checkbox-${checkbox.equipmentCheckBoxId}`}></label>
                                                </div>
                                                <div className="col">
                                                    {checkbox.checkBoxName}
                                                </div>
                                                <div className="col">
                                                    {!checkbox.passed &&
                                                        <textarea
                                                            className="enc-textarea"
                                                            value={checkbox.comment}
                                                            placeholder="Description of problem"
                                                            onChange={(e) => changeCheckBox(cIndex, cbIndex, 'comment', e.target.value)}
                                                            required />}

                                                </div>
                                            </div>
                                        </ListGroup.Item>
                                    )}
                                </ListGroup>
                            )}
                            <div className="row mt-2">
                                <div className="col pt-3">
                                    <h5>Photo Uploads</h5>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col">
                                    <div className="row">
                                        <div className="col">
                                            <strong>Damage Photos</strong>
                                        </div>
                                    </div>
                                    {form.damagePhotos.map((m, i) =>

                                        <ListGroup className="mt-1" key={i}>
                                            <ListGroup.Item className="clickable" onClick={() => GetEquipmentPicture(m.equipmentFormPictureId, m.fileName)}>
                                                <strong>{m.fileName}</strong>
                                            </ListGroup.Item>
                                        </ListGroup>

                                    )}
                                {renderUploadDmg(0, 'dmg')}
                                </div>
                            </div>
                            {deliveryPhoto === true && (
                                <div className="row mt-2">
                                    <div className="col">
                                        <div className="row">
                                            <div className="col">
                                                <strong>Delivery Photos</strong>
                                            </div>
                                        </div>
                                        {form.deliveryPhotos.map((m, i) =>

                                            <ListGroup className="mt-1" key={i}>
                                                <ListGroup.Item className="clickable" onClick={() => GetEquipmentPicture(m.equipmentFormPictureId, m.fileName)}>
                                                    <strong>{m.fileName}</strong>
                                                </ListGroup.Item>
                                            </ListGroup>

                                        )}
                                        {renderUploadDelivery(-1, 'delivery')}
                                    </div>
                                </div>
                        )}
                        {pickupPhoto === true && (
                                <div className="row mt-2">
                                    <div className="col">
                                        <div className="row">
                                            <div className="col">
                                                <strong>Pickup Photos</strong>
                                            </div>
                                        </div>
                                        {form.pickupPhotos.map((m, i) =>

                                            <ListGroup className="mt-1" key={i}>
                                                <ListGroup.Item className="clickable" onClick={() => GetEquipmentPicture(m.equipmentFormPictureId, m.fileName)}>
                                                    <strong>{m.fileName}</strong>
                                                </ListGroup.Item>
                                            </ListGroup>

                                        )
                                        }
                                        {renderUploadPickup(-2, 'pickup')}
                                    </div>
                                </div>
                            )}
                            {form.pictures.map((p, picIndex) => {
                                return (
                                    <div key={`pic-${picIndex}`} className="row mt-2">
                                        <div className="col">
                                            <div className="row">
                                                <div className="col">
                                                    <strong>{p.equipmentPhotoTypeName} <small>*REQUIRED</small></strong>
                                                </div>
                                            </div>
                                            {p.files.map((m, i) =>
                                                <ListGroup className="mt-1" key={i}>
                                                    <ListGroup.Item  >
                                                        <div className="row">
                                                            <strong className="col-8">{m.fileName}</strong>

                                                            <div className="col-2 ">
                                                                <FontAwesomeIcon className="clickable" icon={faDownload} onClick={() => GetEquipmentPicture(m.equipmentFormPictureId, m.fileName)} />
                                                            </div>
                                                            <div className="col-2">
                                                                <FontAwesomeIcon icon={faTrash} className="clickable" onClick={() => deletePicture(m.equipmentFormPictureId, picIndex)} />
                                                            </div>
                                                        </div>
                                                    </ListGroup.Item>
                                                </ListGroup>)}
                                            {p.files.length === 0 &&
                                                renderUpload(p.equipmentPhotoTypeId, picIndex)
                                            }
                                        </div>
                                    </div>
                                )
                            })}
                            <textarea value={form.comments}
                                className="enc-textarea my-2"
                                onChange={(e) => changeForm('comments', e.target.value)}
                                placeholder="comments" />
                            <div className="row">
                                <div className="col">
                                    <span style={{ color: 'red' }}>{error}</span>
                                </div>

                                <div className="col text-right pb-2">
                                    {props.stepId ?
                                        <>
                                            <Button className="enc-button mr-2" onClick={SaveForm}>Save Form</Button>
                                            <Button className="enc-button" onClick={SubmitForm}>Submit Form</Button>
                                        </> :
                                        <Button className="enc-button" onClick={SubmitForm}>Save Changes</Button>
                                    }
                                </div>
                            </div>
                        </>
                    }
                </form>
            </div>
        </div>
    )
}
