import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { CheckRevertScopePermission, RevertScope } from '../API/Jobs';

export default props => {
    const [show, setShow] = useState(false);
    const [revertPermission, setRevertPermission] = useState(false);

    const [confirm, setConfirm] = useState(false)

    const Revert = () => {
        RevertScope(props.jobId)
            .then(res => {
                setShow(false)
                setConfirm(true)
            })
    }

    useEffect(() => {
        CheckRevertScopePermission()
            .then(res => {
                setRevertPermission(res.success)
            })
    }, [])

    return (
        <>
            { revertPermission &&
                <>
                    <Button onClick={() => setShow(true)} className="enc-button">
                        Return to Scope Phase
                    </Button>
                    <Modal show={show} onHide={() => setShow(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirm Returning to Scope</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Please confirm you would like to return job to scoping phase.</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShow(false)}>Close</Button>
                            <Button className="enc-button" onClick={() => Revert()}>Save changes</Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal
                        show={confirm}
                        onClose={() => setConfirm(false)}
                    >
                        <Modal.Header>
                            <strong className="mr-auto">Reverted Job</strong>
                        </Modal.Header>
                        <Modal.Body>Job reverted to scope phase successfully.</Modal.Body>
                        <Modal.Footer>
                            <Button className="enc-button" onClick={() => setConfirm(false)}>Close</Button>
                        </Modal.Footer>
                    </Modal>
                </>
            }
        </>

    )
}
