const APIRequest = (params) => {
    return new Promise((resolve, reject) => {
        let token;

        const LS = localStorage.getItem("BAENCToken");
        const SS = sessionStorage.getItem("BAENCToken");

        if (LS) {
            token = LS;
        } else if (SS) {
            token = SS;
        }

        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        if (token) {
            headers['Authorization'] = `Bearer ${token}`
        }

        fetch(params.url, {
            method: params.method,
            headers: headers,
            body: JSON.stringify(params.body)
        }).then(response => {
            response.json()
            .then(data => {
                if(response.ok){
                    resolve(data);
                }
                else if (response.status === 401) {
                    reject({ reason: "Unauthorized", status: response.status, details: data});
                }
                else if(response.status === 400){
                    reject({ reason: "Bad Request", status: response.status, details: data })
                }
                else {
                    throw Error(response)
                }
            })
            .catch(error =>{
                reject({reason: "Unknown", details: error})
            })
        })
    })
}

export default APIRequest;

export const APIFileUpload = (params) => {
    return new Promise((resolve, reject) => {
        let token;

        const LS = localStorage.getItem("BAENCToken");
        const SS = sessionStorage.getItem("BAENCToken");

        if (LS) {
            token = LS;
        } else if (SS) {
            token = SS;
        }

        const headers = {
        }



        if (token) {
            headers['Authorization'] = `Bearer ${token}`
        }

        fetch(params.url, {
            method: params.method,
            headers: headers,
            body: params.body
        }).then(response => {
            if (response.status === 401) {
                reject({ reason: "Unauthorized", details: response });
            }
            if (!response.ok)
                throw Error(response)
            return response.blob();
        }).then(data => {
            resolve(data);
        }).catch(error => {
            reject({ reason: "Unknown", details: error })
        })
    });
}

export const APIGenericFileIteration2 = (params) => {
    return new Promise((resolve, reject) => {
        let token;

        const LS = localStorage.getItem("BAENCToken");
        const SS = sessionStorage.getItem("BAENCToken");

        if (LS) {
            token = LS;
        } else if (SS) {
            token = SS;
        }

        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        if (token) {
            headers['Authorization'] = `Bearer ${token}`
        }

        fetch(params.url, {
            method: params.method,
            headers: headers,
            body: JSON.stringify(params.body)
        }).then(response => {
            if (response.status === 401) {
                reject({ reason: "Unauthorized", details: response });
            }
            if (!response.ok)
                throw Error(response)
            
            let content = response.headers.get("content-disposition").split(';')[1].split('filename')[1].split('=')[1].trim();
            return {
                fileName: content,
                blob: response.blob()
            }

        }).then(data => {
            resolve(data);
        }).catch(error => {
            reject({ reason: "Unknown", details: error })
        })
    });
}

export const APIGenericFile = (params) => {
    return new Promise((resolve, reject) => {
        let token;

        const LS = localStorage.getItem("BAENCToken");
        const SS = sessionStorage.getItem("BAENCToken");

        if (LS) {
            token = LS;
        } else if (SS) {
            token = SS;
        }

        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        if (token) {
            headers['Authorization'] = `Bearer ${token}`
        }

        fetch(params.url, {
            method: params.method,
            headers: headers,
            body: JSON.stringify(params.body)
        }).then(response => {
            if (response.status === 401) {
                reject({ reason: "Unauthorized", details: response });
            }
            if (!response.ok)
                throw Error(response)
            return response.blob();
        }).then(data => {
            resolve(data);
        }).catch(error => {
            reject({ reason: "Unknown", details: error })
        })
    });
}

export const APIPDFRequest = (params) => {
    return new Promise((resolve, reject) => {
        let token;

        const LS = localStorage.getItem("BAENCToken");
        const SS = sessionStorage.getItem("BAENCToken");

        if (LS) {
            token = LS;
        } else if (SS) {
            token = SS;
        }

        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/pdf'
        }
        if (token) {
            headers['Authorization'] = `Bearer ${token}`
        }

        fetch(params.url, {
            method: params.method,
            headers: headers,
            body: JSON.stringify(params.body)
        }).then(response => {
            if (response.status === 401) {
                reject({ reason: "Unauthorized", details: response });
            }
            if (!response.ok)
                throw Error(response)
            return response.blob();
        }).then(data => {
            resolve(data);
        }).catch(error => {
            reject({ reason: "Unknown", details: error })
        })
    });
}

export const APIXLSXRequest = (params) => {
    return new Promise((resolve, reject) => {
        let token;

        const LS = localStorage.getItem("BAENCToken");
        const SS = sessionStorage.getItem("BAENCToken");

        if (LS) {
            token = LS;
        } else if (SS) {
            token = SS;
        }

        const headers = {
            'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'Content-Type': 'application/json'
        }
        if (token) {
            headers['Authorization'] = `Bearer ${token}`
        }

        fetch(params.url, {
            method: params.method,
            headers: headers,
            body: JSON.stringify(params.body)
        }).then(response => {
            if (response.status === 401) {
                reject({ reason: "Unauthorized", details: response });
            }
            if (!response.ok)
                throw Error(response)
            return response.blob();
        }).then(data => {
            resolve(data);
        }).catch(error => {
            reject({ reason: "Unknown", details: error })
        })
    });
}

export const APIPostFileReturn = (params) => {
    return new Promise((resolve, reject) => {
        let token;

        const LS = localStorage.getItem("BAENCToken");
        const SS = sessionStorage.getItem("BAENCToken");

        if (LS) {
            token = LS;
        } else if (SS) {
            token = SS;
        }

        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        if (token) {
            headers['Authorization'] = `Bearer ${token}`
        }

        fetch(params.url, {
            method: params.method,
            headers: headers,
            body: JSON.stringify(params.body)
        }).then(response => {
            if (response.status === 401) {
                reject({ reason: "Unauthorized", details: response });
            }
            if (!response.ok)
                throw Error(response)
            return response.blob();
        }).then(data => {
            resolve(data);
        }).catch(error => {
            reject({ reason: "Unknown", details: error })
        })
    });
}