import APIRequest, { APIXLSXRequest } from './MainAPICall/api';
import config from '../APIConfig';

export const VerifyTimesheet = (payload) => {
    return APIRequest({
        url: config.baseURL + "/Timesheet/VerifyTimesheet",
        method: "post",
        body: payload
    });
}

export const DeleteTimesheet = (id) => {
    return APIRequest({
        url: config.baseURL + "/Timesheet/DeleteTimesheet",
        method: "post",
        body: { id: id }
    });
}

export const GetTimesheetList = (payload) => {
    return APIRequest({
        url: config.baseURL + "/Timesheet/GetTimesheetList",
        method: "post",
        body: payload
    });
}

export const GetTimesheet = (id) => {
    return APIRequest({
        url: config.baseURL + "/Timesheet/GetTimesheet",
        method: "post",
        body: { id: id }
    });
}

export const GetTimeSheetReport = (payload) => {
    return APIXLSXRequest({
        url: config.baseURL + "/Timesheet/GetTimeSheetReport",
        method: "post",
        body: payload
    });
}
