import APIRequest from './MainAPICall/api';
import config from '../APIConfig';

export const GetOfficeList = () => {
    return APIRequest({
        url: config.baseURL + "/Office/GetOfficeList",
        method: "get",
    });
}

export const GetOffice = (id) => {
    return APIRequest({
        url: config.baseURL + "/Office/GetOffice",
        method: "post",
        body: {
            id: id
        }
    });
}
export const SaveOffice = (payload) => {
    return APIRequest({
        url: config.baseURL + "/Office/SaveOffice",
        method: "post",
        body: payload
    });
}

export const DeleteOffice = (id) => {
    return APIRequest({
        url: config.baseURL + "/Office/DeleteOffice",
        method: "post",
        body: {
            id: id
        }
    });
}