import React from "react";

const FloatingLabel = ({ component = "input", placeholder, className = "", ...rest }) => (
  <label
    className={`floating-label ${className} ${rest.value ? "floating" : ""}`}
    htmlFor={rest.id}
  >
    {React.createElement(component, rest)}
    <span>{placeholder}</span>
  </label>
);

export default FloatingLabel;
