
import React from 'react';
import { Button } from 'react-bootstrap';

export default props => {
    return (
        <>
            <form action="#" onSubmit={(e) => { e.preventDefault(); props.save() }}>
                <div className="row">
                    <div className="col">
                        Prevailing wage: 
                        <div className="toggle-switch small-switch">
                            <input
                                type="checkbox"
                                className="toggle-switch-checkbox"
                                id="prevailingWage"
                                checked={props.prevailingWage}
                                onChange={() => props.change('prevailingWage', !props.prevailingWage)}
                            />
                            <label className="toggle-switch-label" htmlFor="prevailingWage">
                                <span className={"toggle-switch-inner"} />
                                <span
                                    className={"toggle-switch-switch"}
                                />
                            </label>
                        </div>
                    </div>
                </div>
                <div className="divider my-3" />
                <div className="row">
                    <div className="col">
                        <textarea className="enc-textarea" id="additionalinfo" value={props.additionalInfo} onChange={(e) => props.change('additionalInfo', e.target.value)} placeholder="Additional Info..." />
                    </div>
                </div>
                <div className="row">
                    <div className="col text-right">
                        <Button className="enc-button" onClick={() => props.save()}>Save</Button>
                    </div>
                </div>
            </form>
        </>
    )
}
