import React, { useEffect, useState } from 'react';
import { GetJobList } from '../API/Jobs';
import { useHistory, Link } from 'react-router-dom';
import { ListGroup, Button } from 'react-bootstrap';

export default props => {
    const [jobs, setJobs] = useState([]);
    const [closedJobs, setClosedJobs] = useState(false);

    const oppId = props.match.params.oppId


    useEffect(() => {
        LoadJobs(parseInt(oppId), closedJobs)
    },[oppId, closedJobs])

    const LoadJobs = (id, closed) => {
        GetJobList(closed, -1, id)
            .then(res => {
                setJobs(res.data)
            })
    }
    const history = useHistory();
    const navigateToJob = (id, stageName) => {
        let stage = stageName.replace(/\s/g, '')
        history.push(`${props.match.url}/${id}/${stage}`)
    }
    return (
        <>
            <div className="container">
                <div className="row my-2">
                <div className="col-8 text-right">
                        <div className="toggle-switch small-switch">
                            <input
                                type="checkbox"
                                className="toggle-switch-checkbox"
                                id="disabled"
                                checked={closedJobs}
                                onChange={() => setClosedJobs(!closedJobs)}
                            />
                            <label className="toggle-switch-label" htmlFor="disabled">
                                <span className={"toggle-switch-inner"} />
                                <span
                                    className={"toggle-switch-switch"}
                                />
                            </label>
                        </div>
                    &nbsp; &nbsp;Show Canceled Jobs
                </div>
                    <div className="col text-right">
                    <Button className="enc-button" as={Link} to={`${props.match.url}/NewJob`}>New Job</Button>
                    </div>
                </div>
                <ListGroup>
                    <ListGroup.Item className="enc-data-list">
                        Job List
                    </ListGroup.Item>
                    <ListGroup.Item className="enc-data-list">
                        <div className="row">
                            <div className="col-lg-4">
                                Name
                            </div>
                            <div className="col-lg-3">
                                Classification
                            </div>
                            <div className="col-lg-2">
                                Job Number
                            </div>
                            <div className="col-lg-3">
                                Step
                            </div>
                        </div>
                    </ListGroup.Item>
                    {jobs.length === 0 && <ListGroup.Item>No Jobs to Display</ListGroup.Item>}
                    {jobs.map(m =>
                        <ListGroup.Item key={`LGI-${m.jobId}`} onClick={() => navigateToJob(m.jobId, m.jobStage)} className="click-list">
                            <div className="row">
                                <div className="col-lg-4">
                                    {m.canceled && "Cancelled -"} {m.jobName}
                                </div>
                                <div className="col-lg-3">
                                    {m.jobClassification}
                                </div>
                                <div className="col-lg-2">
                                    {m.jobNumber}
                                </div>
                                <div className="col-lg-3">
                                    {m.jobStep}
                                </div>
                            </div>
                        </ListGroup.Item>)}
                </ListGroup>
            </div>
        </>
    )
}
