import React, { useState, useEffect, useCallback } from 'react';
import { SignJSA, GetJSASignatures } from "../../API/JSA"
import SignatureCanvas from 'react-signature-canvas';
import { Button, ListGroup } from 'react-bootstrap';
export default props => {

    const [newSignature, setNewSignature] = useState({
        name: null,
        signature: {}
    })
    const [signatures, setSignatures] = useState([])
    const [signatureErrors, setSignatureErrors] = useState({
        nameError: "",
        signatureError: ""
    })
    const getSignatures = useCallback(
        () => {

            GetJSASignatures(props.JSAId).then(resp => {
                setSignatures(resp.signatures)
            })
        },
        [props.JSAId]
    )



    useEffect(() => {
        getSignatures()

    }, [props.JSAId, getSignatures]

    )

    const changeForm = (value) => {
        let form = { ...newSignature }
        form.name = value
        setNewSignature(form)
    }

    const changeFormCallback = (e) => {
        return changeForm(e.target.value)
    }



    const signForm = () => {
        SignJSA({ name: newSignature.name, jsaId: parseInt(props.JSAId), signature: newSignature.signature.toDataURL() }).then(x => { getSignatures() })
    }

    const validateNameInput = () => {
        if (newSignature.name == null || newSignature.name === "") {
            let errors = { ...signatureErrors }
            errors.nameError = "Name Input Required"
            setSignatureErrors(errors)
            console.log("name validated")
            return false
        }
        else {
            return true
        }
    }

    const validateSignature = () => {
        if (newSignature.signature.isEmpty() === true) {
            console.log(`${newSignature.signature.isEmpty()}`)
            let errors = { ...signatureErrors }
            errors.signatureError = "Signature Must Be Provided"
            setSignatureErrors(errors)

            return false
        }
        else {
            console.log("thinks it's true")
            return true
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        if (validateNameInput() && validateSignature()) {
            signForm()
            document.getElementById("nameInput").value = null
            newSignature.signature.clear()
            let signature = { ...newSignature }
            signature.name = null;
            setNewSignature(signature)
            setSignatureErrors({ nameError: "", signatureError: "" })

        }
    }

    return (
        <>
            <div className="divider my-2" />
            <div className="row">
                <div className="col">
                    <h3>Sign Form </h3>
                </div>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="row">
                <div className="col-6">
                        <small>Signature</small>
                        <br />
                        <SignatureCanvas
                            ref={(ref) => { newSignature.signature = ref }}
                            onFocus={() => newSignature.signature.on()}
                            onBlur={() => newSignature.signature.off()}
                            penColor='black'
                            clearOnResize={false}
                            canvasProps={{ className: 'sigCanvas signature-style p1-border' }}
                        />
                    </div>
                    <div className="col-6">
                        <small>Signee Name - Printed</small>
                        <input
                            id="nameInput"
                            onChange={changeFormCallback}
                            type="text"
                            placeholder="Your Name"
                            className="enc-input-100"
                        />
                    </div>

                </div>

                <div className="row">
                    <div className="col text-right">
                        <Button type='submit' className="enc-button">Save Signature</Button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-8">
                        <p className="text-danger col-3">{signatureErrors.nameError}</p>
                        <p className="text-danger col-3">{signatureErrors.signatureError}</p>
                    </div>
                </div>
            </form>
                <ListGroup>
                    <ListGroup.Item className="enc-list">
                        Sign In Sheet
                    </ListGroup.Item>
                    {signatures.map((sig, index) =>
                    <ListGroup.Item key={`sig-${index}`}>
                        <div className="row">
                            <div className="col-1">{index+1}</div>
                            <div className="col-3"> {sig.name} </div>
                            <div className="col-3">
                                <img className="img-fluid" src={sig.dataURL} alt="signature" />
                            </div>
                        </div>
                    </ListGroup.Item>

                    )}
                </ListGroup>
        </>
    )
}