import React, { useState, useEffect } from 'react';
import { DropdownList } from 'react-widgets';
import { AllowedYardList } from '../../API/Inventory';
import { GetShopTicketList } from '../../API/ShopConversion';
import { ListGroup } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

export default props => {
    const [yards, setYards] = useState([]);
    const [selectedYard, setSelectedYard] = useState(null);
    const [tickets, setTickets] = useState([]);
    const [closed, setClosed] = useState(false)


    useEffect(() => {
        AllowedYardList()
            .then(res => {
                setYards(res.data)
                if (res.data.length > 0) {
                    setSelectedYard(res.data[0])
                }
            })
    }, [])
    useEffect(() => {
        if (selectedYard) {
            GetShopTicketList(selectedYard.yardId, closed)
                .then(res => {
                    setTickets(res.data)
                })
        }
    }, [selectedYard, closed])
    return (
        <div className="container">
            <div className="row mb-2">
                <div className="col-md-6">
                    <DropdownList
                        data={yards}
                        value={selectedYard}
                        textField="yardName"
                        onChange={e => setSelectedYard(e)}
                    />
                </div>
                <div className="col text-right">
                    <div className="toggle-switch small-switch">
                        <input
                            type="checkbox"
                            className="toggle-switch-checkbox"
                            id="disabled"
                            checked={closed}
                            onChange={() => setClosed(!closed)}
                        />
                        <label className="toggle-switch-label" htmlFor="disabled">
                            <span className={"toggle-switch-inner"} />
                            <span
                                className={"toggle-switch-switch"}
                            />
                        </label>
                    </div>
                    &nbsp; &nbsp;View Closed Tickets
                    </div>
            </div>
            <ListGroup>
                <ListGroup.Item className="enc-data-list">Shop Ticket List</ListGroup.Item>
                <ListGroup.Item className="enc-data-list">
                    <div className="row">
                        <div className="col-6">
                            Ticket Name
                        </div>
                        <div className="col">
                            Ticket Number
                        </div>
                        <div className="col">
                            Ticket Date
                        </div>
                    </div>
                </ListGroup.Item>
                {tickets.map((m, i) => {
                    return (
                        <ListGroup.Item key={`ShopTicket-${i}`} className="click-list" as={NavLink} to={`${props.match.url}/${m.shopTicketId}`}>
                            <div className="row">
                                <div className="col-6">
                                    {m.ticketName ? m.ticketName : `Unnamed Shop Ticket - ${m.shopTicketId}`}
                                </div>
                                <div className="col">
                                    {m.shopTicketId}
                                </div>
                                <div className="col">
                                    {m.date}
                                </div>
                            </div>
                        </ListGroup.Item>
                    )
                })}
            </ListGroup>
        </div>
    )
}
