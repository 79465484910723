import { Button } from 'react-bootstrap';
import {GetJSAPdf, GetFieldTicketPdf, GetEquipmentFormPdf, GetBol} from "../../../API/JobFormPdf";
import React from 'react';

export default props =>{


const loadPdf = () =>{

    switch (props.formType){
    case "JSA":
        GetJSAPdf(props.formId, (new Date()).getTimezoneOffset())
        .then(response=>{
        
            const fileURL = URL.createObjectURL(response)
            window.open(fileURL)
       
        })
        break
    case "Field Ticket":
        GetFieldTicketPdf(props.formId)
        .then(response=>{
        
            
            const fileURL = URL.createObjectURL(response)
            window.open(fileURL)
        })
        break
    case "Equipment Form":
        GetEquipmentFormPdf(props.formId)
        .then(response=>{
            const fileURL = URL.createObjectURL(response)
            window.open(fileURL)
        })
        break
    case "BOL":
        GetBol(props.formId)
        .then(response=>{
            const fileURL = URL.createObjectURL(response)
            window.open(fileURL)
        })
        break;
    default:
        break;
    }
}

return <Button className="enc-button" onClick={loadPdf}>Get PDF</Button>

}