import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default props => {
    return (
        <div>
            <div className="row">
                <div className="col">
                    <h4>Possible Matches Found:</h4>
                </div>
            </div>
            <ListGroup>
                <ListGroup.Item className="enc-data-list">
                    <div className="row">

                        <div className="col-4">
                            Account Number
                        </div>
                        <div className="col-8">
                            Account Name
                        </div>
                    </div>
                </ListGroup.Item>
                {props.matches &&
                    props.matches.map((m, i) => {
                        return (
                            <ListGroup.Item
                                className="click-list"
                                key={`possible-account-${i}`}
                                as={Link}
                                to={`/Accounts/${m.accountId}`}
                            >
                                <div className="row">
                                    <div className="col-4">
                                        {m.accountNumber}
                                    </div>
                                    <div className="col-8">
                                        {m.accountName}
                                    </div>
                                </div>
                            </ListGroup.Item>
                        )
                    })
                }
            </ListGroup>
        </div >
    )
}
