import React from 'react';
import { Card } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

export default props => {
    const history = useHistory();
    const handleClick = (id) => {
        history.push(`${props.url}/${props.accountId}/Contact/${id}`)
    }
    return (
        <div className="container-fluid mt-2">
            <div className="row mt-2">
                {props.contacts.map(c => {
                    // Variable to allow clickable header if props.click is set to true. Allows for reuse without a linking header.
                    let clickObject = props.click ? {
                        className: "clickable",
                        onClick: () => handleClick(c.accountContactId)
                    } : {}
                    return (
                        <Card key={`Contact-Card-${c.accountContactId}`} className="mx-2 mb-2" style={{ width: '18rem' }}>
                            <Card.Header {...clickObject} ><strong>{`${c.firstName} ${c.lastName}`}</strong></Card.Header>
                            <Card.Body>
                                <Card.Text className="mt-2">
                                    {c.phoneNumber &&
                                        <>
                                            <Card.Subtitle>
                                                <small>Phone Number</small>
                                            </Card.Subtitle>
                                            <Card.Link href={`tel:${c.phoneNumber}`}>{c.phoneNumber}</Card.Link>
                                        </>
                                    }

                                    {c.mobileNumber &&
                                        <>
                                            <Card.Subtitle>
                                                <small>Mobile Number</small>
                                            </Card.Subtitle>
                                            <Card.Link href={`tel:${c.mobileNumber}`}>{c.mobileNumber}</Card.Link>
                                        </>
                                    }

                                    {c.officeNumber &&
                                        <>
                                            <Card.Subtitle>
                                                <small>Office Number</small>
                                            </Card.Subtitle>
                                            <Card.Link href={`tel:${c.officeNumber}`}>{c.officeNumber}</Card.Link>
                                        </>
                                    }

                                    {c.otherNumber &&
                                        <>
                                            <Card.Subtitle>
                                                <small>Other Number</small>
                                            </Card.Subtitle>
                                            <Card.Link href={`tel:${c.otherNumber}`}>{c.otherNumber}</Card.Link>
                                        </>
                                    }


                                    <Card.Subtitle>
                                        <small>Email</small>
                                    </Card.Subtitle>
                                    <Card.Link href={`mailto:${c.email}`}>{c.email}</Card.Link>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    )
                }
                )}
            </div>
        </div >
    )
}
