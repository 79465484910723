import React, { useEffect, useState } from 'react';
import { ToggleButtonGroup, ToggleButton, Button } from 'react-bootstrap';
import { DropdownList } from 'react-widgets';
import { TransferYards, GetJobsWithInventory, TransferJobs } from '../../API/InventoryTransfer';

export default props => {
    const [fromType, setFromType] = useState(0)
    // From Type - 0 = Yard // 1 = Job
    const [toType, setToType] = useState(0)


    const [FromSelectionList, setFromSelectionList] = useState([])
    const [FromSelection, setFromSelection] = useState(null)
    const [ToSelectionList, setToSelectionList] = useState([])
    const [ToSelection, setToSelection] = useState(null)

    useEffect(() => {
        if (fromType === 0) {
            TransferYards()
                .then(res => {
                    setFromSelectionList(res.data)
                })
        }
        else if (fromType === 1) {
            GetJobsWithInventory()
                .then(res => {
                    setFromSelectionList(res.data)
                })
        }
    }, [fromType])

    useEffect(() => {
        if (toType === 0) {
            TransferYards()
                .then(res => {
                    setToSelectionList(res.data)
                })
        }
        else if (toType === 1) {
            TransferJobs()
                .then(res => {
                    setToSelectionList(res.data)
                })
        }
    }, [toType])

    const Save = () => {
        if (FromSelection && ToSelection) {
            props.save(fromType, FromSelection, toType, ToSelection)
        }
    }








    return (
        <div className="container">
            <form action="#" onSubmit={(e) => { e.preventDefault(); Save() }}>
                <div className="row">
                    <div className="col">
                        <h4>Select Origin</h4>
                    </div>
                </div>
                <div className="row my-2">
                    <div className="col-3">
                        Select Origin Type:
                        </div>
                    <div className="col">
                        <ToggleButtonGroup onChange={(e) => setFromType(e)} value={fromType} type="radio" name="options" defaultValue={fromType}>
                            <ToggleButton className="enc-toggle-button" value={0}>Yard</ToggleButton>
                            <ToggleButton className="enc-toggle-button" value={1}>Job</ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <DropdownList
                            filter="contains"
                            placeholder="Select Origin Location"
                            data={FromSelectionList.filter(x => !(ToSelection && (x.type === ToSelection.type && x.id === ToSelection.id)))}
                            onChange={e => setFromSelection(e)}
                            value={FromSelection}
                            textField="name"
                        />
                    </div>
                </div>
                <div className="divider my-2" />
                <div className="row mt-3">
                    <div className="col">
                        <h4>Select Destination</h4>
                    </div>
                </div>
                <div className="row my-2">
                    <div className="col-3">
                        Select Destination Type:
                        </div>
                    <div className="col">
                        <ToggleButtonGroup onChange={(e) => setToType(e)} value={toType} type="radio" name="options" defaultValue={toType}>
                            <ToggleButton className="enc-toggle-button" value={0}>Yard</ToggleButton>
                            <ToggleButton className="enc-toggle-button" value={1}>Job</ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <DropdownList
                            placeholder="Select Destination Location"
                            data={ToSelectionList.filter(x => !(FromSelection && (x.type === FromSelection.type && x.id === FromSelection.id)))}
                            onChange={e => setToSelection(e)}
                            value={ToSelection}
                            filter="contains"
                            textField="name"
                        />
                    </div>
                </div>
                <div className="row mt-2 mb-5">
                    <div className="col text-right">
                        <Button className="enc-button" type="submit">Confirm Selections</Button>
                    </div>
                </div>
            </form>
        </div>
    )
}
