import React, { useState } from 'react';
import FinalScreen from '../../Inventory/InventoryTransfer/FinalScreen';
import JobTransferDestination from './JobTransferDestination';
import JobTransferSelection from './JobTransferSelection';

export default props => {

    const [wizardStep, setWizardStep] = useState(0)

    /// Save Location Data from Server
    const [toLocation, setToLocation] = useState({})
    const [toType, setToType] = useState(0)



    const setToData = (type, model) => {
        setToType(type)
        setToLocation(model)
        setWizardStep(1)
    }

    const reset = () => {
        setToLocation({})
        setWizardStep(0)
    }


    const LoadScreenLogic = () => {
        switch (wizardStep) {
            case 0:
                return <JobTransferDestination save={setToData} />
            case 1:
                return <JobTransferSelection save={() => setWizardStep(2)} fromType={1} toType={toType} to={toLocation} fromId={props.jobId} />
            case 2:
                return <FinalScreen reset={reset} />
            default:
                return // Error Page
        }
    }
    return (
        <>
            <div className="container">
                <div className="row">
                    {LoadScreenLogic()}
                </div>
            </div>
        </>
    )
}
