import React, { useState, useEffect } from 'react';
import { Table, ListGroup } from 'react-bootstrap';
import { GetJobInventory } from '../API/Inventory';
import { Link } from 'react-router-dom';

export default props => {
    // Items That Are In Inventory
    const [assetInventory, setAssetInventory] = useState([]);
    const [jobReadyInventory, setJobReadyInventory] = useState([]);
    const [bulkInventory, setBulkInventory] = useState([]);

    const [filter, setFilter] = useState("");

    useEffect(() => {
        let id = props.match.params.jobId;
        LoadJobInventory(id);
    }, [props.match.params.jobId]);

    const LoadJobInventory = (id) => {
        GetJobInventory(id)
            .then(res => {
                setAssetInventory(res.data.assets);
                setJobReadyInventory(res.data.jobReadyItems);
                setBulkInventory(res.data.bulkItems)
            })
    }
    
    return (
        <>
            <div className="row">
                <div className="col text-right">
                   <input className={"enc-input"} placeholder="Item Name Filter" value={filter} onChange={(e) => setFilter(e.target.value)} />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    Job Ready Items
                </div>
            </div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Item Name</th>
                        <th>Historical</th>
                        <th>Inventory</th>
                    </tr>
                </thead>
                <tbody>
                    {jobReadyInventory.filter(e => e.name.toLowerCase().includes(filter.toLowerCase())).map((m, index) =>
                        <tr key={`Row-${m.jobInventoryId}`}>
                            <td>{m.name}</td>
                            <td>{m.totalAmount}</td>
                            <td>{m.currentAmount}</td>
                        </tr>
                    )}
                </tbody>
            </Table>

            <div className="row">
                <div className="col">
                    Bulk Items
                </div>
            </div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Item Name</th>
                        <th>Historical</th>
                        <th>Inventory</th>
                    </tr>
                </thead>
                <tbody>
                    {bulkInventory.filter(e => e.bulkItemName.toLowerCase().includes(filter.toLowerCase())).map((m, index) =>
                        <tr key={`Row-${m.jobInventoryBulkId}`}>
                            <td>{m.bulkItemName}</td>
                            <td>{m.totalAmount}</td>
                            <td>{m.currentAmount}</td>
                        </tr>
                    )}
                </tbody>
            </Table>
            
            <div className="row">
                <div className="col">
                    Assets
                </div>
            </div>
            <ListGroup>
                <ListGroup.Item className="click-list">
                    <div className="row">
                        <div className="col">
                            Name
                            </div>
                        <div className="col">
                            Serial Number
                            </div>
                        <div className="col">
                            Status
                        </div>
                    </div>
                </ListGroup.Item>
                {assetInventory.map((m, i) =>
                    <ListGroup.Item key={`AssetInventory-${i}`} className="click-list" as={Link} to={`/Inventory/Asset/${m.serializedItemId}`}>
                        <div className="row">
                            <div className="col">
                                {m.name}
                            </div>
                            <div className="col">
                                {m.serialNumber}
                            </div>
                            <div className="col">
                                {m.status}
                            </div>
                        </div>
                    </ListGroup.Item>
                )}
            </ListGroup>
        </>
    )
}
