import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import React, { useEffect, useState } from 'react';
import { Alert, Button, ListGroup } from 'react-bootstrap';
import { DropdownList } from 'react-widgets';
import { GetProposalAttachment, PostProposalAttachment, ProposalListNames, GetAttachmentUpload } from '../../API/Proposal';

export default props => {
    const [prefills, setPrefills] = useState([])
    const [selected, setSelected] = useState(null);

    const [confirmSave, setConfirmSave] = useState(false);

    const [attachment, setAttachment] = useState({
        prefillAttachmentId: 0,
        attachmentName: "",
        termAndCondition: false,
        scopes: []
    })


    registerPlugin(FilePondPluginFileValidateType)


    useEffect(() => {
        LoadAttachment(props.match.params.id)
    }, [props.match.params.id])

    useEffect(() => {
        LoadProposalPrefills()
    }, [])

    const LoadAttachment = (id) => {
        GetProposalAttachment(id)
            .then(res => {
                setAttachment(res.data)
            })
    }

    const LoadProposalPrefills = () => {
        ProposalListNames()
            .then(res => {
                setPrefills(res.data)
            })
    }

    const AddScopeLine = () => {
        if (selected) {
            let s = { ...attachment }
            s.scopes.push(selected)
            setSelected(null)
            setAttachment(s)
        }
    }

    const RemoveScopeLine = (i) => {
        let s = { ...attachment }
        s.scopes.splice(i, 1)
        setAttachment(s)
    }

    const SaveAttachment = () => {
        PostProposalAttachment(attachment)
            .then(res => {
                LoadAttachment(props.match.params.id)
                setConfirmSave(true);
            })
    }

    const changeName = (value) => {
        let s = { ...attachment }
        s.attachmentName = value
        setAttachment(s)
    }

    const checkTerms = () => {
        let s = { ...attachment }
        s.termAndCondition = !s.termAndCondition
        setAttachment(s)
    }

    const [uploadComplete, setUploadComplete] = useState(false);

    const FilePondSettings = (id) => {
        let token;

        const LS = localStorage.getItem("BAENCToken");
        const SS = sessionStorage.getItem("BAENCToken");

        if (LS) {
            token = LS;
        } else if (SS) {
            token = SS;
        }

        return (
            {
                url: "/api/Proposal",
                process: {
                    url: "/UploadAttachment",
                    method: "post",
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    ondata: (formData) => {
                        formData.append("prefillAttachmentId", id);
                        return formData;
                    },
                    onload: (res) => {
                        setUploadComplete(true);
                        setChangeUpload(false);
                    }
                },
                load: null,
                revert: null,
                restore: null,
                fetch: null,
                patch: null
            })
    }

    const GetUpload = () => {
        GetAttachmentUpload(attachment.prefillAttachmentId)
            .then(blob => {
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                }
                else {
                    var objectUrl = URL.createObjectURL(blob);
                    window.open(objectUrl);
                }
            })
    }

    const [changeUpload, setChangeUpload] = useState(false);


    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h3>Attachment Editor</h3>
                </div>
                <div className="col text-right">
                    <Button className="enc-button" onClick={GetUpload}>
                        Preview
                    </Button>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col">
                    <label>Attachment Name</label>
                </div>
                <div className="col">
                    <input
                        type='text'
                        value={attachment.attachmentName}
                        className='enc-input-100'
                        onChange={e => changeName(e.target.value)}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="toggle-switch small-switch">
                        <input
                            type="checkbox"
                            className="toggle-switch-checkbox"
                            id="terms"
                            checked={attachment.termAndCondition}
                            onChange={checkTerms}
                        />
                        <label className="toggle-switch-label" htmlFor="terms">
                            <span className={"toggle-switch-inner"} />
                            <span
                                className={"toggle-switch-switch"}
                            />
                        </label>
                    </div>
                    &nbsp; &nbsp; Terms and Conditions
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-8">
                    <DropdownList
                        placeholder="Select a Scope Line"
                        data={prefills.filter(item => !attachment.scopes.find(({ leafId }) => item.leafId === leafId))}
                        textField="scopeName"
                        filter='contains'
                        onChange={e => setSelected(e)}
                        value={selected}
                    />
                </div>
                <div className="col text-right">
                    <Button className="enc-button" onClick={AddScopeLine}>Add Scope Line</Button>
                </div>
            </div>



            <ListGroup className="mt-2">
                <ListGroup.Item className="enc-list">
                    Scopes Linked
                    </ListGroup.Item>
                {attachment.scopes.map((m, i) => {
                    return (
                        <ListGroup.Item key={`Prefill-${i}`}>
                            <div className="row">
                                <div className="col">
                                    {m.scopeName}
                                </div>
                                <div className="col-2">
                                    <FontAwesomeIcon icon={faTrash} className="clickable" onClick={e => RemoveScopeLine(i)} />
                                </div>
                            </div>
                        </ListGroup.Item>
                    )
                })}
            </ListGroup>
            <div className="row mt-2">
                <div className="col-8">
                    <div className="col-8">
                        <Alert variant="success" show={confirmSave} onClose={() => setConfirmSave(false)} dismissible>
                            <Alert.Heading>Attachment Data Saved</Alert.Heading>
                            <p>
                                Successfully saved attachment data.
                            </p>
                        </Alert>
                    </div>
                </div>
                <div className="col text-right">
                    <Button className="enc-button" onClick={SaveAttachment}>Save</Button>
                </div>
            </div>


            <div className="row mt-2">
                <div className="col">
                    <div className="toggle-switch small-switch">
                        <input
                            type="checkbox"
                            className="toggle-switch-checkbox"
                            id="disabled"
                            checked={changeUpload}
                            onChange={e => setChangeUpload(!changeUpload)}
                        />
                        <label className="toggle-switch-label" htmlFor="disabled">
                            <span className={"toggle-switch-inner"} />
                            <span
                                className={"toggle-switch-switch"}
                            />
                        </label>
                    </div>
                    &nbsp; &nbsp; Change Uploaded Document
                    <br />
                    <small>Warning: This will instantly replace the uploaded document.</small>
                </div>
            </div>
            <div className="row my-3">
                <Alert show={uploadComplete} onClose={() => setUploadComplete(false)} variant="success" dismissible>
                    <Alert.Heading>Upload Complete</Alert.Heading>
                </Alert>
                <div className="col">
                    {changeUpload && <FilePond
                        files={[]}
                        acceptedFileTypes={['application/pdf']}
                        className="mt-3"
                        allowMultiple={false}
                        server={FilePondSettings(attachment.prefillAttachmentId)}
                        credits={false}

                    />}
                </div>
            </div>

        </div>
    )
}
