import APIRequest,{ APIXLSXRequest } from './MainAPICall/api';
import config from '../APIConfig';


export const GetScopeBuilder = () => {
    return APIRequest({
        url: config.baseURL + "/JobScope/ScopeBuilder",
        method: "get",
    });
}

export const GetScopeBuilderNoOther = () => {
    return APIRequest({
        url: config.baseURL + "/JobScope/ScopeBuilderNoOther",
        method: "get",
    });
}

export const PostJobScope = (payload) => {
    return APIRequest({
        url: config.baseURL + "/JobScope/JobScope",
        method: "post",
        body: payload
    });
}

export const EditScopeItem = (payload) => {
    return APIRequest({
        url: config.baseURL + `/JobScope/EditJobScopeItem`,
        method: "post",
        body: payload
    });
}

export const DeleteScopeItem = (id) => {
    return APIRequest({
        url: config.baseURL + `/JobScope/DeleteJobScopeItem?ScopeItemId=${id}`,
        method: "get"
    });
}

export const GetJobScopeList = (id) => {
    return APIRequest({
        url: config.baseURL + `/JobScope/JobScopeList?HandOffId=${id}`,
        method: "get"
    });
}

export const SetJobScopeItemSummary = (payload) => {
    return APIRequest({
        url: config.baseURL + "/JobScope/JobScopeIncludeSummary",
        method: "post",
        body: payload
    });
}

export const GetJobScopeBudgetSummary = (id) => {
    return APIXLSXRequest({
        url: config.baseURL + `/JobScope/GetJobScopeBudgetSummary?HandOffId=${id}`,
        method: "get"
    });
}