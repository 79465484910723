import APIRequest from './MainAPICall/api';
import config from '../APIConfig';

export const AuthRequest = (username, password) => {
    return APIRequest({
        url: config.baseURL + "/Authentication/Authenticate",
        method: "post",
        body: {
            username: username,
            password: password
        }
    });
}

export const CheckAuth = () => {
    return APIRequest({
        url: config.baseURL + "/Authentication/CheckAuth",
        method: "get"
    })
}