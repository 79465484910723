import React, { useEffect, useState } from 'react';
import BudgetEditView from './Budgeting/BudgetEditView';
import { FlagStage2Review, GetActiveEdit } from '../../API/PB';
import BudgetView from './Budgeting/BudgetView';
import Stage2Calculator from './Stage2Calculator';
import ProposalList from './Proposal/ProposalList';
import Attachments from '../../Components/Attachments';
import { Button } from 'react-bootstrap';
import JobReview from '../../Components/JobReview';

export default props => {
    const [editBudget, setEditBudget] = useState(false);
    const [allowReview, setAllowReview] = useState(false);
    const [allowProposal, setAllowProposal] = useState(false);
    const [review, setReview] = useState(false)
    
    const ReviewStage = () => {
        FlagStage2Review(props.jobId)
        .then(res => {
            LoadViews(props.jobId)
        })
    }

    useEffect(()=>{
        LoadViews(parseInt(props.jobId))
    },[props.jobId])

    const LoadViews = (id) => {
        GetActiveEdit(id)
        .then(res=>{
            setEditBudget(res.displayEditBudget)
            setAllowReview(res.allowReview)
            setAllowProposal(res.allowProposal)
            setReview(res.displayReview)
            //setEditBudget(true)
            //setAllowReview(true)
            //setAllowProposal(true)
            //setReview(true)
        })
    }

    const reloadViews = () => {
        props.reload()
        LoadViews(parseInt(props.jobId))
    }


    return (
        <div className="container">
            { editBudget? <BudgetEditView reloadParent={() => reloadViews()} jobId={props.jobId} /> : <BudgetView jobId={props.jobId}/>}
            <div className="divider my-3" />

            <Stage2Calculator jobId={props.jobId} />
            <div className="divider my-3" />
            <ProposalList 
            showButton = {allowProposal}
            reloadParent={() => reloadViews()} 
            url={props.match.url} 
            jobId={props.jobId}/>
            <div className="divider my-3" />
            <Attachments 
                jobId={props.jobId}
                jobStep={1}
                editable={true}
            />
            {allowReview&&
            <div className="row">
                <div className="col text-right">
                    <Button className="enc-button" onClick={ReviewStage}>Submit for Review</Button>
                </div>
            </div>}
            {review&&<JobReview  reloadParent={()=> reloadViews()} jobId={props.jobId} />}
        </div>
    )
}
