import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, Spinner } from 'react-bootstrap';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/lib/ReactCrop.scss';
import { useHistory } from 'react-router-dom';
import { GetUserSignature, PostUserSignature, SelfCheck } from '../API/User';

export default props => {
    const [img, setImg] = useState("")

    const [loggedIn, setLoggedIn] = useState(0)

    const [cropSettings, setCropSettings] = useState({
        unit: '%',
        width: 100,
        aspect: 16 / 9
    })

    const [showConfirm, setShowConfirm] = useState(false);
    const [disableButton, setDisabledButton] = useState(false);
    const [saving, setSaving] = useState(false);

    const selectImage = (e) => {
        let image = e.target.files[0];
        const fileTypes = ['jpg', 'jpeg', 'png'];
        const extension = image.name.split('.').pop().toLowerCase();
        if (fileTypes.indexOf(extension) === -1) {
            setImg("")
        } else {
            ConvertFileToCanvas(image)
        }
    }


    useEffect(() => {
        if (props.userId) {
            LoadUserSignature(props.userId)
        } else {
            SelfCheck()
                .then(res => {
                    LoadUserSignature(res.id)
                    setLoggedIn(res.id)
                })
        }
    }, [props.userId])

    const LoadUserSignature = (id) => {
        GetUserSignature(id)
            .then(res => {
                const convertUrl = () => {
                    return new Promise((resolve, reject) => {
                        var reader = new FileReader();
                        reader.onload = () => { resolve(reader.result) }
                        reader.readAsDataURL(res)
                    })
                }
                convertUrl()
                    .then(res => {
                        setImg(res)
                    })
            })
    }

    const getCropped = (image, crop) => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height,
        );
        return new Promise((resolve) => {
            canvas.toBlob(blob => {
                blob.name = 'signature.png'
                const fileUrl = window.URL.createObjectURL(blob);
                resolve(fileUrl)
            }, 'image/png');
        })
    }

    const ConvertFileToCanvas = (file) => {
        setDisabledButton(false);
        let img = new Image();
        img.onload = () => {
            CreateImageCanvas(img)
                .then(res => {
                    setImg(res)
                })
        }
        img.src = URL.createObjectURL(file);
    }

    const CreateImageCanvas = (image) => {
        const aspectWidth = Math.ceil(image.height * (16 / 9)) + 2
        const aspectHeight = Math.ceil(image.width / (16 / 9)) + 2

        const canvasHeight = aspectHeight > image.height ? aspectHeight : image.height;
        const canvasWidth = aspectWidth > image.width ? aspectWidth : image.width;


        const canvas = document.createElement('canvas');
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;
        const ctx = canvas.getContext('2d');
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, canvasWidth, canvasHeight)

        ctx.drawImage(
            image,
            canvasWidth / 2 - image.width / 2,
            canvasHeight / 2 - image.height / 2
        );

        return new Promise((resolve) => {
            canvas.toBlob(blob => {
                blob.name = 'signature.png'
                const fileUrl = window.URL.createObjectURL(blob);
                resolve(fileUrl)
            }, 'image/png');
        })
    }

    let imgRef = useRef();

    const createBlob = (image, crop) => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height,
        );
        return new Promise((resolve) => {
            canvas.toBlob(blob => {
                blob.name = 'signature.png'
                resolve(blob);
            }, 'image/png', 1);

        })
    }

    const uploadSignature = () => {
        setSaving(true);
        setDisabledButton(true)
        createBlob(imgRef.current.imageRef, cropSettings)
            .then(blob => {
                let form = new FormData();
                form.append('file', blob)
                let userId = 0;
                if (props.userId) {
                    userId = props.userId
                } else {
                    userId = loggedIn
                }
                form.append('userId', userId)
                PostUserSignature(form)
                    .then(res => {
                        setCropSettings({ aspect: 16 / 9 })
                        if (props.userId) {
                            LoadUserSignature(props.userId)
                        } else {
                            LoadUserSignature(loggedIn)
                        }
                        setSaving(false);
                        setShowConfirm(true);
                    })
            })
    }

    const createPreview = () => {
        setDisabledButton(false);
        if (imgRef.current.imageRef && cropSettings.width && cropSettings.height) {
            getCropped(imgRef.current.imageRef, cropSettings)
                .then(imgUrl => {
                    setImg(imgUrl);
                })
        }
    }

    let history = useHistory();

    return (
        <div className="container">
            <div className="row mt-3">
                <div className="col">
                    <h3>Change User Signature</h3>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <input type="file" name="file" id="file" className="inputfile" onChange={selectImage} accept='image/*' />
                    <label htmlFor="file"><FontAwesomeIcon icon={faUpload} /> Choose a file</label>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <ReactCrop
                        ref={imgRef}
                        src={img}
                        onChange={cropSettings => setCropSettings(cropSettings)}
                        crop={cropSettings}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col text-right">
                    <Button variant="secondary" onClick={() => history.goBack()}>Cancel</Button>
                    <Button className="enc-button ml-2" onClick={createPreview} >Crop</Button>
                    <Button className="enc-button ml-2" onClick={uploadSignature} disabled={disableButton}>
                        {saving ?
                            <>
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                    Saving...
                    </>
                            :
                            "Upload"
                        }
                    </Button>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Alert variant="success" show={showConfirm} onClose={() => setShowConfirm(false)} dismissible>
                        <Alert.Heading>Successfully saved signature.</Alert.Heading>
                    </Alert>
                </div>
            </div>
        </div>
    )
}
