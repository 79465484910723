import React, { useState, useEffect } from 'react';
import { DropdownList } from 'react-widgets';
import { GetEquipmentFormNames, GetEquipmentFormTemplate, GetEquipmentFormCategories, GetEquipmentFormPhotoTypes, GetEquipmentFormCheckboxes, PostEquipmentFormCheckbox, PostEquipmentFormPhotoType, PostEquipmentFormCategory, PostEquipmentFormName, PostEquipmentFormTemplate, DeleteEquipmentFormName } from '../../API/JobForm';
import { ListGroup, Button } from 'react-bootstrap';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default props => {
    const [formInformation, setFormInformation] = useState({
        equipmentFormNameId: -1,
        equipmentFormName: "",
        checkBoxesCategories: [],
        requiredPhotos: []
    });

    const [newForm, setNewForm] = useState("")

    const [newCategory, setNewCategory] = useState("")

    const [newPhoto, setNewPhoto] = useState("")

    const [newCheckBox, setNewCheckBox] = useState("")

    const [equipmentFormNames, setEquipmentFormNames] = useState([])

    const [categories, setCategories] = useState([])

    const [selectedCategory, setSelectedCategory] = useState(null)

    const [photoTypes, setPhotoTypes] = useState([])

    const [photoTypeIds, setPhotoTypeIds] = useState([])

    const [selectedPhoto, setSelectedPhoto] = useState(null)

    const [checkOptions, setCheckOptions] = useState([])

    const [checkOptionIds, setCheckOptionIds] = useState([])

    const [selectedCheckOption, setSelectedCheckOption] = useState(null)

    const [addCheckErr, setAddCheckErr] = useState("")
    const [addPhotoErr, setAddPhotoErr] = useState("")


    useEffect(() => {
        loadForm(formInformation.equipmentFormNameId)
    }, [formInformation.equipmentFormNameId])

    useEffect(() => {
        LoadEquipmentFormNames();
    }, [])

    useEffect(() => {
        LoadCategories();
        LoadPhotoTypes();
        LoadCheckBoxes();
    }, [])

    const LoadCategories = () => {
        GetEquipmentFormCategories()
            .then(res => {
                setCategories(res.data)
            })
    }

    const LoadPhotoTypes = () => {
        GetEquipmentFormPhotoTypes()
            .then(res => {
                setPhotoTypes(res.data)
            })
    }

    const LoadCheckBoxes = () => {
        GetEquipmentFormCheckboxes()
            .then(res => {
                setCheckOptions(res.data)
            })
    }

    const LoadEquipmentFormNames = () => {
        GetEquipmentFormNames()
            .then(res => {
                res.data.unshift({
                    equipmentFormNameId: -1,
                    name: "Select Equipment Form"
                })
                setEquipmentFormNames(res.data)
            })
    }


    const DeleteForm = () => {
        if (window.confirm("This will permenantly delete this form from the system. Please confirm.")) {
            DeleteEquipmentFormName(formInformation.equipmentFormNameId)
                .then(res => {
                    if (res.success) {
                        LoadEquipmentFormNames();
                        setFormInformation({
                            equipmentFormNameId: -1,
                            equipmentFormName: "",
                            checkBoxesCategories: [],
                            requiredPhotos: []
                        })
                    }
                })
        }
    }

    const AddNewCheckBox = () => {
        let payload = {
            name: newCheckBox
        }
        PostEquipmentFormCheckbox(payload)
            .then(res => {
                if (res.success) {
                    LoadCheckBoxes()
                    setNewCheckBox("")
                }
            })
    }


    const AddNewForm = () => {
        let payload = {
            name: newForm
        }
        PostEquipmentFormName(payload)
            .then(res => {
                if (res.success) {
                    LoadEquipmentFormNames()
                    let newName = res.data
                    let s = { ...formInformation, ...newName }
                    setFormInformation(s)
                    setNewForm("")
                }
            })
    }

    const AddNewPhotoType = () => {
        let payload = {
            name: newPhoto
        }
        PostEquipmentFormPhotoType(payload)
            .then(res => {
                if (res.success) {
                    LoadPhotoTypes()
                    setNewPhoto("")
                }
            })
    }

    const AddNewCategory = () => {
        let payload = {
            name: newCategory
        }
        PostEquipmentFormCategory(payload)
            .then(res => {
                if (res.success) {
                    LoadCategories()
                    setNewCategory("")
                }
            })
    }

    const SaveForm = () => {
        PostEquipmentFormTemplate(formInformation)
            .then(res => {
                if (res.success) {
                    setFormInformation({
                        equipmentFormNameId: -1,
                        equipmentFormName: "",
                        checkBoxesCategories: [],
                        requiredPhotos: []
                    })
                }
            })
    }

    const AddCheckBoxToForm = () => {
        if (!selectedCheckOption) {
            setAddCheckErr("Please select a checkbox to add");
            return;
        }
        if (!selectedCategory) {
            setAddCheckErr("Please select a category for checkbox");
            return;
        }
        let s = { ...formInformation }
        let item = { ...selectedCheckOption, ...selectedCategory }
        let catFind = s.checkBoxesCategories.findIndex(cbc => cbc.categoryId === item.categoryId)
        if (catFind === -1) {
            s.checkBoxesCategories.push({
                categoryId: item.categoryId, categoryName: item.categoryName, checkBoxes: [item]
            })
        } else {
            s.checkBoxesCategories[catFind].checkBoxes.push(item)
        }

        let nums = [...checkOptionIds]
        nums.push(item.checkBoxId)
        setCheckOptionIds(nums)
        setFormInformation(s)
        setSelectedCheckOption(null)
        setSelectedCategory(null)
    }

    const AddPhotoToForm = () => {
        if (!selectedPhoto) {
            setAddPhotoErr("Please select a photo type to add to form.")
            return;
        }
        let s = { ...formInformation }
        s.requiredPhotos.push(selectedPhoto)
        setFormInformation(s)

        let nums = [...photoTypeIds]
        nums.push(selectedPhoto.photoTypeId)
        setPhotoTypeIds(nums)

        setSelectedPhoto(null)
    }

    const RemoveByValue = (array, value) => {
        let index = array.find(value)
        if (index !== -1) {
            array.splice(index, 1)
        }
        return array;
    }

    const DeletePhotoFromForm = (arrIndex) => {
        let s = { ...formInformation }
        s.requiredPhotos.splice(arrIndex, 1)
        setFormInformation(s)

        let nums = RemoveByValue([...photoTypeIds])
        setPhotoTypeIds(nums)
    }

    const DeleteCheckBoxFromForm = (cIndex, cbIndex) => {
        let s = { ...formInformation }
        s.checkBoxesCategories[cIndex].checkBoxes.splice(cbIndex, 1)
        setFormInformation(s)

        let nums = RemoveByValue([...checkOptionIds])
        setCheckOptionIds(nums)
    }

    const loadForm = (id) => {
        if (id !== -1) {
            GetEquipmentFormTemplate(id)
                .then(res => {
                    setFormInformation(res.data)
                    let nums = []
                    for (let i = 0; i < res.data.checkBoxesCategories.length; i++) {
                        for (let x = 0; x < res.data.checkBoxesCategories[i].checkBoxes.length; x++) {
                            nums.push(res.data.checkBoxesCategories[i].checkBoxes[x].checkBoxId)
                        }
                    }
                    let photoIds = []
                    res.data.requiredPhotos.forEach(element => {
                        photoIds.push(element.photoTypeId)
                    });
                    setPhotoTypeIds(photoIds)
                    setCheckOptionIds(nums)
                })
        }
    }

    const selectForm = (obj) => {
        let s = { ...formInformation }
        s.equipmentFormName = obj.equipmentFormName
        s.equipmentFormNameId = obj.equipmentFormNameId
        setFormInformation(s)
    }

    const changeCategory = (categoryIndex, checkboxIndex, e) => {
        let s = { ...formInformation }
        let listItem = { ...s.checkBoxesCategories[categoryIndex].checkBoxes[checkboxIndex] }
        listItem.categoryId = e.categoryId
        listItem.categoryName = e.categoryName
        s.checkBoxesCategories[categoryIndex].checkBoxes.splice(checkboxIndex, 1)
        let catFind = s.checkBoxesCategories.findIndex(cbc => cbc.categoryId === e.categoryId)
        if (catFind === -1) {
            s.checkBoxesCategories.push({
                categoryId: e.categoryId, categoryName: e.categoryName, checkBoxes: [listItem]
            })
        } else {
            s.checkBoxesCategories[catFind].checkBoxes.push(listItem)
        }
        setFormInformation(s)
    }

    return (
        <div className="container px-0">
            <div className="row itemHeaderENCLight">
                <div className="col text-center">
                    <h4>Equipment Form Option Creation</h4>
                </div>
            </div>
            <div className="row border" style={{ minHeight: "25em" }}>
                <div className="col">
                    <div className="col border m-2 p-2">
                        <form action="#" onSubmit={(e) => { e.preventDefault(); AddNewForm() }}>
                            <div className="row">
                                <div className="col text-center">
                                    <h4>Create New Form</h4>
                                </div>
                            </div>
                            <div className="row my-2">
                                <div className="col">
                                    Create a new form:
                        </div>
                                <div className="col">
                                    <input
                                        className="enc-input-100"
                                        placeholder="New Form Name"
                                        value={newForm}
                                        onChange={(e) => setNewForm(e.target.value)}
                                        required />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col text-right">
                                    <Button type='submit' className="enc-button">Create New Form</Button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col border m-2 p-2">
                        <form action="#" onSubmit={(e) => { e.preventDefault(); AddNewCheckBox() }}>
                            <div className="row">
                                <div className="col text-center">
                                    <h4>Create New Checkbox Option</h4>
                                </div>
                            </div>
                            <div className="row my-2">
                                <div className="col">
                                    Create a new checkbox:
                        </div>
                                <div className="col">
                                    <input
                                        className="enc-input-100"
                                        placeholder="New CheckBox Name"
                                        value={newCheckBox}
                                        onChange={(e) => setNewCheckBox(e.target.value)}
                                        required />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col text-right">
                                    <Button type='submit' className="enc-button">Create New Checkbox</Button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col border m-2 p-2">
                        <form action="#" onSubmit={(e) => { e.preventDefault(); AddNewPhotoType() }}>
                            <div className="row">
                                <div className="col text-center">
                                    <h4>Create New Photo Option</h4>
                                </div>
                            </div>
                            <div className="row my-2">
                                <div className="col">
                                    Create a new photo:
                        </div>
                                <div className="col">
                                    <input
                                        className="enc-input-100"
                                        placeholder="New Photo Name"
                                        value={newPhoto}
                                        onChange={(e) => setNewPhoto(e.target.value)}
                                        required />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col text-right">
                                    <Button type='submit' className="enc-button">Create New Photo</Button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col border m-2 p-2">
                        <form action="#" onSubmit={(e) => { e.preventDefault(); AddNewCategory() }}>
                            <div className="row">
                                <div className="col text-center">
                                    <h4>Create New Form Category</h4>
                                </div>
                            </div>
                            <div className="row my-2">
                                <div className="col">
                                    Create a new category:
                        </div>
                                <div className="col">
                                    <input
                                        className="enc-input-100"
                                        placeholder="New Category Name"
                                        value={newCategory}
                                        onChange={(e) => setNewCategory(e.target.value)}
                                        required />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col text-right">
                                    <Button type='submit' className="enc-button">Create New Category</Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="container px-0 my-5">
                <div className="row text-center itemHeaderENCLight">
                    <div className="col">
                        <h4 >Equipment Form Builder</h4>
                    </div>
                </div>

                <div className='row border'>
                    <div className="col">
                        <form action="#" onSubmit={(e) => { e.preventDefault(); SaveForm() }}>
                            <div style={{ minHeight: '350px' }}>
                                <div className="row">
                                    <div className="col text-center">
                                        <h4>Form Options</h4>
                                    </div>
                                </div>
                                <div className="row my-5">
                                    <div className="col">
                                        Select Form to Edit:
                        </div>
                                    <div className="col">
                                        <DropdownList
                                            filter='contains'
                                            placeholder="Equipment Form Name"
                                            data={equipmentFormNames}
                                            textField="name"
                                            valueField="equipmentFormNameId"
                                            value={formInformation.equipmentFormNameId}
                                            onChange={(e) => selectForm(e)}
                                        />
                                    </div>
                                </div>
                                {/* Only Load Bottom of Form once Vehicle Type is Selected */}
                                {formInformation.equipmentFormNameId !== -1 &&
                                    <>
                                        <div className="row">
                                            <div className="col text-right">
                                                <Button onClick={DeleteForm} variant='danger'>Delete Form</Button>
                                            </div>
                                        </div>
                                        <div className="divider my-3" />
                                        <div className="row border m-2 p-3">
                                            <div className="col">
                                                <div className="row">
                                                    <div className="col text-center">
                                                        <h4>Add Check Box Panel</h4>
                                                    </div>
                                                </div>
                                                <div className="row my-2">
                                                    <div className="col">
                                                        <DropdownList
                                                            filter='contains'
                                                            placeholder="Select Checkbox"
                                                            textField="checkBoxName"
                                                            data={checkOptions.filter(co => !checkOptionIds.find(i => i === co.checkBoxId))}
                                                            value={selectedCheckOption}
                                                            onChange={e => setSelectedCheckOption(e)}
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        <DropdownList
                                                            filter='contains'
                                                            placeholder="Select Category"
                                                            data={categories}
                                                            textField="categoryName"
                                                            value={selectedCategory}
                                                            onChange={e => setSelectedCategory(e)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-8">
                                                        <span style={{ color: 'red' }}>{addCheckErr}</span>
                                                    </div>
                                                    <div className="col text-right">
                                                        <Button className="enc-button" onClick={AddCheckBoxToForm}>Add Box</Button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col text-center">
                                                <h5>Check Box Section Preview</h5>
                                            </div>
                                        </div>
                                        <div className="divider my-2" />
                                        {formInformation.checkBoxesCategories.map((category, cIndex) =>
                                            <ListGroup key={`cat-${cIndex}`} className="mt-3 ">
                                                <ListGroup.Item className="shaded-list">
                                                    <div className="row">
                                                        <div className="col">
                                                            <strong>{category.categoryName}:</strong>

                                                        </div>
                                                        <div className="col">
                                                            Change Category
                                                </div>
                                                        <div className="col-1 text-right">

                                                        </div>
                                                    </div>
                                                </ListGroup.Item>
                                                {category.checkBoxes.map((checkbox, cbIndex) =>
                                                    <ListGroup.Item key={`${cIndex}-${cbIndex}-Checkbox`} className="shaded-list">
                                                        <div className="row">
                                                            <div className="col-1">
                                                                <input type="checkbox"
                                                                    disabled={true}
                                                                    checked={checkbox.passed}
                                                                    id={`${props.iterator}-checkbox-${checkbox.equipmentCheckBoxId}`}
                                                                    className="regular-checkbox big-checkbox" />
                                                                <label htmlFor={`${props.iterator}-checkbox-${checkbox.equipmentCheckBoxId}`}></label>
                                                            </div>
                                                            <div className="col">
                                                                {checkbox.checkBoxName}
                                                            </div>
                                                            <div className="col">
                                                                <DropdownList
                                                                    data={categories}
                                                                    textField='categoryName'
                                                                    valueField='categoryId'
                                                                    value={formInformation.checkBoxesCategories[cIndex].checkBoxes[cbIndex].categoryId}
                                                                    placeholder="Change Category"
                                                                    onChange={(e) => changeCategory(cIndex, cbIndex, e)}
                                                                    filter='contains'
                                                                />
                                                            </div>
                                                            <div className="col-1 text-right">
                                                                <FontAwesomeIcon onClick={e => DeleteCheckBoxFromForm(cIndex, cbIndex)} icon={faTrash} className="clickable" />
                                                            </div>
                                                        </div>
                                                    </ListGroup.Item>
                                                )}
                                            </ListGroup>
                                        )}
                                        <div className="row mt-2">
                                            <div className="col pt-3">
                                                <h5>Photo Uploads</h5>
                                            </div>
                                        </div>
                                        <div className="row py-3 mt-2" style={{ backgroundColor: '#D3D3D3' }}>
                                            <div className="col">
                                                <div className="row">
                                                    <div className="col">
                                                        <small>Default Damage Section - Cannot be Removed</small>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <strong>Damage Photos</strong>
                                                    </div>
                                                </div>
                                                <ListGroup className="mt-1">
                                                    <ListGroup.Item>
                                                        <strong>File.jpg</strong>
                                                    </ListGroup.Item>
                                                </ListGroup>
                                            </div>
                                        </div>
                                        <div className="row py-3 mt-2" style={{ backgroundColor: '#D3D3D3' }}>
                                            <div className="col">
                                                <div className="row">
                                                    <div className="col">
                                                        <small>Default Delivery Section - Cannot be Removed - For Installation Phase</small>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <strong>Delivery Photos</strong>
                                                    </div>
                                                </div>
                                                <ListGroup className="mt-1">
                                                    <ListGroup.Item>
                                                        <strong>File.jpg</strong>
                                                    </ListGroup.Item>
                                                </ListGroup>
                                            </div>
                                        </div>
                                        <div className="row py-3 mt-2" style={{ backgroundColor: '#D3D3D3' }}>
                                            <div className="col">
                                                <div className="row">
                                                    <div className="col">
                                                        <small>Default Pickup Section - Cannot be Removed - For Removal Phase</small>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                    <strong>Pickup Photos</strong>
                                                    </div>
                                                </div>
                                                <ListGroup className="mt-1">
                                                    <ListGroup.Item>
                                                        <strong>File.jpg</strong>
                                                    </ListGroup.Item>
                                                </ListGroup>
                                            </div>
                                        </div>
                                        <div className="row border m-2 p-3">
                                            <div className="col">
                                                <div className="row">
                                                    <div className="col text-center">
                                                        <h4>Add New Required Photo</h4>
                                                    </div>
                                                </div>
                                                <div className="row my-2">
                                                    <div className="col">
                                                        <DropdownList
                                                            placeholder="Select Photo Type"
                                                            textField="photoTypeName"
                                                            data={photoTypes.filter(photoType => !photoTypeIds.find(pti => pti === photoType.photoTypeId))}
                                                            value={selectedPhoto}
                                                            onChange={e => setSelectedPhoto(e)}
                                                            filter='contains'
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-8">
                                                        <span style={{ color: 'red' }}>{addPhotoErr}</span>
                                                    </div>
                                                    <div className="col text-right">
                                                        <Button className="enc-button" onClick={AddPhotoToForm}>Add Required Photo</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col text-center">
                                                <h5>Required Photo Preview</h5>
                                            </div>
                                        </div>
                                        <div className="divider my-2" />
                                        {formInformation.requiredPhotos.map((p, picIndex) => {
                                            return (
                                                <>
                                                    <div className="divider my-1" />
                                                    <div key={`pic-${picIndex}`} className="row mt-2">
                                                        <div className="col">
                                                            <div className="row mt-2">
                                                                <div className="col">
                                                                    <strong>{p.photoTypeName}</strong>
                                                                </div>
                                                                <div className="col-1">
                                                                    <FontAwesomeIcon onClick={e => DeletePhotoFromForm(picIndex)} icon={faTrash} className="clickable" />
                                                                </div>
                                                            </div>

                                                            <ListGroup className="mt-1">
                                                                <ListGroup.Item>
                                                                    <strong>File.jpg</strong>
                                                                </ListGroup.Item>
                                                            </ListGroup>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })}
                                        <div className="row mt-3">
                                            <div className="col">
                                                <textarea
                                                    disabled={true}
                                                    className="enc-textarea"
                                                    placeholder="comments" />
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                            <div className="row my-2">
                                <div className="col text-right">
                                    <Button type='submit' className="enc-button">Save Form</Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
