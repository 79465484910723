import React, { useEffect, useState } from 'react';
import { Nav } from 'react-bootstrap';
import { Switch, Route, NavLink } from 'react-router-dom';
import FieldTicketList from './FieldTicketList';
import EquipmentFormList from './EquipmentFormList';
import FieldTicketEditCheck from './FieldTicketEditCheck';
import EquipmentFormEditCheck from './EquipmentFormEditCheck';
import { GetJobFormNav } from '../../API/JobForm';
import JSAList from './JSAList';
import JSAEditCheck from './JSAEditCheck';
import JobInventoryRouter from '../Inventory/JobInventoryRouter';
import { CloseStep, CloseStepPermission } from '../../API/Jobs';
import SurveyFormList from './SurveyForm/SurveyFormList';
import SurveyFormView from './SurveyForm/SurveyFormView';
import InProgressDash from './InProgressDash';
import Attachments from '../../Components/Attachments';

export default props => {
    const [showButton, setShowButton] = useState(false);



    const [nav, setNav] = useState([])
    useEffect(() => {
        GetJobFormNav()
            .then(res => setNav(res.navigationItems))
    }, [])

    useEffect(() => {
        checkPermissions(props.stepId, props.jobId)
    }, [props.stepId, props.jobId])

    const checkPermissions = (stepId, jobId) => {
        CloseStepPermission(stepId, jobId)
            .then(res => {
                setShowButton(res.success)
            })
    }

    const CloseStage = () => {
        CloseStep(props.stepId, props.jobId)
    }
    return (
        <>
            <Nav variant="tabs" className="my-2 no-print">
                {nav.map((m, i) => {
                    return (
                        <Nav.Item key={`nav-${i}`}>
                            <Nav.Link eventKey={i} as={NavLink} to={`${props.url}${m.url}`}>{m.label}</Nav.Link>
                        </Nav.Item>
                    )
                })}
            </Nav>
            <div style={{ minHeight: "300px" }}>
                <Switch>
                    <Route path={`${props.url}/FieldTickets/:id`} render={(RouteProps) => <FieldTicketEditCheck stepId={props.stepId} preurl={props.url} jobId={props.jobId} {...RouteProps} />} />
                    <Route path={`${props.url}/FieldTickets/`} render={(RouteProps) => <FieldTicketList stepId={props.stepId} jobId={props.jobId} {...RouteProps} />} />
                    <Route path={`${props.url}/EquipmentForms/:id`} render={(RouteProps) => <EquipmentFormEditCheck stepId={props.stepId} jobId={props.jobId} {...RouteProps} />} />
                    <Route path={`${props.url}/EquipmentForms/`} render={(RouteProps) => <EquipmentFormList stepId={props.stepId} jobId={props.jobId} {...RouteProps} />} />
                    <Route path={`${props.url}/SurveyForms/:id`} render={(RouteProps) => <SurveyFormView {...RouteProps} />} />
                    <Route path={`${props.url}/SurveyForms/`} render={(RouteProps) => <SurveyFormList jobId={props.jobId} {...RouteProps} />} />
                    <Route path={`${props.url}/JSA/:id`} render={(RouteProps) => <JSAEditCheck stepId={props.stepId} jobId={props.jobId} {...RouteProps} />} />

                    <Route path={`${props.url}/JSA/`} render={(RouteProps) => <JSAList stepId={props.stepId} jobId={props.jobId} {...RouteProps} />} />
                    <Route path={`${props.url}/Inventory`} render={(routeProps) =>
                        <JobInventoryRouter jobId={props.jobId} {...routeProps} />
                    } />
                    <Route path={"/"} render={(routeProps) =>
                        <InProgressDash JobId={props.jobId} ShowButton={showButton} StepId={props.stepId} StageName={props.stageName} CloseStage={CloseStage}  {...routeProps} />
                    } />
                </Switch>
                <div className="container">
                    <div className="row my-2">
                        <div className="col">
                            <Attachments
                                jobId={props.jobId}
                                jobStep={props.stepId}
                                title={`${props.stageName} Attachments`}
                                editable={true}
                            />
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
