import React from 'react';
import { Modal, Button } from 'react-bootstrap';

export default ({ show, setShow, action, message }) => {
    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Confirm Action</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>{message === "" ? "Confirm that you would like to perform action." : message}</p>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>Close</Button>
                <Button variant="danger" onClick={action}>Confirm</Button>
            </Modal.Footer>
        </Modal>
    )
}
