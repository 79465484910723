import React, { useState, useEffect } from 'react';
import { faStar as FullStar, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faStar as EmptyStar } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DropdownList } from 'react-widgets';

import { CreateUser, GetPhoneTypes, GetUser, EditUser } from '../API/User'
import { GetRoleList } from '../API/Administrator'
import { useHistory } from 'react-router-dom';
import { ListGroup, Button } from 'react-bootstrap';
import { GetOfficeList } from '../API/Office';

export default props => {
    const [userInfo, setUserInfo] = useState({
        userId: 0,
        username: "",
        firstName: "",
        lastName: "",
        fullName: "",
        positionTitle: "",
        roleId: 0,
        phoneNumbers: [
            {
                phoneNumber: "",
                primary: true,
                type: ""
            }
        ],
        emails: [
            {
                email: "",
                primary: true
            }
        ],
        employeePhoto: "",
        officeLocations: []
    })
    const [roleList, setRoleList] = useState([])
    const [phoneTypes, setPhoneTypes] = useState([])
    const [userError, setUserError] = useState("")
    const [officeList, setOfficeList] = useState([]);
    const [selectedOffice, setSelectedOffice] = useState(null);
    const [imageError, setImageError] = useState("")

    useEffect(() => {
        loadData();
    }, [])

    useEffect(()=>{LoadOfficeList()},[])

    useEffect(() => {
        function loadUser() {
            if (props && props.userId) {
                GetUser(parseInt(props.userId))
                    .then(res => {
                        setUserInfo(res.data)
                    })
            }
        }
        loadUser();
    }, [props, props.userId])

    const LoadOfficeList = () => {
        GetOfficeList()
        .then(res=>{setOfficeList(res.data)})
    }



    const loadData = () => {
        GetRoleList()
            .then(res => {
                if (res.success) {
                    setRoleList(res.roleList)
                }
            });
        GetPhoneTypes()
            .then(res => {
                if (res.success) {
                    setPhoneTypes(res.phoneTypeList);
                }
            })
    }

    const changeUserInfo = (e) => {
        let newUserInfo = { ...userInfo }
        newUserInfo[e.target.name] = e.target.value
        setUserInfo(newUserInfo)
    }

    const validationCheck = () => {
        if (userInfo.emails.length < 1) {
            setUserError("Please set one or more Email(s) for user.")
            return false;
        }
        if (userInfo.roleId === 0) {
            setUserError("Please Select Role for User.")
            return false;
        }
        return true;
    }


    // Image Handling
    const selectImage = (e) => {
        let image = e.target.files[0];
        const fileTypes = ['jpg', 'jpeg', 'png'];
        const extension = image.name.split('.').pop().toLowerCase();
        const convertToBase64 = () => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (event) => {
                    resolve(event.target.result);
                };
                reader.onerror = (err) => {
                    reject(err);
                }
                reader.readAsDataURL(image);
            });
        };
        if (fileTypes.indexOf(extension) === -1) {
            setImageError('Invalid Image Type')
            let newUser = { ...userInfo };
            newUser.employeePhoto = "";
            setUserInfo(newUser);
        } else {
            convertToBase64()
                .then(res => {
                    let newUser = { ...userInfo };
                    newUser.employeePhoto = res;
                    setUserInfo(newUser);
                    setImageError("");
                })
        }
    }

    const displayUserImage = () => {
        if (userInfo.employeePhoto) {
            return <img className="profile-image" src={userInfo.employeePhoto} alt="Employee" />
        } else {
            return <img className="profile-image" src="../images/user.png" alt="Environmental Noise Control" />
        }
    }
    // Phone List Handling
    const phoneDisplay = (phone, index) => {
        return (
            <ListGroup.Item key={`phone-${index}`}>
                <div className="row align-items-center mb-1">
                    <div className="col-1">
                        {primaryCheckPhone(phone, index)}
                    </div>
                    <div className="col-7">
                        <input type="tel" className="enc-input-100" name={index} placeholder="Phone Number"
                            onChange={changePhone} value={phone.phoneNumber}
                            required />
                    </div>
                    <div className="col-3">
                        <select className="enc-select" name={index} value={phone.typeId} onChange={changePhoneType}>
                            {phoneTypes.map(a => (phone.typeId === a.typeId) ?
                                <option selected key={a.typeId} value={a.typeId}>{a.typeName}</option> :
                                <option key={a.typeId} value={a.typeId}>{a.typeName}</option>
                            )}
                        </select>
                    </div>
                    <div className="col-1">
                        <FontAwesomeIcon id={index} icon={faTrash} className="clickable" onClick={removePhone} />
                    </div>
                </div>
            </ListGroup.Item>
        )
    }
    const changePhone = (e) => {
        let newUserInfo = { ...userInfo }
        newUserInfo.phoneNumbers[e.target.name].phoneNumber = e.target.value
        setUserInfo(newUserInfo)
    }

    const changePhoneType = (e) => {
        let newUserInfo = { ...userInfo }
        newUserInfo.phoneNumbers[e.target.name].typeId = parseInt(e.target.value)
        setUserInfo(newUserInfo)
    }

    const togglePrimaryPhone = (e) => {
        let newUserInfo = { ...userInfo }
        newUserInfo.phoneNumbers.forEach(element => {
            element.primary = false
        });
        newUserInfo.phoneNumbers[e.currentTarget.id].primary = true
        setUserInfo(newUserInfo)
    }
    const primaryCheckPhone = (phone, index) => {
        if (phone.primary) {
            return <FontAwesomeIcon icon={FullStar} className="clickable" id={index} onClick={togglePrimaryPhone} />
        } else {
            return <FontAwesomeIcon icon={EmptyStar} className="clickable" id={index} onClick={togglePrimaryPhone} />
        }
    }
    const addPhone = () => {
        let newPhone = { ...userInfo }
        newPhone.phoneNumbers.push({
            phoneNumberId: 0,
            phoneNumber: "",
            primary: false,
            type: ""
        })
        setUserInfo(newPhone)
    }
    const removePhone = (e) => {
        let removePhone = { ...userInfo }
        removePhone.phoneNumbers.splice(e.currentTarget.id, 1)
        setUserInfo(removePhone)
    }
    // Email List Handling
    const addEmail = () => {
        let newEmail = { ...userInfo }
        newEmail.emails.push({
            email: "",
            primary: false
        })
        setUserInfo(newEmail);
    }

    const removeEmail = (e) => {
        let removeEmail = { ...userInfo }
        removeEmail.emails.splice(e.currentTarget.id, 1)
        setUserInfo(removeEmail)
    }

    const togglePrimaryEmail = (e) => {
        let newUserInfo = { ...userInfo }
        newUserInfo.emails.forEach(element => {
            element.primary = false
        });
        newUserInfo.emails[e.currentTarget.id].primary = true
        setUserInfo(newUserInfo)
    }
    const primaryCheckEmail = (email, index) => {
        if (email.primary) {
            return <FontAwesomeIcon icon={FullStar} className="clickable" id={index} onClick={togglePrimaryEmail} />
        } else {
            return <FontAwesomeIcon icon={EmptyStar} className="clickable" id={index} onClick={togglePrimaryEmail} />
        }
    }
    const changeEmailAddress = (e) => {
        let newUserInfo = { ...userInfo }
        newUserInfo.emails[e.target.id].email = e.target.value
        setUserInfo(newUserInfo)
    }
    const emailDisplay = (email, index) => {
        return (
            <ListGroup.Item key={`email-${index}`} id={index}>
                <div className="row align-items-center mb-1">
                    <div className="col-1">
                        {primaryCheckEmail(email, index)}
                    </div>
                    <div className="col-8">
                        <input type="email" className="enc-input-100" id={index} placeholder="Email Address"
                            onChange={changeEmailAddress} value={email.email}
                            required />
                    </div>
                    <div className="col-1">
                        <FontAwesomeIcon className="clickable" id={index} icon={faTrash} onClick={removeEmail} />
                    </div>
                </div>
            </ListGroup.Item>
        )
    }
    // API call for new user
    const history = useHistory();
    const CreateNewUser = () => {
        if (validationCheck()) {
            let payload = userInfo;
            payload.roleId = parseInt(userInfo.roleId)
            if (userInfo.userId === 0) {
                CreateUser(payload)
                    .then(res => {
                        if (res.success) {
                            history.push("/Admin/UserList")
                        }
                        else {
                            setUserError(res.message)
                        }
                    })
            } else {
                EditUser(payload)
                    .then(res => {
                        if (res.success) {
                            history.push("/Admin/UserList")
                        } else {
                            setUserError(res.message)
                        }
                    })
            }

        }
    }

    const AddOffice = () => {
        let s = {...userInfo}
        if(selectedOffice === null){
            return;
        } 
        s.officeLocations.push(selectedOffice)
        setUserInfo(s)
        setSelectedOffice(null)
    }

    const RemoveOffice = (i) => {
        let s = {...userInfo}
        s.officeLocations.splice(i, 1)
        setUserInfo(s)
    }

    return (
        <div>
            <div className="container">
                <div className="itemHeaderENCLight row">
                    <div className="col">
                        <h4 className="text-center">Edit/Create New User</h4>
                    </div>
                </div>
                <div className="p-2 mb-5">
                    <form action="#" onSubmit={(e) => { e.preventDefault(); CreateNewUser() }}>
                        <div className="row mb-2">
                            <div className="col-sm">
                                <div className="row align-items-end">
                                    <div className="col text-center py-3">
                                        {displayUserImage()}
                                        {imageError}
                                    </div>
                                    <div className=" col border-left mt-4">
                                        <div className="row">
                                            <div className="col">
                                                Username:
                                                <input type="text" className="enc-input-100" name="username" placeholder="Username"
                                                    onChange={changeUserInfo} value={userInfo.username}
                                                    required />
                                            </div>
                                            <div className="col">
                                                Application Role:
                                                <select className="enc-select" name="roleId" value={userInfo.roleId} onChange={changeUserInfo}>
                                                    <option value={0}>-- Role --</option>
                                                    {roleList.map(r =>
                                                        <option key={r.roleId} value={r.roleId}>{r.roleName}</option>
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col">
                                                Position Title:
                                                <input type="text" className="enc-input-100" name="positionTitle" placeholder="Position Title"
                                                    onChange={changeUserInfo} value={userInfo.positionTitle}
                                                    required />
                                                </div>
                                        </div>

                                        <div className="row mt-2">
                                            <div className="col">
                                                First Name:
                                            <input type="text" className="enc-input-100" name="firstName" placeholder="First Name"
                                                onChange={changeUserInfo} value={userInfo.firstName}
                                                required />
                                            </div>
                                            <div className="col">
                                                Last Name:
                                            <input type="text" className="enc-input-100" name="lastName" placeholder="Last Name"
                                                onChange={changeUserInfo} value={userInfo.lastName}
                                                required />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col pt-2">
                                    <input type="file" onChange={selectImage} />
                                </div>
                            </div>
                        </div>

                        <div className="divider my-2" />
                        <div className="row">
                            <div className="col text-center">
                                <h5>Contact Information</h5>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-6 col-sm-12">
                                <ListGroup>
                                    <ListGroup.Item>
                                        <h5>Email <FontAwesomeIcon className="clickable" icon={faPlus} onClick={addEmail} /></h5>
                                    </ListGroup.Item>
                                    {userInfo.emails.map((e, index) => emailDisplay(e, index))}
                                </ListGroup>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <ListGroup>
                                    <ListGroup.Item>
                                        <h5>Phone <FontAwesomeIcon className="clickable" icon={faPlus} onClick={addPhone} /></h5>
                                    </ListGroup.Item>
                                    {userInfo.phoneNumbers.map((p, index) => phoneDisplay(p, index))}
                                </ListGroup>
                            </div>

                        </div>
                        <div className="divider my-2" />
                        <div className="row">
                            <div className="col text-center">
                                <h5>User Office Assignments</h5>
                            </div>
                        </div>
                        <ListGroup>
                            <ListGroup.Item className="enc-list">
                                Currently Assigned
                        </ListGroup.Item>
                        {userInfo.officeLocations.length === 0 && <ListGroup.Item className="enc-list">No Current Assigned Offices</ListGroup.Item>}
                        {userInfo.officeLocations.map((m, i) => {return(
                        <ListGroup.Item key={`location-${i}`} className="enc-list">
                            <div className="row">
                                <div className="col">
                                    {m.name}
                                </div>
                                <div className="col-2">
                                    <FontAwesomeIcon icon={faTrash} className="clickable" onClick={() => RemoveOffice(i)} />
                                </div>
                            </div>
                        </ListGroup.Item>
                        )})}

                        </ListGroup>
                        <div className="row my-3">
                            <div className="col">
                                <DropdownList
                                data={officeList.filter(office => !userInfo.officeLocations.find(({officeLocationId}) => office.officeLocationId === officeLocationId))}
                                textField='name'
                                placeholder="Select an Office"
                                onChange={e => setSelectedOffice(e)}
                                value={selectedOffice}
                                />
                            </div>
                            <div className="col text-right">
                                <Button onClick={AddOffice} className="enc-button">Add Office</Button>
                            </div>
                        </div>

                        <div className="divider my-2" />
                        <div className="row">
                            <div className="col">
                                {userError}
                            </div>
                            <div className="col text-right">
                                <input type="submit" value="Save" className="enc-button" />
                            </div>
                        </div>
                    </form >
                </div >
            </div>
        </div>
    )
}
