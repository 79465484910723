import React, { useState, useEffect } from 'react';
import { CheckEditJSA } from '../../API/JSA';
import JSA from './JSA';
import ViewJSA from './ViewJSA';

export default props => {
    const [edit, setEdit] = useState(false);
    useEffect(()=>{
        LoadPage(props.match.params.id)
    },[props.match.params.id])

    const LoadPage = (arg) => {
        let id = arg;
        if(arg === 'new'){
            id = 0;
        }
        CheckEditJSA(id)
        .then(res=>{
            setEdit(res.success)
        })
    }

    return (
        <>
            {edit?
            <JSA stepId={props.stepId} {...props} setEdit={setEdit} reloadParent={(id) => id? LoadPage(id):LoadPage(props.match.params.id)} />
            :props.match.params.id!=='new'&&<ViewJSA setEdit={setEdit} {...props} />}
        </>
    )
}
