import React from 'react';
import { Card } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

export default props => {
    const history = useHistory();
    const handleClick = (id) => {
        history.push(`${props.url}/${props.account}/Location/${id}`)
    }

    const display = (props.hidepc === true) ? { style: { display: "none" } } : {}


    return (
        <div className="container-fluid mt-2">
            <div className="row mt-2">
                <div className="col">
                    Account Location - Offices
                </div>
            </div>
            <div className="row mt-2">
                {props.location.filter(x => !x.jobLocation).length === 0 && <div className="col"><strong>No Locations to Display</strong></div>}
                {props.location.filter(x => !x.jobLocation).map(location => {
                    // Variable to allow clickable header if props.click is set to true. Allows for reuse without a linking header.
                    let clickObject = (props.click) ? {
                        className: "clickable",
                        onClick: () => handleClick(location.accountLocationId)
                    } : {};
                    return (
                        <Card className="mx-2 mb-2" key={`location-${location.accountLocationId}`} style={{ width: '18rem' }}>
                            <Card.Header {...clickObject}  ><strong>{location.locationName}</strong></Card.Header>
                            <Card.Body>
                                {(location.billingAddress1 || location.billingAddress2 || location.billingCity || location.billingZip) &&
                                    <>
                                        <Card.Subtitle><strong>Billing Address</strong></Card.Subtitle>
                                        <Card.Text>
                                            {`${location.billingAddress1}`} <br />
                                            {location.billingAddress2 && <> {`${location.billingAddress2}`} <br /> </>}
                                            {`${location.billingZip && location.billingCity + ","} ${location.billingState} ${location.billingZip}`}
                                        </Card.Text>
                                    </>
                                }
                                {(location.shippingAddress1 || location.shippingAddress2 || location.shippingCity || location.shippingZip) &&
                                    <>
                                        <Card.Subtitle><strong>Shipping Address</strong></Card.Subtitle>
                                        <Card.Text>
                                            {location.shippingAddress1 && <> {`${location.shippingAddress1}`} <br /> </>}
                                            {location.shippingAddress2 && <> {`${location.shippingAddress2}`} <br /> </>}
                                            {`${location.shippingCity && location.shippingCity + ","} ${location.shippingState} ${location.shippingZip}`}
                                        </Card.Text>
                                    </>
                                }
                            </Card.Body>
                            <Card.Footer {...display}>
                                <Card.Subtitle><strong>Primary Contact</strong></Card.Subtitle>
                                {location.pocName} <br />
                                <Card.Link href={`tel:${location.pocPhoneNumber}`}>{location.pocPhoneNumber}</Card.Link> <br />
                                <Card.Link href={`mailto:${location.pocEmail}`}>{location.pocEmail}</Card.Link>
                            </Card.Footer>
                        </Card>
                    )
                })}
            </div>
            <div className="divider my-2" />
            <div className="row mt-2">
                <div className="col">
                    Account Location - Jobs
                </div>
            </div>
            <div className="row mt-2">
                {props.location.filter(x => x.jobLocation).length === 0 && <div className="col"><strong>No Locations to Display</strong></div>}
                {props.location.filter(x => x.jobLocation).map(location => {
                    // Variable to allow clickable header if props.click is set to true. Allows for reuse without a linking header.
                    let clickObject = (props.click) ? {
                        className: "clickable",
                        onClick: () => handleClick(location.accountLocationId)
                    } : {};
                    return (
                        <Card className="mx-2 mb-2" key={`location-${location.accountLocationId}`} style={{ width: '18rem' }}>
                            <Card.Header {...clickObject}  ><strong>{location.locationName}</strong></Card.Header>
                            <Card.Body>
                                {(location.billingAddress1 || location.billingAddress2 || location.billingCity || location.billingZip) &&
                                    <>
                                        <Card.Subtitle><strong>Billing Address</strong></Card.Subtitle>
                                        <Card.Text>
                                            {`${location.billingAddress1}`} <br />
                                            {`${location.billingAddress2}`} <br />
                                            {`${location.billingCity}, ${location.billingState} ${location.billingZip}`}
                                        </Card.Text>
                                    </>
                                }
                                {(location.shippingAddress1 || location.shippingAddress2 || location.shippingCity || location.shippingZip) &&
                                    <>
                                        <Card.Subtitle><strong>Shipping Address</strong></Card.Subtitle>
                                        <Card.Text>
                                            {`${location.shippingAddress1}`} <br />
                                            {`${location.shippingAddress2}`} <br />
                                            {`${location.shippingCity}, ${location.shippingState} ${location.shippingZip}`}
                                        </Card.Text>
                                    </>
                                }
                            </Card.Body>
                            <Card.Footer {...display}>
                                <Card.Subtitle><strong>Primary Contact</strong></Card.Subtitle>
                                <Card.Subtitle>
                                    <small>Name</small>
                                </Card.Subtitle>
                                <Card.Subtitle><strong>{location.pocName}</strong></Card.Subtitle>
                                <Card.Text className="mt-2">
                                    {location.pocPhoneNumber &&
                                        <>
                                            <Card.Subtitle>
                                                <small>Phone Number</small>
                                            </Card.Subtitle>
                                            <Card.Link href={`tel:${location.pocPhoneNumber}`}>{location.pocPhoneNumber}</Card.Link>
                                        </>
                                    }

                                    {location.pocMobileNumber &&
                                        <>
                                            <Card.Subtitle>
                                                <small>Mobile Number</small>
                                            </Card.Subtitle>
                                            <Card.Link href={`tel:${location.pocMobileNumber}`}>{location.pocMobileNumber}</Card.Link>
                                        </>
                                    }

                                    {location.pocOfficeNumber &&
                                        <>
                                            <Card.Subtitle>
                                                <small>Office Number</small>
                                            </Card.Subtitle>
                                            <Card.Link href={`tel:${location.pocOfficeNumber}`}>{location.pocOfficeNumber}</Card.Link>
                                        </>
                                    }

                                    {location.pocOtherNumber &&
                                        <>
                                            <Card.Subtitle>
                                                <small>Other Number</small>
                                            </Card.Subtitle>
                                            <Card.Link href={`tel:${location.pocOtherNumber}`}>{location.pocOtherNumber}</Card.Link>
                                        </>
                                    }


                                    <Card.Subtitle>
                                        <small>Email</small>
                                    </Card.Subtitle>
                                    <Card.Link href={`mailto:${location.pocEmail}`}>{location.pocEmail}</Card.Link>
                                </Card.Text>
                            </Card.Footer>
                        </Card>
                    )
                })}
            </div>
        </div>
    )
}
