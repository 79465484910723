
import config from '../APIConfig';
import APIDownload from './MainAPICall/APIDownload';
import APIRequest from './MainAPICall/api';

export const GetJobAttachment = (id, filename) => {
    return APIDownload({
        url: `${config.baseURL}/JobAttachment/GetJobAttachment?FileId=${id}`,
        method: "get",
        body: {id: id},
        filename: filename
    });
}

export const GetJobAttachmentList = (jobId, stepId=-1) => {
    return APIRequest({
        url: `${config.baseURL}/JobAttachment/GetJobAttachmentList?jobId=${jobId}&stepId=${stepId}`,
        method: "get"
    })
}

export const DeleteJobAttachment = (id) => {
    return APIRequest({
        url: `${config.baseURL}/JobAttachment/DeleteJobAttachment`,
        method: "Post",
        body: {id:id}
    })
}
