import React, { useState, useEffect } from 'react';
import HandOffEdit from './HandOffEdit';
import HandoffView from './HandoffView';
import { CheckEdit } from '../../API/HandOff';

export default props => {
    const [editPhase, setEditPhase] = useState(false)

    useEffect(()=>{
        CheckEdit(parseInt(props.jobId))
        .then(res=>{
            setEditPhase(res.success)
        })
    },[props.jobId])

    const checkView = () => {
        CheckEdit(parseInt(props.jobId))
        .then(res=>{
            setEditPhase(res.success)
        })
    }

    return (
        <>
            <div className="container">
                {editPhase?
                    <HandOffEdit reload={props.reload} checkView={checkView} jobId={props.jobId}/>:
                    <HandoffView reload={props.reload} url={props.url} checkView={checkView} jobId={props.jobId} />}
            </div>
        </>
    )
}
