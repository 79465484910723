import React, { useEffect, useState } from 'react';
import { GetTimesheetEntryList } from '../API/TimeSheetEntry';
import { useHistory } from 'react-router-dom';
import { ListGroup } from 'react-bootstrap';

const TimesheetEntryList = (props) => {
    const [timesheetEntryList, setTimesheetEntryList] = useState([]);
    
    useEffect(() => {
        loadTimesheetEntries(props.timesheetId);
    }, [props.timesheetId])
    
    const loadTimesheetEntries = (tsId) => {
        GetTimesheetEntryList(parseInt(tsId))
            .then(res => {
                setTimesheetEntryList(res.data)
            })
    }

    const history = useHistory();
    const navigateToEditTimesheetEntry = (tseId) => {
        if(props.nav)
        {
            history.push(`/Timesheets/EditTimesheetEntry/${tseId}`)
        }        
    }

    return (
        <ListGroup>
            <ListGroup.Item className="enc-data-list">
                Timesheet Entries
            </ListGroup.Item>
            <ListGroup.Item className="enc-data-list">
                <div className="row">
                    <div className="col">
                        Location
                    </div>
                    <div className="col">
                        Job
                    </div>
                    <div className="col">
                        Start Time
                    </div>
                    <div className="col">
                        End
                    </div>
                    <div className="col">
                        Hours
                    </div>
                    <div className="col">
                        Activity
                    </div>
                </div>
            </ListGroup.Item>
            {timesheetEntryList.map((tse, i) =>
                <ListGroup.Item key={`TimesheetEntry-${i}`} onClick={() => navigateToEditTimesheetEntry(tse.timesheetEntryId)} className="click-list">
                    <div className="row">
                        <div className="col">
                            {tse.locationName}
                        </div>
                        <div className="col">
                            {tse.jobName}
                        </div>
                        <div className="col">
                            {new Date(tse.startTime).toLocaleTimeString()}
                        </div>
                        <div className="col">
                            {new Date(tse.endTime).toLocaleTimeString()}
                        </div>
                        <div className="col">
                            {tse.duration}
                        </div>
                        <div className="col">
                            {tse.activityName}
                        </div>
                    </div>
                </ListGroup.Item>)
            }
            {timesheetEntryList.length === 0 &&
                <ListGroup.Item key={0} className="click-list">
                <div className="row">
                    <div className="col text-center">
                        no entries...
                    </div>
                </div>
            </ListGroup.Item>
            }
        </ListGroup>
    )
}

export default TimesheetEntryList;