import React from 'react';
import { Button } from 'react-bootstrap';

export default props => {
    return (
        <div className="col">
            <div className="row">
                <div className="col text-center m-5">
                    <strong>Pick Order Submitted</strong>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col text-center">
                    <Button className="enc-button" onClick={() => {props.reset()}}>New Pick Order</Button>
                </div>
            </div>
        </div>
    )
}
