import React, { useState, useEffect } from 'react';
import { GetJSAForm, GetJSA, PostJSA } from '../../API/JSA';
import { GetPrefill } from '../../API/Proposal';
import { Button, ListGroup, Alert } from 'react-bootstrap';
import { faArrowUp, faArrowDown, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactDatePicker from 'react-datepicker';
import { useHistory, useLocation } from 'react-router-dom';
import ProductBeInstalled from './ProductBeInstalled';
import { DropdownList, Multiselect } from 'react-widgets';
import { GetScopeBuilder } from '../../API/JobScope';

export default ({setEdit, reloadParent, ...props}) => {
  const [emergencyOptions, setEmergencyOptions] = useState([])
  const [form, setForm] = useState({
    jsaId: 0,
    date: new Date(),
    supervisor: "",
    supervisorContact: "",
    cprCertifiedEmployee: "",
    nameOfNearestHospital: "",
    nearestHospitalAddress: "",
    emergencyAddress: "",
    createdBy: "",
    checkBoxCategories: [],
    emergency: [],
    risk: [], // remove?
    riskManagements: [],
    actions: [],
    risks: [],
  })
    const [addProductToBeInstalled, setAddProductToBeInstalled] = useState(false);

    const [scopeBuilder, setScopeBuilder] = useState([]);
    const [payloadSelections, setPayloadSelections] = useState([]);

    const [errAlert, setErrAlert] = useState(false);
    const [errMsg, setErrMsg] = useState("Please fill in the missing fields");

  useEffect(() => {
    if (props.match.params.id === 'new') {
      LoadNewJSAForm()
    } else {
      LoadJSA(props.match.params.id)
    }
  }, [props.match.params.id])

    useEffect(() => {
        GetScopeBuilder()
            .then(res => {
                let data = res.data;
                let sb = data.filter(s => s.itemName === "Mitigation"); // only from mitigation scope
                setScopeBuilder(sb)
            })
    }, [])

  const LoadJSA = (id) => {
    GetJSAForm()
      .then(res => {
        setEmergencyOptions(res.emergencyTypes)
      })
    GetJSA(id)
      .then(res => {
          if (res.success) {
              let s = { ...res.data }
              s.date = new Date(Date.parse(s.date));
              s.jsaId = Number(id);
              setForm(s)
          } else {
              console.log("jsa res")
              console.log(res)
          }
      })
    .catch(err => {
        console.log("get jsa error:")
        console.log(err);
    })
  }

  const LoadNewJSAForm = () => {
    GetJSAForm()
      .then(res => {
        setForm(form => ({ ...form, checkBoxCategories: [...res.jsaCategoryBoxes], risks: [...res.risks], actions: [...res.actions] }))
        setEmergencyOptions(res.emergencyTypes)
      })
  }
  
  const changeForm = (field, value) => {
    let state = { ...form };
    state[field] = value
    setForm(state)
  }

  const CheckBox = (category, index) => {
        let s = { ...form }
        let invert = !s.checkBoxCategories[category].boxes[index].checked;
        if (s.checkBoxCategories[category].boxes[index].JSAItemName === "None" && invert) {
            // Uncheck all boxes in this category if None was checked.

        }
        s.checkBoxCategories[category].boxes[index].checked = !s.checkBoxCategories[category].boxes[index].checked
        setForm(s)
    }

  const otherText = (category, index, value) => {
    let s = { ...form }


    s.checkBoxCategories[category].boxes[index].otherText = value
    setForm(s)
  }

  const createEmergency = (obj) => {
    let s = { ...form }
    s.emergency.push({
      emergencyTypeID: obj.jsaEmergencyID,
      iconUrl: obj.iconUrl,
      emergencyTypeLocation: ""
    })
    setForm(s)
  }

  const removeEmergency = (index) => {
    let s = { ...form }
    s.emergency.splice(index, 1)
    setForm(s)
  }

  const editEmergency = (index, value) => {
    let s = { ...form };
    let warning = false;
    if (value.length > 16) {
      value = value.substring(0,16);
      warning = true;
    }
    s.emergency[index].emergencyTypeLocation = value;
    s.emergency[index].warning = warning;
    setForm(s);
  }

  const addProductBtn = () => {
    if (addProductToBeInstalled === true) {
        setAddProductToBeInstalled(false);
    } else {
        setAddProductToBeInstalled(true);
    }
  }

    const addProduct = (payload) => {
        addProductBtn();
        //let name = "";
        //let productId = payload.selections[payload.selections.length - 1].scopeBuilderId;
        //for (let i = 1; i < payload.selections.length; i++) {
        //    name += " " + payload.selections[i].itemName;
        //}
        let sbId;
        if (payload.selections[payload.selections.length - 1] === null) {
            sbId = payload.selections[payload.selections.length - 2].scopeBuilderId;
        } else {
            sbId = payload.selections[payload.selections.length - 1].scopeBuilderId;
        }
        setPayloadSelections(payload.selections);
        GetPrefill(sbId).then(res => {
            let item = {
                jSARiskMangagementId: parseInt(0),
                productName: res.data.proposalTitle !== null ? res.data.proposalTitle : res.data.scopeName,
                productActionId: parseInt(0),
                action: "",
                //productRiskId: parseInt(0),
                //risk: "",
                siteRisk: "",
                riskMitigation: "",
                scopeBuilderId: Number(sbId),
                actionOther: false,
                actionTextOther: "",
                riskOther: false,
                riskTextOther: "",
                productRisks: []
            };
            let newForm = { ...form };
            newForm.riskManagements.push(item);
            setForm(newForm);
        })
    };

    const duplicateProduct = (i) => {
        let s = { ...form };
        let item = { ...s.riskManagements[i] };
        item.productActionId = parseInt(0);
        item.action = "";
        item.siteRisk = "";
        item.riskMitigation = "";
        item.actionOther = false;
        item.actionTextOther = "";
        item.riskOther = false;
        item.riskTextOther = "";
        item.jsaRiskMangagementId = parseInt(0);
        item.sequenceActionId = parseInt(0);
        item.productRisks = [];
        s.riskManagements.push(item);
        setForm(s);
    }

    const removeProduct = (i) => {
        let s = { ...form }
        s.riskManagements.splice(i, 1);
        setForm(s);
    }

    const changeProductAction = (value, index) => {
        let newForm = { ...form };
        newForm.riskManagements[index].productActionId = parseInt(value.productActionId);
        newForm.riskManagements[index].action = value.action;
        newForm.riskManagements[index].sequenceActionId = value.sequenceActionId;
        if (value.action.toLowerCase() === "other") {
            newForm.riskManagements[index].actionOther = true;
        } else {
            newForm.riskManagements[index].actionOther = false;
        }
        let productRisks = [...newForm.riskManagements[index].productRisks];
        productRisks.splice(0, productRisks.length);
        newForm.riskManagements[index].productRisks = productRisks;
        setForm(newForm);
    }

    const changeActionTextOther = (value, index) => {
        let newForm = { ...form };
        newForm.riskManagements[index].actionTextOther = value;
        setForm(newForm);
    }

    const changeProductRisk = (value, index) => {
        let newForm = { ...form };
        let productRisks = [...newForm.riskManagements[index].productRisks];
        productRisks.splice(0, productRisks.length);
        for (var i = 0; i < value.length; i++) {
            let id = newForm.riskManagements[index].jsaRiskMangagementId;
            let newId;
            if (id === undefined) {
                newId = parseInt(0);
            } else {
                newId = parseInt(id);
            }
            let item = {
                productRiskId: parseInt(0), // should be 0 since adding
                potentialRiskId: parseInt(value[i].potentialRiskId),
                risk: value[i].risk,
                jsaRiskManagementId: newId, // may or may not be 0
            }
            productRisks.push(item);
        }
        newForm.riskManagements[index].productRisks = productRisks;
        let otherItem = productRisks.find(i => i.risk.toLowerCase() === "other");
        if (otherItem === undefined) {
            newForm.riskManagements[index].riskOther = false;
        } else {
            newForm.riskManagements[index].riskOther = true;
        }
        setForm(newForm);
    }

    const changeRiskTextOther = (value, index) => {
        let newForm = { ...form };
        newForm.riskManagements[index].riskTextOther = value;
        setForm(newForm);
    }

    const changeSiteRisk = (value, index) => {
        let newForm = { ...form };
        newForm.riskManagements[index].siteRisk = value;
        setForm(newForm);
    }

    const changeRiskMitigation = (value, index) => {
        let newForm = { ...form };
        newForm.riskManagements[index].riskMitigation = value;
        setForm(newForm);
    }

  let location = useLocation();
    let history = useHistory();

  const SubmitJSA = (e) => {
    e.preventDefault();

    if (form.riskManagements.length > 0) {
        let newErrMsg = "The following fields are required:";

        let actionEmpty = false;
        let riskEmpty = false;
        let siteRiskEmpty = false;
        let mitigationEmpty = false;

        for (let i = 0; i < form.riskManagements.length; i++) {
            let riskItem = form.riskManagements[i];

            if (riskItem.action === "" && riskItem.productActionId === 0) {
                actionEmpty = true;
                newErrMsg += " Sequence of Actions.";
            }
            if (riskItem.productRisks.length === 0) {
                riskEmpty = true;
                newErrMsg += " Potential Risks.";
            }
            if (riskItem.siteRisk === "") {
                siteRiskEmpty = true;
                newErrMsg += " Site Specific Risk.";
            }
            if (riskItem.riskMitigation === "") {
                siteRiskEmpty = true;
                newErrMsg += " Site Specific Risk Mitigation.";
            }
        }

        if (actionEmpty === true || riskEmpty === true || siteRiskEmpty === true || mitigationEmpty === true) {
            console.log(newErrMsg);
            setErrMsg(newErrMsg);
            setErrAlert(true);
            return;
        }
    }

    let newLoc = location.pathname.split('/').slice(0, -1).join('/')
    let payload = { ...form }
    payload.date = form.date.toJSON()
    payload.jobId = parseInt(props.jobId)
    if (props.stepId) {
      payload.stepId = parseInt(props.stepId)
    }
    PostJSA(payload)
      .then(res => {
        if (res.success) {
          if(setEdit){
            setEdit(false);
          }
          history.push(newLoc + `/${res.id}`)
        }
      })
  }

  return (

    <div className="container">
      <div className="row">
        <div className="col">
          <h3>Job Safety Analysis Worksheet</h3>
        </div>
      </div>
      <div className="divider my-2" />
      {/*<form action="#" onSubmit={(e) => { e.preventDefault(); SubmitJSA() }}>*/}
        <div className="row">
          <div className="col-md-6">
            <small>Date</small>
            <br />
            <ReactDatePicker
              selected={form.date}
              className="enc-input-100"
              onChange={date => changeForm('date', date)}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="time"
              dateFormat="MMMM d, yyyy h:mm aa" />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <small>Supervisor Name</small>
            <input className="enc-input-100" value={form.supervisor} onChange={e => changeForm("supervisor", e.target.value)} />
          </div>
          <div className="col-md-6">
            <small>Supervisor Contact</small>
            <input className="enc-input-100" value={form.supervisorContact} onChange={e => changeForm("supervisorContact", e.target.value)} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <small>CPR Certified Employee On Site</small>
            <input className="enc-input-100" value={form.cprCertifiedEmployee} onChange={e => changeForm("cprCertifiedEmployee", e.target.value)} />
          </div>
          <div className="col-md-6">
            <small>Name Of Nearest Hospital</small>
            <input className="enc-input-100" value={form.nameOfNearestHospital} onChange={e => changeForm("nameOfNearestHospital", e.target.value)} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <small>Emergency Address</small>
            <textarea className="enc-textarea" value={form.emergencyAddress} onChange={e => changeForm("emergencyAddress", e.target.value)} />
          </div>
          <div className="col-md-6">
            <small>Nearest Hospital Address</small>
            <textarea className="enc-textarea" value={form.nearestHospitalAddress} onChange={e => changeForm("nearestHospitalAddress", e.target.value)} />
          </div>
        </div>


        <div className="divider my-2" />

        <div className="row">
          <div className="col">
            <h3>Considerations</h3>
          </div>
        </div>
        {form.checkBoxCategories.map((cbc, ci) => {
          return (
            <ListGroup className="mt-3" key={`category-${ci}`}>
              <ListGroup.Item className="enc-list">
                <h4>{cbc.category}</h4>
              </ListGroup.Item>
              {cbc.boxes.map((permitItem, Index) =>
                <ListGroup.Item className="enc-list" key={`cb-${ci}-${Index}`}>
                  <div className="row">
                    <div className="col-2">
                      <input type="checkbox"
                        checked={permitItem.checked}
                        id={`checkbox-${ci}-${Index}`}
                        className="regular-checkbox big-checkbox"
                        onChange={() => CheckBox(ci, Index)} />
                      <label htmlFor={`checkbox-${ci}-${Index}`}></label>
                    </div>
                    <div className="col-lg-5">
                      <span>{permitItem.jsaItemName}</span>
                    </div>
                    <div className="col-lg-5">{permitItem.checked && permitItem.other && <textarea value={permitItem.otherText} className="enc-textarea" onChange={e => otherText(ci, Index, e.target.value)}></textarea>}</div>

                  </div>
                </ListGroup.Item>
              )}
            </ListGroup>
          )
        })}

        {/* SECTION 2.5.... Emergency Locations*/}

        <div className="divider my-2" />
        <div className="row">
          <div className="col">
            <h4>Emergency Locations</h4>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <strong>Click image to add</strong>
          </div>
        </div>
        <div className="row">
          {emergencyOptions.map((m, i) => (
            <div key={`emergencyicon-${i}`} className="col m-1 jsa-icon p-2 text-center" onClick={() => createEmergency(m)}>
              <img className="img-fluid my-auto" src={m.iconUrl} alt="icon" />
              <p className="my-auto">{m.emergencyName}</p>
            </div>
          )
          )}
        </div>

        <ListGroup>
          <ListGroup.Item className="enc-data-list">
            Emergency Locations
            </ListGroup.Item>
          <ListGroup.Item className="enc-data-list">
            <div className="row">
              <div className="col-1 text-center" > #</div>
              <div className="col-3" >Emergency Type</div>
              <div className="col">Location</div>
              <div className="col-2" ></div>
            </div>
          </ListGroup.Item>
          {form.emergency.length === 0 &&
            <ListGroup.Item className="enc-data-list">
              No Emergency Locations Set
          </ListGroup.Item>}
          {form.emergency.map((emerg, i) => {
            return (
              <ListGroup.Item key={`table-${i}`} className="enc-list">
                <div className="row">
                  <div className="col-1 text-center">{i + 1}</div>
                  <div className="col-3" >
                    <img className="card-img-top" style={{ width: "80px" }} src={emerg.iconUrl} alt="icon" />
                  </div>
                  <div className="col">
                    <div className="row">
                      <input className="enc-input-100" value={emerg.emergencyTypeLocation} onChange={(e) => editEmergency(i, e.target.value)} />
                    </div>
                    {emerg.warning &&
                    <div className="row warning">
                      Input limited to 16 characters.
                    </div>
                    }
                  </div>
                  <div className="col-2" >
                    <FontAwesomeIcon
                      icon={faTrash}
                      className="clickable"
                      onClick={() => removeEmergency(i)} />
                  </div>
                </div>
              </ListGroup.Item>
            )
          })}
        </ListGroup>

        <div className="divider my-2" />
        {/* SECTION 3 */}
        {/* RISK MITIGATION */}
        <div className="row">
          <div className="col">
            <h3>Risk Management</h3>
          </div>
        </div>

        <ListGroup>
          <ListGroup.Item className="enc-data-list">

            <div className="row">
              <div className="col">
                Risks
              </div>
              <div className="col text-right">
                <Button className="enc-button" onClick={addProductBtn}>Add Product to be Installed</Button>
              </div>
            </div>
          </ListGroup.Item>

          <ListGroup.Item className="enc-data-list">
            <div className="row">
              <div className="col-1">Product to be Installed</div>
              <div className="col">Sequence of Actions</div>
              <div className="col">Potential Risks</div>
              <div className="col">Site Specifc Risk</div>
              <div className="col">Site Specifc Risk Mitigation</div>
              <div className="col-1"></div>
            </div>
          </ListGroup.Item>

          {form.riskManagements.length === 0 && 
              <ListGroup.Item>
                No Products Added to JSA
              </ListGroup.Item>
          }
            {form.riskManagements.map((m, i) => {
                let productRiskIds = m.productRisks.map(x => { return x.potentialRiskId });
                m.actionTextOther = m.actionTextOther === null ? "" : m.actionTextOther;
                m.riskTextOther = m.riskTextOther === null ? "" : m.riskTextOther;

                let filterActions = form.actions.filter(f => f.scopeBuilderId === m.scopeBuilderId);

                if (form.actions.filter(f => f.scopeBuilderId === m.scopeBuilderId).length === 0) {
                    let sbItem0 = scopeBuilder[0].children.filter(f => f.scopeBuilderId === payloadSelections[1].scopeBuilderId); // item after Mitigation
                    
                    let sbItem = sbItem0;
                    for (let i = 2; i < payloadSelections.length; i++) {
                        if (payloadSelections[i] !== null) {
                            sbItem = sbItem[0].children.filter(f => f.scopeBuilderId === payloadSelections[i].scopeBuilderId);
                        } else {
                            i = payloadSelections.length + 1;
                        }
                    }

                    if (sbItem[0].children.length !== 0 && (sbItem[0].children[0].itemName === 'STC 25' || sbItem[0].children[0].itemName === 'STC 32')) {
                        filterActions = form.actions.filter(f => f.scopeBuilderId === sbItem[0].children[0].scopeBuilderId);
                    }
                }
              return (
                <ListGroup.Item key={`product-${i}`}>
                  <div className="row">
                    <div className="col-1">
                        {m.productName}
                    </div>
                    <div className="h-100 col">
                        {/* overflow style to data?? */}
                        <DropdownList
                            className="jsaDropdown"
                            data={filterActions}
                            onChange={e => changeProductAction(e, i)}
                            value={m.action}
                            textField='action'
                            placeholder="Select an action"
                            required
                            //style={{width: '100%'}}
                            //style={{'white-space': 'wrap'}}
                        />
                        {m.actionOther === true && (
                            <>
                                <br/>
                                <textarea
                                    className="h-100 enc-input-100"
                                    value={m.actionTextOther}
                                    onChange={(e) => changeActionTextOther(e.target.value, i)}
                                    required
                                    placeholder="Enter a description for action"
                                    wrap='soft'
                                />
                            </>
                        )}
                    </div>
                    <div className="h-100 col">
                      {/* any other way than adding multiple 'other' items to Admin backend?? */}
                      <Multiselect
                            data={form.risks.filter(f => f.sequenceActionId === m.sequenceActionId && !productRiskIds.includes(f.potentialRiskId))}
                            onChange={e => changeProductRisk(e, i)}
                            value={m.productRisks}
                            textField='risk'
                            placeholder="Select a risk"
                            required
                        />
                        {m.riskOther === true && (
                            <>
                                <br />
                                <textarea
                                    className="h-100 enc-input-100"
                                    value={m.riskTextOther}
                                    onChange={(e) => changeRiskTextOther(e.target.value, i)}
                                    required
                                    placeholder="Enter a description for risk"
                                    wrap='soft'
                                />
                            </>
                        )}
                    </div>
                    <div className="col">
                        <textarea
                            className="h-100 enc-input-100"
                            value={m.siteRisk}
                            onChange={(e) => changeSiteRisk(e.target.value, i)}
                            required
                            placeholder="Enter a site specific risk"
                            wrap='soft'
                        />
                    </div>
                    <div className="col">
                        <textarea
                            className="h-100 enc-input-100"
                            value={m.riskMitigation}
                            onChange={(e) => changeRiskMitigation(e.target.value, i)}
                            required
                            placeholder="Enter a site specific risk mitigation"
                            wrap='soft'
                        />
                    </div>
                    <div className="col-1 text-center mx-1">
                        <FontAwesomeIcon className="clickable" icon={faTrash} onClick={() => removeProduct(i)} />
                        <br/>
                        <Button className="enc-button" onClick={() => duplicateProduct(i)}>Copy</Button>
                    </div>
                  </div>
                </ListGroup.Item>
              )
            })}

          {addProductToBeInstalled === true && (<ProductBeInstalled jsaID={props.match.params.id} addProduct={addProduct} />)}
        </ListGroup>

        <div className="row mt-2 mb-5">
          <div className="col-8">
                <Alert variant="danger" show={errAlert} onClose={() => setErrAlert(false)} dismissible>
                    {errMsg}
                </Alert>
          </div>
          
          <div className="col-4 text-right">
            {setEdit && <Button variant="secondary" onClick={() => setEdit(false)}>Cancel</Button>}
            <Button type="submit" className="enc-button ml-2" onClick={(e) => SubmitJSA(e)}>Save JSA</Button>
          </div>
        </div>
      {/*</form >*/}
    </div >
  )
}