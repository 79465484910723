import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { DropdownList, Multiselect } from 'react-widgets';
import { GetJobList, GetJobStageList, GetJobSteps, JobSearch } from '../API/Jobs';
import { GetTypeList } from '../API/PB';
import { GetAccountList } from '../API/Account';

export default ({ setJobs = () => { }, type, ...props }) => {
    const [open, setOpen] = useState(false);
    const [steps, setSteps] = useState([]);
    const [step, setStep] = useState();
    const [canceledJobs, setCanceledJobs] = useState(false);


    const [advanceSteps, setAdvanceSteps] = useState([])
    const [advanceStep, setAdvanceStep] = useState([])

    const [advanceJobNumber, setAdvanceJobNumber] = useState("")
    const [advanceJobName, setAdvanceJobName] = useState("")

    const [accountList, setAccountList] = useState([]);
    const [advanceAccount, setAdvanceAccount] = useState(null)

    const [stageList, setStageList] = useState([])
    const [advanceStage, setAdvanceStage] = useState([])

    const [typeList, setTypeList] = useState([])
    const [advanceClassification, setAdvanceClassification] = useState([])

    const reset = () => {
        setAdvanceClassification([]);
        setAdvanceAccount(null)
        setAdvanceStage([])
        setAdvanceJobName("")
        setAdvanceJobNumber("")
        setAdvanceStep([])
        setCanceledJobs(false);
    }

    const Search = () => {
        let payload = {
            jobName: advanceJobName,
            jobNumber: advanceJobNumber,
            account: advanceAccount,
            stages: advanceStage,
            steps: advanceStep,
            classifications: advanceClassification,
            canceled: canceledJobs
        }
        JobSearch(payload)
        .then(res => {
            setJobs(res.data)
        })
    }

    useEffect(() => {
        const prom = new Promise(function (resolve, reject) {
            let num = 0
            if (type) {
                num = parseInt(type)
            }
            setStep(num)
            LoadJobs(false , num)
            resolve()
        })
        prom
            .then(res => {
                LoadSteps()
            })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type])

    useEffect(() => {
        loadClassifications();
        loadAccount();
        LoadStages();
    }, [])

    const history = useHistory();
    const changeStep = (e) => {
        history.push(`/Jobs/${e.stepId}`)
    }

    const loadAccount = () => {
        GetAccountList(true)
        .then(res => {
            setAccountList(res.data)
        })
    }

    const loadClassifications = () => {
        GetTypeList()
        .then(res => {
            setTypeList(res.data)
        })
    }

    const LoadStages = () => {
        GetJobStageList()
        .then(res => {
            setStageList(res.data)
        })
    }

    const LoadSteps = () => {
        GetJobSteps()
            .then(res => {
                setAdvanceSteps(res.data)
                let s = [...res.data]
                s.push({
                    "stepId": -1, "stepName": "View All Jobs"
                })
                setSteps(s)
            })
    }
    const LoadJobs = (canceled, jobStep) => {
        GetJobList(canceled, jobStep)
            .then(res => {
                setJobs(res.data)
            })
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            Search();
        }
    };


    return (
        <div>
            <div className="row my-2">
                <div className="col">
                    <DropdownList 
                    data={steps} 
                    textField='stepName' 
                    value={step} 
                    valueField='stepId' 
                    onChange={(e) => changeStep(e)} />
                </div>
            </div>
            <div className="row">
                <div className="col clickable" onClick={() => setOpen(!open)}>
                    Advance Search {open ? <FontAwesomeIcon icon={faAngleDown} /> : <FontAwesomeIcon icon={faAngleUp} />}
                </div>
            </div>
            {open &&
                <>
                    <div className="row">
                        <div className="col">
                            <small>Job Name</small>
                            <input 
                                value={advanceJobName}
                                onChange={e => setAdvanceJobName(e.target.value)}
                                className="enc-input-100"
                                onKeyDown={handleKeyDown}
                            />
                        </div>
                        <div className="col">
                            <small>Job Number</small>
                            <input 
                                value={advanceJobNumber}
                                onChange={e => setAdvanceJobNumber(e.target.value)}
                                className="enc-input-100" 
                                onKeyDown={handleKeyDown}
                            />
                        </div>
                        <div className="col">
                            <small>Account Name</small>
                            <DropdownList
                                filter="contains"
                                data={accountList}
                                onChange={setAdvanceAccount}
                                textField="accountName"
                                value={advanceAccount}
                                onKeyDown={handleKeyDown}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <small>Stage</small>
                            <Multiselect
                                data={stageList}
                                textField="stageName"
                                value={advanceStage}
                                onChange={setAdvanceStage}
                                onKeyDown={handleKeyDown}
                            />
                        </div>
                        <div className="col">
                            <small>Classification</small>
                            <Multiselect 
                                data={typeList}
                                textField="classificationName"
                                onChange={setAdvanceClassification}
                                value={advanceClassification}
                                onKeyDown={handleKeyDown}
                            />
                        </div>
                        <div className="col">
                            <small>Step</small>
                            <Multiselect
                                data={advanceSteps}
                                textField='stepName' 
                                value={advanceStep}
                                onChange={setAdvanceStep}
                                onKeyDown={handleKeyDown}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col text-right">
                            <div className="toggle-switch small-switch">
                                <input
                                    type="checkbox"
                                    className="toggle-switch-checkbox"
                                    id="disabled"
                                    checked={canceledJobs}
                                    onChange={() => setCanceledJobs(!canceledJobs)}
                                />
                                <label className="toggle-switch-label" htmlFor="disabled">
                                    <span className={"toggle-switch-inner"} />
                                    <span
                                        className={"toggle-switch-switch"}
                                    />
                                </label>
                            </div>
                            &nbsp; &nbsp;Cancelled
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col text-right">
                            <Button className="enc-button" onClick={reset}>Reset Search</Button>
                            <Button className="enc-button ml-2" onClick={Search}>Search</Button>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}
