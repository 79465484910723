import React, { useState, useCallback, useEffect } from 'react'
import { DragWidget } from './Drag/DragWidget'
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DropdownList } from 'react-widgets';
import JobListWidget from './Widgets/JobListWidget';
import { GetUsersWidgets, GetWidgetTypesList, PostWidget, PostWidgetList } from '../API/Dashboard';
import { Button } from 'react-bootstrap';
import { GetJobStageList } from '../API/Jobs';

export default props => {

  const [addWidget, setAddWidget] = useState({
    widgetName: "",
    widgetType: -1,
    stage: 1
  });
  const [widgets, setWidgets] = useState([])
  const [widgetTypes, setWidgetTypes] = useState([])
  const [stages, setStages] = useState([])
  const [err, setErr] = useState("")

  useEffect(() => {
    GetUsersWidgets()
      .then(res => {
        setWidgets(res.data)
      })
  }, [])

  useEffect(() => {
    GetWidgetTypesList()
      .then(res => {
        res.data.unshift({
          "widgetTypeId": -1,
          "widgetTypeName": "Select a widget type",
          "stageDropdown": false
        })
        setWidgetTypes(res.data);
      })
  }, [])

  useEffect(() => {
    GetJobStageList()
      .then(res => {
        setStages(res.data);
      })
  }, [])

  const createWidget = () => {
    if (addWidget.widgetType === -1) {
      setErr('Please select widget type.')
      return;
    }
    PostWidget(addWidget)
      .then(res => {
        if (res.success) {
          setAddWidget({
            widgetName: "",
            widgetType: -1,
            stage: -1
          })
          LoadWidgets();
          setErr("")
        }
      })
  }

  const LoadWidgets = () => {
    GetUsersWidgets()
      .then(res => {
        setWidgets(res.data)
      })
  }

  const SaveWidgets = () => {
    PostWidgetList({ widgets: widgets })
      .then(res => {
        if (res.success) {
          props.toggle();
        }
      })
  }

  const changeAddWidget = (key, value) => {
    let s = { ...addWidget }
    s[key] = value
    setAddWidget(s)
  }

  const renderLogic = () => {
    if (addWidget.widgetType !== -1) {
      let widgetType = widgetTypes.filter(wt => wt.widgetTypeId === addWidget.widgetType).pop();
      if (widgetType) {
        return widgetType.stageDropdown
      }
    }
    return false;
  }



  const moveWidget = useCallback(
    (dragIndex, hoverIndex) => {
      let s = [...widgets]
      const dragCard = widgets[dragIndex]
      s.splice(dragIndex, 1)
      s.splice(hoverIndex, 0, dragCard)
      setWidgets(s)
    },
    [widgets]
  )

  const DeleteWidget = (index) => {
    let s = [...widgets]
    s.splice(index, 1)
    setWidgets(s)
  }

  const renderCard = (widget, index) => {
    let x;
    if (widget.widgetType === 0) {
      x = JobListWidget
    }
    if (widget.widgetType === -1) {
      return;
    }
    return (
      <DragWidget
        key={index}
        index={index}
        widgetId={widget.dashboardWidgetId}
        moveWidget={moveWidget}
        Display={x}
        remove={() => DeleteWidget(index)}
      />
    )
  }
  return (
    <div className="container">
      <div className="divider my-2" />
      <div className="row">
        <div className="col text-right">
          <Button className="enc-button mr-2" onClick={props.toggle}>Cancel</Button>
          <Button className="enc-button" onClick={SaveWidgets}>Save</Button>
        </div>
      </div>
      <form action="#" onSubmit={(e) => { e.preventDefault(); createWidget() }} >
        <div className="container p-3 p1-border">
          <div className="row mb-2">
            <div className="col">
              <input
                placeholder="Custom Widget Name"
                className="enc-input-100"
                value={addWidget.widgetName}
                onChange={e => changeAddWidget('widgetName', e.target.value)}
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label>Widget Type</label>
              <DropdownList
                data={widgetTypes}
                textField='widgetTypeName'
                valueField='widgetTypeId'
                value={addWidget.widgetType}
                placeholder="Select Widget Type"
                onChange={e => changeAddWidget('widgetType', e.widgetTypeId)}
              />
            </div>
            <div className="col">
              {renderLogic() &&
                <>
                  <label>Job Stage Selection</label>
                  <DropdownList
                    data={stages}
                    textField='stageName'
                    valueField='stageId'
                    value={addWidget.stage}
                    placeholder="Select Stage"
                    onChange={e => changeAddWidget('stage', e.stageId)}
                  />
                </>}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <span className="error-message">{err}</span>
            </div>
            <div className="col text-right">
              <Button type='submit' className="enc-button">Add Widget</Button>
            </div>
          </div>
        </div>
      </form>
      <DndProvider className="row" backend={HTML5Backend}>
        <div className="row">
          {widgets.map((card, i) => renderCard(card, i))}
        </div>
      </DndProvider>
    </div>
  )
}