import APIRequest from './MainAPICall/api';
import config from '../APIConfig';

export const FlagStage2Review = (id) => {
    return APIRequest({
        url: config.baseURL + `/PB/FlagForReview?jobId=${id}`,
        method: "get"
    });
}

export const GetActiveEdit = (id) => {
    return APIRequest({
        url: config.baseURL + `/PB/GetActiveEdit?JobId=${id}`,
        method: "get"
    });
}

export const GetTypeList = () => {
    return APIRequest({
        url: config.baseURL + "/PB/GetTypeList",
        method: "get"
    })
}

export const SetClassification = (payload) => {
    return APIRequest({
        url: config.baseURL + "/PB/SetJobClassification",
        method: "post",
        body: payload
    })
}