import APIRequest from './MainAPICall/api';
import config from '../APIConfig';

export const GetRouteHelpLink = (route) => {
    return APIRequest({
        url: config.baseURL + "/Dokuwiki/GetRouteHelpLink",
        method: "post",
        body: { route: route }
    });
}

export const SearchWiki = (search) => {
    return APIRequest({
        url: config.baseURL + "/Dokuwiki/SearchWiki",
        method: "post",
        body: { query: search }
    });
}
