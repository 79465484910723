import React, { useEffect, useState } from 'react';
import { GetRoleList } from '../API/Administrator';
import { Route, NavLink } from 'react-router-dom';
import RoleViewer from './RoleViewer';
import { Nav, Button } from 'react-bootstrap';

export default props => {
    const [roleList, setRoleList] = useState([]);

    useEffect(() => {
        loadRoles();
    }, []);

    const loadRoles = () => {
        GetRoleList()
            .then(res => {
                if (res.success) {
                    setRoleList(res.roleList)
                }
            });
    }

    return (

        <div className="container">

            <div className="container">
                <h4 className="itemHeaderENCLight text-center">Roles</h4>
            </div>

            <div className="container">

                <div className="row">
                    <div className="col">
                        <Nav>
                            <Button className="enc-button my-1" activeClassName="active-role" as={NavLink} to={"/Admin/RoleList/new"}>Create Role</Button>
                            {roleList.map(m =>
                                <Nav.Link
                                    as={NavLink}
                                    key={`role-${m.roleId}`}
                                    className="role-button"
                                    activeClassName="active-role"
                                    to={`/Admin/RoleList/${m.roleId}`} >
                                    {m.roleName}
                                </Nav.Link>
                            )}
                        </Nav>
                    </div>

                    <div className="col-xl-10">
                        <Route exact path="/Admin/RoleList/:id" render={(routeprops) =>
                            <RoleViewer key={`RoleView-${routeprops.match.params.id}`} roleId={routeprops.match.params.id} />
                        } />
                    </div>
                </div>

            </div>

        </div>
    )
}
