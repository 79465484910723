import React, { useState, useEffect } from 'react';
import { GetContactProfile, GetAccountLocations, SaveContact } from '../API/Account';
import { Card, Button, Modal, Breadcrumb } from 'react-bootstrap';
import { Combobox } from 'react-widgets';
import { useHistory, Link } from 'react-router-dom';

export default props => {
    const [contactInfo, setContactInfo] = useState({
        accountContactId: 0,
        accountId: 0,
        firstName: "",
        lastName: "",
        phoneNumber: "",
        mobileNumber: "",
        officeNumber: "",
        otherNumber: "",
        email: "",
        enabled: true
    })
    const [allLocations, setAllLocations] = useState([])
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [show, setShow] = useState(false);
    const [locations, setLocations] = useState([]);
    const [removeLocations, setRemoveLocations] = useState([])
    const [remConf, setRemConf] = useState(false);

    useEffect(() => {
        if (props.contactId) {
            loadData(parseInt(props.contactId))
        }
    }, [props.contactId])

    const loadData = (id) => {
        GetContactProfile(id)
            .then(res => {
                setContactInfo(res.contactInfo)
                setLocations(res.locations)
            })
    }

    const changeContact = (key, value) => {
        let s = { ...contactInfo };
        s[key] = value
        setContactInfo(s)
    }

    const removeLocation = (i) => {
        let newLocations = [...locations];
        let removed = newLocations.splice(i, 1)
        let removedList = [...removeLocations]
        removedList.push(removed[0].accountContactId)
        setRemoveLocations(removedList)
        setLocations(newLocations)
    }

    const loadLocationList = () => {
        setSelectedLocation(null)
        GetAccountLocations(parseInt(props.accountId))
            .then(res => {
                let contactIds = []
                locations.map(e => contactIds.push(e.accountLocationId));
                let filteredList = res.data.filter(e => contactIds.indexOf(e.accountContactId) === -1)
                setAllLocations(filteredList)
            })
        setShow(true)
    }

    const addLocation = () => {
        let newLocations = [...locations]
        allLocations.forEach(e => {
            if (e.accountLocationId === parseInt(selectedLocation)) {
                newLocations.push(e)
            }
        })
        setLocations(newLocations)
        setShow(false)
    }

    const history = useHistory();
    const saveContact = () => {
        let payload = { ...contactInfo };
        let locationIdList = [];
        locations.map(m => locationIdList.push(m.accountLocationId))
        if (payload.accountContactId === 0) {
            payload.accountId = parseInt(props.accountId);
        }
        payload.locationList = locationIdList;
        payload.removedLocations = removeLocations;
        SaveContact(payload)
            .then(res => {
                if (res.success) {
                    history.push(`${props.url}/${props.accountId}/Contact/${res.contactId}`)
                }
            })
    }
    const deactivateContact = () => {
        let payload = { ...contactInfo };
        payload.enabled = false;
        SaveContact(payload)
            .then(res => {
                if (res.success) {
                    history.push(`${props.url}/${props.accountId}/Contact`)
                }
            })
    }

    return (
        <div className="container-fluid">
            <Breadcrumb className="breadcrumb">
                <li className="breadcrumb-item"><Link to={`${props.url}`}>Accounts List</Link></li>
                <li className="breadcrumb-item"><Link to={`${props.url}/${props.accountId}`}>Account</Link></li>
                {contactInfo.accountContactId !== 0 && <li className="breadcrumb-item"><Link to={`${props.url}/${props.accountId}/Contact/${contactInfo.accountContactId}`}>Contact Profile</Link></li>}
                <Breadcrumb.Item active>Edit Contact</Breadcrumb.Item>
            </Breadcrumb>
            <div className="container">
                <form action="#" id="Contact_Form" onSubmit={(e) => { e.preventDefault(); saveContact() }}>
                    <div className="row mt-3 p-2">
                        <div className="col">
                            <div className="row">
                                <div className="col">
                                    <h5>Contact Information</h5>
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col">
                                    <small>First Name</small>
                                    <input type="text"
                                        className="enc-input-100"
                                        name="firstName"
                                        value={contactInfo.firstName}
                                        placeholder="First Name"
                                        onChange={e => changeContact('firstName', e.target.value)} />
                                </div>
                                <div className="col">
                                    <small>Last Name</small>
                                    <input
                                        type="text"
                                        className="enc-input-100"
                                        name="lastName"
                                        value={contactInfo.lastName}
                                        placeholder="Last Name"
                                        onChange={e => changeContact('lastName', e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col">
                                    <small>Phone</small>
                                    <input
                                        type="text"
                                        className="enc-input-100"
                                        name="phoneNumber"
                                        value={contactInfo.phoneNumber}
                                        placeholder="Phone Number"
                                        onChange={e => changeContact('phoneNumber', e.target.value)} />
                                </div>
                                <div className="col">
                                    <small>Mobile Number</small>
                                    <input
                                        type="text"
                                        className="enc-input-100"
                                        name="mobileNumber"
                                        value={contactInfo.mobileNumber}
                                        placeholder="Mobile Number"
                                        onChange={e => changeContact('mobileNumber', e.target.value)} />
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col">
                                    <small>Office Number</small>
                                    <input
                                        type="text"
                                        className="enc-input-100"
                                        name="officeNumber"
                                        value={contactInfo.officeNumber}
                                        placeholder="Office Number"
                                        onChange={e => changeContact('officeNumber', e.target.value)} />
                                </div>
                                <div className="col">
                                    <small>Other Number</small>
                                    <input
                                        type="text"
                                        className="enc-input-100"
                                        name="otherNumber"
                                        value={contactInfo.otherNumber}
                                        placeholder="Other Number"
                                        onChange={e => changeContact('otherNumber', e.target.value)} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <small>Email</small>
                                    <input
                                        type="text"
                                        className="enc-input-100"
                                        name="email"
                                        value={contactInfo.email}
                                        placeholder="Email"
                                        onChange={e => changeContact('email', e.target.value)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div className="row my-2">
                <div className="col text-center">
                    <h3>Contact Locations</h3>
                </div>
            </div>
            <div className="row">
                <div className="col text-center">
                    <Button onClick={loadLocationList} className="enc-button">Add Location</Button>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col">
                    {locations.map((location, index) =>
                        <Card className="mx-2" key={`location-${location.accountLocationId}`} style={{ width: '18rem' }}>
                            <Card.Header ><strong>{location.locationName}</strong></Card.Header>
                            <Card.Body>
                                <Card.Subtitle><strong>Billing Address</strong></Card.Subtitle>
                                <Card.Text>
                                    {`${location.billingAddress1}`} <br />
                                    {`${location.billingAddress2}`} <br />
                                    {`${location.billingCity}, ${location.billingState} ${location.billingZip}`}
                                </Card.Text>
                                <Card.Subtitle><strong>Shipping Address</strong></Card.Subtitle>
                                <Card.Text>
                                    {`${location.shippingAddress1}`} <br />
                                    {`${location.shippingAddress2}`} <br />
                                    {`${location.shippingCity}, ${location.shippingState} ${location.shippingZip}`}
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer>
                                <div className="row">
                                    <div className="col text-right">
                                        <Button variant="danger" onClick={() => removeLocation(index)}><small>Remove Location</small></Button>
                                    </div>
                                </div>
                            </Card.Footer>
                        </Card>
                    )}
                </div>
            </div >
            <div className="container">
                <div className="row mt-2 mb-5">
                    <div className="col text-right">
                        {contactInfo.accountContactId !== 0 ? <Button variant="danger" onClick={() => setRemConf(true)}>Delete Contact</Button> : null}
                        <Button className="enc-button ml-2" form="Contact_Form" type='submit'>Save Changes</Button>
                    </div>
                </div>
            </div>
            <Modal show={show} onHide={() => setShow(false)}>
                <form action="#" onSubmit={(e) => { e.preventDefault(); addLocation() }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Contact to Location</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Combobox
                            data={allLocations}
                            textField='locationName'
                            valueField='accountLocationId'
                            value={selectedLocation}
                            onChange={con => setSelectedLocation(con.accountLocationId)}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="enc-button" onClick={() => setShow(false)}>
                            Cancel
                    </Button>
                        <Button type='submit' className="enc-button" >
                            Add Contact
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            <Modal show={remConf} onHide={() => setRemConf(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Removal</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    This will delete this contact. Please confirm you would like to take this action.
                    </Modal.Body>
                <Modal.Footer>
                    <Button className="enc-button" onClick={() => setRemConf(false)}>
                        Cancel
                    </Button>
                    <Button className="enc-button" onClick={deactivateContact} >
                        Confirm
                        </Button>
                </Modal.Footer>
            </Modal>
        </div >
    )
}
