import React, { useState, useEffect } from 'react';
import { ListGroup } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { GetDashboardJobList } from '../../API/Dashboard';

export default ({ passRef, style, widgetId, remove, ...props }) => {
    const [widgetName, setWidgetName] = useState("")
    const [jobs, setJobs] = useState([])
    useEffect(()=>{
        loadJobs(widgetId)
    },[widgetId])

    const loadJobs = (widgetId) => {
        GetDashboardJobList(widgetId)
        .then(res => {
            setJobs(res.jobs)
            setWidgetName(res.widgetName)
        })
    }

    let history = useHistory();
    const navigateToJob = (job) => {
        if(remove){
            return;
        }
        history.push(`/Opportunities/${job.opportunityId}/${job.jobId}/${job.jobStage}`)
    }

    return (
        <div ref={passRef} style={style} className="col-lg-12 col-md-12 p-4">
            {remove &&
                <div className="row">
                    <div className="col text-right">
                        <FontAwesomeIcon icon={faTrash} className="clickable" onClick={remove} />
                    </div>
                </div>
            }

            <ListGroup>
                <ListGroup.Item className="enc-data-list">
                    <div className="row">
                        <div className="col text-center">
                            <h4>{widgetName}</h4>
                        </div>
                    </div>
                </ListGroup.Item>
                <ListGroup.Item className="enc-data-list">
                        <div className="row">
                            <div className="col-lg-3">
                                Job Name
                            </div>
                            <div className="col-lg-2">
                                Classification
                            </div>
                            <div className="col-lg-3">
                                Job #
                            </div>
                            <div className="col-lg-2">
                                Stage
                            </div>
                            <div className="col-lg-2">
                                Last Updated
                            </div>
                        </div>
                    </ListGroup.Item>
                {jobs.length === 0 && <ListGroup.Item>No Jobs to Display</ListGroup.Item>}
                {jobs.map(m => {
                    const getTime = () => {
                        if (m.lastUpdated !== null) {
                            var utcDate = m.lastUpdated;
                            var localDate = new Date(utcDate + 'Z');
                            return (
                                <>
                                    {localDate.toLocaleDateString()}
                                    &nbsp;
                                    {localDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                </>
                            )
                        }
                        else {
                            return (
                                <>
                                    N/A
                                </>
                            )
                        }
                    };
                    return (
                        <ListGroup.Item key={`LGI-${m.jobId}`} onClick={() => navigateToJob(m)} className="click-list">
                            <div className="row">
                                <div className="col-lg-3">
                                    {m.canceled && "Cancelled -"} {m.jobName}
                                </div>
                                <div className="col-lg-2">
                                    {m.jobClassification}
                                </div>
                                <div className="col-lg-3">
                                    {m.jobNumber}
                                </div>
                                <div className="col-lg-2">
                                    {m.jobStage}
                                </div>
                                <div className="col-lg-2">
                                    {getTime()}
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col">
                                    {m.jobDescription}
                                </div>
                            </div>
                        </ListGroup.Item>
                    )
                })}
            </ListGroup>

        </div>
    )
}
