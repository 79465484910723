import APIRequest from './MainAPICall/api';
import config from '../APIConfig';

export const GetTimesheetActivities = () => {
    return APIRequest({
        url: config.baseURL + "/TimesheetEntry/GetTimesheetActivities",
        method: "get"
    });
}

export const CreateTimesheetEntries = (payload) => {
    return APIRequest({
        url: config.baseURL + "/TimesheetEntry/CreateTimesheetEntries",
        method: "post",
        body: payload
    });
}

export const SaveTimesheetEntry = (payload) => {
    return APIRequest({
        url: config.baseURL + "/TimesheetEntry/SaveTimesheetEntry",
        method: "post",
        body: payload
    });
}

export const DeleteTimesheetEntry = (id) => {
    return APIRequest({
        url: config.baseURL + "/TimesheetEntry/DeleteTimesheetEntry",
        method: "post",
        body: { id: id }
    });
}

export const GetTimesheetEntryList = (id) => {
    return APIRequest({
        url: config.baseURL + "/TimesheetEntry/GetTimesheetEntryList",
        method: "post",
        body: { id: id }
    });
}

export const GetTimesheetEntry = (id) => {
    return APIRequest({
        url: config.baseURL + "/TimesheetEntry/GetTimesheetEntry",
        method: "post",
        body: { id: id }
    });
}

