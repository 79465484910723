import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Grid({ data = [], headers = [], headerName = "", clickRow = false, url="/", urlId="id" }) {
    const [dataList, setDataList] = useState(data)

    const [sortBy, setSortBy] = useState({ sortKey: "", sortDesc: true, sortType: "basic" })
    const sortType = {
        BASIC: "basic",
        DATE: "date",
    };
    useEffect(() => {
        if (headers.length > 0 && headers[0].key) {
            setSortBy({ sortKey: headers[0].key, sortDesc: true, sortType: headers[0].sortType ? headers[0].sortType : sortType.BASIC })
        }
    }, [headers])

    useEffect(() => {
        if(data) {
            setDataList(data)
        }
    },[data])

    useEffect(() => {
        let s = [...dataList]
        function compare(a, b) {
            var lhs = a[sortBy.sortKey];
            var rhs = b[sortBy.sortKey];
            if (sortBy.sortType == sortType.BASIC) {
                // No transformation
            }
            if (sortBy.sortType == sortType.DATE) {
                lhs = Date.parse(lhs);
                rhs = Date.parse(rhs);
            }
            if (lhs < rhs) {
                return -1;
            }
            if (lhs > rhs) {
                return 1;
            }
            return 0;
        }
        s = s.sort(compare);
        if (sortBy.sortDesc) {
            s.reverse()
        }
        setDataList(s)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortBy])

    const HeaderClick = (header) => {
        let key = header.key;
        let type = header.sortType;
        let s = { ...sortBy }
        if (s.sortKey === key) {
            s.sortDesc = !s.sortDesc
        } else {
            s.sortKey = key
            s.sortDesc = true
        }
        s.sortType = header.sortType;
        setSortBy(s)
    }




    return (
        <div className="container-fluid my-2">
            <ListGroup>
                <ListGroup.Item className="enc-data-list">
                    {headerName ? headerName : "Data Grid"}
                </ListGroup.Item>
                <ListGroup.Item className="enc-data-list">
                    <div className="row">
                        {headers.map((m, i) => {
                            return (
                                <div className={`col clickable`} key={`header-${i}`} onClick={() => HeaderClick(m)}>
                                    {m.name} {m.key === sortBy.sortKey && (sortBy.sortDesc ? <FontAwesomeIcon icon={faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} />)}
                                </div>
                            )
                        })}
                    </div>
                </ListGroup.Item>
                {dataList.map((m, i) => {
                    let AdditionalParams = {}
                    if(clickRow){
                        AdditionalParams["as"] = Link
                        AdditionalParams["to"] = `${url}/${m[urlId]}`
                        AdditionalParams["className"] = "click-list"
                    }
                    
                    return (
                        <ListGroup.Item key={`SDG-${i}`} {...AdditionalParams} > 
                            <div className="row">
                                {headers.map((h, hi) => {
                                    return (
                                        <div className="col" key={`SDG-DC-${hi}`}>
                                            {m[h.key]}
                                        </div>
                                    )
                                })}
                            </div>
                        </ListGroup.Item>
                    )
                })}
            </ListGroup>
        </div>
    )
}

export default Grid