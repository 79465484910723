import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import AccountBudgetSummary from './AccountingBudgetSummary'
import { AccountingApproval, AccountingSkip } from '../../../API/JobApproval';
import DownloadBudgetSummary from './DownloadBudgetSummary';

export default ({jobId, rl, approval, accountingSkipped}) => {

    const [disable, setDisable] = useState(false);

    const Approve = () => {
        setDisable(true)
        AccountingApproval(parseInt(jobId))
            .then(res => {
                if (res.success) {
                    rl()
                }
            })
    }

    const Reject = () => {
        setDisable(true)
        AccountingSkip(parseInt(jobId))
            .then(res => {
                if (res.success) {
                    rl()
                }
            })
    }


    return (
        <div>
            <div className="row">
                <div className="col">
                    <h4>Accounting Approval</h4>
                </div>
            </div>
            {approval.jobApprovalId !== 0 ?
                <div className="row">
                    <div className="col-md-6">
                        {`Approved by: ${approval.approver} on ${approval.approvalDate}`}
                    </div>
                    <div className="col-md-6 text-right">
                        <DownloadBudgetSummary jobId={jobId} />
                    </div>
                </div>
                    :
                <div className="row">
                        <div className="col">
                            "No Approval"
                    </div>
                </div>
            }
            <AccountBudgetSummary jobId={jobId} />
            {approval.edit &&
                <div className="row mt-2">
                    <div className="col text-right">
                        <Button className="enc-button mr-1" onClick={Reject} disabled={disable || accountingSkipped}>Skip Accounting</Button>
                        <Button className="enc-button" onClick={Approve} disabled={disable}>Approve</Button>
                    </div>
                </div>
            }
        </div>
    )
}
