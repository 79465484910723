import React, { useState, useEffect } from 'react';
import { TransferYards, TransferJobs, GetInvetoryExcelTemplate } from '../API/InventoryTransfer';
import { Button, Alert } from 'react-bootstrap';
import { DropdownList } from 'react-widgets';
import { FilePond } from 'react-filepond';
import LoadingOverlay from 'react-loading-overlay';

export default props => {
    const [jobList, setJobList] = useState([])
    const [jobSelection, setJobSelection] = useState(null)

    const [yardList, setYardList] = useState([])
    const [yardSelection, setYardSelection] = useState(null);

    const [loading, setLoading] = useState(false);

    const [alert, setAlert] = useState({
        showAlert: false,
        variant: "success",
        message: "Error Message"
    })


    useEffect(() => {
        TransferYards()
            .then(res => {
                setYardList(res.data)
            })
        TransferJobs()
            .then(res => {
                setJobList(res.data)
            })
    }, [])

    const FilePondSettings = () => {
        let token;

        const LS = localStorage.getItem("BAENCToken");
        const SS = sessionStorage.getItem("BAENCToken");

        if (LS) {
            token = LS;
        } else if (SS) {
            token = SS;
        }

        const startLoading = () => {
            setLoading(true);
        }

        const finishLoading = (res) => {
            setLoading(false);
            if (res.success) {
                setJobSelection(null);
                setYardSelection(null);
                setAlert({
                    showAlert: true,
                    variant: "success",
                    message: "Successfully uploaded excel sheet"
                })
            } else {
                setAlert({
                    showAlert: true,
                    variant: "danger",
                    message: res.message
                })
            }
        }

        return (
            {
                url: "/api/InventoryTransfer",
                process: {
                    url: "/UploadInventoryExcel",
                    method: "post",
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    ondata: (formData) => {
                        startLoading()
                        formData.append("YardId", yardSelection.id);
                        formData.append("JobId", jobSelection.id);
                        return formData;
                    },
                    onload: (res) => {
                        finishLoading(JSON.parse(res))
                    }
                },
                load: null,
                revert: null,
                restore: null,
                fetch: null,
                patch: null
            })
    }


    return (
        <>
            <LoadingOverlay
                active={loading}
                spinner
                text="Processing request..."
            >

                <div className="container">
                    <div className="row mt-2">
                        <div className="col ">
                            <h3>Inventory Excel Upload</h3>
                        </div>
                    </div>
                    {alert.showAlert &&
                        <Alert variant={alert.variant} dismissible onClose={() => { setAlert({ ...alert, showAlert: false }) }}>
                            {alert.message}
                        </Alert>
                    }
                    <div className="row mt-2">
                        <div className="col text-right">
                            <Button onClick={GetInvetoryExcelTemplate} className="enc-button">Download Form</Button>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col">
                            Select an Origin Yard
                            </div>
                        <div className="col">
                            <DropdownList
                                placeholder="Select Origin Yard"
                                data={yardList}
                                onChange={e => setYardSelection(e)}
                                value={yardSelection}
                                textField="name"
                            />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col">
                            Select a Destination Job
                        </div>
                        <div className="col">
                            <DropdownList
                                placeholder="Select Destination Job"
                                data={jobList}
                                onChange={e => setJobSelection(e)}
                                value={jobSelection}
                                textField="name"
                            />
                        </div>
                    </div>
                    {jobSelection && yardSelection &&
                        <>
                            <div className="row">
                                <div className="col">
                                    <h4>Excel File Upload</h4>
                                </div>
                            </div>
                            <FilePond
                                files={[]}
                                className="mt-3"
                                allowMultiple={false}
                                server={FilePondSettings()}
                                credits={false}

                            />
                        </>
                    }
                </div>
            </LoadingOverlay>
        </>
    )
}
