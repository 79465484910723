import React from 'react';
import ChangePassword from '../User/ChangePassword'


export default props => {



    return (
        <div className="app-container-image">
            <div className="container">
                <div className="row justify-content-center h-100">
                    <div className="col-xl-8 col-sm-12 m-auto white-bg p1-border px-5 py-3">
                        <ChangePassword {...props}/>
                    </div>
                </div>
            </div>
        </div>
    )
}