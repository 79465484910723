import React, { useState, useEffect } from 'react';
import { ChangePassword, ResetPasswordLoggedIn } from '../API/User';
import { Button } from 'react-bootstrap';

export default props => {

    const [errorMessage, setErrorMessage] = useState(" ");
    const [currentPassword, setCurrentPassword] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [reset, setReset] = useState(false);

    useEffect(() => {
        if (password !== confirmPassword) {
            setErrorMessage("Passwords must match")
        }
        else {
            setErrorMessage(" ")
        }
    }, [password, confirmPassword]);

    const handlePassword = (e) => {
        setPassword(e.target.value)
    }

    const handleCurrentPassword = (e) => {
        setCurrentPassword(e.target.value)
    }

    const handleConfirmPassword = (e) => {
        setConfirmPassword(e.target.value)
    }

    const loggedInReset = () => {
        ResetPasswordLoggedIn()
            .then(res => {
                setReset(true)
            })
    }

    const ChangePasswordClick = () => {
        let payload = {
            currentPassword: currentPassword.trim(),
            newPassword: password.trim(),
            confirmPassword: confirmPassword.trim()
        }
        ChangePassword(payload)
            .then(res => {
                setErrorMessage(res.message)
                if (props.changed) {
                    props.changed();
                }
            })
            .catch(err => {
                setErrorMessage(err.message)
            })
    }

    return (
        <div className="container mt-3">
            <form action="#" onSubmit={(e) => { e.preventDefault(); ChangePasswordClick() }}>
                <div className="row mb-2">
                    <div className="col">
                        <h3>Change Password</h3>
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col">Current Password:</div>
                    <div className="col">
                        <input type="password" className="enc-input-100" name="currentPassword"
                            value={currentPassword} onChange={handleCurrentPassword}
                            required />
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col">New Password:</div>
                    <div className="col">
                        <input type="password" className="enc-input-100" name="password"
                            value={password} onChange={handlePassword}
                            required />
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col">Confirm New Password:</div>
                    <div className="col">
                        <input type="password" className="enc-input-100" name="confirmPassword"
                            value={confirmPassword} onChange={handleConfirmPassword}
                            required />
                    </div>
                </div>
                <div className="row">
                    <div className="col text-center">
                        <span style={{ color: "red" }}>{errorMessage}</span>
                    </div>
                    <div className="col text-right">
                        <input type="button" value="Confirm" className="enc-button" onClick={ChangePasswordClick} />
                    </div>
                </div>
            </form>

            {props.force &&
                <>
                    <div className="row mt-2">
                        <div className="col">
                            <h4>Reset current password:</h4>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col text-right">
                            {!reset ? <Button className="enc-button" onClick={loggedInReset}>Send new password</Button> : <div>New Password Sent to Email, please check to reset</div>}
                        </div>
                    </div>
                </>
            }

        </div>
    )
}