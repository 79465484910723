import React, { useEffect, useState } from 'react';
import { SerializedItemProfile } from '../../../API/AssetInventory';
import FailureForm from './FailureForm';
import PhotoUpload from './PhotoUpload';

export default props => {
    const [profile, setProfile] = useState({
        serializedItemId: 0,
        serialNumber: "",
        name: "",
        decommissioned: false,
        statuses: [],
        manufacturer: "",
        mode: "",
        licenses: "",
        purchaseDate: "",
        purchasePrice: 0,
        assetType: 0,
        meterType: ""
    })

    const [step, setStep] = useState(0);

    const [failureId, setFailureId] = useState(null);

    useEffect(() => {
        SerializedItemProfile(props.match.params.id)
            .then(res => {
                setProfile(res.data)
            })
    }, [props.match.params.id])

    const SaveForm = (id) => {
        setStep(1)
        setFailureId(id)
    }

    const RenderLogic = () => {
        switch(step){
            case 0: 
                return <FailureForm save={SaveForm} {...props} />
            case 1:
                return <PhotoUpload failureId={failureId} />
            default:
                return;
        }
    }


    return (
        <div className="container my-5">
            <div className="row">
                <div className="col">
                   <h3>{profile.manufacturer} - {profile.model} - {profile.serialNumber}</h3>
                </div>
            </div>
            {RenderLogic()}
        </div>
    )
}
