import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { GetAsset, PostAsset } from '../../../API/AssetInventory';

import { useHistory } from 'react-router-dom';
import ReactDatePicker from 'react-datepicker';


export default props => {



    const [asset, setAsset] = useState({
        serialNumber: "",
        yardId: -1,
        manufacturer: "",
        model: "",
        licenses: "",
        purchaseDate: "",
        purchasePrice: "",
        assetType: -1,
        meterType: -1
    });

    const LoadAsset = (id) => {
        GetAsset(id)
        .then(res => {
            console.log(res.data.lastCalibratedDate)
            res.data.lastCalibratedDate = new Date(res.data.lastCalibratedDate)
            setAsset(res.data)
        })
    }

    useEffect(() => {
        LoadAsset(props.match.params.id)
    }, [props.match.params.id])


    let history = useHistory();
    const submitNew = () => {
        let payload = { ...asset };
        payload.purchasePrice = parseFloat(payload.purchasePrice)
        payload.lastCalibratedDate = asset.lastCalibratedDate.toJSON();
        PostAsset(payload)
            .then(res => {
                history.push(`/Admin/Inventory/Asset/${asset.serializedItemId}`)
            });
    }

    const changeAsset = (key, value) => {
        let s = { ...asset }
        s[key] = value
        setAsset(s)
    }


    return (

        <div className="container">
            <div>
                <h4 className="text-center itemHeaderENCLight">Edit Asset Item</h4>
            </div>

            <div className="container border border-dark py-3">

                <div className="divider my-2" />
                <form action="#" onSubmit={(e) => { e.preventDefault(); submitNew() }}>
                    <div className="row">
                        <div className="col">
                            <input
                                className="enc-input-100"
                                placeholder="Manufacturer"
                                value={asset.manufacturer}
                                onChange={(e) => changeAsset('manufacturer', e.target.value)}
                                required
                            />
                        </div>
                        <div className="col">
                            <input
                                className="enc-input-100"
                                placeholder="Model"
                                value={asset.model}
                                onChange={(e) => changeAsset('model', e.target.value)}
                                required
                            />
                        </div>
                        <div className="col">
                            <input
                                className="enc-input-100"
                                placeholder="Serial Number"
                                value={asset.serialNumber}
                                onChange={(e) => changeAsset('serialNumber', e.target.value)}
                                required
                            />
                        </div>
                    </div>


                    <div className="row mt-1">
                        <div className="col">
                            {asset.assetType}

                        </div>
                        <div className="col">
                            {asset.meterType}
                        </div>
                    </div>
                    <div className="row mt-1">
                        <div className="col">
                            {asset.purchaseDate}
                        </div>
                        <div className="col-4 date-picker-fix">
                            <ReactDatePicker
                                className="enc-input-100"
                                placeholderText="Last Calibrated Date"
                                selected={asset.lastCalibratedDate}
                                onChange={(date) => changeAsset('lastCalibratedDate', date)}
                            />
                        </div>
                        <div className="col">
                            <input
                                type="number"
                                className="enc-input-100"
                                placeholder="Purchase Price"
                                value={asset.purchasePrice}
                                onChange={(e) => changeAsset('purchasePrice', e.target.value)} />
                        </div>
                    </div>
                    <div className="row mt-1">
                        <div className="col">
                            <textarea
                                className="enc-textarea"
                                placeholder="Licenses"
                                value={asset.licenses}
                                onChange={(e) => changeAsset('licenses', e.target.value)} />
                        </div>
                    </div>
                    <div className="row mt-1">
                        <div className="col text-right">
                            <Button className="enc-button" type='submit'>Save Item</Button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
