import APIRequest from './MainAPICall/api';
import config from '../APIConfig';

export const CreateBulkItem = (payload) => {
    return APIRequest({
        url: config.baseURL + "/InventoryBulk/BulkItem",
        method: "post",
        body: payload
    });
}

export const GetBulkItemList = () => {
    return APIRequest({
        url: config.baseURL + "/InventoryBulk/BulkItemList",
        method: "get"
    });
}

export const GetYardBulkInventory = (id) => {
    return APIRequest({
        url: config.baseURL + `/JobReadyInventory/YardInventory?id=${id}`,
        method: "get"
    });
}

export const GetBulkItem = (id) => {
    return APIRequest({
        url: config.baseURL + `/InventoryBulk/BulkItem?id=${id}`,
        method: "get"
    });
}

export const DeleteBulkItem = (id) => {
    return APIRequest({
        url: config.baseURL + `/InventoryBulk/DeleteBulkItem?id=${id}`,
        method: "delete"
    });
}

export const GetJobInventoryBulk = (id) => {
    return APIRequest({
        url: config.baseURL + `/InventoryBulk/JobInventoryBulk?id=${id}`,
        method: "get"
    });
}

export const PostJobInventoryBulk = (payload) => {
    return APIRequest({
        url: config.baseURL + `/InventoryBulk/JobInventoryBulk`,
        method: "post",
        body: payload
    });
}