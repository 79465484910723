import React, { useEffect, useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { GetJSAListAll } from '../../API/JSA';

export default  (props) => {
    const [jsaList, setJSAList] = useState([]);

    useEffect(() => {
        GetJSAListAll(props.jobId)
            .then(res => {
                setJSAList(res.data)
            })
    }, [props.jobId])
    return (
        <div className="container my-3">
            <ListGroup>
            <ListGroup.Item className="enc-list">Job Safety Analysis</ListGroup.Item>
            {jsaList.length === 0 && <ListGroup.Item>No Forms Available</ListGroup.Item>}
                {jsaList.map(j => {
                    return (
                        <ListGroup.Item  className="click-list" key={`jsa-${j.jsaId}`} as={Link} to={`${props.match.url}/${j.jsaId}`}>
                            <div className="row">
                                <div className="col">
                                    {j.date} 
                                </div>
                                <div className="col">
                                    {j.createdBy}
                                </div>
                            </div>
                        </ListGroup.Item>
                    )
                })}
            </ListGroup>
        </div>
    )
}
