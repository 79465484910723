import APIRequest from './MainAPICall/api';
import config from '../APIConfig';

export const GetOpportunityList = (closed = false) => {
    return APIRequest({
        url: config.baseURL + `/Opportunity/GetOpportunityList?Closed=${closed}`,
        method: "get"
    });
}

export const GetOpportunityProfile = (id) => {
    return APIRequest({
        url: config.baseURL + "/Opportunity/GetOpportunityProfile",
        method: "post",
        body: {
            id: id
        }
    });
}

export const SaveOpportunity = (payload) => {
    return APIRequest({
        url: config.baseURL + "/Opportunity/SaveOpportunity",
        method: "post",
        body: payload
    });
}

export const CloseOpportunity = (id) => {
    return APIRequest({
        url: config.baseURL + `/Opportunity/CloseOpportunity?opportunityId=${id}`,
        method: "get"
    });
}

export const OpenOpportunity = (id) => {
    return APIRequest({
        url: config.baseURL + `/Opportunity/OpenOpportunity?opportunityId=${id}`,
        method: "get"
    });
}

export const GetOpportunityNavigation = () => {
    return APIRequest({
        url: config.baseURL + "/Opportunity/OpportunityNavigation",
        method: "get"
    });
}

export const GetOpportunityLocationList = (id) => {
    return APIRequest({
        url: config.baseURL + `/Opportunity/OpportunityLocationList?opportunityId=${id}`,
        method: "get"
    });
}

export const CheckEditOpportunity = () => {
    return APIRequest({
        url: config.baseURL + "/Opportunity/CheckEditOpportunity",
        method: "get"
    });
}