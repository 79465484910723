import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { Button } from 'react-bootstrap';
import { DropdownList } from 'react-widgets';
import SurveyMeasurementAttachmentList from './SurveyMeasurementAttachmentList';

export default (props) => {
    const [surveyMeterOption, setSurveyMeterOption] = useState({value: "", serialNumber: "", type: ""});

    useEffect(() => {
        for (let i = 0; i < props.surveyMeterOptions.length; i++) {
            if (parseInt(props.surveyMeterOptions[i].value) === parseInt(props.surveyMeasurement.inventoryAssetId)) {
                setSurveyMeterOption(props.surveyMeterOptions[i]);
                break;
            }
        }
    },[props.surveyMeasurement, props.surveyMeterOptions])

    const changeSurveyMeasurement = (e) => {
        const newState = { ...(props.surveyMeasurement) };
        newState[e.target.name] = e.target.value;
        props.updateSurveyMeasurement(newState, props.index);
    }

    const changeMeter = (e) => {
        const newState = { ...(props.surveyMeasurement) };
        newState.inventoryAssetId = parseInt(e.value);
        props.updateSurveyMeasurement(newState, props.index);
    }

    const changeDeploymentDate = (date) => {
        const newState = { ...(props.surveyMeasurement) };
        newState.deploymentDate = date;
        newState.deploymentDateString = date;
        props.updateSurveyMeasurement(newState, props.index);
    }

    const changeRetrievalDate = (date) => {
        const newState = { ...(props.surveyMeasurement) };
        newState.retrievalDate = date;
        newState.retrievalDateString = date;
        props.updateSurveyMeasurement(newState, props.index);
    }

    const toggleCheckbox = (e) => {
        const newState = { ...(props.surveyMeasurement) };
        newState[e.target.name] = !props.surveyMeasurement[e.target.name];
        props.updateSurveyMeasurement(newState, props.index);
    }

    const disableSurveyMeasurement = () => {
        const newState = { ...(props.surveyMeasurement) };
        newState["unused"] = true;
        props.updateSurveyMeasurement(newState, props.index);
    }

    const enableSurveyMeasurement = () => {
        const newState = { ...(props.surveyMeasurement) };
        newState["unused"] = false;
        newState["unusedReason"] = "";
        props.updateSurveyMeasurement(newState, props.index);
    }

    return (
        <div className="container pt-4">
            <div className={props.surveyMeasurement.unused ? "row survey-head-unused" : "row survey-head"}>
                <div className="col-lg-2">
                    {props.surveyMeasurement.unused && <>Unused</>}
                </div><div className="col text-center">
                    Survey Measurement
                </div><div className="col-lg-2 text-right">
                    {props.editing ? 
                        props.surveyMeasurement.unused ? 
                            <Button className="enc-button ml-2" onClick={() => enableSurveyMeasurement()}>Re-enable</Button>
                            : parseInt(props.surveyMeasurement.surveyMeasurementId) === 0 ?
                                <Button variant="danger" onClick={() => props.deleteSurveyMeasurement(props.index)}>Delete</Button>
                                : <Button variant="secondary" className="ml-2" onClick={() => disableSurveyMeasurement()}>Mark Unused</Button>
                        : props.surveyMeasurement.unused && <>Unused</>
                        // Here's what the above code does:
                        // -If editing and unused, display re-enable button.
                        // -If editing and used and new, display delete button.
                        // -If editing and used and not new, display mark-unused button.
                        // -If not editing and unused, display "Unused".
                        // -If not editing and used, do nothing.
                    }
                </div>
            </div> 

            <div className="row">
                <div className={props.surveyMeasurement.unused ? "col-lg-1 num-header-unused" : "col-lg-1 num-header"}>
                    {`${props.index + 1}`}
                </div>
                <div className="col">
                    <div className="row">
                        <div className="col">
                            <div className="row survey-subhead">
                                <div className="col">
                                    Meter Info
                                </div>
                            </div> <div className="row">
                                <div className="col">
                                    Serial:
                                </div> <div className="col">
                                    { props.editing ?
                                        <DropdownList 
                                            data={props.surveyMeterOptions} 
                                            textField='serialNumber' 
                                            value={surveyMeterOption.serialNumber} 
                                            valueField='value' 
                                            onChange={(e) => changeMeter(e)} 
                                        /> : <>
                                            {surveyMeterOption.serialNumber}
                                        </>
                                    }
                                </div> 
                            </div> <div className="row">
                                <div className="col">
                                    Type:
                                </div> <div className="col">
                                    {surveyMeterOption.type}
                                </div>
                            </div> 
                        </div>
                        <div className="col">
                            <div className="row survey-subhead">
                                <div className="col">
                                    Installation
                                </div>
                            </div> <div className="row">
                                <div className="col">
                                    Microphone Height:
                                </div> <div className="col">
                                    { props.editing ?
                                        <input 
                                            type="text" 
                                            className="enc-input-100" 
                                            name="height" 
                                            value={props.surveyMeasurement.height || ""} 
                                            onChange={changeSurveyMeasurement} 
                                        /> : <>
                                            {props.surveyMeasurement.height}
                                        </>
                                    }
                                </div>
                            </div> <div className="row">
                                <div className="col">
                                    Mounting Method:
                                </div> <div className="col">
                                    { props.editing ?
                                        <input 
                                            type="text" 
                                            className="enc-input-100" 
                                            name="mounting" 
                                            value={props.surveyMeasurement.mounting || ""} 
                                            onChange={changeSurveyMeasurement} 
                                        /> : <>
                                            {props.surveyMeasurement.mounting}
                                        </>
                                    }
                                </div>
                            </div> 
                        </div> 
                        <div className="col">
                            <div className="row survey-subhead">
                                <div className="col">
                                    Coordinates
                                </div>
                            </div> <div className="row">
                                <div className="col">
                                    Lat:
                                </div> <div className="col">
                                    { props.editing ?
                                        <input 
                                            type="text" 
                                            className="enc-input-100" 
                                            name="gpsLatitude" 
                                            value={props.surveyMeasurement.gpsLatitude || ""} 
                                            onChange={changeSurveyMeasurement} 
                                        /> : <>
                                            {props.surveyMeasurement.gpsLatitude}
                                        </>
                                    }
                                </div>
                            </div> <div className="row">
                                <div className="col">
                                    Long:
                                </div> <div className="col">
                                    { props.editing ?
                                        <input 
                                            type="text" 
                                            className="enc-input-100" 
                                            name="gpsLongitude" 
                                            value={props.surveyMeasurement.gpsLongitude || ""} 
                                            onChange={changeSurveyMeasurement} 
                                        /> : <>
                                            {props.surveyMeasurement.gpsLongitude}
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>            
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="row">
                        <div className="col">
                            <div className="row survey-subhead">
                                <div className="col">
                                    Deployment Date
                                </div>
                            </div> <div className="row">
                                <div className="col">
                                    { props.editing ?
                                        <DatePicker
                                            className="enc-input-100"
                                            selected={props.surveyMeasurement.deploymentDate}
                                            date={props.surveyMeasurement.deploymentDate}
                                            showTimeSelect
                                            dateFormat="MMMM d, yyyy h:mm aa"
                                            onChange={(date) => changeDeploymentDate(date)}
                                        /> : <>
                                            {props.surveyMeasurement.deploymentDate > new Date("1/1/1") ? 
                                                props.surveyMeasurement.deploymentDate.toLocaleString() : 
                                                <div className="warning">
                                                    Deployment date not set.
                                                </div>
                                            }
                                        </>
                                    }
                                </div>
                            </div> <div className="row">
                                <div className="col">
                                    <input
                                        type="checkbox"
                                        name="preCalibrated"
                                        checked={props.surveyMeasurement.preCalibrated}
                                        onChange={toggleCheckbox}
                                        disabled={!props.editing}
                                    />
                                    {" Meter was calibrated before deploying?"}
                                </div>
                            </div> 
                        </div>
                        <div className="col">
                            <div className="row survey-subhead">
                                <div className="col">
                                    Retrieval Date
                                </div>
                            </div> <div className="row">
                                <div className="col">
                                    { props.editing ?
                                        <DatePicker
                                            className="enc-input-100"
                                            selected={props.surveyMeasurement.retrievalDate}
                                            date={props.surveyMeasurement.retrievalDate}
                                            showTimeSelect
                                            dateFormat="MMMM d, yyyy h:mm aa"
                                            onChange={(date) => changeRetrievalDate(date)}
                                        /> : <>
                                            {props.surveyMeasurement.retrievalDate > new Date("1/1/1") ? 
                                                props.surveyMeasurement.retrievalDate.toLocaleString() : 
                                                <div className="warning">
                                                    Retrieval date not set.
                                                </div>
                                            }
                                        </>
                                    }
                                </div>
                            </div> <div className="row">
                                <div className="col">
                                    <input
                                        type="checkbox"
                                        name="postCalibrated"
                                        checked={props.surveyMeasurement.postCalibrated}
                                        onChange={toggleCheckbox}
                                        disabled={!props.editing}
                                    />
                                    {" Meter was calibrated after retrieving?"}
                                </div>
                            </div> 
                        </div>
                        <div className="col">
                            <div className="row survey-subhead">
                                <div className="col">
                                    Notes
                                </div>
                            </div> <div className="row">
                                <div className="col">
                                    { props.editing ?
                                        <input 
                                            type="text" 
                                            className="enc-input-100" 
                                            name="notes" 
                                            value={props.surveyMeasurement.notes || ""} 
                                            onChange={changeSurveyMeasurement} 
                                        /> : <>
                                            {props.surveyMeasurement.notes}
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
            <div className="divider my-2" />
            {
                props.surveyMeasurement.unused ? <div className="row">
                    <div className="col-lg-2">
                    Reason for Not Using:
                    </div><div className="col">
                        { props.editing ?
                        <input 
                            type="text" 
                            className="enc-input-100" 
                            name="unusedReason" 
                            value={props.surveyMeasurement.unusedReason || ""} 
                            onChange={changeSurveyMeasurement} 
                        /> : <>
                            {props.surveyMeasurement.unusedReason}
                        </> }
                    </div>
                </div>: <SurveyMeasurementAttachmentList
                    surveyMeasurementId={props.surveyMeasurement.surveyMeasurementId}
                    editing={props.editing} 
                    photoOptions={props.photoOptions}
                />
            }
        </div>
    )
}


