import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { GetInventoryCategory, PostInventoryCategory } from '../../API/Inventory';

export default props => {
    const [category, setCategory] = useState({
        name: ""
    })
    useEffect(() => {
        if (props.match.params.id !== 'new') {
            GetInventoryCategory(props.match.params.id)
                .then(res => {
                    setCategory(res.data)
                })
        }
    }, [props.match.params.id])

    let history = useHistory();
    const save = () => {
        PostInventoryCategory(category)
            .then(res => {
                let newLoc = props.match.url.split('/').slice(0, -1).join('/');
                history.push(newLoc)
            })
    }

    const changeCategory = (key, value) => {
        let s = { ...category }
        s[key] = value
        setCategory(s)
    }

    return (
        <div className="container">
            <form action="#" onSubmit={(e) => { e.preventDefault(); save() }}>
                <div className="row">
                    <div className="col">
                        <h3>Inventory Category Editor</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <small>Category Name</small>
                        <input className="enc-input-100" value={category.name} onChange={e => changeCategory('name', e.target.value)} />
                    </div>
                </div>
                <div className="col text-right">
                    <Button className="enc-button" type='submit'>
                        Save
                    </Button>
                </div>
            </form>
        </div>
    )
}
