import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import "react-datepicker/dist/react-datepicker.css";
import { DropdownList } from 'react-widgets';
import { GetTimesheetActivities, GetTimesheetEntry, CreateTimesheetEntries, SaveTimesheetEntry, DeleteTimesheetEntry } from '../API/TimeSheetEntry';
import { GetJobListOptions } from '../API/Jobs';
import { GetAccountOptions, GetAccountLocationOptions } from '../API/Account';
import { GetEmployeeList } from '../API/Employee'
import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';

export default (props) => {
    const [timesheetEntry, setTimesheetEntry] = useState({
        timesheetEntryId: 0,
        timesheetId: 0,
        employeeIds: [],
        accountId: 0,
        accountName: "",
        accountLocationId: 0,
        locationName: "",
        jobId: 0,
        jobName: "",
        startTime: "",
        endTime: "",
        activityId: 0,
        activityName: "",
        billable: false,
        description: "",
        date:""
    });
    const [activities, setActivities] = useState([]);
    const [startTime, setStartTime] = useState(new Date());
    const [endTime, setEndTime] = useState(new Date());
    const [employeePool, setEmployeePool] = useState([]);
    const [account, setAccount] = useState([]);
    const [location, setLocation] = useState([]);
    const [job, setJob] = useState([]);
    const [accountOptions, setAccountOptions] = useState([]);
    const [locationOptions, setLocationOptions] = useState([]);
    const [jobOptions, setJobOptions] = useState([]);
    const [employeeSelect, setEmployeeSelect] = useState(false);
    const [accountSelected, setAccountSelected] = useState(false);
    const [message, setMessage] = useState(false);



    useEffect(() => {
        LoadAccountOptions();
        LoadActivities();
        props.timesheetEntryId !== "new" ? 
            LoadTimesheetEntry(props.timesheetEntryId) :
            LoadEmployeePool();
    },[props.timesheetEntryId, props.date])

    useEffect(() => {
        LoadJobOptions(account.value);
        LoadLocationOptions(account.value);
    },[account])

    const history = useHistory();

    const LoadTimesheetEntry = (timesheetEntryId) => {
        GetTimesheetEntry(parseInt(timesheetEntryId))
            .then(res => {
                if (res.success) {
                    setTimesheetEntry(res.data);
                    setStartTime(new Date(Date.parse(res.data.startTime)));
                    setEndTime(new Date(Date.parse(res.data.endTime)));
                    setAccount({value: res.data.accountId, label: res.data.accountName});
                    setAccountSelected(true);
                    setLocation({value: res.data.accountLocationId, label: res.data.locationName});
                    setJob({value: res.data.jobId, label: res.data.jobName})
                } 
            })
    }

    const LoadEmployeePool = () => {
        setEmployeeSelect(true);
        GetEmployeeList({})
            .then(res => {
                let options = [];
                res.data.forEach(employee => {
                    options.push({value: employee.employeeId, label: employee.lastName + ", " + employee.firstName})
                })
                setEmployeePool(options)
            })
    }

    const LoadAccountOptions = () => {
        GetAccountOptions()
            .then(res => {
                let options = [];
                res.data.forEach(acc => {
                    options.push({value: acc.accountId, label: acc.accountName, accountId: acc.accountId})
                })
                setAccountOptions(options)
            })
    }

    const LoadLocationOptions = (accountId) => {
        if (accountId !== null && accountId !== 0 && accountId !== undefined){
            GetAccountLocationOptions(accountId)
                .then(res => {
                    let options = [];
                    res.data.forEach(loc => {
                        options.push({value: loc.accountLocationId, label: loc.locationName})
                    })
                    setLocationOptions(options);
                })
        }
    }

    const LoadJobOptions = (accountId) => {
        if (accountId !== null && accountId !== 0 && accountId !== undefined){
            GetJobListOptions(accountId)
                .then(res => {
                    let options = [];
                    res.data.forEach(job => {
                        options.push({value: job.jobId, label: job.jobName})
                    })
                    setJobOptions(options);
                })
        }
    }

    const LoadActivities = () => {
        GetTimesheetActivities()
            .then(res => {
                setActivities(res.data);
            })
    }

    const SetStartTime = (date) => {
        setStartTime(date);

        if (date > endTime) {
            setEndTime(date);
        }
    }

    const SetEndTime = (date) => {
        setEndTime(date);

        if (date < startTime) {
            setStartTime(date);
        }
    }

    const SetEmployees = (selectedOptions) => {
        let newState = { ...timesheetEntry };
        let employeeIds = [];
        selectedOptions.forEach(option => {
            employeeIds.push(option.value);
        })
        newState.employeeIds = employeeIds;
        setTimesheetEntry(newState);
    }

    const SubmitTimesheet = () => {
        let payload = { ...timesheetEntry };
        payload.jobId = job.value;
        payload.accountLocationId = location.value;
        payload.startTime = startTime.toJSON();
        payload.endTime = endTime.toJSON();
        payload.date = props.date;

        if (timesheetEntry.timesheetEntryId === 0){
            CreateTimesheetEntries(payload)
            .then(res => {
                if (res.success) {
                    history.push(`/Timesheets/`);
                }
                else{
                    changeErrorMessage(res.message);
                }
            })
        }
        else{
            SaveTimesheetEntry(payload)
            .then(res => {
                if (res.success) {
                    history.push(`/Timesheets/`);
                }
                else{
                    changeErrorMessage(res.message);
                }
            })
        }
    }

    const DeleteCurrentTimesheetEntry = () => {
        DeleteTimesheetEntry(timesheetEntry.timesheetEntryId)
            .then(res => {
                if (res.success) {
                    history.push(`/Timesheets/`);
                }
            })
    }

    const changeTimesheetEntry = (e) => {
        let newState = { ...timesheetEntry };
        newState[e.target.name] = e.target.value;
        setTimesheetEntry(newState);
    }
    
    const changeActivity = (e) => {
        let newState = { ...timesheetEntry };
        newState.activityId = e.activityId;
        newState.activityName = e.activityName;
        setTimesheetEntry(newState);
    }

    const changeErrorMessage = (msg) => {
        setMessage(msg);
    }

    const changeAccount = (e) => {
        LoadLocationOptions(e.value);
        LoadJobOptions(e.value);
        setAccount(e);
        setLocation({});
        setJob({});
        setAccountSelected(true);
    }

    return (
        <div className="container pt-4">
            <form action="#" onSubmit={(e) => { e.preventDefault(); SubmitTimesheet() }}>
                <div className="row pt-2">
                    <div className="col">
                        { employeeSelect && 
                            <Select 
                                isMulti
                                name="employees"
                                options={employeePool}
                                onChange={(selectedOptions) => SetEmployees(selectedOptions)}
                            />
                        }
                    </div>
                </div>
                <div className="row pt-2">
                    <div className="col-lg-2">
                        Account: 
                    </div>
                    <div className="col">  
                        <DropdownList 
                            data={accountOptions} 
                            textField='label' 
                            value={account.label} 
                            valueField='value' 
                            onChange={(e) => changeAccount(e)} 
                        />
                    </div>
                    <div className="col-lg-2">
                        Activity: 
                    </div>
                    <div className="col">   
                        <DropdownList data={activities} textField='activityName' value={timesheetEntry.activityName} valueField='activityId' onChange={(e) => changeActivity(e)} />
                    </div>
                </div>
                { accountSelected &&
                    <div className="row pt-2">
                        <div className="col-lg-2">
                            Location: 
                        </div>
                        <div className="col">  
                            <DropdownList 
                                data={locationOptions} 
                                textField='label' 
                                value={location.label} 
                                valueField='value' 
                                onChange={(e) => setLocation(e)} 
                            />
                        </div>
                        <div className="col-lg-2">
                            Job: 
                        </div>
                        <div className="col">    
                            <DropdownList 
                                data={jobOptions} 
                                textField='label' 
                                value={job.label} 
                                valueField='value' 
                                onChange={(e) => setJob(e)} 
                            />
                        </div>
                    </div>
                }
                <div className="row pt-2">
                    <div className="col-lg-2">
                        Start Time: 
                    </div>
                    <div className="col">   
                        <DatePicker
                            className="enc-input"
                            selected={startTime}
                            date={startTime}
                            onChange={(date) => SetStartTime(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                        />
                    </div>
                    <div className="col-lg-2">
                        End Time:
                    </div>
                    <div className="col">   
                        <DatePicker
                            className="enc-input"
                            selected={endTime}
                            date={endTime}
                            onChange={(date) => SetEndTime(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                        />
                    </div>
                </div>
                <div className="row pt-2">
                    <div className="col-lg-2">
                        Total Hours: 
                    </div>
                    <div className="col">   
                        {((endTime - startTime) / 3600000).toFixed(1)}
                    </div>
                    <div className="col-lg-2">
                        Description:
                    </div>
                    <div className="col">
                        <input type="text" className="enc-input-100" name="description" value={timesheetEntry.description} onChange={changeTimesheetEntry} />
                    </div>
                </div>
                <div className="row pt-2">
                    <div className="col text-right">
                        {!employeeSelect && <Button variant="danger" onClick={DeleteCurrentTimesheetEntry}>Delete</Button>}
                        <Button variant="secondary" className="ml-2"  onClick={() => history.push(`/Timesheets`)}>Cancel</Button>
                        <Button type="submit" className="enc-button ml-2"> Save </Button>
                    </div>
                </div>
                <div className="row pt-2">
                    <div className="col">
                        {message}
                    </div>
                </div>
            </form>
        </div>
    )
}


