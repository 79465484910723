import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, Overlay, Tooltip } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { Combobox, DropdownList } from 'react-widgets';
import { GetAccountList } from '../API/Account';

import { SaveCall } from '../API/Call';
import { GetOfficeList } from '../API/Office';

export default props => {
    const [callInfo, setCallInfo] = useState({
        callTime: new Date(),
        officeLocationId: 0,
        accountId: 0,
        callerName: "",
        companyName: "",
        contactEmail: "",
        contactPhone: "",
        jobLocation: "",
        description: ""
    }) //Info from Stage 3
    const [accounts, setAccounts] = useState([])
    const [officeList, setOfficeList] = useState([]);
    const [show, setShow] = useState(false);

    const target = useRef(null);

    useEffect(() => {
        loadAccounts();
        LoadOffices();
    }, [])

    const LoadOffices = () => {
        GetOfficeList()
            .then(res => {
                setOfficeList(res.data)
            })
    }

    const loadAccounts = () => {
        GetAccountList()
            .then(res => {
                setAccounts(res.data)
            })
    }

    const comboboxChange = (e) => {
        if (typeof (e) === 'string') {
            changeAccountInfo(0, e)
        } else {
            changeAccountInfo(e.accountId, e.accountName)
        }

    }

    // Save Success Alert 
    const [alertShow, setAlertShow] = useState(false)

    const saveCall = () => {
        if (callInfo.officeLocationId === 0) {
            setShow(true);
            return;
        }
        let payload = { ...callInfo };
        payload.callTime = callInfo.callTime.toJSON();
        SaveCall(payload)
            .then(res => {
                if (res.success) {
                    setCallInfo({
                        callTime: new Date(),
                        officeLocationId: null,
                        callerName: "",
                        companyName: "",
                        contactEmail: "",
                        contactPhone: "",
                        jobLocation: "",
                        description: ""
                    })
                    setAlertShow(true)
                }
            })
    }


    const changeAccountInfo = (accountId, companyName) => {
        let newCallInfo = { ...callInfo };
        newCallInfo.companyName = companyName;
        newCallInfo.accountId = accountId;
        setCallInfo(newCallInfo);
    }

    const changeCallInfo = (key, val) => {
        let newCallInfo = { ...callInfo };
        newCallInfo[key] = val;
        setCallInfo(newCallInfo);
    }

    return (
        <div className="container mt-3 mb-2">
            <div className="row">
                <div className="col">
                    <h3>Call Intake Form</h3>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-md">
                    <Alert show={alertShow} className="enc-alert">
                        <Alert.Heading>Sucessfully Saved Call.</Alert.Heading>
                        <hr />
                        <div className="d-flex justify-content-end">
                            <Button onClick={() => setAlertShow(false)} className='enc-button'>
                                Dismiss
                                </Button>
                        </div>
                    </Alert>
                </div>
            </div>
            <form action="#" onSubmit={(e) => { e.preventDefault(); saveCall() }}>
                <div className="row mb-2">
                    <div className="col-6 date-picker-fix">
                        <small>Date</small>
                        <ReactDatePicker
                            className="enc-input"
                            selected={callInfo.callTime}
                            onChange={date => changeCallInfo("callTime", date)}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeCaption="Time"
                            dateFormat="MMMM d, yyyy h:mm aa" />
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col">
                        <small>Company Name</small>
                        <Combobox
                            placeholder="Company Name"
                            data={accounts}
                            textField='accountName'
                            valueField='accountName'
                            filter="contains"
                            value={callInfo.companyName}
                            onChange={(e) => comboboxChange(e)}
                            inputProps={{ required: true }} />
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col">
                        <small>Contact Name</small>
                        <input className="enc-input-100"
                            value={callInfo.callerName}
                            type="text"
                            placeholder="Contact Name"
                            onChange={(e) => changeCallInfo("callerName", e.target.value)}
                            required />
                    </div>
                    <div className="col">
                        <small>Contact Email</small>
                        <input className="enc-input-100"
                            value={callInfo.contactEmail}
                            type="email"
                            placeholder="Contact Email"
                            onChange={(e) => changeCallInfo("contactEmail", e.target.value)}
                            required />
                    </div>
                    <div className="col">
                        <small>Contact Phone Number</small>
                        <input className="enc-input-100"
                            value={callInfo.contactPhone}
                            type="tel"
                            placeholder="Contact Phone"
                            onChange={(e) => changeCallInfo("contactPhone", e.target.value)}
                            required />
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col" ref={target}>
                        <small>Internal Regional Office</small>
                        <DropdownList
                            onChange={m => { changeCallInfo("officeLocationId", m.officeLocationId); setShow(false) }}
                            data={officeList}
                            filter='contains'
                            textField="name"
                            valueField='officeLocationId'
                            value={callInfo.officeLocationId}
                            placeholder="Office Location - Internal"
                            inputProps={{ required: true }} />
                        <Overlay target={target.current} show={show} placement="bottom">
                            {props => (
                                <Tooltip id="overlay-example" {...props}>
                                    Please Select an office location.
                                </Tooltip>
                            )}
                        </Overlay>
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col">
                        <small>Job Location</small>
                        <input className="enc-input-100"
                            value={callInfo.jobLocation}
                            type="text"
                            placeholder="Job Location"
                            onChange={m => changeCallInfo("jobLocation", m.target.value)}
                            required />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <small>Description</small>
                        <textarea
                            className="enc-textarea"
                            onChange={m => changeCallInfo("description", m.target.value)}
                            value={callInfo.description}
                            placeholder="Call Description" />
                    </div>
                </div>
                <div className="row">
                    <div className="col text-right">
                        <Button
                            className="enc-button"
                            type='submit'>
                            Save
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    )
}
