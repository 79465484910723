import React, { useEffect, useState } from 'react';
import { ListGroup, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { GetJSAList, CheckEditJSA } from '../../API/JSA';

export default props => {
    const [jsaList, setJSAList] = useState([]);
    const [button, setButton] = useState(false);
    useEffect(() => {
        GetJSAList(props.jobId, props.stepId)
            .then(res => {
                setJSAList(res.data)
            })
        CheckEditJSA(0)
        .then(res =>{
            setButton(res.success)
        })
    }, [props.jobId, props.stepId])
    return (
        <div className="container">
            <div className="row my-2">
                {button &&
                <div className="col">
                    <Button className="enc-button" as={Link} to={`${props.match.url}/new`}>New JSA</Button>
                </div>
            }
            </div>
            <ListGroup>
                <ListGroup.Item className="click-list">JSA List</ListGroup.Item>
                {jsaList.length === 0 && <ListGroup.Item>No Forms Available</ListGroup.Item>}
                {jsaList.map(j => {
                    return (
                        <ListGroup.Item className="click-list" key={`jsa-${j.jsaId}`} as={Link} to={`${props.match.url}/${j.jsaId}`}>
                            <div className="row">
                                <div className="col">
                                    {j.date}
                                </div>
                                <div className="col">
                                    {j.createdBy}
                                </div>
                            </div>
                        </ListGroup.Item>
                    )
                })}
            </ListGroup>
        </div>
    )
}
