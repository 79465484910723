import React, { useEffect, useState } from 'react';
import { ToggleButtonGroup, ToggleButton, Button } from 'react-bootstrap';
import { DropdownList } from 'react-widgets';
import { TransferJobs, TransferYards } from '../../API/InventoryTransfer';

export default props => {
    const [toType, setToType] = useState(0)
    // From Type - 0 = Yard // 1 = Job


    const [selectionList, setSelectionList] = useState([])
    const [selection, setSelection] = useState(null)

    useEffect(() => {
        if(toType === 0){
            TransferYards()
            .then(res=>{
                setSelectionList(res.data)
            })
        }
        else if(toType === 1){
            TransferJobs()
            .then(res => {
                setSelectionList(res.data)
            })
        }

    }, [toType])

    const Save = () => {
        if(selection){
            props.save(toType, selection)
        }
    }

    return (
        <div className="container">
            <form action="#" onSubmit={(e) => { e.preventDefault(); Save() }}>
                    <div className="row">
                        <div className="col">
                            <h4>Step 1 - Select Select Destination</h4>
                        </div>
                    </div>
                    <div className="row my-2">
                        <div className="col-3">
                            Select Destination Type:
                        </div>
                        <div className="col">
                            <ToggleButtonGroup onChange={(e) => setToType(e)} value={toType} type="radio" name="options" defaultValue={toType}>
                                <ToggleButton className="enc-toggle-button" value={0}>Yard</ToggleButton>
                                <ToggleButton className="enc-toggle-button" value={1}>Job</ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <DropdownList
                                placeholder="Select Destination Location"
                                data={selectionList}
                                onChange={e => setSelection(e)}
                                value={selection}
                                textField="name"
                            />
                        </div>
                    </div>
                    <div className="row mt-2 mb-5">
                        <div className="col text-right">
                            <Button className="enc-button" type="submit">Confirm Selection</Button>
                        </div>
                    </div>
            </form>
        </div>
    )
}
