import React from 'react';

export default props => {
    return (
        <div className="container">
            <div className="row my-5">
                <div className="col text-center">
                    <h3>Please Select a stage to see relevant details.</h3>
                </div>
            </div>
        </div>
    )
}
