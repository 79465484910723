import React, { useState, useEffect } from 'react';
import { Nav } from 'react-bootstrap';
import { Switch, NavLink, Route } from 'react-router-dom';
import JobInventory from './JobInventory';
import YardInventory from './YardInventory';
import YardList from './YardList';
import JobInventoryList from './JobInventoryList';

import SerializedInventoryList from './SerializedInventoryList';
import SerializedItemDetails from './SerializedItemDetails';

import PickTicket from './PickTicket';
import TransferList from './TransferList';
import CheckInTicket from './CheckInTicket';
import EditTransfer from './InventoryTransfer/EditTransfer';
import TransferRequest from './InventoryTransfer/TransferRequest';
import PickSelector from './PickSelector';
import CheckInSelector from './CheckInSelector';
import CreateShopTicket from './ShopTicket/CreateShopTicket';
import ShopTicketList from './ShopTicket/ShopTicketList';
import ShopTicket from './ShopTicket/ShopTicket';

import BillOfLadingSelector from './BillOfLading/BillOfLadingSelector';
import BillOfLadingToggle from './BillOfLading/BillOfLadingToggle';
import { GetInventoryNavigation } from '../API/Navigation';
import ExcelUpload from './ExcelUpload';

export default props => {
    const [nav, setNav] = useState([])

    useEffect(()=>{
        GetInventoryNavigation()
        .then(res=>{
            setNav(res.navigationItems)
        })
    },[])

    return (
        <>
            <Nav variant="tabs" className="my-2 no-print">
                {nav.map((m, i)=>{return(
                    <Nav.Item key={`navInv-${i}`}>
                        <Nav.Link eventKey={i} as={NavLink} to={`${props.match.url}${m.url}`}>{m.label}</Nav.Link>
                    </Nav.Item>
                )})}
            </Nav>
            <div className="container">
                <Switch>
                    <Route path={`${props.match.url}/EditTransfer/:id`} component={EditTransfer} />
                    <Route path={`${props.match.url}/EditTransfer`} render={(renderProps)=>
                        <TransferList type="PickTickets" {...renderProps}/>
                    } />

                    <Route path={`${props.match.url}/Excel`} component={ExcelUpload} />

                    <Route path={`${props.match.url}/PickTicket/:id`} component={PickTicket} />
                    <Route path={`${props.match.url}/PickTicket`} component={PickSelector} />

                    <Route path={`${props.match.url}/CheckInTicket/:id`} component={CheckInTicket} />
                    <Route path={`${props.match.url}/CheckInTicket`} component={CheckInSelector} />

                    <Route path={`${props.match.url}/CreateShopTicket`} component={CreateShopTicket} />
                    <Route path={`${props.match.url}/ShopTicket/:id`} component={ShopTicket} />
                    <Route path={`${props.match.url}/ShopTicket`} component={ShopTicketList} />
                    
                    <Route path={`${props.match.url}/BillOfLading/:id`} component={BillOfLadingToggle} />
                    <Route path={`${props.match.url}/BillOfLading`} component={BillOfLadingSelector} />

                    <Route path={`${props.match.url}/CreatePickTicket`} component={TransferRequest} />
                    <Route path={`${props.match.url}/Asset/:Sid`} component={SerializedItemDetails} />
                    <Route path={`${props.match.url}/Asset`} component={SerializedInventoryList} />
                    <Route path={`${props.match.url}/Job/:jobId`} component={JobInventory} />
                    <Route path={`${props.match.url}/Job`} component={JobInventoryList} />
                    <Route path={`${props.match.url}/Yard/:yardId`} component={YardInventory} />
                    <Route path={`${props.match.url}/Yard`} component={YardList} />
                </Switch>
            </div>
        </>
    )
}



