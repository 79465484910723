import React, { useEffect, useState } from 'react';
import { SerializedItemProfile, RepairAsset } from '../../../API/AssetInventory';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

export default props => {
    const [profile, setProfile] = useState({
        serializedItemId: 0,
        serialNumber: "",
        name: "",
        decommissioned: false,
        statuses: [],
        manufacturer: "",
        mode: "",
        licenses: "",
        purchaseDate: "",
        purchasePrice: 0,
        assetType: 0,
        meterType: ""
    })

    const [repairNote, setRepairNote] = useState("")

    useEffect(() => {
        SerializedItemProfile(props.match.params.id)
            .then(res => {
                setProfile(res.data)
            })
    }, [props.match.params.id])

    let history = useHistory();
    const SendRepair = () => {
        let payload = {
            assetId: profile.serializedItemId,
            repairNote: repairNote
        }
        RepairAsset(payload)
            .then(res => {
                history.push(`/Admin/Inventory/Asset/${profile.serializedItemId}`)
            })
    }


    return (
        <div className="container my-5">
            <form action="#" onSubmit={(e) => { e.preventDefault(); SendRepair() }}>
                <div className="row">
                    <div className="col">
                        <h3>Send Asset for Repair</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <h4>{profile.manufacturer} - {profile.model} - {profile.serialNumber}</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        Repair Note:
                </div>
                </div>
                <div className="row">
                    <div className="col">
                        <textarea className="enc-textarea" value={repairNote} onChange={e => setRepairNote(e.target.value)} />
                    </div>
                </div>
                <div className="row">
                    <div className="col text-right">
                        <Button onClick={SendRepair} className="enc-button">Send Repair</Button>
                    </div>
                </div>
            </form>
        </div>
    )
}
