import React, { useEffect, useState, useRef } from 'react';
import { Button, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DropdownList } from 'react-widgets';
import { AvailableJobInventory, PostTransferRequest } from '../../API/InventoryTransfer';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

export default props => {
    useEffect(() => {
        AvailableJobInventory(props.fromId)
            .then(res => {
                setAvailableInventory(res.data)
            })
    }, [props.fromType, props.fromId])
    const damaged = props.toType === 0;
    const [availableInventory, setAvailableInventory] = useState({
        "jobReadyAvailable": [],
        "assetsAvailable": []
    })

    const [selectedJobReady, setSelectedJobReady] = useState(null)
    const [amount, setAmount] = useState(0)
    const [selectedAsset, setSelectedAsset] = useState(null)

    const [pickOrder, setPickOrder] = useState({
        assets: [],
        jobReadyItems: []
    })

    const ValidateAmount = (value) => {
        setAmount(value)
        if (value === "" || (new RegExp('^([0-9]{0,5})$').test(value) && selectedJobReady.available - parseInt(value) >= 0)) {
            ref.current.setCustomValidity("")
        } else {
            ref.current.setCustomValidity("Cannot add more than is available")
        }
    }

    const ValidateListAmount = (index, value, damaged = false) => {
        let s = { ...pickOrder }
        let available = pickOrder.jobReadyItems[index].available

        if (value === "" || (new RegExp('^([0-9]{0,5})$').test(value) && available - parseInt(s.jobReadyItems[index].damagedAmount) - parseInt(value) >= 0)) {
            s.jobReadyItems[index].amount = value
            s.jobReadyItems[index].valid = null
        }
        else if (new RegExp('^([0-9]{0,5})$').test(value)) {
            s.jobReadyItems[index].amount = parseInt(available - s.jobReadyItems[index].damagedAmount)
            s.jobReadyItems[index].valid = "Cannot add more than available."
        }
        else {
            s.jobReadyItems[index].amount = 0
            s.jobReadyItems[index].valid = "Cannot add negative values."
        }
        setPickOrder(s)
    }

    const ValidateDamagedAmount = (index, value) => {
        let s = { ...pickOrder }
        let available = pickOrder.jobReadyItems[index].available
        if (value === "" || (new RegExp('^([0-9]{0,5})$').test(value) && available - parseInt(s.jobReadyItems[index].amount) - parseInt(value) >= 0)) {
            s.jobReadyItems[index].damagedAmount = value
            s.jobReadyItems[index].valid = null
        }
        else if (new RegExp('^([0-9]{0,5})$').test(value)) {
            s.jobReadyItems[index].damagedAmount = parseInt(available - s.jobReadyItems[index].amount)
            s.jobReadyItems[index].valid = "Cannot add more than available."
        }
        else {
            s.jobReadyItems[index].damagedAmount = 0
            s.jobReadyItems[index].valid = "Cannot add negative values."
        }

        setPickOrder(s)
    }

    let ref = useRef();

    const RemoveAsset = (index) => {
        let s = { ...pickOrder }
        s.assets.splice(index, 1)
        setPickOrder(s)
    }

    const RemoveJobReadyItem = (index) => {
        let s = { ...pickOrder }
        s.jobReadyItems.splice(index, 1)
        setPickOrder(s)
    }

    const AddJobReadyItem = () => {
        if(selectedJobReady.available === 0){
            return;
        }
        let s = { ...pickOrder }
        let item = {
            ...selectedJobReady,
            damagedAmount: 0,
            amount: amount,
            valid: null
        }
        s.jobReadyItems.push(item)
        setPickOrder(s)
        setSelectedJobReady(null)
        setAmount(0)
    }

    const AddAsset = () => {
        let s = { ...pickOrder }
        let item = {
            ...selectedAsset,
            amount: 1
        }
        s.assets.push(item)
        setPickOrder(s)
        setSelectedAsset(null)
    }

    const submitPickOrder = () => {
        if (pickOrder.jobReadyItems.length === 0 && pickOrder.assets.length === 0) {
            return;
        }
        let payload = {
            FromType: props.fromType,
            FromLocationId: parseInt(props.fromId),
            ToType: props.toType,
            ToLocationId: props.to.id,
            ...pickOrder
        }
        payload.jobReadyItems.forEach(element => {
            if (element.amount === "") {
                element.amount = 0
            } else {
                element.amount = parseInt(element.amount)
            }
            if (element.damagedAmount === "") {
                element.damagedAmount = 0
            } else {
                element.damagedAmount = parseInt(element.damagedAmount)
            }
        });
        PostTransferRequest(payload)
            .then(res => {
                props.save()
            })
    }

    const returnAll = () => {
        let payload = {
            fromType: 1,
            fromLocationId: parseInt(props.fromId),
            toType: props.toType,
            toLocationId: props.to.id,
            assets: [],
            jobReadyItems: []
        }
        for (let i = 0; i < availableInventory.jobReadyAvailable.length; i++) {
            let inv = availableInventory.jobReadyAvailable[i]
            let item = {
                ...inv,
                amount: inv.available,
                valid: null
            }
            if(inv.available !== 0){
                payload.jobReadyItems.push(item)
            }
        }
        for (let i = 0; i < availableInventory.assetsAvailable.length; i++) {
            let inv = availableInventory.assetsAvailable[i];
            let item = {
                ...inv,
                amount: 1
            }
            payload.assets.push(item)
        }
        if(payload.assets.length === 0 && payload.jobReadyItems.length === 0){
            return;
        }
        PostTransferRequest(payload)
            .then(res => {
                props.save()
            })

    }







    return (
        <div className="container">
            <div className="row mt-1">
                <div className="col">
                    <div className="row">
                        <div className="col">
                            Available Assets
                            </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <DropdownList
                                textField={e => e ? `${e.name} - ${e.serialNumber}` : ""}
                                data={availableInventory.assetsAvailable.filter(item => !pickOrder.assets.find(({ serializedItemId }) => item.serializedItemId === serializedItemId))}
                                value={selectedAsset}
                                onChange={e => setSelectedAsset(e)}
                                filter="contains"
                            />
                        </div>
                    </div>
                    {selectedAsset &&
                        <>
                            <div className="row">
                                <div className="col">
                                    Serial Number:
                        </div>
                                <div className="col">
                                    {selectedAsset.serialNumber}
                                </div>
                            </div>
                            <Button className="enc-button" onClick={AddAsset}>Add Asset</Button>
                        </>}

                </div>
                <div className="col">
                    <form action="#" onSubmit={(e) => { e.preventDefault(); AddJobReadyItem() }}>
                        <div className="row">
                            <div className="col">
                                Available Job Ready Inventory
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <DropdownList
                                    textField='name'
                                    data={availableInventory.jobReadyAvailable.filter(item => !pickOrder.jobReadyItems.find(({ jobReadyItemId }) => item.jobReadyItemId === jobReadyItemId))}
                                    value={selectedJobReady}
                                    onChange={e => setSelectedJobReady(e)}
                                    filter="contains"
                                />
                            </div>
                        </div>
                        {selectedJobReady &&
                            <>
                                <div className="row">
                                    <div className="col">
                                        Available Amount:
                        </div>
                                    <div className="col">
                                        {selectedJobReady.available}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <input type='number' ref={ref} className="enc-input-100" onChange={e => ValidateAmount(e.target.value)} value={amount} />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col">
                                        <Button className="enc-button" type="submit">Add Job Ready Item</Button>
                                    </div>
                                </div>
                            </>
                        }
                    </form>
                </div>
            </div>

            <div className="row mt-2">
                <div className="col">
                    <ListGroup>
                        <ListGroup.Item>
                            <div className="row">
                                <div className="col">
                                    Job Ready Items
                                        </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    Item:
                            </div>
                                <div className="col">
                                    Available:
                            </div>
                                <div className="col">
                                    {damaged ? "Return " : "Order "}Amount:
                                </div>
                                {damaged &&
                                    <div className="col">
                                        Damaged Amount
                                    </div>
                                }
                                <div className="col-1">

                                </div>
                            </div>
                        </ListGroup.Item>
                        {pickOrder.jobReadyItems.map((m, i) => {
                            return (
                                <ListGroup.Item key={`JobReadyItem-${i}`}>
                                    <div className="row">
                                        <div className="col">
                                            {m.name}
                                        </div>
                                        <div className="col">
                                            {m.available}
                                        </div>
                                        <div className="col">
                                            <input type='number' className="enc-input-100" onChange={e => ValidateListAmount(i, e.target.value)} value={m.amount} />
                                            {m.valid && <span color="red">{m.valid}</span>}
                                        </div>
                                        {damaged &&
                                            <div className="col">
                                                <input type='number' className="enc-input-100" onChange={e => ValidateDamagedAmount(i, e.target.value)} value={m.damagedAmount} />
                                            </div>
                                        }
                                        <div className="col-1">
                                            <FontAwesomeIcon className="clickable" onClick={() => RemoveJobReadyItem(i)} icon={faTrash} />
                                        </div>
                                    </div>
                                </ListGroup.Item>
                            )
                        })}
                    </ListGroup>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col">
                    <ListGroup>
                        <ListGroup.Item>
                            <div className="row">
                                <div className="col">
                                    Assets
                                </div>
                            </div>
                        </ListGroup.Item>
                        {pickOrder.assets.map((m, i) => {
                            return (
                                <ListGroup.Item key={`Asset-${i}`}>
                                    <div className="row">
                                        <div className="col">
                                            {m.serialNumber} - {m.name}
                                        </div>
                                        <div className="col-1">
                                            <FontAwesomeIcon className="clickable" onClick={() => RemoveAsset(i)} icon={faTrash} />
                                        </div>
                                    </div>
                                </ListGroup.Item>
                            )
                        })}
                    </ListGroup>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-7">

                </div>
                <div className="col-2 text-right">
                    <Button className="enc-button" onClick={returnAll}>Return All Items</Button>
                </div>
                <div className="col-3 text-right">
                    <Button className="enc-button" onClick={submitPickOrder}>Submit Pick Order</Button>
                </div>
            </div>
        </div>
    )
}
