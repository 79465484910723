import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Alert, Button, ListGroup } from 'react-bootstrap';
import { DropdownList } from 'react-widgets';
import { GetBudgetTemplateItemsList, GetBudgetTemplate, PostBudgetTemplate } from '../API/BudgetTemplate';
import { GetSequenceAction, GetPotentialRisks, SaveRiskActions } from '../API/JSA';
import { Link } from 'react-router-dom';

export default props => {
    const [confirm, setConfirm] = useState(false);
    const [action, setAction] = useState("");
    const [riskActions, setRiskActions] = useState([]);
    const [risks, setRisks] = useState([]);
    const [risk, setRisk] = useState(null);

    let sequnceActionLink = "/Admin/SequenceActions";

    useEffect(() => {
        LoadAction(props.match.params.id);
        LoadRisks();
    }, [props.match.params.id])

    const LoadAction = (id) => {
        GetSequenceAction(id).then(res => {
            if (res.success === true) {
                setAction(res.action)
                setRiskActions(res.risks);
            }
        })
    }

    const LoadRisks = () => {
        GetPotentialRisks().then(res => {
            setRisks(res.data)
        })
    };

    const AddRisk = () => {
        if (risk !== null) {
            let ras = [...riskActions];
            let riskAction = {
                riskActionId: 0,
                potentialRiskId: risk.potentialRiskId,
                sequenceActionId: action.sequenceActionId,
                action: action.action,
                risk: risk.risk,
            }
            ras.push(riskAction);
            setRisk(null);
            setRiskActions(ras);
        }
    }

    const RemoveRisk = (index) => {
        let ras = [ ...riskActions ];
        ras.splice(index, 1);
        setRiskActions(ras);
    }

    const SaveAction = () => {
        let payload = {
            sequenceActionId: action.sequenceActionId,
            riskActions: riskActions
        }
        SaveRiskActions(payload).then(res => {
            if (res.success === true) {
                LoadAction(props.match.params.id);
                LoadRisks();
                setConfirm(true);
            }
        })
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-8">
                    <h3>{action.action}</h3>
                </div>

                <div className="col-4 text-right">
                    <Button className="enc-button" as={Link} to={`${sequnceActionLink}`}>View Actions</Button>
                </div>
            </div>

            <div className="divider my-2" />

            <div className="row">
                <div className="col-8">
                    <DropdownList
                        filter='contains'
                        data={risks.filter(r => !riskActions.find(({ potentialRiskId }) => r.potentialRiskId === potentialRiskId))}
                        textField="risk"
                        placeholder="Select a Potential Risk"
                        value={risk}
                        onChange={e => setRisk(e)}
                    />
                </div>

                <div className="col-4 text-right">
                    <Button className="enc-button" onClick={AddRisk}>Add Risk</Button>
                </div>
            </div>

            <div className="divider my-2" />

            <ListGroup>
                <ListGroup.Item className="enc-list">
                    Action Risks
                </ListGroup.Item>

                {riskActions.length === 0 && <ListGroup.Item>No Action Risks Added</ListGroup.Item>}

                {riskActions.map((m, i) => {
                    return (
                        <ListGroup.Item key={`risk-${i}`}>
                            <div className="row">
                                <div className="col-8">
                                    {m.risk}
                                </div>

                                <div className="col-4 text-right">
                                    <FontAwesomeIcon icon={faTrash} className='clickable' onClick={() => RemoveRisk(i)}/>
                                </div>
                            </div>
                        </ListGroup.Item>
                    )
                })}
            </ListGroup>
            
            <div className="row my-3">
                <div className="col-8">
                    <Alert variant="success" show={confirm} onClose={() => setConfirm(false)} dismissible>
                        Successfully Saved Risk
                    </Alert>
                </div>

                <div className="col text-right">
                    <Button onClick={SaveAction} className="enc-button">Save Risk</Button>
                </div>
            </div>
        </div>
    )
}
