import APIRequest, { APIGenericFileIteration2 } from './MainAPICall/api';
import config from '../APIConfig';

export const GetNewEquipmentForm = (id) => {
    return APIRequest({
        url: config.baseURL + `/JobForm/NewEquipmentForm?EquipmentForm=${id}`,
        method: "get",
    });
}

export const GetEquipmentFormNames = () => {
    return APIRequest({
        url: config.baseURL + "/JobForm/EquipmentFormNames",
        method: "get",
    });
}

export const PostEquipmentForm = (payload) => {
    return APIRequest({
        url: config.baseURL + "/JobForm/EquipmentForm",
        method: "post",
        body: payload
    })
}

export const GetEquipmentForm = (id) => {
    return APIRequest({
        url: config.baseURL + `/JobForm/EquipmentForm?id=${id}`,
        method: "get"
    })
}

export const GetFieldTicketCommentType = () => {
    return APIRequest({
        url: config.baseURL + "/JobForm/FieldTicketCommentTypes",
        method: "get"
    })
}

export const GetEquipmentFormListById = (ids) => {
    let strList = []
    ids.forEach(x => {
        strList.push(`EquipmentFormId=${x}`)
    })
    let strQuery = strList.join('&')
    return APIRequest({
        url: config.baseURL + `/JobForm/ListedEquipmentForms?${strQuery}`,
        method: "get",
    });
}

export const GetOrphanedForms = (date, jobId) => {
    return APIRequest({
        url: config.baseURL + `/JobForm/OrphanedForms?jobId=${jobId}&date=${date}`,
        method: "get"
    })
}

export const PostFieldTicket = (payload) => {
    return APIRequest({
        url: config.baseURL + `/JobForm/FieldTicket`,
        method: "post",
        body: payload
    })
}

export const GetEquipmentFormList = (jobId, stepId) => {
    return APIRequest({
        url: config.baseURL + `/JobForm/EquipmentFormList?jobId=${jobId}&stepId=${stepId}`,
        method: "get"
    })
}

export const GetEquipmentFormListAllByJobId = (jobId) => {
    return APIRequest({
        url: config.baseURL + `/JobForm/EquipmentFormList?jobId=${jobId}`,
        method: "get"
    })
}


export const GetFieldTicketFormList = (jobId, stepId) => {
    return APIRequest({
        url: config.baseURL + `/JobForm/FieldTicketList?jobId=${jobId}&stepId=${stepId}`,
        method: "get"
    })
}

export const GetAllFieldTicketsByJobId =(jobId) =>{

    return APIRequest({
        url: config.baseURL + `/JobForm/FieldTicketListAllByJobId?jobId=${jobId}`,
        method:"get"
    })
}

export const GetEditFieldTicket = (id) => {
    return APIRequest({
        url: config.baseURL + `/JobForm/EditFieldTicket?id=${id}`,
        method: "get"
    })
}

export const GetFieldTicket = (id) => {
    return APIRequest({
        url: config.baseURL + `/JobForm/FieldTicket?id=${id}`,
        method: "get"
    })
}

export const CheckEditFieldTicket = (id) => {
    return APIRequest({
        url: config.baseURL + `/JobForm/CheckEditFieldTicket?id=${id}`,
        method: "get"
    })
}

export const CheckEditEquipmentForm = (id) => {
    return APIRequest({
        url: config.baseURL + `/JobForm/CheckEditEquipmentForm?id=${id}`,
        method: "get"
    })
}

export const GetJobFormNav = () => {
    return APIRequest({
        url: config.baseURL + `/JobForm/JobFormsNav`,
        method: "get"
    })
}

export const GetJobFormsAdminNav = () => {
    return APIRequest({
        url: config.baseURL + `/JobForm/JobFormsAdminNav`,
        method: "get"
    })
}

export const GetAdminJobList = () => {
    return APIRequest({
        url: config.baseURL + `/JobForm/AdminJobList`,
        method: "get"
    })
}

export const GetEquipmentFormTemplate = (id) => {
    return APIRequest({
        url: config.baseURL + `/JobForm/EquipmentFormTemplate?EquipmentNameId=${id}`,
        method: "get"
    })
}

export const GetEquipmentFormCategories = () => {
    return APIRequest({
        url: config.baseURL + `/JobForm/EquipmentFormCategories`,
        method: "get"
    })
}

export const GetEquipmentFormPhotoTypes = () => {
    return APIRequest({
        url: config.baseURL + `/JobForm/EquipmentFormPhotoTypes`,
        method: "get"
    })
}

export const GetEquipmentFormCheckboxes = () => {
    return APIRequest({
        url: config.baseURL + `/JobForm/EquipmentFormCheckboxes`,
        method: "get"
    })
}

export const GetEquipmentPicture = (id, filename) => {
    let download = require('downloadjs')

    let token;
            
    const LS = localStorage.getItem("BAENCToken");
    const SS = sessionStorage.getItem("BAENCToken");

    if(LS){
        token = LS;
    } else if (SS) {
        token = SS;
    }

    fetch(`${config.baseURL}/JobForm/GetEquipmentPicture?FileId=${id}`, {
        method: "get",
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then(res=>res.blob())
    .then(blob => {
        download(blob, filename)})
}

export const RemoveEquipmentPicture = (id) =>{

    
        return APIRequest({
            url: `${config.baseURL}/JobForm/DeleteEquipmentPicture?`,
            method: "Post",
            body: {id:id}
        })
    
}

export const PostEquipmentFormTemplate = (payload) => {
    return APIRequest({
        url: config.baseURL + `/JobForm/EquipmentFormTemplate`,
        method: "post",
        body: payload
    })
}

export const PostEquipmentFormCategory = (payload) => {
    return APIRequest({
        url: config.baseURL + `/JobForm/EquipmentFormCategory`,
        method: "post",
        body: payload
    })
}

export const PostEquipmentFormPhotoType = (payload) => {
    return APIRequest({
        url: config.baseURL + `/JobForm/EquipmentFormPhotoType`,
        method: "post",
        body: payload
    })
}

export const PostEquipmentFormCheckbox = (payload) => {
    return APIRequest({
        url: config.baseURL + `/JobForm/EquipmentFormCheckbox`,
        method: "post",
        body: payload
    })
}

export const PostEquipmentFormName = (payload) => {
    return APIRequest({
        url: config.baseURL + `/JobForm/EquipmentFormName`,
        method: "post",
        body: payload
    })
}

export const DeleteEquipmentFormName = (id) => {
    return APIRequest({
        url: config.baseURL + `/JobForm/DeleteEquipmentFormName?EquipmentFormNameId=${id}`,
        method: "get"
    })
}

export const GetHourFuelPhoto = (id) => {
    return APIGenericFileIteration2({
        url: config.baseURL + `/JobForm/HourFuelPicture?PhotoId=${id}`,
        method: "get"
    })
}