import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";
import { GetAnnouncement, SaveAnnouncement, DeleteAnnouncement } from '../API/Announcement';
import { useHistory } from 'react-router-dom';

export default (props) => {
    const [announcement, setAnnouncement] = useState({
        announcementId: 0,
        title: "",
        details: "",
        startDate: "",
        endDate: "",
        enabled: true
    });
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    useEffect(() => {
        LoadAnnouncement(props.id)
    }, [props.id])

    const history = useHistory();

    const LoadAnnouncement = (id) => {
        if (id !== "new") {
            let payload = { announcementId: parseInt(id) }
            GetAnnouncement(payload)
                .then(res => {
                    if (res.success) {
                        setAnnouncement(res.data)
                        setStartDate(new Date(res.data.startDate));
                        setEndDate(new Date(res.data.endDate))
                    }
                })
        }
    }

    const changeAnnounce = (e) => {
        let newState = { ...announcement };
        newState[e.target.name] = e.target.value;
        setAnnouncement(newState)
    }

    const SetEndDate = (date) => {
        setEndDate(date)
    }

    const SetStartDate = (date) => {
        setStartDate(date)
    }

    const SubmitAnnouncement = () => {
        let payload = { ...announcement };
        payload.startDate = startDate.toJSON();
        payload.endDate = endDate.toJSON();
        SaveAnnouncement(payload)
            .then(res => {
                if (res.success) {
                    history.push('/Admin/AnnouncementList');
                }
            })

    }

    const DeleteCurrentAnnouncement = () => {
        DeleteAnnouncement({ ...announcement })
            .then(res => {
                if (res.success) {
                    history.push('/Admin/AnnouncementList');
                }
            })
    }

    const toggleEnabled = () => {
        let newAnnounce = { ...announcement };
        newAnnounce.enabled = !announcement.enabled
        setAnnouncement(newAnnounce)
    }

    return (
        <div className="container border py-3">
            <form action="#" onSubmit={(e) => { e.preventDefault(); SubmitAnnouncement() }}>
                <div className="row">
                    <div className="col">
                        Start Date:
            <DatePicker
                            className="enc-input"
                            selected={startDate}
                            onChange={(date) => SetStartDate(date)}
                            selectsEnd
                            minDate={new Date()}
                            startDate={startDate}
                            endDate={endDate}
                        />
                    </div>
                    <div className="col">
                        End Date:
            <DatePicker
                            className="enc-input"
                            selected={endDate}
                            onChange={(date) => SetEndDate(date)}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                        />
                    </div>
                    <div className="col">
                        <div className="row">
                            <div className="col text-right pr-2">
                                <div className="toggle-switch small-switch">
                                    <input
                                        type="checkbox"
                                        className="toggle-switch-checkbox"
                                        id="activetoggle"
                                        checked={announcement.enabled}
                                        onChange={toggleEnabled}
                                    />
                                    <label className="toggle-switch-label" htmlFor="activetoggle">
                                        <span className={"toggle-switch-inner"} />
                                        <span
                                            className={"toggle-switch-switch"}
                                        />
                                    </label>
                                </div>
                            </div>
                            <div className="col text-left">
                                Enabled
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col pt-2">
                        <input type="text" className="enc-input-100" name="title" value={announcement.title} placeholder="Announcement Title" onChange={changeAnnounce} required />
                    </div>
                </div>
                <div className="row pt-2">
                    <div className="col">
                        <textarea rows="5" className="enc-textarea" name="details" cols="50" value={announcement.details} placeholder="Announcement Text" onChange={changeAnnounce} required />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <input type="submit" value="Save" className="enc-button" />
                    </div>
                    {announcement.announcementId !== 0 &&
                    <div className="col text-right">
                        <input type="button" value="Delete" className="enc-button" onClick={DeleteCurrentAnnouncement}/>
                    </div>}
                </div>
            </form>
        </div>
    )
}


