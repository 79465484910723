import React, { useState, useEffect } from 'react';
import { GetInventoryAudit } from '../../API/Inventory';
import { Button, ListGroup } from 'react-bootstrap';

export default props => {
    const [audit, setAudit] = useState([]);
    const [disable, setDisable] = useState(false);

    useEffect(() => {
        loadAuditRecords(0);
    }, []);

    const loadAuditRecords = (amount) => {
        GetInventoryAudit(amount)
            .then(res => {
                setAudit(res.data);
            });
    }

    const loadAllRecords = () => {
        setDisable(true);
        loadAuditRecords(1);
    }

    return (
        <div className="container my-5">
            <div className="row mb-2">
                <div className="col text-right">
                    <Button className="enc-button" disabled={disable} onClick={loadAllRecords}>Load All Records</Button>
                </div>
            </div>
            <ListGroup>
                <ListGroup.Item className="enc-data-list">
                    Inventory Audit
                </ListGroup.Item>
                <ListGroup.Item className="enc-data-list">
                    <div className="row">
                        <div className="col">
                            User
                            </div>
                        <div className="col">
                            Date
                            </div>
                        <div className="col">
                            Location of Adjustment
                            </div>
                    </div>
                    <div className="divider my-1" />
                    <div className="row">
                        <div className="col">
                            Inventory Item
                            </div>
                        <div className="col">
                            Amount
                            </div>
                    </div>
                </ListGroup.Item>
                {audit.map((a, i) => {
                    return (
                        <ListGroup.Item key={`audit-${i}`} className="enc-data-list">
                            <div className="row">
                                <div className="col">
                                    {a.fullName}
                                </div>
                                <div className="col">
                                    {a.date}
                                </div>
                                <div className="col">
                                    {a.location}
                                </div>
                            </div>
                            <div className="divider my-1" />

                            <div className="row">
                                <div className="col">
                                    {a.itemName}
                                </div>
                                <div className="col">
                                    {a.amount}
                                </div>
                            </div>
                        </ListGroup.Item>
                    )
                })}
            </ListGroup>

        </div>
    )
}
