import React, { useEffect, useState } from 'react';
import { Alert, Button, ListGroup, Spinner, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { DropdownList } from 'react-widgets';
import { GetBudgetTemplateItemsList, PostBulkTemplate } from '../API/BudgetTemplate';
import { GetScopeBuilderNoOther } from '../API/JobScope';
import { GetPotentialRisks, GetPotentialRisk, AddPotentialRisk, EditPotentialRisk, RemovePotentialRisk } from '../API/JSA';
import { ProposalListNames } from '../API/Proposal';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ConfirmDelete from '../Components/ConfirmDelete';

export default props => {
    const [selected, setSelected] = useState([]);
    const [filter, setFilter] = useState("");

    const [confirmDeleteItem, setConfirmDeleteItem] = useState(false);
    const [riskId, setRiskId] = useState(0);

    const [risks, setRisks] = useState([]);

    const [risk, setRisk] = useState("");

    const [openEditModal, setOpenEditModal] = useState(false);
    const [riskItem, setRiskItem] = useState("");
    const [riskItemId, setRiskItemId] = useState(0);

    let productsLink = "/Admin/ProductRisks";

    useEffect(() => {
        LoadRisks();
    }, [])

    const LoadRisks = () => {
        GetPotentialRisks().then(res => {
            setRisks(res.data)
        })
    };

    const AddRisk = () => {
        if (risk !== null && risk !== "" && risk !== " ") {
            let payload = {
                potentialRiskId: 0,
                risk: risk
            };
            AddPotentialRisk(payload).then(res => {
                if (res.success === true) {
                    setRisk("");
                    LoadRisks();
                }
            }).catch(err => {
                //
            });
        }
    }

    const openEditRiskModal = (id) => {
        GetPotentialRisk(id).then((res) => {
            if (res.success === true) {
                setRiskItem(res.data.risk);
                setRiskItemId(res.data.potentialRiskId);
                setOpenEditModal(true);
            }
        }).catch((err) => {
            //
        });
    }

    const editRiskModal = () => {
        return (
            <Modal show={openEditModal} onHide={() => setOpenEditModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Risk</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <input
                        className="enc-input-100"
                        value={riskItem}
                        onChange={(e) => setRiskItem(e.target.value)}
                        required
                    />
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setOpenEditModal(false)}>Close</Button>
                    <Button variant="danger" onClick={EditRisk}>Confirm</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const EditRisk = () => {
        EditPotentialRisk({ potentialRiskId: Number(riskItemId), risk: riskItem }).then((res) => {
            if (res.success === true) {
                setOpenEditModal(false);
                LoadRisks();
            }
        }).catch((err) => {
            //
        });
    }

    const confirmRemoveItem = (id) => {
        setRiskId(id);
        setConfirmDeleteItem(true);
    }

    const RemoveRisk = () => {
        RemovePotentialRisk(riskId).then(res => {
            setConfirmDeleteItem(false);
            setRiskId(0);
            LoadRisks();
        }).catch(err => {
            setConfirmDeleteItem(false);
        });
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h3>Risks</h3>
                </div>
            </div>

            <div className="divider my-2" />

            <div className="row my-2">
                <div className="col-4">
                    <Button className="enc-button" as={Link} to={`${productsLink}`}>View Products</Button>
                </div>
                <div className="col-4"></div>
                <div className="col-4 text-right">
                    <input placeholder="Filter" className="enc-input-100" value={filter} onChange={e => setFilter(e.target.value)} />
                </div>
            </div>

            <div className="divider my-2" />

            <div className="row my-2">
                <div className="col-10">
                    <input
                        className="enc-input-100"
                        value={risk}
                        onChange={(e) => setRisk(e.target.value)}
                        required
                    />
                </div>
                <div className="col-2 text-right">
                    <Button className="enc-button" onClick={AddRisk}>Add Risk</Button>
                </div>
            </div>

            <div className="divider my-2" />

            <ListGroup className="mb-3">
                <ListGroup.Item className="click-list">
                    Potential Risks
                </ListGroup.Item>

                {risks.length === 0 && <ListGroup.Item>No Risks Added</ListGroup.Item>}

                {risks.filter(x => x.risk.toLowerCase().search(filter.toLowerCase()) !== -1).map((m, i) => {
                    return (
                        <ListGroup.Item key={`risk-${i}`}>
                            <div className="row">
                                <div className="col-10">
                                    {m.risk}
                                </div>

                                <div className="col-1 text-right">
                                    {editRiskModal()}

                                    <Button className="enc-button" onClick={() => openEditRiskModal(m.potentialRiskId)}>Edit</Button>
                                </div>

                                <div className="col-1 text-right">
                                    <ConfirmDelete
                                        show={confirmDeleteItem}
                                        setShow={setConfirmDeleteItem}
                                        action={RemoveRisk}
                                        message="Confirm you would like to delete risk as it will delete risk from all actions."
                                    />

                                    <FontAwesomeIcon icon={faTrash} className='clickable' onClick={() => confirmRemoveItem(m.potentialRiskId)} />
                                </div>
                            </div>
                        </ListGroup.Item>
                    )
                })}
            </ListGroup>
        </div>
    )
}
