import React from 'react';
import NewAccount from './NewAccount';
import { Route, Switch } from 'react-router-dom';
import AccountList from './AccountList';
import AccountProfile from './AccountProfile';
import LocationProfile from './LocationProfile';
import EditLocation from './EditLocation';
import EditContact from './EditContact';
import ContactProfile from './ContactProfile';
import EditAccount from './EditAccount';

export default props => {
    return (
        <div className="mt-3">
                <Switch>
                    <Route exact path={`${props.match.url}`} component={AccountList} />
                    <Route exact path={`${props.match.url}/:id/Location/New`} render={(routeprops) => 
                    <EditLocation url={props.match.url} accountId={routeprops.match.params.id} />
                    } />
                    <Route exact path={`${props.match.url}/:id/Location/:loc/Edit`} render={(routeprops) => 
                    <EditLocation url={props.match.url} accountId={routeprops.match.params.id} locid={routeprops.match.params.loc}/>
                    } />
                    <Route exact path={`${props.match.url}/:id/Location/:loc`} render={(routeprops) =>
                        <LocationProfile url={props.match.url} accountId={routeprops.match.params.id} locid={routeprops.match.params.loc} />
                    } />

                    <Route exact path={`${props.match.url}/:id/Location`} render={(routeprops) =>
                        <AccountProfile start={"locations"} url={props.match.url} accountId={routeprops.match.params.id} />
                    } />

                    <Route exact path={`${props.match.url}/:id/Contact`} render={(routeprops) =>
                        <AccountProfile start={"contacts"} url={props.match.url} accountId={routeprops.match.params.id} />
                    } />
                    <Route exact path={`${props.match.url}/:id/Contact/New`} render={(routeprops) =>
                        <EditContact url={props.match.url} accountId={routeprops.match.params.id} />
                    } />
                    <Route exact path={`${props.match.url}/:id/Contact/:cid/Edit`} render={(routeprops) =>
                        <EditContact url={props.match.url} accountId={routeprops.match.params.id} contactId={routeprops.match.params.cid} />
                    } />
                    <Route exact path={`${props.match.url}/:id/Contact/:cid`} render={(routeprops) =>
                        <ContactProfile start={"contacts"} url={props.match.url} accountId={routeprops.match.params.id} contactId={routeprops.match.params.cid} />
                    } />
                    <Route exact path={`${props.match.url}/New`} component={NewAccount} />

                    <Route exact path={`${props.match.url}/:id/Edit`} render={(routeprops) =>

                    <EditAccount url={props.match.url} accountId={routeprops.match.params.id} />
                    } />

                    <Route exact path={`${props.match.url}/:id`} render={(routeprops) =>
                        <AccountProfile start={"locations"} url={props.match.url} accountId={routeprops.match.params.id} />
                    } />
                </Switch>
            
        </div>
    )
}
