import APIRequest, {APIPDFRequest} from './MainAPICall/api';
import config from '../APIConfig';

export const GetJSAPdf = (id, timezoneOffset) => {
    return APIPDFRequest({
        url: config.baseURL + `/Pdf/GetJSAPDF?JSAId=${id}&timezoneOffset=${timezoneOffset}`,
        method: "get",
        responseType: "blob"
    });
}

export const GetFieldTicketPdf = (id) => {
    return APIPDFRequest({
        url: config.baseURL + `/Pdf/GetFieldTicketPDF?fieldTicketId=${id}`,
        method: "get",
        responseType: "blob"
    });
}

export const GetEquipmentFormPdf =(id) =>{
    return APIPDFRequest({
        url: config.baseURL + `/Pdf/GetEquipmentFormPDF?equipmentFormId=${id}`,
        method: "get",
        responseType: "blob"
    });
}

export const GetBol = (billofLadingId) =>{
    return APIPDFRequest({
        url: config.baseURL + `/Pdf/GetBolPDF?transferRequestId=${billofLadingId}`, 
        method: "get",
        responseType: "blob"
    })
}

export const EmailJSAPDF = (JSAId, toAddress, toName, message, fromName)=>{
    let response = null;
    APIRequest({
        url: config.baseURL + `/Pdf/EmailJSAPdf?JSAId=${JSAId}&toAddress=${toAddress}&toName=${toName}&message=${message}`,
        method: "get",
    }).then(resp=> {return response = resp})
    return response
}

export const  EmailFieldTicketPDF =(FieldTicketId, toAddress, toName, message, fromName) =>{
    APIRequest({
        url: config.baseURL + `/Pdf/EmailFieldTicketPdf?fieldTicketId=${FieldTicketId}&toAddress=${toAddress}&toName=${toName}&message=${message}&fromName=${fromName}`,
        method: "get"
    }).then(resp => {return resp})
}

export const  EmailEquipmentFormPDF = (EquipmentFormId, toAddress, toName, message, fromName)=>{
    
    APIRequest({
        url: config.baseURL + `/Pdf/EmailEquipmentFormPdf?EquipmentFormId=${EquipmentFormId}&toAddress=${toAddress}&toName=${toName}&message=${message}&fromName=${fromName}`,
        method:"get"
    })
    
}

export const EmailBolPDF = (TransferRequestId, toAddress, toName, message, fromName) =>{
  return  APIRequest({
        url: config.baseURL + `/Pdf/EmailBolPDF?transferRequestId=${TransferRequestId}&toAddress=${toAddress}&toName=${toName}&message=${message}&fromName=${fromName}`,
        method: "get"
    })
}