import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { ListGroup, Button } from 'react-bootstrap';
import { GetFailureFormList } from '../../../API/AssetInventory';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

export default props => {
    const dateOffset = (24 * 60 * 60 * 1000) * 90
    const [startDate, setStartDate] = useState(new Date(new Date().getTime() - dateOffset))
    const [endDate, setEndDate] = useState(new Date())
    const [failureLog, setFailureLog] = useState([])

    const LoadLog = () => {
        let start = startDate.toJSON();
        let end = endDate.toJSON();
        GetFailureFormList(start, end)
            .then(res => {
                setFailureLog(res.data)
            })
    }

    useEffect(() => {
        // this intentionally is not set to have dependancies. 
        LoadLog()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h3>Failure Log</h3>
                </div>
            </div>
            <div className="row my-2">
                <div className="col">
                    Start Date:
                </div>
                <div className="col">
                    <ReactDatePicker
                        required
                        className="enc-input-100"
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                    />
                </div>
                <div className="col">
                    End Date:
                </div>
                <div className="col">
                    <ReactDatePicker
                        required
                        className="enc-input-100"
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col text-right">
                    <Button className="enc-button" onClick={LoadLog}>Load Log</Button>
                </div>
            </div>
            <ListGroup className="my-3">
                <ListGroup.Item className="enc-data-list">
                Failure Log
                </ListGroup.Item>
                {failureLog.map((m, i) =>
                    <ListGroup.Item key={`log-${i}`} as={Link} to={`${props.match.url}/${m.assetFailureId}`} className="click-list mt-1">
                        <div className="row">
                            <div className="col-6">
                                <strong>Failure Date:</strong> {m.date}
                                <br />
                                <strong>Serial Number:</strong>{m.serialNumber}
                                <br />
                                <strong>Office: </strong>{m.office}
                                <br />
                                <strong>Job: </strong>{m.job}
                                <br />
                                <strong>Circulation?:</strong>{m.inCirculation && <FontAwesomeIcon icon={faCheck} />}
                            </div>

                            <div className="col-6">
                                <strong>Description:</strong>
                                <br />
                                {m.description}
                                <br />
                                <strong>Response:</strong>
                                <br />{m.response}
                            </div>
                        </div>
                    </ListGroup.Item>
                )}
            </ListGroup>

        </div>
    )
}
