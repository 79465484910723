import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Alert, Button, ListGroup } from 'react-bootstrap';
import { DropdownList } from 'react-widgets';
import { GetBudgetTemplateItemsList, GetBudgetTemplate, PostBudgetTemplate } from '../API/BudgetTemplate';

export default props => {
    const [template, setTemplate] = useState({
        scopeLeafId: 0,
        fullName: "",
        items: []
    })
    const [availableBudgets, setAvailableBudgets] = useState([])
    const [selectedBudget, setSelectedBudget] = useState(null)
    const [confirm, setConfirm] = useState(false);


    useEffect(() => {
        GetBudgetTemplate(props.match.params.id)
            .then(res => {
                setTemplate(res.data)
            })
        GetBudgetTemplateItemsList()
            .then(res => {
                setAvailableBudgets(res.data)
            })
    }, [props.match.params.id])

    const AddItem = () => {
        let s = { ...template }
        s.items.push(selectedBudget)
        setSelectedBudget(null)
        setTemplate(s)
    }

    const RemoveItem = (index) => {
        let s = {...template}
        s.items.splice(index,1);
        setTemplate(s)
    }

    const SaveTemplate = () => {
        PostBudgetTemplate(template)
        .then(res => {
            GetBudgetTemplate(props.match.params.id)
            .then(res => {
                setTemplate(res.data)
            })
            setConfirm(true);
        })
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h3>{template.fullName}</h3>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <DropdownList
                        filter='contains'
                        data={availableBudgets.filter(budg => !template.items.find(({ budgetItemId }) => budg.budgetItemId === budgetItemId))}
                        textField="budgetItemName"
                        placeholder="Select a Budget Line"
                        value={selectedBudget}
                        onChange={e => setSelectedBudget(e)}
                    />
                </div>
            </div>
            <div className="row my-3">
                <div className="col text-right">
                    <Button className="enc-button" onClick={AddItem}>Add Budget Item</Button>
                </div>
            </div>

            <div className="divider my-2" />

            <ListGroup>
                <ListGroup.Item className="enc-list">
                    Budget Template
                </ListGroup.Item>
                {template.items.length === 0 && <ListGroup.Item>No Budget Lines Added</ListGroup.Item>}
                {template.items.map((m, i) => {
                    return (
                        <ListGroup.Item key={`template-item-${i}`}>
                            
                            <div className="row">
                                <div className="col-8">
                                    {m.budgetItemName}
                                </div>
                                <div className="col-4 text-right">
                                    <FontAwesomeIcon icon={faTrash} className='clickable' onClick={() => RemoveItem(i)}/>
                                </div>
                            </div>
                        </ListGroup.Item>
                    )
                })}
            </ListGroup>

            <div className="row my-3">
                <div className="col-8">
                    <Alert variant="success" show={confirm} onClose={() => setConfirm(false)} dismissible>
                        Successfully Saved Template
                    </Alert>
                </div>
                <div className="col text-right">
                    <Button onClick={SaveTemplate} className="enc-button">Save Template</Button>
                </div>
            </div>
        </div>
    )
}
