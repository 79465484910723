import React, { useState, useEffect } from 'react';
import ReactDatePicker from 'react-datepicker';
import { ListGroup, Button, Accordion, Card } from 'react-bootstrap';
import { GetReminderList, PostReminder, DeleteReminder, GetRemindersUsers } from '../API/Reminder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Multiselect } from 'react-widgets';


export default props => {

    const [reminder, setReminder] = useState({
        reminderDate: new Date(),
        reminderText: "",
        remindersUser: []
    })

    const [reminderList, setReminderList] = useState([])

    const [allowUserAdd, setAllowUserAdd] = useState(false)
    const [users, setUsers] = useState([])

    useEffect(() => {
        GetRemindersUsers()
            .then(res => {
                setUsers(res.users)
                setAllowUserAdd(res.allowUserAdd)
            })
    }, [])

    useEffect(() => {
        LoadReminders(props.jobId)
    }, [props.jobId])

    const LoadReminders = (id) => {
        GetReminderList(id)
            .then(res => {
                setReminderList(res.data)
            })
    }

    const RemoveReminder = (id) => {
        DeleteReminder(id)
            .then(res => {
                if (res.success) {
                    LoadReminders(props.jobId)
                }
            })
    }

    const SaveReminder = () => {
        let payload = { ...reminder }
        payload.jobId = parseInt(props.jobId)
        payload.reminderDate = reminder.reminderDate.toJSON();
        PostReminder(payload)
            .then(res =>
                LoadReminders(props.jobId)
            )
    }

    const ChangeReminder = (key, value) => {
        let s = { ...reminder }
        s[key] = value
        setReminder(s)
    }

    const displayDate = (dateTime) => {
        let d = new Date(`${dateTime} UTC`),
            minutes = d.getMinutes().toString().length === 1 ? '0' + d.getMinutes() : d.getMinutes(),
            hours = d.getHours().toString().length === 1 ? '0' + d.getHours() : d.getHours(),
            months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
        return `${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()} - ${hours}:${minutes}`
    }


    return (
        <>
            <Accordion>
                <Card style={{ overflow: "visible" }}>
                    <Accordion.Toggle className="clickable" as={Card.Header} eventKey="0">
                        <h4>Job Reminders</h4>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <div className="row">
                                <div className="col">
                                    <ListGroup>
                                        {reminderList.map((item, ri) => {

                                            return (
                                                <ListGroup.Item key={`reminder-${ri}`}>
                                                    <div className="row">
                                                        <div className="col">
                                                            {displayDate(item.reminderDate)}
                                                        </div>
                                                        <div className="col">
                                                            {item.reminderText}
                                                        </div>
                                                        <div className="col-1">
                                                            <FontAwesomeIcon icon={faTrash} className="clickable" onClick={() => RemoveReminder(item.jobReminderId)} />
                                                        </div>
                                                    </div>
                                                    <div className="divider my-2" />
                                                    <div className="row">
                                                        <div className="col text-center">
                                                            <strong>Users On Reminder</strong>
                                                                </div>
                                                    </div>
                                                    <div className="row">
                                                        {item.userList && item.userList.map((user, i) => {
                                                            return (
                                                                <div className="col-2" key={`user-${i}`}>
                                                                    {user.name}
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </ListGroup.Item>
                                            )
                                        })}
                                    </ListGroup>
                                </div>
                            </div>
                            <form action="#" onSubmit={(e) => { e.preventDefault(); SaveReminder() }}>
                                <div className="row my-2">
                                    <div className="col-3">
                                        <label>Set Reminder Date and Time:</label>
                                        <br />
                                        <ReactDatePicker
                                            style={{ height: '25em' }}
                                            className="enc-input-100"
                                            minDate={new Date()}
                                            selected={reminder.reminderDate}
                                            onChange={(date) => ChangeReminder('reminderDate', date)}
                                            dateFormat="MMMM d, yyyy h:mm aa"
                                            showTimeSelect
                                        />
                                        <hr />

                                        {allowUserAdd &&
                                            <>
                                                <label>Assign Other Users:</label>
                                                <Multiselect
                                                    filter='contains'
                                                    data={users}
                                                    textField='name'
                                                    value={reminder.remindersUser}
                                                    valueField='userId'
                                                    onChange={(val) => ChangeReminder('remindersUser', val)}
                                                />
                                            </>
                                        }
                                    </div>
                                    <div className="col-6">
                                        <textarea placeholder="Reminder Text" className="enc-textarea" value={reminder.reminderText} onChange={(e) => ChangeReminder('reminderText', e.target.value)} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col text-right">
                                        <Button type='submit' className="enc-button">Save Reminder</Button>
                                    </div>
                                </div>
                            </form>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>

        </>
    )
}
