import React, { useState } from 'react';
import UserAnnouncement from './UserAnnouncement';
import DocumentList from './DocumentList';
import DashboardWidgets from './DashboardWidgets';
import WidgetOptions from './WidgetOptions';

export default () => {
    const [options, setOptions] = useState(false)
    return (
        <div className="container-fluid">
            <div className="row mt-2">
                <div className="col-md-5">
                    <UserAnnouncement />
                </div>
                <div className="col-md-3"></div>
                <div className="col-md-4">
                    <DocumentList />
                </div>
            </div>
            <div className="row">
                {options? <WidgetOptions toggle={() => setOptions(false)} /> : <DashboardWidgets toggle={() => setOptions(true)} />}
            </div>
        </div>
    )
}