import React, { useEffect, useState } from 'react';
import { GetRolePermission, SaveRole } from '../API/Administrator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { ListGroup } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay';

export default props => {
    const [roleCategories, setRoleCategories] = useState([]);
    const [role, setRole] = useState({
        roleId: 0,
        roleName: ""
    });
    const [active, setActive] = useState(true);
    const [editName, setEditName] = useState(false);
    const [message, setMessage] = useState("");

    useEffect(() => {
        function loadPermissions() {
            let roleId;
            if (props.roleId === "new") {
                roleId = 0;
                setEditName(true)
            } else {
                roleId = parseInt(props.roleId)
            }
            GetRolePermission(roleId)
                .then(res => {
                    if (res.success) {
                        setRoleCategories(res.roleCategories)
                        setRole(res.role)
                        setActive(false);
                    }
                });
        }
        loadPermissions();
    }, [props.roleId]);

    const toggleRole = (categoryIndex, permissionIndex) => {
        let s = [...roleCategories];
        s[categoryIndex].rolePermissionList[permissionIndex].active = !s[categoryIndex].rolePermissionList[permissionIndex].active
        setRoleCategories(s)
    }
    const toggleJobClass = (categoryIndex, permissionIndex, jobClassIndex) => {
        let s = [...roleCategories];
        s[categoryIndex].rolePermissionList[permissionIndex].permission.jobClasses[jobClassIndex].active = !s[categoryIndex].rolePermissionList[permissionIndex].permission.jobClasses[jobClassIndex].active;
        setRoleCategories(s)
    }

    const saveRole = () => {
        let perms = [];
        let payload = {};
        //roleCategories.forEach(category => {
        //    category.rolePermissionList.forEach(e => {
        //        if (e.access) {
        //            perms.push(e.permission.id)
        //        }
        //    })
        //});
        payload.role = role;
        payload.roleCategories = roleCategories;
        //payload.roleId = role.roleId
        //payload.roleName = role.roleName
        //payload.rolePermissionIds = perms
        SaveRole(payload)
            .then(res => {
                if (res.success) {
                    setRoleCategories(res.roleCategories)
                    setRole(res.role)
                    setEditName(false)
                }
                setMessage(res.message);
            })
    }

    const toggleEdit = () => {
        setEditName(!editName)
    }

    const editRoleName = (e) => {
        const newState = { ...role };
        newState.roleName = e.target.value;
        setRole(newState)
    }


    return (
        <LoadingOverlay
            active={active}
            spinner
            text='Loading Permissions...'
        >
            <div className="container-fluid">
                <form action="#" onSubmit={(e) => { e.preventDefault(); saveRole() }}>
                    <div className="row  mb-3">
                        <div className="col-3">
                            <h3>Role Name:</h3>
                        </div>
                        <div className="col">
                            {editName ? <input type="text" className="enc-input-100" placeholder="Role Name"
                                onChange={editRoleName} value={role.roleName}
                                required /> :
                                <div className="row">
                                    <div className="col-10">
                                        <h3>{role.roleName}</h3>
                                    </div>
                                    <div className="col-2 text-right">
                                        <FontAwesomeIcon className="clickable" icon={faPencilAlt} onClick={toggleEdit} />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    {roleCategories.map((category, categoryIndex) =>
                        <ListGroup className="mb-5" key={`category-${categoryIndex}`}>
                            <ListGroup.Item className="enc-list">
                                {category.categoryName}
                            </ListGroup.Item>
                            {category.rolePermissionList.map((permission, permissionIndex) => {
                                return (
                                    <div key={`permission-${permissionIndex}`}>
                                        <ListGroup.Item className=" py-1" key={`permission-${categoryIndex}-${permissionIndex}`}>
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <div className="toggle-switch small-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="toggle-switch-checkbox"
                                                            checked={permission.active}
                                                            id={`ToggleSwitch-${categoryIndex}-${permissionIndex}`}
                                                            onChange={(e) => toggleRole(categoryIndex, permissionIndex)}
                                                        />
                                                        <label className="toggle-switch-label" htmlFor={`ToggleSwitch-${categoryIndex}-${permissionIndex}`}>
                                                            <span className={"toggle-switch-inner"} />
                                                            <span
                                                                className={"toggle-switch-switch"}
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    {permission.permission.name}
                                                </div>
                                                <div className="col-md-6">
                                                    {permission.permission.description}
                                                </div>
                                            </div>
                                            {permission.permission.roleCategory === 6 ? // NOTIFICATIONS ROLE CATEGORY
                                                <>
                                                    <hr className ="my-1"/>
                                                    {permission.permission.jobSpecific && 
                                                        permission.permission.jobClasses.map((jobClass, classIndex) => {
                                                            return (
                                                                <div className="row pl-5" key={classIndex}>
                                                                    <div className="col-md-2">
                                                                        <div className="toggle-switch small-switch">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="toggle-switch-checkbox"
                                                                                checked={jobClass.active}
                                                                                id={`ToggleSwitch-${categoryIndex}-${permissionIndex}-${classIndex}`}
                                                                                onChange={(e) => toggleJobClass(categoryIndex, permissionIndex, classIndex)}
                                                                            />
                                                                            <label className="toggle-switch-label" htmlFor={`ToggleSwitch-${categoryIndex}-${permissionIndex}-${classIndex}`}>
                                                                                <span className={"toggle-switch-inner"} />
                                                                                <span
                                                                                    className={"toggle-switch-switch"}
                                                                                />
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-10">
                                                                        {jobClass.name}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })

                                                    }
                                                </>
                                                : null}
                                        </ListGroup.Item>
                                    </div>
                                )
                            })}
                        </ListGroup>
                    )}
                    <div className="row mt-3">
                        <div className="col">
                            {message}
                        </div>
                        <div className="col text-right">
                            <input type="submit" className="enc-button" value="Save" />
                        </div>
                    </div>
                </form>
            </div>
        </LoadingOverlay>
    )
}
