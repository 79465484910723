import React, { useState, useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas'
import { Button } from 'react-bootstrap';

export default props => {
    const reference = useRef();
    const [signature, setSignature] = useState("");

    const saveSignature = () => {
        let s = reference.current.toDataURL();
        setSignature(s);
    }



    return (
        <div>
            <div>
                <img src={`${signature}`} alt="Signature Preview"/>
                <hr />
            <SignatureCanvas 
            ref={reference}
            onFocus={() => reference.on()}
            onBlur={() => reference.off()}
            penColor='green'
            canvasProps={{width: 500, height: 200, className: 'sigCanvas'}} />
            <Button onClick={saveSignature}>Save</Button>
            <Button onClick={() => reference.current.clear()}>Clear</Button>
            </div>
        </div>
    )
}



