import React from 'react';
import { Switch, Route } from 'react-router-dom';
import EditOpportunity from './EditOpportunity';
import OpportunityView from './OpportunityView';
import OpportunityListView from './OpportunityListView';
import CallOpportunityList from './CallOpportunityList';
import CallIntakeView from './CallIntakeView';


export default (props) => {
    return (
        <>
            <Switch>
                <Route path={`${props.match.url}/Call/:CallId`} render={(routeprops) =>
                    <EditOpportunity url={props.match.url} callId={routeprops.match.params.CallId} />
                } />
                <Route path={`${props.match.url}/ClosedCall/:id`} render={(routeprops) =>
                    <CallIntakeView callId={routeprops.match.params.id} />
                } />
                <Route path={`${props.match.url}/Call`} render={(routeprops) =>
                    <CallOpportunityList url={props.match.url} {...routeprops} />} />
                <Route path={`${props.match.url}/NewOpportunity`} render={() =>
                    <EditOpportunity />
                } />
                <Route path={`${props.match.url}/:oppId/Edit`} render={(routeprops) =>
                    <EditOpportunity oppId={routeprops.match.params.oppId} />
                } />
                <Route path={`${props.match.url}/:oppId`} component={OpportunityView} />
                <Route path={`${props.match.url}`} render={() =>
                    <OpportunityListView {...props} />
                } />
            </Switch>
        </>
    )
}