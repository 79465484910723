import React, { useEffect, useState } from 'react';
import { Alert, Button, ListGroup, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { DropdownList } from 'react-widgets';
import { GetBudgetTemplateItemsList, PostBulkTemplate } from '../API/BudgetTemplate';
import { GetScopeBuilderNoOther } from '../API/JobScope';
import { ProposalListNames } from '../API/Proposal';

export default props => {
    const [scopeItem, setScopeItem] = useState([])
    const [working, setWorking] = useState(false);
    const [selected, setSelected] = useState([]);
    const [prefills, setPrefills] = useState([])
    const [filter, setFilter] = useState("")
    const [confirm, setConfirm] = useState(false);

    const [budgetTemplateItems, setBudgetTemplateItems] = useState([])
    const [selectedBudgetTemplateItem, setSelectedBudgetTemplateItem] = useState(null)


    useEffect(() => {
        GetBudgetTemplateItemsList()
        .then(res => {
            setBudgetTemplateItems(res.data)
        })
        LoadProposalPrefills()
        GetScopeBuilderNoOther()
            .then(res => {
                setScopeItem(res.data)
            })
    }, [])





    const LoadProposalPrefills = () => {
        ProposalListNames()
            .then(res => {
                setPrefills(res.data)
            })
    }


    const changeScope = (tier, value) => {
        let s = [...selected]
        if (selected.length >= tier + 1) {
            s.splice(tier, selected.length - tier, value)
        } else {
            s.push(value)
        }
        setSelected(s)
    }

    const loadScope = (sc, tier = 0, arr = []) => {
        arr.push({ data: sc, tier: tier })
        let select;
        if (selected[tier]) {
            select = sc.filter(scope => scope.scopeBuilderId === selected[tier].scopeBuilderId)[0]
        }
        if (select && select.children && select.children.length > 0) {
            loadScope(select.children, tier + 1, arr)
        }
        return arr;
    }

    const BulkAddBudget = () => {
        let lastItem = selected.slice(-1).pop()
        setWorking(true);
        if (lastItem && selectedBudgetTemplateItem) {
            let payload = {
                scopeBuilderId: lastItem.scopeBuilderId,
                BudgetItem: selectedBudgetTemplateItem
            }
            PostBulkTemplate(payload)
            .then(res => {
                setWorking(false);
                setSelected([])
                setSelectedBudgetTemplateItem(null)
                setConfirm(true);
            })
        }

    }


    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h3>Budget Templates</h3>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <h4>Bulk Template Builder</h4>
                </div>
            </div>
            <div>
                <form action="#" onSubmit={(e) => { e.preventDefault();  BulkAddBudget() }}>
                    <div className="row">
                        <div className="col">
                            <small>Select a Budget Line for Bulk Operation</small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <DropdownList 
                                filter="contains"
                                data={budgetTemplateItems}
                                placeholder="Select a Budget Line"
                                textField="budgetItemName"
                                value={selectedBudgetTemplateItem}
                                onChange={e => setSelectedBudgetTemplateItem(e)}
                            />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col">
                            <small>Select a scope parent to bulk add budget.</small>
                        </div>
                    </div>
                    <div className='row mb-2'>
                        {loadScope(scopeItem).map((m, i) => {
                            return <div key={`dd-${i}`} className="col-6 mt-1">
                                <DropdownList
                                    data={m.data}
                                    onChange={e => changeScope(m.tier, e)}
                                    value={selected[m.tier]}
                                    textField='itemName'
                                    placeholder="Select a value"
                                    required
                                />
                            </div>
                        })}
                    </div>
                    <div className="row">
                        <div className="col-8">
                        <Alert variant="success" show={confirm} onClose={() => setConfirm(false)} dismissible>
                            Successfully Bulk Added Budget Lines
                        </Alert>
                        </div>
                        <div className="col text-right">
                            <Button type='submit' className="enc-button" disabled={working}>
                                {working ?
                                    <span>Processing
                                    <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        /></span> : "Bulk Add Budget Line"}
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
            <div className="divider my-2" />
            <div className="row my-2">
                <div className="col-8" />
                <div className="col-4 text-right">
                    <input placeholder="Filter" className="enc-input-100" value={filter} onChange={e => setFilter(e.target.value)} />
                </div>
            </div>
            <ListGroup className="mb-3">
                <ListGroup.Item className="click-list">
                    Job Scope Combinations
                    </ListGroup.Item>
                {prefills.filter(x => x.scopeName.toLowerCase().search(filter.toLowerCase()) !== -1).map((m, i) => {
                    return (
                        <ListGroup.Item as={Link} to={`${props.match.url}/${m.leafId}`} className="click-list" key={`Prefill-${i}`}>
                            {m.scopeName}
                        </ListGroup.Item>
                    )
                })}
            </ListGroup>
        </div>
    )
}
