import React, { useState } from 'react';
import FromLocation from './LocationSelection';
import ItemSelection from './ItemSelection';
import FinalScreen from './FinalScreen';

export default props => {

    const [wizardStep, setWizardStep] = useState(0)

    const [fromLocation, setFromLocation] = useState({})
    const [fromType, setFromType] = useState(0)
    /// Save Location Data from Server
    const [toLocation, setToLocation] = useState({})
    const [toType, setToType] = useState(0)

    const setFromData = (type, model) => {
        setFromType(type)
        setFromLocation(model)
    }

    const setToData = (type, model) => {
        setToType(type)
        setToLocation(model)
    }

    const SaveLocation = (TypeFrom, ModelFrom, TypeTo, ModelTo) => {
        setToData(TypeTo, ModelTo);
        setFromData(TypeFrom, ModelFrom)
        setWizardStep(1)
    }


    const reset = () => {
        setToLocation({})
        setWizardStep(0)
        setFromLocation({})
    }


    const LoadScreenLogic = () => {
        switch (wizardStep) {
            case 0:
                return <FromLocation save={SaveLocation} />
            case 1:
                return <ItemSelection save={() => setWizardStep(2)} fromType={fromType} toType={toType} to={toLocation} from={fromLocation} />
            case 2:
                return <FinalScreen reset={reset} />
            default:
                return // Error Page
        }
    }




    return (
        <>
            <div className="container">
                <div className="row my-3">
                    <div className='col'>
                        <h3>Create Pick Ticket</h3>
                    </div>
                </div>
                <div className="row">
                    {LoadScreenLogic()}
                </div>
            </div>
        </>
    )
}
