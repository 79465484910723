import React, { useEffect, useState } from 'react';
import ClientApproval from './ClientApproval';
import CorporateApproval from './CorporateApproval';
import OperationsApproval from './OperationsApproval';
import { GetApprovals } from '../../API/JobApproval';
import SalesReview from './SalesReview';
import Accounting from './Accounting/Accounting';

export default props => {
    const [accountingSkipped, setAccountingSkipped] = useState(false);
    const [corporate, setCorporate] = useState({
        "jobApprovalId": 0,
        "edit": false,
        "approval": false,
        "approver": "",
        "approvalDate": ""
    })
    const [operations, setOperations] = useState({
        "jobApprovalId": 0,
        "edit": false,
        "approval": false,
        "approver": "",
        "approvalDate": ""
    })
    const [client, setClient] = useState({
        "jobApprovalId": 0,
        "edit": false,
        "approval": false,
        "approver": "",
        "approvalDate": ""
    })
    const [salesReview, setSalesReview] = useState({
        "jobApprovalId": 0,
        "edit": false,
        "approval": false,
        "approver": "",
        "approvalDate": ""
    });
    const [accounting, setAccounting] = useState({
        "jobApprovalId": 0,
        "edit": false,
        "approval": false,
        "approver": "",
        "approvalDate": ""
    });


    useEffect(() => {
        LoadApprovals(parseInt(props.jobId))
    }, [props.jobId])
    const LoadApprovals = (id) => {
        GetApprovals(id)
            .then(res => {
                if (res.success) {
                    setClient(res.clientApproval)
                    setCorporate(res.corporateApproval)
                    setOperations(res.operationsApproval)
                    setSalesReview(res.salesApproval)
                    setAccounting(res.accountingApproval)
                    setAccountingSkipped(res.accountingSkipped)
                }
            })
    }
    const reloadApprovals = () => {
        LoadApprovals(parseInt(props.jobId))
    }

    return (
        <div className="container">
            <OperationsApproval rl={reloadApprovals} jobId={props.jobId} approval={operations} />
            <div className="divider my-3" />
            <CorporateApproval rl={reloadApprovals} jobId={props.jobId} approval={corporate} />
            <div className="divider my-3" />
            <SalesReview jobId={props.jobId} rl={reloadApprovals} approval={salesReview} />
            <div className="divider my-3" />
            <ClientApproval rl={reloadApprovals} jobId={props.jobId} approval={client} />
            <div className="divider my-3" />
            <Accounting jobId={props.jobId} rl={reloadApprovals} accountingSkipped={accountingSkipped} approval={accounting} />
        </div>
    )
}
