import React, { useState, useEffect } from 'react';
import { GetDocument, GetDocumentAndPolicyList } from '../API/DocumentAndPolicy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { ListGroup } from 'react-bootstrap';

export default props => {
    const [documents, setDocuments] = useState([])

    useEffect(() => {
        loadDocuments();
    }, [])

    const loadDocuments = () => {
        GetDocumentAndPolicyList()
            .then(res => {
                setDocuments(res.data)
            })
    }

    const downloadDoc = (id, name) => {
        let download = require('downloadjs');
        GetDocument(parseInt(id))
            .then(res => {
                download(res, name)
            })
    }

    return (
        <ListGroup>
            <ListGroup.Item className="enc-list">
                Documents and Policies
            </ListGroup.Item>
            {documents.map(m => {
                return (
                    <ListGroup.Item key={`Document-${m.documentId}`} className="enc-list">
                        <div className="row">
                            <div className="col-sm-1"> <FontAwesomeIcon
                                icon={faDownload}
                                className="clickable"
                                onClick={() => downloadDoc(m.documentId, m.filename)} />
                            </div>
                            <div className="col-sm-10">
                                <h5> {m.name}</h5>
                            </div>
                        </div>
                    </ListGroup.Item>
                )
            })}
        </ListGroup>
    )
}
