import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { Button, Accordion, Card, Alert, ListGroup } from 'react-bootstrap';
import { GetBudget, SaveBudget, GetBudgetingHoursList, GetBudgetCalculations, GetBudgetTimes, SaveUserItemOrder, SaveRevenueOrder, SaveCostOrder } from '../../../API/Budget';
import HourCalculator from '../../../Components/HourCalculator';
import AddLine from '../../../Components/AddLine';
import { Prompt } from 'react-router-dom'
import { DropdownList } from 'react-widgets';
import LoadingOverlay from 'react-loading-overlay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faFont, faDollarSign, faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import TextareaAutosize from 'react-textarea-autosize';

export default ({ reloadParent, ...props }) => {
    const [budgetCalculations, setBudgetCalculations] = useState([]);
    const [budgetTimes, setBudgetTimes] = useState([]);


    const [scope, setScope] = useState([])
    const [budgetLines, setBudgetLines] = useState({
        budgetCostLabor: [],
        budgetCostNonLabor: []
    })
    const [revenueLines, setRevenueLines] = useState([])
    const [hours, setHours] = useState([])
    const [alertText, setAlertText] = useState("")
    const [showAlert, setShowAlert] = useState(false);
    const [blockNavigation, setBlockNavigation] = useState(false)

    const [Summary, setSummary] = useState({
        totalCost: 0,
        totalRevenue: 0,
        totalProfit: 0,
        totalProfitPercentage: 0
    });

    const [loading, setLoading] = useState(true);

    const textareaStyle = {
        resize: "none",
        height: "unset"
    };

    // Sum All Totals and do maths for budget summary.
    useEffect(() => {
        let totalRevenue = 0;
        let totalCost = 0;
        for (let i = 0; i < scope.length; i++) {
            let si = scope[i];
            if (si.budgetRevenue) {
                for (let br = 0; br < si.budgetRevenue.length; br++) {
                    let e = si.budgetRevenue[br];
                    let r = 0;
                    if (e.revenue !== "" && e.revenue !== ".") {
                        totalRevenue += parseFloat(e.revenue)
                    }
                    if (e.revenuePer !== "" && e.revenuePer !== ".") {
                        if (e.budgetCalculation && e.budgetCalculation.name && e.budgetCalculation.name !== '' && e.budgetCalculation.name.toLowerCase() !== 'none') {
                            totalRevenue += parseFloat(e.revenuePer * e.quantity * e.minCycles)
                        } else {
                            totalRevenue += parseFloat(e.revenuePer * e.minCycles)
                        }
                    }
                }
            }
            if (si.budgetCost.budgetCostLabor) {
                for (let bcl = 0; bcl < si.budgetCost.budgetCostLabor.length; bcl++) {
                    if (si.budgetCost.budgetCostLabor[bcl].cost !== "" && si.budgetCost.budgetCostLabor[bcl].cost !== ".") {
                        totalCost += parseFloat(si.budgetCost.budgetCostLabor[bcl].cost)
                    }
                }
            }
            if (si.budgetCost.budgetCostNonLabor) {
                for (let bcn = 0; bcn < si.budgetCost.budgetCostNonLabor.length; bcn++) {
                    if (si.budgetCost.budgetCostNonLabor[bcn].cost !== "" && si.budgetCost.budgetCostNonLabor[bcn].cost !== ".") {
                        totalCost += parseFloat(si.budgetCost.budgetCostNonLabor[bcn].cost)
                    }
                }
            }
        }
        let res = {
            totalCost: Math.round((totalCost + Number.EPSILON) * 100) / 100,
            totalRevenue: Math.round((totalRevenue + Number.EPSILON) * 100) / 100,
        }
        if (totalRevenue !== 0) {
            res.totalProfit = totalRevenue - totalCost;
            res.totalProfitPercentage = Math.round((totalRevenue - totalCost) / totalRevenue * 100);
        } else {
            res.totalProfit = 0;
            res.totalProfitPercentage = 0;
        }
        setSummary(res);
    }, [scope])

    useEffect(() => {
        LoadBudget(props.jobId)
    }, [props.jobId])

    const LoadBudget = (id) => {
        setLoading(true);
        GetBudgetTimes()
            .then(times => {
                setBudgetTimes(times.data)
                GetBudgetCalculations()
                    .then(calcs => {
                        setBudgetCalculations(calcs.data)
                        GetBudget(parseInt(id))
                            .then(res => {
                                for (let sc = 0; sc < res.budgetItems.length; sc++) {
                                    for (let i = 0; i < res.budgetItems[sc].budgetRevenue.length; i++) {
                                        res.budgetItems[sc].budgetRevenue[i].budgetCalculation = calcs.data.find(e => e.budgetCalculationId === res.budgetItems[sc].budgetRevenue[i].budgetCalculation)
                                        res.budgetItems[sc].budgetRevenue[i].budgetTime = times.data.find(e => e.budgetTimeId === res.budgetItems[sc].budgetRevenue[i].budgetTime)
                                    }
                                }
                                setBudgetLines(res.budgetLines)
                                setRevenueLines(res.budgetRevenueLines)
                                setScope(res.budgetItems)
                                setLoading(false);
                            })
                    })
            })
        GetBudgetingHoursList()
            .then(res => {
                setHours(res.data)
            })
    }


    const createPayload = (submit) => {
        let payload = {
            jobId: parseInt(props.jobId),
            submit: submit
        }

        let newScope = [...scope]
        for (let i = 0; i < scope.length; i++) {
            let si = scope[i];
            if (si.budgetRevenue) {
                for (let br = 0; br < si.budgetRevenue.length; br++) {
                    if (si.budgetRevenue[br].revenuePer !== "" && si.budgetRevenue[br].revenuePer !== ".") {
                        newScope[i].budgetRevenue[br].revenuePer = parseFloat(si.budgetRevenue[br].revenuePer)
                    } else {
                        newScope[i].budgetRevenue[br].revenuePer = 0
                    }
                    if (si.budgetRevenue[br].revenue !== "" && si.budgetRevenue[br].revenue !== ".") {
                        newScope[i].budgetRevenue[br].revenue = parseFloat(si.budgetRevenue[br].revenue)
                        
                    } else {
                        newScope[i].budgetRevenue[br].revenue = 0
                    }
                    newScope[i].budgetRevenue[br].minCycles = parseInt(si.budgetRevenue[br].minCycles)
                }
            }
            if (si.budgetCost.budgetCostLabor) {
                for (let bcl = 0; bcl < si.budgetCost.budgetCostLabor.length; bcl++) {
                    if (si.budgetCost.budgetCostLabor[bcl].cost !== "" && si.budgetCost.budgetCostLabor[bcl].cost !== ".") {
                        newScope[i].budgetCost.budgetCostLabor[bcl].cost = parseFloat(si.budgetCost.budgetCostLabor[bcl].cost)
                    } else {
                        newScope[i].budgetCost.budgetCostLabor[bcl].cost = 0
                    }
                    for (let hr = 0; hr < si.budgetCost.budgetCostLabor[bcl].hours.length; hr++) {
                        if (si.budgetCost.budgetCostLabor[bcl].hours[hr].hours !== "" && si.budgetCost.budgetCostLabor[bcl].hours[hr].hours !== ".") {
                            newScope[i].budgetCost.budgetCostLabor[bcl].hours[hr].hours = parseFloat(si.budgetCost.budgetCostLabor[bcl].hours[hr].hours)
                        } else {
                            newScope[i].budgetCost.budgetCostLabor[bcl].hours[hr].hours = 0
                        }
                    }
                }
            }
            if (si.budgetCost.budgetCostNonLabor) {
                for (let bcn = 0; bcn < si.budgetCost.budgetCostNonLabor.length; bcn++) {
                    if (si.budgetCost.budgetCostNonLabor[bcn].cost !== "" && si.budgetCost.budgetCostNonLabor[bcn].cost !== ".") {
                        newScope[i].budgetCost.budgetCostNonLabor[bcn].cost = parseFloat(si.budgetCost.budgetCostNonLabor[bcn].cost)
                    } else {
                        newScope[i].budgetCost.budgetCostNonLabor[bcn].cost = 0
                    }
                }
            }
            if (si.budgetTax) {
                for (let btn = 0; btn < si.budgetTax.length; btn++) {
                    if (si.budgetTax[btn].cost !== "" && si.budgetTax[btn].cost !== ".") {
                        newScope[i].budgetTax[btn].cost = parseFloat(si.budgetTax[btn].cost)
                    } else {
                        newScope[i].budgetTax[btn].cost = 0
                    }
                }
            }
        }

        payload.budgetItems = newScope;

        for (let sc = 0; sc < payload.budgetItems.length; sc++) {
            for (let i = 0; i < payload.budgetItems[sc].budgetRevenue.length; i++) {
                if (payload.budgetItems[sc].budgetRevenue[i].budgetCalculation) {
                    payload.budgetItems[sc].budgetRevenue[i].budgetCalculation = payload.budgetItems[sc].budgetRevenue[i].budgetCalculation.budgetCalculationId
                }
                if (payload.budgetItems[sc].budgetRevenue[i].budgetTime)
                {
                    payload.budgetItems[sc].budgetRevenue[i].budgetTime = payload.budgetItems[sc].budgetRevenue[i].budgetTime.budgetTimeId
                }
            }
        }
        return payload;
    }

    const saveBudget = (submit = false) => {
        let payload = createPayload(submit);
        SaveBudget(payload)
            .then(res => {
                if (res.success && submit) {
                    reloadParent()
                } else if (res.success) {
                    setAlertText("Saved Successfully")
                    setShowAlert(true)
                    LoadBudget(props.jobId)
                }
                setBlockNavigation(false);
            })
    }

    const GetTotals = (m) => {
        let totalRevenue = 0;
        m.budgetRevenue.forEach(e => {
            if (e.revenue !== "" && e.revenue !== ".") {
                totalRevenue += parseFloat(e.revenue)
            }
            if (e.revenuePer !== "" && e.revenuePer !== "." && e.minCycles !== "" && e.minCycles !== ".")
            {
                if (e.revenuePer && e.budgetCalculation && e.budgetCalculation.name && e.budgetCalculation.name.toLowerCase() !== 'none'){
                    totalRevenue += (parseFloat(e.revenuePer) * m.quantity * parseInt(e.minCycles))
                } else if (e.revenuePer){
                    totalRevenue += (parseInt(e.revenuePer) * parseInt(e.minCycles))
                }
            }
        });
        let totalCost = 0;
        m.budgetCost.budgetCostLabor.forEach(e => {
            if (e.cost !== "" && e.cost !== ".") {
                totalCost += parseFloat(e.cost)
            }
        });
        m.budgetCost.budgetCostNonLabor.forEach(e => {
            if (e.cost !== "" && e.cost !== ".") {
                totalCost += parseFloat(e.cost)
            }
        })

        let res = {
            totalCost: Math.round((totalCost + Number.EPSILON) * 100) / 100,
            totalRevenue: Math.round((totalRevenue + Number.EPSILON) * 100) / 100,
        }

        if (totalRevenue !== 0) {
            res.totalProfit = totalRevenue - totalCost;
            res.totalProfitPercentage = Math.round((Math.round((totalRevenue - totalCost) / totalRevenue * 100) + Number.EPSILON) * 100) / 100;
        } else {
            res.totalProfit = 0;
            res.totalProfitPercentage = 0;
        }
        return res;
    }

    const changeRev = (scopeIndex, revenueIndex, value) => {
        const re = new RegExp('^([0-9]+)?(\\.)?([0-9]{0,2})?$');
        if (!value === "" || !re.test(value)) {
            return;
        }
        let s = [...scope]
        s[scopeIndex].budgetRevenue[revenueIndex].revenue = value;
        setScope(s)
        checkBothCalcMessage(scopeIndex, revenueIndex);
        setBlockNavigation(true)
    }

    const changeRevText = (scopeIndex, revenueIndex, value) => {
        let s = [...scope]
        s[scopeIndex].budgetRevenue[revenueIndex].revenueText = value;
        setScope(s)
        setBlockNavigation(true)
    }

    const changeRevPer = (scopeIndex, revenueIndex, value) => {
        const re = new RegExp('^([0-9]+)?(\\.)?([0-9]{0,2})?$');
        if (!value === "" || !re.test(value)) {
            return;
        }
        let s = [...scope]
        s[scopeIndex].budgetRevenue[revenueIndex].revenuePer = value;
        setScope(s)
        checkBothCalcMessage(scopeIndex, revenueIndex);
        setBlockNavigation(true)
    }

    const changeRevNotes = (scopeIndex, revenueIndex, value) => {
        let s = [...scope];
        s[scopeIndex].budgetRevenue[revenueIndex].notes = value;
        setScope(s);
        setBlockNavigation(true)
    }

    const checkBothCalcMessage = (scopeIdx, revIdx) => {
        // If using both lump sum and calculated rate calculation modes, show message as warning.
        let s = [...scope]
        s[scopeIdx].budgetRevenue[revIdx]["showLumpSumWarning"] = (s[scopeIdx].budgetRevenue[revIdx].revenue > 0 && s[scopeIdx].budgetRevenue[revIdx].revenuePer > 0);
        setScope(s)
    }

    const setRevenueLineAltInput = (scopeIndex, revenueIndex) => {
        let s = [...scope]
        let br = s[scopeIndex].budgetRevenue;
        let r = br[revenueIndex];
        if (isRevenueNumericInput(r)) {
            // alt input disabled, reset text
            r.revenue = 0;
            r["revAltInput"] = true;
        } else {
            // alt input enabled, reset revenue
            r.revenueText = "";
            r["revAltInput"] = false;
        }
        setScope(s)
    }
    const isRevenueNumericInput = (revenueItem) => {
        return (revenueItem.revenueText.length == 0) &&
            (revenueItem["revAltInput"] === undefined || (revenueItem["revAltInput"] !== undefined && revenueItem["revAltInput"] === false));
    }

    const removeRevenueLine = (scopeIndex, revenueIndex) => {
        let s = [...scope]
        s[scopeIndex].budgetRevenue.splice(revenueIndex, 1);
        setScope(s)
        setBlockNavigation(true)
    }

    const SetRevPer = (scopeIndex, revenueIndex, value) => {
        let s = [...scope]
        s[scopeIndex].budgetRevenue[revenueIndex].budgetCalculation = value;
        setScope(s)
        setBlockNavigation(true)
    }

    const SetTimePer = (scopeIndex, revenueIndex, value) => {
        let s = [...scope]
        s[scopeIndex].budgetRevenue[revenueIndex].budgetTime = value;
        setScope(s)
        setBlockNavigation(true)
    }

    const setMinCycles = (scopeIndex, revenueIndex, value) => {
        const re = new RegExp('^([0-9]+)?$');
        if (!value === "" || !re.test(value)) {
            return;
        }
        let s = [...scope]
        s[scopeIndex].budgetRevenue[revenueIndex].minCycles = value;
        setScope(s)
        setBlockNavigation(true)
    }

    const changeCost = (scopeIndex, laborCategory, costIndex, value) => {
        let s = [...scope]
        const re = new RegExp('^([0-9]+)?(\\.)?([0-9]{0,2})?$');
        if (!value === "" || !re.test(value)) {
            return;
        }
        s[scopeIndex].budgetCost[laborCategory][costIndex].cost = value;
        setScope(s)
        setBlockNavigation(true)
    }

    const changeCostNotes = (scopeIndex, laborCategory, costIndex, value) => {
        let s = [...scope]
        s[scopeIndex].budgetCost[laborCategory][costIndex].notes = value;
        setScope(s)
        setBlockNavigation(true)
    }

    const removeCostLine = (scopeIndex, laborCategory, costIndex) => {
        let s = [...scope]

        s[scopeIndex].budgetCost[laborCategory].splice(costIndex, 1);
        setScope(s)
        setBlockNavigation(true)
    }


    const changeTax = (scopeIndex, taxIndex, value) => {
        let s = [...scope]
        const re = new RegExp('^([0-9]+)?(\\.)?([0-9]{0,2})?$');
        if (!value === "" || !re.test(value)) {
            return;
        }
        s[scopeIndex].budgetTax[taxIndex].cost = value;
        setScope(s)
        setBlockNavigation(true)
    }

    const removeTaxLine = (scopeIndex, taxIndex) => {
        let s = [...scope]

        s[scopeIndex].budgetTax.splice(taxIndex, 1);
        setScope(s)
        setBlockNavigation(true)
    }

    const changeTaxDescription = (scopeIndex, taxIndex, value) => {
        let s = [...scope]
        s[scopeIndex].budgetTax[taxIndex].description = value;
        setScope(s)
        setBlockNavigation(true)
    }
    const changeTaxNotes = (scopeIndex, taxIndex, value) => {
        let s = [...scope]
        s[scopeIndex].budgetTax[taxIndex].notes = value;
        setScope(s)
        setBlockNavigation(true)
    }






    const changeSubline = (scopeIndex, lineIndex, key, value) => {
        let s = [...scope]
        s[scopeIndex].budgetAdditionalLines[lineIndex][key] = value;
        setScope(s)
        setBlockNavigation(true)
    }

    const removeSubline = (scopeIndex, lineIndex) => {
        let s = [...scope]
        s[scopeIndex].budgetAdditionalLines.splice(lineIndex, 1)
        setScope(s)
    }
    const addSubline = (scopeIndex) => {
        let s = [...scope]
        s[scopeIndex].budgetAdditionalLines.push({ additionalText: "", optionalCost: "" });
        setScope(s)
    }


    const AddBudgetLine = (scopeIndex, cat, e) => {
        let s = [...scope]
        let costRouter = {
            "Non-Labor": "budgetCostNonLabor",
            "Labor": "budgetCostLabor"
        }
        if (cat === "Tax") {
            s[scopeIndex].budgetTax.push({ budgetTaxId: 0, scopeItemId: 0, cost: 0, notes: "" })
        }
        else if (cat === "Revenue") {
            e.order = s[scopeIndex].budgetRevenue.length;
            e.budgetRevenueId = -1 - s[scopeIndex].budgetRevenue.length;
            console.log(e)
            s[scopeIndex].budgetRevenue.push({ ...e })
        } else {
            let BudgetLine = {...e};
            BudgetLine.hours = [];
            BudgetLine.order = s[scopeIndex].budgetCost[costRouter[cat]].length;
            BudgetLine.budgetCostId = -1 - s[scopeIndex].budgetCost[costRouter[cat]].length;
            console.log(BudgetLine)
            s[scopeIndex].budgetCost[costRouter[cat]].push(BudgetLine)
        }
        setScope(s)
        setBlockNavigation(true)
    }

    const AddHourLine = (scopeIndex, laborCategory, costIndex, e, bid) => {
        let s = [...scope]
        let payload = { ...e }
        e.budgetCostId = bid;
        s[scopeIndex].budgetCost[laborCategory][costIndex].hours.push(payload);
        setScope(s)
        setBlockNavigation(true)
    }

    const RemoveHour = (scopeIndex, laborCategory, costIndex, hourIndex) => {
        let s = [...scope]
        s[scopeIndex].budgetCost[laborCategory][costIndex].hours.splice(hourIndex, 1);
        setBlockNavigation(true)
        setScope(s)
    }

    const UpdateHours = (scopeIndex, laborCategory, costIndex, hoursIndex, value) => {
        const re = new RegExp('^([0-9]+)?(\\.)?([0-9]{0,5})?$');
        if (!value === "" || !re.test(value)) {
            return;
        }
        let s = [...scope]
        s[scopeIndex].budgetCost[laborCategory][costIndex].hours[hoursIndex].hours = value;
        let total = s[scopeIndex].budgetCost[laborCategory][costIndex].hours.reduce((acc, obj) => { return acc + (obj.hours * obj.price) }, 0);
        s[scopeIndex].budgetCost[laborCategory][costIndex].cost = Math.round((total + Number.EPSILON) * 100) / 100;
        setScope(s)
        setBlockNavigation(true)
    }

    //const moveScopeUp = (index) => {
    //    let s = [ ...scope ];
    //    if (index === 0) { return; }
    //    let first = s[index];
    //    let second = s[index - 1];
    //    first.order = parseInt(index - 1);
    //    second.order = parseInt(index);
    //    s.splice(index - 1, 2, first, second);
    //    resetScopeOrder(s);
    //}
    //const moveScopeDown = (index) => {
    //    let s = [...scope];
    //    if (index === scope.length - 1) { return; }
    //    let first = s[index + 1];
    //    let second = s[index];
    //    first.order = parseInt(index);
    //    second.order = parseInt(index + 1);
    //    s.splice(index, 2, first, second);
    //    resetScopeOrder(s);
    //}
    //const resetScopeOrder = (s) => {
    //    setLoading(true);
    //    let payload = {
    //        jobId: parseInt(props.jobId),
    //        submit: true,
    //        budgetItems: s
    //    };
    //    SaveUserItemOrder(payload).then((res) => {
    //        if (res.success) {
    //            LoadBudget(props.jobId);
    //        }
    //        setLoading(false);
    //    }).catch(e => {
    //        console.log(e);
    //        setLoading(false);
    //    });
    //}

    const moveRevUp = (scopeIndex, revIndex) => {
        let s = [...scope];
        //if (scopeIndex === 0) { return; }
        let item = s[scopeIndex];
        let revItem = item.budgetRevenue;
        if (revIndex === 0) { return; }
        let first = revItem[revIndex];
        let second = revItem[revIndex - 1];
        first.order = parseInt(revIndex - 1);
        second.order = parseInt(revIndex);
        revItem.splice(revIndex - 1, 2, first, second);

        for (let i = 0; i < revItem.length; i++) {
            if (revItem[i].budgetCalculation !== null && revItem[i].budgetCalculation !== undefined) {
                revItem[i].budgetCalculationStr = revItem[i].budgetCalculation.name;
                revItem[i].budgetCalculation = revItem[i].budgetCalculation.budgetCalculationId;
            }
            else {
                revItem[i].budgetCalculation = null;
                revItem[i].budgetCalculationStr = "";
            }

            if (revItem[i].budgetTime !== null && revItem[i].budgetTime !== undefined) {
                revItem[i].budgetTimeStr = revItem[i].budgetTime.name;
                revItem[i].budgetTime = revItem[i].budgetTime.budgetTimeId;
            }
            else {
                revItem[i].budgetTime = null;
                revItem[i].budgetTimeStr = "";
            }
        }

        setScope(s);

        //resetRevOrder(item);
    };
    const moveRevDown = (scopeIndex, revIndex) => {
        let s = [...scope];
        //if (scopeIndex === scope.length - 1) { return; }
        let item = s[scopeIndex];
        let revItem = item.budgetRevenue;
        if (revIndex === revItem.length - 1) { return; }
        let first = revItem[revIndex + 1];
        let second = revItem[revIndex];
        first.order = parseInt(revIndex);
        second.order = parseInt(revIndex + 1);
        revItem.splice(revIndex, 2, first, second);

        for (let i = 0; i < revItem.length; i++) {
            if (revItem[i].budgetCalculation !== null && revItem[i].budgetCalculation !== undefined) {
                revItem[i].budgetCalculationStr = revItem[i].budgetCalculation.name;
                revItem[i].budgetCalculation = revItem[i].budgetCalculation.budgetCalculationId;
            }
            else {
                revItem[i].budgetCalculation = null;
                revItem[i].budgetCalculationStr = "";
            }

            if (revItem[i].budgetTime !== null && revItem[i].budgetTime !== undefined) {
                revItem[i].budgetTimeStr = revItem[i].budgetTime.name;
                revItem[i].budgetTime = revItem[i].budgetTime.budgetTimeId;
            }
            else {
                revItem[i].budgetTime = null;
                revItem[i].budgetTimeStr = "";
            }
        }

        setScope(s);

        //resetRevOrder(item);
    };
    const resetRevOrder = (r) => {
        console.log(r)

        setLoading(true);
        let payload = {
            jobId: parseInt(props.jobId),
            submit: true,
            budgetItem: r
        };
        SaveRevenueOrder(payload).then((res) => {
            if (res.success) {
                //LoadBudget(props.jobId);
            }
            setLoading(false);
        }).catch(e => {
            console.log(e);
            setLoading(false);
        });
    };

    const moveNonLaborUp = (scopeIndex, costIndex) => {
        let s = [...scope];
        //if (scopeIndex === 0) { return; }
        let item = s[scopeIndex];
        let costItem = item.budgetCost.budgetCostNonLabor;
        if (costIndex === 0) { return; }
        let first = costItem[costIndex];
        let second = costItem[costIndex - 1];
        first.order = parseInt(costIndex - 1);
        second.order = parseInt(costIndex);
        costItem.splice(costIndex - 1, 2, first, second);

        setScope(s);

        //resetCostLaborOrder(costItem);
    };
    const moveNonLaborDown = (scopeIndex, costIndex) => {
        let s = [...scope];
        //if (scopeIndex === scope.length - 1) { return; }
        let item = s[scopeIndex];
        let costItem = item.budgetCost.budgetCostNonLabor;
        if (costIndex === costItem.length - 1) { return; }
        let first = costItem[costIndex + 1];
        let second = costItem[costIndex];
        first.order = parseInt(costIndex);
        second.order = parseInt(costIndex + 1);
        costItem.splice(costIndex, 2, first, second);

        setScope(s);
        
        //resetCostLaborOrder(costItem);
    };

    const moveLaborUp = (scopeIndex, costIndex) => {
        let s = [...scope];
        //if (scopeIndex === 0) { return; }
        let item = s[scopeIndex];
        let costItem = item.budgetCost.budgetCostLabor;
        if (costIndex === 0) { return; }
        let first = costItem[costIndex];
        let second = costItem[costIndex - 1];
        first.order = parseInt(costIndex - 1);
        second.order = parseInt(costIndex);
        costItem.splice(costIndex - 1, 2, first, second);

        setScope(s);

        //resetCostLaborOrder(costItem);
    };
    const moveLaborDown = (scopeIndex, costIndex) => {
        let s = [...scope];
        //if (scopeIndex === scope.length - 1) { return; }
        let item = s[scopeIndex];
        let costItem = item.budgetCost.budgetCostLabor;
        if (costIndex === costItem.length - 1) { return; }
        let first = costItem[costIndex + 1];
        let second = costItem[costIndex];
        first.order = parseInt(costIndex);
        second.order = parseInt(costIndex + 1);
        costItem.splice(costIndex, 2, first, second);

        setScope(s);

        //resetCostLaborOrder(costItem);
    };

    const resetCostLaborOrder = (c) => {
        console.log(c)

        setLoading(true);
        let payload = {
            jobId: parseInt(props.jobId),
            submit: true,
            budgetItems: c
        };
        SaveCostOrder(payload).then((res) => {
            if (res.success) {
                //LoadBudget(props.jobId);
            }
            setLoading(false);
        }).catch(e => {
            console.log(e);
            setLoading(false);
        });
    };

    return (
        <LoadingOverlay
            active={loading}
            spinner
            text='Loading budget...'
        >
            <div>
                <Prompt
                    when={blockNavigation}
                    message="You have unsaved changes, are you should you would like to leave?"
                />
                {scope.map((m, scopeIndex) => {
                    let totals = GetTotals(m)
                    return (
                        <Accordion key={`ScopeItem-${scopeIndex}`}>
                            <Card style={{ overflow: "visible" }}>
                                <Accordion.Toggle as={Card.Header} eventKey={m.scopeItemId.toString()}>
                                    <div className="row">
                                        <div className="col">{m.optional && "Optional - "}{m.name}</div>
                                        <div className="col-3 text-right">Quantity: {m.quantity}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col">{m.description}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col">Total Rev: ${totals.totalRevenue}</div>
                                        <div className="col">Total Cost: ${totals.totalCost}</div>
                                        <div className="col">Total Profit: ${totals.totalProfit}</div>
                                        <div className="col">Profit: {totals.totalProfitPercentage}%</div>
                                    </div>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={m.scopeItemId.toString()}>
                                    <Card.Body>
                                        <AddLine
                                            add={(cat, e) => AddBudgetLine(scopeIndex, cat, e)}
                                            revenue={revenueLines.filter(item => !scope[scopeIndex].budgetRevenue.find(({ budgetRevenueLineId }) => item.budgetRevenueLineId === budgetRevenueLineId))}
                                            labor={budgetLines.budgetCostLabor.filter(item => !scope[scopeIndex].budgetCost.budgetCostLabor.find(({ budgetCostLineId }) => item.budgetCostLineId === budgetCostLineId))}
                                            nonlabor={budgetLines.budgetCostNonLabor.filter(item => !scope[scopeIndex].budgetCost.budgetCostNonLabor.find(({ budgetCostLineId }) => item.budgetCostLineId === budgetCostLineId))}
                                        />

                                        <ListGroup className="mt-3">
                                            <ListGroup.Item className="enc-budget-list">
                                                <div>
                                                    <div className="col">
                                                        Revenue
                                                    </div>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item className="enc-budget-list">
                                                <div className="row">
                                                    <div className="col"></div>
                                                    <div className="col enc-budget-col">#</div>
                                                    <div className="col enc-budget-col">Description</div>
                                                    <div className="col enc-budget-col">Revenue</div>
                                                    <div className="col-2" />
                                                </div>
                                            </ListGroup.Item>
                                            {/* setup key cost to have diff id if id === 0 */}
                                            {m.budgetRevenue.map((r, revIndex) => {
                                                let keyId = r.budgetRevenueId;
                                                if (Number(r.budgetRevenueId) === 0) {
                                                    keyId = -1 - revIndex - m.budgetRevenue.length;
                                                }

                                                return (
                                                    //<ListGroup.Item key={`rev-${r.budgetRevenueId}`} className="enc-budget-list">
                                                    <ListGroup.Item key={`rev-${keyId}`} className="enc-budget-list">
                                                        <div className="row" key={`BudgetRev-${revIndex}`}>
                                                            <div className="col">
                                                                {revIndex !== 0 && <FontAwesomeIcon onClick={() => moveRevUp(scopeIndex, revIndex)} className="clickable" icon={faArrowUp} />}
                                                                &nbsp;
                                                                <strong>#{revIndex + 1}</strong>
                                                                &nbsp;
                                                                {revIndex !== m.budgetRevenue.length - 1 && <FontAwesomeIcon onClick={() => moveRevDown(scopeIndex, revIndex)} className="clickable" icon={faArrowDown} />}
                                                            </div>

                                                            <div className="col enc-budget-col">{r.number}</div>
                                                            <div className="col enc-budget-col">{r.name}</div>
                                                            <div className="col enc-budget-col">
                                                                {isRevenueNumericInput(r)
                                                                    ?
                                                                    <input
                                                                        style={(!r.revenue || r.revenue === "0") ? { borderColor: 'red' } : {}}
                                                                        className="enc-input-100"
                                                                        type='text'
                                                                        value={r.revenue}
                                                                        onChange={(e) => changeRev(scopeIndex, revIndex, e.target.value)} />
                                                                    :
                                                                    <input
                                                                        style={(!r.revenueText || r.revenue === "0") ? { borderColor: 'red' } : {}}
                                                                        className="enc-input-100"
                                                                        type='text'
                                                                        value={r.revenueText}
                                                                        onChange={(e) => changeRevText(scopeIndex, revIndex, e.target.value)} />
                                                                }
                                                            </div>
                                                            <div className="col-1 enc-budget-col text-center">
                                                                <FontAwesomeIcon icon={isRevenueNumericInput(r) ? faDollarSign : faFont} className="clickable" onClick={(e) => setRevenueLineAltInput(scopeIndex, revIndex)} />
                                                            </div>
                                                            <div className="col-1 enc-budget-col text-right">
                                                                <FontAwesomeIcon icon={faTrash} className="clickable" onClick={(e) => removeRevenueLine(scopeIndex, revIndex)} />
                                                            </div>
                                                        </div>
                                                        <div className="divider my-2" />
                                                        <div className="row">
                                                            <div className="col">
                                                                <small>Rate</small>
                                                                <input
                                                                    className="enc-input-100"
                                                                    type='text'
                                                                    value={r.revenuePer}
                                                                    onChange={(e) => changeRevPer(scopeIndex, revIndex, e.target.value)} />
                                                            </div>
                                                            <div className="col enc-budget-col">
                                                                <small>Per Unit</small>
                                                                <DropdownList
                                                                    data={budgetCalculations}
                                                                    value={r.budgetCalculation}
                                                                    placeholder="Per Unit"
                                                                    onChange={e => SetRevPer(scopeIndex, revIndex, e)}
                                                                    textField='name'
                                                                />
                                                            </div>
                                                            <div className="col enc-budget-col">
                                                                <small>Time</small>
                                                                <DropdownList
                                                                    data={budgetTimes}
                                                                    value={r.budgetTime}
                                                                    placeholder="Time Unit"
                                                                    onChange={e => SetTimePer(scopeIndex, revIndex, e)}
                                                                    textField='name'
                                                                />
                                                            </div>
                                                            <div className="col-2 enc-budget-col">
                                                                <small>Minimum</small>
                                                                <input
                                                                    className="enc-input-100"
                                                                    type='text'
                                                                    value={r.minCycles}
                                                                    onChange={(e) => setMinCycles(scopeIndex, revIndex, e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <small>Notes:</small>
                                                                <TextareaAutosize
                                                                    className="enc-textarea"
                                                                    minRows={1}
                                                                    value={r.notes}
                                                                    style={textareaStyle}
                                                                    onChange={(e) => changeRevNotes(scopeIndex, revIndex, e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                        {r["showLumpSumWarning"] &&
                                                            <div className="row">
                                                                <div className="divider my-2" />
                                                                <div className="col text-center">
                                                                    <span className="text-danger">Warning: Please use either lump sum OR rate calculated values and not both. </span>
                                                                </div>
                                                            </div>
                                                        }
                                                    </ListGroup.Item>
                                                )
                                            }
                                            )}
                                        </ListGroup>

                                        <ListGroup className="mt-3">
                                            <ListGroup.Item className="enc-budget-list">
                                                <div>
                                                    <div className="col">
                                                        Non-Labor
                                                    </div>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item className="enc-budget-list">
                                                <div className="row">
                                                    <div className="col"></div>
                                                    <div className="col enc-budget-col">#</div>
                                                    <div className="col enc-budget-col">Category</div>
                                                    <div className="col enc-budget-col">Description</div>
                                                    <div className="col enc-budget-col">Summary Cost</div>
                                                    <div className="col-1" />
                                                </div>
                                            </ListGroup.Item>

                                            {/* setup key cost to have diff id if id === 0 */}
                                            {m.budgetCost.budgetCostNonLabor.map((b, budgetIndex) => {
                                                let keyId = b.budgetCostId;
                                                if (Number(b.budgetCostId) === 0) {
                                                    keyId = -1 - budgetIndex - m.budgetCost.budgetCostNonLabor.length;
                                                }

                                                return (
                                                    //<ListGroup.Item key={`cost-${b.budgetCostId}`} className="enc-budget-list">
                                                    <ListGroup.Item key={`cost-${keyId}`} className="enc-budget-list">
                                                        <div className="row" key={`BudgetRev-${budgetIndex}`}>
                                                            <div className="col">
                                                                {budgetIndex !== 0 && <FontAwesomeIcon onClick={() => moveNonLaborUp(scopeIndex, budgetIndex)} className="clickable" icon={faArrowUp} />}
                                                                &nbsp;
                                                                <strong>#{budgetIndex + 1}</strong>
                                                                &nbsp;
                                                                {budgetIndex !== m.budgetCost.budgetCostNonLabor.length - 1 && <FontAwesomeIcon onClick={() => moveNonLaborDown(scopeIndex, budgetIndex)} className="clickable" icon={faArrowDown} />}
                                                            </div>

                                                            <div className="col enc-budget-col">
                                                                {b.number}
                                                            </div>
                                                            <div className="col enc-budget-col">
                                                                {b.category}
                                                            </div>
                                                            <div className="col enc-budget-col">
                                                                {b.name}
                                                            </div>
                                                            <div className="col enc-budget-col">
                                                                <input
                                                                    type='text'
                                                                    className="enc-input-100"
                                                                    disabled={b.hours.length !== 0}
                                                                    value={b.cost}
                                                                    style={(!b.cost || b.cost === "0") ? { borderColor: 'red' } : {}}
                                                                    onChange={(e) => changeCost(scopeIndex, 'budgetCostNonLabor', budgetIndex, e.target.value)} />
                                                            </div>
                                                            <div className="col-1 enc-budget-col text-right">
                                                                <FontAwesomeIcon icon={faTrash} className="clickable" onClick={(e) => removeCostLine(scopeIndex, 'budgetCostNonLabor', budgetIndex)} />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <small>Notes:</small>
                                                                <TextareaAutosize
                                                                    className="enc-textarea"
                                                                    minRows={1}
                                                                    value={b.notes}
                                                                    style={textareaStyle}
                                                                    onChange={(e) => changeCostNotes(scopeIndex, 'budgetCostNonLabor', budgetIndex, e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </ListGroup.Item>
                                                )
                                            }
                                            )}
                                        </ListGroup>

                                        <ListGroup className="mt-3">
                                            <ListGroup.Item className="enc-budget-list">
                                                <div>
                                                    <div className="col">
                                                        Labor
                                                </div>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item className="enc-budget-list">
                                                <div className="row">
                                                    <div className="col"></div>
                                                    <div className="col enc-budget-col">#</div>
                                                    <div className="col enc-budget-col">Category</div>
                                                    <div className="col enc-budget-col">Description</div>
                                                    <div className="col enc-budget-col">Summary Cost</div>
                                                    <div className="col-1" />
                                                </div>
                                            </ListGroup.Item>

                                            {/* setup key labor to have diff id if id === 0 */}
                                            {m.budgetCost.budgetCostLabor.map((bb, bbudgetIndex) => {
                                                let keyId = bb.budgetCostId;
                                                if (Number(bb.budgetCostId) === 0) {
                                                    keyId = -1 - bbudgetIndex - m.budgetCost.budgetCostLabor.length;
                                                }

                                                return (
                                                    //<ListGroup.Item key={`labor-${bb.budgetCostId}`} className="enc-budget-list">
                                                    <ListGroup.Item key={`labor-${keyId}`} className="enc-budget-list">
                                                        <>
                                                            <div className="row" key={`BudgetRev-${bbudgetIndex}`}>
                                                                <div className="col">
                                                                    {bbudgetIndex !== 0 && <FontAwesomeIcon onClick={() => moveLaborUp(scopeIndex, bbudgetIndex)} className="clickable" icon={faArrowUp} />}
                                                                    &nbsp;
                                                                    <strong>#{bbudgetIndex + 1}</strong>
                                                                    &nbsp;
                                                                    {bbudgetIndex !== m.budgetCost.budgetCostLabor.length - 1 && <FontAwesomeIcon onClick={() => moveLaborDown(scopeIndex, bbudgetIndex)} className="clickable" icon={faArrowDown} />}
                                                                </div>

                                                                <div className="col enc-budget-col">
                                                                    {bb.number}
                                                                </div>
                                                                <div className="col enc-budget-col">
                                                                    {bb.category}
                                                                </div>
                                                                <div className="col enc-budget-col">
                                                                    {bb.name}
                                                                </div>
                                                                <div className="col enc-budget-col">
                                                                    <input type='text'
                                                                        className="enc-input-100"
                                                                        disabled={bb.hours.length !== 0}
                                                                        value={bb.cost}
                                                                        style={(!bb.cost || bb.cost === "0") ? { borderColor: 'red' } : {}}
                                                                        onChange={(e) => changeCost(scopeIndex, 'budgetCostLabor', bbudgetIndex, e.target.value)} />
                                                                </div>
                                                                <div className="col-1 enc-budget-col text-right">
                                                                    <FontAwesomeIcon icon={faTrash} className="clickable" onClick={(e) => removeCostLine(scopeIndex, 'budgetCostLabor', bbudgetIndex)} />
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <small>Notes:</small>
                                                                    <TextareaAutosize
                                                                        className="enc-textarea"
                                                                        minRows={1}
                                                                        value={bb.notes}
                                                                        style={textareaStyle}
                                                                        onChange={(e) => changeCostNotes(scopeIndex, 'budgetCostLabor', bbudgetIndex, e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="divider my-2" />
                                                            <HourCalculator
                                                                hours={bb.hours}
                                                                hoursList={hours}
                                                                removeHour={(e) => RemoveHour(scopeIndex, 'budgetCostLabor', bbudgetIndex, e)}
                                                                addHours={(e) => AddHourLine(scopeIndex, 'budgetCostLabor', bbudgetIndex, e, bb.budgetCostId)}
                                                                updateHours={(e, index) => UpdateHours(scopeIndex, 'budgetCostLabor', bbudgetIndex, index, e)}
                                                            />
                                                        </>
                                                    </ListGroup.Item>
                                                )
                                            }

                                            )}
                                        </ListGroup>

                                        <ListGroup className="mt-3">
                                            <ListGroup.Item className="enc-budget-list">
                                                <div>
                                                    <div className="col">
                                                        Tax
                                                    </div>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item className="enc-budget-list">
                                                <div className="row">
                                                    <div className="col-2">Tax Amount</div>
                                                    <div className="col-1" />
                                                </div>
                                            </ListGroup.Item>

                                            {m.budgetTax.map((bt, taxIndex) =>
                                                <ListGroup.Item key={`cost-${taxIndex}`} className="enc-budget-list">
                                                    <div className="row" key={`BudgetTax-${taxIndex}`}>
                                                        <div className="col-11">
                                                            <input
                                                                type='text'
                                                                className="enc-input-100"
                                                                value={bt.cost}
                                                                style={(!bt.cost || bt.cost === "0") ? { borderColor: 'red' } : {}}
                                                                onChange={(e) => changeTax(scopeIndex, taxIndex, e.target.value)} />
                                                        </div>
                                                        <div className="col-1 enc-budget-col text-right">
                                                            <FontAwesomeIcon icon={faTrash} className="clickable" onClick={(e) => removeTaxLine(scopeIndex, taxIndex)} />
                                                        </div>
                                                    </div>
                                                    <div className="row" key={`BudgetTaxDescription-${taxIndex}`}>
                                                        <div className="col-11">
                                                            <small>Tax for:</small>
                                                            <input
                                                                placeholder="Description"
                                                                type='text'
                                                                className="enc-input-100"
                                                                value={bt.description}
                                                                onChange={(e) => changeTaxDescription(scopeIndex, taxIndex, e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <small>Notes:</small>
                                                            <TextareaAutosize
                                                                className="enc-textarea"
                                                                minRows={1}
                                                                value={bt.notes}
                                                                style={textareaStyle}
                                                                onChange={(e) => changeTaxNotes(scopeIndex, taxIndex, e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </ListGroup.Item>

                                            )}
                                        </ListGroup>

                                        <ListGroup className="mt-3">
                                            <ListGroup.Item className="enc-data-list">
                                                <div className="row">
                                                    <div className="col">
                                                        Additional Sublines
                                                    </div>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item className="enc-data-list">
                                                <div className="row">
                                                    <div className="col">
                                                        Text Line
                                                    </div>
                                                    <div className="col-2 text-right">
                                                        Optional Cost
                                                    </div>
                                                    <div className="col-1 text-right">
                                                        
                                                    </div>
                                                </div>
                                            </ListGroup.Item>
                                            {m.budgetAdditionalLines.map((bal, alIndex) =>
                                                <ListGroup.Item key={`AdditionalLine-${alIndex}`}>
                                                    <div className="row">
                                                        <div className="col-9">
                                                            <input
                                                                className="enc-input-100"
                                                                value={bal.additionalText}
                                                                onChange={e => changeSubline(scopeIndex, alIndex, 'additionalText', e.target.value)}
                                                            />
                                                        </div>
                                                        <div className="col-2">
                                                            <input
                                                                className="enc-input-100"
                                                                value={bal.optionalCost}
                                                                onChange={e => changeSubline(scopeIndex, alIndex, 'optionalCost', e.target.value)}
                                                            />
                                                        </div>
                                                        <div className="col-1">
                                                            <FontAwesomeIcon icon={faTrash} className="clickable" onClick={() => removeSubline(scopeIndex, alIndex)} />
                                                        </div>
                                                    </div>
                                                </ListGroup.Item>
                                            )}

                                            <ListGroup.Item className="enc-data-list">
                                                <>
                                                    <div className="row mt-2">
                                                        <div className="col text-right">
                                                            <Button className="enc-button" onClick={() => addSubline(scopeIndex)}>Add Additional Line</Button>
                                                        </div>
                                                    </div>
                                                </>
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card >
                        </Accordion >
                    )
                })}

                <div className="container border p-2">
                    <div className="row">
                        <div className="col text-center">
                            <h4>Summary</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">Total Rev: ${Summary.totalRevenue}</div>
                        <div className="col">Total Cost: ${Summary.totalCost}</div>
                        <div className="col">Total Profit: ${Summary.totalProfit}</div>
                        <div className="col">Profit: {Summary.totalProfitPercentage}%</div>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-8">
                        <Alert show={showAlert} onClose={() => setShowAlert(false)} dismissible>
                            {alertText}
                        </Alert>
                    </div>
                    <div className="col-4 text-right">
                        <Button className="enc-button" onClick={() => saveBudget(false)}>Save Budget</Button> <Button className="enc-button" onClick={() => saveBudget(true)}>Submit Budget</Button>
                    </div>
                </div>
            </div >
        </LoadingOverlay>
    )
}
