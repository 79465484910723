import APIRequest from './MainAPICall/api';
import config from '../APIConfig';

export const GetHandOffProfile = (id) => {
    return APIRequest({
        url: config.baseURL + "/HandOff/GetHandOffProfile",
        method: "post",
        body: {
            id: id
        }
    });
}

export const SaveHandOffInfo = (payload) => {
    return APIRequest({
        url: config.baseURL + "/HandOff/SaveHandOffInfo",
        method: "post",
        body: payload
    });
}

export const SaveMobilization = (payload) => {
    return APIRequest({
        url: config.baseURL + "/HandOff/SaveMobilization",
        method: "post",
        body: payload
    });
}

export const CheckEdit = (id) => {
    return APIRequest({
        url: config.baseURL + "/HandOff/CheckEdit",
        method: "post",
        body: {
            id: id
        }
    });
}

export const CompleteStepOne = (id) => {
    return APIRequest({
        url: config.baseURL + "/HandOff/CompleteStepOne",
        method: "post",
        body: {
            id: id
        }
    });
}
