import React, { useEffect, useState } from 'react';
import { GetScopeBuilder } from '../../API/JobScope';
import { DropdownList } from 'react-widgets';
import { Button, Spinner } from 'react-bootstrap';
import { BulkNoteLink } from '../../API/Proposal';

export default props => {
    const [scopeBuilder, setScopeBuilder] = useState([])
    const [selected, setSelected] = useState([])
    const [working, setWorking] = useState(false);

    useEffect(() => {
        GetScopeBuilder()
            .then(res => {
                setScopeBuilder(res.data)
            })
    }, [])

    const changeScope = (tier, value) => {
        let s = [...selected]
        if (selected.length >= tier + 1) {
            s.splice(tier, selected.length - tier, value)
        } else {
            s.push(value)
        }
        setSelected(s)
    }

    const loadScope = (sc, tier = 0, arr = []) => {
        arr.push({ data: sc, tier: tier })
        let select;
        if (selected[tier]) {
            select = sc.filter(scope => scope.scopeBuilderId === selected[tier].scopeBuilderId)[0]
        }
        if (select && select.children && select.children.length > 0) {
            loadScope(select.children, tier + 1, arr)
        }
        return arr;
    }

    const BulkAddNote = () => {
        let lastItem = selected.slice(-1).pop()
        setWorking(true);
        if(lastItem && props.noteId){
            BulkNoteLink(lastItem.scopeBuilderId, props.noteId)
            .then(res => {
                props.reloadList()
                setWorking(false);
                setSelected([])
            })
        }

    }


    return (
        <div>
            <form action="#" onSubmit={(e) => { e.preventDefault(); BulkAddNote() }}>
                <div className='row my-2'>
                    {loadScope(scopeBuilder).map((m, i) => {
                        return <div key={`dd-${i}`} className="col-6 mt-1">
                            <DropdownList
                                data={m.data}
                                onChange={e => changeScope(m.tier, e)}
                                value={selected[m.tier]}
                                textField='itemName'
                                placeholder="Select a value"
                                required
                            />
                        </div>
                    })}
                </div>
                <div className="row">
                    <div className="col text-right">
                        <Button type='submit' className="enc-button" disabled={working}>
                            {working? 
                            <span>Processing 
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              /></span>: "Bulk Add Note"} 
                                </Button>
                    </div>
                </div>
            </form>
        </div>
    )
}
