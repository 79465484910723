import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Accordion, Alert, Button, Card, ListGroup } from 'react-bootstrap';
import { DropdownList } from 'react-widgets';
import { ProposalListNames, GetNote, PostNote } from '../../API/Proposal';
import PrefillMassAdd from './PrefillMassAdd';

export default props => {
    const [prefills, setPrefills] = useState([])
    const [selected, setSelected] = useState(null);
    const [confirmSave, setConfirmSave] = useState(false);

    const [note, setNote] = useState({
        prefilleNoteId: 0,
        note: "",
        scopes: []
    })





    useEffect(() => {
        LoadNote(props.match.params.id)
    }, [props.match.params.id])


    // Dropdown Menu Loading.
    useEffect(() => {
        LoadProposalPrefills()
    }, [])

    const LoadProposalPrefills = () => {
        ProposalListNames()
            .then(res => {
                setPrefills(res.data)
            })
    }


    const LoadNote = (id) => {
        GetNote(id)
            .then(res => {
                setNote(res.data)
            })
    }

    const SaveNote = () => {
        PostNote(note)
            .then(res => {
                LoadNote(props.match.params.id)
                setConfirmSave(true);
            })
    }



    const AddScopeLine = () => {
        if (selected) {
            let s = { ...note }
            s.scopes.push(selected)
            setSelected(null)
            setNote(s)
        }
    }

    const RemoveScopeLine = (i) => {
        let s = { ...note }
        s.scopes.splice(i, 1)
        setNote(s)
    }

    const changeNote = (value) => {
        let s = { ...note }
        s.note = value
        setNote(s)
    }


    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h3>Note Editor</h3>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col">
                    <label>Note</label>
                </div>
                <div className="col">
                    <textarea
                        value={note.note}
                        className='enc-textarea'
                        onChange={e => changeNote(e.target.value)}
                    />
                </div>
            </div>

            <div className="row mt-2">
                <div className="col-8">
                    <DropdownList
                        placeholder="Select a Scope Line"
                        data={prefills.filter(item => !note.scopes.find(({ leafId }) => item.leafId === leafId))}
                        textField="scopeName"
                        filter='contains'
                        onChange={e => setSelected(e)}
                        value={selected}
                    />
                </div>
                <div className="col text-right">
                    <Button className="enc-button" onClick={AddScopeLine}>Add Scope Line</Button>
                </div>
            </div>


            <Accordion className="my-2">
                <Card style={{overflow: "visible"}}>
                    <Accordion.Toggle className="clickable" as={Card.Header} eventKey="0">
                        Bulk Note Link
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body><PrefillMassAdd noteId={note.prefillNoteId} reloadList={() => LoadNote(props.match.params.id)} /></Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
            


            <ListGroup className="mt-2">
                <ListGroup.Item className="enc-list">
                    Scopes Linked
                    </ListGroup.Item>
                {note.scopes.map((m, i) => {
                    return (
                        <ListGroup.Item key={`Prefill-${i}`}>
                            <div className="row">
                                <div className="col">
                                    {m.scopeName}
                                </div>
                                <div className="col-2">
                                    <FontAwesomeIcon icon={faTrash} className="clickable" onClick={e => RemoveScopeLine(i)} />
                                </div>
                            </div>
                        </ListGroup.Item>
                    )
                })}
            </ListGroup>
            <div className="row mt-2">
                <div className="col-8">
                    <Alert variant="success" show={confirmSave} onClose={() => setConfirmSave(false)} dismissible>
                        <Alert.Heading>Note Saved</Alert.Heading>
                        <p>
                            Successfully saved note data.
                            </p>
                    </Alert>
                </div>
                <div className="col text-right">
                    <Button className="enc-button" onClick={SaveNote}>Save</Button>
                </div>
            </div>
        </div>
    )
}
