import React, { useEffect, useState } from 'react';
import { GetOffice, SaveOffice } from '../API/Office';
import { useHistory } from 'react-router-dom';

export default props => {
    const [office, setOffice] = useState({
        officeLocationId: 0,
        name: "",
        address: "",
        city: "",
        state: "",
        zipCode: "",
        faxNumber: "",
        phoneNumber: ""
    });
    const [error, setError] = useState("")

    const history = useHistory();

    useEffect(() => {
        LoadOffice(props.id);
    }, [props.id])

    const LoadOffice = (id) => {
        if (id !== "new") {
            GetOffice(parseInt(id))
                .then(res => {
                    if (res.success) {
                        setOffice(res.data)
                    }
                })
        }
    }

    const handleChange = (e) => {
        let newState = { ...office };
        newState[e.target.name] = e.target.value;
        setOffice(newState)
    }

    const saveButton = () => {
        SaveOffice(office)
            .then(res => {
                history.push('/Admin/OfficeLocations')
            })
            .catch(err => {
                setError("Unable to save data.")
            })
    }

    return (
        <div className="container">
            <form action="#" onSubmit={(e) => { e.preventDefault(); saveButton() }}>
                <div className="row">
                    <div className="col">
                        <input type="text" className="enc-input-100" name="name" value={office.name} onChange={handleChange} placeholder="Office Name" required />
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="col">
                        <input type="text" className="enc-input-100" name="address" value={office.address} onChange={handleChange} placeholder="Address" required />
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="col-md-6">
                        <input type="text" className="enc-input-100" name="city" value={office.city} onChange={handleChange} placeholder="City" required />
                    </div>
                    <div className="col-md-3">
                        <input type="text" className="enc-input-100" name="state" value={office.state} onChange={handleChange} placeholder="State" required />
                    </div>
                    <div className="col-md-3">
                        <input type="text" className="enc-input-100" name="zipCode" value={office.zipCode} onChange={handleChange} placeholder="Zip Code" required />
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="col">
                        <input type="tel" className="enc-input-100" name="phoneNumber" value={office.phoneNumber} onChange={handleChange} placeholder="Phone Number" />
                    </div>
                    <div className="col">
                        <input type="tel" className="enc-input-100" name="faxNumber" value={office.faxNumber} onChange={handleChange} placeholder="Fax Number" />
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="col">
                        {error}
                    </div>
                    <div className="col text-right">
                        <input type="submit" value="Save" className="enc-button" />
                    </div>
                </div>
            </form>
        </div>
    )
}
