import React, { useState, useEffect } from 'react';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PostJobScope, GetJobScopeList, EditScopeItem, DeleteScopeItem } from '../../../API/JobScope';
import ScopeBuilder from './ScopeBuilder';
import { ListGroup } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import "bootstrap/dist/css/bootstrap.min.css";
import ConfirmDelete from '../../../Components/ConfirmDelete';

export default props => {
    const [scope, setScope] = useState([]);
    const [openCloseModal, setOpenCloseModal] = useState(false); // modal starts as closed === false
    const [itemID, setItemID] = useState(0);
    const [descript, setDescript] = useState("");
    const [quant, setQuant] = useState(0);

    const [confirmDeleteItem, setConfirmDeleteItem] = useState(false);
    const [removeItemID, setRemoveItemID] = useState(-1);

    useEffect(() => {
        LoadScope(props.handOffId)
    }, [props.handOffId])

    const LoadScope = (handOffId) => {
        GetJobScopeList(handOffId)
            .then(res => {
                setScope(res.data)
            })
    }

    const removeItem = (id) => {
        setRemoveItemID(id);
        setConfirmDeleteItem(true);
    }

    const removeScopeItem = () => {
        DeleteScopeItem(parseInt(removeItemID))
            .then(res => {
                setConfirmDeleteItem(false);
                LoadScope(props.handOffId)
            })
    }

    const addScopeItem = (payload) => {
        return new Promise((resolve, reject) => {
            PostJobScope(payload)
                .then(res => {
                    LoadScope(props.handOffId)
                    resolve(true)
                })
        })
    }

    const editItem = (m) => {
        setItemID(m.scopeItemId);
        setDescript(m.description);
        setQuant(m.quantity);
        setOpenCloseModal(!openCloseModal);
    };

    const closeModal = () => {
        setOpenCloseModal(false);
    }

    const saveItem = () => {
        const payload = {
            scopeItemID: Number(itemID),
            quantity: Number(quant),
            description: descript,
        };
        EditScopeItem(payload).then(res => {
            resetItem();
            closeModal();
            LoadScope(props.handOffId);
        });
    };

    const resetItem = () => {
        setItemID(0);
        setDescript("");
        setQuant(0);
    }

    const popupModal = () => {
        return (
            <Modal show={openCloseModal} onHide={closeModal}>
                <Modal.Header>
                    Edit Scope Item
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-2">
                            <label>Description</label>
                        </div>
                        <div className="col">
                            <textarea
                                value={descript}
                                onChange={e => setDescript(e.target.value)}
                                className="enc-textarea"
                                placeholder="Insert Description Here..."
                                required
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2">
                            <label>Quantity</label>
                        </div>
                        <div className="col-5">
                            <input
                                type='number'
                                value={quant}
                                onChange={e => setQuant(e.target.value)}
                                className="enc-input-100"
                                min={0}
                                required
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={closeModal}>Cancel</button>
                    <button onClick={saveItem}>Save</button>
                </Modal.Footer>
            </Modal>
        )
    }

    return (
        <div>
            {popupModal()}
            <ListGroup>
                <ListGroup.Item className="enc-data-list">Job Scope</ListGroup.Item>
                <ListGroup.Item className="enc-data-list">
                    <div className="row">
                        <div className="col">
                            Name
                                </div>
                        <div className="col">
                            Internal Description
                                </div>
                        <div className="col-2">
                            Quantity
                                </div>
                        <div className="col-2"></div>
                        <div className="col-1"></div>
                    </div>
                </ListGroup.Item>
                {scope.map(m => {
                    return (
                        <ListGroup.Item key={`JS-${m.scopeItemId}`} className="enc-data-list">
                            <div className="row data-display">
                                <div className="col">
                                    {m.optional && "Optional"} {m.name}
                                </div>
                                <div className="col">
                                    {m.description}
                                </div>
                                <div className="col-2">
                                    {m.quantity}
                                </div>
                                <div className="col-2 text-right">
                                    <button type='button' className="enc-button" onClick={() => editItem(m)}>Edit Item</button>
                                </div>
                                <div className="col-1 text-right">
                                    <ConfirmDelete
                                        show={confirmDeleteItem}
                                        setShow={setConfirmDeleteItem}
                                        action={removeScopeItem}
                                        message="Confirm you would like to delete scope item."
                                    />
                                    <FontAwesomeIcon icon={faTrash} className="clickable" onClick={() => removeItem(m.scopeItemId)} />
                                </div>
                            </div>
                        </ListGroup.Item>
                    )
                })}
            </ListGroup>
        <ScopeBuilder handOffId={props.handOffId} addScopeItem={addScopeItem} />
        </div >
    )
}


