import React from 'react';
import { Button } from 'react-bootstrap';

export default props => {
    const getFormattedDate = (date) => {
        if(date !== ""){
            date = new Date(date)
            let year = date.getFullYear();
            let month = (1 + date.getMonth()).toString().padStart(2, '0');
            let day = date.getDate().toString().padStart(2, '0');
          
            return month + '/' + day + '/' + year;
        }
    }
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-4">Install Date: <br /> {getFormattedDate(props.data.installDate)}</div>
                    <div className="col-4">Install Type: <br /> {props.data.installType}</div>
                    <div className="col-4">Lead Time: <br /> {props.data.leadTime}</div>
                </div>
                <div className="row mt-2">
                    <div className="col-4">Removal Date: <br /> {getFormattedDate(props.data.removalDate)}</div>
                    <div className="col-4">Removal Type: <br /> {props.data.removalType}</div>
                </div>
            </div>
            <div className="row mt-1">
                <div className="col text-right">
                    {props.setEdit && <Button className="enc-button" onClick={() => props.setEdit(true)}>Edit Mobilization</Button>}
                </div>
            </div>

        </>
    )
}
