import React, { useState, useEffect } from 'react';
import { Accordion, Card, Button, ListGroup } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPencilAlt, faPlus } from '@fortawesome/free-solid-svg-icons';

import { GetJobNoteList } from '../API/Jobs';

export default props => {
    const [JobNotes, setJobNotes] = useState([])


    useEffect(() => {
        LoadNotes(props.jobId)
    }, [props.jobId])

    const LoadNotes = (id) => {
        GetJobNoteList(id)
            .then(res => {
                res.data.forEach(element => {
                    element.notes.forEach(note => {
                        note.expand = false;
                    })
                });
                setJobNotes(res.data)
            })
    }

    const handleExpand = (catIndex, noteIndex) => {
        let s = [...JobNotes]
        s[catIndex].notes[noteIndex].expand = !s[catIndex].notes[noteIndex].expand;
        setJobNotes(s)
    }


    const history = useHistory();

    return (

        <div className="container mt-3 mb-5">
            <ListGroup>
                <ListGroup.Item className="enc-list">
                    <div className="row">
                        <div className='col'>
                            Job Notes
                        </div>
                        <div className="col-2 text-right">
                            <Button className='enc-button' onClick={() => { if (window.confirm('Adding a note will remove all unsaved changes to all forms in progress. Do you still want to continue?')) history.push(`${props.url}/NewNote`) }}>Add Note</Button>
                        </div>
                    </div>

                </ListGroup.Item>
                <Accordion>
                    {JobNotes.map((category, categoryIndex) =>
                        <Card key={categoryIndex}>
                            <Accordion.Toggle as={Card.Header} eventKey={`note-${categoryIndex}`}>
                                <div className="row">
                                    <div className="col-8">
                                        {category.header}
                                    </div>
                                    <div className="col-4 text-right">
                                        <small>{category.notes.length === 0 ? "No Notes Currently Available" : `Notes Available: ${category.notes.length}`}</small>
                                    </div>
                                </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={`note-${categoryIndex}`}>
                                <Card.Body>
                                    {category.notes.map((note, noteIndex) => {
                                        return (
                                            <Card className="note-container p-2 my-1" key={`NoteCard-${note.jobNoteId}`}>
                                                <div className="container-fluid">
                                                    <div className="row">
                                                        <div className="col">
                                                            <strong>{note.title.length === 0 ? "No Note Title Found" : note.title}</strong><br></br>
                                                        </div>
                                                        <div className="col-2 text-right">
                                                            {note.editable && <FontAwesomeIcon
                                                                className="clickable"
                                                                onClick={() => { history.push(`${props.url}/EditNote/${note.jobNoteId}`) }}
                                                                icon={faPencilAlt} />}
                                                        </div>
                                                    </div>
                                                    <div className="divider" />
                                                    <div className="row note-body p-2">
                                                        <div className="col">
                                                            <div className="row">
                                                                <div className="col">
                                                                    {note.description.length === 0 && "No Note Body Found"}
                                                                    {note.description.length >= 150 && !note.expand ?
                                                                        `${note.description.substring(0, 147)}...`
                                                                        :
                                                                        `${note.description}`
                                                                    }
                                                                </div>
                                                            </div>
                                                            {note.description.length >= 150 &&
                                                                <div className="row">
                                                                    <div className="col text-center">
                                                                        {(note.expand) ?
                                                                            <FontAwesomeIcon className="clickable" icon={faMinus} onClick={() => handleExpand(categoryIndex, noteIndex)} />
                                                                            :
                                                                            <FontAwesomeIcon className="clickable" icon={faPlus} onClick={() => handleExpand(categoryIndex, noteIndex)} />
                                                                        }
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col text-right pr-2">
                                                            by {note.noteUser} on {note.date}
                                                            </div>
                                                    </div>
                                                </div>
                                            </Card>
                                        )
                                    })}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    )}
                </Accordion>
            </ListGroup>
        </div>
    )
}