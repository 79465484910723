import React, { useEffect, useState } from 'react';
import { GetAccount, GetAccountListWithChildren, SaveAccountInfo, GetIndustryTypes, GetTerritories } from '../API/Account';
import { DropdownList } from 'react-widgets';
import { Button, Breadcrumb, Alert } from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';

export default props => {
    const [info, setInfo] = useState({
        "accountId": 0,
        "accountName": "",
        "accountNumber": "",
        "enabled": true,
        "parentAccount": 0,
        "industryTypeId": null,
        "territoryId": null,
        "taxExempt": false
    });
    const [accountList, setAccountList] = useState([]);
    const [industryTypeList, setIndustryTypeList] = useState([]);
    const [territoriesList, setTerritoryList] = useState([]);

    const [show, setShow] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [redirectUrl, setRedirectUrl] = useState("");

    useEffect(() => {
        const loadAccounts = () => {
            GetAccountListWithChildren(true)
                .then(res => {
                    if (res.success) {
                        setAccountList(flattenAccountsList(res.data))
                    }
                })
        }

        const flattenAccountsList = (accounts) => {
            let flattenedList = []
            accounts.forEach(e => {
                if (parseInt(props.accountId) !== e.accountId) {
                    flattenedList.push(e)
                    if (e.nodes && e.nodes.length > 0) {
                        flattenedList = flattenAccountsList(e.nodes).concat(flattenedList)
                    }
                }
            });
            return flattenedList
        }

        const loadProfile = (id) => {
            GetAccount(id)
                .then(res => {
                    setInfo(res.data)
                })
        }

        const loadIndustryTypes = () => {
            GetIndustryTypes()
                .then(res => {
                    if (res.success) {
                        setIndustryTypeList(res.data)
                    }
                })
        }

        const loadTerritories = () => {
            GetTerritories()
                .then(res => {
                    if (res.success) {
                        setTerritoryList(res.data)
                    }
                })
        }

        loadProfile(parseInt(props.accountId));
        loadAccounts();

        loadIndustryTypes();
        loadTerritories();

    }, [props.accountId]);

    const changeInfo = (field, value) => {
        let state = { ...info };
        state[field] = value
        console.log(field, value);
        setInfo(state)
    }

    const history = useHistory();
    const submitSave = () => {
        SaveAccountInfo(info)
            .then(res => {
                if (res.success) {
                    history.push(`${props.url}/${res.data.accountId}`)
                }
            })
            .catch(err => {
                setShow(true);
                if(err.details){
                    setAlertMessage(err.details.message)
                    if(err.details.existingID){
                        setRedirectUrl(`${props.url}/${err.details.existingID}`)
                    }
                } else {
                    setAlertMessage("An error has occured");
                }
            })
    }

    const toggleEnabled = () => {
        let payload = { ...info }
        payload.enabled = !info.enabled;
        SaveAccountInfo(payload)
            .then(res => {
                if (res.success) {
                    history.push(`${props.url}/${res.data.accountId}`)
                }
            })
    }


    return (
        <div className="container-fluid">
            <Breadcrumb className="breadcrumb">
                <li className="breadcrumb-item"><Link to={`${props.url}`}>Accounts List</Link></li>
                <li className="breadcrumb-item"><Link to={`${props.url}/${props.accountId}`}>Account</Link></li>
                <Breadcrumb.Item active>Edit Account</Breadcrumb.Item>
            </Breadcrumb>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <h3>Edit Account</h3>
                    </div>
                </div>
                <div className="divider my-2" />
                <form action="#" onSubmit={(e) => { e.preventDefault(); submitSave() }}>
                    <div className="row">
                        <div className="col">
                            <h4>Account Information</h4>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col">
                            <small>Account Name</small>
                            <input type="text" className="enc-input-100" name="accountName" value={info.accountName} placeholder="Account Name" onChange={(e) => changeInfo('accountName', e.target.value)} />
                        </div>
                        <div className="col">
                            <small>Account Number</small>
                            <input type="text" className="enc-input-100" name="accountNumber" value={info.accountNumber} placeholder="Account Number" onChange={(e) => changeInfo('accountNumber', e.target.value)} />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col">
                            <small>Parent Account</small>
                            <DropdownList
                                filter='contains'
                                placeholder="Parent Account"
                                data={accountList}
                                textField="accountName"
                                value={info.parentAccount}
                                valueField='accountId'
                                onChange={e => changeInfo('parentAccount', e.accountId)}

                            />
                        </div>
                    </div>
                    {/*<div className="row mb-2">*/}
                    {/*    <div className="col">*/}
                    {/*        <small>Industry</small>*/}
                    {/*        <DropdownList*/}
                    {/*            filter='contains'*/}
                    {/*            placeholder="Select Industry"*/}
                    {/*            data={industryTypeList}*/}
                    {/*            textField="industryName"*/}
                    {/*            value={info.industryTypeId}*/}
                    {/*            valueField="industryTypeId"*/}
                    {/*            onChange={e => changeInfo("industryTypeId", e.industryTypeId) }*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*    <div className="col">*/}
                    {/*        <small>Territory</small>*/}
                    {/*        <DropdownList*/}
                    {/*            filter='contains'*/}
                    {/*            placeholder="Territory"*/}
                    {/*            data={territoriesList}*/}
                    {/*            textField="territoryName"*/}
                    {/*            value={info.territoryId}*/}
                    {/*            valueField="territoryId"*/}
                    {/*            onChange={e => { changeInfo("territoryId", e.territoryId) }}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="row mt-3">*/}
                    {/*    <div className="col">*/}
                    {/*        <div className="toggle-switch small-switch">*/}
                    {/*            <input*/}
                    {/*                type="checkbox"*/}
                    {/*                className="toggle-switch-checkbox"*/}
                    {/*                id="taxExempt"*/}
                    {/*                checked={info.taxExempt}*/}
                    {/*                onChange={e => changeInfo("taxExempt", e.target.checked)}*/}
                    {/*            />*/}
                    {/*            <label className="toggle-switch-label" htmlFor="taxExempt">*/}
                    {/*                <span className={"toggle-switch-inner"} />*/}
                    {/*                <span*/}
                    {/*                    className={"toggle-switch-switch"}*/}
                    {/*                />*/}
                    {/*            </label>*/}
                    {/*        </div>*/}
                    {/*        <small className="ml-2">Tax Exempt</small>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="row">
                        <div className="col-8">
                            <Alert show={show} variant="danger" onClose={() => setShow(false)} dismissible>
                                {alertMessage} {redirectUrl !== "" && <Link to={redirectUrl}>Click here to go to account.</Link>}
                            </Alert>
                        </div>
                        <div className="col text-right">
                            {info.enabled ? <Button variant="danger" onClick={toggleEnabled}>Disable Account</Button> :
                                <Button variant="danger" onClick={toggleEnabled}>Enable Account</Button>}
                            <Button type='submit' className="enc-button ml-2">Save</Button>
                        </div>
                    </div>
                </form>
            </div >
        </div >
    )
}
